import React, { useRef } from 'react'
import {Select} from 'antd'
import {IncidentFilter} from '../model'
import {SelectValue} from 'antd/es/select'
import {ResponseValueWithId} from '../../general-incident-setting'

interface OwnProps {
    onChanged: (value: string[]) => void
    criteria?: IncidentFilter
    ticketTypes: ResponseValueWithId[]
    isLoading?: Function
}

type Props = OwnProps

const TicketTypeFilter: React.FC<Props> = (props: Props) => {
    const {Option} = Select
    const ref = useRef<any>(null)

    const onTicketsTypeChanged = (value: SelectValue) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        props.onChanged(Array.isArray(value) ? (value as Array<any>).map((it) => it as string) : [value as string])
    }

    return (
        <>
            <p className={'topicName'}>Ticket Type</p>
            <Select ref={ref} mode="multiple" style={{width: '100%'}}
                onChange={(e) => { onTicketsTypeChanged(e) }}
                value={props.criteria?.ticketTypes?.split(',') || []}
                placeholder={'Select a ticket type'}
                allowClear>
                {props.ticketTypes?.map((it, index) => {
                    return <Option key={index} value={it.value}>{it.value}</Option>
                })}
            </Select><br/>
        </>
    )
}

export default TicketTypeFilter
