import { Row, Col, Icon, Form, Card, Button, Modal } from 'antd'
import React from 'react'
import { newPackages } from '../Services/model'
import { FormComponentProps } from 'antd/es/form'
import moment from 'moment'
interface params {
    current: number
    isCancel: boolean
    setIsCancel: Function
    setCurrent: Function
    tempData: newPackages
}

const { confirm } = Modal
type Props = params & FormComponentProps
const SummaryOrder: React.FC<Props> = (props: Props) => {
    // const { getFieldDecorator, setFieldsValue, getFieldValue, setFields } = props.form
    // const [packageStorage, setPackageStorage] = useState<packages[]>([])
    // const [packageUser, setPackageUser] = useState<packages[]>([])
    // const [packagePeople, setPackagePeople] = useState<packages[]>([])
    // const [packageDatabase, setPackageDatabase] = useState<packages[]>([])
    // const [total, setTotal] = useState<number>(0)
    const total = 0

    const renderIcons = (data: string) => {
        if (data === 'Ticket') {
            return <Icon type="warning" />
        } else if (data === 'Asset') {
            return <Icon type="laptop" />
        } else if (data === 'Problem') {
            return <Icon type="security-scan" />
        } else if (data === 'Knowledge') {
            return <Icon type="bulb" />
        } else if (data === 'WorkOrder') {
            return <Icon type="profile" />
        } else if (data === 'Preventive') {
            return <Icon type="tool" />
        }
    }

    const HerderOrder = () => {
        return <div style={{backgroundColor: '#F2F2F2', borderTopLeftRadius: 10, borderTopRightRadius: 10}}>
            <div style={{padding: 5}}>Order</div>
        </div>
    }

    const backCurrent = () => {
        props.setCurrent(props.current - 1)
    }

    const onCancel = () => {
        props.setIsCancel(true)
    }

    const confirmOrder = () => {
        confirm({
            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm to submit the information?</span>,
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            className: 'custom-modal-wo',
            onOk() {
                // submit
            },
            onCancel() {
                console.log('Cancelled')
            }
        })
    }

    return (
        <div style={{ padding: '2%', margin: '2% 5%', border: '1px solid #E5E5E5', borderRadius: '10px' }}>
            <Row>
                <Col span={12} style={{ borderRight: '1px solid #E5E5E5' }}>
                    <h3><b>Infomation</b></h3>
                    <br/>
                    <div style={{marginLeft: 25, lineHeight: '20px'}}>
                        <Row>
                            <Col span={6}>
                                <div><b>Company Name</b></div>
                            </Col>
                            <Col span={18}>
                                <div>{props.tempData.companyName}</div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={6}>
                                <div><b>Tax ID</b></div>
                            </Col>
                            <Col span={18}>
                                <div>{props.tempData.taxID}</div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={6}>
                                <div><b>Billing Address</b></div>
                            </Col>
                            <Col span={18}>
                                <div>{props.tempData.billingAddress}</div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={6}>
                                <div><b>Contact Name</b></div>
                            </Col>
                            <Col span={18}>
                                <div>{props.tempData.firstName} {props.tempData.lastName}</div>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={6}>
                                <div><b>Phone Number</b></div>
                            </Col>
                            <Col span={18}>
                                <div>{props.tempData.phone}</div>
                            </Col>
                        </Row>
                        <br/><br/>
                    </div>

                    <h3><b>Summary</b></h3>
                    <div><b>You choose Service</b></div>
                    <br/>
                    <div style={{marginLeft: 25}}>
                        <Row style={{ lineHeight: '30px'}}>
                            {props.tempData.chooseServices.map((data, index) => {
                                return <Col span={12} key={index}>{renderIcons(data)} {data}</Col>
                            })}
                        </Row>
                    </div>
                    <br/>
                    <div><b>You choose  Add-ons</b></div>
                    <br/>
                    <div style={{marginLeft: 25, lineHeight: '30px'}}>
                        <Row>
                            <Col span={12}>
                                <b>Storage size</b>
                            </Col>
                            <Col span={112}>
                                <b>{props.tempData.Storage} GB</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <b>Database size</b>
                            </Col>
                            <Col span={112}>
                                <b>{props.tempData.Database} GB</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <b>Go service Access</b>
                            </Col>
                            <Col span={112}>
                                <b>{props.tempData.User} User</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <b>Self-Portal Access</b>
                            </Col>
                            <Col span={112}>
                                <b>{props.tempData.People} User</b>
                            </Col>
                        </Row>
                    </div>
                    <br/>
                    <Row>
                        <Col span={8}><div><b>You choose  Add-ons</b></div></Col>
                        <Col span={16}><div><b>{moment(props.tempData.startDate).format('YYYY-MM-DD HH:mm:ss')} - {moment(props.tempData.endDate).format('YYYY-MM-DD HH:mm:ss')}  {moment(props.tempData.endDate).diff(moment(props.tempData.startDate), 'days')} Days</b></div></Col>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                        <Col span={8} style={{ color: 'red' }}><span><b>Clearing All Data to Start Over</b></span></Col>
                        <Col span={16} style={{ color: 'red' }}><span>{props.tempData.isClear === 1 ? 'Store Information' : 'Clear All Data'}</span></Col>
                    </Row>
                    <br/>
                </Col>
                <Col span={12} style={{ paddingLeft: '2%' }}>
                    <h3><b>Summary Order</b></h3>
                    <br/>
                    <Card title={HerderOrder()} className={'mycardtest'} >
                        <div className={'my-body-card'}>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>You get Storage size</Col>
                                <Col span={6}>{props.tempData.Storage !== 0 ? props.tempData.Storage : 0} &emsp; GB</Col>
                                <Col span={6}>{props.tempData.Storage !== 0 ? props.tempData.Storage * 5000 : 0} &emsp; Baht</Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>You get Database size</Col>
                                <Col span={6}>{props.tempData.Database !== 0 ? props.tempData.Database : 0} &emsp; GB</Col>
                                <Col span={6}>{props.tempData.Database !== 0 ? props.tempData.Database * 5000 : 0} &emsp; Baht</Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>You get User</Col>
                                <Col span={6}>{props.tempData.User !== 0 ? props.tempData.User : 0} &emsp; User</Col>
                                <Col span={6}>{props.tempData.User !== 0 ? props.tempData.User * 1000 : 0} &emsp; Baht</Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={12}>You get People</Col>
                                <Col span={6}>{props.tempData.People !== 0 ? props.tempData.People : 0} &emsp; People</Col>
                                <Col span={6}>{props.tempData.People !== 0 ? props.tempData.People * 1000 : 0} &emsp; Baht</Col>
                            </Row>
                        </div>
                    </Card>
                    <Row>
                        <Col span={24} style={{padding: 10, textAlign: 'end'}}>
                            <span>Total</span>&emsp;
                            <span style={{backgroundColor: '#F2F2F2', padding: 5, marginRight: 25}}>{total} &emsp; Baht</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{ textAlign: 'end' }}>
                <Button type="primary" onClick={backCurrent}>
                    <Icon type="left" /> Back
                </Button>
                <Button onClick={onCancel} style={{ marginRight: 5, marginLeft: 5 }}>
                            Cancel
                </Button>
                <Button type="primary" onClick={confirmOrder}>
                            Confirm Order
                </Button>
            </div>
        </div>
    )
}

const MySummaryOrder = Form.create<Props>({ name: 'SummaryOrder' })(SummaryOrder)
export default MySummaryOrder
