import { Dispatch } from 'redux'
import { checkRedirect } from '../../common-misc'
import { decryptBody } from '../../common-misc/encryptBody'
import { axiosGet } from '../rest'
import { Impact } from './model'
import { GetAllImpactPrioritySuccessEvent } from './state-event'

export const getAllImpactPriorty = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Impact[]>('/api/priority-levels/priorities?types=Impact')
            dispatch(GetAllImpactPrioritySuccessEvent.build(decryptBody(response.data.data!!)?.impact))
            return response.status
        } catch (err: any) {
            console.log(err.response)
            checkRedirect(err.response.status)
            throw err
        }
    }
}
