import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, TimePicker} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import {updateIncidentStatus} from './service'
import {IncidentWithSlaCalculations, TicketStatus} from './model'
import moment from 'moment'
import {getTicketStatus} from '../status'

interface IProps extends FormComponentProps {
    targetId?: number,
    currentStatus: (string) => void,
    statuses: TicketStatus[],
    disablePending: boolean,
    ticketNumber: string
    incidentData: IncidentWithSlaCalculations
    ticketType: string
    setCurrentStatus?: Function
    setIsSpinning?: Function
}

const ModalPendingForm: React.FC<IProps> = (props: IProps) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const { TextArea } = Input
    const [isVisible, setIsVisible] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (props.incidentData.pendingReason) {
            setFieldsValue({
                datePending: moment(props.incidentData.pendingUntil),
                timePending: moment(props.incidentData.pendingUntil),
                PendingReason: props.incidentData.pendingReason.trim()
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentData])

    const handlePendingOk = (e: any) => {
        e.preventDefault()

        props.form.validateFields((err: any, fieldsValue: any) => {
            if (!err) {
                setIsLoading(true)
                if (props.setIsSpinning) {
                    props.setIsSpinning(true)
                }
                fieldsValue.PendingReason = fieldsValue.PendingReason ? fieldsValue.PendingReason.trim() : undefined
                // Should format date value before submit.
                const values = {
                    ...fieldsValue,
                    // eslint-disable-next-line quote-props
                    'datePending': fieldsValue.datePending.format('YYYY-MM-DD'),
                    // eslint-disable-next-line quote-props
                    'timePending': fieldsValue.timePending.format('HH:mm:ss')
                }
                const newStatus: any = 'Pending'
                const dueDatePending = values.datePending + ' ' + values.timePending + '.000'
                const reasonOfPending = values.PendingReason
                // eslint-disable-next-line prefer-const
                let ticketStatus = getTicketStatus(props.statuses, props.ticketType, newStatus)
                if (props.setCurrentStatus) {
                    props.setCurrentStatus(ticketStatus.status)
                }
                updateIncidentStatus(props.targetId!!, ticketStatus, reasonOfPending, dueDatePending)
                    .then(() => {
                        props.currentStatus(newStatus)
                        setIsVisible(false)
                        setIsLoading(false)
                        if (props.setIsSpinning) {
                            props.setIsSpinning(false)
                        }
                    })
                    .catch((err) => {
                        setIsLoading(false)
                        if (props.setIsSpinning) {
                            props.setIsSpinning(false)
                        }
                        message.error(err.response.data.message)
                    })
            }
        })
    }
    function setTitle(): string {
        return `Pending to Date (${props.ticketNumber})`
    }
    return (
        <>
            <Modal
                visible={isVisible}
                title={setTitle()}
                onOk={handlePendingOk}
                onCancel={() => setIsVisible(false)}
                footer={[
                    <Button key="back" onClick={() => setIsVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handlePendingOk} loading={isLoading}
                        disabled={props.disablePending}
                    >
                        Save
                    </Button>
                ]}
            >
                <Form onSubmit={handlePendingOk}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Date">
                                {getFieldDecorator('datePending', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: 'Please select Date!'
                                        }
                                    ]
                                })(<DatePicker disabledDate={current => {
                                    return current!! < moment().startOf('day')
                                }}/>)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Time">
                                {getFieldDecorator('timePending', {
                                    rules: [
                                        {
                                            type: 'object',
                                            required: true,
                                            message: 'Please select time!'
                                        }
                                    ]
                                })(<TimePicker />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item label="Pending Reason">
                            {getFieldDecorator('PendingReason', {
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Please input pending reason!'
                                    }
                                ]
                            })(<TextArea style={{ marginTop: '10px' }} rows={4} maxLength={255}/>)}
                        </Form.Item>
                    </Row>
                </Form>

            </Modal>
        </>
    )
}

const ModalPending = Form.create<IProps>({})(ModalPendingForm)
export default ModalPending
