import {Dispatch} from 'redux'
import {ResponseDetails} from '../../common-rest'
import {GetAllSupportTeamSuccessEvent} from './state-event'
import {SupportTeam} from './model'
import {axiosFactory as supportTeamAxiosFactory} from '../../authorization-module/rest'
import {decryptBody} from '../../common-misc/encryptBody'
import { GetAllSupportTeamWithPermissionSuccessEvent } from './state-event-with-permission'
import { GetAllSupportTeamWithDeleteGroupSuccessEvent } from './state-event-with-delete-group'

export const getAllSupportTeam = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await supportTeamAxiosFactory().get<ResponseDetails<SupportTeam[]>>('/api/groups/active-teams')
            if (response.data.data) {
                dispatch(GetAllSupportTeamSuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err: any) {
            console.log(err.response)
            // checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const getAllSupportTeamByPermission = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await supportTeamAxiosFactory().get<ResponseDetails<SupportTeam[]>>('/api/groups/assignees')
            if (response.data.data) {
                dispatch(GetAllSupportTeamWithPermissionSuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err: any) {
            console.log(err.response)
            // checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const getAllEmailGroupByCurrentUser = async () => {
    const response = await supportTeamAxiosFactory().get('/api/groups/emails')
    return response.data
}

export const getAllSupportTeamWithDeletionGroup = () => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    showAll: true
                }
            }
            const response = await supportTeamAxiosFactory().get<ResponseDetails<SupportTeam[]>>('/api/groups/active-teams', config)
            if (response.data.data) {
                dispatch(GetAllSupportTeamWithDeleteGroupSuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err: any) {
            console.log(err.response)
            // checkRedirect(err?.response?.status)
            throw err
        }
    }
}
