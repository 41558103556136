/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'
import {Table} from 'antd'
import {connect} from 'react-redux'
import {StoreState} from '../store'
import {getSatisfactionReport} from './service'
import { checkRolesState, RoleType } from '../authorization-module/permissions'
import { Link } from 'react-router-dom'
import { waitingGetSessions } from '../guard/route'

const mapStateToProps = (state: StoreState) => {
    return {
        SatisfactionReportState: state.satisfactionReport
    }
}

interface DispatchProps {
    getSatisfactionReport: (created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    create: string,
    start?: string,
    end?: string,
    getChangeTable?: (sortBy, orderBy, skip, limit) => void
}

type Props = Param & StateProps & DispatchProps

const TableSatisfaction: React.FC<Props> = (props: Props) => {
    const [page, setPage] = useState<number>(1)
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('ticketNumber')
    const [pageSize, setPageSize] = useState<number>(10)

    useEffect(() => {
        props.getSatisfactionReport(props.create, props.start, props.end, sortBy, orderBy, (page - 1) * pageSize, pageSize)
        if (props.getChangeTable) {
            props.getChangeTable(sortBy, orderBy, 0, props.SatisfactionReportState.total)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, page, orderBy, sortBy, props.create, props.start, props.end, props.getSatisfactionReport])

    useEffect(() => {
        setPage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, props.create])

    const column = [
        {
            title: 'Ticket Number',
            dataIndex: 'ticketNumber',
            sorter: true,
            render: (data, record) => (<>
                {checkRolesState(RoleType.Incident, 'ViewIncident')
                    ? <Link to={`/IncidentDetailsView/${record.incidentId}`} target="_blank" onClick={() => waitingGetSessions()}>{data}</Link>
                    : <>{data}</>}
            </>)
        },
        {
            title: 'Create datetime',
            dataIndex: 'createDate',
            sorter: true,
            render: (row) => {
                if (row) {
                    const splitDate = (row as string).split('T')
                    if (splitDate) {
                        return (
                            <>
                                <span>{splitDate[0]} {(splitDate[1]).substring(0, 8)}</span>
                            </>
                        )
                    }
                }
            }
        },
        {
            title: 'Assignment group',
            dataIndex: 'assignmentGroup',
            sorter: true
        },
        {
            title: 'Assigned to',
            dataIndex: 'assignedTo',
            sorter: true
        },
        {
            title: 'Satisfaction Done',
            dataIndex: 'satisfactionDone',
            sorter: true,
            render: (row) => {
                if (row) {
                    if (row as string === 'true') {
                        return <><span>Yes</span></>
                    } else {
                        return <><span>No</span></>
                    }
                }
            }
        },
        {
            title: 'Satisfaction datetime',
            dataIndex: 'satisfactionDate',
            sorter: true,
            render: (row) => {
                if (row) {
                    const splitDate = (row as string).split('T')
                    if (splitDate) {
                        return (
                            <>
                                <span>{splitDate[0]} {(splitDate[1]).substring(0, 8)}</span>
                            </>
                        )
                    }
                }
            }
        },
        {
            title: 'Evaluate by (Requester)',
            dataIndex: 'requester',
            sorter: true
        },
        {
            title: 'Satisfaction Result',
            dataIndex: 'satisfactionResult',
            sorter: true
        }
    ]

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSortBy(sorter.field)
            if (sorter.order === 'ascend') {
                setOrderBy('ASC')
            } else if (sorter.order === 'descend') {
                setOrderBy('DESC')
            } else {
                setSortBy('ticketNumber')
                setOrderBy('DESC')
            }
        }
        setPageSize(pagination.pageSize)
    }
    return (
        <>
            <Table
                columns={column}
                dataSource={props.SatisfactionReportState.data}
                rowKey='id'
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: props.SatisfactionReportState.total,
                    onChange: (event) => { handleOnClickPageNumber(event) },
                    showSizeChanger: true
                }}
                onChange={handleChangeTable}
                style={{width: 'auto'}}
            />
        </>
    )
}

export default connect(mapStateToProps, {
    getSatisfactionReport
})(TableSatisfaction)
