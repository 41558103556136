import { axiosGet, axiosDelete, axiosPost, axiosPatch } from './rest'
import { Asset, AssetForWO} from './model'
import { Dispatch } from 'redux'
import {
    AddAssetSuccessEvent,
    DeleteAssetSuccessEvent,
    GetAllAssetSuccessEvent,
    UpdateAssetSuccessEvent
} from './state-event'
import { decryptBody } from '../../../common-misc/encryptBody'

// export async function getLocation(value: string) {
//     try {
//         const res = await axiosGet<any>(`/api/assets/search?location=${value}`)
//         return decryptBody(res.data.data!!)
//     } catch (error: any) {
//         console.log(error)
//         throw error
//     }
// }

// export async function addDataTicketAffectedAsset(data) {
//     try {
//         const res = await axiosPost<any>('/api/ticketAffectedAsset', data)
//         return decryptBody(res.data.data!!)
//     } catch (error: any) {
//         console.log(error)
//         throw error
//     }
// }

export async function searchAssetSerial(Serial: string) {
    try {
        const res = await axiosGet<any>(`/api/assets/serial/${Serial}`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function getAssetById(id: string): Promise<Asset> {
    try {
        const res = await axiosGet<Asset>(`/api/assets/${id}`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const getAllAsset = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Asset[]>('/api/assets')
            dispatch(GetAllAssetSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const addAsset = (businessHour: Asset) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<Asset>('/api/assets', businessHour)
            dispatch(AddAssetSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteAsset = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/asset${id}`)
            dispatch(DeleteAssetSuccessEvent.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

// edit function
export const updateAsset = (patch: Asset) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            // delete patch.id
            const response = await axiosPatch<Asset>(`/api/assets/${id}`, patch)
            dispatch(UpdateAssetSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export async function addAssetWithWO(asset): Promise<any> {
    try {
        const response = await axiosPost<AssetForWO>('/api/ticketAffectedAsset/create', asset)
        // dispatch(AddAssetSuccessEvent.build(response.data.data!!))
        return decryptBody(response)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function getAssetForWOByWOId(id: string): Promise<AssetForWO[]> {
    try {
        const res = await axiosGet<AssetForWO[]>(`/api/ticketAffectedAsset/ticketId/${id}`)
        if (res.data.data) {
            return decryptBody(res.data.data!! || [])
        } else {
            return []
        }
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function DeleteAssetForWOByTicketRelatedAssetId(id: string): Promise<any> {
    try {
        const res = await axiosDelete<any>(`/api/ticketAffectedAsset/${id}`)
        return res.status
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const getAssetRelatedWo = async (id: string) => {
    try {
        const response = await axiosGet(`/api/related-wo/${id}/assets`)
        if (response.data.data) {
            return decryptBody(response.data.data!! || [])
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const searchAssetRelatedWo = async (woId?: string, search?: string, sortBy?: string, orderBy?: string, skip?: number, limit?: number) => {
    try {
        const params = {
            id: woId,
            value: search || null,
            sort: `${sortBy},${orderBy}`,
            page: skip,
            size: limit
        }
        const response = await axiosGet('/api/related-wo/search', {params})
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

// export const getAssetForWosignform = async (woId?: string, jobNo?: string) => {
//     try {
//         const response = await axiosPost(`/api/assets/jobRelatedAsset/assetRelateJob?woId=${woId}&jobNo=${jobNo}`)
//         return decryptBody(response.data.data)
//     } catch (err: any) {
//         console.log(err)
//         throw err
//     }
// }
