import React, { useState, useEffect } from 'react'
import { Button, Card, Col, message, Modal, Input, Row, Table, Form } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { convertCamelCaseToSentenceCase } from '../util'
import { ApproveWorkOrder, ApproveWorkOrderList, ApproveWorkOrderStatus } from './model'
import { updateNewWorkOrderWithApprovalStatus } from './service'
import { getAllWorkOrderForApproveOrRejectJob } from '../../msp-wo/wo-management'
import { Link } from 'react-router-dom'
import { waitingGetSessions } from '../../guard/route'

// const pageSize = require('../knowledge/config.json').pageSize

interface Params {
    workorderList?: ApproveWorkOrderList
    dataSourceWorkOrder?: ApproveWorkOrder[]
    total?: number
}

type Props = Params & FormComponentProps

const ApprovalWO: React.FC<Props> = (props: Props) => {
    const [workOrderList] = useState<ApproveWorkOrderList>(props.workorderList!!)
    const [isLoadForSubmitModalButton, setisLoadForSubmitModalButton] = useState<boolean>(false)
    const [isVisibleReasonModal, setIsVisibleReasonModal] = useState<boolean>(false)
    const [onLoading, setOnLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [workorderId, setWorkorderId] = useState<number>(0)
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
    const [isModalRequiredField, setIsModalRequiredField] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<ApproveWorkOrder[]>(props.dataSourceWorkOrder!!)
    const pageSize = 10

    useEffect(() => {
        if (props.dataSourceWorkOrder) {
            setDataSource(props.dataSourceWorkOrder)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataSourceWorkOrder])

    useEffect(() => {
        if (onLoading) {
            // getAllWorkOrderWithApprovalStatus(sessionStorage.getItem('username') || ' ', ApproveWorkOrderStatus.NotActioned, (currentPage - 1), pageSize).then((workorder) => {
            //     setWorkOrderList(workorder)
            // }).catch((error) => {
            //     message.error(error.message)
            // }).finally(() => {
            //     setOnLoading(false)
            // })
            getAllWorkOrderForApproveOrRejectJob(currentPage, pageSize).then((res) => {
                setDataSource(res.data)
            }).catch((error) => {
                message.error(error.message)
            }).finally(() => {
                setOnLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onLoading, currentPage])

    const columns = [{
        title: 'Job Number',
        dataIndex: '',
        key: '',
        width: '15%',
        render: (approvalworkorder: ApproveWorkOrder) => {
            return (
                <Link to={`/WoFormEdit/${approvalworkorder.woId}`} target="_blank" onClick={() => waitingGetSessions()}>{approvalworkorder.itemNumber}</Link>
            )
        }
    },
    {
        title: 'Topic',
        dataIndex: 'woTopic',
        key: 'woTopic',
        width: '45%'
    },
    {
        title: 'Job Status',
        dataIndex: 'awaitingApproval',
        key: 'awaitingApproval',
        width: '15%',
        // eslint-disable-next-line react/display-name
        render: (status: string) => {
            return (
                <span>{convertCamelCaseToSentenceCase(status || '')}</span>
            )
        }
    },
    {
        title: '',
        key: 'action',
        width: '20%',
        // eslint-disable-next-line react/display-name
        render: (workorder: ApproveWorkOrder) => {
            if (workorder.status === ApproveWorkOrderStatus.AwaitingApproval) {
                return (
                    <Row gutter={[16, 16]}>
                        <span className='approval-button-group'>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="danger" id={workorder.id?.toString()} onClick={(event) => { onShowModal(event, true) }}>Reject</Button>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="primary" id={workorder.id?.toString()} onClick={(event) => { onShowModal(event, false) }}>Approve</Button>
                            </Col>
                        </span>
                    </Row>
                )
            } else if (workorder.status === ApproveWorkOrderStatus.Approved) {
                return (
                    <span>You have Successfully Approved.</span>
                )
            } else if (workorder.status === ApproveWorkOrderStatus.Rejected) {
                return (
                    <span>You have Successfully Rejected.</span>
                )
            }
        }
    }]

    const onShowModal = (event: React.MouseEvent<HTMLElement, MouseEvent>, requirefield: boolean) => {
        setWorkorderId(Number(event.currentTarget.id))
        setIsVisibleReasonModal(true)
        setIsModalRequiredField(requirefield)
    }

    const onSubmitReasonModal = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        validateFields((error, row) => {
            if (error) {
                return
            }
            setisLoadForSubmitModalButton(true)
            updateNewWorkOrderWithApprovalStatus(isModalRequiredField ? 'reject' : 'approve', workorderId, row.ApprovalworkorderReason).then(() => {
                message.success(`You have Successfully ${isModalRequiredField ? 'Reject' : 'Approve'} the data.`)
                updateApproveWorkorderStatus(workorderId, isModalRequiredField ? ApproveWorkOrderStatus.Rejected : ApproveWorkOrderStatus.Approved)
                setFieldsValue({ ApprovalworkorderReason: undefined })
            }).catch(() => {
                message.error('Error Can not Update Data')
            }).finally(() => {
                setIsVisibleReasonModal(false)
                setisLoadForSubmitModalButton(false)
            })
        })
    }

    const updateApproveWorkorderStatus = (workorderId: number, workorderStatus: string) => {
        const newContent = dataSource?.map((workorder) => {
            if (workorder.id === workorderId) {
                if (workorderStatus === ApproveWorkOrderStatus.Approved) {
                    workorder.status = ApproveWorkOrderStatus.Approved
                    workorder.awaitingApproval = 'Approved'
                } else {
                    workorder.status = ApproveWorkOrderStatus.Rejected
                    workorder.awaitingApproval = 'Rejected'
                }
                return workorder
            } else {
                return workorder
            }
        })
        let newApprovalworkorder = dataSource
        newApprovalworkorder = newContent
        setDataSource(newApprovalworkorder)
    }

    const handleOnClickPageNumber = (page: number) => {
        setCurrentPage(page)
        setOnLoading(true)
    }

    const onCancelReasonModal = () => {
        setIsVisibleReasonModal(false)
        resetFields()
    }

    return (
        <>
            <Col span={18} xs={24} sm={24} md={24} lg={24} xl={18}>
                <Card className='card-height-approval-default'>
                    <Row>
                        <h2 className="main-title">
                            Job ({props.total || workOrderList?.total || '0'})
                        </h2>
                    </Row>
                    <div style={{ overflowX: 'auto' }}>
                        <Row>
                            <Table
                                className="table-configherder"
                                dataSource={dataSource || []}
                                columns={columns}
                                rowKey="id"
                                style={{ minWidth: 500 }}
                                loading={onLoading}
                                pagination={{
                                    pageSize: pageSize,
                                    current: currentPage,
                                    total: workOrderList?.total || props.total || 0,
                                    onChange: (event) => { handleOnClickPageNumber(event) }
                                }}
                            />
                        </Row>
                    </div>
                </Card>
                <Modal
                    title={'Reason'}
                    visible={isVisibleReasonModal}
                    onCancel={onCancelReasonModal}
                    footer={[
                        <Button key="back" onClick={onCancelReasonModal}>
                            Cancel
                        </Button>,
                        <Button key="submit" loading={isLoadForSubmitModalButton} onClick={onSubmitReasonModal} type="primary" >
                            Submit
                        </Button>
                    ]}>
                    <Form>
                        <Form.Item label='Reason' >
                            {getFieldDecorator('ApprovalworkorderReason', {
                                rules: [{ required: isModalRequiredField, message: 'Reject reason is required' },
                                    { min: isModalRequiredField ? 3 : 0, message: 'Enter more than 3 characters' }]
                            })(
                                <Input.TextArea rows={4} placeholder="please enter description" />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Col>
        </>
    )
}

const ApprovalWOListForm = Form.create<Props>()(ApprovalWO)

export default ApprovalWOListForm
