import { Dispatch } from 'redux'
import { Incident, IncidentFilterReport, Satisfaction, TicketStatus, searchTicketRelated } from './model'
import { IncidentFilter } from '../incident-view'
import {
    GetAllIncidentSuccessEvent,
    GetAllSatisfactionSuccessEvent,
    IncidentAddedEvent,
    IncidentUpdatedEvent,
    SatisfactionAddedEvent,
    SearchIncidentsSuccessEvent
} from './state-event'
import { IncidentTableTotalDataEvent } from '../../store/state-event'
import { axiosDelete, axiosGet, axiosPatch, axiosPatchWithOutBearer, axiosPost } from '../rest'
import { IncidentWebSocketChannel, SLAWebSocketChannel } from './web-socket-channel'
import { People } from '../../authorization-module/people/model'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'

interface IncidentSearchResult {
    incidents: Incident[],
    total: number
    ticketsSearch: Incident[]
}

export const searchIncidents = (criteria: IncidentFilter, sortBy: string, orderBy: string, skip?: number, limit?: number) => {
    return async (dispatch: Dispatch) => {
        try {
            sortBy = sortBy === 'createdDate' ? 'createdDatetime' : sortBy
            criteria.sortBy = sortBy
            criteria.orderBy = orderBy
            const body = {
                filter: criteria,
                skip: skip,
                limit: limit
            }
            const encrypted = encryptBody(JSON.stringify(body))
            const response = await axiosPost<IncidentSearchResult>('/api/incidents/pagination', encrypted, herderPostPatch)
            const searchResult: IncidentSearchResult = decryptBody(response.data.data!!)
            console.log(searchResult.ticketsSearch)
            dispatch(IncidentTableTotalDataEvent.build(searchResult.total))
            dispatch(SearchIncidentsSuccessEvent.build(searchResult.ticketsSearch))
            IncidentWebSocketChannel.subscribeToIncidentIds(searchResult.ticketsSearch.map((it) => it.id!!))
            SLAWebSocketChannel.subscribeToIncidentIds(searchResult.ticketsSearch.map((it) => it.id!!))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export async function getIncidentById(id: number): Promise<Incident> {
    try {
        const res = await axiosGet<Incident>(`/api/incidents/${id}`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

// export async function getPrefilledNewIncident(): Promise<Incident> {
//     try {
//         const res = await axiosGet<Incident>('/api/incidents/getPrefilledNewIncident')
//         return decryptBody(res.data.data!!)
//     } catch (error: any) {
//         console.log(error)
//         checkRedirect(error.response.status)
//         throw error
//     }
// }

export const addIncident = (incident: Incident) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(incident))
            const response = await axiosPost<Incident>('/api/incidents', encryptData, herderPostPatch)
            console.log(decryptBody(response.data.data!!))
            dispatch(IncidentAddedEvent.build(decryptBody(response.data.data!!)))
            return decryptBody(response.data.data!!)
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const addSatisfaction = (satisfaction: Satisfaction) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(satisfaction))
            const response = await axiosPost<Satisfaction>('/api/satisfaction', encryptData, herderPostPatch)
            dispatch(SatisfactionAddedEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}
export const updateIncident = (patch: Incident) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPatch<Incident>(`/api/incidents/${id}`, encryptData, herderPostPatch)
            dispatch(IncidentUpdatedEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export async function updateIncidentStatus(
    id: number, ticketStatus: TicketStatus, pendingReason?: string, pendingUntil?: string
): Promise<Incident> {
    try {
        const encryptData = encryptBody(JSON.stringify({
            ticketStatus: ticketStatus,
            pendingReason,
            pendingUntil
        }))
        const res = await axiosPatch<Incident>(`/api/incidents/${id}`, encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function markIncidentViewed(id: any, dispatch): Promise<Incident> {
    try {
        const encryptData = encryptBody(JSON.stringify({ viewed: true }))
        const res = await axiosPatch<Incident>(`/api/incidents/${id}`, encryptData, herderPostPatch)
        dispatch(IncidentUpdatedEvent.build(decryptBody(res.data.data!!)))
        return res.data.data!!
    } catch (error: any) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function markSatisfactionDone(id: any): Promise<Incident> {
    try {
        const encryptData = encryptBody(JSON.stringify({ satisfactionDone: 1 }))
        const res = await axiosPatch<Incident>(`/api/incidents/${id}`, encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}
export async function setSatisfaction(id: any, data: string): Promise<any> {
    try {
        const res = await axiosPatchWithOutBearer<Satisfaction>(`/api/satisfaction/${id}/mark-done`, data, herderPostPatch)
        return res
    } catch (error: any) {
        console.log(error.response.data.message)
        checkRedirect(error.response.status)
        throw error.response.data.message
    }
}

export async function markSatisfactionNotDone(id: any): Promise<Incident> {
    try {
        const encryptData = encryptBody(JSON.stringify({ satisfactionDone: 0 }))
        const res = await axiosPatch<Incident>(`/api/incidents/${id}`, encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export const getAllIncident = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Incident[]>('/api/incidents')
            if (response.data.data) {
                dispatch(GetAllIncidentSuccessEvent.build(decryptBody(response.data.data)))
            }
            return response.status
        } catch (err: any) {
            console.log(err.response)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const getAllSatisfaction = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Satisfaction[]>('/api/satisfaction')
            dispatch(GetAllSatisfactionSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const sendSurvey = async (idUser: number, incidentId: number) => {
    try {
        const response = await axiosGet<any>(`/api/satisfaction/survey/${incidentId}/${idUser}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export async function addRequester(Requester: People): Promise<number> {
    try {
        const encryptData = encryptBody(JSON.stringify(Requester))
        const response = await axiosPost<People>('/api/people', encryptData, herderPostPatch)
        return response.status
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export async function getRequester() {
    try {
        const response = await axiosGet<any>('/api/people')
        return response
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const exportIncident = async (criteria: IncidentFilter | undefined, sortBy: string, orderBy: string) => {
    try {
        const body = {
            sortBy: sortBy,
            orderBy: orderBy,
            filter: criteria
        }
        const response = await axiosPost<IncidentFilterReport>('/api/incidents/exportIncident', encryptBody(JSON.stringify(body)), herderPostPatch)
        return decryptBody(response.data.data)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const GetAllTicketRelatedTicketIncView = async (ticketId: number) => {
    try {
        const response = await axiosGet(`/api/related-tickets/${ticketId}/child`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const SearchTicketRelatedTicketIncView = async (incidentsFilter?: searchTicketRelated, sortBy?: string, orderBy?: string, skip?: number, limit?: number, onSearch?: string) => {
    try {
        const body = {
            sortBy: sortBy,
            orderBy: orderBy,
            skip: skip,
            limit: limit,
            ticketNumber: onSearch,
            filter: incidentsFilter
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<Incident>('/api/related-tickets/pagination', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const CreateTicketRelatedTicketIncView = async (ticketId: number, ticketNumber: number[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(ticketNumber))
        const response = await axiosPost(`/api/related-tickets/${ticketId}/child`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const DeletedTicketRelatedTicketIncView = async (ticketId: number, ticketNumber: number | undefined) => {
    try {
        const response = await axiosDelete(`/api/related-tickets/${ticketId}/child/${ticketNumber}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getAllTicketRelatedWo = async (woId: number) => {
    try {
        const response = await axiosGet(`/api/related-tickets/incident-relate-wo/${woId}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const CreateRelatedKnowledgeIncidentView = async (IncidentId: number, KnowledgeData: {id:number, ticketNumber:string}[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(KnowledgeData))
        const response = await axiosPost(`/api/related-knowledge/${IncidentId}/child`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const GetAllKnowledgeRelatedInIncidentView = async (ticketId: number) => {
    try {
        const response = await axiosGet(`/api/related-knowledge/${ticketId}/knowledge`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const DeletedKnowledgeRelatedIncidentView = async (TicketId: number | undefined, kmNumber:string) => {
    try {
        const response = await axiosDelete(`/api/related-knowledge/${TicketId}/child/${kmNumber}`)
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const GetTicketRelateToShowInKm = async(kmId:number) => {
    try {
        const response = await axiosGet(`/api/related-knowledge/${kmId}/tickets`)
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const CreateTemplete = async (data) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost('/api/templates', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const searchAllTemplete = async() => {
    try {
        const response = await axiosGet('/api/templates/pagination')
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

// export const searchTempleteByID = async(Id:number) => {
//     try {
//         const response = await axiosGet(`/api/templates/search/${Id}`)
//         // @ts-ignore
//         return decryptBody(response.data.data!!)
//     } catch (err: any) {
//         console.log(err)
//         throw err
//     }
// }

// export const DeletedTempleteByID = async (id) => {
//     try {
//         const response = await axiosDelete(`/api/templates/delete/${id}`)
//         // @ts-ignore
//         return decryptBody(response.data.data!!)
//     } catch (err: any) {
//         console.log(err)
//         throw err
//     }
// }

// export const searchTempleteByName = async(name:string) => {
//     try {
//         const response = await axiosGet(`/api/templates/pagination?search=${name}`)
//         // @ts-ignore
//         return decryptBody(response.data.data!!)
//     } catch (err: any) {
//         console.log(err)
//         throw err
//     }
// }

export const searchAllRelateTicketById = async(id:string) => {
    try {
        const response = await axiosGet(`/api/incidents/${id}/tag-related`)
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function getCountIncident(): Promise<number> {
    try {
        const res = await axiosGet<number>('/api/priorities/total-tickets')
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export async function sentEmailSatisfactionNotDone(created?: string, startDate?: string, endDate?: string) {
    try {
        // const config = {
        //     params: {
        //         createdScope: created === 'anytime' ? '' : created,
        //         startDate: startDate || null,
        //         endDate: endDate || null
        //     }
        // }
        const params = {
            createdScope: created === 'anytime' ? '' : created,
            startDate: startDate || null,
            endDate: endDate || null
        }
        const response = await axiosPost('/api/satisfaction/surveys-incomplete/send', params)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
