import { Dispatch } from 'redux'
import { checkRedirect, herderPostPatch } from '../../../common-misc'
import { encryptBody, decryptBody } from '../../../common-misc/encryptBody'
import { CustomFiledWithWorkFlow } from '../../../configurable-fields'
import { AddCustomFiledWithWorkFlowSuccessEvent, DeleteCustomFiledWithWorkFlowSuccessEvent, GetAllCustomFiledWithWorkFlowSuccessEvent, UpdateCustomFiledWithWorkFlowSuccessEvent } from '../../../configurable-fields/state-event-custom-workflow'
import { axiosPost, axiosGet, axiosPatch, axiosDelete } from '../../../msp-wo/rest'
import { ResponseContentDetails } from '../../Catalog'
import { WorkFlow } from './Model'
import { GetAllWorkFlowSuccessEvent, DeleteWorkFlowSuccessEvent, UpdateWorkFlowCurrentPageSuccessEvent, UpdateWorkFlowTotalPageSuccessEvent, AddWorkFlowSuccessEvent, UpdateWorkFlowSuccessEvent } from './state-event'

export const searchWorkFlow = (page: number, pageSize: number, sort?: string, order?: string, search?: string, activated?: boolean[]) => {
    return async (dispatch: Dispatch) => {
        try {
            // page = page - 1
            // const response = await axiosGet<ResponseContentDetails<WorkFlow[]>>(`/api/workflows/search?filter=${search || ''}&page=${page}&size=${pageSize}&activated=${activated || ''}&sort=${sort || 'createdDatetime'},${order || 'asc'}`)
            console.log(activated)
            let dataFilter: { activate?: boolean, deactivate?: boolean } = {}
            if (activated && activated?.length === 1) {
                if (activated[0] === true) {
                    dataFilter = { activate: true }
                } else {
                    dataFilter = { deactivate: false }
                }
            } else if (activated && activated?.length === 2) {
                dataFilter = { activate: true, deactivate: false }
            }
            const body = {
                search: search,
                page: page,
                pageSize: pageSize || 10,
                activated: dataFilter,
                sortBy: sort,
                orderBy: order
            }
            const encryptData = encryptBody(JSON.stringify(body))
            const response = await axiosPost<ResponseContentDetails<WorkFlow[]>>('/api/workflows/search', encryptData, herderPostPatch)
            const dataDecrypt: ResponseContentDetails<WorkFlow[]> = decryptBody(response.data.data)
            dispatch(GetAllWorkFlowSuccessEvent.build(dataDecrypt.data || []))
            dispatch(UpdateWorkFlowCurrentPageSuccessEvent.build(dataDecrypt.number!! + 1 || page))
            dispatch(UpdateWorkFlowTotalPageSuccessEvent.build(dataDecrypt.totalElements!! || dataDecrypt.total))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const addWorkFlow = (workFlow: WorkFlow) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(workFlow))
            const response = await axiosPost<WorkFlow>('/api/workflows', encryptData, herderPostPatch)
            dispatch(AddWorkFlowSuccessEvent.build(decryptBody(response.data.data)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const getWorkFlowById = async (id: number) => {
    try {
        const response = await axiosGet<WorkFlow>(`/api/workflows/${id}`)
        // return response.data.data || []
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const getWorkFlowByIdForWOPreview = async (workflowId: number, date: string) => {
    try {
        const params = {
            id: workflowId,
            timestamp: date
        }
        const response = await axiosGet<WorkFlow>('/api/workflow-histories', {params})
        // return response.data.data || []
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const getAllWorkFlow = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<WorkFlow[]>('/api/workflows')
            // return decryptBody(response.data.data || [])
            // dispatch(GetAllWorkFlowSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const updateWorkFlow = (id: number, workFlow: WorkFlow) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(workFlow))
            const response = await axiosPatch<WorkFlow>(`/api/workflows/${id}`, encryptData, herderPostPatch)
            dispatch(UpdateWorkFlowSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const deleteWorkFlow = (id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<WorkFlow>(`/api/workflows/${id}`)
            dispatch(DeleteWorkFlowSuccessEvent.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const getAllWorkflowNoRedux = async () => {
    try {
        const response = await axiosGet<WorkFlow[]>('/api/workflows')
        if (response.data.data) {
            return decryptBody(response.data.data)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const getAllCustomFiledWithWorkFlow = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<CustomFiledWithWorkFlow>('/api/custom-fields')
            dispatch(GetAllCustomFiledWithWorkFlowSuccessEvent.build(decryptBody(response.data.data!!)))
            // console.log(decryptBody(response.data.data!!))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const addCustomFiledWithWorkFlow = (customField: CustomFiledWithWorkFlow) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(customField))
            const response = await axiosPost<CustomFiledWithWorkFlow>('/api/custom-fields', encryptData, herderPostPatch)
            console.log('decryptBody(response.data.data!!)', decryptBody(response.data.data!!)[0])
            if (decryptBody(response.data.data!!)[0]) {
                dispatch(AddCustomFiledWithWorkFlowSuccessEvent.build(decryptBody(response.data.data!!)[0]))
            } else {
                dispatch(AddCustomFiledWithWorkFlowSuccessEvent.build(customField))
            }

            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const updateCustomFiledWithWorkFlow = (id:number, patch: CustomFiledWithWorkFlow) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPatch<CustomFiledWithWorkFlow>(`/api/custom-fields/${id}/settings`, encryptData, herderPostPatch)
            dispatch(UpdateCustomFiledWithWorkFlowSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const deleteCustomFiledWithWorkFlow = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/custom-fields/${id}`)
            dispatch(DeleteCustomFiledWithWorkFlowSuccessEvent.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

// export async function getAllFieldsDetailWithWorkFlow(): Promise<CustomFiledWithWorkFlow> {
//     try {
//         const res = await axiosGet<CustomFiledWithWorkFlow>('/api/custom-fields/fieldsDetail')
//         return res.data.data!!
//     } catch (err: any) {
//         console.log(err)
//         checkRedirect(err.response.status)
//         throw err
//     }
// }

export const getAllWorkflowSelectData = async (incidentId?: number) => {
    try {
        const params = {
            incidentId: incidentId
        }
        const response = await axiosGet<WorkFlow[]>('/api/workflows/related-workflow', {params})
        if (response.data.data) {
            return decryptBody(response.data.data)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}
