/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form, Icon, Input, Modal, Select, Spin, Switch, Table, Tooltip, Row, Col, Button, InputNumber, DatePicker } from 'antd'
import { DndProvider } from 'react-dnd'
import { DragableBodyRow, RNDContext } from '../../../../configurable-fields/DragableBodyRow'
import { CustomFiled, CustomFiledAsset, mySelectOption } from '../../../../configurable-fields'
import update from 'immutability-helper'
import '../../CustomStyle.css'
import { useParams } from 'react-router-dom'
import { WorkFlow } from '../../../workflow/Service/Model'
import moment from 'moment'
// import moment from 'moment'
import { findCustomFieldWOByWorkflowID } from '../../../../msp-wo/wo-management'

interface Params {
    form: any
    data?: CustomFiled[]
    setCustomFieldsForUsed: Function
    customFieldsForUsed: CustomFiled[]
    isEditMode: boolean
    isRequest: boolean
    loading: boolean
    dataWorkflow: WorkFlow[]
    getWorkflowID: Function
    dataWorkflowId?: number
    ItemcatalogOldType?: boolean
    currentTabpane: string
}

type Props = Params
const { Option } = Select
const listsEdit: number[] = []
const { confirm } = Modal
const FieldSetting: React.FC<Props> = (props: Props) => {
    const { id }: any = useParams()
    const [data, setData] = useState<CustomFiled[]>([])
    const [dataWorkflowTarget, setDataWorkflowTarget] = useState<WorkFlow>()
    const [dataNotRequireFields, setDataNotRequireFields] = useState<CustomFiled[]>([])
    const [tempDataRequireFields, setTempDataRequireFields] = useState<CustomFiled[]>([])
    const [isSpinning, setIsSpinning] = useState(false)
    const [isAddField, setIsAddField] = useState(false)
    const [isRowEdit, setIsRowEdit] = useState<number[]>([])
    const [isRowEditTemp, setIsUpdateValue] = useState<boolean>(false)
    const { getFieldDecorator, getFieldValue, resetFields, setFieldsValue } = props.form
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const manager = useRef(RNDContext)
    const [defaultReqCustomfield, setDefaultReqCustomfield] = useState<CustomFiled[]>([])
    let tempcustomfield: CustomFiled[] = []
    const [pageCustomfiled, setPageCustomfield] = useState<number>(1)
    const [onLoadTable, setOnLoadTable] = useState<boolean>(false)

    useEffect(() => {
        if (props.dataWorkflowId) {
            setFieldsValue({ Workflow: props.dataWorkflowId })
        }
        if (props.customFieldsForUsed) {
            if (props.ItemcatalogOldType) {
                // eslint-disable-next-line array-callback-return
                tempcustomfield = props.customFieldsForUsed.map((it) => {
                    it.isItemRequest = true
                    return it
                })
            } else {
                // eslint-disable-next-line array-callback-return
                tempcustomfield = props.customFieldsForUsed.map((it) => {
                    it.isItemRequest = false
                    return it
                })
            }
        }
        if (props.dataWorkflowId) {
            findCustomFieldWOByWorkflowID(props.dataWorkflowId.toString()).then((res) => {
                const tempReponse = res as CustomFiled[]
                const nonMandatoryField = tempReponse.filter((data) => { return !data?.isRequireFields })
                setDataNotRequireFields(nonMandatoryField)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.currentTabpane !== '2') {
            setIsAddField(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentTabpane])

    useEffect(() => {
        setIsSpinning(true)
        const dataDesc: CustomFiled = {
            customFieldOrder: 0,
            dataType: 'String',
            defaultValue: undefined,
            description: undefined,
            id: 999999,
            inputType: 'TextBox',
            isRequireFields: true,
            isEditable: true,
            isVisible: true,
            label: 'Description',
            name: 'Description',
            placeholder: '',
            selectOption: []
        }
        const dataAppointmentDate: CustomFiled = {
            customFieldOrder: 0,
            dataType: 'Date',
            defaultValue: undefined,
            description: undefined,
            id: 999998,
            inputType: 'DatePicker',
            isRequireFields: true,
            isEditable: true,
            isVisible: true,
            label: 'Appointment Date',
            name: 'Appointment_Date',
            placeholder: '',
            selectOption: []
        }
        if (props.data) {
            if (props.isRequest) {
                const mandatoryField: any[] = []
                mandatoryField.push(dataDesc)
                mandatoryField.push(dataAppointmentDate)
                setData(mandatoryField)
                setTempDataRequireFields(mandatoryField)
                setDefaultReqCustomfield(mandatoryField)
            } else {
                const mandatoryField = props.data.filter((data) => { return data?.isRequireFields })
                mandatoryField.forEach((data) => {
                    data.isEditable = true
                    data.isVisible = true
                    data.isdisableRequireFields = true
                })
                mandatoryField.push(dataDesc)
                setData(mandatoryField)
                setTempDataRequireFields(mandatoryField)
                const nonMandatoryField = props.data.filter((data) => { return !data?.isRequireFields })
                setDataNotRequireFields(nonMandatoryField)
            }
        } else {
            if (props.isRequest) {
                const mandatoryField: CustomFiledAsset[] = []
                dataDesc.isdisableRequireFields = true
                dataAppointmentDate.isdisableRequireFields = true
                mandatoryField.push(dataDesc)
                mandatoryField.push(dataAppointmentDate)
                setData(mandatoryField)
                setTempDataRequireFields(mandatoryField)
                setDefaultReqCustomfield(mandatoryField)
            } else {
                setData([dataDesc])
            }
        }
        if (id) {
            if (props.isRequest) {
                const filterItemRequest = tempcustomfield.filter((it) => it.isItemRequest === true)
                const initData = filterItemRequest
                if (initData) {
                    initData.map((it) => {
                        return it.isItemRequest = true
                    })
                    dataDesc.isdisableRequireFields = true
                    dataAppointmentDate.isdisableRequireFields = true
                    initData.push(dataDesc)
                    initData.push(dataAppointmentDate)
                    setData(initData)
                    setTempDataRequireFields(initData)
                    setDefaultReqCustomfield([dataDesc, dataAppointmentDate])
                }
            } else {
                let mandatoryField
                if (props.data) {
                    mandatoryField = props.data.filter((data) => { return data?.isRequireFields })
                    mandatoryField.forEach((data) => {
                        data.isEditable = true
                        data.isVisible = true
                        data.isdisableRequireFields = true
                    })
                    resetFields(['Workflow'])
                }
                let filterItemRequest = tempcustomfield.filter((it) => it.isItemRequest === false)
                const init = tempcustomfield.length > 0 ? filterItemRequest : mandatoryField
                dataDesc.isdisableRequireFields = true
                tempcustomfield = mandatoryField
                init.push(dataDesc)
                setData(init)
            }
        }
        setIsSpinning(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, id, props.isRequest])

    useEffect(() => {
        if (data) {
            const tempData = data
            tempData.forEach((it) => {
                delete it.active
                delete it.createdBy
                delete it.createdDatetime
                delete it.lastModifiedBy
                delete it.lastModifiedDate
                // delete it.isdisableRequireFields
                delete it.createdByDisplay
                delete it.lastModifiedByDisplay
                delete it.createdById
                delete it.lastModifiedById
                delete it?.defaultValueTemp
                delete it?.isItemRequest
            })
            props.setCustomFieldsForUsed(tempData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, props.loading])

    const components = {
        body: {
            row: DragableBodyRow
        }
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = data[dragIndex]
            setData(
                update(data, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow]
                    ]
                })
            )
        },
        [data]
    )

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    {!props.isEditMode ? !row.isRequireFields ? <Tooltip placement="bottom" title={'Delete'}><Icon className="edit_icon" type="minus-square" onClick={() => deleteRow(data.id)} /></Tooltip> : null : null}
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    {row.name === 'Description' && row.name === 'Appointment_Date' ? null : !props.isEditMode ? row.id ? <Tooltip placement="bottom" title={'edit'}><Icon className="del_icon" type="edit" onClick={() => addAfterRow(index)} /></Tooltip> : null : null}
                </>
            )
        },
        {
            title: <span>Field label</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('fields[' + data.id + '].name', {
                            initialValue: row.name,
                            rules: [
                                {
                                    required: true, message: 'Field name is required'
                                }
                            ]
                        })(
                            <Input maxLength={255} onChange={(e) => handleChangeLabel(row, e.target.value)} disabled={true} />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span>Display label</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('fields[' + data.id + '].label', {
                            initialValue: row.label,
                            rules: [
                                { required: true, message: 'Field label is required' },
                                { max: 255, message: 'max Input 255 characters' },
                                { validator: validateNameUniqueness(data) }
                            ],

                        })(
                            <Input
                                max={255}
                                maxLength={255}
                                onChange={(e) => handleChangeLabel(row, e.target.value)}
                                disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: <span>Input type</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('fields[' + data.id + '].inputType', {
                            initialValue: row.inputType,
                            rules: [{ required: false, message: 'Input type is required' }]
                        })(
                            <Select style={{ width: 135 }} onChange={(e) => handleChangeInputType(row, e, index)} allowClear disabled={true}>
                                <Option value="Checkbox">Checkbox</Option>
                                <Option value="DatePicker">Date picker</Option>
                                <Option value="DropdownSelect">Dropdown Select</Option>
                                <Option value="InputNumber">Input number</Option>
                                <Option value="RadioButton">Radio button</Option>
                                <Option value="TextBox">Text box</Option>
                            </Select>
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Select option',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <div key={index}>
                    <Form.Item>
                        {getFieldDecorator('fields[' + data.id + '].selectOption', {
                            initialValue: row.selectOption ? (row.selectOption || []).map((it) => { return it.option }) : undefined,
                            rules: [{
                                required: (getFieldValue('fields[' + index + '].inputType') === 'DropdownSelect' || getFieldValue('fields[' + index + '].inputType') === 'Checkbox' || getFieldValue('fields[' + index + '].inputType') === 'RadioButton'),
                                message: 'Select option is required'
                            }]
                        })(
                            <Select
                                maxTagCount={20} maxTagTextLength={20} maxTagPlaceholder={20}
                                mode="tags" style={{ width: 135 }} onChange={(e => handleChangeSelectOption(row, e))} disabled={true} />
                        )}
                    </Form.Item>
                </div>
            )
        },
        {
            title: 'Default value',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    {!!(row.isEditable === false && data.isVisible === true) || (row.isRequireFields === true && row.isEditable === false && data.isVisible === false)
                        ? <Form.Item>
                            {getFieldDecorator('fields[' + row.id + '].defaultValue', {
                                initialValue: row.defaultValue ? row.dataType === 'Number' ? Number(row.defaultValue) : row.dataType === 'Date' && row.defaultValue ? moment(row.defaultValue) : row.name === 'Description' ? '' : row.defaultValue : undefined,
                                rules: [
                                    {
                                        type: getFieldValue('fields[' + data.id + '].inputType') === 'Checkbox' || getFieldValue('fields[' + data.id + '].inputType') === 'RadioButton'
                                            ? undefined
                                            : row.dataType
                                                ? (row.dataType as string).toLowerCase() === 'date' ? 'object' : (row.dataType as string).toLowerCase()
                                                : undefined,
                                        message: (row.dataType as string).toLowerCase() === 'email' ? 'The input is not valid E-mail!' : 'default value is not a valid'
                                    }, {
                                        required: !!(row.isEditable === false && data.isVisible === true) || (row.isRequireFields === true && row.isEditable === false && data.isVisible === false), message: 'Default value is required'
                                    }
                                ]
                            })(
                                row.dataType === 'Number'
                                    ? <InputNumber min={0} onChange={(e) => handleChangeDefaultValue(row, e)}
                                        style={{ width: '100%' }} defaultValue={0}
                                        disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)} />
                                    : getFieldValue('fields[' + data.id + '].inputType') === 'DropdownSelect' || getFieldValue('fields[' + data.id + '].inputType') === 'Checkbox' || getFieldValue('fields[' + data.id + '].inputType') === 'RadioButton' ? <Select
                                        disabled={row.name === 'Description' || !isRowEdit.includes(index) || row.name === 'Appointment_Date'}
                                        onChange={(e) => handleChangeDefaultValue(row, e?.toString())}
                                        allowClear={true}
                                    >
                                        {(getFieldValue('fields[' + data.id + '].selectOption') || []).map((data, index) => {
                                            return <Option value={data} key={index}>{data}</Option>
                                        })}
                                    </Select>
                                        : getFieldValue('fields[' + data.id + '].inputType') === 'DatePicker'
                                            ? <DatePicker disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)} onChange={(e, i) => handleChangeDefaultValue(row, i)} style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                                            : <Input onChange={(e) => handleChangeDefaultValue(row, e.target.value)}
                                                disabled={row.name === 'Description' ? true : !isRowEdit.includes(index)}
                                            />
                            )}
                        </Form.Item> : <Form.Item>{getFieldDecorator('fields[' + row.id + '].defaultValueTemp', {
                            initialValue: row.defaultValue ? row.dataType === 'Number' ? Number(row.defaultValue) : row.dataType === 'Date' && row.defaultValue ? moment(row.defaultValue) : row.name === 'Description' ? '' : row.defaultValue : undefined,
                            rules: [
                                {
                                    type: getFieldValue('fields[' + data.id + '].inputType') === 'Checkbox' || getFieldValue('fields[' + data.id + '].inputType') === 'RadioButton'
                                        ? undefined
                                        : row.dataType
                                            ? (row.dataType as string).toLowerCase() === 'date' ? 'object' : (row.dataType as string).toLowerCase()
                                            : undefined,
                                    message: (row.dataType as string).toLowerCase() === 'email' ? 'The input is not valid E-mail!' : 'default value is not a valid'
                                }
                            ]
                        })(
                            row.dataType === 'Number'
                                ? <InputNumber min={0} onChange={(e) => handleChangeDefaultValue(row, e)}
                                    style={{ width: '100%' }} defaultValue={0}
                                    disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)} />
                                : getFieldValue('fields[' + data.id + '].inputType') === 'DropdownSelect' || getFieldValue('fields[' + data.id + '].inputType') === 'Checkbox' || getFieldValue('fields[' + data.id + '].inputType') === 'RadioButton' ? <Select
                                    disabled={row.name === 'Description' || !isRowEdit.includes(index) || row.name === 'Appointment_Date'}
                                    onChange={(e) => handleChangeDefaultValue(row, e?.toString())}
                                    allowClear={true}
                                >
                                    {(getFieldValue('fields[' + data.id + '].selectOption') || []).map((data, index) => {
                                        return <Option value={data} key={index}>{data}</Option>
                                    })}
                                </Select>
                                    : getFieldValue('fields[' + data.id + '].inputType') === 'DatePicker'
                                        ? <DatePicker disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)} onChange={(e, i) => handleChangeDefaultValue(row, i)} style={{ width: '100%' }} format={'YYYY-MM-DD'} />
                                        : <Input onChange={(e) => handleChangeDefaultValue(row, e.target.value)}
                                            disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)}
                                        />
                        )}</Form.Item>}
                </>
            )
        },
        {
            title: 'Placeholder',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('fields[' + data.id + '].placeholder', {
                            initialValue: row.placeholder,
                            rules: [{ required: false }]
                        })(
                            <Input maxLength={255} onChange={(e) => handleChangePlaceholder(row, e.target.value)}
                                disabled={row.name === 'Description' || row.inputType === 'Checkbox' || row.inputType === 'RadioButton' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Require field',
            dataIndex: '',
            key: '',
            width: '90px',
            render: (row: CustomFiledAsset, data, index) => (
                <>
                    <Tooltip placement="bottom" title={row.isRequireFields ? 'Deactivate' : 'Activate'}>
                        {/* disabled={id ? !isRowEdit.includes(index) || row.isdisableRequireFields || row.isRequireFields : CheckIsMandatoryIsField(row) ? true : !isRowEdit.includes(index)} */}
                        <Switch checked={row.isRequireFields} onChange={() => toggleActiveness(row)} disabled={checkDisableRequired(id, index, row)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Editable',
            dataIndex: '',
            key: '',
            width: '90px',
            render: (row: CustomFiledAsset, data, index) => (
                <>
                    <Tooltip placement="bottom" title={row.isEditable ? 'Deactivate' : 'Activate'}>
                        <Switch checked={row.isEditable} onChange={() => toggleEditable(row)} disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)} />
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Visible',
            dataIndex: '',
            key: '',
            width: '90px',
            render: (row: CustomFiledAsset, data, index) => (
                <>
                    <Tooltip placement="bottom" title={row.isVisible ? 'Deactivate' : 'Activate'}>
                        <Switch checked={row.isVisible} onChange={() => toggleVisible(row)} disabled={row.name === 'Description' || row.name === 'Appointment_Date' ? true : !isRowEdit.includes(index)} />
                    </Tooltip>
                </>
            )
        }
    ]

    const CheckIsMandatoryIsField = (row: CustomFiledAsset): boolean => {
        let check = true
        const findData = tempDataRequireFields.find((data) => data.name === row.name)
        if (!findData) {
            check = false
        }
        return check
    }

    const addAfterRow = (currentRow) => {
        const tempRowEdit = isRowEdit
        if (!tempRowEdit.includes(currentRow)) {
            tempRowEdit.push(currentRow)
            setIsRowEdit(tempRowEdit)
        } else {
            const result = tempRowEdit.filter(it => it !== currentRow)
            setIsRowEdit(result)
        }
        setIsUpdateValue(!isRowEditTemp)
    }

    const deleteRow = (id) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                const tempData2 = data.filter((item) => item.id !== id)
                setData(tempData2)
            },
            onCancel() {
            }
        })
    }

    const handleChangeLabel = (row: CustomFiledAsset, value: string) => {
        row.label = value
        if (row.id) {
            listsEdit.push(row.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const handleChangeInputType = (row: CustomFiledAsset, value, index: number) => {
        row.inputType = value
        const setValue = {}
        setValue['selectOption' + row.id + index] = undefined
        setValue['defaultValue' + row.id + index] = undefined
        setValue['placeholder' + row.id + index] = undefined
        row.defaultValue = ''
        row.selectOption = []
        row.placeholder = ''
        if (value === 'Checkbox' || value === 'DropdownSelect' || value === 'RadioButton') {
            setValue['dataType' + row.id + index] = 'String'
            row.dataType = 'String'
            setFieldsValue(setValue)
        } else if (value === 'DatePicker') {
            setValue['dataType' + row.id + index] = 'Date'
            row.dataType = 'Date'
            setFieldsValue(setValue)
        } else if (value === 'InputNumber') {
            setValue['dataType' + row.id + index] = 'Number'
            row.dataType = 'Number'
            setValue['defaultValue' + row.id + index] = 0
            row.defaultValue = 0
            setFieldsValue(setValue)
        } else {
            setValue['dataType' + row.id + index] = undefined
            row.dataType = undefined
            setFieldsValue(setValue)
        }
        if (row.id) {
            listsEdit.push(row.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const handleChangeSelectOption = (row: CustomFiledAsset, value) => {
        const addDataOption: mySelectOption[] = value.map((it) => {
            return {
                option: it,
                moduleName: 'Incident'
            }
        })
        row.selectOption = addDataOption
        if (row.id) {
            listsEdit.push(row.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const handleChangeDefaultValue = (row: CustomFiled, value: string | number | undefined) => {
        row.defaultValue = value?.toString()
        if (row.id) {
            listsEdit.push(row.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const handleChangePlaceholder = (row: CustomFiledAsset, value: string) => {
        row.placeholder = value
        if (row.id) {
            listsEdit.push(row.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const toggleActiveness = (data: CustomFiledAsset) => {
        data.isRequireFields = !data.isRequireFields
        forceUpdate()
        if (data.id) {
            listsEdit.push(data.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const toggleEditable = (data: CustomFiledAsset) => {
        if (data.isEditable === false && data.isVisible === false) {
            data.isEditable = true
            data.isVisible = true
            forceUpdate()
        } else {
            data.isEditable = !data.isEditable
            forceUpdate()
        }
        if (data.id) {
            listsEdit.push(data.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const toggleVisible = (data: CustomFiledAsset) => {
        if (data.isEditable === true && data.isVisible === true) {
            data.isEditable = false
            data.isVisible = false
            forceUpdate()
        } else {
            data.isVisible = !data.isVisible
            forceUpdate()
        }
        if (data.id) {
            listsEdit.push(data.id)
            setIsUpdateValue(!isRowEditTemp)
        }
    }

    const addField = () => {
        if (!props.isEditMode) {
            setIsAddField(!isAddField)
        }
    }

    const handleCancel = () => {
        resetFields(['fieldLabelCustom', 'displayLabelCustom'])
        setIsAddField(false)
    }

    const handleSubmit = () => {
        props.form.validateFields((err, value) => {
            if (Object.keys(err || []).filter(item => ['fieldLabelCustom', 'displayLabelCustom'].includes(item)).length <= 0) {
                const arr: any[] = []
                const name = getFieldValue('fieldLabelCustom')
                const findCustomField = props.isRequest ? dataNotRequireFields.find((data) => { return data.name === name }) : props.data?.find((data) => { return data.name === name })
                if (findCustomField) {
                    findCustomField.label = getFieldValue('displayLabelCustom') ? getFieldValue('displayLabelCustom') : getFieldValue('fieldLabelCustom')
                    findCustomField.isEditable = true
                    findCustomField.isVisible = true
                    arr.push(findCustomField)
                    setData((data || []).concat(arr))
                    resetFields(['fieldLabelCustom', 'displayLabelCustom'])
                    forceUpdate()
                    setIsAddField(false)
                }
            }
        })
    }

    const onSelectFieldLabel = (e) => {
        if (props.isRequest) {
            const findData = dataNotRequireFields.find(data => data.name === e)
            if (findData) {
                setFieldsValue({
                    displayLabelCustom: findData.label
                })
            }
        } else {
            if (props.data) {
                const findData = props.data.find(data => data.name === e)
                if (findData) {
                    setFieldsValue({
                        displayLabelCustom: findData.label
                    })
                }
            }
        }
    }

    const UniqueData = (dataFiled: CustomFiled[]): CustomFiled[] => {
        const arrayUniqueByKey = [...new Map(dataFiled.map(item =>
            [item.name, item])).values()]
        return arrayUniqueByKey
    }

    const onSelectWorkflow = (e) => {
        const findData = props.dataWorkflow.find((data) => data.id === e)
        setIsAddField(false)
        if (props.getWorkflowID) {
            props.getWorkflowID(e)
        }
        findCustomFieldWOByWorkflowID(e.toString()).then((res) => {
            const tempReponse = res as CustomFiled[]
            const mandatoryField: CustomFiled[] = tempReponse.filter((it) => { return it?.isRequireFields })
            mandatoryField.forEach((it) => {
                it.isEditable = true
                it.isVisible = true
                it.isdisableRequireFields = true
            })
            const nonMandatoryField = tempReponse.filter((data) => { return !data?.isRequireFields })
            setDataNotRequireFields(nonMandatoryField)
            if (mandatoryField.length !== 0) {
                setData([...defaultReqCustomfield, ...mandatoryField])
                setTempDataRequireFields([...defaultReqCustomfield, ...mandatoryField])
            } else {
                setData([...defaultReqCustomfield])
                setTempDataRequireFields([...defaultReqCustomfield])
            }
        })
        resetFields(['fieldLabelCustom'])
        setDataWorkflowTarget(findData)
        console.log(dataWorkflowTarget)
    }

    const handlePreviewWorkflow = () => {
        const workflowId = getFieldValue('Workflow')
        window.open('/WorkflowFormView/' + workflowId)
    }

    const checkDisableRequired = (id, index, data: CustomFiledAsset) => {
        if (id) {
            return !isRowEdit.includes(index) || data.isdisableRequireFields
        } else {
            if (CheckIsMandatoryIsField(data)) {
                return true
            } else {
                return !isRowEdit.includes(index)
            }
        }
    }

    const validateNameUniqueness = (row) => async (_: any, value: any, callback) => {
        if (value) {
            let tempdisplayList = data.map((it) => {
                if (it.label && it.id !== row.id) {
                    return it.label.replace(/ +/g, ' ').trim().toLowerCase()
                }
            })
            if (tempdisplayList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                callback('The Display Label already exists. It must be unique. Please try another one.')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const sortSelectWorkflowOption = () => {
        if (props.dataWorkflow) {
            let tempDataWorkflow: WorkFlow[] = props.dataWorkflow.sort((a, b) => {
                if (a.id === props.dataWorkflowId && b.id !== props.dataWorkflowId) {
                    return -1;
                } else if (a.id !== props.dataWorkflowId && b.id === props.dataWorkflowId) {
                    return 1;
                } else {
                    return 0;
                }
            })
            return (tempDataWorkflow.map(((target, index) => {
                if (target.activated) {
                    return <Option value={target.id} key={index}>{target.name}</Option>
                } else if (target.id === props.dataWorkflowId && !target.activated) {
                    return <Option value={target.id} key={index} disabled={true}>{target.name}</Option>
                } else {
                    return null
                }
            })))
        } else {
            return null
        }
    }

    return (
        <>
            {props.isRequest ? <Row gutter={[8, 8]}>
                <Col span={2}>
                    <div style={{ marginTop: 10 }}>Workflow : </div>
                </Col>
                <Col span={10}>
                    <Form.Item >
                        {getFieldDecorator('Workflow', {
                            // initialValue: props.dataWorkflowId,
                            rules: [{ required: false, message: 'Please Select Workflow.' }]
                        })(
                            <Select style={{ width: '100%' }} placeholder={'Select Workflow'} onSelect={(e: string) => onSelectWorkflow(e)} allowClear disabled={props.isEditMode} onChange={() => { setData([...defaultReqCustomfield]); setTempDataRequireFields([...defaultReqCustomfield]); setPageCustomfield(1); }}>
                                {sortSelectWorkflowOption()}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Button onClick={() => handlePreviewWorkflow()} style={{ marginTop: 5 }} disabled={!getFieldValue('Workflow')}> <Icon type="box-plot" /> Preview Workflow</Button>
                </Col>
            </Row> : null}
            <DndProvider manager={manager.current.dragDropManager!!}>
                <Spin spinning={isSpinning}>
                    <Table
                        columns={columns}
                        dataSource={UniqueData(data)}
                        components={components}
                        rowKey='name'
                        onRow={(record, index) => ({
                            index,
                            moveRow
                        })}
                        scroll={{ x: 768 }}
                        pagination={{
                            pageSize: 10,
                            current: pageCustomfiled,
                            total: data.length,
                            onChange: (event) => {
                                setPageCustomfield(event);
                                setOnLoadTable(true);
                                setTimeout(() => { setOnLoadTable(false) }, 500)
                            }
                        }}
                        loading={onLoadTable}
                    />
                </Spin>
            </DndProvider>
            <br />
            {getFieldValue('Workflow') || !props.isRequest ? <p style={{ color: '#323276', cursor: !props.isEditMode ? 'pointer' : 'default' }} onClick={() => addField()}><Icon type="plus-square" /> Add Field</p>
                : null}
            {isAddField ? <div className={'myBox'}>
                <Row gutter={[8, 8]}>
                    <Col span={10}>
                        <Form.Item label={'Field Label'}>
                            {getFieldDecorator('fieldLabelCustom', {
                                rules: [{ required: true, message: 'Please Select Custom Field!' }]
                            })(
                                <Select style={{ width: '100%' }} placeholder={'Select Custom Field'} onSelect={(e: string) => onSelectFieldLabel(e)} allowClear>
                                    {props.isRequest ? dataNotRequireFields.map((target, index) => {
                                        if (!data.some((it) => { return it.name === target.name })) {
                                            return <Option value={target.name} key={index}>{target.name}</Option>
                                        } else {
                                            return null
                                        }
                                    }) : (props.data || []).map((target, index) => {
                                        if (!data.some((it) => { return it.name === target.name })) {
                                            return <Option value={target.name} key={index}>{target.name}</Option>
                                        } else {
                                            return null
                                        }
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={10} >
                        <Form.Item label={'Display Label'}>
                            {getFieldDecorator('displayLabelCustom', {
                                rules: [{ required: false },
                                { validator: validateNameUniqueness(data) }]

                            })(
                                <Input maxLength={255} placeholder={'Display Label'} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Col span={24} style={{ marginTop: 40 }}>
                            <Button type="primary" htmlType="button" onClick={() => handleSubmit()} style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                            <Button style={{ float: 'right' }} onClick={() => handleCancel()}>Cancel</Button>
                        </Col>
                    </Col>
                </Row>
            </div> : null}
        </>
    )
}

export default FieldSetting
