import {axiosGet, axiosPost} from '../incident-management/rest'
import {
    AddCustomFiledsSuccessEvent,
    GetAllCustomFiledsSuccessEvent,
    UpdateCustomFiledsSuccessEvent
} from './state-event'
import {Dispatch} from 'redux'
import {CustomFileds} from './model'
import { decryptBody, encryptBody } from '../common-misc/encryptBody'
import { herderPostPatch } from '../common-misc'

export const getAllCustomFields = (value: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<CustomFileds>('/api/custom-fields/ticket-type/' + value)
            dispatch(GetAllCustomFiledsSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const addCustomFields = (customField: CustomFileds) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(customField))
            const response = await axiosPost<CustomFileds>('/api/custom-fields', encryptData, herderPostPatch)
            dispatch(AddCustomFiledsSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const updateCustomFields = (patch: CustomFileds) => {
    return async (dispatch: Dispatch) => {
        try {
            const ticketType = patch.ticketType
            // delete patch.id
            const encryptData = encryptBody(JSON.stringify(patch.fields))
            const response = await axiosPost<CustomFileds>(`/api/custom-fields/ticket-type/${ticketType}`, encryptData, herderPostPatch)
            console.log('return : ', decryptBody(response.data.data))
            dispatch(UpdateCustomFiledsSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}
