import React, { createContext, useReducer, PropsWithChildren } from 'react'

export const TicketRelatedContext = createContext({})

const initialState = []

interface Param {

}
type props = PropsWithChildren<Param>
const TicketRelatedReducer = (state, action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_TicketRelated':
        return action.payload
    case 'ADD_TicketRelated':
        return [...state, action.payload]
    case 'DELETE_TicketRelated':
        const filterTicketRelated = state.filter((it) => it.id !== action.payload)
        return filterTicketRelated
    default:
        return state
    }
}

export const TicketRelatedProvider = (props: props) => {
    const [TicketRelatedState, TicketRelatedDispatch] = useReducer(TicketRelatedReducer, initialState)

    const GetTicketRelated = (payload) => {
        TicketRelatedDispatch({ type: 'GET_TicketRelated', payload })
    }

    const AddTicketRelated = (payload) => {
        TicketRelatedDispatch({ type: 'ADD_TicketRelated', payload })
    }

    const DeleteTicketRelated = (payload) => {
        TicketRelatedDispatch({ type: 'DELETE_TicketRelated', payload })
    }
    return (
        <TicketRelatedContext.Provider value={{ TicketRelatedState, GetTicketRelated, AddTicketRelated, DeleteTicketRelated }}>
            {props.children}
        </TicketRelatedContext.Provider>
    )
}
