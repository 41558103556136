/* eslint-disable no-useless-escape */
import { Form } from 'antd'
import React, { useEffect, useState } from 'react'
import ImageResize from 'quill-image-resize-module-react'
import ReactQuill, { Quill } from 'react-quill'
import { UploadFile } from 'antd/lib/upload/interface'
import KnowledgeFormUpload from '../../../../../knowledge-management/knowledge/uploads/KnowledgeFormUpload'
// import { useParams } from 'react-router-dom'

interface Params {
    label: string
    isrequired: boolean
    isUploadFile: boolean
    getData: Function
    getUploadFile?: Function
    isSubmit: boolean
    displayIndex: number
    intializeData?: string
    disable?: boolean
    fileList?: UploadFile[]
    isVisibleModal?: boolean
}

Quill.register('modules/imageResize', ImageResize)
const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean']
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link'
]

type Props = Params
const CustomTextEditor = (props: Props) => {
    const [detail, setDetail] = useState<string>('')
    const [textError, setTextError] = useState<string>('')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    // const { id }: any = useParams()

    useEffect(() => {
        if (props.isSubmit && props.isrequired) {
            const detailValue = detail || ''
            const CheckValue = (detailValue).replace(/<[^>]*>/g, '')
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (CheckValue === '' || CheckValue.length <= 2 || !pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                if (CheckValue === '') {
                    setTextError(props.label + ' is required')
                } else {
                    if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                        if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                            setTextError('')
                        } else {
                            setTextError('Enter more than 3 characters')
                        }
                    } else {
                        if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                            setTextError('Enter more than 1 letter and can not contain any of the following characters ' + format)
                        } else {
                            setTextError('Enter more than 3 characters')
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSubmit])

    useEffect(() => {
        if (props.intializeData) {
            setDetail(props.intializeData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.intializeData])

    useEffect(() => {
        if (props.fileList) {
            setFileList(props.fileList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fileList])

    useEffect(() => {
        if (props.isVisibleModal && props.intializeData) {
            setDetail(props.intializeData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisibleModal])

    const onTextEditorChange = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const element = document.getElementsByClassName('ql-editor')?.item(props.displayIndex) as HTMLElement
        if (value) {
            setDetail(value)
            props.getData(value)
            if (props.isrequired) {
                if (CheckValue === '') {
                    element.style.border = '1px solid red'
                    setTextError(props.label + ' is required')
                } else {
                    if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                        if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                            element.style.border = '0px solid red'
                            setTextError('')
                        } else {
                            element.style.border = '1px solid red'
                            setTextError('Enter more than 3 characters')
                        }
                    } else {
                        element.style.border = '1px solid red'
                        if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                            setTextError('Enter more than 1 letter and can not contain any of the following characters ' + format)
                        } else {
                            setTextError('Enter more than 3 characters')
                        }
                    }
                }
            }
        }
    }

    // const uploadImageInTextArea = (value: string, callBack: Function) => {
    //     if (value.search('<img')) {
    //         const MyFileList: UploadFile[] = []
    //         const temp: string[] = []
    //         const pattern = /<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>|<([A-Z][A-Z0-9]*).*?\/>/gi
    //         const HTMLTags = value.match(pattern)
    //         if (HTMLTags) {
    //             // when new image is not last
    //             for (let i = 0; i < HTMLTags.length; i++) {
    //                 if (HTMLTags[i].search('<img') !== -1) {
    //                     const tagDatasource = HTMLTags[i].split('<img src="data:')
    //                     tagDatasource.forEach(tagData => {
    //                         if (tagData.search('image') !== -1) {
    //                             temp.push(tagData.split('"')[0])
    //                         }
    //                     })
    //                 } else {
    //                     temp.push(HTMLTags[i])
    //                 }
    //             }
    //         }

    //         const newValue: string[] = []
    //         temp.forEach((it, index) => {
    //             if (it && it.startsWith('image')) {
    //                 const fileType = it.split(';')[0]
    //                 const fileExtention = fileType.split('/')[1] || ''
    //                 const deleteTagHtml = 'data:' + it.split('">')[0]
    //                 const ts = `${Math.round((new Date()).getTime() / 1000)}-${index}`
    //                 const ConvertUploadLink: UploadFile = {
    //                     uid: `${id}-${ts}`,
    //                     name: `${id}-${ts}.${fileExtention}`,
    //                     type: fileType,
    //                     url: deleteTagHtml,
    //                     size: deleteTagHtml.length
    //                 }
    //                 MyFileList.push(ConvertUploadLink)
    //             }
    //         })

    //         if (MyFileList.length === 0) callBack(value)

    //         FSS.putFile(MyFileList, '/knowledgeEditor/', (uploadLink) => {
    //             if (uploadLink) {
    //                 // eslint-disable-next-line no-unused-expressions
    //                 uploadLink?.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
    //                 if (HTMLTags) {
    //                     let imgIndex = 0
    //                     HTMLTags.forEach((it, index) => {
    //                         if (HTMLTags[index].search('<img') !== -1) {
    //                             const inside64 = HTMLTags[index].split('<img src="data:')
    //                             console.log('inside64', inside64)
    //                             inside64.forEach(tagData => {
    //                                 if (tagData.startsWith('image')) {
    //                                     const insideReplace = tagData.split('"')[0]
    //                                     imgIndex++
    //                                     newValue.push(...tagData.replace(insideReplace, `<img src="${uploadLink[imgIndex - 1].url}`))
    //                                 } else {
    //                                     newValue.push(...tagData)
    //                                 }
    //                             })
    //                         } else {
    //                             newValue.push(HTMLTags[index])
    //                         }
    //                     })
    //                 }
    //                 const joinV = newValue.join('')
    //                 console.log(joinV)
    //                 setDetail(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
    //                 callBack(joinV.replace(/width="(\d+)"/g, 'width="$1px"'))
    //             }
    //         })
    //     }
    // }

    const handleSetFileList = (fileList: UploadFile[]) => {
        setFileList(fileList)
        if (props.getUploadFile) {
            props.getUploadFile(fileList)
        }
    }

    return (
        <div>
            <Form.Item label={props.label} required={props.isrequired} className='custom-formLabel-wo'>
                <ReactQuill modules={modules} readOnly={props.disable} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ overflow: 'hidden', border: textError === '' ? '1px solid #f2f2f2' : '1px solid #fa541c', backgroundColor: props.disable ? '#F8F8F9' : 'white' }} />
                <span style={{ color: 'red' }}>{textError}</span>
            </Form.Item>
            {props.isUploadFile ? <KnowledgeFormUpload fileList={fileList} setFileList={handleSetFileList} /> : null}
        </div>
    )
}

export default CustomTextEditor
