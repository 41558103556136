/* eslint-disable no-useless-escape */
import { Button, Col, Form, Icon, Input, message, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import ImageResize from 'quill-image-resize-module-react'
import { FormComponentProps } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import { createWorkNote, getMailCount } from '../../service'
import FSS from '../../../../file-server-storage'
import { UploadLink } from '../../../../file-server-storage/model'
import { checkRolesState, RoleType } from '../../../../authorization-module/permissions'
import { Problem } from '../../Model'

Quill.register('modules/imageResize', ImageResize)
const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        ['clean']
    ],
    imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
    }
}

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet', 'indent',
    'link'
]

interface Param {
    id: string
    reloadWorknote: Function
    isNonApproved: boolean
    isRenderPTask: boolean
    problem?: Problem
}

type Props = FormComponentProps & Param;
let validateDetail = true
const DisplayWorkNote = (props: Props) => {
    const { getFieldDecorator, validateFields, resetFields } = props.form
    const [NotifyAgentType, setNotifyAgentType] = useState<string>('Noted')
    const [detail, setDetail] = useState<string>('')
    const [detail2, setDetail2] = useState<string>('')
    const [fileList2, setFileList2] = useState<UploadFile[]>([])
    const [previewVisible2, setPreviewVisible2] = useState<boolean>()
    const [previewImage2] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [textError, setTextError] = useState<string>('')
    const [textError2, setTextError2] = useState<string>('')
    const [getCount, setCount] = useState()
    const [isDisableNotify, setIsDisableNotify] = useState(false)

    useEffect(() => {
        getMailCount(props.id, props.isRenderPTask).then((count) => {
            setCount(count)
            if (count === '3/3') {
                setIsDisableNotify(true)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id])

    useEffect(() => {
        resetFields()
        setDetail('')
        setTextError('')
        setDetail2('')
        setTextError2('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [NotifyAgentType])

    // const base64MimeType = (encoded: any) => {
    //     if (!encoded) return
    //     const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
    //     if (mime && mime.length) return mime[1]
    // }

    const onTextEditorChange = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
        if (value) {
            setDetail(value)
            if (CheckValue === '' && validateDetail === true) {
                element.style.border = '1px solid red'
                setTextError('Description is required')
            } else {
                if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                    if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                        element.style.border = '0px solid red'
                        setTextError('')
                    } else {
                        element.style.border = '1px solid red'
                        setTextError('Enter more than 3 characters')
                    }
                } else {
                    if (validateDetail === true) {
                        element.style.border = '1px solid red'
                        if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                            setTextError('Enter more than 1 letter and can not contain any of the following characters ' + format)
                        } else {
                            setTextError('Enter more than 3 characters')
                        }
                    }
                }
            }
        }
    }

    const onTextEditorChange2 = (value: string) => {
        const CheckValue = (value).replace(/<[^>]*>/g, '')
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
        if (value) {
            setDetail2(value)
            if (CheckValue === '' && validateDetail === true) {
                element.style.border = '1px solid red'
                setTextError2('Description is required')
            } else {
                if (pattern.test(CheckValue.replace(/ +/g, '').trim())) {
                    if (CheckValue.replace(/ +/g, '').trim().length >= 3) {
                        element.style.border = '0px solid red'
                        setTextError2('')
                    } else {
                        element.style.border = '1px solid red'
                        setTextError2('Enter more than 3 characters')
                    }
                } else {
                    if (validateDetail === true) {
                        element.style.border = '1px solid red'
                        if (format.test(CheckValue.replace(/ +/g, '').trim())) {
                            setTextError2('Enter more than 1 letter and can not contain any of the following characters ' + format)
                        } else {
                            setTextError2('Enter more than 3 characters')
                        }
                    }
                }
            }
        }
    }

    // const uploadProps2 = {
    //     multiple: true,
    //     onRemove: (file: any) => {
    //         setFileList2(state => {
    //             const index = state.indexOf(file)
    //             const newFileList = state.slice()
    //             newFileList.splice(index, 1)
    //             // deleteObjectInS3(file.uid).catch((err) => message.error(`Failed delete object in S3 data. ${err}`))
    //             return newFileList
    //         })
    //     },
    //     beforeUpload: (file: any) => {
    //         const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
    //         const extension = file.name.split('.').pop().toLowerCase()
    //         const isSuccess = fileTypes.indexOf(extension) < 0
    //         if (isSuccess) {
    //             message.error('Upload only File type : ' + fileTypes.join(','))
    //             return false
    //         }
    //         const reader = new FileReader()
    //         reader.readAsDataURL(file)
    //         reader.onloadend = function (e: any) {
    //             console.log(e)
    //             if (file.size > fileSize) {
    //                 message.error('Maximum permitted size of 5 Mb')
    //                 return false
    //             }
    //             let fillOffFileList = fileList2
    //             fillOffFileList.push({
    //                 uid: file.uid,
    //                 name: file.name,
    //                 status: 'done',
    //                 url: reader.result?.toString(),
    //                 type: base64MimeType(reader.result),
    //                 size: e.total,
    //                 thumbUrl: exportIcon(base64MimeType(reader.result))
    //             })
    //             fillOffFileList = fillOffFileList.slice(0)
    //             setFileList2(fillOffFileList)
    //             console.log(fileList2)
    //         }

    //         return false
    //     },
    //     showUploadList: { showDownloadIcon: false }
    // }

    // const getBase64 = (file: Blob) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader()
    //         reader.readAsDataURL(file)
    //         reader.onload = () => resolve(reader.result)
    //         reader.onerror = error => reject(error)
    //     })
    // }

    // const handlePreview2 = async (file: { url: any; preview: unknown; originFileObj: Blob; type: string }) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj)
    //     }
    //     if (file.type.includes('image')) {
    //         setPreviewVisible2(true)
    //         setPreviewImage2(file.url || file.preview)
    //     } else {
    //         DownloadAnyFile(file)
    //     }
    // }

    const handleSkipOrSubmit = () => {
        const CheckValue = NotifyAgentType === 'Noted' ? (detail)?.replace(/<[^>]*>/g, '') : (detail2)?.replace(/<[^>]*>/g, '')
        if (CheckValue.trim() !== '') {
            validateFields((err: any, values) => {
                if (!err) {
                    if (CheckValue.trim().length > 2) {
                        setIsLoading(true)
                        validateDetail = false
                        const convertData: any = {
                            subject: values.Subject?.trim(),
                            content: NotifyAgentType === 'Noted' ? detail.trim() : detail2.trim(),
                            type: NotifyAgentType,
                            pbSendMail: values.email ? { email: values.email } : undefined
                        }
                        if (props.isRenderPTask) {
                            convertData.problemTaskId = Number(props.id)
                        } else {
                            convertData.problemId = Number(props.id)
                        }
                        if (fileList2.length > 0 && fileList2.length <= 5) {
                            FSS.putFile(fileList2, 'problem', (uploadLink: UploadLink[]) => {
                                convertData.pbWorknoteUploadLink = uploadLink?.map((data) => {
                                    return {
                                        name: data.name,
                                        url: data.url,
                                        type: data.type
                                    } as UploadLink
                                })
                                createWorkNote(convertData, props.isRenderPTask).then((it) => {
                                    setDetail('')
                                    setFileList2([])
                                    if (values.email !== undefined) {
                                        getMailCount(props.id, props.isRenderPTask).then((count) => {
                                            setCount(count)
                                            if (count === '3/3') {
                                                setNotifyAgentType('Noted')
                                                setIsDisableNotify(true)
                                            }
                                        })
                                    }
                                    resetFields()
                                    // setNotifyAgentType('Noted')
                                    props.reloadWorknote(true)
                                    setTextError('')
                                    setDetail2('')
                                    setTextError2('')
                                }).finally(() => {
                                    setIsLoading(false)
                                })
                            })
                        } else if (fileList2.length > 5) {
                            setIsLoading(false)
                            message.error('Upload File Max 5')
                        } else {
                            createWorkNote(convertData, props.isRenderPTask).then((it) => {
                                setDetail('')
                                setFileList2([])
                                if (values.email !== undefined) {
                                    getMailCount(props.id, props.isRenderPTask).then((count) => {
                                        setCount(count)
                                        if (count === '3/3') {
                                            setNotifyAgentType('Noted')
                                            setIsDisableNotify(true)
                                        }
                                    })
                                }
                                resetFields()
                                // setNotifyAgentType('Noted')
                                props.reloadWorknote(true)
                                setTextError('')
                                setDetail2('')
                                setTextError2('')
                            }).finally(() => {
                                setIsLoading(false)
                            })
                        }
                    }
                }
            })
        } else {
            // validate details?
            const element = document.getElementsByClassName('ql-editor')?.item(0) as HTMLElement
            if (element) {
                setDetail('')
                element.style.border = '1px solid red'
                setTextError('Description is required')
            }
        }
    }

    return (
        <div>
            <div className='custom-formLabel-wo'><p><b>Notify Agent</b></p></div>
            <div className='display-scrollbar-height500' style={{ paddingRight: 10 }}>
                <Form>
                    <Button htmlType="button" style={{ marginRight: '5px' }} type={NotifyAgentType === 'Noted' ? 'primary' : 'default'} onClick={() => setNotifyAgentType('Noted')}> <Icon type="lock" /> Noted</Button>
                    {props.isRenderPTask === false && getCount !== '3/3' ? <Button htmlType="button" disabled={isDisableNotify} type={NotifyAgentType === 'Notify' ? 'primary' : 'default'} onClick={() => setNotifyAgentType('Notify')}> <Icon type="mail" />Notify ( {getCount} ) </Button> : null}
                    {NotifyAgentType === 'Notify' ? <div>
                        <Form.Item label={'Email'} className='custom-formLabel-wo'>
                            {getFieldDecorator('email', {
                                rules: [
                                    { required: true, message: 'Email is required' },
                                    { type: 'email' },
                                    { whitespace: true }
                                ]
                            })(
                                <Input style={{ width: '100%' }} placeholder='Email' disabled={props.isNonApproved} />
                            )}
                        </Form.Item>
                        <Form.Item label={'Subject'} className='custom-formLabel-wo'>
                            {getFieldDecorator('Subject', {
                                rules: [{ required: true, message: 'Subject is required' }, { whitespace: true }]
                            })(
                                <Input style={{ width: '100%' }} placeholder='Subject' disabled={props.isNonApproved} />
                            )}
                        </Form.Item>
                    </div> : null}
                    {NotifyAgentType === 'Noted' ? <div>
                        <Form.Item label={'Description'} className='custom-formLabel-wo' required>
                            <ReactQuill modules={modules} formats={formats} value={detail} onChange={(e: any) => { onTextEditorChange(e) }} style={{ height: 275, marginBottom: 56 }} />
                        </Form.Item>
                        <span style={{ color: 'red' }}>{textError}</span>
                    </div> : null}

                    {NotifyAgentType !== 'Noted' ? <div>
                        <Form.Item label={'Description'} className='custom-formLabel-wo' required>
                            <ReactQuill modules={modules} formats={formats} value={detail2} onChange={(e: any) => { onTextEditorChange2(e) }} style={{ height: 275, marginBottom: 56 }} />
                        </Form.Item>
                        <span style={{ color: 'red' }}>{textError2}</span>
                    </div> : null}

                    {/* <Row>
                    <Col span={24} lg={24} md={24} sm={24} xs={24}
                        style={{ marginBottom: '20px', marginTop: '10px' }}>
                        <Upload {...uploadProps2}
                            listType="picture"
                            className={'upload-list-inline'}
                            fileList={fileList2}
                            onPreview={(e: any) => handlePreview2(e)}
                            disabled={true}
                        >
                            <Button disabled={fileList2.length >= 5 || true}>
                                <Icon type="upload" /> Upload
                            </Button>
                        </Upload>
                    </Col>
                </Row> */}
                    <Modal visible={previewVisible2} footer={null}
                        onCancel={() => setPreviewVisible2(false)}>
                        <img alt={previewImage2} style={{ width: '100%' }} src={previewImage2} />
                    </Modal>
                </Form>
                <div>
                    <Row>
                        <Col span={24} style={{ textAlign: 'end', marginTop: NotifyAgentType === 'Notify' ? 0 : 0 }}>
                            <Form.Item>
                                <Button htmlType="button" type='primary' onClick={() => handleSkipOrSubmit()} loading={isLoading} disabled={props.isRenderPTask ? false : props.isNonApproved || !checkRolesState(RoleType.Problem, 'UpdateProblem') || props.problem?.pbStatus.status === 'Cancelled'}>{NotifyAgentType === 'Noted' ? 'Add' : 'Send'}</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

const DisplayWorkNoteForm = Form.create<Props>({ name: 'DisplayWorkNote' })(DisplayWorkNote)
export default DisplayWorkNoteForm
