import React, { useContext, useEffect, useState } from 'react'
import { WorkFlowSelect } from '../../../serviceCatalog/workflow/Service/Model'
import { Button, Row, Col, Collapse, Icon, Modal } from 'antd'
import RenderFieldWorkFlow from './RenderFieldWorkFlow'
import { WorkflowRelatedContext } from '../../../Preventive/ContextAPI/WorkflowRelatedContext'
import { FieldDetail } from '..'
import { CustomFiled } from '../../../configurable-fields'
import { replaceName } from '../../../common-file'
import moment from 'moment'
// import { WorkflowRelatedSelectContext } from '../../../Preventive/ContextAPI/WorkflowRelatedSelectContext'

interface Param {
    selectWorkflow: string[]
    form: any
    dataWorkFlow: WorkFlowSelect[]
    dataCustomFields: Function
    isRequired: boolean
    workFlowId: number
    isModalVisible: boolean
    dataSelectItem: Function
}

type Props = Param
const { Panel } = Collapse
const { confirm } = Modal
const RenderTabWorkflow: React.FC<Props> = (props: Props) => {
    const [listWorkFlowRender, setListWorkFlowRender] = useState<WorkFlowSelect[]>([])
    const { DeleteWorkflowRelated, GetWorkflowRelated, WorkflowRelatedState } = useContext<any>(WorkflowRelatedContext)
    // const { WorkflowRelatedSelectState } = useContext<any>(WorkflowRelatedSelectContext)

    useEffect(() => {
        setListWorkFlowRender([])
        if (Array.isArray(props.selectWorkflow)) {
            const temp: WorkFlowSelect[] = []
            props.selectWorkflow.forEach((value) => {
                const findWorkFlow = props.dataWorkFlow.find((data) => data.workflowNumber.toString() + ' : ' + data.name?.toString() === value.toString())
                if (findWorkFlow) {
                    temp.push(findWorkFlow)
                }
            })
            setListWorkFlowRender(temp)
            if (GetWorkflowRelated) {
                GetWorkflowRelated(temp)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectWorkflow])

    useEffect(() => {
        if (listWorkFlowRender.length > 0) {
            // eslint-disable-next-line array-callback-return
            listWorkFlowRender.map((data) => {
                const fieldDetails: FieldDetail[] = data.customFields.map((obj: CustomFiled) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(props.form.getFieldValue(replaceName(data.id ? obj.name + data.id : obj.name)))) {
                            value = (props.form.getFieldValue(replaceName(data.id ? obj.name + data.id : obj.name)) || []).join('|')
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(replaceName(data.id ? obj.name + data.id : obj.name))) {
                            value = moment(props.form.getFieldValue(data.id ? obj.name + data.id : obj.name)).format('YYYY-MM-DD')
                        } else {
                            if (props.form.getFieldValue(replaceName(data.id ? obj.name + data.id : obj.name)) && typeof props.form.getFieldValue(props.form.getFieldValue(replaceName(data.id ? obj.name + data.id : obj.name))) === 'string') {
                                value = props.form.getFieldValue(replaceName(data.id ? obj.name + data.id : obj.name))?.replace(/ +/g, ' ')?.trim()
                            } else {
                                value = props.form.getFieldValue(replaceName(data.id ? obj.name + data.id : obj.name))
                            }
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields),
                        workFlowId: data.id
                    }
                    return fieldDetail
                })
                props.dataCustomFields(fieldDetails)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listWorkFlowRender, props.form])

    const deleteList = (name: string) => {
        const filter = listWorkFlowRender.filter((data) => data.name !== name)
        const findData = listWorkFlowRender.find((data) => data.name === name)
        if (findData && DeleteWorkflowRelated) {
            DeleteWorkflowRelated(findData.id)
            // eslint-disable-next-line array-callback-return
            WorkflowRelatedState.find((data) => {
                if (data.id === findData.id) {
                    data.customFields.forEach((obj) => {
                        obj.value = undefined
                    })
                }
            })
        }
        setListWorkFlowRender(filter)
        props.dataSelectItem(filter.map((data) => data.workflowNumber + ' : ' + data.name))
        props.form.setFieldsValue({
            selectWorkflow: filter.map((data) => data.workflowNumber + ' : ' + data.name)
        })
    }

    const openView = (id) => {
        window.open('/WorkflowFormView/' + id)
    }

    const modalDeleteWorkflow = (name: string) => {
        confirm({
            title: 'Are you sure ?',
            content: 'Do you confirm delete of this data ?',
            onOk() {
                deleteList(name)
            },
            onCancel() {}
        })
    }

    return (
        <div>{
            listWorkFlowRender.map((data, index) => {
                // const checkWorkflowRelated = WorkflowRelatedSelectState.find((it) => it.name === data.name && it.id === data.id)
                return (<Row gutter={[8, 8]} key={index}>
                    <Col span={2} style={{ textAlign: 'center', marginTop: 8}}>
                        <Button onClick={() => modalDeleteWorkflow(data.name)}><Icon type="delete" /></Button>
                    </Col>
                    <Col span={22}>
                        <Collapse>
                            <Panel style={{border: props.isRequired && props.workFlowId === data.id ? '1px solid red' : ''}} header={data.workflowNumber + ' : ' + data.name} key={index} extra={<span className='feck-link' onClick={() => openView(data.id)}>Preview Workflow</span>}>
                                <RenderFieldWorkFlow workFlowId={data.id!!} form={props.form} customFields={data.customFields} isModalVisible={props.isModalVisible} selectWorkflow={props.selectWorkflow}/>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>)
            })
        }</div>
    )
}

export default RenderTabWorkflow
