/* eslint-disable react-hooks/exhaustive-deps */
import { AutoComplete, Checkbox, Col, Form, Input, message, Modal, Row, Select } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { People } from '../../authorization-module/people/model'
import { PeopleContext } from '../../Preventive/ContextAPI/UserHRContext'
import { DataSourceItemObject } from 'antd/lib/auto-complete'
import { getAllUsersByUserId } from '../../authorization-module/user-role/duck/action'
import { SetNoneAssetDetailFieldListContext } from '../../Preventive/components/SyncDataPMToAsset/ContextAPI/SetNoneAssetDetailFieldList'

interface Param {
    form: any
    isPreview: boolean
    DataHR: People[]
    OpenQRCode?: Function
    OpenBarcode?: Function
    required?: boolean
    isEdit?: boolean
    maxInput?: number,
    disableItem?: boolean
    isScan?: boolean
    isOpenModel?: boolean
    getEditMode?: Function
    isFromSyncPM?: boolean
    postfix?: string,
    fullWitdh?: boolean
    showCopyCheckbox?: boolean,
    handleCopyAsset?: Function
}

type Props = Param
const { Option } = Select
const CustomInputGroupAsset2: React.FC<Props> = (props: Props) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const { PeopleState, GetPeople } = useContext<any>(PeopleContext)
    const [tempHR, setTempHR] = useState<People[]>([])
    const [isSearch, setIsSearch] = useState(false)
    const { SetNoneAssetDetailFieldListState } = useContext<any>(SetNoneAssetDetailFieldListContext)

    const name = ['User ID']
    const name2 = ['User Name', 'Department']

    useEffect(() => {
        if (props.form.getFieldValue(props.postfix ? 'User_ID' + props.postfix : 'User_ID') === undefined) {
            props.form.resetFields([props.postfix ? 'User_Name' + props.postfix : 'User_Name'])
            props.form.resetFields([props.postfix ? 'Department' + props.postfix : 'Department'])
        } else if (props.form.getFieldValue(props.postfix ? 'User_ID' + props.postfix : 'User_ID') !== undefined && !props.disableItem) {
            const timer = setTimeout(() => {
                setIsSearch(true)
            }, 1000)
            return () => clearTimeout(timer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue(props.postfix ? 'User_ID' + props.postfix : 'User_ID')])

    useEffect(() => {
        if (isSearch) {
            getAllUsersByUserId(props.form.getFieldValue(props.postfix ? 'User_ID' + props.postfix : 'User_ID')).then((res) => {
                if (res) {
                    setTempHR(res)
                    if (GetPeople) {
                        GetPeople(res)
                    }
                }
            }).catch(() => {
                message.warning('Data is null')
            }).finally(() => {
                setIsSearch(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearch])

    useEffect(() => {
        if (props.DataHR) {
            setTempHR(props.DataHR)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.DataHR])

    useEffect(() => {
        if (props.disableItem && props.form.getFieldValue(props.postfix ? name + props.postfix : name)) {
            props.form.resetFields([props.postfix ? name + props.postfix : name])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, props.disableItem, props.form])

    useEffect(() => {
        if (searchValue) {
            searchUserHR(searchValue)
        } else {
            props.form.resetFields(['User Name'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    useEffect(() => {
        if (!props.form.getFieldValue(props.postfix ? 'User_ID' + props.postfix : 'User_ID')) {
            name2.forEach((it) => {
                props.form.resetFields([replaceName(props.postfix ? it + props.postfix : it)])
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue(props.postfix ? 'User_ID' + props.postfix : 'User_ID')])

    const handleOk = () => {
        setIsModalVisible(false)
        searchUserHR(searchValue)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        props.form.resetFields(['SearchEmployee2'])
    }

    const searchUserHR = (e: string) => {
        if (tempHR || PeopleState) {
            const HR: People | undefined = (tempHR || PeopleState).find((res: People) => res.id?.toString() === e)
            console.log(e)
            const obj: any = {}
            if (HR) {
                if (props.postfix) {
                    console.log(HR)
                    if (props.isFromSyncPM) {
                        obj.User_IDAdSync = HR.employeeId
                        obj.User_NameAdSync = HR.fullName
                        obj.DepartmentAdSync = HR.departmentName
                    } else {
                        obj.User_IDAd = HR.employeeId
                        obj.User_NameAd = HR.fullName
                        obj.DepartmentAd = HR.departmentName
                    }
                } else {
                    obj.User_ID = HR.employeeId
                    obj.User_Name = HR.fullName
                    obj.Department = HR.departmentName
                }
                props.form.setFieldsValue(obj)
                setIsModalVisible(false)
            } else {
                message.warning('Data is null')
            }
        }
    }

    const replaceName = (name: string) => {
        return name.replace(/ /g, '_')
    }

    const replaceUserToOwner = (name: string) => {
        return name.replace(/User/g, 'Owner')
    }

    const onSearchRequester = (searchValue) => {
        if (searchValue && tempHR) {
            const HR: People | undefined = (tempHR || PeopleState).find((res: People) => res.employeeId?.toString() === searchValue)
            const obj: any = {}
            if (HR) {
                if (props.postfix) {
                    if (!props.isFromSyncPM) {
                        obj.User_IDAd = HR.employeeId
                        obj.User_NameAd = HR.fullName
                        obj.DepartmentAd = HR.departmentName
                    } else {
                        obj.User_IDAdSync = HR.employeeId
                        obj.User_NameAdSync = HR.fullName
                        obj.DepartmentAdSync = HR.departmentName
                    }
                } else {
                    obj.User_ID = HR.employeeId
                    obj.User_Name = HR.fullName
                    obj.Department = HR.departmentName
                }
                props.form.setFieldsValue(obj)
                setIsModalVisible(false)
            }
        }
    }

    return (
        <Row>
            <Modal title="Search Employee" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form.Item
                    label={''}
                >
                    {props.form.getFieldDecorator('SearchEmployee2')(
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select a person "
                            optionFilterProp="children"
                            onChange={(e) => setSearchValue(e?.toString() || '')}
                            filterOption={(inputValue, option: any) =>
                                (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            allowClear
                        >
                            {(tempHR || PeopleState).sort((a, b) => {
                                return Number(a.id) - Number(b.id)
                            }).map((data: People) => { return <Option value={data.employeeId} key={data.employeeId}>{data.employeeId}</Option> })}
                        </Select>
                    )}
                </Form.Item>
            </Modal>
            {name.map((res, index) => {
                return <Row key={index}>
                    <Col xl={props.fullWitdh ? 0 : 3} md={0} />
                    <Col xl={props.fullWitdh ? 24 : 16} md={24} style={{ marginTop: 10 }}>
                        <Col span={props.fullWitdh ? 10 : props.isPreview ? 4 : 6} xl={props.fullWitdh ? 10 : props.isPreview ? 4 : 6} lg={props.fullWitdh ? 10 : props.isPreview ? 4 : 6} sm={10} xs={10}>
                            {/* className={index === 0 ? 'ant-form-item-required' : ''} */}
                            <div style={{ textAlign: props.fullWitdh ? 'left' : window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4, marginBottom: 8 }} >
                                {replaceUserToOwner(res)}
                            </div>
                        </Col>
                        {props.showCopyCheckbox ? <Col span={14}>
                            <div style={{
                                marginTop: 4,
                                float: 'right'
                            }}>
                                <Checkbox value={replaceName(res)} onChange={(value) => props.handleCopyAsset ? props?.handleCopyAsset(value, name) : null}>Set None</Checkbox>
                            </div>
                        </Col> : null}
                        <Col span={props.fullWitdh ? 24 : 21} xl={props.fullWitdh ? 24 : props.isPreview ? 20 : 18} md={24} sm={24} xs={24}>
                            <Form.Item>
                                {props.form.getFieldDecorator(replaceName(props.postfix ? res + props.postfix : res), {
                                    rules: [{ required: false, message: `Please input your ${replaceUserToOwner(res)}!` }]
                                })(
                                    index === 0 ? <AutoComplete
                                        dataSource={(tempHR || PeopleState).map((it: People) => {
                                            return {
                                                value: it.employeeId?.toString(),
                                                text: it.employeeId?.toString()
                                            } as DataSourceItemObject
                                        })}
                                        style={{ width: '100%' }}
                                        onSelect={onSearchRequester}
                                        disabled={props.disableItem || SetNoneAssetDetailFieldListState.includes(replaceName(res))}
                                        // onChange={() => setRequester(undefined)}
                                        placeholder={`Select ${replaceUserToOwner(res)}`}
                                        allowClear
                                    /> : <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder={`Select ${replaceUserToOwner(res)}`}
                                        optionFilterProp="children"
                                        onChange={(e) => setSearchValue(e?.toString() || '')}
                                        disabled={index !== 0}
                                        filterOption={(inputValue, option: any) =>
                                            (option.props.children as string)?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                                        }
                                        allowClear
                                        id={`${res}`}
                                    >
                                        {(tempHR || PeopleState).sort((a, b) => {
                                            return Number(a.id) - Number(b.id)
                                        }).map((data: People) => { return <Option value={data.id} key={data.id}>{data.employeeId}</Option> })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col xl={props.fullWitdh ? 0 : 5} md={0} ></Col>
                </Row>
            })}
            {name2.map((res, index) => {
                return <Row key={index}>
                    <Col xl={props.fullWitdh ? 0 : 3} md={0} />
                    <Col xl={props.fullWitdh ? 24 : 16} md={24} style={{ marginTop: 10 }}>
                        <Col xl={props.fullWitdh ? 24 : props.isPreview ? 4 : 6} md={24}>
                            <div style={{ textAlign: props.fullWitdh ? 'left' : window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4, marginBottom: 8 }} >
                                {replaceUserToOwner(res)}
                            </div>
                        </Col>
                        <Col span={props.fullWitdh ? 24 : 21} xl={props.fullWitdh ? 24 : props.isPreview ? 20 : 18} md={24} sm={24} xs={24}>
                            <Form.Item>
                                {props.form.getFieldDecorator(replaceName(props.postfix ? res + props.postfix : res))(
                                    <Input placeholder={replaceUserToOwner(res)} disabled={true} />
                                )}
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col xl={props.fullWitdh ? 0 : 5} md={0} ></Col>
                </Row>
            })}
        </Row>
    )
}

export default CustomInputGroupAsset2
