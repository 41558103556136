import { Dispatch } from 'redux'
import { GetAllCategorySuccessEvent } from './state-event'
import { axiosPost, axiosPatch, axiosDelete } from '../rest'
import { Category } from './model'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { checkKmAdminPermission } from '../knowledge'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'

export const getAllCategory = (groupUser?: string[]) => {
    return async (dispatch: Dispatch) => {
        const isAdmin = checkKmAdminPermission()
        try {
            const body = {
                groups: groupUser,
                isAdmin: isAdmin ? 0 : 1
            }
            const encryptData = encryptBody(JSON.stringify(body))
            const response = await axiosPost<Category[]>('/api/categories/criteria', encryptData, herderPostPatch)
            if (response.data.data) {
                dispatch(GetAllCategorySuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err: any) {
            checkRedirect(err?.response?.status)
            throw err.response
        }
    }
}

export const getCetagoryById = async (categoryId: number, groupUser?: string[]) => {
    const isAdmin = checkKmAdminPermission()
    try {
        const body = {
            groups: groupUser,
            isAdmin: isAdmin ? 0 : 1
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<Category>(`/api/categories/${categoryId}/criteria`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const createCategory = async (data: Category) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        return await axiosPost<Category>('/api/categories', encryptData, herderPostPatch)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const updateCategory = async (data: Category) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        return await axiosPatch<Category>(`/api/categories/${data.id}`, encryptData, herderPostPatch)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const deleteCategory = async (categoryId: string) => {
    try {
        return await axiosDelete<Category>(`/api/categories/${categoryId}`)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}
