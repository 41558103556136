import React from 'react'
import moment from 'moment'
import {IncidentState, IncidentWithSlaCalculations} from './index'
import {getLeastTargetTimeOfAllInProgressSlaCalculations, getLeastTargetTimeOfAllInProgressSlaCalculationsReturnModel} from '../misc'
import TimeLeft from '../sla/TimeLeft'
import {Col, Row} from 'antd'
import RunningClock, { RunningClockMode } from '../sla/RunningClock'
import { SlaCalculationFunc } from '../../sla-management/sla-calculation/model'

interface Params {
    incident: IncidentWithSlaCalculations
    isMedium: boolean
    incidentState: IncidentState
}

type Props = Params

const IncidentTimeIndex: React.FC<Props> = (props: Props) => {
    // const getLastTimeStatus = (status: string) => {
    //     let time = ''
    //     for (const history of props.incident.statusHistories!!) {
    //         if (history.status === status && time < history.timestamp) {
    //             time = history.timestamp
    //         }
    //     }
    //     return time
    // }

    const findSlaTargetTimeAndPendingUntil = (slaTarget: string, pendingUntil?: string) => {
        if (window.innerWidth >= 992) {
            if (pendingUntil) {
                return <span >Pending remaining : <TimeLeft until={pendingUntil}/></span>
            } else if (slaTarget) {
                return renderTime() || 'Due in : 0 secs'
                // if (props.incidentState.slaCalculationDataSuit) {
                //     const slaPolicyHistories = props.incidentState.slaCalculationDataSuit!!.slaPolicyHistories
                //     const slaCalculation = getLeastTargetTimeOfAllInProgressSlaCalculationsReturnModel(props.incident.slaCalculations)

                //     const slaPolicyHistory = slaPolicyHistories?.find((it) => it.id === slaCalculation?.slaPolicyHistoryId)
                //     const slaGoal = slaPolicyHistory?.slaGoals?.find((it) => it.name === slaCalculation?.slaGoalName)

                //     if (slaCalculation?.slaStage === 'InProgress') {
                //         if (slaCalculation?.slaStatus === 'Met') {
                //             const durationProvider = function (): moment.Duration {
                //                 if (slaPolicyHistory && slaGoal) {
                //                     return SlaCalculationFunc.calculateInProgressTimeLeftDuration(slaCalculation, slaGoal)
                //                 } else {
                //                     return moment.duration(0)
                //                 }
                //             }
                //             return <span >Due in : <RunningClock durationProvider={durationProvider} mode={RunningClockMode.TimeLeft}/></span>
                //         }
                //     }
                // }

                // return <span >Due in : <TimeLeft until={slaTarget}/></span>
            }
        }
        if (window.innerWidth < 992) {
            if (pendingUntil) {
                return (
                    <Row>
                        <Col xs={9} md={5}><span >Pending remaining :</span></Col>
                        <Col xs={1}></Col>
                        <Col xs={14} md={14}><span > <TimeLeft
                            until={pendingUntil}/></span></Col>
                    </Row>
                )
            } else if (slaTarget) {
                return (
                    <Row>
                        <Col xs={10} md={14}><span >Due in :</span></Col>
                        <span > {renderTime() || 'Due in : 0 secs'}</span>
                    </Row>
                )
            }
        }
    }

    const renderTime = () => {
        if (props.incidentState.slaCalculationDataSuit) {
            const slaPolicyHistories = props.incidentState.slaCalculationDataSuit!!.slaPolicyHistories
            const slaCalculation = getLeastTargetTimeOfAllInProgressSlaCalculationsReturnModel(props.incident.slaCalculations)
            const slaPolicyHistory = slaPolicyHistories?.find((it) => it.id === slaCalculation?.slaPolicyHistoryId)
            const slaGoal = slaPolicyHistory?.slaGoals?.find((it) => it.name === slaCalculation?.slaGoalName)

            if (slaCalculation?.slaStage === 'InProgress') {
                if (slaCalculation?.slaStatus === 'Met') {
                    const durationProvider = function (): moment.Duration {
                        if (slaPolicyHistory && slaGoal) {
                            return SlaCalculationFunc.calculateInProgressTimeLeftDuration(slaCalculation, slaGoal)
                        } else {
                            return moment.duration(0)
                        }
                    }
                    return <span >Due in : <RunningClock durationProvider={durationProvider} mode={RunningClockMode.TimeLeft}/></span>
                }
            }
        }
    }

    const showStatusWithStampTime = (status) => {
        if ((status?.status === 'New' || status?.value === 'New') || (status?.status === 'InProgress' || status?.value === 'InProgress') || (status?.status === 'Assigned' || status?.value === 'Assigned')) {
            return (
                <Col style={{ padding: 0 }}>
                    <Row>
                        {/* <Col lg={6} md={6} xs={10}>
                            <span >Created : </span>
                        </Col>
                        <Col lg={7} md={14} xs={14}>
                            <span>{moment(props.incident.createdDatetime!!).format('YYYY-MM-DD HH:mm:ss')}</span>
                        </Col> */}
                        {!props.isMedium
                            ? <Col span={24} style={{ padding: 0 }}>
                                {findSlaTargetTimeAndPendingUntil(getLeastTargetTimeOfAllInProgressSlaCalculations(props.incident.slaCalculations), props.incident.pendingUntil)}
                            </Col>
                            : null}
                    </Row>
                    {props.isMedium
                        ? <Col>
                            {findSlaTargetTimeAndPendingUntil(getLeastTargetTimeOfAllInProgressSlaCalculations(props.incident.slaCalculations), props.incident.pendingUntil)}
                        </Col>
                        : null}
                </Col>
            )
            // return <span>Created :
            //     <span style={{ marginLeft: 39 }}>{moment(props.incident.createdDatetime!!).format('YYYY-MM-DD HH:mm:ss')}</span>
            //     <span style={{ marginLeft: 39 }}></span>
            // </span>
        } else {
            return (
                <Row>
                    {/* <Col lg={6} md={6} xs={10}>
                        <span >{status?.value || status?.status} : </span>
                    </Col>
                    <Col lg={7} md={14} xs={14}>
                        <span>{moment(props.incident.lastStatusTimestamp!!).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </Col> */}
                    <Col span={24} lg={24} xs={24}>
                        {findSlaTargetTimeAndPendingUntil(getLeastTargetTimeOfAllInProgressSlaCalculations(props.incident.slaCalculations), props.incident.pendingUntil)}
                    </Col>
                </Row>
            )
        }
    }

    return (
        <>
            {showStatusWithStampTime(props.incident.status!!)}
        </>
    )
}
export default IncidentTimeIndex
