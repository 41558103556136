import { Col, DatePicker, Form, Row } from 'antd'
import React from 'react'
import { replaceName } from '../../../common-file'
import moment from 'moment'

interface Params {
    form: any,
    name: string,
    editable: boolean,
    required: boolean,
    disableItem?: boolean,
    dateService: any
}

type Props = Params
const CustomInputDate: React.FC<Props> = (props: Props) => {
    // useEffect(() => {
    //     const dateService = moment(getFieldValue('Date_in_Service'))
    //     const expiryDate = moment(getFieldValue('Expiry_Date'))
    //     if (props.form.getFieldValue('Date_in_Service') === null) {
    //         props.form.resetFields()
    //     }
    //     if (dateService > expiryDate) {
    //         props.form.resetFields(['Expiry_Date'])
    //     }
    // }, [props.form.getFieldValue('Date_in_Service')])
    const disabledDate = (current, disable) => {
        return current && current < moment(disable).startOf('day')
    }
    return (
        <Row>
            <Col xl={3} md={0} />
            <Col xl={16} md={24} style={{ marginTop: 10 }}>
                <Col xl={4} md={24}>
                    <div style={{textAlign: window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4 }} className={props.required ? 'ant-form-item-required' : ''}>
                        {props.name}
                    </div>
                </Col>
                <Col xl={20} md={24}>
                    <Form.Item>
                        <Col md={24}>
                            {props.form.getFieldDecorator(replaceName(props.name), {
                                rules: [
                                    { required: props.required, message: `${props.name} is required` }
                                ]
                            })(
                                props.dateService
                                    ? <DatePicker
                                        placeholder={'Select Date'}
                                        style={{width: '100%'}}
                                        disabled={props.disableItem}
                                        disabledDate={(current) => props.name === 'Expiry Date' ? disabledDate(current, props.dateService) : !disabledDate(current, props.dateService)}
                                    /> : <DatePicker
                                        placeholder={'Select Date'}
                                        style={{width: '100%'}}
                                        disabled={props.disableItem}
                                    />
                            )}
                        </Col>
                    </Form.Item>
                </Col>
            </Col>
            <Col xl={5} md={0} />
        </Row>
    )
}

export default CustomInputDate
