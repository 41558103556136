import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, Col, Form, Row, Select } from 'antd'
import { LocationBuilding } from '../../Preventive/Model'
import { replaceName } from '../../common-file'
import { SetNoneAssetDetailFieldListContext } from '../../Preventive/components/SyncDataPMToAsset/ContextAPI/SetNoneAssetDetailFieldList'

interface Param {
    form: any
    isPreview: boolean
    locationBuildingList: LocationBuilding[]
    dataZone: string[]
    options?: string[]
    required?: boolean
    isEdit?: boolean
    maxInput?: number,
    disableItem?: boolean
    isOpenModel?: boolean,
    getEditMode?: Function,
    isSubmitSuccess?: boolean
    postfix?: string,
    fullWidth?: boolean,
    showCopyCheckbox?: boolean
    handleCopyAsset?: Function
}
type Props = Param
const { Option } = Select
const CustomSelectGroupAsset: React.FC<Props> = (props: Props) => {
    const [isDisable, setIsDisable] = useState<boolean>(props.disableItem || false)
    const [locationList, setLocationList] = useState<string[]>([])
    const [buildingList, setBuildingList] = useState<string[]>([])
    const [floorList, setFloorList] = useState<string[]>([])
    const [site, setSite] = useState('')
    const [location, setLocation] = useState('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const names = ['Site', 'Location', 'Building', 'Floor', 'Zone']
    const { SetNoneAssetDetailFieldListState } = useContext<any>(SetNoneAssetDetailFieldListContext)

    useEffect(() => {
        if (props.form.getFieldValue(props.postfix ? 'Site' + props.postfix : 'Site')) {
            const dataSourceLocation = (props.locationBuildingList || []).filter((res: LocationBuilding) => { return res.site === props.form.getFieldValue(props.postfix ? 'Site' + props.postfix : 'Site') })
            setLocationList(dataSourceLocation.map((res: LocationBuilding) => { return res.location }) || [])
            // const dataSourceBuilding = (props.locationBuildingList || []).map((res: LocationBuilding) => { return res.building }) || []
            // setBuildingList(dataSourceBuilding)
            // const dataSourceFloor = (props.locationBuildingList || []).map((res: LocationBuilding) => { return res.floor }) || []
            // setFloorList(dataSourceFloor)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue(props.postfix ? 'Site' + props.postfix : 'Site')])

    useEffect(() => {
        if (isDisable && props.form.getFieldValue(names)) {
            props.form.resetFields([names])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisable, props.form])

    useEffect(() => {
        if (props.isSubmitSuccess) {
            setIsDisable(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSubmitSuccess])

    useEffect(() => {
        if (props.form.getFieldValue('Location')) {
            const dataSourceBuilding = (props.locationBuildingList || []).filter((res: LocationBuilding) => { return res.location === props.form.getFieldValue('Location') }) || []
            setBuildingList(dataSourceBuilding.map((res: LocationBuilding) => { return res.building }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('Location')])

    useEffect(() => {
        if (props.form.getFieldValue('Building')) {
            const dataSourceFloor = (props.locationBuildingList || []).filter((res: LocationBuilding) => { return res.building === props.form.getFieldValue('Building') }) || []
            setFloorList(dataSourceFloor.map((res: LocationBuilding) => { return res.floor }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('Building')])

    // const toggleDisableItem = () => {
    //     setIsDisable(!isDisable)
    //     if (props.getEditMode) {
    //         props.getEditMode(!isDisable)
    //     }
    // }

    const createOption = (name: string) => {
        let dataSource: string[] = []
        if (name === 'Site') {
            dataSource = (props.locationBuildingList || []).map((res: LocationBuilding) => { return res.site }) || []
            return renderOption(dataSource)
        } else if (name === 'Location') {
            dataSource = (props.locationBuildingList || []).map((res: LocationBuilding) => { return res.location }) || []
            return renderOption(locationList || dataSource)
        } else if (name === 'Building') {
            dataSource = (props.locationBuildingList || []).map((res: LocationBuilding) => { return res.building }) || []
            return renderOption(buildingList || dataSource)
        } else if (name === 'Floor') {
            dataSource = (props.locationBuildingList || []).map((res: LocationBuilding) => { return res.floor }) || []
            return renderOption(floorList || dataSource)
        } else if (name === 'Zone') {
            return renderOption(props.dataZone)
        }
    }

    const renderOption = (dataSource: string[]) => {
        return [...new Set(dataSource)].sort().filter(it => it !== null).map((data) => {
            return (<Option value={data} key={data}>{data}</Option>)
        })
    }

    const handleChange = (name: string, value?: string) => {
        if (name === 'Site') {
            if (value) {
                setLocationList([])
                setBuildingList([])
                setFloorList([])
                props.form.resetFields([props.postfix ? 'Location' + props.postfix : 'Location', props.postfix ? 'Building' + props.postfix : 'Building', props.postfix ? 'Floor' + props.postfix : 'Floor'])
                const LocationFilter: LocationBuilding[] = (props.locationBuildingList).filter((res: LocationBuilding) => res.site === value)
                if (LocationFilter.length === 1) {
                    props.form.setFieldsValue({
                        Location: LocationFilter[0].location ? LocationFilter[0].location : undefined,
                        Building: LocationFilter[0].building ? LocationFilter[0].building : undefined,
                        Floor: LocationFilter[0].floor ? LocationFilter[0].floor : undefined
                    })
                    setLocationList([LocationFilter[0].location])
                    setBuildingList([LocationFilter[0].building])
                    setFloorList([LocationFilter[0].floor])
                } else {
                    const TempData = LocationFilter.map((res: LocationBuilding) => {
                        return res.location
                    })
                    setSite(value)
                    console.log(site)
                    setBuildingList([])
                    setFloorList([])
                    setLocationList(TempData)
                    props.form.resetFields([props.postfix ? 'Location' + props.postfix : 'Location', props.postfix ? 'Building' + props.postfix : 'Building', props.postfix ? 'Floor' + props.postfix : 'Floor'])
                    if ([...new Set(TempData)].length === 1) {
                        props.form.setFieldsValue({
                            Location: TempData[0]
                        })
                    }
                }
            } else {
                props.form.resetFields([props.postfix ? 'Location' + props.postfix : 'Location', props.postfix ? 'Building' + props.postfix : 'Building', props.postfix ? 'Floor' + props.postfix : 'Floor'])
                setLocationList([])
                setBuildingList([])
                setFloorList([])
            }
        } else if (name === 'Location') {
            if (value) {
                const BuildingFilter: LocationBuilding[] = (props.locationBuildingList).filter((res: LocationBuilding) => res.location === value && res.site === props.form.getFieldValue(props.postfix ? 'Site' + props.postfix : 'Site'))
                const TempData = BuildingFilter.map((res: LocationBuilding) => {
                    return res.building
                })
                setLocation(value)
                setFloorList([])
                setBuildingList(TempData)
                props.form.resetFields([props.postfix ? 'Building' + props.postfix : 'Building', props.postfix ? 'Floor' + props.postfix : 'Floor'])
                if ([...new Set(TempData)].length === 1) {
                    const TempDataFloor = BuildingFilter.map((res: LocationBuilding) => {
                        return res.floor
                    })
                    setFloorList(TempDataFloor || ['-'])
                }
                if (BuildingFilter.length === 1) {
                    props.form.setFieldsValue({
                        Building: BuildingFilter[0].building ? BuildingFilter[0].building : undefined,
                        Floor: BuildingFilter[0].floor ? BuildingFilter[0].floor : undefined
                    })
                } else {
                    const TempData = BuildingFilter.map((res: LocationBuilding) => {
                        return res.building
                    })
                    setLocation(value)
                    setFloorList([])
                    setBuildingList(TempData)
                    props.form.resetFields(['Building', 'Floor'])
                    if ([...new Set(TempData)].length === 1) {
                        const TempDataFloor = BuildingFilter.map((res: LocationBuilding) => {
                            return res.floor
                        })
                        setFloorList(TempDataFloor || ['-'])
                        props.form.setFieldsValue({
                            Building: TempData[0]
                        })
                    }
                }
            } else {
                props.form.resetFields([props.postfix ? 'Building' + props.postfix : 'Building', props.postfix ? 'Floor' + props.postfix : 'Floor'])
                setBuildingList([])
                setFloorList([])
            }
        } else if (name === 'Building') {
            if (value) {
                const FloorFilter: LocationBuilding[] = (props.locationBuildingList).filter((res: LocationBuilding) => res.building === value && res.location === location)
                const TempData = FloorFilter.map((res: LocationBuilding) => {
                    return res.floor
                })
                setFloorList(TempData)
                props.form.resetFields([props.postfix ? 'Floor' + props.postfix : 'Floor'])
                if (FloorFilter.length === 1) {
                    props.form.setFieldsValue({
                        Floor: FloorFilter[0].floor ? FloorFilter[0].floor : undefined
                    })
                } else {
                    const TempData = FloorFilter.map((res: LocationBuilding) => {
                        return res.floor
                    })
                    setFloorList(TempData)
                    props.form.resetFields(['Floor'])
                }
            } else {
                props.form.resetFields([props.postfix ? 'Floor' + props.postfix : 'Floor'])
                setFloorList([])
            }
        }
    }

    return (
        <>
            {names.map((name, index) => {
                return <Row key={name}>
                    <Col xl={props.fullWidth ? 0 : 3} md={0} />
                    <Col xl={props.fullWidth ? 24 : 16} md={24} style={{ marginTop: 10 }} key={name}>
                        <Col span={props.fullWidth ? 10 : props.isPreview ? 4 : 6}>
                            <div style={{ textAlign: props.fullWidth ? 'left' : window.innerWidth > 1024 ? 'right' : 'left', marginRight: 8, marginTop: 4, marginBottom: 8 }}>
                                {name}
                            </div>
                        </Col>
                        {props.showCopyCheckbox && (name === 'Site' || name === 'Zone') ? <Col span={14}>
                            <div style={{
                                marginTop: 4,
                                float: 'right'
                            }}>
                                <Checkbox value={replaceName(name)} onChange={(value) => props.handleCopyAsset ? props?.handleCopyAsset(value, name) : null}>Set None</Checkbox>
                            </div>
                        </Col> : null}
                        <Col span={props.fullWidth ? 24 : 21} xl={props.fullWidth ? 24 : props.isPreview ? 20 : 18} md={24} sm={24} xs={24}>
                            <Form.Item>
                                {props.form.getFieldDecorator((props.postfix ? name + props.postfix : name), {
                                    rules: [{
                                        required: false, // props.isEdit || !props.isPreview,
                                        message: `Please input your ${name}!`
                                    }]
                                })(
                                    <Select
                                        placeholder={`Select ${name}`}
                                        style={{ width: '100%' }}
                                        // Zone seperate from site location building floor
                                        disabled={props.disableItem || (name !== 'Zone' ? SetNoneAssetDetailFieldListState.includes(replaceName('Site')) : SetNoneAssetDetailFieldListState.includes(replaceName(name)))}
                                        filterOption={(inputValue, option: any) =>
                                            (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={(e) => handleChange(name, e?.toString())}
                                        loading={props.locationBuildingList?.length === 0}
                                        allowClear={true}
                                        showSearch
                                    >{createOption(name)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Col>
                    <Col xl={props.fullWidth ? 0 : 5} md={props.isEdit && index === 0 ? 2 : 0} sm={props.isEdit && index === 0 ? 2 : 0} xs={props.isEdit && index === 0 ? 2 : 0}>
                        {/* {props.isEdit && index === 0 ? isDisable ? <Icon type='close' style={{ marginTop: window.innerWidth < 768 ? 15 : 25, marginLeft: 10 }} className="feck-cancel" onClick={() => toggleDisableItem()} /> : <Icon type='edit' style={{ marginTop: window.innerWidth < 768 ? 15 : 25, marginLeft: 10 }} className="feck-link" onClick={() => toggleDisableItem()} /> : null} */}
                    </Col>
                </Row>
            })}
        </>
    )
}

export default CustomSelectGroupAsset
