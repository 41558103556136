import { Dispatch } from 'redux'
import {
    DeleteRoleAndPermissionsEvent,
    GetAllRoleAndPermissionsOptionsSuccessEvent,
    GetAllRoleAndPermissionsSuccessEvent,
    UpdatePageRoleAndPermissionsEvent,
    UpdateTotalContentRoleAndPermissionsEvent,
    GetAllRoleKMSuccessEvent
} from './state-event'
import { axiosDelete, axiosGet, axiosGetWithPagination, axiosPatch, axiosPost } from '../rest'
import { RoleAndPermissions, LineNotify } from './model'
import { permissionMapping, RoleState } from '../permissions'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'

const tempMapping: RoleState[] = []

export const getAllRoleAndPermissionsOptions = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<RoleAndPermissions>('/api/roles/permissions')
            const searchResult = decryptBody(response.data.data!!)
            const result = permissionMappingNonScope(JSON.stringify(searchResult))
            dispatch(GetAllRoleAndPermissionsOptionsSuccessEvent.build(result))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err.response
        }
    }
}

export const getAllRoleAndPermissions = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<RoleAndPermissions[]>('/api/roles')
            dispatch(GetAllRoleAndPermissionsSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err.response
        }
    }
}

export const getAllRoleAndPermissionsWithPaging = (page: number, pageSize: number, sortBy?: string, orderBy?: string) => {
    sortBy = sortBy === undefined ? 'createdDatetime' : sortBy
    orderBy = orderBy === undefined ? 'desc' : orderBy
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    page: page - 1,
                    size: pageSize,
                    sort: `${sortBy},${orderBy}`
                }
            }
            const url = '/api/roles/pagination'
            const response = await axiosGetWithPagination<RoleAndPermissions[]>(url, config)
            const searchResult = decryptBody(response.data.data!!)
            dispatch(UpdatePageRoleAndPermissionsEvent.build(searchResult.number + 1))
            dispatch(UpdateTotalContentRoleAndPermissionsEvent.build(searchResult.totalElements || searchResult?.total || 0))
            dispatch(GetAllRoleAndPermissionsSuccessEvent.build(searchResult.content || []))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const getRoleAndPermissionsById = async (RoleAndPermissionsId: number) => {
    try {
        const response = await axiosGet<RoleAndPermissions>(`/api/roles/${RoleAndPermissionsId}`)
        const searchResult = decryptBody(response.data.data!!)
        const temp = permissionMapping(JSON.stringify(searchResult), true)
        return temp
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const createRoleAndPermissions = async (data: RoleAndPermissions) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        return await axiosPost<RoleAndPermissions>('/api/roles', encryptData, herderPostPatch)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const updateRoleAndPermissions = async (data: RoleAndPermissions, id: string) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        return await axiosPatch<RoleAndPermissions>(`/api/roles/${id}`, encryptData, herderPostPatch)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const deleteRoleAndPermissions = (RoleAndPermissionsId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const respone = await axiosDelete<RoleAndPermissions>(`/api/roles/${RoleAndPermissionsId}`)
            dispatch(DeleteRoleAndPermissionsEvent.build(RoleAndPermissionsId))
            return respone.status
        } catch (err: any) {
            checkRedirect(err?.response?.status)
            throw err.response
        }
    }
}

export async function sendLineNotify(message: string, group: string) {
    try {
        const formData = new FormData()
        const obj = { msg: message, group: group }
        for (const key in obj) {
            formData.append(key, obj[key])
        }
        const response = await axiosPost<LineNotify>('/api/line-notify/sendMessages', formData)
        return response.status
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        console.log(err)
        throw err
    }
}

export const getKmMapRole = (email: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<string[]>(`/api/groups/current/${email}`)
            if (response.data.data) {
                dispatch(GetAllRoleKMSuccessEvent.build(decryptBody(response.data.data!!)))
                return decryptBody(response.data.data!!)
            } else {
                return []
            }
        } catch (err: any) {
            checkRedirect(err?.response?.status)
            throw err.response
        }
    }
}

export const CheckDupRoleName = async (roleName: string) => {
    try {
        const config = {
            params: {
                name: roleName
            }
        }
        const response = await axiosGet<RoleAndPermissions>('/api/roles/check-duplicate', config)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const permissionMappingNonScope = (value: string) => {
    let i
    const temp = JSON.parse(value)
    if (value) {
        if (tempMapping.length === 0) {
            if (temp.Admin !== undefined && temp.Admin?.length !== 0) {
                for (i = 0; i < temp.Admin.length; i++) {
                    if (temp.Admin[i] === '01') temp.Admin[i] = 'ManageRoleAndPermission'
                    if (temp.Admin[i] === '02') temp.Admin[i] = 'ManagePeopleUserGroup'
                    if (temp.Admin[i] === '03') temp.Admin[i] = 'ManageGeneralSetting'
                    if (temp.Admin[i] === '04') temp.Admin[i] = 'ManageTicketSetting'
                    if (temp.Admin[i] === '05') temp.Admin[i] = 'ManageSLASetting'
                    if (temp.Admin[i] === '06') temp.Admin[i] = 'ManageWorkflow'
                    if (temp.Admin[i] === '07') temp.Admin[i] = 'ManageProblemSetting'
                    if (temp.Admin[i] === '08') temp.Admin[i] = 'ManageAssetSetting'
                }
            }
            if (temp.Security !== undefined && temp.Security.length !== 0) {
                for (i = 0; i < temp.Security.length; i++) {
                    // if (temp.Security[i] === '11') temp.Security[i] = 'ManageRoleAndPermission'
                    // if (temp.Security[i] === '12') temp.Security[i] = 'ManagePeopleUserGroup'
                    // if (temp.Security[i] === '13') temp.Security[i] = 'ManageGeneralSetting'
                    // if (temp.Security[i] === '14') temp.Security[i] = 'ManageTicketSetting'
                    // if (temp.Security[i] === '15') temp.Security[i] = 'ManageSLASetting'

                    if (temp.Security[i] === '11') temp.Security[i] = 'ManageTicketAccess'
                    if (temp.Security[i] === '12') temp.Security[i] = 'ManageKnowledgeAccess'
                    if (temp.Security[i] === '13') temp.Security[i] = 'ManageAssetAccess'
                    if (temp.Security[i] === '14') temp.Security[i] = 'ManagePMAccess'
                    if (temp.Security[i] === '15') temp.Security[i] = 'ManageServiceCatalogAccess'
                    if (temp.Security[i] === '16') temp.Security[i] = 'ManageWorkOrderAccess'
                    if (temp.Security[i] === '17') temp.Security[i] = 'ManageProblemAccess'
                }
            }
            if (temp.Incident.length !== 0) {
                for (i = 0; i < temp.Incident.length; i++) {
                    if (temp.Incident[i] === '21') temp.Incident[i] = 'CreateIncident'
                    if (temp.Incident[i] === '22') temp.Incident[i] = 'UpdateIncident'
                    if (temp.Incident[i] === '23') temp.Incident[i] = 'DeleteIncident'
                    if (temp.Incident[i] === '24') temp.Incident[i] = 'ViewIncident'
                    if (temp.Incident[i] === '25') temp.Incident[i] = 'CloseIncident'
                    if (temp.Incident[i] === '26') temp.Incident[i] = 'SatisfactionTicket'
                    if (temp.Incident[i] === '27') temp.Incident[i] = 'ViewReportTicket'
                }
            }
            if (temp.Knowledge.length !== 0) {
                for (i = 0; i < temp.Knowledge.length; i++) {
                    // if (temp.Knowledge[i] === '31') temp.Knowledge[i] = 'CreateKnowledge'
                    // if (temp.Knowledge[i] === '32') temp.Knowledge[i] = 'UpdateKnowledge'
                    // if (temp.Knowledge[i] === '33') temp.Knowledge[i] = 'DeleteKnowledge'
                    // if (temp.Knowledge[i] === '34') temp.Knowledge[i] = 'ViewKnowledge'
                    // if (temp.Knowledge[i] === '35') temp.Knowledge[i] = 'ApproveOrRejectKnowledge'
                    if (temp.Knowledge[i] === '31') temp.Knowledge[i] = 'ManageCategoryFolderArticle'
                    if (temp.Knowledge[i] === '32') temp.Knowledge[i] = 'ApproveOrRejectKnowledge'
                }
            }
            if (temp.Asset.length !== 0) {
                for (i = 0; i < temp.Asset.length; i++) {
                    if (temp.Asset[i] === '41') temp.Asset[i] = 'CreateAsset'
                    if (temp.Asset[i] === '42') temp.Asset[i] = 'UpdateAsset'
                    if (temp.Asset[i] === '43') temp.Asset[i] = 'DeleteAsset'
                    if (temp.Asset[i] === '44') temp.Asset[i] = 'ViewAsset'
                    if (temp.Asset[i] === '45') temp.Asset[i] = 'ViewAssetReport'
                }
            }
            if (temp.PM.length !== 0) {
                for (i = 0; i < temp.PM.length; i++) {
                    if (temp.PM[i] === '51') temp.PM[i] = 'CreateUpdateDeletePMPlan'
                    if (temp.PM[i] === '52') temp.PM[i] = 'PMList'
                    if (temp.PM[i] === '53') temp.PM[i] = 'AddNewAsset'
                    if (temp.PM[i] === '54') temp.PM[i] = 'CompletedPM'
                    if (temp.PM[i] === '55') temp.PM[i] = 'ReportPM'
                    if (temp.PM[i] === '56') temp.PM[i] = 'ApprovePM'
                }
            }
            if (temp.ServiceCatalog.length !== 0) {
                for (i = 0; i < temp.ServiceCatalog.length; i++) {
                    if (temp.ServiceCatalog[i] === '61') temp.ServiceCatalog[i] = 'ManageCatalogCategoryItemCatalog'
                    // if (temp.ServiceCatalog[i] === '62') temp.ServiceCatalog[i] = 'ManageWorkflow'
                    // if (temp.ServiceCatalog[i] === '63') temp.ServiceCatalog[i] = 'ApproveOrRejectWorkorder'
                    // if (temp.ServiceCatalog[i] === '64') temp.ServiceCatalog[i] = 'ApproveOrRejectJob'
                }
            }
            if (temp.WorkOrder.length !== 0) {
                for (i = 0; i < temp.WorkOrder.length; i++) {
                    if (temp.WorkOrder[i] === '71') temp.WorkOrder[i] = 'CreateWorkOrder'
                    if (temp.WorkOrder[i] === '72') temp.WorkOrder[i] = 'UpdateWorkOrder'
                    if (temp.WorkOrder[i] === '73') temp.WorkOrder[i] = 'UpdateJob'
                    if (temp.WorkOrder[i] === '74') temp.WorkOrder[i] = 'CloseWorkOrder'
                    if (temp.WorkOrder[i] === '75') temp.WorkOrder[i] = 'ApproveOrRejectWorkOrder'
                    if (temp.WorkOrder[i] === '76') temp.WorkOrder[i] = 'ApproveOrRejectJob'
                    if (temp.WorkOrder[i] === '77') temp.WorkOrder[i] = 'ViewWorkOrder'
                    if (temp.WorkOrder[i] === '78') temp.WorkOrder[i] = 'ViewReportWorkOrder'
                }
            }
            if (temp.Problem.length !== 0) {
                for (let i = 0; i < temp.Problem.length; i++) {
                    if (temp.Problem[i] === '81') temp.Problem[i] = 'CreateProblem'
                    if (temp.Problem[i] === '82') temp.Problem[i] = 'UpdateProblem'
                    if (temp.Problem[i] === '83') temp.Problem[i] = 'ViewProblem'
                    if (temp.Problem[i] === '84') temp.Problem[i] = 'CloseProblem'
                    if (temp.Problem[i] === '85') temp.Problem[i] = 'CreateProblemTask'
                    if (temp.Problem[i] === '86') temp.Problem[i] = 'UpdateProblemTask'
                    if (temp.Problem[i] === '87') temp.Problem[i] = 'ApprovalProblem'
                }
            }
            tempMapping.push(temp)
            return temp
        } else {
            return tempMapping[0]
        }
    }
}
