import React, {useContext, useEffect, useState} from 'react'
import {Breadcrumb, Collapse, Col, Row, Steps, Tabs, Table} from 'antd'
import Step1Form from './components/Step1Form'
import Step2Form from './components/Step2Form'
import {Asset, pmAdjustData, PreventiveWithAsset} from './Model'
import {Link, useParams} from 'react-router-dom'
import Step3Form from './components/Step3Form'
import Step4Form from './components/Step4Form'
import {PreventiveContext} from './ContextAPI/PreventiveContext'
import {getHistoryById, getPreventiveById, getDataInfoPmWorklog} from './services'
import {People} from '../authorization-module/people/model'
import {PMActivityField} from '../configurable-fields'
import moment from 'moment'

const { Step } = Steps

const steps = [
    {
        title: 'On site',
        content: 'First-content'
    },
    {
        title: 'Validate Asset Details',
        content: 'Second-content'
    },
    {
        title: 'PM Activity',
        content: 'Last-content'
    },
    {
        title: 'Signature',
        content: 'Last-content'
    }
]

interface Param {

}
type Props = Param
const { TabPane } = Tabs
const { Panel } = Collapse
const PMSteps: React.FC<Props> = (props: Props) => {
    const [current, setCurrent] = useState<number>(0)
    const [dataPM, setDataPM] = useState<PreventiveWithAsset>()
    const [dataAsset, setDataAsset] = useState<Asset>()
    const [dataHR, setDataHR] = useState<People>()
    const [pmAdjustDatas, setPmAdjustDatas] = useState<pmAdjustData>()
    const [online, setOnline] = useState(window.navigator.onLine)
    const [isUserSignature, setIsUserSignature] = useState<boolean>(false)
    const [serialNumber, setSerialNumber] = useState('')
    const [PmActivity, setPmActivity] = useState<PMActivityField[]>([])
    const [OptionRemark, setOptionRemark] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [ticketChangeHistory, setTicketChangeHistory] = useState<any[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [dataInFoWorklog, setDataInFoWorklog] = useState<any>()

    const { id }: any = useParams()
    const params = new URLSearchParams(window.location.search)
    const { PreventiveState }: any = useContext(PreventiveContext)

    useEffect(() => {
        window.addEventListener('online', () => setOnline(true))
        window.addEventListener('offline', () => setOnline(false))
        // setTimeout(() => {
        //     localStorage.clear()
        // }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (params.get('serialNumber') != null) {
            setSerialNumber(params.get('serialNumber')!!)
            FetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PreventiveState, id, online])

    useEffect(() => {
        if (isOpen) {
            getHistoryById(dataPM?.preventiveMaintenance.id, page, pageSize).then((result) => {
                if (result) {
                    setTicketChangeHistory(result.historyPM)
                    setTotal(result.total)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, isOpen])

    const FetchData = () => {
        setIsLoading(true)
        getPreventiveById(id, params.get('serialNumber')!!).then((res) => {
            if (res) {
                const convertData: PreventiveWithAsset = {
                    preventiveMaintenance: res,
                    asset: res.asset
                }
                console.log('getPreventiveById', res)
                setDataPM(convertData)
                setDataAsset(res.asset)
                setCurrent(res.currentStep)
                setDataHR(res.asset.people)
                setPmAdjustDatas(res.pmAdjust)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getPmAdjustDataId = (adjustData: pmAdjustData) => {
        setPmAdjustDatas(adjustData)
    }

    const getPmActivity = (Pm: PMActivityField[]) => {
        if (Pm) {
            setPmActivity(Pm)
        }
    }

    const getIsUserSignature = (value: boolean) => {
        setIsUserSignature(value)
    }

    const renderType = (value: string | undefined): string => {
        let temp = ''
        if (value) {
            if (value === 'ITAsset') {
                temp = 'IT Asset'
            } else {
                temp = value
            }
        }
        return temp
    }

    const getInitRemarkOption = (value: any[]) => {
        if (value) {
            setOptionRemark([...new Set(value)])
        }
    }

    const getReworked = (isRework) => {
        if (isRework) {
            FetchData()
        }
    }

    const openChange = () => {
        setIsOpen(!isOpen)
    }

    const columnsChangeHistory = [
        {
            title: 'Date/Time',
            dataIndex: 'createdDatetime',
            render: (text, row, index) => {
                return (moment(row.createdDatetime).format('YYYY-MM-DD HH:mm:ss'))
            }
        },
        {
            title: 'Details',
            dataIndex: 'details'
        },
        {
            title: 'By',
            dataIndex: 'lastModifiedBy'
        }
    ]

    const onchangeTable = (event) => {
        setPage(event)
    }

    const handleChangeTabs = (e) => {
        if (e === '2') {
            FetchDataInFo()
        }
    }

    const FetchDataInFo = () => {
        setIsLoading(true)
        getDataInfoPmWorklog(dataPM?.preventiveMaintenance.id).then((res) => {
            setDataInFoWorklog(res)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>{id ? <Link to="/Preventive">Preventive Maintenance</Link> : 'Preventive Maintenance'}</Breadcrumb.Item>
                <Breadcrumb.Item>PM Activity (Step 1-4)</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Tabs type="card" className='tab-margin-custom' onChange={(e) => handleChangeTabs(e)}>
                <TabPane tab="Preventive Maintenance Form" key="1" >
                    <div style={{ borderBottom: 'none', paddingTop: '23px', border: '2px solid #ebebeb', paddingBottom: 23 }} >
                        <div className="main-title" style={{ marginLeft: 25 }}><p>{serialNumber}  / {renderType(dataPM?.asset?.assetGroup?.name) || renderType(dataAsset?.assetGroup?.name)}</p></div>
                        <Row>
                            <Col span={4} xs={1} sm={1} md={1} lg={4}></Col>
                            <Col span={16} xs={22} sm={22} md={22} lg={22} xl={16}>
                                <Steps current={current}
                                    direction={window.innerWidth < 768 ? 'vertical' : 'horizontal'}
                                >
                                    {steps.map((item) => (
                                        <Step key={item.title} title={item.title} disabled={dataPM?.preventiveMaintenance?.userSignature != null || isUserSignature} />
                                    ))}
                                </Steps>
                                <br />
                                <div className="steps-content">
                                    {current === 0 ? <Step1Form current={current} setCurrent={setCurrent}
                                        dataPM={dataPM} isOnline={online} pmAdjusted={pmAdjustDatas} /> : null}
                                    {current === 1 ? <Step2Form current={current} setCurrent={setCurrent}
                                        Group={dataPM?.asset?.assetGroup?.name || dataAsset?.assetGroup?.name}
                                        dataPM={dataPM} dataAsset={dataAsset} isOnline={online}
                                        dataHR={dataHR} getInitRemarkOption={getInitRemarkOption}
                                        getPmAdjustDataId={getPmAdjustDataId} pmAdjusted={pmAdjustDatas} /> : null}
                                    {current === 2 ? <Step3Form current={current} setCurrent={setCurrent}
                                        dataPM={dataPM} isOnline={online}
                                        pmAdjusted={pmAdjustDatas} OptionRemark={OptionRemark}
                                        Group={dataPM?.asset?.assetGroup?.name || dataAsset?.assetGroup?.name} getPmActivity={getPmActivity}/> : null}
                                    {current === 3 ? <Step4Form current={current} setCurrent={setCurrent}
                                        Group={dataPM?.asset?.assetGroup?.name || dataAsset?.assetGroup?.name}
                                        dataPM={dataPM} dataAsset={dataAsset}
                                        isOnline={online} pmAdjusted={pmAdjustDatas}
                                        dataHR={dataHR} getReworked={getReworked} isLoadingData={isLoading}
                                        getIsUserSignature={getIsUserSignature} PmActivity={PmActivity} /> : null}
                                </div>
                            </Col>
                            <Col span={4} xs={1} sm={1} md={1} lg={4}></Col>
                        </Row>
                    </div>
                </TabPane>
                <TabPane tab="Worklog" key="2">
                    <div style={{ borderBottom: 'none', paddingTop: '23px', border: '2px solid #ebebeb', paddingBottom: 23 }}>
                        <div className="main-title" style={{ marginLeft: 25 }}><p>{serialNumber}  / {renderType(dataPM?.asset?.assetGroup?.name) || renderType(dataAsset?.assetGroup?.name)}</p></div>
                        <br/>
                        <div style={{ margin: 23, marginTop: 0 }}>
                            <div style={{ backgroundColor: '#F2F2F2', padding: 23 }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                Created by : {dataInFoWorklog?.createdBy}
                                    </Col>
                                    <Col span={12}>
                                Last modified by : {dataInFoWorklog?.lastModifiedBy}
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                Created date time : {dataInFoWorklog?.createdDatetime}
                                    </Col>
                                    <Col span={12}>
                                Last modified date time : {dataInFoWorklog?.lastModifiedDatetime}
                                    </Col>
                                </Row>
                            </div>
                            <br/>
                            <Collapse defaultActiveKey={['0']} onChange={openChange}>
                                <Panel header="History" key="1">
                                    <div style={{ overflow: 'auto' }}>
                                        <Table
                                            columns={columnsChangeHistory}
                                            dataSource={ticketChangeHistory}
                                            size="middle"
                                            pagination={{
                                                pageSize: pageSize,
                                                current: page,
                                                total: total,
                                                showSizeChanger: true,
                                                onShowSizeChange(current, size) {
                                                    setPageSize(size)
                                                    setPage(1)
                                                },
                                                pageSizeOptions: ['10', '30', '50', '80', '100'],
                                                size: 'medium',
                                                onChange: (event) => { onchangeTable(event) }
                                            }}
                                        />
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </TabPane>
            </Tabs>

        </div>
    )
}

export default PMSteps
