/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    AutoComplete,
    Breadcrumb,
    Button,
    Card,
    Col,
    DatePicker,
    Icon,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Upload
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { Space } from '../../common-components/Space'
import {
    checkFieldDuplicate,
    createAssetOwnerLocation,
    getAllAssetGroup,
    getAssetFilter,
    getFieldDetailAsset,
    searchFetch
} from './service'
import { UploadFile } from 'antd/lib/upload/interface'
import Form, { FormComponentProps } from 'antd/lib/form'
import exportIcon from '../../common-file/icon-file'
import { StoreState } from '../../store'
import { decryptDataVspace, DownloadAnyFile } from '../../common-misc'
import RenderFiled from '../../configurable-fields/RenderFiled'
import { AssetGroups, CustomFiledAsset } from '../../configurable-fields'
import moment from 'moment'
import { Asset, AssetGroupType, LocationBuilding } from '../../Preventive/Model'
import { getAllSerialNumber, getDataAssetGroup, getDataItems, getDataLocationBuilding } from '../../Preventive/services'
import CustomSelectGroupAsset from './CustomSelectGroupAsset'
import { PeopleContext } from '../../Preventive/ContextAPI/UserHRContext'
import CustomInputGroupAsset2 from './CustomInputGroupAsset2'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../file-server-storage/model'

const mapStateToProps = (state: StoreState) => {
    return {
        filterAsset: state.filterAsset
    }
}

interface DispatchProps {
    getAssetFilter: () => Promise<number>
}

type StateProps = ReturnType<typeof mapStateToProps>
type Props = FormComponentProps & StateProps & DispatchProps
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const { confirm } = Modal
const { Option } = Select
const AssetForm: React.FC<Props> = (props: Props) => {
    const history = useHistory()
    const [, setvSpaceName] = useState<string>('')
    const { getFieldDecorator, getFieldValue, resetFields } = props.form
    const [isLoading, setIsLoading] = useState<boolean>()
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const loading = false
    const [dataComputerName, setDataComputerName] = useState<string[]>([])
    const [computerName, setComputerName] = useState<string[]>([])
    const [dataBrand, setDataBrand] = useState<string[]>([])
    const [brand, setBrand] = useState<string[]>([])
    const [dataModel, setDataModel] = useState<string[]>([])
    const [model, setModel] = useState<string[]>([])
    const [dataZone, setDataZone] = useState<string[]>([])
    const [, setZone] = useState<string[]>([])
    const [dataSerialNumber, setDataSerialNumber] = useState<string[]>([])
    const [serialNumber, setSerialNumber] = useState<string[]>([])
    const [status, setStatus] = useState<string[]>([])
    const [assetGroups, setAssetGroups] = useState<AssetGroupType[]>([])
    const [LocationBuildings, setLocationBuildings] = useState<LocationBuilding[]>([])
    const [CustomFiledAssetDetails, setCustomFiledAssetDetails] = useState<CustomFiledAsset[]>([])
    const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false)
    const { PeopleState } = useContext<any>(PeopleContext)
    const [assetType, setAssetType] = useState<AssetGroups[]>([])
    const [messageError, setMessageError] = useState('')
    const [isLoadingAssetType, setIsLoadingAssetType] = useState(false)
    const isDesktop = window.innerWidth > 1024

    useEffect(() => {
        const dataVspace = decryptDataVspace()
        if (typeof dataVspace === 'object') {
            const email = dataVspace.email.toString()
            setvSpaceName(email)
        }
        loadFilter()
        getDataAssetGroup().then((res) => {
            setAssetGroups(res || [])
        })
        getDataLocationBuilding().then((res) => {
            setLocationBuildings(res)
        })
        // getAllPeople().then((res) => {
        //     if (GetPeople) {
        //         GetPeople(res)
        //     }
        // })
        getAllSerialNumber().then((res) => {
            if (res) {
                setDataSerialNumber(res)
                setSerialNumber(res.slice(0, 20) || [])
            }
        })
        setIsLoadingAssetType(true)
        getAllAssetGroup().then((res) => {
            if (res) {
                setAssetType(res)
            }
        }).finally(() => {
            setIsLoadingAssetType(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (getFieldValue('Group')) {
            setIsLoadingCustomFields(true)
            const promiseAll: any[] = []
            promiseAll.push(getFieldDetailAsset(getFieldValue('Group')).then((res) => {
                if (res) {
                    setCustomFiledAssetDetails(res)
                }
            }))
            // promiseAll.push(getFieldDetailAsset(getFieldValue('Group'), 'LocationDetails').then((res) => {
            //     if (res) {
            //         setCustomFiledLocationDetails(res)
            //     }
            // }))
            // promiseAll.push(getFieldDetailAsset(getFieldValue('Group'), 'UserDetails').then((res) => {
            //     if (res) {
            //         setCustomFiledUserDetails(res)
            //     }
            // }))
            // promiseAll.push(getFieldDetailAsset(getFieldValue('Group'), 'SETPeripheralDetails').then((res) => {
            //     if (res) {
            //         setCustomFiledLoanDetails(res)
            //     }
            // }))
            // promiseAll.push(getFieldDetailAsset(getFieldValue('Group'), 'DateDetails').then((res) => {
            //     if (res) {
            //         setCustomFiledDateDetails(res)
            //     }
            // }))
            Promise.all(promiseAll).finally(() => {
                setIsLoadingCustomFields(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('Group')])

    useEffect(() => {
        // if (getFieldValue('Group') === 1) {
        //     setDeleteLoanDetail(true)
        // } else {
        //     setDeleteLoanDetail(false)
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('Group')])

    useEffect(() => {
        const dateService = moment(getFieldValue('Date_in_Service'))
        const expiryDate = moment(getFieldValue('Expiry_Date'))
        if (props.form.getFieldValue('Date_in_Service') === null) {
            props.form.resetFields(['Expiry_Date'])
        }
        if (dateService > expiryDate) {
            props.form.resetFields(['Expiry_Date'])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.getFieldValue('Date_in_Service')])

    const loadFilter = async () => {
        getDataItems('zone').then((res) => {
            console.log(res)
            if (res?.length !== 0) {
                setDataZone(res)
                setZone(res.slice(0, 20) || [])
            } else {
                setDataZone(['-'])
                setZone(['-'])
            }
        })
        getDataItems('model').then((res) => {
            setDataModel(res)
            setModel(res.slice(0, 20) || [])
        })
        getDataItems('assetName').then((res) => {
            setDataComputerName(res)
            setComputerName(res.slice(0, 20) || [])
        })
        getDataItems('brand').then((res) => {
            setDataBrand(res)
            setBrand(res.slice(0, 20) || [])
        })
        getDataItems('assetStatus').then((res) => {
            setStatus(res.slice(0, 20) || [])
        })
    }

    const uploadButton = (
        <div>
            <Icon type={loading ? 'loading' : 'plus'} />
            <div className="ant-upload-text">Upload</div>
        </div>
    )

    const replaceName = (name: string) => {
        return name.replace(/ /g, '_')
    }

    const handleBlurValidateSerialNoUniqueness = () => {
        const name: string | undefined = getFieldValue('Serial_Number')
        if (name) {
            checkFieldDuplicate(name.trim(), undefined).then(() => {
                setMessageError('')
            }).catch((err: Error) => {
                if (err.message === 'Request failed with status code 409') {
                    setMessageError('Serial Number. is already exists. It must be unique. Please try another one.')
                } else {
                    setMessageError('')
                    message.error(err.message)
                }
            })
        }
    }

    // const validateSerialNoUniqueness = (_: any, value: any, callback) => {
    //     if (value) {
    //         checkFieldDuplicate(value, undefined).then(() => {
    //             callback()
    //         }).catch((err: Error) => {
    //             if (err.message === 'Request failed with status code 409') {
    //                 callback('Serial No. is already exists. It must be unique. Please try another one.')
    //             } else {
    //                 message.error(err.message)
    //             }
    //         })
    //     } else {
    //         callback()
    //     }
    // }

    // const validateAssetTagUniqueness = (_: any, value: any, callback) => {
    //     if (value) {
    //         checkFieldDuplicate(undefined, value).then(() => {
    //             callback()
    //         }).catch((err: Error) => {
    //             if (err.message === 'Request failed with status code 409') {
    //                 callback('Asset Tag is already exists. It must be unique. Please try another one.')
    //             } else {
    //                 message.error(err.message)
    //             }
    //         })
    //     } else {
    //         callback()
    //     }
    // }

    const customCheckDuplicateInput = (name: string,
        required: boolean,
        validator: Object[],
        maxLength?: number) => {
        return (
            <Row>
                <Col xl={3} md={0} />
                <Col xl={16} md={24} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{
                            textAlign: isDesktop ? 'right' : 'left',
                            marginRight: 8,
                            marginTop: 4
                        }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item
                            validateStatus={name === 'Serial Number' && messageError === 'Serial Number. is already exists. It must be unique. Please try another one.' ? 'error' : undefined}
                            help={name === 'Serial Number' && messageError !== '' ? messageError : undefined}
                        >
                            {getFieldDecorator(replaceName(name), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: 255, message: 'message max 255 characters' },
                                    ...validator
                                ]
                            })(
                                <Input placeholder={`${name}`} id={`${name}`} maxLength={maxLength} onBlur={name === 'Serial Number' ? handleBlurValidateSerialNoUniqueness : undefined} />
                            )}
                        </Form.Item>

                    </Col>
                </Col>
                <Col xl={5} md={0} />
            </Row>
        )
    }

    const customSelectWithFilter = (name: string,
        required: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        setState?: Function,
        maxInput?: number,
        isNotShowArrow?: boolean) => {
        return (
            <Row>
                <Col xl={3} md={0} />
                <Col xl={16} md={24} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{
                            textAlign: isDesktop ? 'right' : 'left',
                            marginRight: 8,
                            marginTop: 4
                        }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: maxInput || 250, message: `message max ${maxInput || 250} characters` }
                                ]
                            })(
                                <Select placeholder={`Select ${name}`}
                                    onSearch={(value) => {
                                        searchFetch(value, fullData || [], setState!!)
                                    }}
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(inputValue, option) =>
                                        String(option.props.children)?.toUpperCase()?.indexOf(inputValue?.toUpperCase()) !== -1
                                    }
                                    showArrow={!isNotShowArrow}
                                >
                                    {(autoCompleteData || []).map((item, index) => {
                                        return <Option value={item} key={index} title={item}>{item}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Col>
                <Col xl={5} md={0} />
            </Row>
        )
    }

    const customInput = (name: string,
        maxInput?: number,
        validator?: Object[],
        required: boolean = false,
        autoCompleteData: string[] = [],
        fullData: string[] = [],
        isITAsset?: boolean,
        setState?: Function) => {
        return (
            <Row>
                <Col xl={3} md={0} />
                <Col xl={16} md={24} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{
                            textAlign: isDesktop ? 'right' : 'left',
                            marginRight: 8,
                            marginTop: 4
                        }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name), {
                                rules: [
                                    { required: required, message: `${name} is required` },
                                    { max: maxInput || 250, message: `message max ${maxInput || 250} characters` },
                                    ...validator || []
                                ]
                            })(
                                autoCompleteData.length > 0 ? (
                                    <AutoComplete
                                        dataSource={autoCompleteData}
                                        placeholder={isITAsset && name === 'Asset Name' ? `${name}, Computer Name` : name}
                                        id={`${name}`}
                                        onChange={(value) => {
                                            searchFetch(value, fullData || [], setState!!)
                                        }}
                                    />
                                ) : (
                                    <Input
                                        placeholder={isITAsset && name === 'Asset Name' ? `${name}, Computer Name` : name}
                                        id={`${name}`} maxLength={maxInput} disabled={maxInput === 0} />
                                )
                            )}
                        </Form.Item>

                    </Col>
                </Col>
                <Col xl={5} md={0} />
            </Row>
        )
    }

    const createOption = (dataSource: string[]) => {
        return dataSource.map((data) => {
            return (<Option key={data}>{data}</Option>)
        })
    }

    const customSelect = (name: string, options: string[], required: boolean) => {
        return (
            <Row>
                <Col xl={3} md={0} />
                <Col xl={16} style={{ marginTop: 10 }}>
                    <Col xl={6} md={24}>
                        <div style={{
                            textAlign: isDesktop ? 'right' : 'left',
                            marginRight: 8,
                            marginTop: 4
                        }} className={required ? 'ant-form-item-required' : ''}>
                            {name}
                        </div>
                    </Col>
                    <Col xl={18} md={24}>
                        <Form.Item>
                            {getFieldDecorator(replaceName(name), {
                                initialValue: options[5],
                                rules: [
                                    { required: required, message: `${name} is required` }
                                ]
                            })(
                                <Select
                                    placeholder='Select Asset Status'
                                    style={{ width: '100%' }}
                                >{createOption(options)}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Col>
                <Col xl={5} md={0} />
            </Row>
        )
    }

    const disabledDate = (current, disable) => {
        return current && current < moment(disable).startOf('day')
    }

    const inputDate = (name: string, disable?: any) => {
        return (
            <Row>
                <Form.Item>
                    <Col xl={3} md={0} />
                    <Col xl={16} style={{ marginTop: 10 }}>
                        <Col xl={6} md={24}>
                            <div style={{
                                textAlign: isDesktop ? 'right' : 'left',
                                marginRight: 8,
                                marginTop: 4
                            }}>
                                {name}
                            </div>
                        </Col>
                        <Col xl={18} md={24}>
                            {getFieldDecorator(replaceName(name))(
                                disable
                                    ? <DatePicker
                                        placeholder={'Select Date'}
                                        style={{ width: '100%' }}
                                        disabledDate={(current) => name === 'Expiry Date' ? disabledDate(current, disable) : !disabledDate(current, disable)}
                                    /> : <DatePicker
                                        placeholder={'Select Date'}
                                        style={{ width: '100%' }}
                                    />
                            )}
                        </Col>
                    </Col>
                    <Col xl={5} md={0} />
                </Form.Item>
            </Row>
        )
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)
            }
            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (_err: any, values: any) => {
            if (!_err) {
                setIsLoading(true)
                const fieldDetails: CustomFiledAsset[] = (CustomFiledAssetDetails || []).map((obj: CustomFiledAsset) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox' && Array.isArray(props.form.getFieldValue(replaceName(obj.name)))) {
                            value = (getFieldValue(replaceName(obj.name)) || []).join('|')
                        } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(obj.name)) {
                            value = moment(props.form.getFieldValue(obj.name)).format('YYYY-MM-DD')
                        } else {
                            if (getFieldValue(replaceName(obj.name)) && typeof props.form.getFieldValue(replaceName(obj.name)) === 'string') {
                                value = getFieldValue(replaceName(obj.name))?.replace(/ +/g, ' ')?.trim()
                            } else {
                                value = getFieldValue(replaceName(obj.name))
                            }
                        }
                    }
                    const fieldDetail: CustomFiledAsset = {
                        id: obj.id,
                        name: obj.name,
                        value: obj.name ? value : undefined,
                        label: obj.label,
                        description: obj.description,
                        inputType: obj.inputType,
                        dataType: obj.dataType,
                        selectOption: obj.selectOption,
                        defaultValue: obj.defaultValue,
                        placeholder: obj.placeholder,
                        isRequireFields: Boolean(obj.isRequireFields),
                        assetGroup: obj.assetGroup,
                        headerGroup: obj.headerGroup,
                        isShowPm: obj.isShowPm
                    }
                    return fieldDetail
                })
                console.log(values)
                let assetGroupType
                if (values.Type) {
                    assetGroupType = assetGroups.find((item) => item.type.toString() === values.Type.toString())
                }
                console.log(PeopleState)
                console.log(values.User_ID)
                const findId = PeopleState?.find((it) => { return it.employeeId === values.User_ID })
                const assetOwnerLocation: Asset = {
                    serialNumber: values.Serial_Number?.trim(),
                    assetTag: values.Asset_Tag?.trim(),
                    assetLoanId: values.Loan_ID,
                    manufacturer: values.Manufacturer?.trim(),
                    dateInService: values.Date_in_Service ? moment(values.Date_in_Service).format('YYYY-MM-DD') : undefined,
                    expiryDate: values.Expiry_Date ? moment(values.Expiry_Date).format('YYYY-MM-DD') : undefined,
                    poNumber: values.PO_Number ? values.PO_Number?.trim() : undefined,
                    assetGroupType: assetGroupType,
                    assetName: values.Asset_Name?.trim(),
                    brand: values.Brand,
                    building: values.Building,
                    people: findId ? { id: findId.id } as any : undefined,
                    floor: values.Floor,
                    model: values.Model,
                    status: values.Asset_Status,
                    assetGroup: { id: values.Group } as any,
                    site: values.Site,
                    location: values.Location,
                    zone: values.Zone,
                    pairedItAsset: values.Paired_IT_Asset,
                    peripheralSet: values.SET,
                    customField: fieldDetails,
                    active: '1',
                    dataOrigin: 'addNew'
                }
                console.log(assetOwnerLocation)

                if (fileList.length > 0 && fileList.length <= 20) {
                    FSS.putFile(fileList, 'asset', (uploadLink: UploadLink[]) => {
                        assetOwnerLocation.attachFile = JSON.stringify(uploadLink)
                        createAssetOwner(assetOwnerLocation)
                    })
                } else if (fileList.length > 20) {
                    message.error('Upload File Max 20')
                    setIsLoading(false)
                } else {
                    createAssetOwner(assetOwnerLocation)
                }
            } else {
                console.log(_err)
                setIsLoading(false)
            }
        })
    }

    const createAssetOwner = (assetOwnerLocation: Asset) => {
        createAssetOwnerLocation(assetOwnerLocation).then((res) => {
            message.success('You have Successfully Created Asset.')
            setIsLoading(false)
            if (res) {
                // history.push(`/assetPreview/${res.id}/${assetOwnerLocation.serialNumber}`)
                history.push('/assetList')
            }
        }).catch((err) => {
            if (err?.response?.status === 409) {
                message.error('Serial Number. is already exists. It must be unique. Please try another one.')
                setIsLoading(false)
            } else {
                message.error(`You have unSuccessfully save the data. ${err}`)
                setIsLoading(false)
            }
        })
    }

    const showConfirm = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            onOk() {
                window.location.href = '/assetList'
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const changeTypeAsset = () => {
        resetFields(['Type'])
    }

    const chechCreateAsset = (): boolean => {
        let check = false
        if (!checkRolesState(RoleType.Asset, 'CreateAsset') === false || !checkRolesState(RoleType.PM, 'AddNewAsset') === false) {
            check = false
        } else {
            check = true
        }
        return check
    }

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/assetList'}>Asset List</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Create Asset</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card style={{ overflow: 'auto' }}>
                <div>
                    <h3 className='main-title'>Create Asset</h3>
                </div>
                <Row>
                    <Col md={1} lg={5} />
                    <Col md={22} lg={14} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                        <p className="main-title" style={{ margin: 12 }}>Original Images Asset</p>
                    </Col>
                    <Col span={5} />
                </Row>
                <Row type='flex' justify='center'>
                    <Col span={5} />
                    <Col className="Box-Picture" style={{ zIndex: 5, height: fileList.length <= 7 ? 150 : 230 }} span={14} xs={24} sm={24} md={14} lg={14} xl={14}>
                        <Upload {...uploadProps}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={(e: any) => handlePreview(e)}
                        >
                            {fileList && fileList.length >= 20 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)}>
                            <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </Col>
                    <Col span={5} />
                </Row>
                <Spin spinning={isLoadingAssetType}>
                    <Row>
                        <Form layout="horizontal" onSubmit={handleSubmit}>
                            <Form.Item >
                                {getFieldDecorator('Group', {
                                    rules: [{ required: true }],
                                    initialValue: assetType[0]?.id
                                })(
                                    <Radio.Group onChange={changeTypeAsset} disabled={isLoadingCustomFields} style={{ width: '100%' }}>
                                        <Row type='flex' justify='center'>
                                            {assetType.map((item) => {
                                                return <Radio value={item.id} key={item.id}>{item.name}</Radio>
                                            })}
                                        </Row>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            {/* <div style={{ border: '1px solid #E0E0E0', width: window.innerWidth < 765 ? '95%' : '80%', marginLeft: '10%', padding: 10 }}> */}
                            {customCheckDuplicateInput('Serial Number', true, [
                                // {validator: validateSerialNoUniqueness},
                                {
                                    pattern: new RegExp('^[0-9a-zA-Z -]*$'),
                                    message: 'Please enter only alphabets or numbers'
                                }
                            ], 60)}
                            {customSelect('Asset Status', status, true)}
                            {customCheckDuplicateInput('Asset Tag', false, [], 60)}
                            {customInput('Asset Name', undefined, [], false, computerName, dataComputerName, getFieldValue('Group') === 1, setComputerName)}
                            {customInput('Manufacturer', 60, [], false)}
                            {customSelectWithFilter('Model', false, model, dataModel, setModel)}
                            {customSelectWithFilter('Brand', false, brand, dataBrand, setBrand)}
                            {customSelectWithFilter('Type', false, (assetGroups?.filter((it) => it.assetGroup.id === getFieldValue('Group')) || []).map((data) => {
                                return data.type
                            }))}
                            <CustomSelectGroupAsset form={props.form} isPreview={false} isEdit={false}
                                locationBuildingList={LocationBuildings} dataZone={dataZone} />
                            <CustomInputGroupAsset2 form={props.form} isPreview={false} isEdit={true} maxInput={255}
                                isOpenModel={true} DataHR={PeopleState} />
                            {/* <CustomInputGroupAsset form={props.form} isPreview={false} isEdit={true} maxInput={255} isOpenModel={true} DataHR={PeopleState} /> */}
                            {customSelectWithFilter('Paired IT Asset', false, serialNumber, dataSerialNumber, setSerialNumber, 100, true)}
                            {customInput('PO Number', 30, [{
                                pattern: new RegExp('^[0-9a-zA-Z ]*$'),
                                message: 'Please enter only english characters and number'
                            }])}
                            {inputDate('Date in Service', getFieldValue('Expiry_Date'))}
                            {inputDate('Expiry Date', getFieldValue('Date_in_Service'))}
                            <Row>
                                <Col xl={3} md={0}></Col>
                                <Col xl={16} md={24}>
                                    <Spin spinning={isLoadingCustomFields}>
                                        <RenderFiled fields={CustomFiledAssetDetails} form={props.form} colSpan={24}
                                            colSM={24} labelColSm={6} wrapperColSm={18} maxWidth={'100%'}
                                            genCol={2} disableFields={false} isShowToggle={false} />
                                    </Spin>
                                </Col>
                                <Col xl={5} md={0}></Col>
                            </Row>
                            {/* </div> */}

                            <Row>
                                <Col span={6} xl={6} md={24}></Col>
                                <Col span={14} xl={14} md={24}>
                                </Col>
                                <Col span={4} xl={4} md={4}></Col>
                            </Row>
                            <Row>
                                <Col xl={3} md={0}></Col>
                                <Col xl={16} md={24} style={{ marginTop: 10, paddingRight: 3 }}>
                                    <Button type="primary" htmlType="submit" loading={isLoading}
                                        style={{ float: 'right', marginLeft: 15 }}
                                        disabled={chechCreateAsset()}>Submit</Button>
                                    <Button style={{ float: 'right' }} onClick={showConfirm}>Cancel</Button>
                                </Col>
                                <Col xl={5} md={0}></Col>
                            </Row>
                        </Form>
                    </Row>
                </Spin>
            </Card>
        </>
    )
}
const MyAssetForm = Form.create({ name: 'AssetForm' })(AssetForm)

export default connect(
    mapStateToProps,
    { getAssetFilter }
)(MyAssetForm)
