import React from 'react'
import { FormComponentProps } from "antd/lib/form"
import { Col, Row, Form, Input, Button } from 'antd'
import { siteHierarchyOption } from '../model'

interface Params {
    dataSite: siteHierarchyOption[]
    onCancel: Function
}

type Props = Params & FormComponentProps
const RenderSiteTab: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form

    const validateSite = (_: any, value: any, callback) => {
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const format = /[`!@#$%^&*()_+\-={}[\]:'"\\|,.<>/?~]/
        // eslint-disable-next-line no-useless-escape
        if (value) {
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                const isDuplicate = props.dataSite.some((it) => it.siteName.toLowerCase().trim() === value.toLowerCase().trim())
                if (isDuplicate) {
                    callback('Site Name already exists. It must be unique. Please try another one.')
                } else {
                    callback()
                }
            } else {
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback(`Field Label can't contain any of the following characters ${format}`)
                }
                callback('Default value is not a valid')
            }

        } else {
            callback()
        }
    }

    const onCancel = () => {
        props.onCancel()
    }

    return (
        <Row gutter={[10, 10]}>
            <Col md={18} sm={24}>
                <Form.Item label='Site Name'>
                    {getFieldDecorator('site',
                        {
                            rules: [
                                {
                                    required: true,
                                    message: 'Site Name is required'
                                },
                                {
                                    validator: validateSite
                                }
                            ]
                        }
                    )(
                        <Input
                            placeholder='Site Name'
                            allowClear
                        />
                    )}
                </Form.Item>
            </Col>
            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                <Form.Item style={{ marginTop: 40 }}>
                    <Button type="default" style={{ width: '-webkit-fill-available' }} htmlType="reset" onClick={onCancel}>Cancel</Button>
                </Form.Item>
            </Col>
            <Col span={3} lg={3} md={3} sm={24} xs={24}>
                <Form.Item style={{ marginTop: 40 }}>
                    <Button className="addCriteriaButton" style={{ width: '-webkit-fill-available' }}
                        htmlType="submit">Submit</Button>
                </Form.Item>
            </Col>
        </Row>
    )
}

export default RenderSiteTab