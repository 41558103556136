/* eslint-disable react/prop-types */
import { Button, Checkbox, Col, DatePicker, Form, Icon, Input, InputNumber, Radio, Row, Select } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import React, { useState } from 'react'
import { CustomFiledAsset } from '.'
import moment from 'moment'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { replaceName } from '../common-file'

interface Params {
    fields: CustomFiledAsset[]
    form: WrappedFormUtils
    colSpan: number
    colSM: number
    maxWidth: string
    genCol: number
    disableFields: boolean
    isShowToggle: boolean
    labelColSm: number
    wrapperColSm: number
    isSpin?: boolean
    paddingLeft?: number
    isLabelLeft?: boolean
    uniqueName?: number | string
    isCustomFieldsWo?: boolean
}

type Props = Params & FormComponentProps
const { Option } = Select
const RenderFiled: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [myToggleInput, setMyToggleInput] = useState<boolean>(false)
    const [validateCustomField, setValidateCustomField] = useState<boolean>(false)

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: props.labelColSm }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: props.wrapperColSm }
        }
    }

    const validateCustomInputNumber = (_: any, value: any, callback) => {
        if (value) {
            const pattern = /[0-9]+/
            if (pattern.test(value)) {
                callback()
            } else {
                callback('Enter numbers only')
            }
        } else {
            callback()
        }
    }

    const checkClassNameForRender = () => {
        if (props.isLabelLeft) {
            return 'ticketRelateWo'
        } else if (props.isCustomFieldsWo) {
            return 'custom-formLabel-wo'
        } else {
            return ''
        }
    }

    const validateText = (_rule: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
            if (!value.trim()) {
                callback('Enter more than 1 letter')
            } else {
                callback()
            }
        } else {
            callback()
        }
    }

    const customFieldLayout = () => {
        const rowContents: JSX.Element[] = []
        // eslint-disable-next-line react/prop-types
        const contents = (props.fields || []).reduce((acc: JSX.Element[], obj, index) => {
            rowContents.push(
                <div key={index}>
                    <Col span={props.colSpan} lg={props.colSpan} md={props.colSpan} sm={props.colSM} xs={props.colSM} key={index} style={{ marginTop: 10, zIndex: 2, paddingRight: 3 }}>
                        {obj.inputType === 'Checkbox' ? (
                            <Form.Item label={obj.label} className={checkClassNameForRender()}>
                                {getFieldDecorator(replaceName(props.uniqueName ? obj.name!! + props.uniqueName : obj.name!!) as string, {
                                    initialValue: obj.value || obj.defaultValue,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Checkbox.Group style={{ width: '100%', minHeight: 20 }} disabled={props.disableFields}>
                                        <Row>
                                            {
                                                obj.selectOption?.map((it, i) => {
                                                    return <Col span={8} key={i}>
                                                        <Checkbox value={it.option}>{it.option}</Checkbox>
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DatePicker' ? (
                            <Form.Item label={obj.label} className={checkClassNameForRender()}>
                                {getFieldDecorator(replaceName(props.uniqueName ? obj.name!! + props.uniqueName : obj.name!!) as string, {
                                    initialValue: obj.defaultValue || obj.value ? moment(obj.value as string || obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <DatePicker style={{ width: '100%', alignContent: 'center' }} placeholder={obj.placeholder as string || undefined} disabled={props.disableFields} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'DropdownSelect' ? (
                            <Form.Item label={obj.label} className={checkClassNameForRender()}>
                                {getFieldDecorator(replaceName(props.uniqueName ? obj.name!! + props.uniqueName : obj.name!!) as string, {
                                    initialValue: obj.defaultValue || obj.value ? (obj.value as string || obj.defaultValue as string) : undefined,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Select style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} allowClear disabled={props.disableFields}>
                                        {
                                            obj.selectOption?.map((it, i) => {
                                                return <Option value={it.option} key={i}>{it.option}</Option>
                                            })
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'InputNumber' ? (
                            <Form.Item label={obj.label} className={checkClassNameForRender()}>
                                {getFieldDecorator(replaceName(props.uniqueName ? obj.name!! + props.uniqueName : obj.name!!) as string, {
                                    initialValue: Number(obj.value || obj.defaultValue),
                                    rules: [
                                        // { type: (obj.dataType as string).toLowerCase() || undefined },
                                        { validator: validateCustomInputNumber },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        }
                                    ]
                                })(
                                    <InputNumber min={0} style={{ width: '100%' }}
                                        placeholder={obj.placeholder as string || undefined} disabled={props.disableFields} />
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'RadioButton' ? (
                            <Form.Item label={obj.label} className={checkClassNameForRender()}>
                                {getFieldDecorator(replaceName(props.uniqueName ? obj.name!! + props.uniqueName : obj.name!!) as string, {
                                    initialValue: obj.value || obj.defaultValue,
                                    rules: [{
                                        required: obj.isRequireFields as boolean,
                                        message: obj.label + ' is required'
                                    }]
                                })(
                                    <Radio.Group style={{ width: '100%', minHeight: 20 }} disabled={props.disableFields}>
                                        {
                                            obj.selectOption?.map((it, i) => {
                                                return <Radio value={it.option} key={i}>{it.option}</Radio>
                                            })
                                        }
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        ) : null}
                        {obj.inputType === 'TextBox' ? (
                            <Form.Item label={obj.label} className={checkClassNameForRender()}>
                                {getFieldDecorator(replaceName(props.uniqueName ? obj.name!! + props.uniqueName : obj.name!!) as string, {
                                    initialValue: obj.value || obj.defaultValue,
                                    rules: [
                                        { type: (obj.dataType as string).toLowerCase() || undefined, message: obj.label + ' is not a valid email' },
                                        {
                                            required: obj.isRequireFields as boolean,
                                            message: obj.label + ' is required'
                                        },
                                        { validator: obj.isRequireFields ? validateText : undefined}
                                    ]
                                })(
                                    <Input placeholder={obj.placeholder as string || undefined} style={{ width: '100%', minHeight: 20 }} disabled={props.disableFields} />
                                )}
                            </Form.Item>
                        ) : null}
                    </Col>
                </div>
            )
            if (index % props.genCol === 1) {
                acc.push(
                    <>
                        <Row gutter={8} style={{ margin: 'auto', maxWidth: props.maxWidth }}>{rowContents}</Row>
                    </>
                )
            }
            return acc
        }, [])
        contents.push(<>{rowContents}</>)

        return (
            <>
                {props.genCol === 2
                    ? <Form {...formItemLayout} style={{ margin: 'auto', maxWidth: props.maxWidth, paddingLeft: props.paddingLeft }}>
                        {rowContents}
                    </Form> : <>{rowContents}</>}
            </>
        )
    }

    const toggleInput = async () => {
        setMyToggleInput(!myToggleInput)
        setValidateCustomField(false)
    }

    return (
        <>
            {myToggleInput || !props.isShowToggle ? customFieldLayout() : null}
            <Row>
                {props.isShowToggle === true ? <Col span={24} lg={24} md={24} sm={24} xs={24}>
                    {!myToggleInput ? (
                        <Button onClick={toggleInput} style={{
                            width: '100%',
                            borderColor: validateCustomField ? 'red' : undefined
                        }}>Show More
                            <Icon type="down" style={{ marginBottom: '10px' }} /></Button>
                    ) : (
                        <Button onClick={toggleInput} style={{ width: '100%' }}>Show Less
                            <Icon type="up" style={{ marginBottom: '5px' }} /></Button>
                    )}
                </Col> : null}
            </Row>
        </>
    )
}

export default RenderFiled
