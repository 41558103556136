import { Button, Col, Icon, message, Modal, Row, Tag } from 'antd'
import React, { useContext } from 'react'
import { TicketRelatedContext } from '../../../../Preventive/ContextAPI/TicketRelatedContext'
import { deleteRelateTicketAndProblems } from '../../service'
import { checkRolesState, RoleType } from '../../../../authorization-module/permissions'
import { useParams} from 'react-router-dom'
import { Problem } from '../../Model'

interface Param {
    it: any
    isNonApproved?: boolean
    problems?: Problem
}
type Props = Param
const { confirm } = Modal
const DisplyTicket = (props: Props) => {
    const { DeleteTicketRelated } = useContext<any>(TicketRelatedContext)
    const { id }: any = useParams()
    const deleteTicketRelatedTicket = (data: any, ticketId?: number) => {
        if (props.isNonApproved === false || props.isNonApproved === undefined) {
            confirm({
                title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
                content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Do you confirm delete of this data ?</span>,
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                className: 'custom-modal-wo',
                onOk() {
                    if (ticketId) {
                        DeleteTicketRelated(ticketId)
                        if (id) {
                            deleteRelateTicketAndProblems(data.relateId.toString()).then(() => {
                                message.success('Ticket deleted successfully')
                            })
                        }
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        }
    }

    return (
        <div>
            <div className="TicketRelateCard">
                <Row>
                    <Col span={22} lg={22} md={20}>
                        <Row>
                            <Col span={24}>
                                <div className='text-herder-Orenge'> Ticket Number : {props.it?.number || props.it.ticketNumber}
                                    <Tag style={{ backgroundColor: props.it.priority?.priority?.tagColor, color: '#fff', marginLeft: 10 }}>{props.it.priority?.priority?.name}</Tag>
                                </div>
                            </Col>
                            {/* <Col span={2} xl={2} lg={2} md={4} className='TicketRelateCard-DeleteButton' style={{ marginLeft: 30 }}>
                                        <Icon onClick={(e) => { deleteAssetRelatedTicket(Number(props.it.id)) }} type="delete" />
                                    </Col> */}
                        </Row>
                        <br/>
                        <Row>
                            <Col span={12}>
                                <div className='custom-formLabel-wo'><b>Status</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.ticketStatus?.status}</p>
                            </Col>
                            <Col span={12}>
                                <div className='custom-formLabel-wo'><b>Requester</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.people?.fullName || props.it.requesterName}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <div className='custom-formLabel-wo'><b>Topic</b></div>
                                <p style={{ color: '#757F8D'}}>{props.it?.subject || props.it.topic}</p>
                            </Col>
                            <Col span={12}>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2} lg={2} md={4}>
                        <div style={{ textAlign: 'end'}}>
                            {props.problems?.pbStatus?.status === 'Closed' ? null : props.isNonApproved === false || !checkRolesState(RoleType.Problem, 'UpdateProblem') ? <Button htmlType='button' size='small' onClick={(e) => { deleteTicketRelatedTicket(props.it, props.it.id) }}> <Icon type="delete" style={{ marginLeft: '0px' }}/></Button> : null}
                            {id === undefined ? <Button htmlType='button' size='small' onClick={(e) => { deleteTicketRelatedTicket(props.it, props.it.id) }}><Icon type="delete" /></Button> : null}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default DisplyTicket
