/* eslint-disable no-throw-literal */
import {Dispatch} from 'redux'
import {ResponseUserRole} from './model'
import {UpdateUserRoleCurrentPageSuccessEvent, UpdateUserRoleTotalPageSuccessEvent, USER_ROLES_DATA_SHOW} from './type'
import {axiosGet, axiosGetWithPagination, axiosNonAuthPatch, axiosPatch, axiosPost, axiosPostWithPagination} from '../../rest'
import {CompanyAuth, DepartmentAuth, filterPeopleManagementType, People, tenantConfig} from '../../people/model'
import {
    AddPeopleSuccessEvent,
    GetAllPeopleSuccessEvent,
    UpdatePeopleCurrentPageSuccessEvent,
    UpdatePeopleSuccessEvent,
    UpdatePeopleTotalPageSuccessEvent
} from '../../../incident-management/requester/state-event'
import {UpdateNotifySuccessEvent} from '../../people/state-event'
import {checkRedirect, herderPostPatch} from '../../../common-misc'
import { decryptBody, encryptBody } from '../../../common-misc/encryptBody'
import { axiosDelete } from './rest'

export const BASE_URL = process.env.REACT_APP_BASE_URL_AUTH!!

export const getUserRoleWithPagination = (page: number, pageSize: number, searchValue: string, selectedRoles?: filterPeopleManagementType) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = selectedRoles ? encryptBody(JSON.stringify(selectedRoles)) : undefined
            const config = {
                params: {
                    page: page,
                    showAll: true,
                    size: pageSize,
                    value: searchValue || ''
                },
                ...herderPostPatch
            }
            const url = '/api/users/search'
            // const url = `/api/users/searchAll?page=${page}&showAll=true&pageSize=${pageSize}${searchValue ? '&search=' + searchValue : ''}`
            const response = await axiosPostWithPagination<ResponseUserRole[]>(url, encryptData, config)
            const searchResult = decryptBody(response.data.data!!)
            dispatch(UpdateUserRoleCurrentPageSuccessEvent.build(searchResult.number + 1 || page))
            dispatch(UpdateUserRoleTotalPageSuccessEvent.build(searchResult.totalElements || searchResult.total))
            dispatch(USER_ROLES_DATA_SHOW.build(searchResult.data || []))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export async function searchUserRoleWithPagination(searchValue: string) {
    try {
        const config = {
            params: {
                page: 1,
                showAll: false,
                size: 10,
                value: searchValue || ''
            },
            ...herderPostPatch
        }
        const url = '/api/users/search'
        const response = await axiosPost(url, undefined, config)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getPeopleWithPage = (page: number, pageSize: number, searchValue?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const url = `/api/auth/users/getAllPeople?page=${page.toString()}&pageSize=${pageSize.toString()}&searchValue=${searchValue !== undefined ? searchValue : ''}&sortBy=createdDate&orderBy=desc`
            const response = await axiosGetWithPagination<People[]>(url)
            const searchResult = decryptBody(response.data.data!!)
            dispatch(UpdatePeopleCurrentPageSuccessEvent.build(page))
            dispatch(UpdatePeopleTotalPageSuccessEvent.build(searchResult.totalElements || searchResult.total))
            dispatch(GetAllPeopleSuccessEvent.build(searchResult.content || []))
            return response.status
        } catch (err: any) {
            dispatch(GetAllPeopleSuccessEvent.build([]))
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export async function getAllPeopleNoPage() {
    try {
        const response = await axiosGet('/api/users')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function getAllUserNoPage() {
    try {
        const response = await axiosGet('/api/users/export')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function resend(email: string) {
    try {
        const response = await axiosGet('/api/users/resend/' + email)
        if (response.data.data) {
            return decryptBody(response.data.data)
        } else {
            return response.data.data
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const importPeople = (s: People[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(s))
            const response = await axiosPost<People>('/api/users/importExcel', encryptData, herderPostPatch)
            console.log(decryptBody(response.data.data!!))
            dispatch(AddPeopleSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err.response.data
        }
    }
}

export const genReportPeople = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<any>('/api/users/people/report')
            if (response.status === 200) {
                sessionStorage.setItem('fileName', decryptBody(response.data.data))
                dispatch(UpdateNotifySuccessEvent.build(1))
            }
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err.response.data.message
        }
    }
}

export async function getAllPeopleAuth() {
    try {
        const response = await axiosGet('/api/users')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const addPeople = (s: People) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(s))
            const response = await axiosPost<People>('/api/users', encryptData, herderPostPatch)
            dispatch(AddPeopleSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err.response.data.message
        }
    }
}

export const editPeople = (id: number, s: People) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(s))
            const response = await axiosPatch<People>(`/api/users/${id}`, encryptData, herderPostPatch)
            dispatch(UpdatePeopleSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err.response.data.message
        }
    }
}

export async function getAllDepartmentAuth() {
    try {
        const response = await axiosGet('/api/departments/export')
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

// export async function getAllCompanyAuth() {
//     try {
//         const response = await axiosGet('/api/companies/export')
//         if (response.data.data) {
//             return decryptBody(response.data.data!!)
//         } else {
//             return []
//         }
//     } catch (err: any) {
//         console.log(err)
//         throw err
//     }
// }

export async function importCompanyAuth(data) {
    try {
        console.log(data)
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost('/api/companies/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err?.response?.data || undefined
    }
}

export async function importDepartmentAuth(data) {
    try {
        console.log(data)
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost('/api/departments/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err?.response?.data || undefined
    }
}

export async function importUserAuth(data) {
    try {
        console.log(data)
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost('/api/users/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err?.response?.data || undefined
    }
}

export async function getAllRolesAuth() {
    try {
        const response = await axiosGet('/api/roles')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err.response.data
    }
}

export async function logOutMSP() {
    try {
        const response = await axiosDelete<People>('/api/securities/token')
        return response.status
    } catch (err: any) {
        throw err.response.data.message
    }
}

export async function logOutMSPNoAuth() {
    try {
        const response = await axiosNonAuthPatch<People>('/api/securities/deleteToken')
        return response.status
    } catch (err: any) {
        throw err.response.data.message
    }
}

export async function getTenantConfigByApiKeyType(ApiKeyType: string): Promise<tenantConfig[]> {
    try {
        const res = await axiosGet<tenantConfig[]>(`/api/tenant-config/tenant-profile/${ApiKeyType}`)
        if (res.data.data) {
            return decryptBody(res.data.data!!)
        } else {
            return []
        }
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function createOrUpdateTenantConfig(data: tenantConfig[]): Promise<tenantConfig> {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const res = await axiosPost<tenantConfig>('/api/tenant-config/tenant-profile', encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function updateTenantConfig(id: number, data: tenantConfig): Promise<tenantConfig> {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const res = await axiosPatch<tenantConfig>(`/api/tenant-config/${id}`, encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const RollbackDataFromFileToPeopleForExcel = (data: any[]): People[] => {
    data.shift()
    const allPeople: People[] = []
    // data.forEach((it) => {
    //     if (it) {
    //         const tempCat = convertArrayStringToPeople(it)
    //         allPeople.push(tempCat)
    //     }
    // })
    return allPeople
}

// const convertArrayStringToPeople = (value: string[]): People => {
//     const tempCat: People = {
//         id: Number(value[0]),
//         firstName: value[5],
//         lastName: value[6],
//         fullName: value[7],
//         email: value[8],
//         tel: value[9],
//         company: value[11],
//         companyAddress: value[12],
//         employeeId: value[13],
//         active: Boolean(value[17]),
//         department: value[18]
//     }
//     return tempCat
// }

export async function getAllCompanySetting() {
    try {
        const response = await axiosGet('/api/companies')
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function searchCompaynySetting(sortBy?: string, orderBy?: string, page?: number, pageSize?: number, companyAddress?: string) {
    try {
        const config = {
            params: {
                page: page || 1,
                size: pageSize || 10,
                value: companyAddress,
                sort: `${sortBy || 'createdDatetime'},${orderBy || 'asc'}`
            }
        }

        const response = await axiosGet('/api/companies/search', config)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function createCompanySetting(company: CompanyAuth) {
    try {
        const encryptData = encryptBody(JSON.stringify(company))
        const response = await axiosPost('/api/companies', encryptData, herderPostPatch)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function deleteCompanySetting(id: string) {
    try {
        const response = await axiosDelete(`/api/companies/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function updateCompanySetting(id: number, company: CompanyAuth) {
    try {
        const encryptData = encryptBody(JSON.stringify(company))
        const response = await axiosPatch(`/api/companies/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function getAllDepartmentSetting() {
    try {
        const response = await axiosGet('/api/departments')
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function searchDepartmentSetting(sortBy?: string, orderBy?: string, page?: number, pageSize?: number, search?: string) {
    try {
        const config = {
            params: {
                page: page,
                size: pageSize,
                sort: `${sortBy},${orderBy}`,
                value: search
            }
        }
        const response = await axiosGet('/api/departments/search', config)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function createDepartmentSetting(department: DepartmentAuth) {
    try {
        const encryptData = encryptBody(JSON.stringify(department))
        const response = await axiosPost('/api/departments', encryptData, herderPostPatch)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function updateDepartmentSetting(id: number, department: DepartmentAuth) {
    try {
        const encryptData = encryptBody(JSON.stringify(department))
        const response = await axiosPatch(`/api/departments/${id}`, encryptData, herderPostPatch)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function deleteDepartmentSetting(id: string) {
    try {
        const response = await axiosDelete(`/api/departments/${id}`)
        return response.data.data!!
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function searchDepartmentWithUserpage(search: string) {
    try {
        const config = {
            params: {
                value: search
            }
        }
        const response = await axiosGet('/api/departments/autocomplete', config)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function searchCompanyWithUserpage(search: string) {
    try {
        const config = {
            params: {
                value: search
            }
        }
        const response = await axiosGet('/api/companies/autocomplete', config)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
