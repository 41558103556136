import React, { useEffect, useState } from 'react'
import { Button, Spin, message, Modal, Form, Input } from 'antd'
import { Redirect } from 'react-router-dom'
import DetailPM from './DetailPM'
import { Asset, pmAdjustData, PreventiveWithAsset } from '../Model'
import { People } from '../../authorization-module/people/model'
import { PMActivityField } from '../../configurable-fields'
import { checkRolesState, RoleType } from '../../authorization-module/permissions'
import { reWorkPMPlan } from '../services'
import { FormComponentProps } from 'antd/es/form'

interface Param {
    current: number
    setCurrent: Function
    Group?: string
    dataPM?: PreventiveWithAsset
    dataAsset?: Asset
    isOnline: boolean
    pmAdjusted?: pmAdjustData
    getIsUserSignature: Function
    PmActivity: PMActivityField[]
    dataHR?: People
    getReworked?: Function
    isLoadingData?: boolean

}
const { TextArea } = Input
type Props = Param & FormComponentProps
const Step4Form: React.FC<Props> = (props: Props) => {
    const [isRedirect, SetIsRedirect] = useState<boolean>(false)
    const [isUserSignature, setIsUserSignature] = useState<boolean>(false)
    const [isPmApproved, setIsPmApproved] = useState<boolean>(false)
    const [isAllSignature, setIsAllSignature] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isClearSignature, setIsClearSignature] = useState<boolean>(false)
    const [isShowSignPadModal, setIsShowSignPadModal] = useState<boolean>(false)

    useEffect(() => {
        if (props.isLoadingData === false) {
            setIsLoading(props.isLoadingData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingData])

    const BackStep = () => {
        props.setCurrent(props.current - 1)
    }

    const getIsUserSignature = (value: boolean) => {
        setIsUserSignature(value)
        props.getIsUserSignature(value)
    }

    const getIsAllSignature = (value: boolean) => {
        setIsAllSignature(value)
        console.log(isAllSignature)
    }

    const handleReWork = (reason: string) => {
        if (props.dataPM?.preventiveMaintenance.id) {
            setIsLoading(true)
            reWorkPMPlan(props.dataPM?.preventiveMaintenance.id, reason).then(() => {
                if (props.getReworked) {
                    props.getReworked(true)
                }
                setIsClearSignature(true)
                setIsUserSignature(false)
            }).catch((err) => {
                message.error(err)
                setIsLoading(false)
            }).finally(() => {
                setIsClearSignature(false)
                setIsShowSignPadModal(false)
            })
        }
    }

    const getIsPMApproveSignature = (value: boolean) => {
        setIsPmApproved(value)
    }

    const confirm = (e) => {
        e.preventDefault()
        props.form.validateFields(async (_err, values) => {
            if (!_err) {
                handleReWork(values.reworkReason?.trim())
            }
        })
    }

    const openRework = () => {
        props.form.resetFields()
        setIsShowSignPadModal(true)
    }
    return (
        <div>
            {isRedirect ? <Redirect to="/Preventive" /> : null}
            <Modal
                title="Rework Reason"
                visible={isShowSignPadModal}
                footer={false}
                onCancel={() => setIsShowSignPadModal(false)}
            >
                <Form onSubmit={confirm}>
                    <Form.Item
                        label="Reason : "
                    >
                        {props.form.getFieldDecorator('reworkReason', {
                            rules: [{ required: true, message: 'Please input your Rework Reason', whitespace: true }]
                        })(
                            <TextArea rows={4} autoSize={{ minRows: 3, maxRows: 4 }} placeholder='input your Reason' />
                        )}
                    </Form.Item>
                    <div style={{ textAlign: 'end' }}>
                        <Button htmlType="button" onClick={() => setIsShowSignPadModal(false)}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 5 }} loading={isLoading}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal>
            <Spin spinning={isLoading}>
                <DetailPM
                    Group={props.Group}
                    isView={false}
                    dataPM={props.dataPM}
                    dataAsset={props.dataAsset}
                    isOnline={props.isOnline}
                    pmAdjusted={props.pmAdjusted}
                    getIsUserSignature={getIsUserSignature}
                    getIsPMApproveSignature={getIsPMApproveSignature}
                    getIsAllSignature={getIsAllSignature}
                    PmActivity={props.PmActivity}
                    dataHR={props.dataHR}
                    isClearSignature={isClearSignature}
                />
                <br />
                <div style={{ textAlign: 'end' }}>
                    {(props.dataPM?.preventiveMaintenance.pendingReason || props.dataPM?.preventiveMaintenance.userSignature) && isUserSignature && props.dataPM?.preventiveMaintenance.pmStatus === 'WaitingForSign' && checkRolesState(RoleType.PM, 'ApprovePM') && props.dataPM?.preventiveMaintenance.isPmApproved && !isPmApproved
                        ? <Button htmlType="button" onClick={() => openRework()} loading={isLoading}>
                            Re-Work
                        </Button> : null}
                    {props.dataPM?.preventiveMaintenance.userSignature || isUserSignature || props.dataPM?.preventiveMaintenance.pendingReason ? null
                        : <Button htmlType="button" onClick={BackStep}>
                            Back
                        </Button>
                    }
                    <Button type="primary" htmlType="button" style={{ marginLeft: 5 }} onClick={() => SetIsRedirect(true)}
                    // disabled={isAllSignature}
                    >
                        Go To PM List
                    </Button>
                </div>
                <div></div>
            </Spin>
        </div>
    )
}

const Step4FormDetailPM = Form.create<Props>({ name: 'Step4Form' })(Step4Form)
export default Step4FormDetailPM
