import React, {useEffect, useState} from 'react'
import { AutoComplete } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { IncidentFilter } from '../model'
import {People} from '../../../authorization-module/people/model'
import { searchUserRoleWithPagination } from '../../../authorization-module/user-role/duck/service'

interface OwnProps {
    onChanged: (value: string | undefined) => void
    criteria?: IncidentFilter
    isLoading?: Function
}

type Props = OwnProps
const Option = AutoComplete.Option
export const RequesterFilter: React.FC<Props> = (props: Props) => {
    const [suggestions, setSuggestions] = useState<People[]>([])
    const [inputText, setInputText] = useState<string>(props.criteria?.requesterName || '')
    const [isSelect, setIsSelect] = useState(false)
    const [isTempRequest, setIsTempRequest] = useState<string>('')

    useEffect(() => {
        if (props.criteria?.requesterName) {
            searchUserRoleWithPagination(props.criteria?.requesterName).then((res) => {
                if (res) {
                    setInputText(res.data[0].fullName)
                }
            })
        } else {
            setInputText('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.criteria?.requesterName])

    const onRequesterSearchTriggered = (searchText: string) => {
        populateRequesterSuggestions(searchText)
        if (!searchText) {
            props.onChanged(searchText)
        }
    }

    const onRequesterSelected = (value: SelectValue) => {
        setIsTempRequest(value as string)
        if (value) {
            if (value !== props.criteria?.requesterName) {
                props.onChanged(value === '-- Empty --' ? undefined : (value as string))
            }
            if (props.isLoading) {
                props.isLoading(true)
            }
            setIsSelect(true)
            if (value === inputText || value === isTempRequest) {
                if (props.isLoading) {
                    props.isLoading(false)
                }
            }
        }
    }

    const onInputTextChanged = (value: SelectValue) => {
        setInputText(value as string)
    }

    const populateRequesterSuggestions = (input: any) => {
        searchUserRoleWithPagination(input).then((res) => {
            if (res) {
                setSuggestions(res.data)
            }
            // setSuggestions(
            //     res?.map((requester) => {
            //         return {
            //             email: requester.email,
            //             name: requester.firstName + ' ' + requester.lastName
            //         }
            //     })
            // )
        })
    }

    const onBlurText = (value) => {
        if (value && !isSelect) {
            setInputText('')
        }
    }

    const renderOption = (item) => {
        return (
            <Option key={item.email} value={item.email}>
                {item.firstName + ' ' + item.lastName}
            </Option>
        )
    }

    return (
        <>
            <p className={'topicName'}>Requesters</p>
            <AutoComplete
                dataSource={suggestions?.map(renderOption)}
                style={{ width: '100%' }}
                onSelect={onRequesterSelected}
                onSearch={onRequesterSearchTriggered}
                onChange={onInputTextChanged}
                // onBlur={onRequesterSelected}
                onBlur={(e) => onBlurText(e)}
                autoClearSearchValue={true}
                placeholder="Select Requesters"
                value={inputText}
                allowClear={true}
            /><br />
        </>

    )
}
