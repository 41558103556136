/* eslint-disable no-useless-escape */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import {
    Button,
    Card,
    Dropdown,
    Form,
    Icon,
    Input,
    Menu,
    message,
    Modal,
    Spin,
    Switch,
    Table,
    Tooltip
} from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import { AssetGroups, PMCustomPictureField } from './model'
import {
    getAllAssetGroup,
    addOrUpdatePMCustomPicture,
    gelAllPMCustomPictureField
} from '../msp-wo/asset-management'
import { Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

// const mapStateToProps = (state: StoreState) => {
//   return {
//     PMActivityField: state.PMActivityField,
//   };
// };

// type StateProps = ReturnType<typeof mapStateToProps>;

// interface DispatchProps {
//   getAllPMActivityField: (assetType: number) => Promise<number>;
//   addOrUpdatePMActivityField: (
//     assetType: number,
//     value: PMCustomPictureField[]
//   ) => Promise<number>;
// }

type Props = FormComponentProps;
let arr: PMCustomPictureField[] = []
let listsEdit: number[] = []
const { confirm } = Modal
const PMActivityPictureField: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields } = props.form
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const [data, setData] = useState<PMCustomPictureField[]>([])
    const [isSpinning, setIsSpinning] = useState(false)
    const [checkIsUpdate, setCheckIsUpdate] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const [selectTicketType, setSelectTicketType] = useState<string>('')
    const [dataAssetGroup, setDataAssetGroup] = useState<AssetGroups[]>([])
    const [tempListLabel, setTempListLabel] = useState<string[]>([])
    const [duplicatesLabel, setDuplicatesLabel] = useState<string[]>([])
    const [isMoveRow, setIsMoveRow] = useState<boolean>(false)
    const [tempPMCustomPicture, setTempPMCustompicture] = useState<PMCustomPictureField[]>([])

    let isSubmit = false

    useEffect(() => {
        getAllAssetGroup().then((res) => {
            if (res) {
                setDataAssetGroup(res)
                setSelectTicketType(res[0].name)
                initGeneralIncidentSetting(res[0].id?.toString())
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (tempPMCustomPicture) {
            const fetchCustomPicture = tempPMCustomPicture.map(it => {
                it.createdDatetime = undefined
                it.createdBy = undefined
                it.lastModifiedBy = undefined
                it.lastModifiedDate = undefined
                it.key = uuid().slice(0, 8)
                return it
            })
            setData(fetchCustomPicture)
            setIsSpinning(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempPMCustomPicture])

    useEffect(() => {
        if (isMoveRow) {
            const listLabel2 = data?.map((obj) => {
                return obj.pictureName?.toLocaleLowerCase() || ''
            })

            if (findDuplicates(listLabel2)) {
                setDuplicatesLabel(findDuplicates(listLabel2))
            } else {
                setDuplicatesLabel([])
            }
            setIsMoveRow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoveRow])

    const handleSubmit = (e) => {
        e.preventDefault()
        isSubmit = true
        props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                console.log('err')
                isSubmit = false
            } else {
                setIsLoading(true)
                const findType =
          dataAssetGroup.find((it) => {
              return it.name === selectTicketType
          })?.id || 0
                console.log('type : ', findType)
                const convertData = data.map((it, i) => {
                    it.pictureName = it.pictureName
                        ? it.pictureName.replace(/ +/g, ' ').trim()
                        : undefined
                    // delete it.key
                    return {
                        ...it,
                        pictureSequence: i,
                        assetGroupId: findType,
                        active: true
                    }
                })
                addOrUpdatePMCustomPicture(findType, convertData)
                    .then((res) => {
                        if (res) {
                            message.success('The Update has finished successfully')
                            setIsLoading(false)
                            setIsRedirect(true)
                            isSubmit = false
                        }
                    })
                    .catch((_err) => {
                        message.error(_err?.response?.data?.message || 'Please enter custom fields completely.')
                        setIsLoading(false)
                        isSubmit = false
                    })
            }
        })
    }

    const initGeneralIncidentSetting = (selectTicketType: string) => {
        setIsSpinning(true)
        const findType = dataAssetGroup.find((it) => {
            return it.name === selectTicketType
        })?.id || 1
        gelAllPMCustomPictureField(findType)
            .then((res) => {
                setData(res)
                setTempPMCustompicture(res)
                setCheckIsUpdate(false)
                setIsSpinning(false)
            })
            .catch((_err) => {
                setCheckIsUpdate(true)
                setData([])
                setIsSpinning(false)
            })
    }

    const AddData = () => {
        arr = []
        const listLabel = data.map((obj) => {
            return obj.pictureName || ''
        })
        setTempListLabel([...new Set(listLabel)])
        const smallId = uuid().slice(0, 8)
        const initData = {
            // id: data.length,
            assetGroupId: undefined,
            pictureName: undefined,
            description: undefined,
            isRequireFields: undefined,
            active: undefined,
            pictureSequence: undefined,
            key: smallId
        } as unknown as PMCustomPictureField
        arr.push(initData)
        setData((data || []).concat(arr))
        forceUpdate()
    }

    const validateLabel = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
      const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (format.test(value)) {
                callback()
            } else {
                if (pattern.test(value.trimEnd())) {
                    callback()
                } else {
                    callback('Default value is not a valid')
                }
            }
        } else {
            callback()
        }
    }

    const findDuplicates = (arr) =>
        arr.filter((item, index) => arr.indexOf(item) !== index)

    const handleBlurLabel = (value: string) => {
        const listLabel2 = data.map((obj) => {
            return obj.pictureName?.toLocaleLowerCase() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        } else {
            setDuplicatesLabel([])
        }

        tempListLabel.push(value)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, myData, index) => (
                <>
                    <Tooltip placement="bottom" title={'Add'}>
                        <Icon
                            className="del_icon"
                            type="plus-square"
                            onClick={() =>
                                addAfterRow(
                                    row.id,
                  data?.findIndex((it) => {
                      return it?.key?.toString() === myData?.key?.toString()
                  })
                                )
                            }
                        />
                    </Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}>
                        <Icon
                            className="edit_icon"
                            type="minus-square"
                            onClick={() => deleteRow(index, row.pictureName, data)}
                        />
                    </Tooltip>
                </>
            )
        },
        {
            title: (
                <span>
                    <span className={'requiredIcon'}>* </span>Photo Label
                </span>
            ),
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item
                        validateStatus={
                            row.pictureName === ''
                                ? 'error'
                                : duplicatesLabel.includes(
                  row.pictureName?.toLocaleLowerCase()
                                )
                                    ? 'error'
                                    : undefined
                        }
                        help={
                            row.pictureName === ''
                                ? 'Default value is not a valid'
                                : duplicatesLabel.includes(
                  row.pictureName?.toLocaleLowerCase()
                                )
                                    ? 'The Field Label already exists.'
                                    : undefined
                        }
                    >
                        {getFieldDecorator('pictureName' + row.id + index + row.key, {
                            initialValue: row.pictureName,
                            rules: [
                                {
                                    required: true,
                                    message: 'Photo label is required'
                                },
                                {
                                    validator: !isSubmit ? validateLabel : undefined
                                }
                            ]
                        })(
                            <Input
                                maxLength={255}
                                onChange={(e) => handleChangeLabel(row, e.target.value)}
                                onBlur={(e) => handleBlurLabel(e.target.value)}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('description' + row.id + index, {
                            initialValue: row.description,
                            rules: [{ required: false }]
                        })(
                            <Input
                                maxLength={255}
                                onChange={(e) => handleChangeDescription(row, e.target.value)}
                            />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Required on PM',
            dataIndex: '',
            key: '',
            width: '10%',
            align: 'center' as const,
            render: (row: PMCustomPictureField) => (
                <>
                    <Tooltip
                        placement="bottom"
                        title={row.isRequireFields ? 'Deactivate' : 'Activate'}
                    >
                        <Switch
                            checked={row.isRequireFields}
                            onChange={(value) => {
                                toggleActiveness(row)
                            }}
                        />
                    </Tooltip>
                </>
            )
        }
    ]

    const toggleActiveness = (data: PMCustomPictureField) => {
        data.isRequireFields = !data.isRequireFields
        setCheckIsUpdate(!checkIsUpdate)
        forceUpdate()
    }

    const addAfterRow = (currentRow, index) => {
        arr = []
        const listLabel = data.map((obj) => {
            console.log('obj:', obj)
            return obj.pictureName || ''
        })
        setTempListLabel([...new Set(listLabel)])
        const smallId = uuid().slice(0, 8)
        data.forEach((obj) => {
            if (Number(obj.id) >= currentRow + 1) {
                obj.id = Number(obj.id) + 1
            }
        })
        data.splice(index + 1, 0, {
            id: undefined,
            assetGroupId: undefined,
            pictureName: undefined,
            description: undefined,
            isRequireFields: undefined,
            active: undefined,
            pictureSequence: undefined,
            key: smallId
        })
        resetFields()
        setData((data || []).concat(arr))
        // setData(data)
        forceUpdate()
    }

    const deleteRow = (index, value, mydata) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                const filterTempLabel = tempListLabel.filter((it) => it !== value)
                const tempData2 = data.filter((obj) => obj?.key !== mydata?.key)
                if (duplicatesLabel) {
                    const filterDuplicatesLabel = duplicatesLabel?.filter(
            (it) => it?.toLowerCase().trim() !== value?.toLowerCase().trim()
          )
                    setDuplicatesLabel(filterDuplicatesLabel)
                }
                setData(tempData2)
                setTempListLabel(filterTempLabel)
                message.success('Delete data successfully.')
            },
            onCancel() { }
        })
    }
    // PMCustomPictureField
    const handleChangeLabel = (row: PMCustomPictureField, value: string) => {
        row.pictureName = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleChangeDescription = (row: PMCustomPictureField, value: string) => {
        row.description = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    // const components = {
    //     body: {
    //         row: DragableBodyRow
    //     }
    // }

    // const moveRow = useCallback(
    //     (dragIndex, hoverIndex) => {
    //         const dragRow = data[dragIndex]
    //         setData(
    //             update(data, {
    //                 $splice: [
    //                     [dragIndex, 1],
    //                     [hoverIndex, 0, dragRow]
    //                 ]
    //             })
    //         )
    //     }, [data]
    // )
    // // console.log(components)
    // const manager = useRef(RNDContext)
    const renderTable = () => {
        return (
            <Spin spinning={isSpinning}>
                <Table
                    columns={columns}
                    dataSource={data}
                    // components={components}
                    rowKey='id'
                    // onRow={(record, index) => ({
                    //     index,
                    //     moveRow
                    // })}
                    scroll={{ x: 768 }}
                    // className='Table-CustomField'
                />
            </Spin>
        )
    }

    const goBack = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                listsEdit = []
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const menu = () => {
        return (
            <Menu>
                {dataAssetGroup.map((it, index) => {
                    return (
                        <Menu.Item
                            key={index}
                            onClick={() => setTicketTypes(it.name, it.id!!)}
                        >
                            <span>{it.name}</span>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    const setTicketTypes = (text: string, id: number) => {
        setSelectTicketType(text)
        initGeneralIncidentSetting(text)
    }

    return (
        <>
            {isRedirect ? <Redirect to="Setting" /> : null}
            <Card style={{ overflow: 'auto' }}>
                <h3 className={'main-title text-herder-Orenge'}>Set Upload Image</h3>
                <div style={{ marginBottom: 10 }}>
                    <span style={{ color: 'red' }}>
            * <span style={{ color: '#323276' }}>Group : </span>
                    </span>
                    <Dropdown overlay={() => menu()} trigger={['click']}>
                        <span style={{ color: '#323276', fontWeight: 'bold' }}>
                            {selectTicketType} <Icon type="down" />
                        </span>
                    </Dropdown>
                </div>
                <Button
                    onClick={() => AddData()}
                    htmlType="button"
                    className="addCriteriaButton"
                >
          Add
                </Button>
                <br />
                <br />
                <Form onSubmit={handleSubmit}>
                    {renderTable()}
                    <Form.Item style={{ float: 'right', marginTop: 10 }}>
                        <Button
                            htmlType="button"
                            onClick={() => goBack()}
                            style={{ marginRight: 10 }}
                        >
              Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}

const PMActivityPicture = Form.create<Props>()(PMActivityPictureField)
export default PMActivityPicture
