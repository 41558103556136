/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react'
import {Checkbox, Col, Form, Icon, message, Modal, Row, Switch, Table, Tag, Tooltip} from 'antd'
import '../style.css'
import {StoreState} from '../../../store'
import {editUser, showActiveRoleList} from '../duck/action'
import {connect, useDispatch} from 'react-redux'
import {FetchingState, fetchWithState} from '../../../common-components'
import EditableCell from './UserRoleCell'
import {Link} from 'react-router-dom'
import {Roles, ResponseUserRole} from '../duck/model'
import Search from 'antd/es/input/Search'
import {USER_ROLES_DATA_SHOW} from '../duck/type'
import {getUserRoleWithPagination} from '../duck/service'
import {encodeSpecialCharacter} from '../../../common-misc'

const mapStateToProps = (state: StoreState) => ({
    user_roles: state.userRoles,
    userRolesPagination: state.userRolesPagination,
    roles: (state.roles || [])
})
type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    editUser: (userRole: ResponseUserRole, id: string) => Promise<number>
    getUserRoleWithPagination: (selectedRoles: string[], page: number, pageSize: number, searchValue: string) => Promise<number>
    showActiveRoleList: () => Promise<number>
}

interface Item {
    key: string;
    username: string;
    fullName: string;
    roles: string[];
    peopleId: number;
    active: boolean;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing?: boolean;
    dataIndex?: string;
    title?: any;
    inputType?: 'select' | 'text';
    record?: Item[];
    index?: number;
    children?: React.ReactNode;
}

type Props = StateProps & EditableCellProps & DispatchProps

const UserRoleList: React.FC<Props> = (props: Props) => {
    const [userRolesFetchingState, setUserRolesFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [dataTable, setDataTable] = useState<Array<Item>>([])
    const [editingKey, setEditingKey] = useState<string>('')
    const [editableData, setEditableData] = useState<Array<Item>>([])
    const [valueRoles, setValueRoles] = useState<Array<Roles>>([])
    const {confirm} = Modal
    const [selectFilterRoles, setSelectFilterRoles] = useState<Array<string>>([])
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const pageSize = 10
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState<string>('')
    const [page, setPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        fetchWithState(userRolesFetchingState, setUserRolesFetchingState, (): Promise<number> => {
            return props.getUserRoleWithPagination(selectFilterRoles, 1, pageSize, encodeSpecialCharacter(searchValue)).finally(() => { setIsLoading(false) })
        })
        props.showActiveRoleList().then()
    }, [])

    useEffect(() => {
        props.getUserRoleWithPagination(selectFilterRoles, 1, pageSize, encodeSpecialCharacter(searchValue)).catch((err) => message.error(`Failed fetching user with role. ${err}`))
    }, [selectFilterRoles.length])

    useEffect(() => {
        setValueRoles(props.roles)
    }, [props.roles])

    // useEffect(() => {
    //     const rolesName = (roles || []).map((data) => data.name)
    //     setValueRoles(rolesName)
    // }, [roles])

    useEffect(() => {
        mapColumn()
        mappingData(props.user_roles)
    }, [props.user_roles])

    useEffect(() => {
        setEditableData(dataTable)
    }, [dataTable])

    useEffect(() => {
        fetch(searchValue ? 1 : page, selectFilterRoles, encodeSpecialCharacter(searchValue))
    }, [searchValue, page])

    const fetch = (page: number, selectedRoles: string[], searchValue: string) => {
        // page = props.userRolesPagination.page !== undefined && props.userRolesPagination.page > 1
        //     ? props.userRolesPagination.page : page
        setIsLoading(true)
        fetchWithState(FetchingState.NotStarted, setUserRolesFetchingState,
            () => props.getUserRoleWithPagination(selectedRoles, page, pageSize, encodeSpecialCharacter(searchValue))
                .catch(() => dispatch(USER_ROLES_DATA_SHOW.build([])))
                .finally(() => {
                    setIsLoading(false)
                }))
    }

    const mappingData = (dataProps: any) => {
        const dataToSetState: Item[] = []
        dataProps.forEach((data: any) => {
            dataToSetState.push({
                key: data.id,
                username: data.username,
                fullName: data.fullName || data.people.fullName,
                roles: data.roles || (data.userRole || []),
                peopleId: data.peopleId || data.people.id,
                active: data.active

            })
        })
        setDataTable(dataToSetState)
    }

    const activeRecord = (id): void => {
        let activeValue = false
        let usernameValue = ''
        let fullNameValue = ''
        let rolesValue: any = []
        let peopleId: number = 0
        const pictureProfile = ''
        dataTable.forEach((dt) => {
            if (dt.key === id) {
                console.log(dt.roles)
                activeValue = dt.active
                usernameValue = dt.username
                fullNameValue = dt.fullName
                rolesValue = dt.roles
                peopleId = dt.peopleId
            }
        })
        try {
            props.editUser({
                username: usernameValue,
                fullName: fullNameValue,
                roles: rolesValue.map((role) => {
                    return {
                        id: role.id,
                        name: role.name
                    }
                }
                ),
                active: !activeValue,
                peopleId: peopleId,
                pictureProfile: pictureProfile
            }, id).catch((err) => message.error(`Failed editing user data. ${err}`))
        } catch (error: any) {
            console.log(error)
        }
    }

    const handleFilter = (value) => {
        setSelectFilterRoles(value)
        forceUpdate()
    }

    const renderFilter = () => {
        return (<>
            <Checkbox.Group onChange={handleFilter}>
                <Row>
                    {valueRoles.map((vr, keys) => {
                        return (<Col style={{ margin: 10 }} key={keys}><Checkbox value={vr.name}>{vr.name.substring(0, 20)}</Checkbox></Col>)
                    })}
                </Row>
            </Checkbox.Group>
        </>)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            width: '30px',
            render: (record) => {
                return <Tooltip placement="bottom" title={'Edit'}>
                    <Link to={`/UserSettingEditForm/${record.key}/${record.peopleId}/${record.username}/${(record.roles || []).map((role) => role.name)}`} ><Icon className="edit_icon" type="edit" /></Link>
                </Tooltip>
            }
        },
        {
            title: 'Name',
            dataIndex: '',
            render: (record) => {
                return <Link to={`/UserSettingPreview/${record.key}/${record.peopleId}/${record.username}/${(record.roles || []).map((role) => role.name)}`} >
                    <span>{record.fullName}</span>
                </Link>
            }

        },
        {
            title: 'Email',
            dataIndex: 'username',
            key: 'username',
            editable: false
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles.id',
            editable: true,
            filterDropdown: renderFilter,
            render: (tags) => (
                <span>
                    {(tags || []).map(tag => {
                        let color = tag.name?.length > 5 ? 'geekblue' : 'green'
                        if (tag === 'loser') {
                            color = 'volcano'
                        }
                        return (
                            <Tag color={color} key={tag.id}>
                                {tag.name?.toUpperCase()}
                            </Tag>
                        )
                    })}
                </span>
            )

        },
        {
            title: '',
            dataIndex: '',
            width: '30px',
            render: (record: any) => {
                return <>
                    <Tooltip placement="bottom" title={record.active ? 'Deactivate' : 'Activate'}>
                        <Switch checked={record.active} onChange={() => showConfirm(record.key, record.active)} />
                    </Tooltip>
                    <br />
                </>
            }
        }
    ]

    const showConfirm = (id, active) => {
        if (!active) {
            activeRecord(id)
        } else {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Deactivate this data?',
                onOk() {
                    activeRecord(id)
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        }
    }

    const components = {
        body: {
            cell: EditableCell
        }
    }

    const mapColumn = () => {
        return columns.map(col => {
            if (!col.editable) {
                return col
            }
            return {
                ...col,
                onCell: (record: any) => {
                    return {
                        record,
                        inputType: col.dataIndex === 'roles' ? 'select' : 'text',
                        dataIndex: col.dataIndex,
                        title: col.title,
                        editing: record.key === editingKey
                    }
                }
            }
        })
    }

    const handlePage = (page) => {
        setEditingKey('')
        setPage(page)
    }

    return (
        <div style={{overflow: window.innerWidth < 768 ? 'auto' : 'none'}}>
            <Row>
                <Search
                    placeholder="Can search Name or Email"
                    allowClear={true}
                    onSearch={value => setSearchValue(value)}
                    style={{ width: '30%', marginBottom: 20 }}
                />
            </Row>
            {/* {console.log(userRolesFetchingState)} */}
            {/* <SpinningFetcher fetchingState={userRolesFetchingState}> */}
            <Table
                components={components}
                dataSource={editableData}
                columns={mapColumn()}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: props.userRolesPagination.total,
                    onChange: (event) => { handlePage(event) }
                }}
                loading={isLoading}
            />
            {/* </SpinningFetcher> */}
        </div>
    )
}

const wrappedForm = Form.create({ name: 'UserRoleList' })(UserRoleList)
export default connect(
    mapStateToProps, { editUser, getUserRoleWithPagination, showActiveRoleList}
)(wrappedForm)
