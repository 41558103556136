import { Button, Card, Col, Icon, Modal, Row, Switch, Table, Tooltip, message } from 'antd'
import React, { useEffect, useState } from 'react'
import EditTemplate from './EditTemplate'
import { deleteTemplates, searchTicketTemplate, updateTemplates } from './service'
import { ticketNumber } from './model'

interface OwnProps {

}

type Props = OwnProps
const { confirm } = Modal
const TemplateTicketSetting: React.FC<Props> = (props: Props) => {
    const [dataSource, setDataSource] = useState<ticketNumber[]>([])
    const [total, setTotal] = useState(0)
    const [selectData, setSelectData] = useState<Object>()
    const [isVisibleForm, setIsVisibleForm] = useState(false)
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [sortBy, setSortBy] = useState<string>('lastModified')
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [isOnLoadingData, setIsOnLoadingData] = useState<boolean>(false)
    const [isView, setIsView] = useState<boolean>(false)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize])

    const fetchData = () => {
        setIsOnLoadingData(true)
        searchTicketTemplate(page, pageSize, sortBy, orderBy, undefined, 'true,false').then((res) => {
            setDataSource(res.data)
            setTotal(res.total)
        }).finally(() => {
            setIsOnLoadingData(false)
        })
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'} ><Icon className="del_icon" type="delete" onClick={() => deleteTemplate(row.id, row.activate)} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '50px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Edit'}><Icon className="edit_icon" type="edit" onClick={() => handleEditData(row)}/></Tooltip>
                </>
            )
        },
        {
            title: 'Clone Ticket Number',
            dataIndex: 'ticketNumber',
            key: 'ticketNumber',
            render: (data: any, row) => (
                <>
                    <span>{data === 'null' ? '-' : data}</span>
                </>
            )
        },
        {
            title: 'Template Name',
            dataIndex: 'templateName',
            key: 'templateName',
            render: (data: any, row) => (
                <>
                    <span className='feck-link' onClick={() => handleView(row)}>{data === 'null' ? '-' : data}</span>
                </>
            )
        },
        {
            title: 'Topic',
            dataIndex: 'topic',
            key: 'topic',
            render: (data: any) => (
                <>
                    <div><Tooltip placement="bottom" title={data}>{data?.length > 50 ? data?.substring(0, 50).concat('...') : data!!}</Tooltip></div>
                </>
            )
        },
        {
            title: 'Last Modified',
            dataIndex: 'lastModifiedDatetime',
            key: 'lastModifiedDatetime'
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    {/* <Spin spinning={isLoadingToggle}> */}
                    <span style={{ display: 'flex', float: 'right' }}>
                        <Tooltip placement="bottom" title={row.activate ? 'Deactivate' : 'Activate'}>
                            <Switch checked={row.activate} onChange={() => toggleActiveness(row)} />
                        </Tooltip>
                    </span>
                    {/* </Spin> */}
                </>
            )
        }
    ]

    const handleView = (row) => {
        setSelectData(row)
        setIsView(true)
        setIsVisibleForm(true)
    }

    const deleteTemplate = (id, isActivate) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            okText: 'OK',
            okType: 'danger',
            cancelText: 'Cancle',
            onOk() {
                if (isActivate) {
                    message.warning('Please Deactivate.')
                } else {
                    deleteTemplates(id).then((res) => {
                        message.success('This Ticket Template was deleted.')
                        fetchData()
                    }).catch((err) => {
                        message.error(`You have Unsuccessfully save the data. ${err}`)
                    })
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const handleEditData = (row) => {
        setSelectData(row)
        setIsVisibleForm(true)
    }

    const toggleActiveness = (data) => {
        // setIsloadingToggle(true)
        if (data.activate) {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Deactivate this data?',
                okText: 'OK',
                okType: 'danger',
                cancelText: 'Cancle',
                onOk() {
                    updateTemplates(data.id, { activate: !data.activate }).then((res) => {
                        message.success('The Update has finished successfully.')
                        // const convertData = dataSource.map((datas) => {
                        //     if (datas.id === data.id) {
                        //         datas.activate = !datas.activate
                        //         datas.lastModifiedDatetime = res.lastModifiedDatetime
                        //         return datas
                        //     }
                        //     return datas
                        // })
                        // setDataSource(convertData)
                        fetchData()
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        } else {
            updateTemplates(data.id, { activate: !data.activate }).then((res) => {
                message.success('The Update has finished successfully.')
                fetchData()
                // const convertData = dataSource.map((datas) => {
                //     if (datas.id === data.id) {
                //         datas.activate = !datas.activate
                //         datas.lastModifiedDatetime = res.lastModifiedDatetime
                //         return datas
                //     }
                //     return datas
                // })
                // setDataSource(convertData)
            })
        }
    }

    const closeModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'OK',
            okType: 'danger',
            maskClosable: false,
            cancelText: 'Cancle',
            onOk() {
                setIsVisibleForm(false)
                setSelectData(undefined)
                setIsView(false)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const settingIcon = (
        <Icon type="close" onClick={closeModal}/>
    )

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('number')
            }
        } else {
            setSortBy('number')
        }
        setPageSize(pagination.pageSize)
    }

    return (
        <Card style={{minHeight: '535px'}}>
            <Row>
                <Col span={12}>
                    <h3 className={'main-title'}>Ticket Template</h3>
                </Col>
                <Col span={12} style={{ textAlign: 'end' }}>
                    <Button type='primary' onClick={() => setIsVisibleForm(true)}><Icon type="plus" />Create Template</Button>
                </Col>
            </Row>
            <br/>
            <Table
                dataSource={dataSource}
                columns={columns}
                onChange={handleTableChange}
                rowKey={'id'}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: total,
                    onShowSizeChange(current, size) {
                        setPageSize(size)
                        setPage(1)
                    },
                    onChange: (event) => { setPage(event) },
                    showSizeChanger: true
                }}
                loading={isOnLoadingData}
            />
            <Modal
                title={selectData === undefined ? 'Create Ticket Template' : isView ? 'View Ticket Template' : 'Edit Ticket Template'}
                visible={isVisibleForm}
                width={898}
                footer={false}
                closeIcon={settingIcon}
            >
                <EditTemplate dataEdit={selectData} isVisible={isVisibleForm} setIsVisible={setIsVisibleForm} setSelectData={setSelectData} fetchData={fetchData} isView={isView}/>
            </Modal>
        </Card>
    )
}

export default TemplateTicketSetting
