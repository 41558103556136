/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Col, Input, message, Modal, Row, Form, Button } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import { createDepartmentSetting, getAllDepartmentSetting, updateDepartmentSetting } from '../../../authorization-module/user-role/duck/service'
import { DepartmentAuth } from '../../../authorization-module/people/model'

interface Param {
    closeModal: Function
    isEdit?: boolean
    dataEdit?: any
}

type Props = FormComponentProps & Param
const { confirm } = Modal
const DepartmentForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields, validateFields } = props.form
    const [dataEdit, setDataEdit] = useState<DepartmentAuth>()
    const [dataDepartmentName, setDataDepartmentName] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        getAllDepartmentSetting().then((data) => {
            if (data) {
                const departmentName = data.map((it) => { return it.departmentName.toLowerCase() })
                setDataDepartmentName(departmentName)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        resetFields(['departmentName'])
        if (props.dataEdit) {
            setDataEdit(props.dataEdit)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataEdit])

    useEffect(() => {
        if (props.closeModal) {
            resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.closeModal])

    const updateDepartment = () => {
        validateFields(['departmentNameEdit'], (err, values) => {
            if (!err) {
                setIsLoading(true)
                const updateBody: DepartmentAuth = {
                    departmentName: values.departmentNameEdit.replace(/ +/g, ' ').trim(),
                    active: true
                }
                updateDepartmentSetting(dataEdit?.id!!, updateBody).then((res) => {
                    props.closeModal(res)
                    message.success('The Update has finished successfully.')
                    setIsLoading(false)
                }).catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                }).finally(() => {
                    props.closeModal()
                })
            }
        })
    }

    const addNewDepartment = () => {
        validateFields(['departmentName'], (err, values) => {
            if (!err) {
                setIsLoading(true)
                const tempBody: DepartmentAuth = {
                    departmentName: values.departmentName.replace(/ +/g, ' ').trim(),
                    active: true
                }
                createDepartmentSetting(tempBody).then((res) => {
                    props.closeModal(res)
                    message.success('You have Successfully save the data.')
                    setIsLoading(false)
                    setDataDepartmentName([...dataDepartmentName, res.departmentName])
                }).catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
                resetFields(['departmentName'])
            }
        })
    }

    const closeModalConfirm = () => {
        confirm({
            title: 'Are you sure ?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                resetFields(['departmentName'])
                props.closeModal()
            },
            onCancel() {
                resetFields(['departmentName'])
                console.log('Cancel')
            }
        })
    }

    const validateDepartment = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, ' ').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /[A-Za-z0-9ก-๙]+/
            const isDataDuplicate = dataDepartmentName.includes(CheckValue.toLowerCase().trim())
            const defaultDepartmentName = dataEdit?.departmentName
            if (pattern.test(CheckValue) && !isDataDuplicate && !format.test(CheckValue.charAt(0))) {
                callback()
            } else {
                if (format.test(CheckValue.charAt(0))) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else if (value.trim() === defaultDepartmentName) {
                    callback()
                } else if (isDataDuplicate) {
                    callback('The Department Name is already exists. It must be unique. Please try another one.')
                } else {
                    callback('Default value is not a valid')
                }
            }
        } else {
            callback()
        }
    }

    return (
        <>
            {props.isEdit
                ? <Form>
                    <Row>
                        <Col span={24}>
                            <Form.Item label='Department Name'>
                                {getFieldDecorator('departmentNameEdit', {
                                    initialValue: dataEdit?.departmentName,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Department Name is required'
                                        },
                                        {
                                            validator: validateDepartment
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Department Name"
                                        maxLength={50}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item style={{ textAlign: 'center', paddingBottom: '4%', marginTop: 10}}>
                            <Button type="primary" htmlType="button" style={{ float: 'right' }} loading={isLoading} onClick={() => updateDepartment()}>
                        Submit
                            </Button>
                            <Button type="default" htmlType="button" style={{ float: 'right', marginRight: 10 }} onClick={() => closeModalConfirm()}>
                        Cancel
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>
                : <Form>
                    <Row>
                        <Col span={24}>
                            <Form.Item label='Department Name'>
                                {getFieldDecorator('departmentName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Department Name is required'
                                        },
                                        {
                                            validator: validateDepartment
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Department Name"
                                        maxLength={50}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Item style={{ textAlign: 'center', paddingBottom: '4%', marginTop: 10}}>
                            <Button type="primary" htmlType="button" style={{ float: 'right', marginRight: 10 }} loading={isLoading} onClick={addNewDepartment}>
                                Add
                            </Button>
                        </Form.Item>
                    </Row>
                </Form>}
        </>

    )
}

const DepartmentFormModal = Form.create<Props>()(DepartmentForm)
export default DepartmentFormModal
