import {Order, PackageDashboard, packages, payment, tenantProfile, userPackage} from './model'
import {axiosGet, axiosNonAuthGet, axiosPatch, axiosPost} from './rest'
import {Dispatch} from 'redux'
import {GetAllOrderSuccessEvent} from '../ManageState/state-event'
import { herderPostPatch, tokenDecode } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'

const TenantId = tokenDecode()?.tenantID || sessionStorage.getItem('tenant') || '0'

export async function getDashboardPackageByTenantId(): Promise<PackageDashboard> {
    try {
        const res = await axiosGet<PackageDashboard>(`/api/orders/${TenantId}/dashboard`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const getAllOrder = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Order[]>('/api/orders')
            dispatch(GetAllOrderSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export async function getAllOrderNoRedux(): Promise<Order[]> {
    try {
        const response = await axiosGet<Order[]>('/api/orders')
        return decryptBody(response.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function createOrder(order: Order): Promise<Order> {
    try {
        const encryptData = encryptBody(JSON.stringify(order))
        const response = await axiosPost<Order>('/api/orders', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function updateOrder(id: number, order: Order): Promise<Order> {
    try {
        const encryptData = encryptBody(JSON.stringify(order))
        const response = await axiosPatch<Order>(`/api/orders/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function getOrderById(id: string): Promise<Order> {
    try {
        const response = await axiosGet<Order>(`/api/orders/${id}`)
        return decryptBody(response.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function getSubscriptionPackageByTenantId(): Promise<userPackage[]> {
    try {
        const res = await axiosGet<userPackage[]>(`/api/subscription/getSubscriptionPackage/${TenantId}`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function getAllPackageMaster(): Promise<packages[]> {
    try {
        const res = await axiosGet<packages[]>(`/api/package-masters/${TenantId}/all`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function getTenantProfileByTenantId(): Promise<tenantProfile> {
    try {
        const res = await axiosGet<tenantProfile>(`/api/tenant-settings/${TenantId}`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function updateTenantProfileByTenantId(id: number, data: tenantProfile): Promise<tenantProfile> {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const res = await axiosPatch<tenantProfile>(`/api/tenant-settings/${id}`, encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function addPayment(data: payment): Promise<payment> {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const res = await axiosPost<payment>('/api/payment', encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function updatePayment(id: number, data: payment): Promise<payment> {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const res = await axiosPatch<payment>(`/api/payment/${id}`, encryptData, herderPostPatch)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function getPackageFreeTrial() {
    try {
        const response = await axiosNonAuthGet('/api/package-masters/free-trial')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
