/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Table, Collapse } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {FilterData, related} from '../Model'
import { getDetailAssetByIDPM } from '../services'
import { waitingGetSessions } from '../../guard/route'

interface Param {
    PMID: string
    isParentsLoading: boolean
    valueFilter: FilterData
    valueSearch?: string
}

type Props = Param;
const { Panel } = Collapse
const ExpandListAsset: React.FC<Props> = (props: Props) => {
    const [dataSource, setDataSource] = useState<related[]>([])
    const [totalDataSource, setTotalDataSource] = useState<number>(0)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [isLoadingTable, setIsLoadingTable] = useState(false)

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.PMID, page, pageSize, props.valueFilter, props.valueSearch])

    useEffect(() => {
        if (props.isParentsLoading) {
            fetchData(1)
            setPage(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isParentsLoading])

    const fetchData = (fixPage?: number) => {
        setIsLoadingTable(true)
        getDetailAssetByIDPM(props.PMID, fixPage || page, pageSize, props.valueFilter, props.valueSearch).then((res) => {
            if (res) {
                setDataSource(res.data)
                setTotalDataSource(res.total)
            }
        }).finally(() => {
            setIsLoadingTable(false)
        })
    }

    const columns = [
        {
            title: 'Serial Number',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {/* {props.dataSource?.preventiveMaintenance.pmStatus === 'Completed' ? <Link to={'/CompletePMFormView/' + props.dataSource?.preventiveMaintenance.id}>{data.assetTag}</Link>
                        : props.dataSource?.preventiveMaintenance.pmId
                            ? <Link to={'/pmStep/' + props.dataSource?.preventiveMaintenance.id}>{data.assetTag}</Link>
                            : <Link to={data.id ? '/AssetFormView/' + data.id : '/AssetFormViewByTag/' + data.assetTag}>{data.assetTag}</Link>} */}
                    <Link to={'/pmStep/' + props.PMID + '?serialNumber=' + data?.serialNumber} target="_blank" onClick={() => waitingGetSessions()}>{data?.serialNumber}</Link>
                </>
            )
        },
        {
            title: 'PM Status',
            dataIndex: 'pmStatus',
            key: 'pmStatus',
            render: (data) => (
                <>
                    {data === 'OnProcess' ? 'On Process' : data === 'WaitingForSign' ? 'Waiting for sign' : data}
                </>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Group',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.group}
                </>
            )
        },
        {
            title: 'PM Template',
            dataIndex: 'templateName',
            key: 'templateName',
        },
        {
            title: 'Owner Name',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.ownerName}
                </>
            )
        },
        {
            title: 'Asset Status',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.status}
                </>
            )
        },
        {
            title: 'Department',
            dataIndex: '',
            key: '',
            render: (data: related) => (
                <>
                    {data.department}
                </>
            )
        },
        {
            title: 'Pending Reason',
            dataIndex: 'pendingReason',
            key: 'pendingReason'
        }
    ]

    const onchangeTable = (event) => {
        setPage(event)
    }

    function callback(key) {
        if (key[0] === '1') {
            fetchData()
        }
    }

    return (
        <div>
            <Collapse defaultActiveKey={['1']} onChange={callback}>
                <Panel header={props.PMID} key="1">
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        loading={isLoadingTable}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: totalDataSource,
                            showSizeChanger: true,
                            onShowSizeChange(current, size) {
                                setPageSize(size)
                                setPage(1)
                            },
                            pageSizeOptions: ['10', '30', '50', '80', '100'],
                            size: 'medium',
                            onChange: (event) => { onchangeTable(event) }
                        }}
                        rowKey="asset.serialNumber"
                    />
                </Panel>
            </Collapse>
        </div>
    )
}

export default ExpandListAsset
