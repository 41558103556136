import { Col, Icon, Progress, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Problem } from '../Model'
import { summaryTemplate } from '../../../incident-management/incident/model'
import { searchAllRelateProblemById } from '../service'

interface Params {
    visibleRelated: boolean
    setVisibleRelated: Function
    problem: Problem
}
type Props = Params
const RelateListProblem = (props: Props) => {
    const [dataRelate, setDataRelate] = useState<summaryTemplate>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.problem.id) {
            setIsLoading(true)
            searchAllRelateProblemById(props.problem.id.toString()).then((res) => {
                if (res) {
                    // console.log(res)
                    setDataRelate(res)
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.problem.id])

    const handleClose = () => {
        props.setVisibleRelated(!props.visibleRelated)
    }

    return (
        <div style={{padding: 15, minWidth: 450}}>
            <Row gutter={[26, 26]}>
                <Col span={22}>
                    <div style={{ fontSize: 15, fontWeight: 500 }}>Related ({props.problem.number})</div>
                </Col>
                <Col span={2}>
                    <div style={{ textAlign: 'end', paddingRight: 15}}>
                        <Icon type="close" onClick={() => handleClose()}/>
                    </div>
                </Col>
            </Row>
            <hr style={{ border: '1px solid #DCDCDC'}}/>
            <br/>
            <Spin spinning={isLoading}>
                {dataRelate?.ticket?.total !== 0 ? <Row gutter={[26, 26]}>
                    <div style={{ marginLeft: 15, fontSize: 13, fontWeight: 400}}>
                        <span style={{ color: '#2F80ED'}}>Ticket</span> (Open {dataRelate?.ticket?.open} Ticket / Total {dataRelate?.ticket?.total} Ticket)
                    </div>
                    <Col span={24}>
                        <Progress
                            strokeColor={{
                                from: '#108ee9',
                                to: '#87d068'
                            }}
                            percent={dataRelate?.ticket.percent}
                            status={dataRelate?.ticket.percent === 100 ? 'normal' : 'active'}
                        />
                    </Col>
                </Row> : null}
                {dataRelate?.asset?.total !== 0 ? <Row gutter={[26, 26]}>
                    <div style={{ marginLeft: 15, fontSize: 13, fontWeight: 400}}>
                        <span style={{ color: '#2F80ED'}}>Asset </span> (Total {dataRelate?.asset?.total} Asset)
                    </div>
                    <Col span={24}>
                        <Progress
                            strokeColor={{
                                from: '#108ee9',
                                to: '#87d068'
                            }}
                            percent={100}
                        />
                    </Col>
                </Row> : null}
                {dataRelate?.knowledge?.total !== 0 ? <Row gutter={[26, 26]}>
                    <div style={{ marginLeft: 15, fontSize: 13, fontWeight: 400}}>
                        <span style={{ color: '#2F80ED'}}>Knowledge</span> (Total {dataRelate?.knowledge?.total} Knowledge)
                    </div>
                    <Col span={24}>
                        <Progress
                            strokeColor={{
                                from: '#108ee9',
                                to: '#87d068'
                            }}
                            percent={100}
                        />
                    </Col>
                </Row> : null}
            </Spin>
        </div>
    )
}

export default RelateListProblem
