/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { Button, Card, Col, message, Modal, Input, Row, Table, Form } from 'antd'
import { Knowledge, KnowledgeList, KnowledgeStatus } from '../knowledge'
import { getAllKnowledgeWithApprovalStatus } from './service'
import { updateKnowledgeStatus } from '.'
import { Link } from 'react-router-dom'
import { FormComponentProps } from 'antd/lib/form'
import { convertCamelCaseToSentenceCase } from '../util'
const pageSize = require('../knowledge/config.json').pageSize

interface Params {
    knowledgeList: KnowledgeList
}

type Props = Params & FormComponentProps

const ApprovalList: React.FC<Props> = (props: Props) => {
    const [knowledge, setKnowledge] = useState<KnowledgeList>(props.knowledgeList)
    const [isLoadForApproveButtonKey, setOnLoadForApproveButtonKey] = useState<string>('')
    const [isLoadingForRetireButtonKey, setIsLoadingForRetireButtonKey] = useState<string>('')
    const [isLoadingForRejectButton, setIsLoadingForRejectButton] = useState<boolean>(false)
    const [isVisibleRejectModal, setIsVisibleRejectModal] = useState<boolean>(false)
    const [onSearch, setOnSearch] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [knowledgeId, setKnowledgeId] = useState<number>(0)
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = props.form
    const listOfStatus = [KnowledgeStatus.AwaitingApproval, KnowledgeStatus.AwaitingRetire]

    const param = {
        currentPage: currentPage, listStatus: listOfStatus
    }

    useEffect(() => {
        if (props.knowledgeList) {
            setKnowledge(props.knowledgeList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.knowledgeList])

    useEffect(() => {
        if (onSearch) {
            getAllKnowledgeWithApprovalStatus(param).then((knowledgeList) => {
                setKnowledge(knowledgeList)
            }).catch((error) => {
                message.error(error.message)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSearch, currentPage])

    const columns = [{
        title: 'ID',
        key: 'ticketNumber',
        width: '15%',
        // eslint-disable-next-line react/display-name
        render: (knowledge: Knowledge) => {
            return (
                <Link to={`/ApprovalPreview/${knowledge.id}`}>{knowledge.ticketNumber}</Link>
            )
        }
    },
    {
        title: 'Topic',
        dataIndex: 'topic',
        key: 'topic',
        width: '45%'
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '15%',
        // eslint-disable-next-line react/display-name
        render: (status: string) => {
            return (
                <span>{convertCamelCaseToSentenceCase(status || '')}</span>
            )
        }
    },
    {
        title: '',
        key: 'action',
        width: '20%',
        // eslint-disable-next-line react/display-name
        render: (knowledge: Knowledge) => {
            if (knowledge.status === KnowledgeStatus.AwaitingApproval) {
                return (
                    <Row gutter={[16, 16]}>
                        <span className='approval-button-group'>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="danger" id={knowledge.id?.toString()} onClick={onReject}>Reject</Button>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="primary" loading={isLoadForApproveButtonKey.toString() === knowledge.id?.toString()} id={knowledge.id?.toString()} onClick={onApprove}>Publish</Button>
                            </Col>
                        </span>
                    </Row>
                )
            } else if (knowledge.status === KnowledgeStatus.Published) {
                return (
                    <span>You have Successfully Approved.</span>
                )
            } else if (knowledge.status === KnowledgeStatus.Rejected) {
                return (
                    <span>You have Successfully Rejected.</span>
                )
            } else if (knowledge.status === KnowledgeStatus.Retired) {
                return (
                    <span>You have Successfully Retired.</span>
                )
            } else if (knowledge.status === KnowledgeStatus.AwaitingRetire) {
                return (
                    <Row gutter={[16, 16]}>
                        <span className='approval-button-group'>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="danger" id={knowledge.id?.toString()} onClick={e => {
                                    onReject(e)
                                }}>Reject</Button>
                            </Col>
                            <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12} style={{ textAlign: 'right' }}>
                                <Button type="primary" loading={isLoadingForRetireButtonKey.toString() === knowledge.id?.toString()} id={knowledge.id?.toString()} onClick={onRetire}>Retire</Button>
                            </Col>
                        </span>
                    </Row>
                )
            }
        }
    }]

    const onApprove = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setOnLoadForApproveButtonKey(event.currentTarget.id)
        console.log('onApprove', event.currentTarget.id)
        updateKnowledgeStatus(Number(event.currentTarget.id), true).then(knowledge => {
            message.success('You have Successfully Approved the data.')
            updateKnowledgeListStatus(knowledge)
            setOnLoadForApproveButtonKey('')
        })
    }

    const validateReject = (_: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{3}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                callback()
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 3 letters and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 letters')
                }
            }
        } else {
            callback()
        }
    }

    const onRetire = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setIsLoadingForRetireButtonKey(event.currentTarget.id)
        updateKnowledgeStatus(Number(event.currentTarget.id), true).then(knowledge => {
            message.success('You have Successfully Approved the data.')
            updateKnowledgeListStatus(knowledge)
            setIsLoadingForRetireButtonKey('')
        })
    }

    const onReject = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setKnowledgeId(Number(event.currentTarget.id))
        setIsVisibleRejectModal(true)
    }

    const onSubmitReject = () => {
        validateFields((error, row) => {
            if (error) {
                return
            }
            row.RejectReason = row.RejectReason ? row.RejectReason.replace(/ +/g, ' ').trim() : undefined
            setIsLoadingForRejectButton(true)
            updateKnowledgeStatus(knowledgeId, false, row.RejectReason).then(knowledge => {
                message.success('You have Successfully Rejected the data.')
                updateKnowledgeListStatus(knowledge)
                setIsVisibleRejectModal(false)
                setIsLoadingForRejectButton(false)
                setFieldsValue({ RejectReason: undefined })
            })
        })
    }

    const updateKnowledgeListStatus = (result: Knowledge) => {
        const newContent = knowledge.content?.map((knowledge) => {
            if (knowledge.id === result.id) {
                return result
            } else {
                return knowledge
            }
        })
        const newKnowledge = knowledge
        newKnowledge.content = newContent
        setKnowledge(newKnowledge)
    }

    const handleOnClickPageNumber = (page: number) => {
        setCurrentPage(page)
        setOnSearch(true)
    }

    const onCancel = () => {
        setIsVisibleRejectModal(false)
        resetFields()
    }

    return (
        <>
            <Col span={18} xs={24} sm={24} md={24} lg={24} xl={18}>
                <Card className='card-height-approval-default'>
                    <Row>
                        <h2 className="main-title">
                            Knowledge ({knowledge?.pagination?.totalElements || '0'})
                        </h2>
                    </Row>
                    <div style={{ overflowX: 'auto' }}>
                        <Row>
                            <Table
                                className="table-configherder"
                                dataSource={knowledge?.content || []}
                                columns={columns}
                                rowKey="id"
                                style={{ minWidth: 500 }}
                                pagination={{
                                    pageSize: pageSize,
                                    current: knowledge?.pagination?.currentPage || 1,
                                    total: knowledge?.pagination?.totalElements || 0,
                                    onChange: (event) => { handleOnClickPageNumber(event) }
                                }}
                            />

                        </Row>
                    </div>
                </Card>
                <Modal
                    title="Reject Reason"
                    visible={isVisibleRejectModal}
                    onCancel={onCancel}
                    footer={[
                        <Button key="back" onClick={onCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" loading={isLoadingForRejectButton} type="primary" onClick={onSubmitReject}>
                            Submit
                        </Button>
                    ]}>
                    <Form>
                        <Form.Item label='Reject reason'>
                            {getFieldDecorator('RejectReason', {
                                rules: [
                                    {
                                        required: true,
                                        whitespace: true,
                                        message: 'Reject reason is required'
                                    },
                                    {
                                        validator: validateReject
                                    }]
                            })(
                                <Input.TextArea rows={4} placeholder="please enter description" />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </Col>
        </>
    )
}

const ApprovalListForm = Form.create<Props>()(ApprovalList)

export default ApprovalListForm
