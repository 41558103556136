import { decryptBody, encryptBody } from './../../common-misc/encryptBody'
import { Dispatch } from 'redux'
import { GetAllKnowledgeSuccessEvent, DeleteKnowledgeSuccessEvent } from './state-event'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../rest'
import { Knowledge, KnowledgeList, KnowledgeStatus } from './model'
import {Permissions, RoleState} from '../../authorization-module/permissions'
import { checkRedirect, herderPostPatch } from '../../common-misc'

const pageSize = require('./config.json').pageSize
export const checkKmAdminPermission = () => {
    const roleState: RoleState = Permissions.getInstance().roleState
    if (roleState.knowledge && roleState.knowledge.permissions?.length !== 0) {
        return roleState.knowledge.permissions?.includes('31')
    } else {
        return false
    }
}

export const checkDeletePermission = () => {
    const roleState = Permissions.getInstance().roleState
    if (roleState.knowledge && roleState.knowledge.permissions?.length !== 0) {
        return roleState.knowledge.permissions?.includes('31')
    } else {
        return false
    }
}

export const getAllKnowledge = (currentPage: number = 1, size: number = pageSize, groupUser?: string[]) => {
    const isAdmin = checkKmAdminPermission()
    return async (dispatch: Dispatch) => {
        try {
            const body = {
                page: currentPage,
                size: pageSize,
                groups: groupUser,
                isAdmin: isAdmin ? 0 : 1
            }
            const encryptData = encryptBody(JSON.stringify(body))
            const response = await axiosPost<KnowledgeList>('/api/knowledge/pagination', encryptData, herderPostPatch)
            dispatch(GetAllKnowledgeSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const getKnowledgeById = async (id: string) => {
    try {
        const response = await axiosGet<Knowledge>(`/api/knowledge/${id}`)
        console.log('response', response)
        if (response.data.data) {
            return decryptBody(response.data.data!!)
        } else {
            return null
        }
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const getKnowledgeBySearch = async (currentPage: number, size: number, folderId?: string, categoryId?: string, status?: KnowledgeStatus, input?: string, groupUser?: string[]) => {
    const isAdmin = checkKmAdminPermission()
    try {
        // clear folder id cause it use to fillter all category in another function
        const criteria = {
            categoryId: categoryId || '',
            folderId: folderId || '',
            status: status || '',
            input: input || '',
            page: currentPage,
            size: size,
            isAdmin: isAdmin ? 0 : 1,
            groups: groupUser || []
        }
        const encryptData = encryptBody(JSON.stringify(criteria))
        if (folderId === 'all') { folderId = '' }
        const response = await axiosPost<KnowledgeList>('/api/knowledge/search', encryptData, herderPostPatch)
        sessionStorage.setItem('km_current_page', currentPage.toString())
        if (response.data.data) {
            return decryptBody(response.data.data)
        } else {
            return []
        }
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const createKnowledge = async (data: Knowledge) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<Knowledge>('/api/knowledge', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const updateKnowledge = async (data: Knowledge) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPatch<Knowledge>(`/api/knowledge/${data.id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const deleteKnowledgeByTicketNumber = (ticketNumber: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/knowledge/number/${ticketNumber}`)
            dispatch(DeleteKnowledgeSuccessEvent.build(ticketNumber))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const deleteKnowledgeById = (ticketId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/knowledge/${ticketId}`)
            dispatch(DeleteKnowledgeSuccessEvent.build(ticketId.toString()))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const deleteFolderById = (folderId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/folders/${folderId}`)
            // dispatch(DeleteKnowledgeSuccessEvent.build(ticketId.toString()))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const deleteCategoryById = (CategoryId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<Knowledge>(`/api/categories/${CategoryId}`)
            // dispatch(DeleteKnowledgeSuccessEvent.build(ticketId.toString()))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const historyByTicketNumberAndStatus = async (ticketNumber: string, Status: string) => {
    try {
        const response = await axiosGet<Knowledge[]>(`/api/knowledge/histories/${ticketNumber}/${Status}`)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const getTicketByTicketNumber = async (ticketNumber: string) => {
    try {
        const response = await axiosGet<Knowledge[]>(`/api/knowledge/number/${ticketNumber}`)
        return decryptBody(response.data.data) || []
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const searchKnowledgeRelatedCreateIncident = async (search?: string | undefined, sortBy?: string, orderBy?: string, skip?: number, limit?: number) => {
    try {
        const config = {
            params: {
                value: search,
                page: skip,
                size: limit,
                sort: `${sortBy},${orderBy}`
            }
        }
        const response = await axiosGet<Knowledge>('api/knowledge/all/published', config)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const searchKnowledgeRelatedIncidentView = async (TicketId: number | undefined, search?: string | undefined, sortBy?: string, orderBy?: string, skip?: number, limit?: number) => {
    try {
        const config = {
            params: {
                value: search,
                page: skip,
                size: limit,
                sort: `${sortBy},${orderBy}`,
                incidentId: TicketId
            }
        }
        const path = 'api/knowledge/all/published'
        const response = await axiosGet<Knowledge>(path, config)
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getProblemHistoryKnowError = async (id: string) => {
    try {
        const response = await axiosGet<Knowledge[]>(`/api/problem-history/${id}/lists`)
        return decryptBody(response.data.data) || []
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}
