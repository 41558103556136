/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../store'
import {
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Icon,
    message,
    Modal,
    Row,
    Select,
    Switch,
    Table,
    Tooltip
} from 'antd'
import { Link } from 'react-router-dom'
import { FetchingState, fetchWithState, SpinningFetcher } from '../../common-components'
import { deleteSupportTeam, editSupportTeam, getSupportTeamsWithPagination } from './service'
import { SupportTeamManagement } from './model'
import Search from 'antd/es/input/Search'
import { FormComponentProps } from 'antd/es/form'
import {
    GetAllSupportTeamSuccessEvent,
    UpdateSupportTeamCurrentPageSuccessEvent,
    UpdateSupportTeamFilterSuccessEvent,
    UpdateSupportTeamOrderBySuccessEvent
} from './state-event'
import { encodeSpecialCharacter } from '../../common-misc'
import TableAssignTeam from './TableAssignTeam'
import { useLastLocation } from 'react-router-last-location'

const mapStateToProps = (state: StoreState) => {
    return {
        supportTeamManagement: state.supportTeamManagement,
        supportTeamManagementPagination: state.supportTeamManagementPagination,
        tablePagination: state.tablePagination
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllSupportTeam: (page: number, pageSize: number, searchField: string, searchValue: string, activateFilter: boolean[], sortBy: string, orderBy: string) => Promise<number>
    editSupportTeam: (id: string, s: any) => Promise<number>
    deleteSupportTeam: (id: string) => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps

const SupportTeamList: React.FC<Props> = (props: Props) => {
    const { Option } = Select
    const { getFieldDecorator } = props.form
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [supportTeam, setSupportTeam] = useState<SupportTeamManagement[]>([])
    const [page, setPage] = useState<number>(1)
    const pageSize = 10
    const searchField = props.form.getFieldValue('searchField') === undefined ? '0' : props.form.getFieldValue('searchField')
    const searchValue = props.form.getFieldValue('searchValue') === undefined ? '' : props.form.getFieldValue('searchValue')
    const dispatch = useDispatch()
    const [sortBy, setSortBy] = useState<string>('lastModifiedDatetime')
    const [orderBy, setOrderBy] = useState<any>()
    const [activateFilter, setActivateFilter] = useState<boolean[]>([true, false])
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    const lastLocation = useLastLocation()

    useEffect(() => {
        let isLastView
        if (lastLocation?.pathname.search('/View')) {
            isLastView = lastLocation?.pathname.search('/View') >= 0
        }

        if (isLastView && props.supportTeamManagementPagination.page) {
            fetch(props.supportTeamManagementPagination.page, searchField, encodeSpecialCharacter(searchValue), activateFilter, sortBy, (orderBy || 'desc'))
        } else {
            fetch(page, searchField, encodeSpecialCharacter(searchValue), activateFilter, sortBy, (orderBy || 'desc'))
        }
    }, [page, orderBy, props.supportTeamManagementPagination.page, activateFilter])

    useEffect(() => {
        if (props.supportTeamManagement.length !== 0) {
            if (props.supportTeamManagement[0]) {
                setSupportTeam(props.supportTeamManagement)
            }
        } else {
            if (page > 1) {
                setPage(page - 1)
            } else {
                setPage(1)
            }
            setSupportTeam([])
        }
    }, [props.supportTeamManagement])

    const fetch = (page: number, searchField: string, searchValue: string, activateFilter: boolean[], sortBy: string, orderBy: string) => {
        // page = props.supportTeamManagementPagination.page !== undefined && props.supportTeamManagementPagination.page > 1
        // ? props.supportTeamManagementPagination.page : page
        // activateFilter = props.supportTeamManagementPagination.filter?.length !== 0 && props.supportTeamManagementPagination.filter?.length !== undefined
        //     ? props.supportTeamManagementPagination.filter as boolean[] : [true, false]
        orderBy = props.supportTeamManagementPagination.orderBy !== undefined
            ? changeValue(props.supportTeamManagementPagination.orderBy) : orderBy
        sortBy = props.supportTeamManagementPagination.sortBy !== undefined
            ? props.supportTeamManagementPagination.sortBy as string : sortBy
        fetchWithState(FetchingState.NotStarted, setFetchingState,
            () => props.getAllSupportTeam(page, pageSize, searchField, encodeSpecialCharacter(searchValue), activateFilter, sortBy, (orderBy || 'desc')).then((res) => {
                console.log(lastLocation?.pathname)
            }).catch(() => {
                // message.error('No data')
                dispatch(GetAllSupportTeamSuccessEvent.build([]))
                setSupportTeam([])
            }))
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        dispatch(UpdateSupportTeamCurrentPageSuccessEvent.build(page))
        fetch(page, searchField, encodeSpecialCharacter(searchValue), activateFilter, sortBy, (orderBy || 'desc'))
    }

    const renderFilter = () => {
        return (<>
            <Checkbox.Group style={{ width: '100%' }} onChange={handleFilter} value={activateFilter}>
                <Row>
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={true}>Activate</Checkbox>
                    </Col>
                    <br />
                    <Col span={24} style={{ margin: 10 }}>
                        <Checkbox value={false}>Deactivate</Checkbox>
                    </Col>
                </Row>
            </Checkbox.Group>
        </>)
    }

    const columns = [
        {
            title: '',
            dataIndex: 'actionEdit',
            key: 'actionEdit',
            width: '3%',
            render: (text, record) => (
                <>
                    <div style={{ textAlign: 'center' }}>
                        <Tooltip placement="bottom" title={'Edit'}><Link
                            to={`/SupportTeamSettingForm/${record.id}/Edit`}>
                            <Icon className="edit_icon" type="edit" /></Link></Tooltip>
                    </div>
                </>
            )
        }, {
            title: '',
            dataIndex: 'actionDelete',
            key: 'actionDelete',
            width: '3%',
            render: (text, record) => (
                <>
                    <div style={{ textAlign: 'center' }}>
                        <Tooltip placement="bottom" title={'Delete'}>
                            <Icon className="delete_icon" type="delete" onClick={() => {
                                handleOnDelete(record.id, record.activate)
                            }} /></Tooltip>
                    </div>
                </>
            )
        }, {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '40%',
            sorter: true,
            sortOrder: props.supportTeamManagementPagination.orderBy,
            render: (name, record) => {
                return (
                    <>
                        <Link to={`/SupportTeamSettingForm/${record.id}/View`}>
                            <Tooltip title={name}>
                                <span>{name.length > 80 ? name.slice(0, 80) + "..." : name}</span>
                            </Tooltip>
                        </Link>
                    </>
                )
            }
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '40%'
        }, {
            title: '',
            dataIndex: 'activate',
            key: 'activate',
            width: '5%',
            filters: [
                {
                    text: 'Activate',
                    value: 'true'
                },
                {
                    text: 'Deactivate',
                    value: 'false'
                }
            ],
            filteredValue: activateFilter,
            filterDropdown: renderFilter,
            render: (text, record) => (
                <>
                    <Tooltip placement="bottom" title={record.activate ? 'Deactivate' : 'Activate'}>
                        <Switch className={`${record.id}`}
                            checked={record.activate}
                            onChange={(checked) => {
                                handleOnCheck(record, checked)
                            }} />
                    </Tooltip>
                </>
            )
        }
    ]

    const handleFilter = (value) => {
        dispatch(UpdateSupportTeamFilterSuccessEvent.build(value))
        if (value) {
            if (value.length === 1) {
                if (value[0] === 'true' || value[0] === true) {
                    setActivateFilter([true])
                } else {
                    setActivateFilter([false])
                }
            } else if (value.length === 2) {
                setActivateFilter([true, false])
            } else {
                setActivateFilter([])
            }
            setPage(1)
        }
    }

    const handleOnSearch = (value: any) => {
        fetchWithState(FetchingState.NotStarted, setFetchingState,
            () => props.getAllSupportTeam(page, pageSize, searchField, encodeSpecialCharacter(value), activateFilter, sortBy, (orderBy || 'desc'))
                .catch(() => {
                    message.error('No data')
                    dispatch(GetAllSupportTeamSuccessEvent.build([]))
                }
                ))
    }

    const handleOnCheck = (row: any, checked: boolean) => {
        const activate = { activate: checked }
        const word = checked ? 'Activate' : 'Deactivate'
        Modal.confirm({
            title: 'Are you sure?',
            content: `Do you confirm ${word} this data?`,
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            onCancel: () => {
            },
            onOk: () => {
                props.editSupportTeam(row.id, activate)
                    .then(result => {
                        // message.success('You have Successfully save the data')
                    })
                    .catch(err => {
                        message.error(err, 5)
                    })
            }
        })
    }

    const handleOnDelete = (id: string, activate: boolean) => {
        if (activate) {
            message.warning('Please Deactivate')
        } else {
            Modal.confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                onCancel: () => {
                },
                onOk: () => {
                    if (activate) {
                        message.warning('Please deactivate before delete')
                    } else {
                        props.deleteSupportTeam(id).then(() => {
                            fetch(page, searchField, encodeSpecialCharacter(searchValue), activateFilter, sortBy, (orderBy || 'desc'))
                            message.success('This Group was deleted.')
                        }).catch(err => message.error(`You have Unsuccessfully delete the data. ${err}`))
                    }
                }
            })
        }
    }

    const handleOnDeleteAssignee = (supportTeamId, patch) => {
        if (supportTeamId && patch) {
            props.editSupportTeam(supportTeamId, patch).catch(err => message.error(`You have Unsuccessfully delete the data. ${err}`))
        }
    }

    const changeValue = (v: string) => {
        if (v === 'ascend') {
            return 'asc'
        } else if (v === 'descend') {
            return 'desc'
        } else {
            return v
        }
    }

    // eslint-disable-next-line no-empty-pattern
    const handleOnTableChange = (obj1, obj2, sorter: any) => {
        // dispatch(UpdateSupportTeamSortBySuccessEvent.build(sorter.field))
        dispatch(UpdateSupportTeamOrderBySuccessEvent.build(sorter.order))
        if (sorter.order) {
            if (sorter.order === 'ascend') {
                setOrderBy('asc')
            } else {
                setOrderBy('desc')
            }
            setSortBy(sorter.field)
        } else {
            setOrderBy(undefined)
            setSortBy('lastModifiedDatetime')
        }
        forceUpdate()
    }

    const expandedRowRender = (expandedRow: SupportTeamManagement) => {
        return <TableAssignTeam data={expandedRow.assignees || []} updateSUP={handleOnDeleteAssignee}
            SupportTeam={props.supportTeamManagement} supportTeamId={expandedRow.id!!} />
    }
    console.log('supportTeam : ', supportTeam)
    return (<div className='fix_scroll_md'>
        <Breadcrumb separator=">" className={'content'}>
            <Breadcrumb.Item>
                <Link to="/Setting">Setting</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Group</Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <Card
            title={
                <div>
                    <h3 style={{ paddingLeft: '24px' }} className={'main-title'}>Group</h3>
                </div>
            } extra={
                <span style={{ paddingRight: '20px' }}><Button type={'primary'}>
                    <Link to="/SupportTeamSettingForm/action/Create">Create</Link></Button>
                </span>
            }
        // headStyle={{borderBottom: 'none'}}
        >
            <div>
                <Form layout="inline" style={{ float: 'right' }}>
                    <Form.Item>
                        {getFieldDecorator('searchField', { initialValue: '0' })(
                            <Select style={{ paddingRight: '5px', width: '150px' }}>
                                <Option value='0'>All</Option>
                                <Option value='1'>Name</Option>
                                <Option value='2'>Email</Option>
                                <Option value='3'>Assignee</Option>
                            </Select>)
                        }
                    </Form.Item>
                    <Form.Item style={{ marginRight: '0' }}>
                        {getFieldDecorator('searchValue')(
                            <Search placeholder="Can search Name, Email, or Assignee"
                                onSearch={value => handleOnSearch(value)}
                                style={{ paddingRight: '-5px', width: '280px' }} allowClear
                                onPressEnter={(value) => handleOnSearch(value.currentTarget.value)} />
                        )}
                    </Form.Item>
                </Form>
                <br /><br />
                <SpinningFetcher fetchingState={fetchingState}>
                    <Table
                        columns={columns}
                        rowKey="id"
                        expandedRowRender={expandedRowRender}
                        dataSource={supportTeam || []}
                        pagination={{
                            pageSize: pageSize,
                            current: props.supportTeamManagementPagination.page,
                            total: props.supportTeamManagementPagination.total,
                            onChange: (event) => {
                                handleOnClickPageNumber(event)
                            }
                        }}
                        onChange={handleOnTableChange}
                        locale={{
                            filterConfirm: '',
                            filterReset: ''
                        }}
                        scroll={{ x: 768 }}
                    />
                </SpinningFetcher>
            </div>
        </Card>
    </div>)
}

const wrappedForm = Form.create({ name: 'SupportTeamList' })(SupportTeamList)
export default connect(
    mapStateToProps,
    { getAllSupportTeam: getSupportTeamsWithPagination, editSupportTeam, deleteSupportTeam }
)(wrappedForm)
