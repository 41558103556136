import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const SetNoneCustomFieldListContext = createContext({})

const initialState: string[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const SetNoneCustomFieldListReducer = (state: string[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_SetNoneCustomFieldList':
        return action.payload || [] as string[]
    default:
        return state
    }
}

export const SetNoneCustomFieldListProvider = (props: props) => {
    const [SetNoneCustomFieldListState, SetNoneCustomFieldListDispatch] = useReducer(SetNoneCustomFieldListReducer, initialState)

    const GetSetNoneCustomFieldList = (payload: string[]) => {
        SetNoneCustomFieldListDispatch({ type: 'GET_SetNoneCustomFieldList', payload })
    }

    return (
        <SetNoneCustomFieldListContext.Provider value={{ SetNoneCustomFieldListState, GetSetNoneCustomFieldList }}>
            {props.children}
        </SetNoneCustomFieldListContext.Provider>
    )
}
