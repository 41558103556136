import { herderPostPatch } from '../common-misc'
import { decryptBody, encryptBody } from '../common-misc/encryptBody'
import { axiosGet, axiosGetWithPagination, axiosPost, axiosDelete } from '../msp-wo/asset-management/rest'
import { convertResponseToFile } from '../ReportComponent/components/Services/Service'

export const getAllLabelActive = async () => {
    try {
        const response = await axiosGet('/api/items/labels')
        return decryptBody(response.data.data!!)
    } catch (err) {
        console.log(err)
        throw err
    }
}

export const getAllItemsAssetSettingWithPagination = async (id: string, value?: string, page?: number, size?: number, sort?: string) => {
    try {
        const response = await axiosGetWithPagination(`/api/items/${id}?value=${value}&page=${page}&size=${size}&sort=${sort}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const createItemOneTier = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/items', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const createItemAssetLocation = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/locations', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const createItemAssetGroupType = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/asset-group-types', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getDropdownOption = async (items) => {
    try {
        const response = await axiosGet(`/api/items/${items}/options`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getHieracrhyOption = async (level: string) => {
    try {
        const response = await axiosGet(`/api/locations/options/hierarchy?level=${level}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const deleteItemsAssetSetting = async (listId: number[]) => {
    try {
        const response = await axiosDelete(`/api/items?ids=${listId}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getAllitemsAssetLocation = async (type: number, value?: string, page?: number, size?: number, sort?: string) => {
    try {
        const response = await axiosGetWithPagination(`/api/locations/search?type=${type}&value=${value}&page=${page}&size=${size}&sort=${sort}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const deleteAssetLocation = async (listId: number[]) => {
    try {
        const response = await axiosDelete(`/api/locations?ids=${listId}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getAssetGroupType = async (value?: string, page?: number, size?: number, sort?: string, id?: number) => {
    try {
        const response = await axiosGetWithPagination(`/api/asset-group-types/search?value=${value}&page=${page}&size=${size}&sort=${sort}&id=${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const deleteItemAssetGroupType = async (listId: number[]) => {
    try {
        const response = await axiosDelete(`/api/asset-group-types/list?ids=${listId}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getExportItemAsset = async (itemId: number) => {
    try {
        const response = await axiosGet(`/api/items/${itemId}/export`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getTemplateOnetier = async (type: string) => {
    try {
        const response = await axiosGet(`/api/items/template?type=${type}`, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob'
        })
        convertResponseToFile(response, 'Template_Import_' + type + '.xlsx')
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getTemplateAssetLocation = async () => {
    try {
        const response = await axiosGet('/api/locations/template', {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob'
        })
        convertResponseToFile(response, 'Template_Import_AssetLocation.xlsx')
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getTemplateAssetGroupType = async () => {
    try {
        const response = await axiosGet('/api/asset-group-types/template', {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob'
        })
        convertResponseToFile(response, 'Template_Import_AssetGroupTypes.xlsx')
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getTemplateAssetGroups = async () => {
    try {
        const response = await axiosGet('/api/asset-groups/template', {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob'
        })
        convertResponseToFile(response, 'Template_Import_AssetGroups.xlsx')
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getAllAssetGroupType = async () => {
    try {
        const response = await axiosGet('/api/asset-group-types')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const importOnetierByExcel = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/items/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const importAssetGroupType = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/asset-group-types/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const importAssetLocationByExcel = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/locations/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const importAssetGroupsByExcel = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/asset-groups/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getAssetGroups = async (value?: string, page?: number, size?: number, sort?: string) => {
    try {
        const response = await axiosGetWithPagination(`/api/asset-groups/search?value=${value}&page=${page}&size=${size}&sort=${sort}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const deleteItemAssetGroups = async (listId: number[]) => {
    try {
        const response = await axiosDelete(`/api/asset-groups/list?ids=${listId}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const createItemAssetGroups = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost('/api/asset-groups', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
