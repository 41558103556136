import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Table, message } from 'antd'
import { Link } from 'react-router-dom'
import { Space } from '../../../../common-components/Space'
import { SelfServiceCatalog, SelfServiceItem, ResponseContentDetails, SelfServiceCatagory } from '../../Model'
import { searchCatalogItems } from '../../service'
import { connect } from 'react-redux'
import { getAllGeneralIncidentSetting } from '../../../../incident-management/general-incident-setting/service'
import { StoreState } from '../../../../store'

interface Params {
    setCountItem: Function
    isViewCatalog: boolean
    catalog?: SelfServiceCatalog
    categories?: SelfServiceCatagory
}

const mapStateToProps = (state: StoreState) => {
    return {
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllGeneralIncidentSetting: () => Promise<number>
}

type Props = StateProps & DispatchProps & Params
const { Search } = Input

const ItemList: React.FC<Props> = (props: Props) => {
    const pageSize = 10
    const [dataSource, setDataSource] = useState<ResponseContentDetails<SelfServiceItem[]>>()
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<string>('name')
    const [orderBy, setOrderBy] = useState<string>('asc')
    const [catalogTarget, setCatalogTarget] = useState<string>()
    const [categoryTarget, setCategoryTarget] = useState<string>()
    const [, setOnsearch] = useState<string>('')
    const [searchText, setSearchText] = useState<string>('')

    useEffect(() => {
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general ticket setting data. ${err}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, props.catalog, props.categories])

    const getData = () => {
        setLoading(true)
        let catalogId = ''
        let categoryId = ''
        if (props.catalog) {
            catalogId = `${props.catalog.id}`
            setCatalogTarget(catalogId)
        } else if (props.categories) {
            catalogId = `${props.categories.catalog.id}`
            categoryId = `${props.categories.id}`
            setCatalogTarget(catalogId)
            setCategoryTarget(categoryId)
        }

        searchCatalogItems(page, pageSize, catalogId, categoryId, sortBy, orderBy).then((res) => {
            setDataSource(res)
            if (props.setCountItem) {
                props.setCountItem(res?.totalElements || res?.total)
            }
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'Item Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div>
                        <Link to={'/ItemCatalogView/' + row.id}>{data}</Link>
                    </div>
                </>
            )
        },
        props.isViewCatalog ? {

            title: 'Category Name',
            dataIndex: 'category.name',
            key: 'category.name',
            sorter: true
        } : {},
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: true
        }
    ]

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortBy(sorter.field)
        if (sorter.order === 'ascend') {
            setOrderBy('asc')
        } else if (sorter.order === 'descend') {
            setOrderBy('desc')
        } else {
            setOrderBy('asc')
            setSortBy('name')
        }
    }

    const handleSearch = (search: string) => {
        if (search) {
            let catalogId = ''
            let categoryId = ''
            if (props.catalog) {
                catalogId = `${props.catalog.id}`
            } else if (props.categories) {
                catalogId = `${props.categories.catalog.id}`
                categoryId = `${props.categories.id}`
            }
            searchCatalogItems(1, pageSize, catalogId, categoryId, sortBy, orderBy, undefined, undefined, search.trim()).then((items) => {
                if (items) {
                    setDataSource(items)
                }
            })
            setOnsearch(search.trim())
            setSearchText(search.trim())
        } else {
            setPage(1)
            getData()
        }
    }

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={10}>
                    <Search
                        placeholder="Can search Item Name"
                        onSearch={(value) => handleSearch(value)}
                        style={{ width: '100%' }}
                        allowClear={true}
                        onChange={(value) => setSearchText(value.target.value)}
                        value={searchText}
                    />
                </Col>
                <Col span={14}>
                    <div style={{ textAlign: 'end' }}><Link to={`/ItemCatalog?catalogId=${catalogTarget}&categoryId=${categoryTarget}`} >Add Item Catalog</Link></div>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Table
                    dataSource={dataSource?.content}
                    rowKey={'id'}
                    columns={props.isViewCatalog ? columns : columns.filter(data => data.title !== 'Catalog Name')}
                    loading={loading}
                    pagination={{
                        pageSize: pageSize,
                        current: page,
                        total: dataSource?.totalElements || dataSource?.total,
                        onChange: (event) => {
                            handleOnClickPageNumber(event)
                        }
                    }}
                    onChange={handleChangeTable}
                />
            </Row>
        </>
    )
}

export default connect(mapStateToProps,
    { getAllGeneralIncidentSetting }
)(ItemList)
