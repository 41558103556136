import React, { useEffect } from 'react'
import {Button, Col, Form, message, Select} from 'antd'
import {FormComponentProps} from 'antd/lib/form'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { FilterItem, getallCatalog, getallCategory } from '../Catalog'
import { getAllGeneralIncidentSetting } from '../../incident-management/general-incident-setting/service'

interface Params {
    setOpenFilter: Function
    setSearchFilter: Function
    isVisible?: boolean
    setPage?: Function
    searchFilter?: FilterItem
}

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatalogs: state.selfServiceCatalogs,
        selfServiceCatagories: state.selfServiceCatagories,
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getallCatalog: () => Promise<number>
    getallCategory: () => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
}

type Props = FormComponentProps & Params & StateProps & DispatchProps
const { Option } = Select
const FilterItemCatalog: React.FC<Props> = (props) => {
    const { getFieldDecorator, resetFields, setFieldsValue } = props.form

    // useEffect(() => {
    //     resetFields(['CategoryName'])
    // },[props.form.getFieldValue('CatalogName')])

    useEffect(() => {
        if (props.selfServiceCatalogs.content.length === 0) {
            props.getallCatalog().catch(err => {
                message.error(err)
            })
        }
        if (props.selfServiceCatagories.content.length === 0) {
            props.getallCategory().catch(err => {
                message.error(err)
            })
        }
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed loading initial general ticket setting data. ${err}`))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isVisible) {
            // resetFields()
            if (props.searchFilter) {
                setFieldsValue({
                    CatalogName: props.searchFilter.CatalogId || undefined,
                    CategoryName: props.searchFilter.CategoryId || undefined,
                    Status: props.searchFilter.Status || undefined,
                    Type: props.searchFilter.Type || undefined
                })
            } else {
                setFieldsValue({
                    CatalogName: undefined,
                    CategoryName: undefined,
                    Status: undefined,
                    Type: undefined
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisible])

    const handleSubmit = e => {
        e.preventDefault()
        let filtercatalogName:string[] = []
        let filterParentName:string[] = []
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
                if (props.setOpenFilter) {
                    props.setOpenFilter(false)
                }
                if (props.setSearchFilter) {
                    if (values.CatalogName) {
                        filtercatalogName = props.selfServiceCatalogs.content.filter((item) => values.CatalogName.includes(item.id?.toString())).map((it) => it.name)
                    }
                    if (values.CategoryName) {
                        filterParentName = props.selfServiceCatagories.content.filter((item) => values.CategoryName.includes(item.id?.toString())).map((it) => it.name)
                    }

                    const convertData: FilterItem = {
                        CatalogName: JSON.stringify(filtercatalogName),
                        CatalogId: values.CatalogName,
                        CategoryName: JSON.stringify(filterParentName),
                        CategoryId: values.CategoryName,
                        Status: values.Status,
                        Type: values.Type
                    }
                    props.setSearchFilter(convertData)
                    resetFields()
                    if (props.setPage) {
                        props.setPage(1)
                    }
                }
            }
        })
    }

    const handleCancel = () => {
        // resetFields()
        props.setOpenFilter(false)
    }

    const catalogsOption = () => {
        if (props.selfServiceCatalogs) {
            return props.selfServiceCatalogs.content.map((catalog) => {
                return (<Option key={catalog.id} value={catalog.id?.toString()}>{catalog.name}</Option>)
            })
        }
    }

    const categoryOption = () => {
        // const CatalogNameValue = getFieldValue('CatalogName')
        // if (props.selfServiceCatagories) {
        //     return props.selfServiceCatagories.content.map((Catagories) => {
        //         if (CatalogNameValue) {
        //             if (Number(Catagories.catalog.id) === Number(CatalogNameValue)) {
        //                 return (<Option key={Catagories.id} value={Catagories.id}>{Catagories.name}</Option>)
        //             } else {
        //                 return (null)
        //             }
        //         } else {
        //             return (<Option key={Catagories.id} value={Catagories.id?.toString()}>{Catagories.name}</Option>)
        //         }
        //     })
        // }
        if (props.selfServiceCatagories) {
            return props.selfServiceCatagories.content.map((Catagories) => {
                return (<Option key={Catagories.id} value={Catagories.id?.toString()}>{Catagories.name}</Option>)
            })
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit} >
                <Form.Item label={'Catalog Name'}>
                    {getFieldDecorator('CatalogName')(
                        <Select
                            placeholder="Select Catalog Name"
                            mode={'tags'}
                            allowClear
                        >
                            {catalogsOption()}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Category Name'}>
                    {getFieldDecorator('CategoryName')(
                        <Select
                            placeholder="Select Category Name"
                            mode={'tags'}
                            allowClear
                        >
                            {categoryOption()}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Status'}>
                    {getFieldDecorator('Status')(
                        <Select
                            placeholder="Select Status"
                            mode={'tags'}
                            allowClear
                        >
                            <Option value="Published">Published</Option>
                            <Option value="Draft">Draft</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Type'}>
                    {getFieldDecorator('Type')(
                        <Select
                            placeholder="Select Type"
                            mode={'tags'}
                            allowClear
                        >
                            {props.generalIncidentSetting.ticketTypes?.length === 0 ? null : (props.generalIncidentSetting.ticketTypes?.map((item, index: number) => {
                                return <Option value={item.value}
                                    key={index}>{item.value}</Option>
                            }))}
                        </Select>
                    )}
                </Form.Item>
                <Col span={24} style={{ marginTop: 10 }}>
                    <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                    <Button style={{ float: 'right' }} onClick={() => handleCancel()}>Cancel</Button>
                </Col>
            </Form>
        </>
    )
}
const MyFilterItemCatalog = Form.create<Props>({ name: 'FilterItemCatalog' })(FilterItemCatalog)
export default connect(mapStateToProps,
    { getallCatalog, getallCategory, getAllGeneralIncidentSetting }
)(MyFilterItemCatalog)
