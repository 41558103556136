/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { StoreState } from '../../store'

import {
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Icon,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Tabs,
    Tooltip
} from 'antd'
import { FormComponentProps } from 'antd/lib/form'

import { PermissionsAndScope, RoleAndPermissions } from './model'
import {
    createRoleAndPermissions,
    getAllRoleAndPermissions,
    getAllRoleAndPermissionsOptions,
    getRoleAndPermissionsById,
    updateRoleAndPermissions
} from './service'
import { getAllSupportTeamWithDeletionGroup } from '../../incident-management/support-team/service'
import { Link, Redirect, useParams } from 'react-router-dom'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { checkRolesState, permissionMapping, Permissions, RoleType } from '../permissions'
import { tokenDecode } from '../../common-misc'

const mapStateToProps = (state: StoreState) => {
    return {
        roleAndPermissionsOptions: state.roleAndPermissionsOptions,
        roleAndPermissions: state.roleAndPermissions,
        supportTeamsWithDeleteGroup: state.supportTeamsWithDeleteGroup
    }
}

interface DispatchProps {
    getAllRoleAndPermissionsOptions: () => Promise<number>
    getRoleAndPermissionsById: (id: string) => Promise<number>
    getAllSupportTeamWithDeletionGroup: () => Promise<number>
    getAllRoleAndPermissions: () => Promise<number>

}

type StateProps = ReturnType<typeof mapStateToProps>

interface OwnProps {
    editData?: any
    getAllRoleAndPermissionsOptions: () => void
}

type Props = StateProps & OwnProps & FormComponentProps & DispatchProps

interface TabsContent {
    title: string
    content: JSX.Element[]
    key: string
}

const { confirm } = Modal
const { TabPane } = Tabs
const { Option } = Select

const RoleAndPermissionsFrom: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue, resetFields, validateFields, setFields } = props.form

    const { id }: any = useParams()

    const [roleAndPermissions, setRoleAndPermissions] = useState<RoleAndPermissions>()
    const [selectedGroup] = useState<string[]>([])
    const [selectedGroupName, setSelectedGroupName] = useState<string[]>([])
    const [selectGroupWorkOrder] = useState<string[]>([])
    const [selectedGroupNameWo, setSelectedGroupNameWo] = useState<string[]>([])
    const [preview, setPreview] = useState<boolean>(false)
    const isEdit: boolean = window.location.pathname.includes('RoleAndPermissionsSettingEditForm')
    const [isRedirect, setIsRedirect] = useState(false)
    const [defaultRoleAndPermissionName, setDefaultRoleAndPermissionName] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isPermissionLoading, setIsPermissionLoading] = useState<boolean>(false)
    const [isCheckViewTicket, setIsCheckViewTicket] = useState<boolean>(false)
    const [isCheckWoAccess, setIsCheckWoAccess] = useState<boolean>(false)
    const localRoles = tokenDecode()?.permission
    const rolesMapping = permissionMapping(JSON.stringify(localRoles))
    let roleState = Permissions.getInstance().roleState
    if (rolesMapping) {
        roleState = rolesMapping
    }

    useEffect(() => {
        const pathname = window.location.pathname
        props.getAllRoleAndPermissions().catch((err) => message.error(`Failed getting all role and permissions. ${err}`))
        if (pathname.includes('/RoleAndPermissionsSettingPreview')) {
            setPreview(true)
        }
        if (id) {
            initRoleAndPermissionsOptions()
            initRoleAndPermissionsById(id)
        } else {
            cleanData()
            initRoleAndPermissionsOptions()
        }
        props.getAllSupportTeamWithDeletionGroup()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const groups: string[] = []
        if (props.supportTeamsWithDeleteGroup.length !== 0 && props.supportTeamsWithDeleteGroup) {
            props.supportTeamsWithDeleteGroup.forEach((supportTeam) => {
                selectedGroup.forEach((group) => {
                    if (group === supportTeam.id?.toString()) {
                        groups.push(supportTeam.name)
                    }
                })
            })
        }
        setSelectedGroupName(groups || selectedGroup)
        console.log(selectedGroupName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.supportTeamsWithDeleteGroup, selectedGroup])

    useEffect(() => {
        const groupsWO: string[] = []
        if (props.supportTeamsWithDeleteGroup.length !== 0 && props.supportTeamsWithDeleteGroup) {
            props.supportTeamsWithDeleteGroup.forEach((supportTeam) => {
                selectGroupWorkOrder.forEach((group) => {
                    if (group === supportTeam.id?.toString()) {
                        groupsWO.push(supportTeam.name)
                    }
                })
            })
        }
        setSelectedGroupNameWo(groupsWO || selectGroupWorkOrder)
        console.log(selectedGroupNameWo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.supportTeamsWithDeleteGroup, selectGroupWorkOrder])

    useEffect(() => {
        if (roleAndPermissions) {
            setDefaultRoleAndPermissionName(roleAndPermissions.name!!)
            setFieldsValue({ RoleName: roleAndPermissions.name })
            setFieldsValue({ Description: roleAndPermissions.description })
            setFieldsValue({ Scope: roleAndPermissions.incident?.scope })
            setFieldsValue({ ScopeWorkOrder: roleAndPermissions.workOrder?.scope })
            // const groups = roleAndPermissions.selectedGroup?.split(',')
            // const groupsWorkOrder = roleAndPermissions.selectedGroupWorkOrder?.split(',')
            setFieldsValue({ supportTeam: roleAndPermissions.selectedGroupName?.split(',')})
            setFieldsValue({ supportTeamWorkOrder: roleAndPermissions.selectedGroupWorkOrderName?.split(',')})
            setFieldsValue({ supportTeamProblem: roleAndPermissions.selectedGroupProblemName?.split(',')})
            // if (groups) {
            //     // setSelectedGroup(groups || [groups])
            // }
            // if (groupsWorkOrder) {
            //     // setSelectGroupWorkOrder(groupsWorkOrder || [groupsWorkOrder])
            // }
            console.log(roleAndPermissions)
            // Set Incident
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'CreateIncident')) {
                setFieldsValue({ CreateIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'UpdateIncident')) {
                setFieldsValue({ UpdateIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'DeleteIncident')) {
                setFieldsValue({ DeleteIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'ViewIncident')) {
                setIsCheckViewTicket(true)
                setFieldsValue({ ViewIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'CloseIncident')) {
                setFieldsValue({ CloseIncident: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'SatisfactionTicket')) {
                setFieldsValue({ SatisfactionTicket: true })
            }
            if (roleAndPermissions.incident?.permissions?.find(rp => rp === 'ViewReportTicket')) {
                setFieldsValue({ ViewReportTicket: true })
            }
            if (roleAndPermissions?.incident?.scope) {
                setFieldsValue({ Scope: roleAndPermissions?.incident?.scope.toString() || '0' })
            }

            // set admin
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManageRoleAndPermission')) {
                setFieldsValue({ ManageRoleAndPermission: true })
            }
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManagePeopleUserGroup')) {
                setFieldsValue({ ManagePeopleUserGroup: true })
            }
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManageGeneralSetting')) {
                setFieldsValue({ ManageGeneralSetting: true })
            }
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManageTicketSetting')) {
                setFieldsValue({ ManageTicketSetting: true })
            }
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManageSLASetting')) {
                setFieldsValue({ ManageSLASetting: true })
            }
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManageWorkflow')) {
                setFieldsValue({ ManageWorkflow: true })
            }
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManageProblemSetting')) {
                setFieldsValue({ ManageProblemSetting: true })
            }
            if (roleAndPermissions.admin?.permissions?.find(rp => rp === 'ManageAssetSetting')) {
                setFieldsValue({ ManageAssetSetting: true })
            }

            // Set Security
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageTicketAccess')) {
                setFieldsValue({ ManageTicketAccess: true })
            }
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageKnowledgeAccess')) {
                setFieldsValue({ ManageKnowledgeAccess: true })
            }
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageAssetAccess')) {
                setFieldsValue({ ManageAssetAccess: true })
            }
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManagePMAccess')) {
                setFieldsValue({ ManagePMAccess: true })
            }
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageServiceCatalogAccess')) {
                setFieldsValue({ ManageServiceCatalogAccess: true })
            }
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageWorkOrderAccess')) {
                setFieldsValue({ ManageWorkOrderAccess: true })
            }
            if (roleAndPermissions.security?.permissions?.find(rp => rp === 'ManageProblemAccess')) {
                setFieldsValue({ ManageProblemAccess: true })
            }

            // Set Knowledge
            // if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'CreateKnowledge')) {
            //     setFieldsValue({ CreateKnowledge: true })
            // }
            // if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'UpdateKnowledge')) {
            //     setFieldsValue({ UpdateKnowledge: true })
            // }
            // if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'DeleteKnowledge')) {
            //     setFieldsValue({ DeleteKnowledge: true })
            // }
            // if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'ViewKnowledge')) {
            //     setFieldsValue({ ViewKnowledge: true })
            // }
            if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'ManageCategoryFolderArticle')) {
                setFieldsValue({ ManageCategoryFolderArticle: true })
            }
            if (roleAndPermissions.knowledge?.permissions?.find(rp => rp === 'ApproveOrRejectKnowledge')) {
                setFieldsValue({ ApproveOrRejectKnowledge: true })
            }

            // Set Asset
            if (roleAndPermissions.asset?.permissions?.find(rp => rp === 'CreateAsset')) {
                setFieldsValue({ CreateAsset: true })
            }
            if (roleAndPermissions.asset?.permissions?.find(rp => rp === 'UpdateAsset')) {
                setFieldsValue({ UpdateAsset: true })
            }
            if (roleAndPermissions.asset?.permissions?.find((rp) => rp === 'DeleteAsset')) {
                setFieldsValue({ DeleteAsset: true })
            }
            if (roleAndPermissions.asset?.permissions?.find((rp) => rp === 'ViewAsset')) {
                setFieldsValue({ ViewAsset: true })
            }
            if (roleAndPermissions.asset?.permissions?.find((rp) => rp === 'ViewAssetReport')) {
                setFieldsValue({ ViewAssetReport: true })
            }

            // set PM
            if (roleAndPermissions.pm?.permissions?.find(rp => rp === 'CreateUpdateDeletePMPlan')) {
                setFieldsValue({ CreateUpdateDeletePMPlan: true })
            }
            if (roleAndPermissions.pm?.permissions?.find(rp => rp === 'PMList')) {
                setFieldsValue({ PMList: true })
            }
            if (roleAndPermissions.pm?.permissions?.find(rp => rp === 'AddNewAsset')) {
                setFieldsValue({ AddNewAsset: true })
            }
            if (roleAndPermissions.pm?.permissions?.find(rp => rp === 'CompletedPM')) {
                setFieldsValue({ CompletedPM: true })
            }
            if (roleAndPermissions.pm?.permissions?.find(rp => rp === 'ReportPM')) {
                setFieldsValue({ ReportPM: true })
            }
            if (roleAndPermissions.pm?.permissions?.find(rp => rp === 'ApprovePM')) {
                setFieldsValue({ ApprovePM: true })
            }

            // set servaiceCatalog
            if (roleAndPermissions.serviceCatalog?.permissions?.find(rp => rp === 'ManageCatalogCategoryItemCatalog')) {
                setFieldsValue({ ManageCatalogCategoryItemCatalog: true })
            }
            // if (roleAndPermissions.serviceCatalog?.permissions?.find(rp => rp === 'ManageWorkflow')) {
            //     setFieldsValue({ ManageWorkflow: true })
            // }
            // if (roleAndPermissions.serviceCatalog?.permissions?.find(rp => rp === 'ApproveOrRejectWorkorder')) {
            //     setFieldsValue({ ApproveOrRejectWorkorder: true })
            // }
            // if (roleAndPermissions.serviceCatalog?.permissions?.find(rp => rp === 'ApproveOrRejectJob')) {
            //     setFieldsValue({ ApproveOrRejectJob: true })
            // }

            // set WorkOrder
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'CreateWorkOrder')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ CreateWorkOrder: true})
            }
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'UpdateWorkOrder')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ UpdateWorkOrder: true})
            }
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'UpdateJob')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ UpdateJob: true})
            }
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'CloseWorkOrder')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ CloseWorkOrder: true})
            }
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'ApproveOrRejectWorkOrder')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ ApproveOrRejectWorkOrder: true})
            }
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'ApproveOrRejectJob')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ ApproveOrRejectJob: true})
            }
            if (roleAndPermissions?.workOrder?.scope) {
                setFieldsValue({ ScopeWorkOrder: roleAndPermissions?.workOrder?.scope.toString() || '0' })
            }
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'ViewWorkOrder')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ ViewWorkOrder: true})
            }
            if (roleAndPermissions.workOrder?.permissions?.find(rp => rp === 'ViewReportWorkOrder')) {
                setIsCheckWoAccess(true)
                setFieldsValue({ ViewReportWorkOrder: true})
            }

            // set Problem
            if (roleAndPermissions.problem?.permissions?.find(rp => rp === 'CreateProblem')) {
                setFieldsValue({ CreateProblem: true})
            }
            if (roleAndPermissions.problem?.permissions?.find(rp => rp === 'UpdateProblem')) {
                setFieldsValue({ UpdateProblem: true})
            }
            if (roleAndPermissions.problem?.permissions?.find(rp => rp === 'ViewProblem')) {
                setFieldsValue({ ViewProblem: true})
            }
            if (roleAndPermissions.problem?.permissions?.find(rp => rp === 'CloseProblem')) {
                setFieldsValue({ CloseProblem: true})
            }
            if (roleAndPermissions.problem?.permissions?.find(rp => rp === 'CreateProblemTask')) {
                setFieldsValue({ CreateProblemTask: true})
            }
            if (roleAndPermissions.problem?.permissions?.find(rp => rp === 'UpdateProblemTask')) {
                setFieldsValue({ UpdateProblemTask: true})
            }
            if (roleAndPermissions.problem?.permissions?.find(rp => rp === 'ApprovalProblem')) {
                setFieldsValue({ ApprovalProblem: true})
            }
            if (roleAndPermissions?.problem?.scope) {
                setFieldsValue({ ScopeProblem: roleAndPermissions?.problem?.scope.toString() || '0' })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleAndPermissions])

    useEffect(() => {
        if (getFieldValue('ViewIncident')) {
            setIsCheckViewTicket(true)
        } else {
            setIsCheckViewTicket(false)
        }
        if (roleAndPermissions?.incident?.scope) {
            setFieldsValue({ Scope: roleAndPermissions?.incident?.scope.toString() || '0' })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('ViewIncident')])

    useEffect(() => {
        const createFields = getFieldValue('CreateWorkOrder')
        const updateFields = getFieldValue('UpdateWorkOrder')
        const updateJob = getFieldValue('UpdateJob')
        const closeFields = getFieldValue('CloseWorkOrder')
        const ViewWorkOrder = getFieldValue('ViewWorkOrder')
        const approveWorkOrder = getFieldValue('ApproveOrRejectWorkOrder')
        const approveJob = getFieldValue('ApproveOrRejectJob')
        if (!createFields && !updateFields && !updateJob && !closeFields && !approveWorkOrder && !approveJob && !ViewWorkOrder) {
            setIsCheckWoAccess(false)
        } else {
            setIsCheckWoAccess(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getFieldValue('CreateWorkOrder'), getFieldValue('UpdateWorkOrder'), getFieldValue('UpdateJob'), getFieldValue('ViewWorkOrder'), getFieldValue('CloseWorkOrder'), getFieldValue('ApproveOrRejectWorkOrder'), getFieldValue('ApproveOrRejectJob')])

    const initRoleAndPermissionsById = (id: string) => {
        setIsPermissionLoading(true)
        getRoleAndPermissionsById(Number(id)).then((role) => {
            setRoleAndPermissions(role)
            setIsPermissionLoading(false)
        })
    }

    const initRoleAndPermissionsOptions = () => {
        props.getAllRoleAndPermissionsOptions()
    }

    const cleanData = () => {
        resetFields()
    }

    const submitFrom = () => {
        validateFields(
            (error, value) => {
                if (error) {
                    console.log(error)
                    return
                }
                setIsLoading(true)
                // console.log(value)
                const admin: PermissionsAndScope = {
                    permissions: [],
                    scope: '0'
                }
                const security: PermissionsAndScope = {
                    permissions: [],
                    scope: '0'
                }
                const incident: PermissionsAndScope = {
                    permissions: [],
                    scope: getFieldValue('Scope')
                }
                const knowledge: PermissionsAndScope = {
                    permissions: [],
                    scope: '0'
                }
                const asset: PermissionsAndScope = {
                    permissions: [],
                    scope: '0'
                }

                const PM: PermissionsAndScope = {
                    permissions: [],
                    scope: '0'
                }

                const serviceCatolog: PermissionsAndScope = {
                    permissions: [],
                    scope: getFieldValue('ServiceCatalogScope')
                }

                const workOrder: PermissionsAndScope = {
                    permissions: [],
                    scope: getFieldValue('ScopeWorkOrder')
                }

                const problem: PermissionsAndScope = {
                    permissions: [],
                    scope: getFieldValue('ScopeProblem')
                }

                // Admin value
                if (value.ManageRoleAndPermission === true) {
                    admin.permissions?.push('01')
                }
                if (value.ManagePeopleUserGroup === true) {
                    admin.permissions?.push('02')
                }
                if (value.ManageGeneralSetting === true) {
                    admin.permissions?.push('03')
                }
                if (value.ManageTicketSetting === true) {
                    admin.permissions?.push('04')
                }
                if (value.ManageSLASetting === true) {
                    admin.permissions?.push('05')
                }
                if (value.ManageWorkflow === true) {
                    admin.permissions?.push('06')
                }
                if (value.ManageProblemSetting === true) {
                    admin.permissions?.push('07')
                }
                if (value.ManageAssetSetting === true) {
                    admin.permissions?.push('08')
                }

                // Security value
                // if (value.ManageRoleAndPermission === true) {
                //     security.permissions?.push('11')
                // }
                // if (value.ManagePeopleUserGroup === true) {
                //     security.permissions?.push('12')
                // }
                // if (value.ManageGeneralSetting === true) {
                //     security.permissions?.push('13')
                // }
                // if (value.ManageTicketSetting === true) {
                //     security.permissions?.push('14')
                // }
                // if (value.ManageSLASetting === true) {
                //     security.permissions?.push('15')
                // }

                if (value.ManageTicketAccess === true) {
                    security.permissions?.push('11')
                }
                if (value.ManageKnowledgeAccess === true) {
                    security.permissions?.push('12')
                }
                if (value.ManageAssetAccess === true) {
                    security.permissions?.push('13')
                }
                if (value.ManagePMAccess === true) {
                    security.permissions?.push('14')
                }
                if (value.ManageServiceCatalogAccess === true) {
                    security.permissions?.push('15')
                }

                if (value.ManageWorkOrderAccess === true) {
                    security.permissions?.push('16')
                }
                if (value.ManageProblemAccess === true) {
                    security.permissions?.push('17')
                }

                // Incident value
                if (value.CreateIncident) {
                    incident.permissions?.push('21')
                }
                if (value.UpdateIncident) {
                    incident.permissions?.push('22')
                }
                if (value.DeleteIncident) {
                    incident.permissions?.push('23')
                }
                if (value.ViewIncident === true) {
                    incident.permissions?.push('24')
                }
                if (value.CloseIncident) {
                    incident.permissions?.push('25')
                }
                if (value.SatisfactionTicket) {
                    incident.permissions?.push('26')
                }
                if (value.ViewReportTicket) {
                    incident.permissions?.push('27')
                }

                // Knowledge value
                // if (value.CreateKnowledge === true) {
                //     knowledge.permissions?.push('31')
                // }
                // if (value.UpdateKnowledge) {
                //     knowledge.permissions?.push('32')
                // }
                // if (value.DeleteKnowledge) {
                //     knowledge.permissions?.push('33')
                // }
                // if (value.ViewKnowledge === true) {
                //     knowledge.permissions?.push('34')
                // }
                if (value.ManageCategoryFolderArticle === true) {
                    knowledge.permissions?.push('31')
                }
                if (value.ApproveOrRejectKnowledge === true) {
                    knowledge.permissions?.push('32')
                }

                // asset value
                if (value.CreateAsset === true) {
                    asset.permissions?.push('41')
                }
                if (value.UpdateAsset === true) {
                    asset.permissions?.push('42')
                }
                if (value.ViewAsset === true) {
                    asset.permissions?.push('44')
                }
                if (value.DeleteAsset === true) {
                    asset.permissions?.push('43')
                }
                if (value.ViewAssetReport === true) {
                    asset.permissions?.push('45')
                }

                // PM value
                if (value.CreateUpdateDeletePMPlan === true) {
                    PM.permissions?.push('51')
                }
                if (value.PMList === true) {
                    PM.permissions?.push('52')
                }
                if (value.AddNewAsset === true) {
                    PM.permissions?.push('53')
                }
                if (value.CompletedPM === true) {
                    PM.permissions?.push('54')
                }
                if (value.ReportPM === true) {
                    PM.permissions?.push('55')
                }
                if (value.ApprovePM === true) {
                    PM.permissions?.push('56')
                }

                // service catalog
                if (value.ManageCatalogCategoryItemCatalog === true) {
                    serviceCatolog.permissions?.push('61')
                }
                // if (value.ManageWorkflow === true) {
                //     serviceCatolog.permissions?.push('62')
                // }
                // if (value.ApproveOrRejectWorkorder === true) {
                //     serviceCatolog.permissions?.push('63')
                // }
                // if (value.ApproveOrRejectJob === true) {
                //     serviceCatolog.permissions?.push('64')
                // }

                // workOrder
                if (value.CreateWorkOrder === true) {
                    workOrder.permissions?.push('71')
                }
                if (value.UpdateWorkOrder === true) {
                    workOrder.permissions?.push('72')
                }
                if (value.UpdateJob === true) {
                    workOrder.permissions?.push('73')
                }
                if (value.CloseWorkOrder === true) {
                    workOrder.permissions?.push('74')
                }
                if (value.ApproveOrRejectWorkOrder === true) {
                    workOrder.permissions?.push('75')
                }
                if (value.ApproveOrRejectJob === true) {
                    workOrder.permissions?.push('76')
                }
                if (value.ViewWorkOrder === true) {
                    workOrder.permissions?.push('77')
                }
                if (value.ViewReportWorkOrder === true) {
                    workOrder.permissions?.push('78')
                }

                // problem
                if (value.CreateProblem === true) {
                    problem.permissions?.push('81')
                }
                if (value.UpdateProblem === true) {
                    problem.permissions?.push('82')
                }
                if (value.ViewProblem === true) {
                    problem.permissions?.push('83')
                }
                if (value.CloseProblem === true) {
                    problem.permissions?.push('84')
                }
                if (value.CreateProblemTask === true) {
                    problem.permissions?.push('85')
                }
                if (value.UpdateProblemTask === true) {
                    problem.permissions?.push('86')
                }
                if (value.ApprovalProblem === true) {
                    problem.permissions?.push('87')
                }

                const groups: string[] = []
                if (value.supportTeam) {
                    props.supportTeamsWithDeleteGroup.forEach((supportTeam) => {
                        value.supportTeam.forEach((group) => {
                            if (group === supportTeam.name?.toString()) {
                                groups.push(supportTeam.id!!.toString())
                            }
                        })
                    })
                }

                const groupsWorkOrder: string[] = []
                if (value.supportTeamWorkOrder) {
                    props.supportTeamsWithDeleteGroup.forEach((supportTeam) => {
                        value.supportTeamWorkOrder.forEach((group) => {
                            if (group === supportTeam.name?.toString()) {
                                groupsWorkOrder.push(supportTeam.id!!.toString())
                            }
                        })
                    })
                }

                const groupProblem: string[] = []
                if (value.supportTeamProblem) {
                    props.supportTeamsWithDeleteGroup.forEach((supportTeam) => {
                        value.supportTeamProblem.forEach((group) => {
                            if (group === supportTeam.name?.toString()) {
                                groupProblem.push(supportTeam.id!!.toString())
                            }
                        })
                    })
                }

                const roleAndPermissions: RoleAndPermissions = {
                    name: getFieldValue('RoleName')?.replace(/ +/g, ' ').trim(),
                    description: getFieldValue('Description')?.replace(/ +/g, ' ').trim(),
                    security: security,
                    incident: incident,
                    knowledge: knowledge,
                    asset: asset,
                    pm: PM,
                    admin: admin,
                    problem: problem,
                    selectedGroup: groups?.join(','),
                    serviceCatalog: serviceCatolog,
                    workOrder: workOrder,
                    selectedGroupWorkOrder: groupsWorkOrder.join(','),
                    selectedGroupProblem: groupProblem.join(',')
                }
                console.log(roleAndPermissions)
                if (id) {
                    setDefaultRoleAndPermissionName(getFieldValue('RoleName'))
                    updateRoleAndPermissions(roleAndPermissions, id).then(() => {
                        // console.log('update', response)
                        props.getAllRoleAndPermissionsOptions()
                        message.success('The Update has finished successfully.')
                        setIsRedirect(true)
                        setIsLoading(false)
                    }).catch((error) => {
                        console.log(error)
                        setIsLoading(false)
                        if (error.status === 409) {
                            setFields({ RoleName: { errors: [new Error(error.data.message)] } })
                        }
                        message.error(error.data.message)
                    })
                } else {
                    createRoleAndPermissions(roleAndPermissions).then(() => {
                        // console.log('Created', response)
                        props.getAllRoleAndPermissionsOptions()
                        message.success('You have Successfully save the data.')
                        setIsRedirect(true)
                        setIsLoading(false)
                    }).catch((error) => {
                        console.log(error)
                        setIsLoading(false)
                        if (error.status === 409) {
                            setFields({ RoleName: { errors: [new Error(error.data.message)] } })
                        }
                        message.error(error.data.message)
                    })
                }
            }
        )
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page?',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const disableTab = (data: string, key?: any) => {
        if (key === 'Incident') {
            if (!checkRolesState(RoleType.Security, 'ManageTicketAccess')) {
                return true
            } else if (data === 'DeleteIncident') {
                return true
            } else {
                return !checkRolesState(RoleType.Incident, data)
            }
        }
        if (key === 'Knowledge') {
            if (!checkRolesState(RoleType.Security, 'ManageKnowledgeAccess')) {
                return true
            } else {
                return !checkRolesState(RoleType.Knowledge, data)
            }
        }
        if (key === 'Security') {
            if (!checkRolesState(RoleType.Admin, 'ManageRoleAndPermission')) {
                return true
            } else {
                return false
            }
        }
        if (key === 'Asset') {
            if (!checkRolesState(RoleType.Security, 'ManageAssetAccess')) {
                return true
            } else {
                return !checkRolesState(RoleType.Asset, data)
            }
        }
        if (key === 'PM') {
            if (!checkRolesState(RoleType.Security, 'ManagePMAccess')) {
                return true
            } else {
                return false
            }
        }

        if (key === 'ServiceCatalog') {
            if (!checkRolesState(RoleType.Security, 'ManageServiceCatalogAccess')) {
                return true
            } else {
                return false
            }
        }

        if (key === 'WorkOrder') {
            if (!checkRolesState(RoleType.Security, 'ManageWorkOrderAccess')) {
                return true
            } else {
                return false
            }
        }
    }

    const contentOfTab = (contentData: string[], roleType?: any) => {
        return contentData.map((data) => {
            return (
                <Row style={{ marginLeft: 50, marginTop: 14 }} key={data}>
                    <Form.Item>{getFieldDecorator(data, {
                        valuePropName: 'checked'
                    })(
                        <Checkbox key={data} disabled={preview || disableTab(data, roleType)} onChange={(e) => onChangeCheckBox(e)}>{convertCamelCaseToSentenceCase(data)}</Checkbox>
                        // <Checkbox key={data} disabled={preview} onChange={(e) => onChangeCheckBox(e)}>{convertCamelCaseToSentenceCase(data)}</Checkbox>
                    )}</Form.Item>
                </Row>
            )
        })
    }

    const tabs = () => {
        const tabsContent: (TabsContent | undefined)[] = []
        if (props.roleAndPermissionsOptions) {
            for (const key in props.roleAndPermissionsOptions) {
                if (props.roleAndPermissionsOptions.hasOwnProperty(key)) {
                    const element = props.roleAndPermissionsOptions[key]
                    const content: TabsContent = {
                        title: key,
                        content: contentOfTab(element, key),
                        key: key
                    }
                    tabsContent.push(content)
                }
            }
        }
        return tabsContent || []
    }

    const convertCamelCaseToSentenceCase = (fieldValue: string) => {
        if (fieldValue) {
            if (fieldValue === 'ManagePeopleUserGroup') return 'Manage User and Group'
            if (fieldValue === 'ManageSLASetting') return 'Manage SLA Setting'
            if (fieldValue === 'ManagePMAccess') return 'Manage PM Access'
            if (fieldValue === 'ManageWorkOrderAccess') return 'Manage Work Order Access'
            if (fieldValue === 'Incident') return 'Ticket Access'
            if (fieldValue === 'Knowledge') return 'Knowledge Access'
            if (fieldValue === 'ManageCategoryFolderArticle') return 'Manage Category /Folder/ Article'
            if (fieldValue === 'Asset') return 'Asset Access'
            if (fieldValue === 'WorkOrder') return 'Work Order Access'
            // if (fieldValue === 'CreateUpdateAsset') return 'Create/Update Asset'
            if (fieldValue === 'PM') return 'PM Access'
            if (fieldValue === 'PMList') return 'PM List'
            if (fieldValue === 'CreateUpdateDeletePMPlan') return 'Create/Update/Delete PM Plan'
            if (fieldValue === 'CompletedPM') return 'Completed PM'
            if (fieldValue === 'ReportPM') return 'Report PM'
            if (fieldValue === 'ServiceCatalog') return 'Service Catalog Access'
            if (fieldValue === 'ManageCatalogCategoryItemCatalog') return 'Manage Catalog /Category /Item Catalog'
            if (fieldValue === 'ManageWorkflow') return 'Manage Workflow'
            if (fieldValue === 'ApproveOrRejectWorkOrder') return 'Approve or Reject Work Order'
            if (fieldValue === 'ApproveOrRejectJob') return 'Approve or Reject Job'
            if (fieldValue === 'CreateIncident') return 'Create Ticket'
            if (fieldValue === 'DeleteIncident') return 'Delete Ticket'
            if (fieldValue === 'UpdateIncident') return 'Update Ticket'
            if (fieldValue === 'CloseIncident') return 'Close Ticket'
            if (fieldValue === 'SatisfactionTicket') return 'Satisfaction Ticket'
            if (fieldValue === 'ViewIncident') return 'View Ticket'
            if (fieldValue === 'ManageTicketSetting') return 'Manage Ticket Setting'
            if (fieldValue === 'ManageTicketAccess') return 'Manage Ticket Access'
            if (fieldValue === 'ApprovePM') return 'Approve PM'
            if (fieldValue === 'CreateWorkOrder') return 'Create Work Order'
            if (fieldValue === 'UpdateWorkOrder') return 'Update Work Order'
            if (fieldValue === 'UpdateJob') return 'Update Job'
            if (fieldValue === 'CloseWorkOrder') return 'Close Work Order'
            if (fieldValue === 'ViewReportTicket') return 'View Report Ticket'
            // if (fieldValue === 'ApproveOrRejectWorkOrderWo') return 'Approve or Reject Work Order'
            // if (fieldValue === 'ApproveOrRejectJobWo') return 'Approve or Reject Job'
            // adding space between strings
            else {
                const result = fieldValue.replace(/([A-Z])/g, ' $1')

                // converting first character to uppercase and join it to the final string
                return result.charAt(0).toUpperCase() + result.slice(1).replace('Or',
                    'or').replace('Sla', 'SLA')
            }
        }
    }

    const onChangeCheckBox = (checkedValue: CheckboxChangeEvent) => {
        const obj = {}
        let incident = []
        let knowledge = []
        let problem = []
        for (const key in props.roleAndPermissionsOptions) {
            if (key === 'Incident') incident = props.roleAndPermissionsOptions[key]
            if (key === 'Knowledge') knowledge = props.roleAndPermissionsOptions[key]
            if (key === 'Problem') problem = props.roleAndPermissionsOptions[key]
        }
        const itemsInc = ['CreateIncident', 'DeleteIncident', 'UpdateIncident', 'CloseIncident', 'SatisfactionTicket']
        if ((itemsInc.includes(checkedValue.target.id as string))) {
            setFieldsValue({ ViewIncident: true })
        }
        if (checkedValue.target.id === 'ViewIncident' && !checkedValue.target.checked) {
            incident.forEach((item) => {
                obj[item as string] = false
            })
            setFieldsValue(obj)
        }
        if (checkedValue.target.id === 'CreateUpdateDeletePMPlan') {
            setFieldsValue({ PMList: true })
        }

        if (checkedValue.target.id === 'ViewIncident' || itemsInc.includes(checkedValue.target.id as string)) {
            setIsCheckViewTicket(true)
        }

        if (checkedValue.target.id === 'CloseWorkOrder' && checkedValue.target.checked) {
            setFieldsValue({ UpdateWorkOrder: true })
        } else if (checkedValue.target.id === 'CloseWorkOrder' && !checkedValue.target.checked) {
            setFieldsValue({ UpdateWorkOrder: false })
        } else if (checkedValue.target.id === 'UpdateWorkOrder' && !checkedValue.target.checked) {
            setFieldsValue({ CloseWorkOrder: false })
        }

        if (checkedValue.target.id === 'ViewWorkOrder' && !checkedValue.target.checked) {
            setFieldsValue({ UpdateWorkOrder: false})
            setFieldsValue({ CloseWorkOrder: false})
            setFieldsValue({ UpdateJob: false})
            setFieldsValue({ ApproveOrRejectWorkOrder: false})
            setFieldsValue({ ApproveOrRejectJob: false})
        }

        // const itemsKm = ['ManageCategoryFolderArticle']
        // if ((itemsKm.includes(checkedValue.target.id as string))) {
        //     setFieldsValue({ ViewKnowledge: true })
        // }
        // if (checkedValue.target.id === 'ManageCategoryFolderArticle' && !checkedValue.target.checked) {
        //     knowledge.forEach((item) => {
        //         obj[item as string] = false
        //     })
        //     setFieldsValue(obj)
        // }

        if ((checkedValue.target.id === 'ManageTicketAccess')) {
            setFieldsValue({ CreateIncident: true })
            setFieldsValue({ UpdateIncident: true })
            setFieldsValue({ DeleteIncident: false })
            setFieldsValue({ ViewIncident: true })
            setFieldsValue({ CloseIncident: true })
            setFieldsValue({ SatisfactionTicket: true })
            setFieldsValue({ ViewReportTicket: true })
        }

        if (checkedValue.target.id === 'ManageTicketAccess' && !checkedValue.target.checked) {
            incident.forEach((item) => {
                obj[item as string] = false
            })
            setFieldsValue(obj)
        }

        if ((checkedValue.target.id === 'ManageKnowledgeAccess' && checkedValue.target.checked)) {
            // setFieldsValue({ CreateKnowledge: true })
            // setFieldsValue({ UpdateKnowledge: true })
            // setFieldsValue({ DeleteKnowledge: true })
            // setFieldsValue({ ViewKnowledge: true })
            setFieldsValue({ ManageCategoryFolderArticle: true })
            setFieldsValue({ ApproveOrRejectKnowledge: true })
        }

        if (checkedValue.target.id === 'ManageAssetAccess' && checkedValue.target.checked) {
            setFieldsValue({ CreateAsset: true })
            setFieldsValue({ UpdateAsset: true })
            setFieldsValue({ DeleteAsset: true })
            setFieldsValue({ ViewAsset: true })
            setFieldsValue({ ViewAssetReport: true })
        } else if (checkedValue.target.id === 'ManageAssetAccess' && !checkedValue.target.checked) {
            setFieldsValue({ CreateAsset: false })
            setFieldsValue({ UpdateAsset: false })
            setFieldsValue({ DeleteAsset: false })
            setFieldsValue({ ViewAsset: false })
            setFieldsValue({ ViewAssetReport: false })
        }

        if ((checkedValue.target.id === 'CreateAsset' && checkedValue.target.checked) || (checkedValue.target.id === 'UpdateAsset' && checkedValue.target.checked) || (checkedValue.target.id === 'DeleteAsset' && checkedValue.target.checked)) {
            setFieldsValue({ ViewAsset: true })
        }

        if (checkedValue.target.id === 'ViewAsset' && !checkedValue.target.checked) {
            setFieldsValue({ CreateAsset: false })
            setFieldsValue({ UpdateAsset: false })
            setFieldsValue({ DeleteAsset: false })
        }

        if (checkedValue.target.id === 'ManageWorkOrderAccess' && checkedValue.target.checked) {
            setFieldsValue({ CreateWorkOrder: true})
            setFieldsValue({ UpdateWorkOrder: true})
            setFieldsValue({ UpdateJob: true})
            setFieldsValue({ CloseWorkOrder: true})
            setFieldsValue({ ApproveOrRejectWorkOrder: true})
            setFieldsValue({ ApproveOrRejectJob: true})
            setFieldsValue({ ViewWorkOrder: true})
            setFieldsValue({ ViewReportWorkOrder: false})
        } else if (checkedValue.target.id === 'ManageWorkOrderAccess' && !checkedValue.target.checked) {
            setFieldsValue({ CreateWorkOrder: false})
            setFieldsValue({ UpdateWorkOrder: false})
            setFieldsValue({ UpdateJob: false})
            setFieldsValue({ CloseWorkOrder: false})
            setFieldsValue({ ApproveOrRejectWorkOrder: false})
            setFieldsValue({ ApproveOrRejectJob: false})
            setFieldsValue({ ViewWorkOrder: false})
            setFieldsValue({ ViewReportWorkOrder: false})
        }

        if ((checkedValue.target.id === 'CreateWorkOrder' && checkedValue.target.checked) || (checkedValue.target.id === 'UpdateWorkOrder' && checkedValue.target.checked) || (checkedValue.target.id === 'UpdateJob' && checkedValue.target.checked) || (checkedValue.target.id === 'CloseWorkOrder' && checkedValue.target.checked) || (checkedValue.target.id === 'ApproveOrRejectWorkOrder' && checkedValue.target.checked) || (checkedValue.target.id === 'ApproveOrRejectJob' && checkedValue.target.checked)) {
            setFieldsValue({ ViewWorkOrder: true})
        }

        if ((checkedValue.target.id === 'ViewWorkOrder' && !checkedValue.target.checked)) {
            setFieldsValue({ CreateWorkOrder: false})
        }

        if (checkedValue.target.id === 'PMList' && !checkedValue.target.checked) {
            setFieldsValue({ CreateUpdateDeletePMPlan: false })
            setFieldsValue({ AddNewAsset: false })
            setFieldsValue({ ApprovePM: false })
        } else if (checkedValue.target.id === 'CreateUpdateDeletePMPlan' && !checkedValue.target.checked) {
            setFieldsValue({ PMList: false })
            setFieldsValue({ AddNewAsset: false })
            setFieldsValue({ ApprovePM: false })
        }

        if (checkedValue.target.id === 'ApprovePM' && checkedValue.target.checked) {
            setFieldsValue({ CreateUpdateDeletePMPlan: true })
            setFieldsValue({ PMList: true })
        } else if (checkedValue.target.id === 'ApprovePM' && !checkedValue.target.checked) {
            // setFieldsValue({ CreateUpdateDeletePMPlan: false })
            // setFieldsValue({ PMList: false })
        }

        console.log(roleState)
        if (roleState) {
            // @ts-ignore
            if (JSON.stringify(roleState?.pm?.permissions) !== '[]') {
                if (checkedValue.target.id === 'ManagePMAccess' && checkedValue.target.checked) {
                    setFieldsValue({ CreateUpdateDeletePMPlan: true })
                    setFieldsValue({ PMList: true })
                    setFieldsValue({ AddNewAsset: true })
                    setFieldsValue({ CompletedPM: true })
                    setFieldsValue({ ReportPM: true })
                    setFieldsValue({ ApprovePM: true })
                } else if (checkedValue.target.id === 'ManagePMAccess' && !checkedValue.target.checked) {
                    setFieldsValue({ CreateUpdateDeletePMPlan: false })
                    setFieldsValue({ PMList: false })
                    setFieldsValue({ AddNewAsset: false })
                    setFieldsValue({ CompletedPM: false })
                    setFieldsValue({ ReportPM: false })
                    setFieldsValue({ ApprovePM: false })
                }
            }
        }

        if (checkedValue.target.id === 'ManageKnowledgeAccess' && !checkedValue.target.checked) {
            knowledge.forEach((item) => {
                obj[item as string] = false
            })
            setFieldsValue(obj)
        }

        if (roleState) {
            // @ts-ignore
            // sercat
            if (JSON.stringify(roleState?.serviceCatalog?.permissions) !== '[]') {
                if (checkedValue.target.id === 'ManageServiceCatalogAccess' && checkedValue.target.checked) {
                    setFieldsValue({ ManageCatalogCategoryItemCatalog: true })
                    // setFieldsValue({ ManageWorkflow: true })
                    // setFieldsValue({ ApproveOrRejectWorkorder: true })
                    // setFieldsValue({ ApproveOrRejectJob: true })
                } else if (checkedValue.target.id === 'ManageServiceCatalogAccess' && !checkedValue.target.checked) {
                    setFieldsValue({ ManageCatalogCategoryItemCatalog: false })
                    // setFieldsValue({ ManageWorkflow: false })
                    // setFieldsValue({ ApproveOrRejectWorkorder: false })
                    // setFieldsValue({ ApproveOrRejectJob: false })
                }
            }
        }

        if (checkedValue.target.id === 'AddNewAsset' && checkedValue.target.checked) {
            setFieldsValue({ PMList: true })
        }

        // problem
        if (checkedValue.target.id === 'ManageProblemAccess' && !checkedValue.target.checked) {
            problem.forEach((item) => {
                obj[item as string] = false
            })
            setFieldsValue(obj)
        } else if (checkedValue.target.id === 'ManageProblemAccess' && checkedValue.target.checked) {
            problem.forEach((item) => {
                obj[item as string] = true
            })
            setFieldsValue(obj)
        }
        const itemNotShowWhenViewProblemFalse = ['UpdateProblem', 'CloseProblem', 'CreateProblemTask', 'UpdateProblemTask', 'ApprovalProblem']
        if (checkedValue.target.id === 'ViewProblem' && !checkedValue.target.checked) {
            itemNotShowWhenViewProblemFalse.forEach((item) => {
                obj[item as string] = false
            })
            setFieldsValue(obj)
        }
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (props.form.getFieldValue('RoleName')) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    if (props.roleAndPermissions) {
                        const roleAndPermissionsList = props.roleAndPermissions.map((value) => {
                            return value.name?.toLowerCase()
                        })
                        if (isEdit && value.trim()?.toLowerCase() === defaultRoleAndPermissionName?.toLowerCase()) {
                            callback()
                        } else if (roleAndPermissionsList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                            callback('The Role Name is already exists. It must be unique. Please try another one.')
                        } else {
                            callback()
                        }
                    }

                    // setTimeout(() => {
                    //     CheckDupRoleName(value.replace(/ +/g, ' ').trim()).then((res) => {
                    //         if (isEdit && value.trim()?.toLowerCase() === defaultRoleAndPermissionName?.toLowerCase()) {
                    //             callback()
                    //         } else if (res === 'true') {
                    //             callback('The Role Name is already exists. It must be unique. Please try another one.')
                    //         } else {
                    //             callback()
                    //         }
                    //     })
                    // }, 1000)
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 characters')
                }
            }
        } else {
            callback()
        }
    }

    const validateDescription = (_: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    callback()
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('description cannot be empty Enter more than 3 letters')
                }
            }
        } else {
            callback()
        }
    }

    // const accessForm = (accesstype: string) => {
    //     return <>
    //         <Row style={{ marginTop: 14 }}>
    //             <p style={{ marginLeft: 30 }} className={'sub-title'}>Access</p>
    //             <Form.Item style={{ marginLeft: 50, overflow: 'auto' }}>
    //                 {getFieldDecorator(`${accesstype}Scope`, {
    //                     initialValue: '0'
    //                 })(
    //                     <Radio.Group disabled={preview || !checkRolesState(RoleType.Security, 'ManageTicketAccess')}>
    //                         <Row style={{ marginTop: 14 }}>
    //                             <Radio value="0">Global Access - Can access all Incidents</Radio>
    //                         </Row>
    //                         <Row style={{ marginTop: 14 }}>
    //                             <Radio value="1">Group Access and Selected Group Access -  Can access incidents in their Group(s) and their selected Group(s). Also, Incidents assigned to them.</Radio>
    //                         </Row>
    //                         <Row style={{ marginLeft: 25, marginTop: 10 }}>
    //                             {getFieldValue(`${accesstype}Scope`) === '1'
    //                                 ? <Form.Item label={'Group Access'}>
    //                                     {getFieldDecorator('supportTeam', {
    //                                         rules: [
    //                                             { required: true, message: 'Support Team is required', type: 'array' }
    //                                         ],
    //                                         initialValue: selectedGroupName
    //                                     })(
    //                                         <Select disabled={preview} mode="tags"
    //                                             placeholder="Select a Group Access">
    //                                             {props.supportTeamsWithDeleteGroup.map((supportTeam) => {
    //                                                 return (<Option key={supportTeam.name} value={supportTeam.name}>{supportTeam.name}</Option>)
    //                                             })}
    //                                         </Select>
    //                                     )}
    //                                 </Form.Item>
    //                                 : null}
    //                         </Row>
    //                         <Row style={{ marginTop: 14 }}>
    //                             <Radio value="2">Group Access -  Can access Incidents in their Group(s) and Incidents assigned to them</Radio>
    //                         </Row>
    //                     </Radio.Group>
    //                 )}
    //             </Form.Item>
    //         </Row>
    //     </>
    // }

    const changeTab = (e) => {
        if (e === 'Incident') {
            if (roleAndPermissions?.incident?.scope) {
                setFieldsValue({ Scope: roleAndPermissions?.incident?.scope.toString() || '0' })
            }
        }
        if (e === 'WorkOrder') {
            if (roleAndPermissions?.workOrder?.scope) {
                setFieldsValue({ ScopeWorkOrder: roleAndPermissions.workOrder.scope.toString() || '0'})
            }
        }
        if (e === 'Problem') {
            if (roleAndPermissions?.problem?.scope) {
                setFieldsValue({ ScopeProblem: roleAndPermissions.problem.scope.toString() || '0'})
            }
        }
    }

    return (
        <>
            {isRedirect ? (<Redirect to="/RoleAndPermissionsSettingList" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={'/RoleAndPermissionsSettingList'} >Role and Permission</Link>
                </Breadcrumb.Item>
                {preview ? (
                    <Breadcrumb.Item>View Role and Permission</Breadcrumb.Item>
                ) : (
                    isEdit ? (
                        <Breadcrumb.Item>Edit Role and Permission</Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item>New Role and Permission</Breadcrumb.Item>
                    )
                )}
            </Breadcrumb>
            <br />
            <Card className={'custom-card'}>
                <Form layout="vertical">
                    <Row>
                        <Col span={23}>
                            {preview ? (
                                <h2 className={'main-title'}>View Role and Permission</h2>
                            ) : (
                                isEdit ? (
                                    <h2 className={'main-title'}>Edit Role and Permission</h2>
                                ) : (
                                    <h2 className={'main-title'}>New Role and Permission</h2>
                                )
                            )}
                        </Col>
                        {preview ? (<Col span={1} >
                            <span style={{ float: 'right', marginRight: '8px' }}>
                                <Tooltip placement="bottom" title={'Edit'} ><Link to={`/RoleAndPermissionsSettingEditForm/${id}`} ><Icon className="edit_icon" type="edit" /></Link></Tooltip>
                            </span>
                        </Col>
                        ) : null}
                    </Row>

                    <Row gutter={32}>
                        <Col span={10}>
                            <Form.Item label={'Role Name'}>
                                {getFieldDecorator('RoleName', {
                                    rules: [{ required: true, message: 'Role Name is required' },
                                        { max: 100, message: 'max Input 100 characters' },
                                        { validator: validateNameUniqueness }
                                    ]
                                })(
                                    <Input disabled={preview} placeholder="Role Name" maxLength={100} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <Form.Item label={'Description'}>
                                {getFieldDecorator('Description', {
                                    rules: [{ validator: validateDescription }]
                                })(
                                    <Input disabled={preview} placeholder="Description" maxLength={255} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <h2 className={'sub-title'}>Permission and Access</h2>
                        <Spin spinning={isPermissionLoading}>
                            <Tabs defaultActiveKey="1" onChange={(e) => changeTab(e)}>
                                {tabs().map((pane) => {
                                    if (pane) {
                                        return (
                                            <TabPane tab={convertCamelCaseToSentenceCase(pane?.title)} key={pane.key}>
                                                <h3 style={{ marginLeft: 30 }} className={'sub-title'}>This roles can do</h3>
                                                {pane.content}
                                                {pane.title === 'Incident' && isCheckViewTicket === true
                                                    ? <Row style={{ marginTop: 14 }}>
                                                        <p style={{ marginLeft: 30 }} className={'sub-title'}>Access</p>
                                                        <Form.Item style={{ marginLeft: 50, overflow: 'auto' }}>
                                                            {getFieldDecorator('Scope', {
                                                                initialValue: roleAndPermissions?.incident?.scope || '0'
                                                            })(
                                                                <Radio.Group disabled={preview || !checkRolesState(RoleType.Security, 'ManageTicketAccess')}>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="0">Global Access - Can access all tickets</Radio>
                                                                    </Row>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="1">Group Access and Selected Group Access -  Can access tickets in their Group(s) and their selected Group(s). Also, Tickets assigned to them.</Radio>
                                                                    </Row>
                                                                    <Row style={{ marginLeft: 25, marginTop: 10 }}>
                                                                        {getFieldValue('Scope') === '1'
                                                                            ? <Form.Item label={'Group Access'}>
                                                                                {getFieldDecorator('supportTeam', {
                                                                                    rules: [
                                                                                        { required: true, message: 'Group Access is required', type: 'array' }
                                                                                    ],
                                                                                    initialValue: roleAndPermissions?.selectedGroupName !== '' ? roleAndPermissions?.selectedGroupName?.split(',') : []
                                                                                })(
                                                                                    <Select disabled={preview} mode="multiple"
                                                                                        placeholder="Select a Group Access">
                                                                                        {props.supportTeamsWithDeleteGroup.map((supportTeam) => {
                                                                                            if (supportTeam.activate === true && supportTeam.active === true) {
                                                                                                return (<Option key={supportTeam.name} value={supportTeam.name}>{supportTeam.name}</Option>)
                                                                                            } else {
                                                                                                return null
                                                                                            }
                                                                                        })}
                                                                                    </Select>
                                                                                )}
                                                                            </Form.Item>
                                                                            : null}
                                                                    </Row>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="2">Group Access -  Can access tickets in their Group(s) and tickets assigned to them</Radio>
                                                                    </Row>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="3">Restricted Access -  Can only access tickets assigned to them</Radio>
                                                                    </Row>
                                                                </Radio.Group>
                                                            )}
                                                        </Form.Item>
                                                    </Row> : null
                                                }
                                                {pane.title === 'WorkOrder' && isCheckWoAccess === true
                                                    ? <Row style={{ marginTop: 14 }}>
                                                        <p style={{ marginLeft: 30 }} className={'sub-title'}>Access</p>
                                                        <Form.Item style={{ marginLeft: 50, overflow: 'auto' }}>
                                                            {getFieldDecorator('ScopeWorkOrder', {
                                                                initialValue: roleAndPermissions?.workOrder?.scope || '0'
                                                            })(
                                                                <Radio.Group disabled={preview || !checkRolesState(RoleType.Security, 'ManageWorkOrderAccess')}>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="0">Global Access - Can access all Work Order</Radio>
                                                                    </Row>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="1">Group Access and Selected Group Access -  Can access Work Order in their Group(s) and their selected Group(s). Also, Work Order assigned to them.</Radio>
                                                                    </Row>
                                                                    <Row style={{ marginLeft: 25, marginTop: 10 }}>
                                                                        {getFieldValue('ScopeWorkOrder') === '1'
                                                                            ? <Form.Item label={'Group Access'}>
                                                                                {getFieldDecorator('supportTeamWorkOrder', {
                                                                                    rules: [
                                                                                        { required: true, message: 'Support Team is required', type: 'array' }
                                                                                    ],
                                                                                    initialValue: roleAndPermissions?.selectedGroupWorkOrderName !== '' ? roleAndPermissions?.selectedGroupWorkOrderName?.split(',') : []
                                                                                })(
                                                                                    <Select disabled={preview} mode="tags"
                                                                                        placeholder="Select a Group Access">
                                                                                        {props.supportTeamsWithDeleteGroup.map((supportTeam) => {
                                                                                            if (supportTeam.activate === true && supportTeam.active === true) {
                                                                                                return (<Option key={supportTeam.name} value={supportTeam.name}>{supportTeam.name}</Option>)
                                                                                            } else {
                                                                                                return null
                                                                                            }
                                                                                        })}
                                                                                    </Select>
                                                                                )}
                                                                            </Form.Item>
                                                                            : null}
                                                                    </Row>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="2">Group Access -  Can access Work Order in their Group(s) and Work Order assigned to them</Radio>
                                                                    </Row>
                                                                    <Row style={{ marginTop: 14 }}>
                                                                        <Radio value="3">Restricted Access -  Can only access Work Order assigned to them</Radio>
                                                                    </Row>
                                                                </Radio.Group>
                                                            )}
                                                        </Form.Item>
                                                    </Row> : null }
                                                {/* {pane.title === 'ServiceCatalog' ? accessForm('ServiceCatalog') : null} */}
                                                {pane.title === 'Problem' ? <Row style={{ marginTop: 14 }}>
                                                    <p style={{ marginLeft: 30 }} className={'sub-title'}>Access</p>
                                                    <Form.Item style={{ marginLeft: 50, overflow: 'auto' }}>
                                                        {getFieldDecorator('ScopeProblem', {
                                                            initialValue: roleAndPermissions?.workOrder?.scope || '0'
                                                        })(
                                                            <Radio.Group disabled={preview || !checkRolesState(RoleType.Security, 'ManageWorkOrderAccess')}>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="0">Global Access - Can access all Problem</Radio>
                                                                </Row>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="1">Group Access and Selected Group Access -  Can access Problems in their Group(s) and their selected Group(s). Also, Problem assigned to them.</Radio>
                                                                </Row>
                                                                <Row style={{ marginLeft: 25, marginTop: 10 }}>
                                                                    {getFieldValue('ScopeProblem') === '1'
                                                                        ? <Form.Item label={'Group Access'}>
                                                                            {getFieldDecorator('supportTeamProblem', {
                                                                                rules: [
                                                                                    { required: true, message: 'Support Team is required', type: 'array' }
                                                                                ],
                                                                                initialValue: roleAndPermissions?.selectedGroupProblemName !== '' ? roleAndPermissions?.selectedGroupProblemName?.split(',') : []
                                                                            })(
                                                                                <Select disabled={preview} mode="tags"
                                                                                    placeholder="Select a Group Access">
                                                                                    {props.supportTeamsWithDeleteGroup.map((supportTeam) => {
                                                                                        if (supportTeam.activate === true && supportTeam.active === true) {
                                                                                            return (<Option key={supportTeam.name} value={supportTeam.name}>{supportTeam.name}</Option>)
                                                                                        } else {
                                                                                            return null
                                                                                        }
                                                                                    })}
                                                                                </Select>
                                                                            )}
                                                                        </Form.Item>
                                                                        : null}
                                                                </Row>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="2">Group Access -  Can access Problems in their Group(s) and Problem assigned to them</Radio>
                                                                </Row>
                                                                <Row style={{ marginTop: 14 }}>
                                                                    <Radio value="3">Restricted Access -  Can only access Problems assigned to them</Radio>
                                                                </Row>
                                                            </Radio.Group>
                                                        )}
                                                    </Form.Item>
                                                </Row> : null}
                                            </TabPane>
                                        )
                                    } else { return <></> }
                                })}
                            </Tabs>
                        </Spin>
                    </Row>
                    <Row className={'custom-position-button'}>
                        <Col span={24}>
                            <Button disabled={preview} type="primary"
                                loading={isLoading}
                                htmlType="submit"
                                onClick={submitFrom}
                                style={{ float: 'right', marginRight: 10, marginBottom: '5px' }}>Submit</Button>
                            <Button onClick={leavePageModal} style={{ float: 'right', marginRight: 10 }}>Cancel</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

const MyRoleAndPermissionsFrom = Form.create<Props>()(RoleAndPermissionsFrom)

export default connect(mapStateToProps, { getAllRoleAndPermissionsOptions, getAllSupportTeamWithDeletionGroup, getAllRoleAndPermissions })(MyRoleAndPermissionsFrom)
