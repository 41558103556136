import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '../rest'
import { AnalysisProblems, CurrentViewProblem, LinkOrUnLike, Problem, ProblemRelateByTicket, PTask, Reply, WorkNoteProblems } from './Model'

export const GetAllStatusProblem = async (pbTypeId: string) => {
    try {
        const response = await axiosGet<Problem>(`/api/problem-statuses/${pbTypeId}/lists`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const createNewProblem = async (newItem: Problem) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPost<Problem>('/api/problems', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getAllProblem = async (page: number, size: number, sort: string, order: string, filter?: any) => {
    try {
        const encryptData = encryptBody(JSON.stringify(filter))
        const response = await axiosPost<Problem[]>(`/api/problems/pagination?page=${page - 1}&size=${size}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getCurrentViewProblem = async () => {
    try {
        const response = await axiosGet<Problem>('/api/problem-views/current-view')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const addCurrentViewProblem = async (data: CurrentViewProblem) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<CurrentViewProblem>('/api/problem-views', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const UpdateCurrentViewProblem = async (id: string, data: CurrentViewProblem) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPatch<CurrentViewProblem>('/api/problem-views/' + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const deleteCurrentViewProblem = async (ids: string) => {
    try {
        const response = await axiosDelete<CurrentViewProblem>('/api/problem-views/list?ids=' + ids)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getCurrentViewProblemById = async (id: string) => {
    try {
        const response = await axiosGet<CurrentViewProblem>('/api/problem-filters/' + id)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const GetProblemById = async (pbTypeId: string) => {
    try {
        const response = await axiosGet<Problem>(`/api/problems/${pbTypeId}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateProblem = async (id: string, newItem: Problem) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPatch<Problem>('/api/problems/' + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}
// RelateTicket
export const getRelateTicketAndProblems = async (id: string) => {
    try {
        const response = await axiosGet<any>(`/api/problems/relation/ticket/${id}/list`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateRelateTicketAndProblems = async (id: string, newItem: number[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPost<any>('/api/problems/relation/ticket/' + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const deleteRelateTicketAndProblems = async (id: string) => {
    try {
        const response = await axiosDelete<any>(`/api/relate-tickets/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

// RelateAsset
export const getRelateAssetAndProblems = async (id: string) => {
    try {
        const response = await axiosGet<any>(`/api/problems/relation/asset/${id}/list`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateRelateAssetAndProblems = async (id: string, newItem: number[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPost<any>('/api/problems/relation/asset/' + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const deleteRelateAssetAndProblems = async (id: string) => {
    try {
        const response = await axiosDelete<any>(`/api/relate-assets/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

// RelateKnowledge
export const getRelateKnowledgeAndProblems = async (id: string, isPTask: boolean) => {
    try {
        let prefix = '/api/problems'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const response = await axiosGet<any>(`${prefix}/relation/knowledge/${id}/list`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateRelateKnowledgeAndProblems = async (id: string, newItem: number[], isPTask: boolean) => {
    try {
        let prefix = '/api/problems'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPost<any>(`${prefix}/relation/knowledge/` + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const deleteRelateKnowledgeAndProblems = async (id: string, isPTask: boolean) => {
    try {
        let prefix = '/api'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const response = await axiosDelete<any>(`${prefix}/relate-knowledge/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

// work notes
export const getAllWorknoteByProblemsId = async (id: string, isPTask: boolean) => {
    try {
        let prefix = '/api'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const response = await axiosGet<WorkNoteProblems[]>(`${prefix}/worknotes/${id}/list`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const createWorkNote = async (data: WorkNoteProblems, isPTask: boolean) => {
    try {
        let prefix = '/api'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<WorkNoteProblems>(`${prefix}/worknotes`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateWorkNote = async (id: string, data: WorkNoteProblems, isPTask: boolean) => {
    try {
        let prefix = '/api'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<WorkNoteProblems>(`${prefix}/worknotes` + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateLikeOrUnLikeWorkNote = async (data: LinkOrUnLike, isPTask: boolean) => {
    try {
        let prefix = '/api'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<WorkNoteProblems>(`${prefix}/worknote-likes/setting`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const createWorkNoteReply = async (data: Reply, isPTask: boolean) => {
    try {
        let prefix = '/api'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<Reply>(`${prefix}/worknote-replies`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getMailCount = async (id: string, isPTask: boolean) => {
    try {
        let prefix = '/api'
        if (isPTask) {
            prefix = '/api/tasks'
        }
        const response = await axiosGet<string>(`${prefix}/worknote-mail-counts/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getAllApproveProblem = async (page: number, size: number, sort: string, order: string) => {
    try {
        const response = await axiosGet<Problem[]>(`/api/problems/awaiting-approve/pagination?page=${page - 1}&size=${size}&sort=${sort},${order}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getAllAnalysis = async (id: string, isPTask: boolean) => {
    try {
        let prefix = '/api/problem-analysis'
        if (isPTask) {
            prefix = '/api/tasks/analysis'
        }
        const response = await axiosGet<AnalysisProblems>(`${prefix}/${id}/${isPTask ? 'task' : 'problem'}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateAnalysis = async (id: string, newItem: AnalysisProblems, isPTask: boolean) => {
    try {
        let prefix = '/api/problem-analysis'
        if (isPTask) {
            prefix = '/api/tasks/analysis'
        }
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPatch<Problem>(`${prefix}/` + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

// PTASK
export const CreatePTask = async (newItem: PTask) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPost<PTask>('/api/tasks', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getAllPTask = async (id: string, value?: string, filter?: any) => {
    try {
        const encryptData = encryptBody(JSON.stringify(filter))
        const response = await axiosPost<PTask[]>(`/api/tasks/${id}/list${value ? '?value=' + value : ''}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getAllPTaskById = async (id: number) => {
    try {
        const response = await axiosGet<PTask>(`/api/tasks/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updatePTaskById = async (id: string, newItem: PTask) => {
    try {
        const encryptData = encryptBody(JSON.stringify(newItem))
        const response = await axiosPatch<PTask>('/api/tasks/' + id, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getWorklogProblems = async (id: string) => {
    try {
        const response = await axiosGet<PTask>(`/api/work-logs/${id}/list`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const getRelateProblemByTicketId = async (id: string) => {
    try {
        const response = await axiosGet<ProblemRelateByTicket[]>(`/api/problems/relation/problem/${id}/list`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const searchRelateProblemInTicket = async (value: string, page: number, size: number, sort: string, order: string) => {
    try {
        const response = await axiosGet<ProblemRelateByTicket[]>(`/api/problems/ticket-related-problem/pagination?value=${value}&page=${page - 1}&size=${size}&sort=${sort},${order}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const updateRelateProblemByTicketId = async (id: string, data: number[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost<ProblemRelateByTicket[]>(`/api/problems/relation/problem/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const deleteRelateProblemByTicketId = async (id: string) => {
    try {
        const response = await axiosDelete<ProblemRelateByTicket[]>(`/api/relate-tickets/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err.response.status)
        throw err.response
    }
}

export const searchAllRelateProblemById = async(id:string) => {
    try {
        const response = await axiosGet(`/api/problems/${id}/tag-related`)
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getCheckProblemSetting = async() => {
    try {
        const response = await axiosGet('/api/problems/default-setting')
        // @ts-ignore
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
