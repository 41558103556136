import React, { useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import moment from 'moment'
import { Button, Col, DatePicker, Icon, message, Row, Select, Spin } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'
import { getExportWOReport, getPreviewWOReport } from '../../../msp-portal'
import { ColumnNormal } from '../../../ReportComponent/components/Model'
import { convertCamelCaseToSentenceCase } from '../../../knowledge-management/util'
import { checkRolesState, RoleType } from '../../../authorization-module/permissions'
import PageWoService from './PageWOService'
import ColumnWOReport from './ColumnWOReport'
import ColumnWORelateJob from './ColumnWORelateJob'
import ColumnWORelateAsset from './ColumnWORelateAsset'

interface Param {
    name: string
    form: WrappedFormUtils<any>
}

type Props = Param & FormComponentProps
const { RangePicker } = DatePicker
const { Option } = Select
const dateFormat = ('YYYY-MM-DD')
const CommonPanalWOReport: React.FC<Props> = (props: Props) => {
    const [range, setRange] = useState<any[]>()
    const [columnsNewObjList, setColumnsNewObjList] = useState<ColumnNormal[]>([])
    const [columnsNewList, setColumnsNewList] = useState<string[]>([])
    const [, setisFetchingFirst] = useState<boolean>(false)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingExport, setLoadingExport] = useState<boolean>(false)
    const [tempNewState, setTempNewState] = useState<any[]>([])

    const handleChangeRangePicker = (range: any) => {
        if (range && range.length !== 0) {
            setRange(range)
        } else {
            setColumnsNewList([])
            setColumnsNewObjList([])
            setisFetchingFirst(false)
            setRange(undefined)
            setTotal(0)
        }
    }

    const HandelChangeColumnsNewWOReport = (e) => {
        setColumnsNewList(e)
        sessionStorage.setItem(props.name, e)
    }

    const handleOnClickPreviewWOReport = () => {
        if (range) {
            const startData = range[0].format(dateFormat)
            const endDate = range[1].format(dateFormat)
            if (props.name.toLowerCase() === 'workorder') {
                fetchData(startData, endDate, PageWoService.getInstance().data.pageWo, PageWoService.getInstance().data.pageSizeWo, PageWoService.getInstance().data.sortByWo, PageWoService.getInstance().data.orderByWo, columnsNewList)
            } else if (props.name.toLowerCase() === 'relatedjob') {
                fetchData(startData, endDate, PageWoService.getInstance().data.pageWoJob, PageWoService.getInstance().data.pageSizeWoJob, PageWoService.getInstance().data.sortByJob, PageWoService.getInstance().data.orderByJob, columnsNewList)
            } else if (props.name.toLowerCase() === 'relatedasset') {
                fetchData(startData, endDate, PageWoService.getInstance().data.pageWoAsset, PageWoService.getInstance().data.pageSizeWoAsset, PageWoService.getInstance().data.sortByAsset, PageWoService.getInstance().data.orderByAsset, columnsNewList)
            }
        } else {
            setColumnsNewList([])
            setColumnsNewObjList([])
            setisFetchingFirst(false)
            setRange(undefined)
        }
    }

    const exportNewWOReportData = () => {
        if (range) {
            setLoadingExport(true)
            const startData = moment(range[0]).format(dateFormat)
            const endDate = moment(range[1]).format(dateFormat)
            getExportWOReport(startData, endDate, props.name, columnsNewList).then((data) => {
                console.log('success')
            }).finally(() => setLoadingExport(false))
        }
    }

    const getTableHeader = (data) => {
        const columnsExportNewAsset: ColumnNormal[] = []
        if (data.length > 0) {
            for (const keyName of data) {
                columnsExportNewAsset.push(
                    {
                        title: convertCamelCaseToSentenceCase(keyName),
                        dataIndex: keyName
                    }
                )
            }
            // data.map((keyName) => {
            //     columnsExportNewAsset.push(
            //         {
            //             title: convertCamelCaseToSentenceCase(keyName),
            //             dataIndex: keyName
            //         }
            //     )
            // })
        }
        return columnsExportNewAsset
    }

    const fetchData = (startDate: string, endDate: string, page: number, pageSize: number, sortBy: string, orderBy: string, selectedColumn: string[]) => {
        setLoading(true)
        getPreviewWOReport(startDate, endDate, props.name, page, pageSize, sortBy, orderBy, selectedColumn).then((res) => {
            const assetHeader = getTableHeader(res.header)
            if (res.data.length !== 0) {
                setTotal(res.total)
                setLoading(false)
                if (props.name === 'relatedAsset') {
                    // generate unique key for wo ralate asset report
                    res.data.map((it, idx) => {
                        it.id = idx
                        return it
                    })
                }
                setTempNewState(res.data)
                if (!setisFetchingFirst || columnsNewList.length === 0) {
                    setisFetchingFirst(true)
                    if (sessionStorage.getItem(props.name)) {
                        const newWOReport = sessionStorage.getItem(props.name)?.split(',')
                        setColumnsNewList(newWOReport || [])
                    } else {
                        setColumnsNewList(assetHeader.map((keyName) => keyName.dataIndex))
                    }
                }
                setColumnsNewObjList(assetHeader)
                const newWOReportInSesion = sessionStorage.getItem(props.name)?.split(',')
                if (newWOReportInSesion) {
                    if (newWOReportInSesion[0] !== '') {
                        const obj = {}
                        obj['ColumnWOReports' + props.name] = sessionStorage.getItem(props.name)?.split(',')
                        props.form.setFieldsValue(obj)
                    } else {
                        const obj2 = {}
                        obj2['ColumnWOReports' + props.name] = assetHeader.map((keyName) => keyName.dataIndex)
                        props.form.setFieldsValue(obj2)
                    }
                } else {
                    const obj3 = {}
                    obj3['ColumnWOReports' + props.name] = assetHeader.map((keyName) => keyName.dataIndex)
                    props.form.setFieldsValue(obj3)
                }
            } else {
                setTotal(res.total)
                message.warning('Data Not Found')
                setLoading(false)
                if (!setisFetchingFirst || columnsNewList.length === 0) {
                    setisFetchingFirst(true)
                    if (sessionStorage.getItem(props.name)) {
                        const newWOReport = sessionStorage.getItem(props.name)?.split(',')
                        setColumnsNewList(newWOReport || [])
                    } else {
                        setColumnsNewList(assetHeader.map((keyName) => keyName.dataIndex))
                    }
                }
                setColumnsNewObjList(assetHeader)
            }
        }).catch(() => {
            setTempNewState([])
        }).finally(() => setLoading(false))
    }

    const disabledTime = (current) => {
        // Disable times before 10:00 AM and after 6:00 PM
        return current && current > moment().endOf('day')
    }

    return (
        <Row gutter={[16, 16]}>
            <Col md={6} sm={24}>
                <p>Created Date</p>
                <RangePicker onChange={handleChangeRangePicker} placeholder={['Start Date', 'End Date']}
                    disabled={!checkRolesState(RoleType.WO, 'ViewWorkOrder')}
                    disabledDate={disabledTime} />
            </Col>
            {
                columnsNewObjList.length !== 0
                    ? <>
                        <Col md={8} sm={24}>
                            <p>Selected Column</p>
                            {props.form.getFieldDecorator('ColumnWOReports' + props.name, {
                                initialValue: columnsNewList,
                                rules: [{ required: false, message: 'Please input your Activity!' }]
                            })(<Select mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Show"
                                maxTagCount={3}
                                // defaultValue={columnsNewList}
                                onChange={(e) => HandelChangeColumnsNewWOReport(e)}
                                allowClear={true}
                                loading={loading}
                            >
                                {(columnsNewObjList || []).map((it, index) => {
                                    return <Option key={index}
                                        value={it.dataIndex}>{it.title}</Option>
                                })}
                            </Select>)}
                        </Col>
                        <Col md={2} sm={12} xs={12}>
                            <Button htmlType="button" style={{ marginTop: 32 }}
                                onClick={() => handleOnClickPreviewWOReport()}
                                loading={loading}
                                type='primary'
                            ><Icon type="eye" />Preview</Button>
                        </Col>
                    </>
                    : (<>
                        <Col md={2} sm={12} xs={12}>
                            <Button htmlType="button" style={{ marginTop: 32 }}
                                onClick={() => handleOnClickPreviewWOReport()}
                                loading={loading}
                                type='primary'
                            ><Icon type="eye" /> Preview</Button>
                        </Col></>
                    )
            }
            <Col md={8} sm={12} style={{ textAlign: 'end' }} xs={12}>
                {columnsNewObjList.length > 0
                    ? <Button htmlType="button" style={{ marginTop: 32 }}
                        onClick={() => exportNewWOReportData()}
                        disabled={!checkRolesState(RoleType.WO, 'ViewWorkOrder') || columnsNewList.length === 0}
                        loading={loadingExport}
                    ><Icon type="upload" /> Export</Button> : null}
            </Col>
            <Col span={24}>
                <Spin tip="Loading..." spinning={loading}>
                    {props.name === 'workorder' ? <ColumnWOReport dataTransfer={tempNewState}
                        columnsWOReportList={columnsNewList}
                        columnsWOReportObjList={columnsNewObjList}
                        total={total} handleWOReportChange={handleOnClickPreviewWOReport}
                        range={range}
                    /> : null}
                    {props.name === 'relatedJob' ? <ColumnWORelateJob dataTransfer={tempNewState}
                        columnsRelateJobList={columnsNewList}
                        columnsRelateJobObjList={columnsNewObjList}
                        total={total} handleRelateJobChange={handleOnClickPreviewWOReport}
                        range={range}
                    /> : null}
                    {props.name === 'relatedAsset' ? <ColumnWORelateAsset dataTransfer={tempNewState}
                        columnsRelateAssetList={columnsNewList}
                        columnsRelateAssetObjList={columnsNewObjList}
                        total={total} handleRelateAssetChange={handleOnClickPreviewWOReport}
                        range={range}
                    /> : null}
                </Spin>
            </Col>
        </Row>
    )
}

export default CommonPanalWOReport
