import React, { useState } from 'react'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import { getAllOrder } from '../Services/Service'
import { Breadcrumb, Steps } from 'antd'
import {Link, Redirect} from 'react-router-dom'
import ChoosePackage from './ChoosePackage'
import SummaryOrder from './SummaryOrder'
import { newPackages } from '../Services/model'

const mapStateToProps = (state: StoreState) => {
    return {
        orders: state.orders
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllOrder: () => Promise<number>
}

type Props = StateProps & DispatchProps
const { Step } = Steps

const steps = [
    {
        title: 'Step 1 : Choose  Package',
        content: 'First-content'
    },
    {
        title: 'Step 2 : Summary Order',
        content: 'Second-content'
    }
]
const PackageDetailNew: React.FC<Props> = (props: Props) => {
    const [current, setCurrent] = useState(0)
    const [isCancel, setIsCancel] = useState(false)
    const [tempData, setTempData] = useState<newPackages | undefined>()

    const getData = (data) => {
        if (data) {
            setTempData(data)
        }
    }

    return (
        <div>
            {isCancel ? (<Redirect to="/SubscriptionList" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/SubscriptionList">Subscription Package</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    New Package
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Choose  Package
                </Breadcrumb.Item>
            </Breadcrumb>
            <br/>
            <div style={{ width: '80%', textAlign: 'center'}}>
                <Steps current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
            </div>
            <div>
                {current === 0 && (<ChoosePackage current={current} isCancel={isCancel} setCurrent={setCurrent} setIsCancel={setIsCancel} getData={getData}/>)}
                {current === 1 && (<SummaryOrder current={current} isCancel={isCancel} setCurrent={setCurrent} setIsCancel={setIsCancel} tempData={tempData!!}/>)}
            </div>
            <div>
                {/* {current === 0 && (
                    <div style={{ textAlign: 'end', marginRight: '5%' }}>
                        <Button onClick={onCancel} style={{ marginRight: 5 }}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={nextCurrent}>
                            Next <Icon type="right" />
                        </Button>
                    </div>
                )} */}
                {/* {current === 1 && (
                    <div style={{ textAlign: 'end', marginRight: '5%' }}>
                        <Button type="primary" onClick={backCurrent}>
                            <Icon type="left" /> Back
                        </Button>
                        <Button onClick={onCancel} style={{ marginRight: 5, marginLeft: 5 }}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={confirmOrder}>
                            Confirm Order
                        </Button>
                    </div>
                )} */}
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {getAllOrder})(PackageDetailNew)
