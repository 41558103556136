import React, { ChangeEvent, useEffect, useState } from 'react'
import { StoreState } from '../../../store'
import Form, { FormComponentProps } from 'antd/es/form'
import { connect } from 'react-redux'
import { AutoComplete, Button, Checkbox, Col, DatePicker, Dropdown, Icon, Input, Menu, message, Modal, Radio, Row, Select, Spin } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { allVisibleViews, CurrentViewProblemAndallVisibleViews, pbFilter, ProblemStatus } from '../Model'
import { generalProblem, ProblemType } from '../../general/states/model'
import { addAllTypeProblems, getAllGeneralProblems } from '../../general/states/service'
import { addCurrentViewProblem, GetAllStatusProblem, getCurrentViewProblemById, UpdateCurrentViewProblem } from '../service'
import { getAllCategory } from '../../../incident-management/category'
import { getAllPriorityProblem } from '../../priority'
import { searchUserRoleWithPagination } from '../../../authorization-module/user-role/duck/service'
import { People } from '../../../authorization-module/people/model'
import { SelectValue } from 'antd/lib/select'
import moment from 'moment'
import DeleteFilterProblem from './DeleteFilterProblem'
import { checkRolesState, RoleType } from '../../../authorization-module/permissions'
import { RadioChangeEvent } from 'antd/lib/radio'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.categories,
        priorityProblem: state.priorityProblem
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    isSearchVisible: boolean
    handleSubmitFilter: Function
    ForceLoadingCurrentView: Function
    isAllLoading: boolean
    currentView?: CurrentViewProblemAndallVisibleViews
}

interface DispatchProps {
    getAllCategory: () => Promise<number>
    getAllPriorityProblem: () => Promise<number>
}

type Props = StateProps & Param & FormComponentProps & DispatchProps
const { Option, OptGroup } = Select
const { RangePicker } = DatePicker
let isSetTemp = false
const FilterProblem: React.FC<Props> = (props: Props) => {
    const { getFieldValue, setFieldsValue, getFieldDecorator, validateFields, resetFields } = props.form
    const [selectedAgents, setSelectedAgents] = useState<string[]>([])
    const [typeProblems, setTypeProblems] = useState<ProblemType>()
    const [allStatus, setAllStatus] = useState<ProblemStatus[]>([])
    const [tempCloseStatus, setTempCloseStatus] = useState<generalProblem>()
    const [suggestions, setSuggestions] = useState<People[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isSelect, setIsSelect] = useState(false)
    const [isTempRequest, setIsTempRequest] = useState<string>('')
    const [inputText, setInputText] = useState<string>('')
    const [isVisibleDeleteFilter, setIsVisibleDeleteFilter] = useState<boolean>(false)
    const [isShowingSaveAsModalForm, setIsShowingSaveAsModalForm] = useState<boolean>(false)
    const [isInputReady, setIsInputReady] = useState<boolean>(false)
    const [tempDefaultValue, setDefaultValue] = useState<CurrentViewProblemAndallVisibleViews>()
    const tempDefault = props.currentView?.allVisibleViews?.filter((data: allVisibleViews) => data.visibility === 'Default')
    const tempMyGroup = props.currentView?.allVisibleViews?.filter((data: allVisibleViews) => data.visibility === 'My Group')
    const tempMySelf = props.currentView?.allVisibleViews?.filter((data: allVisibleViews) => data.visibility === 'Myself')
    const [selectedView, setSelectedView] = useState<string>()

    const params = {
        name: '',
        pbFilter: props.currentView?.currentView.pbFilter,
        visibility: props.currentView?.currentView.visibility || 'Myself'
    }

    const onSelectedAgentsChanged = (value: CheckboxValueType[]) => {
        // console.log(value)
        // if (value.includes('assignedToMe')) {
        //     setFieldsValue({
        //         status: ['New', 'Assess', 'Root Cause Analysis', 'Fix In Progress', 'Resolved', 'Closed', 'Cancelled']
        //     })
        // } else {
        //     setFieldsValue({
        //         status: ['New', 'Assess', 'Root Cause Analysis', 'Fix In Progress']
        //     })
        // }
        setSelectedAgents(value.map((it) => it as string))
    }

    useEffect(() => {
        setIsLoading(true)
        const promiseAll: any[] = []
        promiseAll.push(props.getAllCategory())
        promiseAll.push(props.getAllPriorityProblem())
        addAllTypeProblems().then((res: ProblemType[]) => {
            if (res) {
                const filterTypyProblems = res.find((p) => { return p.type === 'Problem' && p.active === true })
                setTypeProblems(filterTypyProblems)
            }
        })
        Promise.all(promiseAll).catch((err) => {
            message.error(`Failed fetching all Data. ${err}`)
        }).finally(() => { setIsLoading(false) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.currentView && props.currentView?.currentView?.pbFilter) {
            if (isSetTemp === false) {
                setDefaultValue(props.currentView)
                isSetTemp = true
            }
            const tempSelectedAgents: string[] = []
            if (props.currentView.currentView.pbFilter.assignedToMe === true) {
                tempSelectedAgents.push('assignedToMe')
            } else {
                tempSelectedAgents.filter((it) => { return it !== 'assignedToMe' })
            }
            if (props.currentView.currentView.pbFilter.assignedToMyTeam === true) {
                tempSelectedAgents.push('assignedToMyTeam')
            } else {
                tempSelectedAgents.filter((it) => { return it !== 'assignedToMyTeam' })
            }
            if (props.currentView.currentView.pbFilter.unassigned === true) {
                tempSelectedAgents.push('unassigned')
            } else {
                tempSelectedAgents.filter((it) => { return it !== 'unassigned' })
            }
            setSelectedAgents(tempSelectedAgents)
            setFieldsValue({
                // allVisibleViews: props.currentView.currentView.pbFilter.id + ' : ' + props.currentView.currentView.name,
                sortBy: props.currentView.currentView.pbFilter.sortBy,
                orderBy: props.currentView.currentView.pbFilter.orderBy,
                requester: props.currentView.currentView.pbFilter.requester,
                created: props.currentView.currentView.pbFilter.created,
                DateRange: props.currentView.currentView.pbFilter.startDate && props.currentView.currentView.pbFilter.endDate ? [moment(props.currentView.currentView.pbFilter.startDate), moment(props.currentView.currentView.pbFilter.endDate)] : undefined,
                status: props.currentView.currentView.pbFilter.status ? props.currentView.currentView.pbFilter.status?.split(',') : [],
                closeStatus: props.currentView.currentView.pbFilter.closedStatus ? props.currentView.currentView.pbFilter.closedStatus?.split(',') : [],
                priority: props.currentView.currentView.pbFilter.priority ? props.currentView.currentView.pbFilter.priority?.split(',') : [],
                category: props.currentView.currentView.pbFilter.category ? props.currentView.currentView.pbFilter.category?.split(',') : []
            })
            setSelectedView(props.currentView.currentView.pbFilter.id + ' : ' + props.currentView.currentView.name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentView])

    useEffect(() => {
        if (props.isSearchVisible === false) {
            isSetTemp = false
            setIsVisibleDeleteFilter(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isSearchVisible])

    useEffect(() => {
        if (typeProblems && typeProblems.id) {
            GetAllStatusProblem(typeProblems.id!!).then((status) => {
                setAllStatus(status)
            })
            getAllGeneralProblems(typeProblems.id?.toString()).then((it) => {
                setTempCloseStatus(it)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeProblems])

    const onSubmitFilter = () => {
        const data = PrepereData()
        console.log(data)
        if (props.currentView?.currentView?.pbFilter) {
            const temp = props.currentView
            temp.currentView.pbFilter = data
            props.handleSubmitFilter(temp)
        }
    }

    const PrepereData = () => {
        const findAssignedToMe = selectedAgents.includes('assignedToMe')
        const findAssignedToMyTeam = selectedAgents.includes('assignedToMyTeam')
        const findUnassigned = selectedAgents.includes('unassigned')
        console.log(getFieldValue('DateRange'))
        let startData: string | null = null
        let endData: string | null = null
        if (getFieldValue('DateRange')) {
            if (getFieldValue('DateRange')[0]) {
                startData = moment(getFieldValue('DateRange')[0]).format('YYYY-MM-DD')
            }
            if (getFieldValue('DateRange')[1]) {
                endData = moment(getFieldValue('DateRange')[1]).format('YYYY-MM-DD')
            }
        }
        const spiltText = selectedView?.split(':')[0]?.trim()
        const findData = props.currentView?.allVisibleViews.find((data) => { return data.pbFilterId.toString() === spiltText?.toString() })
        const converData: pbFilter = {
            id: findData?.pbFilterId,
            createdDatetime: props.currentView?.currentView.pbFilter.createdDatetime,
            lastModifiedDatetime: props.currentView?.currentView.pbFilter.lastModifiedDatetime,
            assignedToMe: findAssignedToMe,
            assignedToMyTeam: findAssignedToMyTeam,
            unassigned: findUnassigned,
            sortBy: getFieldValue('sortBy'),
            orderBy: getFieldValue('orderBy'),
            requester: getFieldValue('requester'),
            created: getFieldValue('created'),
            startDate: startData,
            endDate: endData,
            status: getFieldValue('status')?.join(',') || null,
            closedStatus: getFieldValue('closeStatus')?.join(',') || null,
            priority: getFieldValue('priority')?.join(',') || null,
            category: getFieldValue('category')?.join(',') || null
        }
        return converData
    }

    const renderOption = (item) => {
        return (
            <Option key={item.email} value={item.email}>
                {item.firstName + ' ' + item.lastName}
            </Option>
        )
    }

    const onRequesterSearchTriggered = (searchText: string) => {
        populateRequesterSuggestions(searchText)
    }

    const populateRequesterSuggestions = (input: any) => {
        searchUserRoleWithPagination(input).then((res) => {
            if (res) {
                setSuggestions(res.data)
            }
        })
    }

    const onRequesterSelected = (value: SelectValue) => {
        setIsTempRequest(value as string)
        console.log(isTempRequest)
        if (value) {
            setIsSelect(true)
        }
    }

    const onInputTextChanged = (value: SelectValue) => {
        setInputText(value as string)
    }

    const onBlurText = (value) => {
        if (value && !isSelect) {
            setInputText('')
            resetFields(['requester'])
        }
    }

    const isOverwritingAllowedForCurrentView = () => props.currentView?.currentView?.predefined === false

    const onSaveIconClicked = () => {
        const tempsenddata = props.currentView?.currentView!!
        const data = PrepereData()
        tempsenddata.pbFilter = data!!
        if (tempsenddata.id) {
            UpdateCurrentViewProblem(tempsenddata.id?.toString(), tempsenddata).then((res) => {
                message.success('The update has finished successfully.')
            })
        }
    }

    const onSaveAsIconClicked = () => {
        setIsShowingSaveAsModalForm(true)
    }

    const menu = (
        <Menu>
            {isOverwritingAllowedForCurrentView() ? <Menu.Item key="1" onClick={onSaveIconClicked}>
                <Icon type="download" /> Save
            </Menu.Item> : null}
            <Menu.Item key="2" onClick={onSaveAsIconClicked}>
                <Icon type="save" /> Save as
            </Menu.Item>
        </Menu>
    )

    const onSubmit = async () => {
        validateFields(async (err, fieldsValue) => {
            if (!err) {
                const data = PrepereData()
                const convertData = {
                    name: '',
                    pbFilter: data,
                    visibility: props.currentView?.currentView.visibility || 'Myself'
                }
                setIsLoading(true)
                if (!convertData.name) {
                    convertData.name = getFieldValue('viewName')?.trim()
                    convertData.visibility = getFieldValue('visibility')
                }
                delete convertData.pbFilter.id
                console.log(convertData)
                // add
                addCurrentViewProblem(convertData).then(() => {
                    message.success(`View '${convertData.name?.trim()}' saved successfully.`)
                    props.ForceLoadingCurrentView(true)
                }).catch((error: any) => {
                    message.error(error.response.data.message || 'Failed saving new view.')
                }).finally(() => {
                    setIsShowingSaveAsModalForm(false)
                    setIsLoading(false)
                })
            }
        })
    }

    const onNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
        params.name = e.target.value.trim()
        updateInputReadiness()
    }

    const onVisibilityChanged = (e: RadioChangeEvent) => {
        params.visibility = e.target.value
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (props.currentView?.allVisibleViews?.some((viewName) => {
            return viewName.visibleName.toLowerCase().trim() === value.toLowerCase().trim()
        })) {
            callback(`This view name '${value}' is already in used. Please try the other name.`)
        } else {
            callback()
        }
    }

    const updateInputReadiness = () => {
        setIsInputReady(params.name?.length >= 3 && params.visibility?.length > 0)
    }

    const handleChangeAllVisibleViews = (e) => {
        if (e) {
            const spiltText = e?.split(':')[0]?.trim()
            const findData = props.currentView?.allVisibleViews.find((data) => { return data.pbFilterId.toString() === spiltText.toString() })
            if (findData) {
                setIsLoading(true)
                getCurrentViewProblemById(findData.pbFilterId.toString()).then((current) => {
                    const tempSelectedAgents: string[] = []
                    if (current.assignedToMe === true) {
                        tempSelectedAgents.push('assignedToMe')
                    } else {
                        tempSelectedAgents.filter((it) => { return it !== 'assignedToMe' })
                    }
                    if (current.assignedToMyTeam === true) {
                        tempSelectedAgents.push('assignedToMyTeam')
                    } else {
                        tempSelectedAgents.filter((it) => { return it !== 'assignedToMyTeam' })
                    }
                    if (current.unassigned === true) {
                        tempSelectedAgents.push('unassigned')
                    } else {
                        tempSelectedAgents.filter((it) => { return it !== 'unassigned' })
                    }
                    setSelectedAgents(tempSelectedAgents)
                    setFieldsValue({
                        // allVisibleViews: e,
                        sortBy: current.sortBy,
                        orderBy: current.orderBy,
                        requester: current.requester,
                        created: current.created,
                        DateRange: current.startDate && current.endDate ? [moment(current.startDate), moment(current.endDate)] : undefined,
                        status: current.status ? current.status?.split(',') : [],
                        closeStatus: current.closedStatus ? current.closedStatus?.split(',') : [],
                        priority: current.priority ? current.priority?.split(',') : [],
                        category: current.category ? current.category?.split(',') : []
                    })
                    setSelectedView(e)
                }).finally(() => {
                    setIsLoading(false)
                })
            }
        }
    }

    const handleResetToDefault = () => {
        const tempSelectedAgents: string[] = []
        if (tempDefaultValue?.currentView.pbFilter.assignedToMe === true) {
            tempSelectedAgents.push('assignedToMe')
        } else {
            tempSelectedAgents.filter((it) => { return it !== 'assignedToMe' })
        }
        if (tempDefaultValue?.currentView.pbFilter.assignedToMyTeam === true) {
            tempSelectedAgents.push('assignedToMyTeam')
        } else {
            tempSelectedAgents.filter((it) => { return it !== 'assignedToMyTeam' })
        }
        if (tempDefaultValue?.currentView.pbFilter.unassigned === true) {
            tempSelectedAgents.push('unassigned')
        } else {
            tempSelectedAgents.filter((it) => { return it !== 'unassigned' })
        }
        setSelectedAgents(tempSelectedAgents)
        setFieldsValue({
            // allVisibleViews: tempDefaultValue?.currentView.name,
            sortBy: tempDefaultValue?.currentView.pbFilter.sortBy,
            orderBy: tempDefaultValue?.currentView.pbFilter.orderBy,
            requester: tempDefaultValue?.currentView.pbFilter.requester,
            created: tempDefaultValue?.currentView.pbFilter.created,
            DateRange: tempDefaultValue?.currentView.pbFilter.startDate && tempDefaultValue?.currentView.pbFilter.endDate ? [moment(tempDefaultValue?.currentView.pbFilter.startDate), moment(tempDefaultValue?.currentView.pbFilter.endDate)] : undefined,
            status: tempDefaultValue?.currentView.pbFilter.status ? tempDefaultValue?.currentView.pbFilter.status?.split(',') : [],
            closeStatus: tempDefaultValue?.currentView.pbFilter.closedStatus ? tempDefaultValue?.currentView.pbFilter.closedStatus?.split(',') : [],
            priority: tempDefaultValue?.currentView.pbFilter.priority ? tempDefaultValue?.currentView.pbFilter.priority?.split(',') : [],
            category: tempDefaultValue?.currentView.pbFilter.category ? tempDefaultValue?.currentView.pbFilter.category?.split(',') : []
        })
    }

    return (
        <div>
            <Spin spinning={isLoading || props.isAllLoading}>
                {!isVisibleDeleteFilter ? <div>
                    <Form>
                        <div style={{ textAlign: 'end', fontWeight: 600 }}>
                            <Dropdown overlay={menu}>
                                <span style={{ cursor: 'pointer' }}><Icon type="save" /> &nbsp; Save View </span>
                            </Dropdown>
                            &nbsp; &nbsp;
                            <span style={{ color: '#EB5757', cursor: 'pointer' }} onClick={() => setIsVisibleDeleteFilter(true)}><Icon type="delete" /> &nbsp; Delete View</span>
                        </div>
                        <br />
                        {/* <Form.Item>
                            {getFieldDecorator('allVisibleViews', {
                                initialValue: props.currentView?.currentView.name
                            })( */}
                        <Select style={{ width: '100%' }} onChange={(e) => handleChangeAllVisibleViews(e)} value={selectedView}>
                            {tempDefault && tempDefault?.length > 0
                                ? <OptGroup label="Default">
                                    {tempDefault?.map((data: allVisibleViews, index: number) => {
                                        return (
                                            <Option key={index} value={data.pbFilterId + ' : ' + data.visibleName}>
                                                <span>{data.visibleName}</span>
                                            </Option>
                                        )
                                    })}
                                </OptGroup>
                                : null}
                            {tempMyGroup && tempMyGroup?.length > 0
                                ? <OptGroup label="My group">
                                    {tempMyGroup?.map((data: allVisibleViews, index: number) => {
                                        return (
                                            <Option key={index} value={data.pbFilterId + ' : ' + data.visibleName}>
                                                <span>{data.visibleName}</span>
                                            </Option>
                                        )
                                    })}
                                </OptGroup>
                                : null}
                            {tempMySelf && tempMySelf?.length > 0
                                ? <OptGroup label="My self">
                                    {tempMySelf?.map((data: allVisibleViews, index: number) => {
                                        return (
                                            <Option key={index} value={data.pbFilterId + ' : ' + data.visibleName}>
                                                <span>{data.visibleName}</span>
                                            </Option>
                                        )
                                    })}
                                </OptGroup>
                                : null}
                        </Select>
                        {/* )}
                        </Form.Item> */}
                        <br />
                        <div style={{ textAlign: 'end', fontWeight: 600 }}><p className='feck-link' onClick={handleResetToDefault}>Reset to Default</p></div>
                        <hr style={{ border: '1px solid #DCDCDC' }} />
                        <div style={{ fontWeight: 600 }}>Agents</div>
                        <div>
                            <Checkbox.Group style={{ width: '100%' }} onChange={onSelectedAgentsChanged}
                                value={selectedAgents} key={'agentFilter'}>
                                <Row>
                                    <Col span={24}>
                                        <Checkbox value="assignedToMe">Me</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="unassigned">Unassigned</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox value="assignedToMyTeam">Assign to my groups</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </div>
                        <div style={{ fontWeight: 600 }}>Sort By</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item>
                                    {getFieldDecorator('sortBy', {
                                        initialValue: props.currentView?.currentView.pbFilter.sortBy
                                    })(
                                        <Select style={{ width: '100%' }}>
                                            <Option value="CreatedDatetime">Created date</Option>
                                            <Option value="LastModifiedDatetime">Last modify date</Option>
                                            <Option value="Priority">Priority</Option>
                                            <Option value="ProblemStatus">Status</Option>
                                            <Option value="Number">Problem number</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <Form.Item>
                                    {getFieldDecorator('orderBy', {
                                        initialValue: props.currentView?.currentView.pbFilter.orderBy
                                    })(
                                        <Select>
                                            <Option value="DESC">Descending</Option>
                                            <Option value="ASC">Ascending</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: 600 }}>Requesters</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item>
                                    {getFieldDecorator('requester', {
                                        initialValue: props.currentView?.currentView.pbFilter.requester
                                    })(
                                        <AutoComplete
                                            dataSource={suggestions?.map(renderOption)}
                                            style={{ width: '100%' }}
                                            onSelect={onRequesterSelected}
                                            onSearch={onRequesterSearchTriggered}
                                            onChange={onInputTextChanged}
                                            // onBlur={onRequesterSelected}
                                            onBlur={(e) => onBlurText(e)}
                                            autoClearSearchValue={true}
                                            placeholder="Select Requesters"
                                            value={inputText}
                                            allowClear={true}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: 600 }}>Created</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item>
                                    {getFieldDecorator('created', {
                                        initialValue: props.currentView?.currentView.pbFilter.created
                                    })(
                                        <Select style={{ width: '100%' }}>
                                            <Option value="anytime">Any Time</Option>
                                            <Option value="last5min">Within 5 minutes</Option>
                                            <Option value="last15min">Within 15 minutes</Option>
                                            <Option value="last30min">Within 30 minutes</Option>
                                            <Option value="last1h">Within 1 hour</Option>
                                            <Option value="last4h">Within 4 hours</Option>
                                            <Option value="last12h">Within 12 hours</Option>
                                            <Option value="last24h">Within 24 hours</Option>
                                            <Option value="today">Today</Option>
                                            <Option value="yesterday">Yesterday</Option>
                                            <Option value="thisWeek">This week</Option>
                                            <Option value="thisMonth">This month</Option>
                                            <Option value="last30Days">Last 30 days</Option>
                                            <Option value="last2M">Last 2 months</Option>
                                            <Option value="last6M">Last 6 months</Option>
                                            {/* <Option value="Select Time Period">Select Time Period</Option> */}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: 600 }}>Date</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item>
                                    {getFieldDecorator('DateRange', {
                                        initialValue: props.currentView?.currentView.pbFilter.startDate && props.currentView?.currentView.pbFilter.endDate ? [moment(props.currentView?.currentView.pbFilter.startDate), moment(props.currentView?.currentView.pbFilter.endDate)] : undefined
                                    })(
                                        <RangePicker style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: 600 }}>Status</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item>
                                    {getFieldDecorator('status', {
                                        initialValue: props.currentView?.currentView.pbFilter.status ? props.currentView?.currentView.pbFilter.status?.split(',') : []
                                    })(
                                        <Select style={{ width: '100%' }} mode='tags' allowClear={true} placeholder='Status'>
                                            {allStatus.map((status, index) => {
                                                return <Option value={status.status} key={index}>{status.status}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: 600 }}>Close Status</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item>
                                    {getFieldDecorator('closeStatus', {
                                        initialValue: props.currentView?.currentView.pbFilter.closedStatus ? props.currentView?.currentView.pbFilter.closedStatus?.split(',') : []
                                    })(
                                        <Select mode='tags' allowClear={true} placeholder={'Close Status'}>
                                            {tempCloseStatus?.problemCloseReasonStatus?.map((status, index) => {
                                                return <Option value={status.name} key={index}>{status.name}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: 600 }}>Priority</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item>
                                    {getFieldDecorator('priority', {
                                        initialValue: props.currentView?.currentView.pbFilter.priority ? props.currentView?.currentView.pbFilter.priority?.split(',') : []
                                    })(
                                        <Select mode='tags' allowClear={true} placeholder='Priority'>
                                            {props.priorityProblem?.map((status, index) => {
                                                return <Option value={status.name} key={index}>{status.name}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <div style={{ fontWeight: 600 }}>Category</div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item>
                                    {getFieldDecorator('category', {
                                        initialValue: props.currentView?.currentView.pbFilter.category ? props.currentView?.currentView.pbFilter.category?.split(',') : []
                                    })(
                                        <Select mode='tags' allowClear={true} placeholder='Category'>
                                            {props.categories?.map((status, index) => {
                                                return <Option value={status.name} key={index}>{status.name}</Option>
                                            })}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: 'end' }}>
                            {/* <Button>Cancel</Button> &nbsp;&nbsp; */}
                            <Button type='primary' htmlType={'button'} onClick={onSubmitFilter}>Submit</Button>
                        </Row>

                        <Modal
                            title="Save as"
                            onCancel={() => setIsShowingSaveAsModalForm(false)}
                            visible={isShowingSaveAsModalForm}
                            footer={[
                                // <Button key="back" onClick={onCancel}>
                                //     Cancel
                                // </Button>,
                                <Button key="submit" type="primary" htmlType='button' onClick={onSubmit} disabled={!isInputReady} loading={isLoading}>
                                    Save
                                </Button>
                            ]}
                            maskClosable={false}
                        >
                            <Row >
                                <Form.Item label="View Name">
                                    {getFieldDecorator('viewName', {
                                        initialValue: params.name,
                                        rules: [
                                            { required: true, message: 'View name is required' }, { min: 3, message: 'Enter more than 3 characters' },
                                            { max: 50, message: 'message max 50 characters' }, { validator: validateNameUniqueness }
                                        ]
                                    })(
                                        <Input onChange={onNameChanged} placeholder="View name" />
                                    )}
                                </Form.Item>
                                <Form.Item label="Visible to">
                                    {getFieldDecorator('visibility', {
                                        initialValue: 'Myself',
                                        rules: [{ required: true, message: 'Visibility is require' }]
                                    })(
                                        <Radio.Group value={params.visibility} onChange={onVisibilityChanged} >
                                            <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value={'Myself'}>My Self</Radio>
                                            {checkRolesState(RoleType.Admin, 'ManageProblemSetting') ? <Radio style={{ display: 'block', height: '30px', lineHeight: '30px' }} value={'My Group'}>My Group</Radio> : null}
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Row>
                        </Modal>
                    </Form>
                </div> : <div>
                    <DeleteFilterProblem setIsVisibleDeleteFilter={setIsVisibleDeleteFilter} isVisibleDeleteFilter={isVisibleDeleteFilter} currentView={props.currentView} currentViewId={Number(selectedView?.split(':')[0]?.trim())} ForceLoadingCurrentView={props.ForceLoadingCurrentView} />
                </div>}
            </Spin>
        </div>
    )
}

const wrappedFilterProblem = Form.create<Props>({ name: 'wrappedFilterProblem' })(FilterProblem)
export default connect(mapStateToProps, {
    getAllCategory, getAllPriorityProblem
})(wrappedFilterProblem)
