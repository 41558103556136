import {axiosDelete, axiosGet, axiosGetWithPagination, axiosPatch, axiosPost} from '../rest'
import {Assignee, SupportTeamManagement} from './model'
import {Dispatch} from 'redux'
import {
    AddSupportTeamSuccessEvent,
    DeleteSupportTeamSuccessEvent,
    GetAllSupportTeamSuccessEvent,
    UpdateSupportTeamCurrentPageSuccessEvent,
    UpdateSupportTeamSuccessEvent,
    UpdateSupportTeamTotalPageSuccessEvent
} from './state-event'
import {checkRedirect, herderPostPatch} from '../../common-misc'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'

export const getSupportTeamsWithPagination = (page: number, pageSize: number, searchField: string, searchValue: string, activateFilter: boolean[], sortBy: string, orderBy: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    page: page || 1,
                    size: pageSize || 10,
                    field: searchField,
                    value: searchValue,
                    activations: activateFilter,
                    sort: `${sortBy || 'createdDatetime'},${orderBy || 'asc'}`
                }
            }

            const url = '/api/groups/search'
            const response = await axiosGetWithPagination<SupportTeamManagement[]>(url, config)
            const searchResult = decryptBody(response.data.data!!)
            dispatch(UpdateSupportTeamCurrentPageSuccessEvent.build(page))
            dispatch(UpdateSupportTeamTotalPageSuccessEvent.build(searchResult.totalElements || searchResult.total || 0))
            dispatch(GetAllSupportTeamSuccessEvent.build(searchResult.content || []))
            return response.status
        } catch (err: any) {
            dispatch(GetAllSupportTeamSuccessEvent.build([]))
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}
export async function getAllSupportTeam() {
    try {
        const response = await axiosGet<SupportTeamManagement[]>('/api/groups')
        if (response.status === 204) {
            return []
        } else {
            return decryptBody(response.data.data!!)
        }
        // // console.log(decryptBody(response.data.data!!))
        // return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}
export async function getSupportTeamById(id: string) : Promise<SupportTeamManagement> {
    try {
        const response = await axiosGet<SupportTeamManagement>(`/api/groups/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}
export async function getUser(user: string) : Promise<Assignee[]> {
    if (!user) user = 'user'
    try {
        const response = await axiosGet<Assignee[]>(`/api/groups/users/${user}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err?.response?.status)
        throw err
    }
}

export const addSupportTeam = (s: SupportTeamManagement) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(s))
            const response = await axiosPost<SupportTeamManagement>('/api/groups', encryptData, herderPostPatch)
            dispatch(AddSupportTeamSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err.response.data.message
        }
    }
}

export const editSupportTeam = (id: string, s) => {
    return async (dispatch: Dispatch) => {
        try {
            console.log(s)
            const encryptData = encryptBody(JSON.stringify(s))
            console.log(encryptData)
            const response = await axiosPatch<SupportTeamManagement>(`/api/groups/update/${id}`, encryptData, herderPostPatch)
            dispatch(UpdateSupportTeamSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err.response)
            checkRedirect(err?.response?.status)
            throw err.response.data.message
        }
    }
}

export const deleteSupportTeam = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/groups/${id}`)
            dispatch(DeleteSupportTeamSuccessEvent.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err.response.data.message
        }
    }
}
