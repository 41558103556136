import React, {useState} from 'react'
import Footer from '../common-components/DefaultFooter'
import './FreeRegister.css'
import Logo from '../assets/images/LogoGoService.png'
import LogoMini from '../assets/images/GoServiceMiniLogo.png'
import {Col, message, Row, Steps} from 'antd'
import Step1Free from './components/Step1Free'
import Step2Free from './components/Step2Free'
import {resentMail} from '../authorization-module/login/service'

const { Step } = Steps

const steps = [
    {
        title: 'Service',
        content: 'First-content'
    },
    {
        title: 'Register',
        content: 'Second-content'
    }
    // {
    //     title: 'Account',
    //     content: 'Last-content'
    // }
]
const FreeRegister = () => {
    const [current, setCurrent] = useState<number>(0)
    const [freeUser, setFreeUser] = useState({})
    const [email] = useState<string>('')

    const onClickToResentMail = () => {
        resentMail(email).then(() => {
            message.success('Resend mail success.')
        }).catch(() => {
            message.error('Fail to resend mail, Please try agian.')
        })
    }
    return (
        <div style={{ height: '94%'}}>
            <Row style={{ minHeight: '100%'}}>
                <Col span={6} style={{backgroundColor: '#323276', height: 80 }}></Col>
                <Col span={12}>
                    <div>
                        <div style={{ textAlign: 'center', padding: 7, marginBottom: '9%', backgroundColor: '#323276'}}>
                            <img src={Logo} alt={'logo'} width={250} />
                        </div>
                        <br/>
                        <div id='box-step'>
                            <div id='topic' style={{textAlign: current === 2 ? 'center' : 'left'}}>{current === 2 ? 'Thank you for register' : <div style={{ fontSize: 32 }}>Welcome to <img src={LogoMini} alt='logo' style={{ width: 28, marginBottom: 5}}/><span style={{ color: '#F6712E'}}>O Service</span></div>}</div>
                            <br/><br/>
                            {current === 2 ? <>
                                <div style={{textAlign: 'center'}}>
                                    <p id='sub-title'>Please check your email ({email}) to confirm your account</p>
                                    <p id='main-title'>No subscription confirmation has been received? Check your spam folder or <div className={'feck-link'} onClick={() => onClickToResentMail()}>request new confirmation email.</div></p>
                                </div>
                            </> : <>
                                <Steps current={current}
                                    direction={window.innerWidth < 768 ? 'vertical' : 'horizontal'}
                                >
                                    {steps.map((item) => (
                                        <Step key={item.title} title={item.title} />
                                    ))}
                                </Steps>
                                <br/>
                                {current === 0 ? <Step1Free current={current} setCurrent={setCurrent} freeUser={freeUser} setFreeUser={setFreeUser}/> : null}
                                {current === 1 ? <Step2Free current={current} setCurrent={setCurrent} freeUser={freeUser} setFreeUser={setFreeUser}/> : null}
                                {/* {current === 2 ? <Step3Free current={current} setCurrent={setCurrent} freeUser={freeUser} setFreeUser={setFreeUser} setEmail={setEmail}/> : null} */}
                            </>}
                        </div>
                    </div>
                </Col>
                <Col span={6} style={{backgroundColor: '#323276', height: 80 }}></Col>
            </Row>
            <Footer/>
        </div>
    )
}

export default FreeRegister
