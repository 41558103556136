import React, { useState } from 'react'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Badge, Col, Dropdown, Menu, Row, Tag, Tooltip } from 'antd'
import { waitingGetSessions } from '../../../guard/route'
import RelateListProblem from './RelateListProblem'

const mapStateToProps = (state: StoreState) => {
    return {

    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {

}

interface Param {
    isPTask: boolean
    data?: any
    isRootCause?: boolean
}

type Props = StateProps & DispatchProps & Param;
const ProblemRow: React.FC<Props> = (props: Props) => {
    const [visibleRelated, setVisibleRelated] = useState(false)
    const isMobile = window.innerWidth < 500

    const handelCleck = () => {
        if (props.isPTask) {
            window.open('/ProblemTaskDetailView/' + props.data?.id, '_blank')
        } else {
            window.location.href = '/ProblemDetailView/' + props.data?.id
        }
        waitingGetSessions()
    }

    const getTagColorByStatus = (status: string) => {
        if (props.isPTask) {
            if (status === 'New') {
                return '#00c3ff'
            } else if (status === 'Assess') {
                return '#FFC107'
            } else if (status === 'In Progress') {
                return '#6F42C1'
            } else if (status === 'Closed') {
                return '#2848a7'
            }
        } else {
            if (status === 'New') {
                return '#00c3ff'
            } else if (status === 'Assess') {
                return '#FFC107'
            } else if (status === 'Root Cause Analysis') {
                return '#FD7E14'
            } else if (status === 'Fix In Progress') {
                return '#6F42C1'
            } else if (status === 'Resolved') {
                return '#28A745'
            } else if (status === 'Closed') {
                return '#2848a7'
            } else if (status === 'Cancelled') {
                return '#6C757D'
            }
        }
    }

    const menuRelate = (
        <Menu>
            <RelateListProblem visibleRelated={visibleRelated} setVisibleRelated={setVisibleRelated} problem={props.data} />
        </Menu>
    )

    const handleVisibleChange = () => {
        setVisibleRelated(!visibleRelated)
    }

    return (
        <div style={{ padding: 15, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgb(0 0 0 / 1%) 0px 6px 20px 0px', borderRadius: '5px', marginBottom: 10 }}>
            <div style={{ backgroundColor: props.data?.pbStatus === 'Assess' && !props.isPTask ? '#FFFAF1' : '#F5F9FF', borderRadius: '5px', padding: 10 }}>
                <Row>
                    <Col span={props.isPTask ? 3 : 2} xs={24} sm={24} md={props.isPTask ? 3 : 2} lg={props.isPTask ? 3 : 2} xl={props.isPTask ? 3 : 2}>
                        <Link to={'#'} target={props.isPTask ? '_blank' : ''} onClick={() => handelCleck()}><b>{props.data?.number}</b></Link>
                    </Col>
                    <Col span={props.isPTask ? 5 : 10} xs={props.isPTask ? 12 : 24} sm={props.isPTask ? 12 : 24} md={props.isPTask ? 9 : 10} lg={props.isPTask ? 9 : 10} xl={props.isPTask ? 9 : 10}>
                        <Tooltip title={props.data?.topic}>
                            <div style={{ color: '#323276', paddingLeft: props.isPTask ? '6%' : '0%' }}> <b>{isMobile ? props.data?.topic.length > 30 ? props.data?.topic.slice(0, 30) + ' ...' : props.data?.topic
                                : props.isPTask ? props.data?.topic.length > 50 ? props.data?.topic.slice(0, 50) + ' ...' : props.data?.topic : props.data?.topic.length > 100 ? props.data?.topic.slice(0, 100) + ' ...' : props.data?.topic}</b>
                            </div>
                        </Tooltip>
                    </Col>
                    <Col span={props.isPTask ? 2 : 2} xs={props.isPTask ? 12 : 0} sm={props.isPTask ? 12 : 0} md={props.isPTask ? 2 : 2} lg={props.isPTask ? 2 : 2} xl={props.isPTask ? 2 : 2} style={{ textAlign: isMobile ? 'end' : 'start' }}>
                        {props.isPTask ? <Tag style={{ marginLeft: 0 }} color={props.data?.pbPriority.tagColor}>{props.data?.pbPriority.name}</Tag> : null}
                    </Col>
                    {props.isPTask ? <Col span={2}>
                        <Tag>{props.isRootCause === true ? 'Root Cause Analysis' : 'General'}</Tag>
                    </Col> : null}
                    <Col span={props.isPTask ? 8 : 12} style={{ textAlign: 'end' }} xs={24} sm={24} md={props.isPTask ? 8 : 12} lg={props.isPTask ? 8 : 12} xl={props.isPTask ? 8 : 12}>
                        {props.data?.ptaskCloseReasonStatusDetail ? <Tag color={props.data?.ptaskCloseReasonStatusDetail.tagColor}>{props.data?.ptaskCloseReasonStatusDetail.name}</Tag> : null}
                        {props.data?.pbCloseReasonStatusDetail ? <Tag color={props.data?.pbCloseReasonStatusDetail.tagColor}>{props.data?.pbCloseReasonStatusDetail.name}</Tag> : null}
                        {props.data?.pbStatus === 'Assess' && !props.isPTask ? <Tag color='#ED9E28'>Awaiting Approval</Tag> : null}
                        {props.data.tagRelated ? <Dropdown overlay={menuRelate} trigger={['click']} visible={visibleRelated} onVisibleChange={handleVisibleChange} >
                            <Tag color="#FAAD14">Related</Tag>
                        </Dropdown> : null}
                        {props.data?.viewed === true ? <Tag color="green">New</Tag> : null}
                    </Col>
                </Row>
            </div>
            <div style={{ padding: 10 }}>
                {props.isPTask ? <div>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 7 : 4} xs={6} sm={6} md={props.isPTask ? 7 : 4} lg={props.isPTask ? 7 : 4} xl={props.isPTask ? 7 : 4}>
                                    <p><b>Created : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 17 : 20} xs={18} sm={18} md={props.isPTask ? 17 : 20} lg={props.isPTask ? 17 : 20} xl={props.isPTask ? 17 : 20}>
                                    <p> {props.data?.created} </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 7 : 4} xs={6} sm={6} md={props.isPTask ? 7 : 4} lg={props.isPTask ? 7 : 4} xl={props.isPTask ? 7 : 4}>
                                    <p><b>Status : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 17 : 20} xs={18} sm={18} md={props.isPTask ? 17 : 20} lg={props.isPTask ? 17 : 20} xl={props.isPTask ? 17 : 20}>
                                    <p> <Tag style={{ borderRadius: '10px' }}><Badge color={getTagColorByStatus(props.data?.pbStatus)} text={props.data?.pbStatus} /></Tag></p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Tooltip title={props.data?.assignmentGroup}>
                                <Row>
                                    <Col span={props.isPTask ? 7 : 4} xs={13} sm={13} md={props.isPTask ? 7 : 4} lg={props.isPTask ? 7 : 4} xl={props.isPTask ? 7 : 4}>
                                        <p><b>Assignment Group : </b></p>
                                    </Col>
                                    <Col span={props.isPTask ? 17 : 20} xs={11} sm={11} md={props.isPTask ? 17 : 20} lg={props.isPTask ? 17 : 20} xl={props.isPTask ? 17 : 20}>
                                        <p> {props.data?.assignmentGroup?.length > 85 ? props.data?.assignmentGroup.slice(0, 85) + '...' : props.data?.assignmentGroup} </p>
                                    </Col>
                                </Row>
                            </Tooltip>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 7 : 4} xs={9} sm={9} md={props.isPTask ? 7 : 4} lg={props.isPTask ? 7 : 4} xl={props.isPTask ? 7 : 4}>
                                    <p><b>Assigned To : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 17 : 20} xs={15} sm={15} md={props.isPTask ? 17 : 20} lg={props.isPTask ? 17 : 20} xl={props.isPTask ? 17 : 20}>
                                    <p> {props.data?.assignedTo || '-'} </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 7 : 4} xs={8} sm={8} md={props.isPTask ? 7 : 4} lg={props.isPTask ? 7 : 4} xl={props.isPTask ? 7 : 4}>
                                    <span><b>Start Date : </b></span>
                                </Col>
                                <Col span={props.isPTask ? 17 : 20} xs={16} sm={16} md={props.isPTask ? 17 : 20} lg={props.isPTask ? 17 : 20} xl={props.isPTask ? 17 : 20}>
                                    <span> {props.data?.startDate}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 7 : 4} xs={8} sm={8} md={props.isPTask ? 7 : 4} lg={props.isPTask ? 7 : 4} xl={props.isPTask ? 7 : 4}>
                                    <span><b>Due Date : </b></span>
                                </Col>
                                <Col span={props.isPTask ? 17 : 20} xs={16} sm={16} md={props.isPTask ? 17 : 20} lg={props.isPTask ? 17 : 20} xl={props.isPTask ? 17 : 20}>
                                    <span> {props.data?.endDate}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div> : <div>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 5 : 4} xs={6} sm={6} md={props.isPTask ? 5 : 4} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                    <p><b>Status : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 19 : 20} xs={18} sm={18} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                    <p> <Tag style={{ borderRadius: '10px' }}><Badge color={getTagColorByStatus(props.data?.pbStatus)} text={props.data?.pbStatus} /></Tag></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 5 : 4} xs={7} sm={7} md={props.isPTask ? 5 : 4} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                    <p><b>Priority : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 19 : 20} xs={17} sm={17} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                    <p>{props.data.pbPriority ? <Tag style={{ borderRadius: '10px' }} color={props.data.pbPriority.tagColor}>{props.data.pbPriority.name}</Tag> : null}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 5 : 4} xs={7} sm={7} md={props.isPTask ? 5 : 6} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                    <p><b>Requester : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 19 : 20} xs={17} sm={17} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                    <p> {props.data?.requesterName} </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 5 : 4} xs={6} sm={6} md={props.isPTask ? 5 : 4} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                    <p><b>Created : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 19 : 20} xs={18} sm={18} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                    <p> {props.data?.created} </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Tooltip title={props.data?.assignmentGroup}>
                                <Row>
                                    <Col span={props.isPTask ? 5 : 4} xs={12} sm={12} md={props.isPTask ? 5 : 4} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                        <p><b>Assignment Group : </b></p>
                                    </Col>
                                    <Col span={props.isPTask ? 19 : 20} xs={12} sm={12} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                        <p> {props.data?.assignmentGroup.length > 85 ? props.data?.assignmentGroup.slice(0, 85) + '...' : props.data?.assignmentGroup} </p>
                                    </Col>
                                </Row>
                            </Tooltip>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 5 : 4} xs={8} sm={8} md={props.isPTask ? 5 : 4} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                    <p><b>Assigned To : </b></p>
                                </Col>
                                <Col span={props.isPTask ? 19 : 20} xs={16} sm={16} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                    <p> {props.data?.assignedTo || '-'} </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 5 : 4} xs={7} sm={7} md={props.isPTask ? 5 : 4} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                    <span><b>Start Date : </b></span>
                                </Col>
                                <Col span={props.isPTask ? 19 : 20} xs={17} sm={17} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                    <span> {props.data?.startDate}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Row>
                                <Col span={props.isPTask ? 5 : 4} xs={6} sm={6} md={props.isPTask ? 5 : 4} lg={props.isPTask ? 5 : 4} xl={props.isPTask ? 5 : 4}>
                                    <span><b>Due Date : </b></span>
                                </Col>
                                <Col span={props.isPTask ? 19 : 20} xs={18} sm={18} md={props.isPTask ? 19 : 20} lg={props.isPTask ? 19 : 20} xl={props.isPTask ? 19 : 20}>
                                    <span> {props.data?.endDate}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>}
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {

})(ProblemRow)
