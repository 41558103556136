/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Steps,
    Select,
    InputNumber,
    Timeline,
    Icon
} from 'antd'
import DetailSignature from './DetailSignature'
import { FormComponentProps } from 'antd/lib/form'
import { StoreState } from '../../../store'
import {
    Job,
    description,
    expenses,
    jobSignatureDetail,
    updateJobStatus,
    updateJobWithExpenseCost
} from '../../Relate/Job'
import { JobWorkLog } from '../../Relate/Job/worklog'
import { connect } from 'react-redux'
import {
    addWorkLog
} from '../../Relate/Job/worklog/service'
import { useLocation } from 'react-router-dom'
import {
    AssigneesPeople,
    AssignmentGroups,
    SupportTeamList,
    WorkOrder
} from '../model'
import TableCharging from './TableCharging'
import { ChargingGroup } from '../../Relate/Charging/Model'
import { getSupportTeamUser } from '../service'
import WoSignForm from './WoSignatureForm'
import { Asset } from '../../Relate/Asset'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import { RoleType, checkRolesState } from '../../../authorization-module/permissions'

interface Param {
  isDirection: boolean;
  isSignature: boolean;
  job: Job;
  isCurrentJob: boolean;
  woId?: string;
  handleChangeStatus: (boolean) => void;
  username: string;
  isStatus: boolean;
  roleId?: number;
  vSpaceIncidentNo: string;
  getDataFromMyStep: Function;
  // getAssignedTo: Function
  User?: AssignmentGroups[];
  dataAsset?: Asset[]
  wo?: WorkOrder
}

const mapStateToProps = (state: StoreState) => {
    return {
        workLog: state.jobWorkLog || [],
        jobs: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

const pageStyle = `
  @media print {
    .page-break {
      page-break-inside: avoid;
    }
    html, body {
        -webkit-print-color-adjust: exact;
      }
    .ant-row {
        overflow-wrap: break-word;
    }

    @page {
      size: A4;
      margin: 25px;
    }
    .content-woSign {
      margin: 0;
    }
  }
`

interface DispatchProps {
  addWorkLog: (workLog: JobWorkLog) => Promise<number>;
  // getAllWorkLogByJobNo: (jobNo: string) => Promise<JobWorkLog[] | undefined>
}

type Props = Param & FormComponentProps & StateProps & DispatchProps;

const { Step } = Steps
const { Option } = Select
let descriptionList: description[] = []
const MyStep: React.FC<Props> = (props: Props) => {
    const [currentStatus, setCurrentStatus] = useState<number>(0)
    const [visibleApprove, setVisibleApprove] = useState<boolean>(false)
    const [visibleSignature, setVisibleSignature] = useState<boolean>(false)
    const [visibleDetailWO, setVisibleDetailWO] = useState<boolean>(false)
    const jobStatus = props.job.jobStatus
    const jobTopic = props.job.jobTopic
    const [isDisableOpen, setIsDisableOpen] = useState<boolean>(false)
    const [isDisableInProgress, setIsDisableInProgress] = useState<boolean>(false)
    const [isDisableCancel, setIsDisableCancel] = useState<boolean>(false)
    const [isAddWorkLog, setIsAddWorkLog] = useState<boolean>(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
    const [User, setUser] = useState<AssignmentGroups[]>(props.User || [])
    const [AssignedTo, setAssignedTo] = useState<AssigneesPeople[]>([])
    const [AssignedGroup, setAssignedGroup] = useState<SupportTeamList[]>([])
    const location = useLocation()
    const [username, setUsername] = useState<string>('')
    const [expenseList] = useState<ChargingGroup>({
        chargeType: '',
        chargingList: [],
        total: 0
    })
    const [costTotal, setCostTotal] = useState<number>(0)
    const [updateSignatureWO, setUpdateSignatureWO] = useState<jobSignatureDetail[]>()
    const [tempExpenseWhenSaved, setTempExpenseWhenSaved] = useState<expenses[]>()
    let newCost: any = 0
    let newExpenseData: expenses[] = props.job.expenses || []
    const {
        getFieldDecorator,
        validateFields,
        getFieldValue,
        setFieldsValue,
        resetFields
    } = props.form
    const [tempDescriptionList, setTempDescriptionList] = useState<description[]>([])
    const [isShowSignForm, setIsShowSignForm] = useState<boolean>(false)

    const componentRef = useRef(null)

    useEffect(() => {
        if (props.User) {
            setUser(props.User)
            console.log(User)
        }
        getSupportTeamUser()
            .then((res) => {
                setAssignedGroup(res)
            })
            .catch((err) =>
                message.error(`Failed loading initial TicketNumber data. ${err}`)
            )
        if (location.pathname.includes('/WoForm')) {
            newExpenseData = []
            descriptionList = []
        }
        if (location.pathname.includes('/WoFormEdit')) {
            if (props.job) {
        props.job.expenses?.forEach((data) => {
            return (newCost += data.cost)
        })
        props.job.expenses?.map((it) => {
            const tempData: expenses = {
                expenseDetail: it.expenseDetail,
                cost: it.cost
            }
            return newExpenseData.push(tempData)
        })
        props.job.descriptions?.forEach((it) => {
            const tempDescription: description = {
                description: it.description,
                id: it.id
            }
            descriptionList.push(tempDescription)
        })
        descriptionList.sort((a, b) => b.id!! - a.id!!)
        setTempDescriptionList(descriptionList)
        setCostTotal(newCost)
        setFieldsValue({
            assignmentGroup: props.job.supportTeam || undefined,
            assignedTo: props.job.assignee || undefined
        })
            }
        }
        if (jobStatus) {
            if (jobStatus === 'Open') {
                setCurrentStatus(0)
            }
            if (jobStatus === 'InProgress') {
                setCurrentStatus(1)
            }
            if (jobStatus === 'Closed') {
                setCurrentStatus(2)
            }
            if (jobStatus === 'Cancelled') {
                setCurrentStatus(3)
            }
        }
        // fetchWorkLogData()
        checkCurrentStatusToDisable()
        const user = sessionStorage.getItem('username')
        setUsername(user!!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
    // create
        if (currentStatus === 0) {
            setIsDisableOpen(true)
            setIsDisableCancel(true)
        }
        // open
        if (currentStatus === 1) {
            setIsDisableOpen(true)
            setIsDisableCancel(false)
        }
        // in progress
        if (currentStatus === 2) {
            setIsDisableInProgress(true)
            setIsDisableCancel(true)
        }
        // close
        if (currentStatus === 3) {
            setIsDisableOpen(true)
            setIsDisableInProgress(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isCurrentJob, currentStatus])

    useEffect(() => {
        if (AssignedGroup) {
            (AssignedGroup || []).forEach((data) => {
                if (data.name === getFieldValue('assignmentGroup')) {
                    setAssignedTo(
                        data.assignees.filter((it) => it.fullName?.length !== 0)
                    )
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getFieldValue('assignmentGroup'),
        getFieldValue('assignedTo'),
        AssignedGroup
    ])

    useEffect(() => {
        if (visibleApprove) {
            showConfirm(jobStatus)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleApprove])

    const onChange = (newStatus) => {
        if (currentStatus < newStatus && newStatus !== 2) {
            if (newStatus === 1) {
                setVisibleApprove(true)
            }
        } else {
            if (
                newStatus === 2 &&
        props.isSignature &&
        ((jobTopic === 'Replace' &&
          props.job.attachedSignatureReplace === null) ||
          (jobTopic === 'Return' && props.job.attachedSignatureReturn === null))
            ) {
                setVisibleSignature(true)
                setVisibleApprove(false)
            } else {
                if (props.job.jobSignature || updateSignatureWO) {
                    const findJobSignatureNoSignature = (props.job.jobSignature?.jobSignatureDetail || updateSignatureWO)?.find((data) => { return data.signaturePath === null })
                    if (findJobSignatureNoSignature) {
                        warningForSign()
                    } else {
                        setVisibleApprove(true)
                    }
                } else {
                    setVisibleApprove(true)
                }
            }
        }
    }

    const showConfirm = (status) => {
        Modal.confirm({
            title: <span style={{ color: '#323276', fontSize: '22px', fontWeight: 'bold'}}>Are you sure ?</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>{status === 'InProgress' ? 'Do you want to change status as closed ?' : 'Do you want to change status as in progress ?'}</span>,
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            className: 'custom-modal-wo',
            onOk: handleOkApprove,
            onCancel: handleCancelApprove
        })
    }

    const updateDescription = () => {
        validateFields(['description'], (error, values) => {
            if (!error && values.description !== '') {
                setIsAddWorkLog(false)
                console.log(isAddWorkLog)
                setIsSubmitLoading(true)
                const tempDescription: description = {
                    description: values.description ? values.description.replace(/ +/g, ' ').trim() : undefined
                }
                const findAssigneeId = AssignedTo.find((user) => { return user.fullName === props.job.assignee })
                // setDescription(values.description);
                // descriptionList.push(tempDescription);
                descriptionList.splice(0, 0, tempDescription)
                const jobData: Job = {
                    jobStatus: props.job.jobStatus,
                    woId: props.job.woId,
                    jobNo: props.job.jobNo,
                    jobTopic: jobTopic,
                    supportTeam: props.job.supportTeam,
                    assignee: props.job.assignee,
                    assigneeId: findAssigneeId?.id || undefined,
                    expenses: [],
                    notify: props.job.notify,
                    descriptions: descriptionList,
                    approverName: props.job.approverName,
                    approverReason: props.job.approverReason
                }
                updateJobWithExpenseCost(props.job.id, jobData)
                    .then((res) => {
                        console.log('Res : ', res)
                        message.success('Update Description Successfully')
                        setIsSubmitLoading(false)
                        resetFields(['description'])
                    })
                    .catch((err) => {
                        console.log(err)
                        setIsSubmitLoading(false)
                    })
            } else {
                message.warning('Please input Job (Description)')
            }
        })
    }

    const AddExpense = () => {
        validateFields(['Expense', 'Cost'], (error, values) => {
            if (!error && values.Cost) {
                const newData: expenses = {
                    expenseDetail: values.Expense ? values.Expense.replace(/ +/g, ' ').trim() : undefined,
                    cost: values.Cost
                }
                setCostTotal(values.Cost + costTotal)
                newExpenseData.push(newData)
                resetFields(['Expense', 'Cost'])
            }
        })
    }

    const handleOk = () => {
        setVisibleApprove(false)
        setVisibleSignature(false)
        setVisibleDetailWO(true)
    }

    const isCloseStep = (value) => {
        if (value) {
            const jobData = {
                lastModifiedBy: username,
                jobStatus: 'Closed'
            }
            updateJobStatus(props.job.id!!, jobData)
                .then(() => {
                    setCurrentStatus(2)
                    props.handleChangeStatus(!props.isStatus)
                    message.success('The Update has finished successfully.')
                })
                .catch((err) => {
                    setVisibleApprove(false)
                    setVisibleDetailWO(true)
                    message.error(`You have unSuccessfully save the data. ${err}`)
                })
        }
    }

    const handleCancel = () => {
        setCurrentStatus(1)
        setVisibleApprove(false)
        setVisibleSignature(false)
    }

    const handleCancelApprove = () => {
        setVisibleApprove(false)
    }

    const handleOkDetailWO = () => {
        setVisibleDetailWO(false)
        checkCurrentStatusToDisable()
    }

    const handleCancelDetailWO = () => {
        setVisibleDetailWO(false)
    }

    const warningForSign = () => {
        Modal.warning({
            title: <span style={{ color: '#323276', fontSize: '22px', fontWeight: 'bold'}}>Warning</span>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>Please Signature Document form before close status</span>,
            className: 'custom-modal-wo'
        })
    }

    const handleOkApprove = () => {
        const findAssigneeId = AssignedTo.find((user) => { return user.fullName === props.job.assignee })
        const jobData: Job = {
            id: props.job.id,
            createdBy: props.job.createdBy,
            createdByDisplay: props.job.createdByDisplay,
            createdDatetime: props.job.createdDatetime,
            createdDate: props.job.createdDate,
            lastModifiedByDisplay: props.job.lastModifiedByDisplay,
            lastModifiedDate: props.job.lastModifiedDate,
            lastModifiedDatetime: props.job.lastModifiedDatetime,
            lastModifiedBy: username,
            jobStatus: '',
            woId: props.job.woId,
            jobNo: props.job.jobNo,
            jobTopic: jobTopic,
            supportTeam: props.job.supportTeam,
            assignee: props.job.assignee,
            assigneeId: findAssigneeId?.id || undefined,
            expense: props.job.expense,
            descriptions: descriptionList,
            approverName: props.job.approverName,
            approverReason: props.job.approverReason
        }
        if (currentStatus === 0) {
            const assignedTo = getFieldValue('assignedTo')?.split(':')[1]?.trim()
            const assignmentGroup = getFieldValue('assignmentGroup')
            const findSupportTeamId = AssignedGroup?.find((group) => { return group.name === assignmentGroup })
            const findAssigneeId = findSupportTeamId?.assignees?.find((assignee) => { return assignee.fullName === assignedTo })
            jobData.jobStatus = 'InProgress'
            jobData.supportTeam = assignmentGroup
            jobData.assignmentGroupId = findAssigneeId?.supportTeamId
            jobData.assignee = assignedTo
            jobData.assigneeId = findAssigneeId?.id
            updateJobStatus(props.job.id!!, jobData)
                .then(() => {
                    setVisibleApprove(false)
                    setCurrentStatus(1)
                    props.handleChangeStatus(!props.isStatus)
                    message.success('The Update has finished successfully.')
                })
                .catch((err) => {
                    setVisibleApprove(false)
                    message.error(`You have unSuccessfully save the data. ${err}`)
                })
        } else if (currentStatus === 1) {
            resetFields(['description', 'Expense', 'Cost'])
            jobData.jobStatus = 'Closed'
            jobData.expenses = tempExpenseWhenSaved || newExpenseData
            updateJobStatus(props.job.id!!, jobData)
                .then(() => {
                    setVisibleApprove(false)
                    setCurrentStatus(2)
                    props.handleChangeStatus(!props.isStatus)
                    message.success('The Update has finished successfully.')
                    setTempExpenseWhenSaved([])
                })
                .catch((err) => {
                    if (err?.response?.status === 409) {
                        setVisibleApprove(false)
                        warningForSign()
                    } else {
                        setVisibleApprove(false)
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    }
                })
        }
    }

    const handleCloseModal = (isClose: boolean) => {
        setVisibleDetailWO(isClose)
    }

    const checkCurrentStatusToDisable = () => {
        if (props.isCurrentJob && props.job.id) {
            if (currentStatus >= 0) {
                setIsDisableOpen(true)
                setIsDisableCancel(true)
            } else if (currentStatus >= 1) {
                setIsDisableInProgress(true)
            } else if (currentStatus >= 2) {
                setIsDisableInProgress(true)
                setIsDisableCancel(true)
            }
        } else {
            setIsDisableOpen(false)
            setIsDisableInProgress(false)
            setIsDisableCancel(false)
        }
    }

    const checkToDisableAssetApproval = () => {
        const filterJob = props.jobs.filter((job) => job.jobStatus === 'Closed')
        return filterJob.length < 2
    }

    const changeGroup = (selectValue) => {
        props.form.setFieldsValue({
            assignedTo: undefined
        })
    }

    // const changeAssignTo = (selectValue) => {
    //   console.log('select : ',selectValue)
    //   if (selectValue) {
    //     props.getDataFromMyStep(
    //       props.job.jobNo,
    //       getFieldValue("assignmentGroup"),
    //       selectValue
    //     );
    //   } else {
    //     props.getDataFromMyStep(
    //       props.job.jobNo,
    //       getFieldValue("assignmentGroup"),
    //       null
    //     );
    //   }
    // };

    const saveAssignTo = () => {
        const assignedTo = getFieldValue('assignedTo')
        const assignmentGroup = getFieldValue('assignmentGroup')
        if (assignedTo) {
            props.getDataFromMyStep(props.job.jobNo, assignmentGroup, assignedTo?.split(':')[1]?.trim())
        } else {
            props.getDataFromMyStep(props.job.jobNo, assignmentGroup, null)
        }
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(CheckValue)) {
                if (CheckValue.length >= 1) {
                    if (newExpenseData) {
                        const expenseListData = newExpenseData.map((value) => {
                            return value.expenseDetail
                        })
                        if (expenseListData?.includes(value.trim())) {
                            callback('expense is already exists. It must be unique. Please try another one.')
                        } else {
                            callback()
                        }
                    }
                } else {
                    callback('Enter more than 1 characters')
                }
            } else {
                if (format.test(CheckValue)) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 1 characters')
                }
            }
        } else {
            callback()
        }
    }

    const validateDescription = (_: any, value: any, callback) => {
        if (value) {
            const CheckValue = value.replace(/ +/g, '').trim()
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(CheckValue)) {
                if (CheckValue.length >= 1) {
                    callback()
                } else {
                    callback('Enter more than 1 characters')
                }
            } else {
                if (format.test(CheckValue)) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 1 characters')
                }
            }
        } else {
            callback()
        }
    }

    const updateExpenseCost = () => {
        const jobData: Job = {
            jobStatus: props.job.jobStatus,
            woId: props.job.woId,
            jobNo: props.job.jobNo,
            jobTopic: jobTopic,
            supportTeam: props.job.supportTeam,
            assignee: props.job.assignee,
            expenses: newExpenseData,
            notify: props.job.notify,
            descriptions: descriptionList,
            approverName: props.job.approverName,
            approverReason: props.job.approverReason
        }
        const findAssigneeId = AssignedTo.find((user) => { return user.fullName === props.job.assignee })
        jobData.assigneeId = findAssigneeId?.id || undefined
        console.log('Body Send : ', jobData)
        updateJobWithExpenseCost(props.job.id, jobData)
            .then((res) => {
                console.log('Res : ', res)
                setTempExpenseWhenSaved(res.expenses)
                message.success('Update Expense Successfully')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const deleteExpenseCost = (value: expenses[], totalcost: number) => {
    // expenseDataList = value
        props.job.expenses = value
        setCostTotal(totalcost)
    }

    const showModalSignForm = () => {
        setIsShowSignForm(true)
    }

    const getShowModal = (value) => {
        setIsShowSignForm(value)
    }

    const getSignature = (value) => {
        console.log(value)
        setUpdateSignatureWO(value)
    }

    return jobTopic === 'Approve' ? (
        <>
            <Steps
                current={currentStatus}
                onChange={onChange}
                direction={props.isDirection ? 'horizontal' : 'vertical'}
            >
                <Step
                    title="Awaiting Approval"
                    description={
                        <>
                            <Form>
                                <Row>
                                    <Col xs={24} xl={12} span={8} style={{ textAlign: 'left' }}>
                                        <Form.Item>
                                            <Button
                                                type="default"
                                                onClick={() => setVisibleDetailWO(true)}
                                                disabled={checkToDisableAssetApproval()}
                                            >
                        View Asset Approval
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    }
                />
            </Steps>
            {props.isSignature ? (
                <Modal
                    visible={visibleDetailWO}
                    onOk={handleOkDetailWO}
                    onCancel={handleCancelDetailWO}
                    footer={null}
                    width={'auto'}
                    style={{ padding: '5%', minWidth: 1024 }}
                >
                    <DetailSignature
                        isVisible={visibleDetailWO}
                        woId={props.woId || ''}
                        idJob={props.job.id!!}
                        JobTopic={props.job.jobTopic}
                        isCloseStep={isCloseStep}
                        dataRelateAsset={[]}
                        handleCloseModal={handleCloseModal}
                        isApprove={true}
                        vSpaceIncidentNo={props.vSpaceIncidentNo}
                    />
                </Modal>
            ) : null}
        </>
    ) : (
        <>
            <Form>
                {!props.isDirection ? (
                    <div style={{ padding: 30 }}>
                        <Form.Item label="Assignment Group" className='custom-formLabel-wo'>
                            {getFieldDecorator('assignmentGroup', {
                                initialValue: props.job.supportTeam || undefined
                            })(
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select an Assignment Group"
                                    disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || jobStatus === 'Created' || currentStatus >= 1}
                                    onChange={(e) => changeGroup(e)}
                                >
                                    {AssignedGroup.map((it, index) => {
                                        return (
                                            <Option value={it.name} key={index}>
                                                {it.name}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Assigned To" className='custom-formLabel-wo'>
                            {getFieldDecorator('assignedTo', {
                                initialValue: props.job.assignee
                            })(
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Select an Assigned To"
                                    disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || jobStatus === 'Created' || currentStatus >= 1}
                                    // onChange={(e) => changeAssignTo(e)}
                                    allowClear
                                >
                                    {(AssignedTo || []).map((it, index) => {
                                        return (
                                            <Option value={it.id + ' : ' + it.fullName} key={index}>
                                                {it.employeeId + ' : ' + it.fullName}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <Col style={{ textAlign: 'end', paddingRight: '-8px' }}>
                            <Button
                                type='primary'
                                disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || props.wo?.status === 'Cancelled' || jobStatus === 'Created' || props.job.jobStatus === 'Cancelled' || currentStatus >= 1}
                                onClick={saveAssignTo}
                            >
                              Save
                            </Button>
                        </Col>
                    </div>
                ) : null}
                <Steps
                    current={currentStatus}
                    onChange={onChange}
                    direction={props.isDirection ? 'horizontal' : 'vertical'}
                >
                    <Step
                        title={<span style={{ color: '#323276', fontSize: '14px', fontWeight: 'bold' }}>Open</span>}
                        disabled={props.job.jobStatus === 'Created' ? true : isDisableOpen}
                        style={{ paddingBottom: props.isDirection ? undefined : 25 }}
                        description={'Click step 2 to InProgress'}
                    />
                    <Step
                        title={<span style={{ color: '#323276', fontSize: '14px', fontWeight: 'bold' }}>InProgress</span>}
                        description={
                            <>
                                <Row>
                                    <Row style={{ marginBottom: 5 }}>
                                        <Col xs={12} lg={8} xl={12}></Col>
                                        {(!checkRolesState(RoleType.WO, 'UpdateJob') || currentStatus === 1 || currentStatus === 2) && props.job.jobSignature !== null
                                            ? <Col xs={12} lg={16} xl={12} style={{ textAlign: 'right' }}>
                                                <Button style={{ textAlign: 'right' }} type="default" onClick={showModalSignForm}>View Document Form</Button>
                                            </Col>
                                            : null}
                                    </Row>
                                    <Row>
                                        {currentStatus === 1 ? <p style={{ fontSize: '14px', fontWeight: 'bold', color: '#344054' }}>Description</p> : null}
                                        <Col xs={21} xl={21} lg={18} style={{ paddingRight: 10 }}>
                                            <Form.Item>
                                                {getFieldDecorator('description', {
                                                    initialValue: '',
                                                    rules: [{
                                                        required: false
                                                    }, {
                                                        validator: validateDescription
                                                    }]
                                                })(
                                                    <Input
                                                        placeholder="This is description"
                                                        style={{ width: props.isDirection ? 250 : '100%' }}
                                                        maxLength={255}
                                                        disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || currentStatus !== 1}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={3} xl={3} span={6} style={{ textAlign: 'right' }}>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="button"
                                                    disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || currentStatus !== 1 || props.form.getFieldValue('description')?.length === 0 || props.wo?.status === 'Cancelled'}
                                                    onClick={updateDescription}
                                                    loading={isSubmitLoading}
                                                >
                                                    Add
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Row>
                                {tempDescriptionList.length > 0 ? (
                                    <>
                                        {tempDescriptionList.map((it, index) => {
                                            const workLogDescriptions: JSX.Element[] = []
                                            workLogDescriptions.push(
                                                <Timeline.Item>{it.description}</Timeline.Item>
                                            )
                                            return (
                                                <Timeline key={index}>
                                                    {workLogDescriptions.map((temps: any, i: number) => {
                                                        return <div key={i}>{temps}</div>
                                                    })}
                                                </Timeline>
                                            )
                                        })}
                                    </>
                                ) : null}
                                {props.job.expense && (currentStatus === 1 || currentStatus === 2) ? (
                                    <>
                                        {
                                            <div>
                                                <Row gutter={8}>
                                                    <Col span={11} xxl={10} xl={10} lg={9} md={10} style={{ paddingRight: 20 }}>
                                                        <Form.Item label="Expense" className='custom-formLabel-wo'>
                                                            {getFieldDecorator('Expense', {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter expense'
                                                                    },
                                                                    { validator: validateNameUniqueness }
                                                                ]
                                                            })(
                                                                <Input
                                                                    disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || currentStatus !== 1}
                                                                    placeholder="Expense"
                                                                    style={{ width: '110%' }}
                                                                    maxLength={100}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={11} xxl={10} xl={10} lg={9} md={10} style={{ paddingRight: 10, paddingLeft: 8 }}>
                                                        <Form.Item label="Cost" className='custom-formLabel-wo'>
                                                            {getFieldDecorator('Cost', {
                                                                initialValue: 0,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        validator: (_, value) => {
                                                                            if (value >= 0) {
                                                                                return Promise.resolve()
                                                                            } else {
                                                                                return Promise.reject(
                                                                                    'Please enter cost, Cost cannot be less than 0'
                                                                                )
                                                                            }
                                                                        }
                                                                    }
                                                                ]
                                                            })(
                                                                <InputNumber
                                                                    disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || currentStatus !== 1}
                                                                    size="default"
                                                                    style={{ width: '110%' }}
                                                                    placeholder="Cost"
                                                                    min={0}
                                                                    step={1}
                                                                    maxLength={30}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2} xs={3} xxl={4} xl={4} lg={6} md={4} style={{ marginTop: 27, textAlign: 'right' }}>
                                                        <Form.Item>
                                                            <Button
                                                                disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || props.wo?.status === 'Cancelled' || currentStatus !== 1 || props.form.getFieldValue('Expense')?.length === undefined || props.form.getFieldValue('Expense')?.length === 0 || props.form.getFieldValue('Cost')?.length === 0 || props.form.getFieldValue('Cost') === 0}
                                                                type="primary"
                                                                htmlType="button"
                                                                onClick={AddExpense}
                                                            >
                                Add
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <TableCharging
                                                    data={expenseList}
                                                    Data={newExpenseData}
                                                    isExpense={true}
                                                    deletedata={deleteExpenseCost}
                                                    currentStep={currentStatus}
                                                />
                                                <Row
                                                    style={{ padding: 10, backgroundColor: '#E5E5E5' }}
                                                >
                                                    <Col style={{ color: '#828282' }} span={12}>
                                                        {/* <span>Summary Amount</span> */}
                                                    </Col>
                                                    <Col style={{ textAlign: 'end' }} span={12}>
                                                        <span style={{ color: '#323276', marginRight: 15 }}>
                              Total
                                                        </span>
                                                        <span>{costTotal.toFixed(2) || 0}</span>
                                                        <span style={{ color: '#323276', marginLeft: 3 }}>
                              THB
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Col style={{ textAlign: 'end', paddingRight: 0 }}>
                                                    <Button
                                                        disabled={!checkRolesState(RoleType.WO, 'UpdateJob') || currentStatus !== 1}
                                                        style={{
                                                            marginTop: 20,
                                                            display:
                                newExpenseData.length > 0 ||
                                descriptionList.length > 0
                                    ? 'inline'
                                    : 'none'
                                                        }}
                                                        type="primary"
                                                        htmlType="button"
                                                        onClick={updateExpenseCost}
                                                    >
                            Save
                                                    </Button>
                                                </Col>
                                            </div>
                                        }
                                    </>
                                ) : null}
                            </>
                        }
                        disabled={
                            !checkRolesState(RoleType.WO, 'UpdateJob') || props.job.jobStatus === 'Created' || props.wo?.status === 'Cancelled' ? true : isDisableInProgress
                        }
                    />
                    <Step
                        title={<span style={{ color: '#323276', fontSize: '14px', fontWeight: 'bold' }}>Closed</span>}
                        disabled={
                            !checkRolesState(RoleType.WO, 'UpdateJob') || props.job.jobStatus === 'Created' || props.wo?.status === 'Cancelled' ? true : isDisableCancel
                        }
                        description="Click step 3 to Close Job"
                    />
                </Steps>
            </Form>
            <Modal
                title="Signature"
                visible={visibleSignature}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={'Signature'}
            >
                <p>Please signature before Close status</p>
            </Modal>

            {/* {visibleApprove ? showConfirm(jobStatus) : null} */}
            {props.isSignature ? (
                <Modal
                    visible={visibleDetailWO}
                    onOk={handleOkDetailWO}
                    onCancel={handleCancelDetailWO}
                    footer={null}
                    width={'auto'}
                    style={{ padding: '5%', minWidth: 1024 }}
                >
                    <DetailSignature
                        isVisible={visibleDetailWO}
                        woId={props.woId || ''}
                        idJob={props.job.id!!}
                        JobTopic={props.job.jobTopic}
                        isCloseStep={isCloseStep}
                        dataRelateAsset={[]}
                        handleCloseModal={handleCloseModal}
                        isApprove={
                            props.job.jobTopic === 'Approve' &&
              props.job.jobStatus !== 'Closed'
                        }
                        vSpaceIncidentNo={props.vSpaceIncidentNo}
                    />
                </Modal>
            ) : null}
            <Modal
                visible={isShowSignForm}
                closable={false}
                onCancel={() => setIsShowSignForm(false)}
                footer={null}
                style={{ minWidth: 1024 }}
            >
                <div ref={componentRef} style={{ fontFamily: 'sans-serif' }}>
                    <WoSignForm woId={props.woId} dataJobSignature={props.job.jobSignature} dataAsset={props.dataAsset} dataJob={props.job} isCloseModal={getShowModal} isOpen={isShowSignForm} form={props.form} getSignature={getSignature} wo={props.wo}/>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 13, textAlign: 'end', marginTop: 30 }}>
                    <Button style={{ marginRight: 5 }} onClick={() => setIsShowSignForm(false)}>
                        Close
                    </Button>
                    <ReactToPrint
                        trigger={() => <div>
                            <Button style={{ color: '#fa541c', border: '1px solid #fa541c', marginRight: '24px' }}>
                                <Icon type="upload" /> Export
                            </Button> </div>}
                        content={() => componentRef.current}
                        pageStyle={pageStyle}
                        copyStyles={true}
                        documentTitle={props.job.id + '_' + 'SignatureForm' + '_' + moment().format('YYYY-MM-DD')}
                    />
                </div>

            </Modal>
        </>
    )
}

const MyJobStep = Form.create<Props>({})(MyStep)

export default connect(mapStateToProps, {
    addWorkLog: addWorkLog
})(MyJobStep)
