import React, { useEffect, useState } from 'react'
import { ColumnNormal } from '../../ReportComponent/components/Model'
import PageIncidentService from './PageIncidentService'
import { Table } from 'antd'

interface Param {
    dataTransfer: any[]
    columnsTicketRelateKMList: string[]
    columnsTicketRelateKMObjList: ColumnNormal[]
    total: number
    range: any
    handleTicketRelateKMChange: Function
}

type Props = Param
const ColumnTicketRelateKM: React.FC<Props> = (props: Props) => {
    const [tempColumns, setTempColumns] = useState<ColumnNormal[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    useEffect(() => {
        if (props.columnsTicketRelateKMList && props.columnsTicketRelateKMObjList) {
            const temp: ColumnNormal[] = []
            props.columnsTicketRelateKMList.forEach((it) => {
                const findDataIndex = props.columnsTicketRelateKMObjList.find((data) => data.dataIndex === it)
                if (findDataIndex) {
                    temp.push(findDataIndex)
                }
            })
            setTempColumns(temp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.columnsTicketRelateKMList, props.columnsTicketRelateKMObjList])

    useEffect(() => {
        if (pageSize) {
            PageIncidentService.getInstance().data.pageTicketRelateKM = 1
            setPage(1)
            props.handleTicketRelateKMChange()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize])

    useEffect(() => {
        if (!props.range) {
            setPage(1)
            PageIncidentService.getInstance().data.pageTicketRelateKM = 1
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.range])

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        PageIncidentService.getInstance().data.pageTicketRelateKM = page
        props.handleTicketRelateKMChange()
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        PageIncidentService.getInstance().data.pageSizeTicketRelateKM = pagination.pageSize
    }

    return (
        <div>
            <Table scroll={{x: true}} dataSource={tempColumns.length === 0 ? [] : props.dataTransfer} columns={tempColumns}
                pagination={ props.range && props.columnsTicketRelateKMList.length !== 0 ? {
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '40'],
                    total: props.dataTransfer.length !== 0 ? props.total : 0,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                } : false}
                onChange={handleTableChange}
                className="ReportTable" rowKey={'serialNumber'}/>
        </div>
    )
}

export default ColumnTicketRelateKM
