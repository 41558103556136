import { axiosGet, axiosPost } from './rest'

export const getFileUrl = (path: string) => {
    return `${process.env.REACT_APP_BASE_URL_FSS}?path=${path}`
}

export const getFile = async (path: string) => {
    try {
        const response = await axiosGet(`/path=${path}`)
        return response
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const postUploadFile = async (formData: FormData) => {
    try {
        const response = await axiosPost('/', formData)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
