const CryptoJS = require('crypto-js')
const chunk = require('chunk')

function hexToAscii(str1) {
    const hex = str1.toString()
    let str = ''
    for (let n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16))
    }
    return str
}

function asciiToHex(str) {
    const arr1: any[] = []
    for (let n = 0, l = str.length; n < l; n++) {
        const hex = Number(str.charCodeAt(n)).toString(16)
        arr1.push(hex)
    }
    return arr1.join('')
}

function chunkString(str) {
    const length = str?.length / 4
    const sortingIndexs = [2, 1, 0, 3]
    const chunkStr = chunk(str, length)
    return sortingIndexs
        .map((index) => {
            return chunkStr[index]
        })
        .join('')
}

const salt = 'G@Service'
// eslint-disable-next-line quotes
const password = `8;k,x]vf4ypl^'l6f`

// keySize - WordArray object represents an array of 32-bit words
// keySize- 256 bit/32 bytes
const secretKey = CryptoJS.PBKDF2(password, salt, {
    keySize: 256 / 32,
    iterations: 65536
})

// -------------encrypt AES-256 ----------------------------------
export const encryptBody = (text: string) => {
    const ivRandom = CryptoJS.lib.WordArray.random(16)
    const encrypted = CryptoJS.AES.encrypt(text, secretKey, {
        iv: ivRandom,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    // แปลงเป็น Base64 เพื่อเปลี่ยนข้อมูลต้นฉบับไปเป็นข้อความ หรือข้อมูลชุดใหม่ ที่ไม่สามารถอ่าน
    const cipherWordToStrBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64)
    const ivAndcipher = CryptoJS.enc.Hex.stringify(ivRandom) + asciiToHex(cipherWordToStrBase64)
    const chunkIvAndcipher = chunkString(ivAndcipher || '')
    // console.log('chunkIvAndcipher', chunkIvAndcipher)
    return chunkIvAndcipher
}

// ------------------decrypt AES-256 -----------------
export const decryptBody = (hash) => {
    const responseCiphertext = hash
    const responseNewCiphertext = chunkString(responseCiphertext || '')
    // console.log('responseNewCiphertext', responseNewCiphertext)

    const iv = responseNewCiphertext.slice(0, 32)
    const ciphertext = hexToAscii(responseNewCiphertext.slice(32))
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
    })

    const decrypted = CryptoJS.AES.decrypt(cipherParams, secretKey, {
        iv: CryptoJS.enc.Hex.parse(iv)
    })

    const decryptedToStr = decrypted.toString(CryptoJS.enc.Utf8) || ''

    // console.log('decryptedToStr : ', decryptedToStr)
    if (decryptedToStr.startsWith('[') || decryptedToStr.startsWith('{')) {
        return JSON.parse(decryptedToStr) || ''
    } else {
        return decryptedToStr
    }
    // return JSON.parse(decryptedToStr) || ''
}

export const decryptBodyToken = (hash) => {
    const responseCiphertext = hash
    const responseNewCiphertext = chunkString(responseCiphertext || '')
    // console.log('responseNewCiphertext', responseNewCiphertext)

    const iv = responseNewCiphertext.slice(0, 32)
    const ciphertext = hexToAscii(responseNewCiphertext.slice(32))
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(ciphertext)
    })

    const decrypted = CryptoJS.AES.decrypt(cipherParams, secretKey, {
        iv: CryptoJS.enc.Hex.parse(iv)
    })

    const decryptedToStr = decrypted.toString(CryptoJS.enc.Utf8) || ''
    // console.log('decryptedToStr', decryptedToStr)
    return decryptedToStr || ''
}

// const data = [{
//     ticketNumber: 'INC220000008',
//     requesterName: 'Onleta chumroen',
//     impact: 'Low',
//     urgency: 'Low',
//     priority: 'Low',
//     channel: 'E-mail',
//     ticketType: 'Incident',
//     topic: 'I am not able to configure Exchange Online to send email from my web application',
//     description: 'I am not able to configure Exchange Online to send email from my web application',
//     status: 'New',
//     pendingUntil: 'null',
//     createdDatetime: '2022-08-31 10:09:09.0',
//     createdBy: 'choco.banana.coffee@gmail.com',
//     lastModifiedDatetime: '2022-08-31 10:09:09.0',
//     lastModifiedBy: 'choco.banana.coffee@gmail.com'
// }, {
//     ticketNumber: 'INC220000009',
//     requesterName: 'Onleta chumroen',
//     impact: 'Low',
//     urgency: 'Low',
//     priority: 'Low',
//     channel: 'E-mail',
//     ticketType: 'Incident',
//     topic: 'I am not able to configure Exchange Online to send email from my web application',
//     description: 'I am not able to configure Exchange Online to send email from my web application',
//     status: 'New',
//     pendingUntil: 'null',
//     createdDatetime: '2022-08-31 10:09:09.0',
//     createdBy: 'choco.banana.coffee@gmail.com',
//     lastModifiedDatetime: '2022-08-31 10:09:09.0',
//     lastModifiedBy: 'choco.banana.coffee@gmail.com'
// }]

// const en = encryptBody(JSON.stringify(data))
// console.log(en)
// const de = decryptBody(en)
// console.log(de)
