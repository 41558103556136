import { Card, Col, DatePicker, Icon, message, Row, Select, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Space } from '../../common-components/Space'
import WoCardContainner from './wo-dashboard-component/WoCardContainner'
import { colorWoDashboardStaus, woDashboardData, woDashboardOverview, woDashboardTeam, woDataTotal } from './model'
import StackBarChart from '../../msp-portal/Chart/StackBarChart'
import LineBarFillChart from '../../msp-portal/Chart/LineBarFillChart'
import { DataForChart, getOverviewWorkOrderDashboard, getWorkOrderDashboardTeam } from '../../msp-portal'
import DoughnutChart from '../../msp-portal/Chart/DoughnutChart'
import moment from 'moment'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { getAllPriority, PriorityLevel } from '../../incident-management/priority'
const mapStateToProps = (state: StoreState) => {
    return {
        priority: state.priorities
    }
}

type StateProps = ReturnType<typeof mapStateToProps>
interface DispatchProps {
    getAllPriority: () => Promise<number>
}

type Props = DispatchProps & StateProps
const { Option } = Select
const { RangePicker } = DatePicker
const WoDashboard = (props : Props) => {
    const [ticketType, setTicketType] = useState<string>('Incident')
    const [created, setCreated] = useState<string>('last30Days')
    const [filterGroup] = useState<string>('All Group')
    const [timePeriod, setTimePeriod] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [dataWoDashboard, setDataWoDashboard] = useState<woDashboardOverview>()
    const [dataWoDashboardTeam, setDataWoDashboardTeam] = useState<woDataTotal[]>([])
    const primaryColor = ['#323276', '#FA5A7D', '#FF947A', '#33B8D7', '#4DDC7D', '#BF83FF', '#98A2B3']
    const backgroundColor = ['#DFDFF6', '#FFE2E5', '#FFF4DE', '#D8F2F8', '#E2F9EA', '#F3E8FF', '#E7E7E7']
    const label24Hours = ['00.00', '01.00', '02.00', '03.00', '04.00', '05.00', '06.00', '07.00', '08.00', '09.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00', '19.00', '20.00', '21.00', '22.00', '23.00']
    const isMobile = window.innerWidth < 500
    const [priority, setPriority] = useState<PriorityLevel[]>([])

    useEffect(() => {
        if (created !== 'anytime' || (created === 'anytime' && timePeriod.length)) {
            setIsLoading(true)
            getOverviewWorkOrderDashboard(ticketType, created, timePeriod).then((res) => {
                setDataWoDashboard(res)
            }).catch(() => {
                message.error('Fail to load data')
            }).finally(() => {
                setIsLoading(false)
            })
            props.getAllPriority().then(() => setIsLoading(false)).catch((err) => console.log(err))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketType, created, timePeriod])

    useEffect(() => {
        if (created !== 'anytime' || (created === 'anytime' && timePeriod.length)) {
            getWorkOrderDashboardTeam(ticketType, created, filterGroup, timePeriod).then((res) => {
                setDataWoDashboardTeam(res)
            }).catch(() => {
                message.error('Fail to load data')
            }).finally(() => {
                setIsLoading(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketType, created, filterGroup, timePeriod])

    useEffect(() => {
        if (props.priority) {
            setPriority(props.priority)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.priority])

    const convertModelWoPerHour = (data?: woDashboardData[]) => {
        // const backgroundColor = [colorWoDashboardPiority.Low, colorWoDashboardPiority.Meduim, colorWoDashboardPiority.High, colorWoDashboardPiority.Critical]
        const tempdata = data?.map((it, idx) => {
            it.data = it.totalPerHour
            it.backgroundColor = priority[idx]?.tagColor
            return it
        })
        return tempdata
    }

    const convertModelTicketTeam = (data?: { label: string, total: number }[]) => {
        const tempData: number[] = data?.map((it) => {
            return it.total
        }) || []
        const tempColor: string[] = Array.from({ length: tempData?.length || 0 }, () => {
            return '#' + [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')
        })
        const convertData: DataForChart = {
            labels: [...data?.map((it) => { return it.label }) || ''],
            datasets: [{ data: tempData, backgroundColor: tempColor, hoverBackgroundColor: tempColor }]
        }
        return convertData
    }

    const convertModelWoStatusTeam = (data?: woDashboardTeam) => {
        const backgroundColor = [colorWoDashboardStaus.Assign, colorWoDashboardStaus.InProgress, colorWoDashboardStaus.Complete, colorWoDashboardStaus.Close, colorWoDashboardStaus.Cancel]
        const tempdata = data?.workOrderStatusAndGroup.dataSet?.map((it, idx) => {
            it.data = it.total
            it.backgroundColor = backgroundColor[idx]
            return it
        })
        return tempdata
    }

    const convertModelWorkflowStatusTeam = (data?: woDashboardTeam) => {
        const backgroundColor = [colorWoDashboardStaus.New, colorWoDashboardStaus.Assign, colorWoDashboardStaus.InProgress, colorWoDashboardStaus.Complete, colorWoDashboardStaus.Close, colorWoDashboardStaus.Cancel]
        const tempdata = data?.workOrderStatusAndWorkflow.dataSet?.map((it, idx) => {
            it.data = it.total
            it.backgroundColor = backgroundColor[idx]
            return it
        })
        return tempdata
    }

    const handleSetCreated = (value) => {
        setCreated(value)
        if (value === 'anytime') {
            setTimePeriod([])
        }
    }

    const disabledTime = (current) => {
        // Disable times before 10:00 AM and after 6:00 PM
        return current && current > moment().endOf('day')
    }

    return (
        <Spin spinning={isLoading}>
            <div>Dashboard</div>
            <Space size={20} />
            <Card style={{ overflow: 'auto' }}>
                <Row gutter={[16, 16]} type='flex' align='top'>
                    <Col span={16} xs={24} sm={24} md={16}>
                        {/* <span className='text-herder-Orenge'>Dashboard</span> */}
                    </Col>
                    <Col span={8} xs={24} sm={24} md={8}>
                        <Col span={12} xs={24} sm={24} md={12}>
                            <p>Ticket Type :</p>
                            <Select
                                style={{ width: '100%' }}
                                value={ticketType}
                                onChange={(value) => setTicketType(value)}
                            >
                                <Option value={'Incident'}>Incident</Option>
                                <Option value={'Request'}>Request</Option>
                            </Select>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={12}>
                            <p>Created :</p>
                            <Select
                                style={{ width: '100%' }}
                                value={created}
                                onChange={(value) => handleSetCreated(value)}
                            >
                                <Option value="last30Days">Last 30 days</Option>
                                <Option value="today">Today</Option>
                                <Option value="yesterday">Yesterday</Option>
                                <Option value="thisWeek">This Week</Option>
                                <Option value="thisMonth">This Month</Option>
                                <Option value="anytime">Select Time Period</Option>
                            </Select>
                            {created === 'anytime'
                                ? <RangePicker
                                    onChange={(value, dateString) => { setTimePeriod(dateString) }}
                                    format="YYYY-MM-DD"
                                    placeholder={['Start Time', 'End Time']}
                                    style={{
                                        width: '100%',
                                        marginTop: 10
                                    }}
                                    disabledDate={disabledTime}
                                /> : null}
                        </Col>
                    </Col>
                </Row>
                <br />
                <Row gutter={[24, 24]} type='flex' >
                    {dataWoDashboard?.overviewTotalStatus?.map((it, idx) => {
                        return <WoCardContainner key={idx} primaryColor={primaryColor[idx]} backgroundColor={backgroundColor[idx]} header={it.label} value={it.total} isLoading={isLoading} />
                    })}
                </Row>
                <br />
                <Row gutter={24}>
                    <Col span={12} lg={12} md={24} sm={24} xs={24}>
                        <div className="dashboard-containner">
                            <h2>Status Work Order Team <span><Tooltip title="View the total number of work orders by status (Assigned, In Progress, Completed) for each team."><Icon type="info-circle" style={{ color: 'blue', fontSize: 14 }}/></Tooltip></span></h2>
                            <StackBarChart label={dataWoDashboard?.workOrderStatusAndGroup.groupNames} isVerticle={true} data={convertModelWoStatusTeam(dataWoDashboard)} height={200} isShowLabel={!isMobile} />
                        </div>
                    </Col>
                    <Col span={12} lg={12} md={24} sm={24} xs={24}>
                        <div className='dashboard-containner'>
                            <h2>Ticket Team Group ({dataWoDashboardTeam.reduce((accumulator, current) => accumulator + current.total, 0)}) <span><Tooltip title="View the total number of work orders grouped by team."><Icon type="info-circle" style={{ color: 'blue', fontSize: 14 }}/></Tooltip></span></h2>
                            <DoughnutChart marginTop='0' showLabels={!isMobile} myData={convertModelTicketTeam(dataWoDashboardTeam)} Height={200} />
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} lg={12} md={24} sm={24} xs={24}>
                        <div className='dashboard-containner'>
                            <h2>Work Order Per Hour <span><Tooltip title="Track the number of work orders created or handled each hour."><Icon type="info-circle" style={{ color: 'blue', fontSize: 14 }}/></Tooltip></span></h2>
                            <StackBarChart label={label24Hours} data={convertModelWoPerHour(dataWoDashboard?.workOrderPerHours)} isShowLabel={!isMobile} />
                        </div>
                    </Col>
                    <Col span={12} lg={12} md={24} sm={24} xs={24}>
                        <div className='dashboard-containner'>
                            <h2>Total Work Orders <span><Tooltip title="View the cumulative total number of work orders for each hour."><Icon type="info-circle" style={{ color: 'blue', fontSize: 14 }}/></Tooltip></span></h2>
                            <LineBarFillChart label={label24Hours} data={dataWoDashboard?.sumPerHours} />
                        </div>
                    </Col>
                </Row>
                {/* <Row >

                </Row> */}
                <Row className='dashboard-containner'>
                    <h2>Workflow Work Order by Status <span><Tooltip title="Monitor work orders linked to workflows, categorized by status (Assigned, In Progress, Completed)."><Icon type="info-circle" style={{ color: 'blue', fontSize: 14 }}/></Tooltip></span></h2>
                    <StackBarChart label={dataWoDashboard?.workOrderStatusAndWorkflow.workflowNames} isVerticle={true} data={convertModelWorkflowStatusTeam(dataWoDashboard)} isShowLabel={!isMobile} />
                </Row>
            </Card >
        </Spin>
    )
}

export default connect(mapStateToProps, {getAllPriority})(WoDashboard)
