/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
import { Button, Card, Col, Divider, Empty, Form, Icon, Input, Modal, Row, Spin, Switch, Table, Tabs, Tooltip, message } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import RenderSelectOption from './component/RenderSelectOption'
import { AssetGroups, PMActivityField, PMTemplateField } from './model'
import { FormComponentProps } from 'antd/lib/form'
import { v4 as uuid } from 'uuid'
import { addOrUpdatePMActivityField, getDataCustomfieldPMTemplate } from '../msp-wo/asset-management'
import { connect } from 'react-redux'
import { StoreState } from '../store'

const mapStateToProps = (state: StoreState) => {
    return {
        PMActivityField: state.PMActivityField
    }
}

type StateProps = ReturnType<typeof mapStateToProps>
interface Params {
    dataAssetGroup: AssetGroups[]
    isAddPMTemplate: boolean
    setIsAddPMTemplate: Function
    selectTicketType?: string
    tabpanList?: any[]
}

interface DispatchProps {
    addOrUpdatePMActivityField: (value: any) => Promise<number>
}

type Props = FormComponentProps & Params & DispatchProps & StateProps

const { confirm } = Modal
const { TabPane } = Tabs
let listsEdit: number[] = []
let arr: PMActivityField[] = []
let isSubmit = false
const AddEditPMTemplate = (props: Props) => {
    const { getFieldValue, getFieldDecorator, setFieldsValue, resetFields, getFieldsValue } = props.form
    const [, setSelectTicketType] = useState<string>('')
    const [defaultPMTemplate, setDefaultPMTemplate] = useState<string>()
    const [isVisibleTemplateModal, setIsVisibleTemplateModal] = useState<boolean>(false)
    const [isEditPMName, setIsEditPMName] = useState<string>()
    const [tabpaneList, setTabpaneList] = useState<any[]>([])
    const [currenTab, setCurrentTab] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [duplicatesLabel, setDuplicatesLabel] = useState<string[]>([])
    const [data, setData] = useState<PMTemplateField[]>([])
    const [tempListLabel, setTempListLabel] = useState<string[]>([])
    const [isSpinning, setIsSpinning] = useState(false)
    const [checkIsUpdate, setCheckIsUpdate] = useState<boolean>(false)
    const [deleteDataList, setDeleteDataList] = useState<string[]>([])
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState(Object), [])
    let isEdit = props.tabpanList?.length
    const [originalData] = useState<string[]>([])

    useEffect(() => {
        if (props.isAddPMTemplate) {
            if (props.tabpanList?.length) {
                setIsSpinning(true)
                setTabpaneList(props.tabpanList)
                setDefaultPMTemplate(props.tabpanList.find((it) => it.default === true)?.name)
                setCurrentTab(props.tabpanList.find((it) => it.default === true)?.name)
                getAllTabpanCustomfield(props.tabpanList).finally(() => setIsSpinning(false))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isAddPMTemplate])

    const getAllTabpanCustomfield = async (dataList) => {
        try {
            const promises: PMTemplateField[] = dataList.map(async (it) => {
                const response = await getDataCustomfieldPMTemplate(it.id)
                let tempData: PMTemplateField = {
                    pmActivityTemplateId: it.id,
                    templateName: it.name,
                    isDefaultTemplate: it.default,
                    customFieldsList: response,
                    active: true
                }
                // parse and stringify for make original data and data not pointer in the same object
                originalData.push(JSON.stringify(tempData))
                return tempData
            })
            const results = await Promise.all(promises)
            setData([...results])
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }

    const addNewPMTemplate = () => {
        if (tabpaneList.length < 10) {
            setIsVisibleTemplateModal(true)
        } else {
            Modal.warning({
                title: 'Warnning',
                content: 'cannot create PM Template more than 10 Template.'
            })
        }
    }

    const submitPMTemplate = () => {
        props.form.validateFields(['pmName'], (err, values) => {
            if (err) {
                console.log('err')
            } else {
                if (!isEditPMName) {
                    const tempdata = {
                        name: getFieldValue('pmName').trim(),
                        data: []
                    }
                    if (tabpaneList.length < 1) {
                        setDefaultPMTemplate(getFieldValue('pmName').trim())
                    }
                    setTabpaneList([...tabpaneList, tempdata])
                    // setData(tempdata.data)
                    // call api add
                    const smallId = uuid().slice(0, 8)
                    const initData = {
                        // id: data.length,
                        activityLabel: undefined,
                        description: undefined,
                        customFieldOrder: undefined,
                        active: undefined,
                        assetGroup: undefined,
                        keyValue: smallId,
                        isRequireRemark: undefined
                    } as unknown as PMActivityField
                    setData([...data, { pmActivityTemplateId: undefined, templateName: getFieldValue('pmName').trim(), customFieldsList: [initData] }])
                } else {
                    const editIdx = tabpaneList.findIndex((it) => it.name === isEditPMName)
                    tabpaneList[editIdx].name = getFieldValue('pmName').trim()
                    // call api edit
                    // call api if default = edit should set default
                    if (defaultPMTemplate === isEditPMName) {
                        setDefaultPMTemplate(getFieldValue('pmName').trim())
                    }
                    data[editIdx].templateName = getFieldValue('pmName').trim()
                }
                setCurrentTab(getFieldValue('pmName'))
                setIsVisibleTemplateModal(false)
                setFieldsValue({ pmName: undefined })
                setIsEditPMName(undefined)
            }
        })
    }

    const onChangeTabpane = (currenTab) => {
        const tempdata = tabpaneList.find((it) => it.name === currenTab)
        console.log(tempdata)
        // setData(tempdata.data)
        let labelList = data?.find((it) => it.templateName === currenTab)?.customFieldsList?.map((it) => { return it.activityLabel?.trim().toLocaleLowerCase() || '' })
        setDuplicatesLabel(findDuplicates(labelList) || [])
        setCurrentTab(currenTab)
    }

    const onClosePmTemplate = () => {
        setIsVisibleTemplateModal(false)
        setIsEditPMName(undefined)
        setFieldsValue({ pmName: undefined })
    }

    const onEditPMTemplateName = (pmName) => {
        setIsVisibleTemplateModal(true)
        setIsEditPMName(pmName)
        setFieldsValue({ pmName: pmName })
    }

    const onEditTabpane = (target, action) => {
        if (action === 'remove') {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this template?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    // call api del
                    const tempTabpane = tabpaneList.filter((it) => it.name !== target)
                    setDeleteDataList([...deleteDataList, target])
                    setTabpaneList(tempTabpane)
                    message.success('Delete data successfully.')
                    if (tempTabpane.length > 0) {
                        setCurrentTab(tempTabpane[0].name)
                    } else {
                        setCurrentTab(undefined)
                    }
                    if (target === defaultPMTemplate) {
                        setDefaultPMTemplate(undefined)
                    }
                },
                onCancel() {
                }
            })
        }
    }

    const validatePMTemplateName = (_: any, value: any, callback) => {
        if (value) {
            if (value.trim().length < 1) {
                callback('Please enter more than 1 letter')
            } else {
                let tempName: string[] = []
                if (isEditPMName) {
                    tempName = tabpaneList.filter((it) => it.name !== isEditPMName).map((it) => it.name.trim().toLocaleLowerCase())
                } else {
                    tempName = tabpaneList.map((it) => it.name.trim().toLocaleLowerCase())
                }
                if (tempName.includes(value.trim().toLocaleLowerCase())) {
                    callback('PM Template Name is duplicate')
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    const onDiscardEditPMTemplate = () => {
        Modal.confirm({
            title: 'Are you sure',
            content: 'You want to leave this page?',
            onOk: () => {
                props.setIsAddPMTemplate(false)
            },
            onCancel: () => {

            }
        })
    }

    // const handleChangeDescription = (row: PMActivityField, value: string) => {
    //     row.description = value
    //     if (row.id) {
    //         listsEdit.push(row.id)
    //     }
    // }

    const deleteRow = (index, value, myData) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete this data?',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            onOk: async () => {
                await setIsSpinning(true)
                const tempData2 = data.find((it) => it.templateName === currenTab)?.customFieldsList.filter((_obj) => _obj?.keyValue !== myData?.keyValue)
                // data?.find((it) => it.name === currenTab)?.PMActivityField = tempData2
                data[data.findIndex((it) => it.templateName === currenTab)].customFieldsList = tempData2 || []
                await setIsSpinning(false)
                message.success('Delete data successfully.')
            },
            onCancel() { }
        })
    }

    const handleChangeLabel = (row: PMActivityField, value: string) => {
        row.activityLabel = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const allFields = Object.keys(getFieldsValue())
        const fieldsToValidate = allFields.filter(field => field !== 'pmName')
        props.form.validateFieldsAndScroll(fieldsToValidate, (err, values) => {
            isSubmit = true
            if (err || !defaultPMTemplate) {
                console.log('err')
            } else {
                let tempTabError: string[] = []
                data.forEach((tab) => {
                    // validate duplicate data in other tabpane
                    if (!deleteDataList.includes(tab.templateName)) {
                        let tempLabel: string[] = tab.customFieldsList?.map((it) => { return it.activityLabel?.trim().toLocaleLowerCase()!! })
                        if (tab.customFieldsList.length < 1) {
                            tempTabError.push(tab.templateName)
                        } else {
                            for (const it of tab.customFieldsList) {
                                if (it.activityLabel === undefined || it.activityLabel === '' || tempLabel.filter((label) => label === it.activityLabel?.trim().toLocaleLowerCase()).length > 1) {
                                    tempTabError.push(tab.templateName)
                                    break
                                }
                            }
                        }
                    }
                })
                if (tempTabError.length > 0) {
                    // if (tempTabError.length > 0) {
                    Modal.warning({
                        title: (<b>Failed to save</b>),
                        content: (<div>
                            <b>Failed to save PM template customfields. <br /> Please recheck <br /> - Required fields <br /> - Duplicated data <br /> - All template should have at lease one data <br /> </b>
                            <b style={{ color: '#FA541C', fontWeight: '600' }}>{tempTabError.join(',')}</b>
                        </div>)
                    })
                    // }
                } else {
                    setIsLoading(true)
                    const findType = props.dataAssetGroup.find((it) => {
                        return it.name === props.selectTicketType
                    })?.id || 1
                    let templatesData: any[] = []
                    let convertData: any = {}
                    if (!isEdit) {
                        data.forEach((it) => {
                            const newObj = { ...it }
                            newObj.customFieldsList.map((it2, i) => {
                                it2.activityLabel = it2.activityLabel ? it2.activityLabel.replace(/ +/g, ' ').trim() : undefined
                                // delete it2.keyValue
                                newObj.customFieldsList[i] = { ...it2, customFieldOrder: i, assetGroup: findType, active: true }
                                return { ...it2, customFieldOrder: i, assetGroup: findType, active: true }
                            })
                            newObj.pmActivityTemplateId = null
                            newObj.active = true
                            newObj.isDefaultTemplate = defaultPMTemplate === newObj.templateName
                            templatesData.push(newObj)
                        })
                        convertData = {
                            assetGroupId: findType,
                            defaultTemplateId: null,
                            templates: templatesData
                        }
                    } else {
                        let filterNotupdateData: PMTemplateField[] = []
                        data.forEach((it) => {
                            it.customFieldsList.map((it2, i) => {
                                it2.activityLabel = it2.activityLabel ? it2.activityLabel.replace(/ +/g, ' ').trim() : undefined
                                // delete it2.keyValue
                                it.customFieldsList[i] = { ...it2, customFieldOrder: i, assetGroup: findType, active: true }
                                return { ...it2, customFieldOrder: i, assetGroup: findType, active: true }
                            })
                            it.active = !deleteDataList.includes(it.templateName)
                            it.isDefaultTemplate = it.templateName === defaultPMTemplate
                            templatesData.push(it)
                        })
                        data.filter((it) => it.active || it.pmActivityTemplateId).forEach((it) => {
                            const tempdata = { ...it }
                            tempdata.customFieldsList = tempdata.customFieldsList.map((it) => {
                                const temp2 = { ...it }
                                delete temp2.active
                                delete temp2.assetGroup
                                // delete temp2.customFieldOrder
                                return temp2
                            })
                            // delete tempdata.active
                            if (!originalData.includes(JSON.stringify(tempdata))) {
                                filterNotupdateData.push(it)
                            }
                        })
                        convertData = {
                            assetGroupId: findType,
                            defaultTemplateId: props.tabpanList?.find((it) => it.name === defaultPMTemplate)?.id,
                            templates: filterNotupdateData
                        }
                    }
                    // call api maybe send current tab and default template
                    props.addOrUpdatePMActivityField(convertData).then((res) => {
                        if (res) {
                            message.success('The Update has finished successfully')
                            setIsLoading(false)
                            props.setIsAddPMTemplate(false)
                            isSubmit = false
                        }
                    }).catch((_err) => {
                        message.error(_err?.response?.data?.message || 'Please enter custom fields completely.')
                        setIsLoading(false)
                        isSubmit = false
                    })
                }
            }
        })
    }

    const validateLabel = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            // const pattern = /[A-Za-z0-9ก-๙]+/
            const checkLower = tempListLabel.map((obj) => {
                return obj.toLowerCase() === value.toLowerCase()
            })
            console.log(checkLower)
            if (format.test(value)) {
                // eslint-disable-next-line
                // callback('Field Label can' + 't contain any of the following characters ' + format)
                callback()
            } else {
                if (duplicatesLabel.includes(value?.trim().toLocaleLowerCase())) {
                    callback('The Field Label already exists.')
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

    const handleBlurLabel = (value: string) => {
        const listLabel2 = data.find((it) => it.templateName === currenTab)?.customFieldsList.map((obj) => {
            return obj.activityLabel?.trim().toLocaleLowerCase() || ''
        })

        if (findDuplicates(listLabel2)) {
            setDuplicatesLabel(findDuplicates(listLabel2))
        } else {
            setDuplicatesLabel([])
        }

        tempListLabel.push(value)
    }

    const handleChangeDescription = (row: PMActivityField, value: string) => {
        row.description = value
        if (row.id) {
            listsEdit.push(row.id)
        }
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, myData, index) => (
                <>
                    <Tooltip placement="bottom" title={'Add'}><Icon className="del_icon" type="plus-square"
                        onClick={() => addAfterRow(row.id, data.find((it) => it.templateName === currenTab)?.customFieldsList?.findIndex(it => { return it?.keyValue?.toString() === myData?.keyValue?.toString() }))} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
            // eslint-disable-next-line react/display-name
            render: (row, data, index) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'}><Icon className="edit_icon" type="minus-square"
                        onClick={() => deleteRow(index, row.label, data)} /></Tooltip>
                </>
            )
        },
        {
            title: <span><span className={'requiredIcon'}>* </span>Field label</span>,
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item
                        validateStatus={row.activityLabel === '' ? 'error' : duplicatesLabel.includes(row.activityLabel?.trim().toLocaleLowerCase()) ? 'error' : undefined}
                        help={row.activityLabel === '' ? 'Default value is not a valid' : duplicatesLabel.includes(row.activityLabel?.trim().toLocaleLowerCase()) ? 'The Field Label already exists.' : undefined}
                    >
                        {getFieldDecorator('activityLabel' + index + (row.keyValue || row.id), {
                            initialValue: row.activityLabel,
                            rules: [
                                {
                                    required: true, message: 'Activity label is required'
                                },
                                {
                                    validator: validateLabel
                                }
                            ]
                        })(
                            <Input maxLength={255} onChange={(e) => handleChangeLabel(row, e.target.value)}
                                onBlur={(e) => handleBlurLabel(e.target.value)} />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Field description',
            dataIndex: '',
            key: '',
            render: (row, data, index) => (
                <>
                    <Form.Item>
                        {getFieldDecorator('description' + row.id + index + row.keyValue, {
                            initialValue: row.description,
                            rules: [{ required: false }]
                        })(
                            <Input maxLength={255} onChange={(e) => handleChangeDescription(row, e.target.value)} />
                        )}
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Required remark',
            dataIndex: '',
            key: '',
            width: '10%',
            align: 'center' as const,
            render: (row: PMActivityField) => (
                <>
                    <Tooltip placement="bottom" title={row.isRequireRemark ? 'Deactivate' : 'Activate'}>
                        <Switch checked={row.isRequireRemark} onChange={(value) => { toggleActiveness(row) }} />
                    </Tooltip>
                </>
            )
        }
    ]

    const toggleActiveness = (data: PMActivityField) => {
        data.isRequireRemark = !data.isRequireRemark
        setCheckIsUpdate(!checkIsUpdate)
        forceUpdate()
    }

    const addAfterRow = (currentRow, index) => {
        arr = []
        const listLabel = data.find((it) => it.templateName === currenTab)?.customFieldsList.map((obj) => {
            return obj.activityLabel || ''
        })
        if (!listLabel || listLabel?.length < 30) {
            setTempListLabel([...new Set(listLabel)])
            const smallId = uuid().slice(0, 8)
            data.find((it) => it.templateName === currenTab)?.customFieldsList.forEach((obj) => {
                if (Number(obj.id) >= currentRow + 1) {
                    obj.id = Number(obj.id) + 1
                }
            })
            data.find((it) => it.templateName === currenTab)?.customFieldsList.splice(index + 1, 0, {
                id: undefined,
                activityLabel: undefined,
                description: undefined,
                customFieldOrder: undefined,
                active: undefined,
                assetGroup: undefined,
                keyValue: smallId,
                isRequireRemark: undefined
            })
            resetFields()
            let tempOlddata = data[data.findIndex((it) => it.templateName === currenTab)].customFieldsList
            data[data.findIndex((it) => it.templateName === currenTab)].customFieldsList = [...tempOlddata, ...arr]
            // setData(data)
            forceUpdate()
        } else {
            Modal.warning({
                title: 'Warnning',
                content: 'cannot add customfields more than 30 fields.'
            })
        }
    }

    const renderTable = () => {
        let dataCurrentTab = data.find((it) => it.templateName === currenTab)
        return (
            // <DndProvider manager={manager.current.dragDropManager!!}>
            <Spin spinning={isSpinning}>
                <Table
                    columns={columns}
                    dataSource={dataCurrentTab?.customFieldsList || []}
                    pagination={false}
                // components={components}
                // onRow={(record, index) => ({
                //     index,
                //     moveRow
                // })}
                // scroll={{ x: 768 }}
                // className='Table-CustomField'
                />
            </Spin>
            // </DndProvider>
        )
    }

    const AddData = () => {
        arr = []
        const listLabel = data.find((it) => it.templateName === currenTab)?.customFieldsList.map((obj) => {
            return obj.activityLabel || ''
        })
        if (!listLabel || listLabel?.length < 30) {
            setTempListLabel([...new Set(listLabel)])
            const smallId = uuid().slice(0, 8)
            const initData = {
                // id: data.length,
                activityLabel: undefined,
                description: undefined,
                customFieldOrder: undefined,
                active: undefined,
                assetGroup: undefined,
                keyValue: smallId,
                isRequireRemark: undefined
            } as unknown as PMActivityField
            arr.push(initData)
            // data.forEach((obj) => {
            //     if (obj.id === initData.id) {
            //         console.log('++++')
            //         console.log(obj.id)
            //         initData.id = (obj.id as number)++
            //     }
            // })
            let tempOlddata = data[data.findIndex((it) => it.templateName === currenTab)].customFieldsList
            data[data.findIndex((it) => it.templateName === currenTab)].customFieldsList = [...tempOlddata, initData]
            forceUpdate()
        } else {
            Modal.warning({
                title: 'Warnning',
                content: 'cannot add customfields more than 30 fields.'
            })
        }
    }

    return (
        <Spin spinning={isLoading}>
            <Card>
                <Row>
                    <Col span={20}>
                        <h3 className={'main-title'}> <Icon type="arrow-left" onClick={onDiscardEditPMTemplate} /> Add/Edit template</h3>
                    </Col>
                    <Col span={4}>
                        <Row type='flex' justify='end'>
                            <Button style={{ marginRight: 10 }} type="default" className="addCriteriaButton" onClick={onDiscardEditPMTemplate}>Cancel</Button>
                            <Button type="primary" className="addCriteriaButton" onClick={handleSubmit} disabled={tabpaneList.length === 0}>Save</Button>
                        </Row>
                    </Col>
                </Row>
                <div className='customfield-pm-group-container'>
                    <Row type='flex' align='middle'>
                        <Col span={6}>
                            <RenderSelectOption header={'Group'} groupList={props.dataAssetGroup} selectTicketType={props.selectTicketType} setTicketTypes={setSelectTicketType} isDisabled={true} />
                        </Col>
                        <Col span={12} />
                        <Col span={6}>
                            <Button style={{ float: 'right' }} type="primary" onClick={addNewPMTemplate} htmlType="button" className="addCriteriaButton"> <Icon type="plus" />Add Template Name</Button>
                        </Col>
                    </Row>
                    <Divider />
                    <Row type='flex' justify='end'>
                        <Col span={12} />
                        <Col span={12} >
                            <RenderSelectOption header={'Default PM template'} groupList={tabpaneList} selectTicketType={defaultPMTemplate} setTicketTypes={setDefaultPMTemplate} justify='end' isSubmit={isSubmit} required={true} />
                        </Col>
                    </Row>
                </div>
                <br />
                <br />
                {tabpaneList.length > 0 ? <Tabs
                    hideAdd
                    onChange={onChangeTabpane}
                    activeKey={currenTab}
                    type="editable-card"
                    onEdit={onEditTabpane}
                >
                    {tabpaneList?.map((it) => {
                        return <TabPane tab={it.name.length < 19 ? it.name : <Tooltip title={it.name} placement='bottom'>{it.name.slice(0, 19)}...</Tooltip>} key={it.name}>
                            <h3 className={'main-title'}>Template name : {it.name} &nbsp; &nbsp;<Icon type='edit' onClick={() => onEditPMTemplateName(it.name)} /></h3>
                            <Button onClick={() => AddData()} htmlType="button" className="addCriteriaButton">Add</Button>
                            <br /><br />
                            <Form onSubmit={handleSubmit}>
                                {renderTable()}
                            </Form>
                        </TabPane>
                    })}
                </Tabs> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Card>

            <Modal title={`${isEditPMName ? 'Edit' : 'Add'} template pm activity`}
                visible={isVisibleTemplateModal}
                onCancel={onClosePmTemplate}
                footer={false}>
                <Form>
                    <Row gutter={12}>
                        <Col span={20}>
                            <Form.Item label="Template name" labelCol={{ span: 7 }} wrapperCol={{ span: 17 }}>
                                {getFieldDecorator('pmName', {
                                    rules: [{ required: true, message: 'Please input Template name' }, { validator: validatePMTemplateName }]
                                })(
                                    <Input
                                        placeholder="Template Name"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item >
                                <Button type="primary" style={{ width: '-webkit-fill-available', float: 'right', marginTop: 3 }} onClick={submitPMTemplate}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal >
        </Spin>
    )
}
const WrappedAddEditPMTemplate = Form.create<Props>()(AddEditPMTemplate)
export default connect(mapStateToProps, { addOrUpdatePMActivityField })(WrappedAddEditPMTemplate)
