import { Breadcrumb, Col, Menu, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CustomManageColors from './general/components/CustomManageColors'
import PriortyConfigPage from './priority/PriortyConfigPage'
import ProblemsCustomField from '../configurable-fields/problems/Problems'
import TaskProblems from '../configurable-fields/problems/TaskProblem'
import { addAllTypeProblems } from './general/states/service'
import { ProblemType } from './general/states/model'

const { SubMenu } = Menu
const HomeProblemSetting = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [typeProblems, setTypeProblems] = useState<ProblemType>()
    const [typePTaskProblems, setPTaskTypeProblems] = useState<ProblemType[]>([])
    const [keys, setKeys] = useState('1')

    useEffect(() => {
        setIsLoading(true)
        addAllTypeProblems().then((res: ProblemType[]) => {
            if (res) {
                const filterTypyProblems = res.find((p) => { return p.type === 'Problem' && p.active === true })
                setTypeProblems(filterTypyProblems)
                const filterTypyPTask = res.filter((p) => { return p.type === 'PTask' && p.active === true })
                setPTaskTypeProblems(filterTypyPTask)
            }
        }).finally(() => { setIsLoading(false) })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = e => {
        console.log('click ', e)
        setKeys(e.key)
    }
    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Problem Setting</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Spin spinning={isLoading}>
                <Row gutter={[16, 16]} style={{marginTop: 7}}>
                    <Col xs={24} sm={24} xl={4} lg={4} span={4} >
                        <div style={{ border: '1px solid #DCDCDC', padding: '20px 5px 20px 20px', borderRadius: '5px', height: window.innerWidth > 500 ? '80vh' : '100%' }}>
                            <Menu
                                onClick={handleClick}
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['sub1']}
                                mode="inline"
                                className='problemas-menu'
                            >
                                <Menu.ItemGroup key="g1" title="General" className='fix-color'>
                                    <Menu.Item key="1">{typeProblems?.subType || ''}</Menu.Item>
                                    <SubMenu key="sub2" title={<span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Problem Task</span>}>
                                        {typePTaskProblems.map((problem) => {
                                            return <Menu.Item key={problem.id + 'g1'}>{problem.subType}</Menu.Item>
                                        })}
                                    </SubMenu>
                                    <Menu.Item key="6">Priorty</Menu.Item>
                                </Menu.ItemGroup>
                                <Menu.ItemGroup key="g2" title="Custom field">
                                    <Menu.Item key="4">{typeProblems?.subType || ''}</Menu.Item>
                                    <SubMenu key="sub3" title={<span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Problem Task</span>}>
                                        {typePTaskProblems.map((problem) => {
                                            return <Menu.Item key={problem.id + 'g2'}>{problem.subType}</Menu.Item>
                                        })}
                                    </SubMenu>
                                </Menu.ItemGroup>
                            </Menu>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} xl={20} lg={20} span={20} >
                        <div style={{ border: '1px solid #DCDCDC', padding: 20, borderRadius: '5px', minHeight: 715 }}>
                            {keys === '1' ? <CustomManageColors nameForm={keys} TypeProblemid={typeProblems?.id?.toString()} /> : null}
                            {keys === '2g1' ? <CustomManageColors nameForm={keys} TypeProblemid={typePTaskProblems[0]?.id?.toString()} /> : null}
                            {keys === '3g1' ? <CustomManageColors nameForm={keys} TypeProblemid={typePTaskProblems[1]?.id?.toString()} /> : null}
                            {keys === '4' ? <ProblemsCustomField TypeProblemid={typeProblems?.id?.toString()} /> : null}
                            {keys === '2g2' ? <TaskProblems nameForm={keys} TypeProblemid={typePTaskProblems[0]?.id?.toString()} /> : null}
                            {keys === '3g2' ? <TaskProblems nameForm={keys} TypeProblemid={typePTaskProblems[1]?.id?.toString()} /> : null}
                            {keys === '6' ? <PriortyConfigPage /> : null}
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}

export default HomeProblemSetting
