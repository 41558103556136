import React, { useEffect, useState } from 'react'
import { Col, DatePicker, Form, Row, Select } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import { Category } from '../knowledge-management'
import { StoreState } from '../store'
import { connect } from 'react-redux'
import KnowledgeLayoutChart from './KnowledgeLayoutChart'
import { getKMDashboardCategory, getKnowledgeDashboard } from './service'

const mapStateToProps = (state: StoreState) => {
    return {
        AllKmDashboard: state.AllKmDashboard
    }
}

interface DispatchProps {
    getKnowledgeDashboard: (show, created, startDate?, endDate?, selectedKm?, page?, pageSize?) => number
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = FormComponentProps & StateProps & DispatchProps

const { Option } = Select
const { RangePicker } = DatePicker

const KnowledgeDash: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator } = props.form
    const [show, setShow] = useState<number|string>('all')
    const [created, setCreated] = useState<string>('all')
    const [time, setTime] = useState<string[]>([])
    const [categoryList, setCategoryList] = useState<Category[]>([])

    useEffect(() => {
        getKMDashboardCategory().then((response) => {
            setCategoryList(response)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        CheckGroupAndFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, created, time])

    const CheckGroupAndFetchData = () => {
        if (created !== 'anytime' || time.length !== 0) {
            fetchData(show || 'all', time)
        }
    }

    const changeShow = (e: any) => {
        setShow(e)
    }

    const changeCreated = (e: any) => {
        setCreated(e)
    }

    const changeTime = (value, dateString) => {
        setTime(dateString)
    }

    const fetchData = async (show: number|string, Time: string[]) => {
        if (Time.length !== 0) {
            await props.getKnowledgeDashboard(show !== 'all' ? show : undefined, created, Time[0], Time[1])
        } else {
            await props.getKnowledgeDashboard(show !== 'all' ? show : undefined, created)
        }
    }

    return (
        <div>
            <Form>
                <Row gutter={16}>
                    <Col span={18} />
                    <Col span={3} xs={24} sm={24} md={3} lg={3}>
                        <span>Show Category :</span>
                        <Form.Item>
                            {getFieldDecorator('Show', {
                                initialValue: 'all'
                            })(
                                <Select onChange={(e) => changeShow(e)}>
                                    <Option value='all'>All</Option>
                                    {(categoryList || []).map((it) => {
                                        return <Option key={it.id} value={it.id}>{it.categoryName}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3} xs={24} sm={24} md={3} lg={3}>
                        <span>Created :</span>
                        <Form.Item >
                            {getFieldDecorator('Created', {
                                initialValue: created
                            })(
                                <Select onChange={(e) => changeCreated(e)}>
                                    {/* <Option value="last30Days">Last 30 days</Option> */}
                                    <Option value='all'>All</Option>
                                    <Option value="today">Today</Option>
                                    <Option value="yesterday">Yesterday</Option>
                                    <Option value="thisWeek">This Week</Option>
                                    <Option value="thisMonth">This Month</Option>
                                    <Option value="anytime">Select Time Period</Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={18} />
                    <Col span={6}>{created === 'anytime' ? (<>
                        <Form.Item >
                            {getFieldDecorator('TimePeriod')(
                                <RangePicker
                                    // showTime={{ format: 'HH:mm' }}
                                    onChange={changeTime}
                                    format="YYYY-MM-DD"
                                    placeholder={['Start Time', 'End Time']}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            )}
                        </Form.Item>
                    </>) : null}</Col>
                </Row>
            </Form>
            <br />
            <KnowledgeLayoutChart groupOffKm={{ show, created, time }} />
        </div>
    )
}

const WrappedNormalDashboardForm = Form.create()(KnowledgeDash)

export default connect(mapStateToProps, { getKnowledgeDashboard: getKnowledgeDashboard })(WrappedNormalDashboardForm)
