/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react'
import { Col, Icon, Input, message, Modal, Row, Table, Form, Button } from 'antd'
import { deleteDepartmentSetting, searchDepartmentSetting } from '../../../authorization-module/user-role/duck/service'
import { FormComponentProps } from 'antd/es/form'
import { DepartmentAuth } from '../../../authorization-module/people/model'
import DepartmentFormModal from './DepartmentForm'

type Props = FormComponentProps
const { Search } = Input
const { confirm } = Modal
const CategoryTab: React.FC<Props> = (props: Props) => {
    // const { getFieldDecorator, resetFields, validateFields, setFieldsValue, getFieldValue } = props.form
    const pageSize = 10
    const [page, setPage] = useState<number>(1)
    const [dataSource, setDataSource] = useState<DepartmentAuth[]>()
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false)
    const [dataEdit, setDataEdit] = useState<DepartmentAuth[]>()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isModalVisibleEdit, setIsModalVisibleEdit] = useState<boolean>(false)
    const [isTableChange, setIsTableChange] = useState<boolean>(false)
    const [dataTotal, setDataTotal] = useState<number>(0)
    const [onSearch, setOnsearch] = useState<string>('')
    const [sortBy, setSortBy] = useState<string>('lastModifiedDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')

    useEffect(() => {
        setIsTableLoading(true)
        searchDepartmentSetting(sortBy, orderBy, page, pageSize, '').then((res) => {
            setDataSource(res.data)
            setDataTotal(res.total)
            setIsTableLoading(false)
            setIsTableChange(false)
        }).catch((err) => {
            console.log(err)
            setIsTableLoading(false)
            setIsTableChange(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTableChange])

    useEffect(() => {
        setIsTableLoading(true)
        if (onSearch) {
            searchDepartmentSetting(sortBy, orderBy, page, pageSize, onSearch).then((res) => {
                setDataSource(res.data)
                setDataTotal(res.total)
                setIsTableLoading(false)
            }).catch((err) => {
                console.log(err)
                setIsTableLoading(false)
            })
        } else {
            searchDepartmentSetting(sortBy, orderBy, page, pageSize, '').then((res) => {
                setDataSource(res.data)
                setDataTotal(res.total)
                setIsTableLoading(false)
                setIsTableChange(false)
            }).catch((err) => {
                console.log(err)
                setIsTableLoading(false)
                setIsTableChange(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, onSearch])

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row) => (
                <span><Icon type="delete" onClick={() => deleteCompany(row.id)}/></span>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            render: (row) => (
                <span><Icon type="edit" onClick={() => selectDataEdit(row)}/> </span>
            )
        },
        {
            title: 'Department Name',
            dataTindex: '',
            key: '',
            width: '40%',
            render: (data) => (
                <>
                    {data.departmentName}
                </>
            ),
            sorter: true
        },
        {
            title: 'Last Modified',
            dataIndex: '',
            key: '',
            render: (data) => (
                <>
                    {data.lastModifiedDate || data.lastModifiedDatetime}
                </>
            ),
            width: '40%'
        }
    ]

    const selectDataEdit = (row) => {
        setDataEdit(row)
        setIsEdit(true)
        setIsModalVisibleEdit(true)
    }

    const deleteCompany = (id) => {
        confirm({
            title: 'Are you sure?',
            content: 'Do you confirm Delete of this data ?',
            onOk() {
                setIsTableChange(true)
                deleteDepartmentSetting(id).then(() => {
                    message.success('Delete Department Name successfully.')
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    setIsTableChange(false)
                })
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const closeModal = (value: any) => {
        setIsTableChange(true)
        setIsModalVisible(false)
        setIsModalVisibleEdit(false)
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.order === 'ascend') {
            setOrderBy('ASC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('departmentName')
            }
        } else if (sorter.order === 'descend') {
            setOrderBy('DESC')
            if (sorter.field) {
                setSortBy(sorter.field)
            } else {
                setSortBy('departmentName')
            }
        } else {
            setSortBy('lastModifiedDatetime')
        }
    }

    return (
        <div>
            <div style={{ overflow: window.innerWidth <= 768 ? 'auto' : 'none' }}>
                {/* <TableCategory getAllCategory={getAllCategory} handleOnClickInOtherPage={handleOnClickInOtherPage} getDataToEdit={getDataToEdit} getPage={getPage} /> */}
                <div>
                    <div>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Search
                                    placeholder="Can search Department Name"
                                    onSearch={value => setOnsearch(value.trim())}
                                    style={{ width: 300 }}
                                    allowClear
                                />
                            </Col>
                            <Col span={12}>
                                <Button type="primary" icon='plus' style={{ float: 'right' }} onClick={() => setIsModalVisible(true)}>Add Department</Button>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <br />
                    <Table
                        columns={columns}
                        dataSource={dataSource || []}
                        pagination={{
                            pageSize: pageSize,
                            current: page,
                            total: dataTotal || 0,
                            onChange: (event) => {
                                handleOnClickPageNumber(event)
                            }
                        }}
                        loading={isTableLoading}
                        // rowKey="id"
                        onChange={handleTableChange}
                        scroll={{ x: 768 }}
                    />
                    <Modal width={450} title='Add Department' footer={false} visible={isModalVisible} onCancel={() => setIsModalVisible(false)}>
                        <DepartmentFormModal closeModal={closeModal}/>
                    </Modal>
                    <Modal width={450} title='Edit Department' footer={false} visible={isModalVisibleEdit} onCancel={() => setIsModalVisibleEdit(false)}>
                        <DepartmentFormModal closeModal={closeModal} dataEdit={dataEdit} isEdit={isEdit}/>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

const CategoryTabFrom = Form.create<Props>()(CategoryTab)
export default CategoryTabFrom
