import React, {createContext, PropsWithChildren, useReducer} from 'react'

export const SelectedImagePMContext = createContext({})

const initialState: string[] = []

interface Param {

}
type props = PropsWithChildren<Param>
const SelectedImagePMReducer = (state: string[], action: { type: string; payload: any }) => {
    switch (action.type) {
    case 'GET_SelectedImagePM':
        return action.payload || [] as string[]
    default:
        return state
    }
}

export const SelectedImagePMProvider = (props: props) => {
    const [SelectedImagePMState, SelectedImagePMDispatch] = useReducer(SelectedImagePMReducer, initialState)

    const GetSelectedImagePM = (payload: string[]) => {
        SelectedImagePMDispatch({ type: 'GET_SelectedImagePM', payload })
    }

    return (
        <SelectedImagePMContext.Provider value={{ SelectedImagePMState, GetSelectedImagePM }}>
            {props.children}
        </SelectedImagePMContext.Provider>
    )
}
