import { Dispatch } from 'redux'
import { checkRedirect } from '../../common-misc'
import { decryptBody } from '../../common-misc/encryptBody'
import { axiosGet } from '../rest'
import { Worklog } from './model'
import { GetAllWorklogByIncidentIdSuccessEvent } from './state-event'

export const getAllWorkLogByIncidentId = (incidentId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Worklog[]>(`/api/incidents/${incidentId}/worklogs`)
            dispatch(GetAllWorklogByIncidentIdSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err.response)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}
