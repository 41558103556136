import { FormComponentProps } from 'antd/lib/form'
import React, { useEffect, useState } from 'react'
import { Button, Form, Select } from 'antd'
import { Roles } from '../user-role/duck/model'
import { filterPeopleManagementType } from './model'
import { nullOrEmptyObject } from '../../common-misc'

interface Params {
    roles: Roles[]
    setVisible: Function
    getDataFilter: Function
    drawerVisible: boolean
    filterUser?: filterPeopleManagementType
}

type Props = FormComponentProps & Params
const { Option } = Select
const FilterPeople: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, resetFields, setFieldsValue } = props.form
    const [valueRoles, setValueRoles] = useState<Roles[]>([])

    useEffect(() => {
        if(!props.drawerVisible){
            resetFields()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.drawerVisible])

    useEffect(() => {
        if (!nullOrEmptyObject(props.filterUser)) {
            setFieldsValue({
                filterConfirmed: props.filterUser?.confirmed,
                filterGoAccess: props.filterUser?.goserviceAccess,
                filterRoles: props.filterUser?.roles,
                filterSelfAccess: props.filterUser?.selfPortalAccess
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterUser, props.drawerVisible])

    useEffect(() => {
        setValueRoles(props.roles)
    }, [props.roles])

    const handleSubmitFilter = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                const mapSetFilter: filterPeopleManagementType = {
                    roles: values.filterRoles,
                    confirmed: values.filterConfirmed,
                    goserviceAccess: values.filterGoAccess,
                    selfPortalAccess: values.filterSelfAccess
                }
                if (props.setVisible) {
                    props.setVisible(false)
                }
                if (props.getDataFilter) {
                    props.getDataFilter(mapSetFilter)
                }
                resetFields()
            }
        })
    }

    const onResetField = () => {
        if (props.setVisible) {
            props.setVisible(true)
        }
        resetFields()
    }

    return (
        <Form
            onSubmit={handleSubmitFilter}
        >
            <Form.Item label="Roles" >
                {getFieldDecorator('filterRoles')(
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Roles"
                    >
                        {valueRoles.map((e: Roles) => {
                            return <Option key={e.id} value={e.name}>{e.name}</Option>
                        })}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Confirmed Email" >
                {getFieldDecorator('filterConfirmed')(
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Status"
                    >
                        <Option value={'0'} label='Not Confirmed'>Not-Confirmed</Option>
                        <Option value={'1'} label='Confirmed'>Confirmed</Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Goservice Access" >
                {getFieldDecorator('filterGoAccess')(
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Status"
                    >
                        <Option value={'0'} label='Deactivate'>Deactivate</Option>
                        <Option value={'1'} label='Activate'>Activate</Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item label="Self-Portal Access" >
                {getFieldDecorator('filterSelfAccess')(
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Select Status"
                    >
                        <Option value={'0'} label='Deactivate'>Deactivate</Option>
                        <Option value={'1'} label='Activate'> Activate</Option>
                    </Select>
                )}
            </Form.Item>
            <Form.Item style={{ margin: '0 10px 0 100px' }}>
                <Button type="default" onClick={() => { onResetField() }} style={{ marginRight: '10px' }} >
                            Cancel
                </Button>
                <Button type="primary" htmlType="submit" >
                            Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

const WrappedFilterPeople = Form.create<Props>()(FilterPeople)
export default WrappedFilterPeople
