import React, { useState, useEffect, useContext } from 'react'
import { Button, Col, Drawer, Icon, Input, message, Row, Table, Tag, Tooltip, Form } from 'antd'
import { Space } from '../../common-components/Space'
import { Link } from 'react-router-dom'
import { SearchFilterCategory, SelfServiceCatagory, ResponseContentDetails } from '../Catalog/Model'
import ReactHtmlParser from 'react-html-parser'
import { nullOrEmptyObject, SearchParamsURL } from '../../common-misc'
import FilterCategoryForm from './FilterCategory'
import CategoryForm from '../Catalog/components/catagory/CategoryForm'
import { searchCatagory, addCatagory, getallCatalog, getallCategory } from '../Catalog/service'
import { FormComponentProps } from 'antd/es/form'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { PageServiceModelContext } from '../Catalog/ContextPage/PagesContext'

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatalogs: state.selfServiceCatalogs,
        selfServiceCatagories: state.selfServiceCatagories,
        selfServiceItems: state.selfServiceItems
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getallCatalog: () => Promise<number>
    getallCategory: () => Promise<number>
    addCatagory: (data) => Promise<any>
}

interface Param {
    nameTap: string
}

type Props = StateProps & DispatchProps & FormComponentProps & Param
const { Search } = Input
const CategoryList: React.FC<Props> = (props: Props) => {
    const pageSize = 10
    const { getFieldDecorator, setFieldsValue } = props.form
    const [page, setPage] = useState<number>(1)
    const [visible, setVisible] = useState(false)
    const [dataSource, setDataSource] = useState<ResponseContentDetails<SelfServiceCatagory[]>>()
    const [loading, setLoading] = useState<boolean>(false)
    const [openFilter, setOpenFilter] = useState<boolean>(false)
    const [searchFilter, setSearchFilter] = useState<SearchFilterCategory>()
    const [searchText, setSearchText] = useState<string>()
    const [isOnLoadingData, setIsOnLoadingData] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<string>('createdDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [parent, setParent] = useState<SelfServiceCatagory[]>()
    // const [catalog, setCatalog] = useState<SelfServiceCatalog[]>()
    const { PageServiceModelState, GetPageServiceModel } = useContext<any>(PageServiceModelContext)

    useEffect(() => {
        if (props.selfServiceCatalogs.content.length === 0) {
            props.getallCatalog().catch(err => {
                message.error(err)
            })
        }
        if (props.selfServiceCatagories.content.length === 0) {
            props.getallCategory().catch(err => {
                message.error(err)
            })
        }
        const page = SearchParamsURL('page')
        if (page) {
            setPage(Number(page))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.nameTap) {
            setSearchFilter(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nameTap])

    useEffect(() => {
        if (props.selfServiceCatagories) {
            setParent(props.selfServiceCatagories.content.filter((category) => category.parent === null))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selfServiceCatagories])

    useEffect(() => {
        setLoading(true)
        if (searchFilter) {
            if (searchFilter.CatalogName || searchFilter.Parent || searchFilter.Status) {
                // setPage(1)
                if (PageServiceModelState && GetPageServiceModel) {
                    GetPageServiceModel({
                        pageCatalog: PageServiceModelState.pageCatalog,
                        pageCategory: 1,
                        pageItemCatalog: PageServiceModelState.pageItemCatalog
                    })
                }
                setSearchText(undefined)
                setFieldsValue({
                    Search: undefined
                })
            }
        }
        searchCatagory(page, pageSize, searchFilter?.CatalogId, sortBy, orderBy, searchFilter?.Status as string, searchFilter?.ParentId, searchText).then(catagory => {
            setDataSource(catagory)
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, sortBy, orderBy, searchFilter, searchFilter?.CatalogId, searchFilter?.Status, searchFilter?.ParentId, searchText])

    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (data: string, row) => (
                <>
                    <div>
                        <Link to={'/CategoryView/' + row.id} >{data}</Link>
                    </div>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true
        },
        {
            title: 'Catalog Name',
            dataIndex: 'catalog.name',
            key: 'catalog.name',
            sorter: true
        },
        {
            title: 'Parent',
            dataIndex: 'parent.name',
            key: 'parent.name',
            sorter: true
        }
    ]

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
        if (PageServiceModelState && GetPageServiceModel) {
            GetPageServiceModel({
                pageCatalog: PageServiceModelState.pageCatalog,
                pageCategory: page,
                pageItemCatalog: PageServiceModelState.pageItemCatalog
            })
        }
    }

    const handleChangeTable = (pagination, filters, sorter) => {
        setSortBy(sorter.field)
        if (sorter.order === 'ascend') {
            setOrderBy('asc')
        } else if (sorter.order === 'descend') {
            setOrderBy('desc')
        } else {
            setOrderBy('desc')
            setSortBy('createdDatetime')
        }
    }

    const searchFilterTag = (title: any, searchFilter: string[], onClose: Function, nameTag: string) => {
        let tags: JSX.Element = (<></>)
        if (nameTag !== 'Status') {
            searchFilter = JSON.parse(searchFilter[0])
        }
        if (searchFilter.length > 0) {
            tags = (
                <Tooltip placement="bottom" title={ReactHtmlParser(searchFilter.map((filter: string) => { return `- ${filter}` }).join('</br>'))}>
                    <Tag style={{ margin: 10 }} closable onClose={onClose}>{nameTag}</Tag>
                </Tooltip>)
        }
        return tags
    }

    const search = (value?: string, forceSearch?: boolean) => {
        let filter: SearchFilterCategory | undefined
        if (!nullOrEmptyObject(searchFilter)) {
            filter = searchFilter
        }
        console.log(filter)
        setIsOnLoadingData(false)
    }

    const handleSearch = (search: string | undefined) => {
        setSearchText(search?.trim())
        setSearchFilter(undefined)
        // searchCatagory(page, pageSize, '', sortBy, orderBy, undefined, undefined, search).then((catagory) => {
        //     if (catagory) {
        //         setDataSource(catagory)
        //     }
        // })
        setPage(1)
        setFieldsValue({Search: search?.trim()})
    }

    const insertData = (data: SelfServiceCatagory) => {
        setLoading(true)
        props.addCatagory(data).then((catagory) => {
            message.success('You have Successfully save the data.')
            if (catagory) {
                // eslint-disable-next-line no-unused-expressions
                dataSource?.content.unshift(catagory)
                if (dataSource) {
                    dataSource.total = dataSource.total + 1
                }
                const data = dataSource
                setDataSource(data)
                handleSearch(undefined)
            }
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const onCloseFilter = () => {
        if (searchFilter) {
            if ((searchFilter.CatalogId === '' || !searchFilter.CatalogId) && (searchFilter.ParentId === '' || !searchFilter.ParentId) && (searchFilter.Status === '' || !searchFilter.Status)) {
                setSearchFilter(undefined)
                return
            }
            const newFilter: SearchFilterCategory = {
                CatalogName: searchFilter.CatalogName,
                Parent: searchFilter.Parent,
                Status: searchFilter.Status,
                CatalogId: searchFilter.CatalogId,
                ParentId: searchFilter.ParentId
            }
            setSearchFilter(newFilter)
            search(searchText !== '' ? searchText : undefined, true)
        }
        // setPage(1)
    }

    return (
        <>
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    <Button type={'primary'} onClick={() => setVisible(true)}>Add Category</Button>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Col span={24}>
                    <div><p className={'HerderText'}>Category ({dataSource?.totalElements || dataSource?.total || 0}) </p></div>
                    <Form >
                        <Form.Item
                            label=""
                        >
                            {getFieldDecorator('Search', {})(
                                <Search
                                    placeholder="Can search Category Name"
                                    onSearch={handleSearch}
                                    style={{ width: '100%' }}
                                    allowClear={true}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Space size={10} />
            <Row>
                <Col xs={24}>
                    <Button style={{ border: 0, marginTop: 10 }} onClick={() => { setOpenFilter(true) }} loading={isOnLoadingData} ><Icon type="filter" theme="filled" /> Filter</Button>
                    {searchFilter?.CatalogName ? searchFilterTag(searchFilter.CatalogName, [searchFilter.CatalogName], () => { searchFilter.CatalogName = ''; searchFilter.CatalogId = ''; setSearchFilter(searchFilter); search(searchText !== '' ? searchText : undefined, true); onCloseFilter(); setPage(1) }, 'Category') : null}
                    {searchFilter?.Status ? searchFilterTag(searchFilter.Status, searchFilter.Status as string[], () => { searchFilter.Status = ''; setSearchFilter(searchFilter); search(searchText !== '' ? searchText : undefined, true); onCloseFilter(); setPage(1) }, 'Status') : null}
                    {searchFilter?.Parent ? searchFilterTag(searchFilter.Parent, [searchFilter.Parent], () => { searchFilter.ParentId = ''; searchFilter.Parent = ''; setSearchFilter(searchFilter); search(searchText !== '' ? searchText : undefined, true); onCloseFilter(); setPage(1) }, 'Parent') : null}
                </Col>
            </Row>
            <Table
                dataSource={dataSource?.content}
                rowKey={'id'}
                columns={columns}
                loading={loading}
                pagination={{
                    pageSize: pageSize,
                    current: page,
                    total: dataSource?.totalElements || dataSource?.total,
                    onChange: (event) => {
                        handleOnClickPageNumber(event)
                    }
                }}
                onChange={handleChangeTable}
            />
            <Drawer
                title="Filter"
                placement="right"
                closable={true}
                onClose={() => { setOpenFilter(false) }}
                visible={openFilter}
                width={400}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
            >
                <FilterCategoryForm setOpenFilter={setOpenFilter} setSearchFilter={setSearchFilter} parents={parent} isVisible={openFilter} setPage={setPage} searchFilter={searchFilter} />
            </Drawer>
            <Drawer
                title="Create New Category"
                placement="right"
                closable={true}
                onClose={() => { setVisible(false) }}
                visible={visible}
                width={500}
                bodyStyle={{ paddingBottom: 80, overflow: 'auto' }}
            >
                <CategoryForm setVisible={setVisible} catagoryAction={insertData} parents={parent} isVisible={visible} />
            </Drawer>
        </>
    )
}

const MyCategoryList = Form.create<Props>({ name: 'CategoryList' })(CategoryList)
export default connect(mapStateToProps,
    { getallCatalog, getallCategory, addCatagory }
)(MyCategoryList)
