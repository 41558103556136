import React, { useState, useEffect } from 'react'
import { Button, Icon, Upload, message, Modal } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../../../common-file/icon-file'
import { DownloadAnyFile } from '../../../common-misc'

interface Props {
    fileList?: UploadFile[]
    setFileList: Function
}
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

const KnowledgeFormUpload: React.FC<Props> = (props: Props) => {
    const [fileList, setFileList] = useState<UploadFile[]>(props.fileList || [])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()

    useEffect(() => {
        if (props.fileList) {
            props.fileList.forEach((file) => {
                file.thumbUrl = exportIcon(file.type)
            })
            setFileList(props.fileList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fileList])

    const handlePreview = async (file: { name: string, url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        console.log(file)
        if ((file.name as string).includes('image') || ((file.name) as string).includes('jpg') || ((file.name) as string).includes('png') || ((file.name) as string).includes('jpeg')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                props.setFileList(newFileList)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xlsx', 'csv']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only File type : ' + fileTypes.join(','))
                return false
            }
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                console.log(e)
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
                console.log(fileList)

                props.setFileList(fillOffFileList)
            }

            return false
        },
        showUploadList: { showDownloadIcon: false }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    return (
        <>
            <Upload {...uploadProps}
                listType="picture"
                className={'upload-list-inline'}
                fileList={fileList}
                onPreview={(e: any) => handlePreview(e)}
            >
                <Button disabled={fileList.length >= 5}>
                    <Icon type="upload" /> Upload
                </Button>
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
            </Modal>
        </>
    )
}

export default KnowledgeFormUpload
