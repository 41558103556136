import { Dispatch } from 'redux'
import { checkRedirect } from '../../common-misc'
import { decryptBody } from '../../common-misc/encryptBody'
import { axiosGet } from '../rest'
import { Impact } from './model'
import { GetAllImpactSuccessEvent } from './state-event'

export const getAllImpact = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Impact[]>('/api/priorities-level/impact')
            dispatch(GetAllImpactSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err.response)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}
