import { message } from 'antd'
import { convertResponseToFile } from '../../../ReportComponent/components/Services/Service'
import { checkRedirect, herderPostPatch } from '../../../common-misc'
import { decryptBody, encryptBody } from '../../../common-misc/encryptBody'
import { axiosPostDashboard } from '../../../incident-management/restDashboard'

export const getPreviewAssetReport = async (start: string, end: string, page: number, pageSize: number, selectedColumn) => {
    try {
        const body = {
            startDate: start || '',
            endDate: end || null,
            page: page,
            size: pageSize,
            sortBy: 'createdDatetime',
            orderBy: 'asc',
            selectedColumns: selectedColumn
        }
        const encryptData = encryptBody(JSON.stringify(body))
        // const response = await axiosPostDashboard<NewAsset[]>(`/api/ticket/report/preview/allTicket?start=${start}&end=${end}&page=${page}&size=${pageSize}&sort=createdDatetime,asc`, encryptData, herderPostPatch)
        const response = await axiosPostDashboard('/api/assets/report/preview/allAsset', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}

export const getExportAssetReport = async (start: string, end: string, selectedColumn :string[]) => {
    try {
        const body = {
            startDate: start || null,
            endDate: end || null,
            selectedColumns: selectedColumn
        }
        const encryptData = encryptBody(JSON.stringify(body))
        // const response = await axiosPostDashboard<NewAsset[]>(`/api/ticket/report/preview/allTicket?start=${start}&end=${end}&page=${page}&size=${pageSize}&sort=createdDatetime,asc`, encryptData, herderPostPatch)
        const response = await axiosPostDashboard('/api/assets/report/download/allAsset', encryptData, {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        console.log(response)
        convertResponseToFile(response, `[${start} to ${end}] All Asset.xlsx`)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        message.error('Failed to fetch data')
        throw err
    }
}
