import React, { PropsWithChildren } from 'react'
import { Breadcrumb, Card, Tabs } from 'antd'
import { Link } from 'react-router-dom'
import CompanyTab from './CompanyTab'
import DepartmentTab from './DepartmentTab'
// type StateProps = ReturnType<typeof mapStateToProps>

type Props = PropsWithChildren<any>

const CompanyDepartmentForm = (props: Props) => {
    const { TabPane } = Tabs

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Department & Company Setting</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card >
                <h3 className={'main-title'}>Department & Company Setting</h3>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Department" key="1">
                        <DepartmentTab />
                    </TabPane>
                    <TabPane tab="Company" key="2">
                        <CompanyTab />
                    </TabPane>
                </Tabs>
            </Card>
        </>
    )
}

export default CompanyDepartmentForm
