import { Dispatch } from 'redux'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '../rest'

import { SelfServiceCatalog, SelfServiceCatagory, SelfServiceItem, ResponseContentDetails } from './Model'
import {
    GetAllSelfServiceCatalogSuccessEvent,
    GetAllSelfServiceCatagorySuccessEvent,
    AddSelfServiceCatagorySuccessEvent,
    UpdateSelfServiceCatalogSuccessEvent,
    DeleteSelfServiceCatagorySuccessEvent
} from './state-event'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { herderPostPatch } from '../../common-misc'

export const searchCatalog = async (page: number, pageSize: number, sort?: string, order?: string, search?: string, status?: string) => {
    try {
        const config = {
            params: {
                value: search || null,
                status: status || '',
                page: page - 1,
                size: pageSize,
                sort: `${sort || 'createdDatetime'},${order || 'asc'}`
            }
        }
        const response = await axiosGet<ResponseContentDetails<SelfServiceCatalog[]>>('/api/catalogs/pagination', config)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getallCatalog = () => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    page: 0,
                    size: 9999
                }
            }
            const response = await axiosGet<ResponseContentDetails<SelfServiceCatalog[]>>('/api/catalogs/pagination', config)
            dispatch(GetAllSelfServiceCatalogSuccessEvent.build(decryptBody(response.data.data || [])))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const getallCatalogNoRedux = async () => {
    try {
        const config = {
            params: {
                page: 0,
                size: 9999
            }
        }
        const response = await axiosGet<ResponseContentDetails<SelfServiceCatalog[]>>('/api/catalogs/pagination', config)
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getCatalogById = async (id: number) => {
    try {
        const response = await axiosGet<SelfServiceCatalog>(`/api/catalogs/${id}`)
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const addCatalog = async (catalog: SelfServiceCatalog) => {
    try {
        const encryptData = encryptBody(JSON.stringify(catalog))
        const response = await axiosPost<SelfServiceCatalog>('/api/catalogs', encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const updateCatalog = (catalog: SelfServiceCatalog, id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(catalog))
            const response = await axiosPatch<SelfServiceCatalog>(`/api/catalogs/${id}`, encryptData, herderPostPatch)
            dispatch(UpdateSelfServiceCatalogSuccessEvent.build(decryptBody(response.data.data) || catalog))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }

}

export const updateStatusCatalog = async (catalog: SelfServiceCatalog, id: number) => {
    try {
        const encryptData = encryptBody(JSON.stringify(catalog))
        const response = await axiosPatch<SelfServiceCatalog>(`/api/catalogs/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const deleteCatalog = async (id: number) => {
    try {
        const response = await axiosDelete<SelfServiceCatalog>(`/api/catalogs/${id}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

// ====================================================================================================================================
// =========================================================== Caytagory ==============================================================
// ====================================================================================================================================

export const searchCatagory = async (page: number, pageSize: number, catalogId?: string, sort?: string, order?: string, status?: string, parent?: string, search?: string) => {
    try {
        const sortBy = sort === 'parent.name' ? 'parent' : sort
        const config = {
            params: {
                catalogIds: catalogId || '',
                value: search || null,
                statuses: status || [],
                page: page - 1,
                size: pageSize,
                sort: `${sortBy || 'createdDatetime'},${order || 'asc'}`,
                parents: parent || ''
            }
        }
        const response = await axiosGet<ResponseContentDetails<SelfServiceCatagory[]>>('/api/categories/pagination', config)
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getCatagory = async (id: number) => {
    try {
        const response = await axiosGet<SelfServiceCatagory>(`/api/categories/${id}`)
        return decryptBody(response.data.data!! || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getallCategory = () => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                params: {
                    catalogIds: '',
                    value: '',
                    statuses: '',
                    page: 0,
                    size: 9999,
                    sort: 'createdDatetime,asc',
                    parents: ''
                }
            }
            const response = await axiosGet<ResponseContentDetails<SelfServiceCatagory[]>>('/api/categories/pagination', config)
            dispatch(GetAllSelfServiceCatagorySuccessEvent.build(decryptBody(response.data.data || [])))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const getallCategoryNoRedux = async () => {
    try {
        const config = {
            params: {
                catalogIds: '',
                value: '',
                statuses: '',
                page: 0,
                size: 9999,
                sort: 'createdDatetime,asc',
                parents: ''
            }
        }
        const response = await axiosGet<ResponseContentDetails<SelfServiceCatagory[]>>('/api/categories/pagination', config)
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const addCatagory = (category: SelfServiceCatagory) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(category))
            const response = await axiosPost<SelfServiceCatagory>('/api/categories', encryptData, herderPostPatch)
            dispatch(AddSelfServiceCatagorySuccessEvent.build(decryptBody(response.data.data) || category))
            return decryptBody(response.data.data)
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const updateCategory = async (category: SelfServiceCatagory, id: number) => {
    try {
        const encryptData = encryptBody(JSON.stringify(category))
        const response = await axiosPatch<SelfServiceCatalog>(`/api/categories/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const deleteCategoryCatalog = (id: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<SelfServiceCatalog>(`/api/categories/${id}`)
            dispatch(DeleteSelfServiceCatagorySuccessEvent.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
    // }
}

// ====================================================================================================================================
// ============================================================= Items ================================================================
// ====================================================================================================================================

export const searchCatalogItems = async (page: number, pageSize: number, catalogId?: string, catagoryId?: string, sort?: string, order?: string, status?: string, type?: string, search?: string) => {
    try {
        const config = {
            params: {
                catalogIds: catalogId || '',
                categoryIds: catagoryId || '',
                value: search || null,
                types: type || '',
                statuses: status || '',
                page: page - 1,
                size: pageSize,
                sort: `${sort || 'createdDatetime'},${order || 'asc'}`
            }
        }
        const response = await axiosGet<ResponseContentDetails<SelfServiceItem[]>>('/api/items/pagination', config)
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getallItemsNoRedux = async () => {
    try {
        const config = {
            params: {
                catalogIds: '',
                categoryIds: '',
                value: '',
                types: '',
                statuses: '',
                page: 0,
                size: 9999,
                sort: 'createdDatetime,asc'
            }
        }
        const response = await axiosGet<ResponseContentDetails<SelfServiceCatagory[]>>('/api/items/pagination', config)
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getCatalogItemsById = async (id: number) => {
    try {
        const response = await axiosGet<SelfServiceItem>(`/api/items/${id}`)
        return decryptBody(response.data.data || [])
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const addCatalogItem = async (item: SelfServiceItem) => {
    try {
        const encryptData = encryptBody(JSON.stringify(item))
        const response = await axiosPost<SelfServiceCatagory>('/api/items', encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const updateCatalogItem = async (id: number, item: SelfServiceItem) => {
    try {
        const encryptData = encryptBody(JSON.stringify(item))
        const response = await axiosPatch<SelfServiceCatagory>(`/api/items/${id}`, encryptData, herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const deleteItemCatalog = async (id: number) => {
    try {
        const response = await axiosDelete<SelfServiceCatagory>(`/api/items/${id}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const ticketPacksByincidentId = async (incidentTicketId: string) => {
    try {
        const response = await axiosGet(`/api/ticket-packs/tickets/${incidentTicketId}`)
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
