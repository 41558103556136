import React, { useEffect, useState } from 'react'
import { DatePicker, Select } from 'antd'
import moment from 'moment'
import { IncidentFilter } from '../model'

interface Props {
    onChanged: (dateStrings: string | undefined | string[]) => void
    criteria?: IncidentFilter
    isLoading?: Function
}

export const CreatedFilter: React.FC<Props> = (props: Props) => {
    const [showDatePicker, setShowDatePicker] = useState<boolean>()

    const { Option } = Select
    const { RangePicker } = DatePicker
    const dateFormat = 'YYYY-MM-DD'
    const selectTimePeriod = 'Select Time Period'

    const [defaultCreated, setDefaultCreated] = useState<string>()
    const [defaultRangecreatedDatetime, setDefaultRangecreatedDatetime] = useState<any[]>([])

    useEffect(() => {
        setShowDatePicker(false)
        if (props.criteria?.created != null) {
            setDefaultCreated(props.criteria?.created)
            setDefaultRangecreatedDatetime([null, null])
        } else {
            if (props.criteria?.createdStart !== undefined &&
                        props.criteria?.createdEnd !== undefined) {
                if (props.criteria?.createdStart != null &&
                            props.criteria?.createdEnd != null) {
                    setDefaultRangecreatedDatetime([moment(new Date(props.criteria?.createdStart)),
                        moment(new Date(props.criteria?.createdEnd))])
                    setDefaultCreated(selectTimePeriod)
                    setShowDatePicker(true)
                }
            }
        }
    }, [props.criteria])

    const onCreatedChanged = (e:string | undefined | any) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        if (e !== undefined) {
            setDefaultCreated(e)
            setShowDatePicker(false)
            if (e === selectTimePeriod) {
                setShowDatePicker(true)
                if (props.isLoading) {
                    props.isLoading(true)
                }
            } else {
                props.onChanged(e)
            }
        }
        if (e === undefined) {
            setShowDatePicker(false)
            setDefaultCreated(e)
            props.onChanged(e)
        }
    }

    const onCreatedRangeChanged = (e:string[] | undefined | any) => {
        if (props.isLoading) {
            props.isLoading(true)
        }
        if (e !== undefined) {
            if (e[0] !== '' && e[1] !== '') {
                setDefaultRangecreatedDatetime([moment(new Date(e[0])),
                    moment(new Date(e[1]))])
                props.onChanged(e)
            } else {
                setShowDatePicker(false)
                setDefaultCreated(undefined)
                setDefaultRangecreatedDatetime([moment(new Date()),
                    moment(new Date())])
                props.onChanged(undefined)
            }
        }
    }

    return (
        <>
            <p className={'topicName'}>Created</p>
            <Select style={{ width: '100%' }} onChange={(e:any) => { onCreatedChanged(e) }} value={defaultCreated} >
                <Option value="anytime">Any Time</Option>
                <Option value="last5min">Within 5 minutes</Option>
                <Option value="last15min">Within 15 minutes</Option>
                <Option value="last30min">Within 30 minutes</Option>
                <Option value="last1h">Within 1 hour</Option>
                <Option value="last4h">Within 4 hours</Option>
                <Option value="last12h">Within 12 hours</Option>
                <Option value="last24h">Within 24 hours</Option>
                <Option value="today">Today</Option>
                <Option value="yesterday">Yesterday</Option>
                <Option value="thisWeek">This week</Option>
                <Option value="thisMonth">This month</Option>
                <Option value="last30Days">Last 30 days</Option>
                <Option value="last2M">Last 2 months</Option>
                <Option value="last6M">Last 6 months</Option>
                <Option value="Select Time Period">Select Time Period</Option>
            </Select><br />
            {(showDatePicker) ? <>
                <RangePicker className='filterPickCreateFormInc'
                    value={defaultRangecreatedDatetime} style={{marginTop: '10px', marginBottom: '10px', width: '100%' }}
                    format={dateFormat} onChange={(moment, dateStrings) => { onCreatedRangeChanged(dateStrings) }} />
            </> : null}
        </>
    )
}
