import {checkRedirect, herderPostPatch} from '../../common-misc'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'
import {
    axiosDelete,
    axiosGet,
    axiosPatch,
    axiosPost
} from '../../msp-wo/asset-management/rest'
import {
    AssetGroupType,
    FilterData,
    LocationBuilding,
    ModifyBodySearchAsset,
    PMPlan,
    Preventive,
    PreventiveWithRelatedPagination,
    importPM
} from '../Model'

export const getDataLocationBuilding = async () => {
    try {
        const response = await axiosGet<LocationBuilding[]>(
            '/api/locations/options'
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getDataAssetGroup = async () => {
    try {
        const response = await axiosGet<AssetGroupType[]>('/api/asset-group-types')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getDataItems = async (type: string) => {
    try {
        const response = await axiosGet(`/api/assets/searchAll/${type}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAssetByID = async (id: string) => {
    try {
        const response = await axiosGet(`/api/assets/${id}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getDataPMNameOrDeskside = async () => {
    try {
        const response = await axiosGet('/api/users/desk-side')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getDataPMApprove = async () => {
    try {
        const response = await axiosGet('/api/users/pm-approve')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const searchCreatePM = async (search: string, body: ModifyBodySearchAsset, sortBy?: string, orderBy?: string, page?: number, pageSize?: number) => {
    try {
        const requestBody = {
            filter: body.filter,
            selectedColumn: body.selectedColumn,
            search: search || null,
            page: page,
            size: pageSize,
            sortBy: sortBy,
            orderBy: orderBy?.toLocaleUpperCase()
        }
        const encryptData = encryptBody(JSON.stringify(requestBody))
        const response = await axiosPost('/api/assets/search/pm-creation', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const addPMPlan = async (body: PMPlan[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost(
            '/api/pm/pm-plans',
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const updatePMPlan = async (id: string, body: PMPlan[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPatch(
            '/api/pm/' + id + '/open-status',
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const updatePreventiveById = async (id: number, body: Preventive) => {
    try {
        console.log('body', body)
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPatch(
            '/api/pm/' + id,
            encryptData,
            herderPostPatch
        )
        console.log('response', decryptBody(response.data.data!!))
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log('this is : ' + err)
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const deletePMPlan = async (PMID: string) => {
    try {
        const response = await axiosDelete(`/api/pm/${PMID}/pm-plans`)
        return response.data.data!!
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const updateDescriptionPMPlan = async (id, desc?: string) => {
    try {
        const encryptData = encryptBody(JSON.stringify({pmId: id, description: desc}))
        const response = await axiosPatch('/api/pm/description', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAllPreventive = async (
    Search?: string,
    filter?: FilterData,
    Page?: number,
    PageSize?: number,
    sortBy?: string,
    orderBy?: string
) => {
    try {
        const body = {
            page: Page || 0,
            pageSize: PageSize || 10,
            sortBy: sortBy || null,
            orderBy: orderBy?.toLowerCase() || 'desc',
            search: Search || null,
            filter: filter || null
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<PreventiveWithRelatedPagination>(
            '/api/pm/search',
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }

        throw err
    }
}

export const getAllPreventiveForComplete = async (Search?: string, filter?: FilterData, Page?: number, PageSize?: number, sortBy?: string, orderBy?: string) => {
    try {
        const checkFilter = filter ? {...filter} : null
        if (checkFilter?.pmStatus?.length === 0) {
            checkFilter.pmStatus = ['Completed', 'Ignored']
        }
        const body = {
            page: Page || 0,
            pageSize: PageSize || 10,
            sortBy: sortBy || null,
            orderBy: orderBy?.toLowerCase() || 'desc',
            search: Search || null,
            filter: checkFilter || null
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost<PreventiveWithRelatedPagination>('/api/pm/search', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }

        throw err
    }
}

export const getPreventiveById = async (id: number, serialNumber: string) => {
    try {
        const params = {
            serialNumber: serialNumber
        }
        const response = await axiosGet(
            `/api/pm/${id}/detail`, {params}
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getDashboard = async () => {
    try {
        const response = await axiosGet('/api/pm/dashboard')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getPMHistory = async (serialNumber) => {
    try {
        const response = await axiosGet(`/api/pm/${serialNumber}/histories`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getAllSerialNumber = async () => {
    try {
        const response = await axiosGet('/api/assets/searchAll/serialNumber')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const reWorkPMPlan = async (id: number, reason: string) => {
    try {
        const body = {reworkReason: reason}
        const encryptData = encryptBody(JSON.stringify(body || null))
        const response = await axiosPatch(
            `/api/pm/${id}/rework`,
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getHistoryById = async (id, page, pageSize) => {
    try {
        const params = {
            page: page || 1,
            size: pageSize || 10
        }
        const response = await axiosGet(
            `/api/pm/${id}/pm-histories`, {params}
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const getDetailAssetByIDPM = async (
    id: string,
    page: number,
    pageSize: number,
    filter?: FilterData,
    Search?: string,
    isComplete?: boolean
) => {
    try {
        const tempFilter = filter ? {...filter} : null
        if (isComplete && tempFilter?.pmStatus?.length === 0) {
            tempFilter.pmStatus = ['Completed', 'Ignored']
        }
        const body = {
            page: page || 0,
            pageSize: pageSize || 10,
            search: Search || null,
            filter: tempFilter || null
        }
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosPost(
            `/api/pm/${id}/search`,
            encryptData,
            herderPostPatch
        )
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
    }
}

export const getDataInfoPmWorklog = async (idPm?: number) => {
    try {
        const response = await axiosGet(`/api/pm/${idPm}/info`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        if (err?.response?.status) {
            checkRedirect(err?.response?.status)
        }
        throw err
    }
}

export const importPMByExcel = async (data: importPM[]) => {
    try {
        const encryptData = encryptBody(JSON.stringify(data || ''))
        const response = await axiosPost('/api/pm/import', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        if (err?.response.status) {
            // checkRedirect(err?.response.status)
        }
        throw err
    }
}

export const getAllpmTemplate = async () => {
    try {
        const response = await axiosGet('/api/pm-template')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        if (err?.response?.status) {
            // checkRedirect(err?.response?.status)
        }
        throw err
    }
}
