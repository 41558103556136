import { axiosNonAuthPost } from '../rest'
import axios from 'axios'
import { LoginRequestBody, LoginResponse, LoginVspaceRequestBody } from './model'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { herderPostPatch } from '../../common-misc'

export async function loginMspWithVspaceToken(body: LoginRequestBody) {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosNonAuthPost<LoginResponse>('/api/token/loginMSPWithTokenVspace', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function loginVspace(body: LoginVspaceRequestBody) {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosNonAuthPost<LoginResponse>('/api/vspace-user/login', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        throw err.response
    }
}

export async function getIp() {
    try {
        const response = await axios.get('https://api.ipify.org?format=json')
        return response.data.ip as string
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
