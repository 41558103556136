import {axiosNonAuthGet, axiosNonAuthPatch, axiosNonAuthPost, axiosPatch, axiosPost} from '../rest'
import UserShareData from './UserShareData'
import TenantShareData from './TenantShareData'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import {accessToken, herderPostPatch} from '../../common-misc'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'
import { getCookie, setCookie } from '../../guard/route'

export const asyncsessionStorage = {
    setItem: async function (key, value) {
        await Promise.resolve()
        sessionStorage.setItem(key, value)
    },
    getItem: async function (key) {
        await Promise.resolve()
        return sessionStorage.getItem(key)
    }
}

export const loginMSP = async (body) => {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        return await axiosNonAuthPost('/api/securities/authenticate/go-service', encryptData, herderPostPatch).then(response => {
            if (response.status === 200) {
                const searchResult = decryptBody(response.data.data!!)
                if (searchResult.token !== null) {
                    UserShareData.getInstance().token = searchResult.token
                    setCookie('access_token', searchResult.token, 1)
                    // asyncsessionStorage.setItem('access_token', searchResult.token)
                    const decoded: accessToken = jwt_decode(searchResult.token)
                    if (typeof decoded === 'object') {
                        const tenant = decoded?.tenantID || '0'
                        TenantShareData.getInstance().tenant = tenant
                        asyncsessionStorage.setItem('tenant', tenant)
                        // asyncsessionStorage.setItem('service', decoded.service.serviceId.toString())
                    }

                    return axiosNonAuthGet('/api/users/info', {
                        headers: {
                            // Authorization: `Bearer ${searchResult.token}`,
                            tenantID: `${TenantShareData.getInstance().tenant || decoded?.tenantID || '0'}`
                        }
                    }).then((userInfo) => {
                        const convertData = decryptBody(userInfo.data.data)
                        setCookie('access_token', convertData, 1)
                        getCookie('access_token')
                        return userInfo
                    })
                }
            }
            return response
        })
    } catch (error: any) {
        console.error(error)
        throw error
    }
}

export const renewToken = async () => {
    try {
        const response = await axiosPost('/api/securities/token/renew')
        if (response) {
            const Result = decryptBody(response.data.data!!)
            setCookie('access_token', Result.token, 1)
            UserShareData.getInstance().token = Result.token
            return response
        }
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function registerFreeUser(body) {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosNonAuthPost('/api/free-trials/send-mail', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function updateConfirmUser(secretCode) {
    try {
        // const encryptData = encryptBody(JSON.stringify(secretCode))
        const response = await axiosNonAuthPatch('/api/free-trials/confirm/' + secretCode)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function resentMail(email) {
    try {
        const response = await axiosNonAuthGet('/api/free-trials/resend-mail/' + email)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function generate2fa(email) {
    try {
        const encryptData = encryptBody(JSON.stringify({ username: email }))
        const response = await axiosNonAuthPost('/api/google-authenticator/qr-code', encryptData, {
            responseType: 'arraybuffer'
        })
        return response
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function verify2fa(body) {
    try {
        const encryptData = encryptBody(JSON.stringify(body))
        const response = await axiosNonAuthPost('/api/google-authenticator/validate/go-service', encryptData, herderPostPatch)
        const searchResult = decryptBody(response.data.data!!)
        if (searchResult && searchResult.token !== null) {
            UserShareData.getInstance().token = searchResult.token
            setCookie('access_token', searchResult.token, 1)
            // asyncsessionStorage.setItem('access_token', searchResult.token)
            const decoded: accessToken = jwt_decode(searchResult.token)
            if (typeof decoded === 'object') {
                const tenant = decoded?.tenantID || '0'
                TenantShareData.getInstance().tenant = tenant
                asyncsessionStorage.setItem('tenant', tenant)
                // asyncsessionStorage.setItem('service', decoded.service.serviceId.toString())
            }
        }
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function reset2faAll() {
    try {
        const response = await axiosPatch('/api/users/2fa/all', null, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function reset2faByIDUser(id) {
    try {
        const response = await axiosPatch('/api/users/2fa/user/' + id, null, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function getRequestParamMapping() {
    try {
        const response = await axiosNonAuthGet('/api/params')
        return response.data.data!!
    } catch (err: any) {
        console.log(err)
        throw err
    }
}
