import React from 'react'
import {Breadcrumb, Icon, Tabs} from 'antd'
import WorkFlowList from './Components/WorkFlowList'
import { Space } from '../../common-components/Space'

const { TabPane } = Tabs
const WorkFlowHome: React.FC = () => {
    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>Maintain Workflow</Breadcrumb.Item>
                <Breadcrumb.Item>All Workflow</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <Tabs type="card" tabPosition={'left'} >
                <TabPane tab={<span>
                    <Icon type="apartment" />
                        All Workflow
                </span>} key="1" >
                    <WorkFlowList />
                </TabPane>
            </Tabs>
        </>
    )
}

export default WorkFlowHome
