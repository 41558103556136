/* eslint-disable no-mixed-operators */
/* eslint-disable no-useless-escape */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { Button, Card, Col, Empty, Form, Icon, Row, Spin, Table, Tabs, Tag, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import { AssetGroups, PMActivityField } from './model'
// import { DragableBodyRow, RNDContext } from './DragableBodyRow'
// import update from 'immutability-helper'
import { getAllAssetGroup, getAllPMActivityField, getDataCustomfieldPMTemplate, getPMTemplateList } from '../msp-wo/asset-management'
import { StoreState } from '../store'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import AddEditPMTemplate from './AddEditPMTemplate'
import RenderSelectGroup from './component/RenderSelectOption'

const mapStateToProps = (state: StoreState) => {
    return {
        PMActivityField: state.PMActivityField
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllPMActivityField: (assetType: number) => Promise<number>
}

interface Params {
    currenTab?: string
}

type Props = FormComponentProps & StateProps & DispatchProps & Params
// let arr: PMActivityField[] = []
// let listsEdit: number[] = []
// const { confirm } = Modal
const { TabPane } = Tabs
const PMActivityFiled: React.FC<Props> = (props: Props) => {
    // const { resetFields } = props.form
    // const [, updateState] = useState()
    // const forceUpdate = useCallback(() => updateState(Object), [])
    const [data, setData] = useState<PMActivityField[]>([])
    const [, setIsSpinning] = useState(false)
    // const [checkIsUpdate, setCheckIsUpdate] = useState<boolean>(false)
    const [, setIsLoading] = useState<boolean>(false)
    const [isRedirect] = useState<boolean>(false)
    const [selectTicketType, setSelectTicketType] = useState<string>('')
    const [dataAssetGroup, setDataAssetGroup] = useState<AssetGroups[]>([])
    // const [tempListLabel, setTempListLabel] = useState<string[]>([])
    const [, setDuplicatesLabel] = useState<string[]>([])
    const [isMoveRow, setIsMoveRow] = useState<boolean>(false)
    // const [isVisibleTemplateModal, setIsVisibleTemplateModal] = useState<boolean>(false)
    const [tabpaneList, setTabpaneList] = useState<any[]>([])
    const [currenTab, setCurrentTab] = useState<string>()
    const [defaultPMTemplate, setDefaultPMTemplate] = useState<string>()
    // const [isEditPMName, setIsEditPMName] = useState<string>()
    const [, setCheckSubmit] = useState<boolean>(false)
    const [isAddPMTemplate, setIsAddPMTemplate] = useState<boolean>(false)
    const [isLoadingCustomField, setIsLoadingCustomField] = useState<boolean>(false)
    let isSubmit = false

    useEffect(() => {
        console.log(isSubmit)
        getAllAssetGroup().then((res) => {
            setIsLoadingCustomField(true)
            if (res) {
                setDataAssetGroup(res)
                setSelectTicketType(res[0].name)
                initGeneralIncidentSetting(res[0].id?.toString())
                getPMTemplateList(res[0].id).then((res) => {
                    setTabpaneList(res)
                    setCurrentTab(res.find((it) => it.default === true).id.toString())
                    setDefaultPMTemplate(res.find((it) => it.default === true).name || '')
                    getDataCustomfieldPMTemplate(res.find((it) => it.default === true).id).then((res) => {
                        setData(res)
                    }).catch(() => {
                        setTabpaneList([])
                        setCurrentTab(undefined)
                        setDefaultPMTemplate(undefined)
                        setData([])
                    }).finally(() => {
                        setIsLoadingCustomField(false)
                    })
                })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.PMActivityField) {
            const fetchCustomFieldData = props.PMActivityField.map(it => {
                it.createdDate = undefined
                it.createdBy = undefined
                it.lastModifiedBy = undefined
                it.lastModifiedDate = undefined
                it.keyValue = uuid().slice(0, 8)
                return it
            })
            console.log(fetchCustomFieldData)
            // setData(fetchCustomFieldData)
            setIsSpinning(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.PMActivityField])

    useEffect(() => {
        if (isMoveRow) {
            const listLabel2 = data.map((obj) => {
                return obj.activityLabel?.toLocaleLowerCase() || ''
            })

            if (findDuplicates(listLabel2)) {
                setDuplicatesLabel(findDuplicates(listLabel2))
            } else {
                setDuplicatesLabel([])
            }
            setIsMoveRow(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoveRow])

    useEffect(() => {
        if (!isAddPMTemplate) {
            const findType = dataAssetGroup.find((it) => {
                return it.name === selectTicketType
            })?.id || 0
            getPMTemplateList(findType).then((res) => {
                setTabpaneList(res)
                setCurrentTab(res.find((it) => it.default === true).id.toString())
                setDefaultPMTemplate(res.find((it) => it.default === true).name || '')
                getDataCustomfieldPMTemplate(res.find((it) => it.default === true).id).then((res) => {
                    setData(res)
                }).catch(() => {
                    setTabpaneList([])
                    setCurrentTab(undefined)
                    setDefaultPMTemplate(undefined)
                    setData([])
                }).finally(() => {
                    setIsLoadingCustomField(false)
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAddPMTemplate])

    const handleSubmit = (e) => {
        e.preventDefault()
        isSubmit = true
        setCheckSubmit(true)
        props.form.validateFieldsAndScroll((err, values) => {
            if (err && Object.keys(err)?.filter((it) => it !== 'pmName').length > 0 || !defaultPMTemplate) {
                console.log('err')
                isSubmit = false
            } else {
                setIsLoading(true)
                const findType = dataAssetGroup.find((it) => {
                    return it.name === selectTicketType
                })?.id || 0
                console.log('type : ', findType)
                const convertData = data.map((it, i) => {
                    it.activityLabel = it.activityLabel ? it.activityLabel.replace(/ +/g, ' ').trim() : undefined
                    // delete it.keyValue
                    return { ...it, customFieldOrder: i, assetGroup: findType, active: true }
                })
                console.log('complete', convertData)
                setCheckSubmit(false)
            }
        })
    }

    const initGeneralIncidentSetting = (selectTicketType: string) => {
        setIsSpinning(true)
        const findType = dataAssetGroup.find((it) => {
            return it.name === selectTicketType
        })?.id || 1
        // props.getAllPMActivityField(findType).then((res) => {
        //     setCheckIsUpdate(false)
        //     setIsSpinning(false)
        // }).catch((_err) => {
        //     setCheckIsUpdate(true)
        //     setData([])
        //     setIsSpinning(false)
        // })
        getPMTemplateList(findType).then((res) => {
            if (res && res.length > 0) {
                setTabpaneList(res)
                setCurrentTab(res.find((it) => it.default === true).id.toString())
                setDefaultPMTemplate(res.find((it) => it.default === true).name || '')
                getDataCustomfieldPMTemplate(res.find((it) => it.default === true).id).then((res) => {
                    setData(res)
                }).finally(() => {
                    setIsLoadingCustomField(false)
                })
            } else {
                setTabpaneList([])
                setCurrentTab(undefined)
                setDefaultPMTemplate(undefined)
            }
        })
    }

    // const AddData = () => {
    //     arr = []
    //     const listLabel = data.map((obj) => {
    //         return obj.activityLabel || ''
    //     })
    //     setTempListLabel([...new Set(listLabel)])
    //     const smallId = uuid().slice(0, 8)
    //     const initData = {
    //         // id: data.length,
    //         activityLabel: undefined,
    //         description: undefined,
    //         customFieldOrder: undefined,
    //         active: undefined,
    //         assetGroup: undefined,
    //         keyValue: smallId,
    //         isRequireRemark: undefined
    //     } as unknown as PMActivityField
    //     arr.push(initData)
    //     // data.forEach((obj) => {
    //     //     if (obj.id === initData.id) {
    //     //         console.log('++++')
    //     //         console.log(obj.id)
    //     //         initData.id = (obj.id as number)++
    //     //     }
    //     // })
    //     setData((data || []).concat(arr))
    //     forceUpdate()
    // }

    // const validateLabel = (_: any, value: any, callback) => {
    //     if (value) {
    //         // eslint-disable-next-line
    //         const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    //         const pattern = /[A-Za-z0-9ก-๙]+/
    //         // const checkLower = tempListLabel.map((obj) => {
    //         //     return obj.toLowerCase() === value.toLowerCase()
    //         // })
    //         // const checkArray = checkLower.find((label) => {
    //         //     return label === true
    //         // })
    //         if (format.test(value)) {
    //             // eslint-disable-next-line
    //             // callback('Field Label can' + 't contain any of the following characters ' + format)
    //             callback()
    //         } else {
    //             if (pattern.test(value.trimEnd())) {
    //                 // if ((tempListLabel.includes(value) && !isSubmit) || (checkArray && !isSubmit)) {
    //                 //     callback('The Field Label already exists.')
    //                 // } else {
    //                 //     callback()
    //                 // }
    //                 callback()
    //             } else {
    //                 callback('Default value is not a valid')
    //             }
    //         }
    //     } else {
    //         callback()
    //     }
    // }

    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

    // const handleBlurLabel = (value: string) => {
    //     const listLabel2 = data.map((obj) => {
    //         return obj.activityLabel?.toLocaleLowerCase() || ''
    //     })

    //     if (findDuplicates(listLabel2)) {
    //         setDuplicatesLabel(findDuplicates(listLabel2))
    //     } else {
    //         setDuplicatesLabel([])
    //     }

    //     tempListLabel.push(value)
    // }

    const columns = [
        {
            title: <span><span className={'requiredIcon'}>* </span>Field label</span>,
            dataIndex: 'activityLabel',
            key: 'activityLabel'
            // render: (row, data, index) => (
            //     <>
            //         <Form.Item
            //             validateStatus={row.activityLabel === '' ? 'error' : duplicatesLabel.includes(row.activityLabel?.toLocaleLowerCase()) ? 'error' : undefined}
            //             help={row.activityLabel === '' ? 'Default value is not a valid' : duplicatesLabel.includes(row.activityLabel?.toLocaleLowerCase()) ? 'The Field Label already exists.' : undefined}
            //         >
            //             {getFieldDecorator('activityLabel' + row.id + index + row.keyValue, {
            //                 initialValue: row.activityLabel,
            //                 rules: [
            //                     {
            //                         required: true, message: 'Activity label is required'
            //                     },
            //                     {
            //                         validator: !isSubmit ? validateLabel : undefined
            //                     }
            //                 ]
            //             })(
            //                 <Input maxLength={255} onChange={(e) => handleChangeLabel(row, e.target.value)}
            //                     onBlur={(e) => handleBlurLabel(e.target.value)} />
            //             )}
            //         </Form.Item>
            //     </>
            // )
        },
        {
            title: 'Field description',
            dataIndex: 'description',
            key: 'description'
            // render: (row, data, index) => (
            //     <>
            //         <Form.Item>
            //             {getFieldDecorator('description' + row.id + index, {
            //                 initialValue: row.description,
            //                 rules: [{ required: false }]
            //             })(
            //                 <Input maxLength={255} onChange={(e) => handleChangeDescription(row, e.target.value)} />
            //             )}
            //         </Form.Item>
            //     </>
            // )
        },
        {
            title: 'Required remark',
            dataIndex: '',
            key: '',
            width: '10%',
            align: 'center' as const,
            render: (row: PMActivityField) => (
                <>
                    <Tooltip placement="bottom" title={row.isRequireRemark ? 'Require' : 'Not require'}>
                        <Tag color={row.isRequireRemark ? '#1AC487' : 'gray'}> {row.isRequireRemark ? 'Require' : 'Not require'}</Tag>
                    </Tooltip>
                </>
            )
        }
    ]

    // const toggleActiveness = (data: PMActivityField) => {
    //     data.isRequireRemark = !data.isRequireRemark
    //     setCheckIsUpdate(!checkIsUpdate)
    //     forceUpdate()
    // }

    // const addAfterRow = (currentRow, index) => {
    //     arr = []
    //     const listLabel = data.map((obj) => {
    //         return obj.activityLabel || ''
    //     })
    //     setTempListLabel([...new Set(listLabel)])
    //     const smallId = uuid().slice(0, 8)
    //     data.forEach((obj) => {
    //         if (Number(obj.id) >= currentRow + 1) {
    //             obj.id = Number(obj.id) + 1
    //         }
    //     })
    //     console.log(index)
    //     data.splice(index + 1, 0, {
    //         id: undefined,
    //         activityLabel: undefined,
    //         description: undefined,
    //         customFieldOrder: undefined,
    //         active: undefined,
    //         assetGroup: undefined,
    //         keyValue: smallId,
    //         isRequireRemark: undefined
    //     })
    //     resetFields()
    //     console.log(data)
    //     setData((data || []).concat(arr))
    //     // setData(data)
    //     forceUpdate()
    // }

    // const deleteRow = (index, value, myData) => {
    //     confirm({
    //         title: 'Are you sure?',
    //         content: 'Do you confirm Delete this data?',
    //         okText: 'OK',
    //         okType: 'danger',
    //         autoFocusButton: 'cancel',
    //         cancelText: 'Cancel',
    //         async onOk() {
    //             const tempData2 = data.filter((_obj) => _obj?.id !== myData?.id)
    //             setData(tempData2)
    //             message.success('Delete data successfully.')
    //         },
    //         onCancel() {
    //         }
    //     })
    // }

    // const deleteRow = (index, value, myData) => {
    //     confirm({
    //         title: 'Are you sure?',
    //         content: 'Do you confirm Delete this data?',
    //         okText: 'OK',
    //         okType: 'danger',
    //         autoFocusButton: 'cancel',
    //         cancelText: 'Cancel',
    //         async onOk() {
    //             const tempData2 = data.filter((_obj) => _obj?.keyValue !== myData?.keyValue)
    //             setData(tempData2)
    //             message.success('Delete data successfully.')
    //         },
    //         onCancel() { }
    //     })
    // }

    // const handleChangeLabel = (row: PMActivityField, value: string) => {
    //     row.activityLabel = value
    //     if (row.id) {
    //         listsEdit.push(row.id)
    //     }
    // }

    // const handleChangeDescription = (row: PMActivityField, value: string) => {
    //     row.description = value
    //     if (row.id) {
    //         listsEdit.push(row.id)
    //     }
    // }

    // const components = {
    //     body: {
    //         row: DragableBodyRow
    //     }
    // }

    // const moveRow = useCallback(
    //     (dragIndex, hoverIndex) => {
    //         const dragRow = data[dragIndex]
    //         setData(
    //             update(data, {
    //                 $splice: [
    //                     [dragIndex, 1],
    //                     [hoverIndex, 0, dragRow]
    //                 ]
    //             })
    //         )
    //     },
    //     [data]
    // )

    // const manager = useRef(RNDContext)

    const renderTable = () => {
        return (
            // <DndProvider manager={manager.current.dragDropManager!!}>
            <Spin spinning={isLoadingCustomField}>
                <Table
                    columns={columns}
                    dataSource={data}
                    // components={components}
                    // onRow={(record, index) => ({
                    //     index,
                    //     moveRow
                    // })}
                    // scroll={{ x: 768 }}
                    className='Table-View-CustomField'
                />
            </Spin>
            // </DndProvider>
        )
    }

    // const goBack = () => {
    //     confirm({
    //         title: 'Are you sure?',
    //         content: 'You want to leave this page?',
    //         okText: 'Yes',
    //         cancelText: 'No',
    //         onOk() {
    //             listsEdit = []
    //             setIsRedirect(true)
    //         },
    //         onCancel() {
    //             console.log('Cancel')
    //         }
    //     })
    // }

    // const menu = () => {
    //     return (
    //         <Menu>
    //             {dataAssetGroup.map((it, index) => {
    //                 return (<Menu.Item key={index} onClick={() => setTicketTypes(it.name, it.id!!)}>
    //                     <span>{it.name}</span>
    //                 </Menu.Item>)
    //             })}
    //         </Menu>
    //     )
    // }

    const setTicketTypes = (text: string, id: number) => {
        setSelectTicketType(text)
        initGeneralIncidentSetting(text)
    }

    const onChangeTabpane = (currenTab) => {
        const tempdata = tabpaneList.find((it) => it.id.toString() === currenTab)
        console.log(tempdata)
        setCurrentTab(currenTab)
        setIsLoadingCustomField(true)
        getDataCustomfieldPMTemplate(Number(currenTab)).then((res) => {
            setData(res)
        }).finally(() => {
            setIsLoadingCustomField(false)
        })
    }

    return (
        <>
            {isRedirect ? <Redirect to='Setting' /> : null}
            {isAddPMTemplate ? <AddEditPMTemplate isAddPMTemplate={isAddPMTemplate} setIsAddPMTemplate={setIsAddPMTemplate} dataAssetGroup={dataAssetGroup} selectTicketType={selectTicketType} tabpanList={tabpaneList} />
                : <Card style={{ overflow: 'auto' }}>
                    <Row>
                        <Col span={22}>
                            <h3 className={'main-title'}>Set PM activity</h3>
                        </Col>
                        {/* <Col span={2} >
                            <Button style={{ float: 'right' }} type="primary" onClick={() => { setIsAddPMTemplate(true) }} htmlType="button" className="addCriteriaButton"> <Icon type="plus" />Add/Edit Template</Button>
                        </Col> */}
                    </Row>
                    <div className='customfield-pm-group-container'>
                        <Row type='flex' align='middle'>
                            <Col span={6}>
                                {/* <span style={{ color: 'red' }}>* <span style={{ color: '#323276' }}>Group : </span></span>
                                <Dropdown overlay={() => menu()} trigger={['click']}>
                                    <span style={{ color: '#323276', fontWeight: 'bold' }}>
                                        {selectTicketType} <Icon type="down" />
                                    </span>
                                </Dropdown> */}
                                <RenderSelectGroup header={'Group'} groupList={dataAssetGroup} selectTicketType={selectTicketType} setTicketTypes={setTicketTypes} />
                            </Col>
                            <Col span={12} />
                            <Col span={6}>
                                <Row type='flex' justify='end' >
                                    <RenderSelectGroup header={'Default PM activity'} groupList={tabpaneList} selectTicketType={defaultPMTemplate} setTicketTypes={setDefaultPMTemplate} isDisabled={true} />
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <Card>
                        <Row>
                            <Col span={22}>
                                <h3 className={'main-title'}>Group :  {selectTicketType}</h3>
                            </Col>
                            <Col span={2} >
                                <Button style={{ float: 'right' }} type="primary" onClick={() => { setIsAddPMTemplate(true) }} htmlType="button" className="addCriteriaButton"> <Icon type="plus" />Add/Edit Template</Button>
                            </Col>
                        </Row>
                        {tabpaneList.length > 0 ? <Tabs
                            hideAdd
                            onChange={onChangeTabpane}
                            activeKey={currenTab}
                            type="editable-card"

                        >
                            {tabpaneList?.map((it) => {
                                return <TabPane tab={it.name.length < 19 ? it.name : <Tooltip title={it.name} placement='bottom'>{it.name.slice(0, 19)}...</Tooltip>} key={it.id.toString()} closable={false}>
                                    <h3 className={'main-title'}>Template name : {it.name}</h3>
                                    <br /><br />
                                    <Form onSubmit={handleSubmit}>
                                        {renderTable()}
                                        {/* <Form.Item style={{ float: 'right', marginTop: 10 }}>
                                            <Button htmlType="button"
                                                onClick={() => goBack()}
                                                style={{ marginRight: 10 }}>
                                                Cancel
                                            </Button>
                                            <Button type="primary" htmlType="submit" loading={isLoading}>
                                                Submit
                                            </Button>
                                        </Form.Item> */}
                                    </Form>
                                </TabPane>
                            })}

                        </Tabs> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                    </Card>

                </Card>}
        </>
    )
}

const PMActivityFields = Form.create<Props>()(PMActivityFiled)
export default connect(mapStateToProps, {
    getAllPMActivityField
})(PMActivityFields)
