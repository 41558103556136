import {Dispatch} from 'redux'
import {axiosGet} from '../rest'
import {GetAllStatusSuccessEvent} from './state-event'
import {TicketStatus} from '../incident'
import {checkRedirect} from '../../common-misc'
import {decryptBody} from '../../common-misc/encryptBody'

export const getAllStatus = (ticketType: string | undefined) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<TicketStatus[]>('/api/statuses/' + (ticketType || 'Incident'))
            if (response.data.data) {
                dispatch(GetAllStatusSuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err: any) {
            console.log(err?.response)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export async function getAllStatusUnique(): Promise<TicketStatus[]> {
    try {
        const response = await axiosGet<TicketStatus[]>('/api/statuses/allUnique')
        return decryptBody(response.data.data!!)
    } catch (error: any) {
        console.log(error)
        checkRedirect(error.response.status)
        throw error
    }
}

export const getTicketStatus = (ticketStatus: TicketStatus[], ticketType : string, status: string):TicketStatus => {
    return ticketStatus.find(it => it.status === status && it.ticketType === ticketType)!!
}
