/* eslint-disable no-unused-expressions */
/* eslint-disable react/display-name */
import {Breadcrumb, Button, Card, Drawer, Dropdown, Icon, Input, Menu, message, Modal, Row, Switch, Table, Tag, Tooltip, Upload} from 'antd'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {StoreState} from '../../store'
import {connect} from 'react-redux'
import {filterPeopleManagementType, importPeopleError, People} from './model'
import XLSX from 'sheetjs-style'
import {UploadFile} from 'antd/lib/upload/interface'
import {
    editPeople,
    genReportPeople,
    // getAllDepartmentAuth,
    // getAllUserNoPage,
    getUserRoleWithPagination,
    // importCompanyAuth,
    // importDepartmentAuth,
    importPeople,
    // importUserAuth
} from '../user-role/duck/service'
import WrappedFormPeople from './PeopleForm'
import {encodeSpecialCharacter, nullOrEmptyObject} from '../../common-misc'
import FilterPeople from './FilterPeople'
import {downloadTempleteUsers, importUserAuthFromBE, showActiveRoleList} from '../user-role/duck/action'
import ViewPeople from './ViewPeople'
import ReactHtmlParser from 'react-html-parser'
// import {createHmac} from 'crypto'
import {decryptBody} from '../../common-misc/encryptBody'
// import moment from 'moment'
import { getExportUser } from '../../msp-portal'

const { Search } = Input
const { confirm } = Modal

const mapStateToProps = (state: StoreState) => {
    return {
        people: state.people,
        peopleManagementPagination: state.peopleManagementPagination,
        notify: state.notify,
        user_roles: state.userRoles,
        userRolesPagination: state.userRolesPagination,
        roles: (state.roles || [])
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    // getPeopleWithPage: (page: number, pageSize: number, searchValue?: string) => Promise<number>
    importPeople: (s: People[]) => Promise<number>
    genReportPeople: () => Promise<number>
    getUserRoleWithPagination: (page: number, pageSize: number, searchValue: string, selectedRoles?: filterPeopleManagementType) => Promise<number>
    showActiveRoleList: () => Promise<number>
    editPeople: (id: number, s: People) => Promise<number>
}

type Props = StateProps & DispatchProps
const pageSize = 10
const PeopleManagement: React.FC<Props> = (props: Props) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isModalVisible2, setIsModalVisible2] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [dataEdit, setDataEdit] = useState<People>()
    const [loading, setLoading] = useState<boolean>(false)
    const [, setMyFile] = useState<UploadFile[]>([])
    const [loadingExport, setLoadingExport] = useState<boolean>(false)
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [filterUser, setFilterUser] = useState<filterPeopleManagementType>()
    const [searchValue, setSearchValue] = useState<string>('')
    const [isSearchComplete, setIsSearchComplete] = useState<boolean>(false)
    const [isViewUser, setIsViewUser] = useState<boolean>(false)
    const [dataView, setDataView] = useState<People>()
    // const testEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    // const testPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W+_])[a-zA-Z\d!\W+_]{8,20}$/
    // const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    // const pattern = /^[ูA-Za-z0-9ก-๙]{3}/
    const text = /[!@#$%&*_+\-=[\]{};':"\\|,.<>?]/
    const [targetLoading, setTargetLoading] = useState<number>()
    const [toggleGoserviceLoading, setToggleGoserviceLoading] = useState<boolean>(false)
    const [toggleSelfLoading, setToggleSelfLoading] = useState<boolean>(false)

    useEffect(() => {
        props.showActiveRoleList().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(true)
        if ((filterUser || searchValue !== '') && isSearchComplete) {
            props.getUserRoleWithPagination(1, pageSize, encodeSpecialCharacter(searchValue), filterUser).finally(() => {
                setLoading(false)
                setIsSearchComplete(false)
            })
        } else {
            props.getUserRoleWithPagination(page, pageSize, encodeSpecialCharacter(searchValue), filterUser).finally(() => { setLoading(false) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterUser])

    const viewUser = (data) => {
        setDataView(data)
        setIsViewUser(true)
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '3%',
            render: (row) => (
                <>
                    <div style={{ textAlign: 'center' }}>
                        <Tooltip placement="bottom" title={'Edit'}>
                            <Icon className="edit_icon" type="edit" onClick={() => selectEdit(row)} /></Tooltip>
                    </div>
                </>
            )
        },
        {
            title: 'Name',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (record, data) => (
                <div className='feck-link' onClick={() => viewUser(data)}>{record}</div>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%'
        },
        {
            title: 'Roles',
            dataIndex: '',
            key: '',
            width: '25%',
            render: (record) => {
                const tempRoles: string[] = []
                if (record.roles) {
                    record.roles?.forEach((filter: { name: string }, index: number) => {
                        if (index > 1) {
                            tempRoles.push('- ' + filter.name)
                        }
                    })
                }

                return (
                    <span>
                        {record.roles?.map((e, idx) => {
                            if (idx <= 1) {
                                return <Tag color='geekblue' key={idx}>{e.name}</Tag>
                            }
                        })}
                        {record.roles?.length > 2 && <Tooltip placement='bottom' title={ReactHtmlParser(tempRoles.join('</br>'))}><Tag color='geekblue'> +{record.roles.length - 2}</Tag> </Tooltip> }
                    </span>
                )
            }
        },
        {
            title: 'Confirmed Email',
            dataIndex: '',
            key: '',
            align: 'center' as const,
            width: '15%',
            render: (record) => (
                <>
                    <span>  {record.confirmed ? 'Confirmed' : 'Not-Confirmed'} </span>
                </>
            )
        },
        {
            title: 'Goservice Access',
            dataIndex: '',
            key: '',
            render: (record) => (
                <>
                    <Tooltip placement="bottom" title={record.goServiceAccess ? 'Deactivate' : 'Activate'}>
                        <Switch onChange={(checked) => showConfirm('goservice', record)} checked={record.goServiceAccess} loading={targetLoading === record.id ? toggleGoserviceLoading : false}></Switch>
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Self-Portal Access',
            dataIndex: '',
            key: '',
            render: (row, record) => (
                <>
                    <Tooltip placement="bottom" title={record.selfPortalAccess ? 'Deactivate' : 'Activate'}>
                        <Switch onChange={(checked) => showConfirm('selfservice', record)} checked={record.selfPortalAccess} loading={targetLoading === record.id ? toggleSelfLoading : false}></Switch>
                    </Tooltip>
                </>
            )
        }
    ]

    const selectEdit = (row: People) => {
        setDataEdit(row)
        setIsModalVisible2(true)
    }

    const getValueCreate = (value) => {
        setIsModalVisible(false)
        setIsModalVisible2(false)
        if (value) {
            setLoading(true)
            props.getUserRoleWithPagination(page, pageSize, encodeSpecialCharacter(''), filterUser).finally(() => { setLoading(false) })
        }
    }

    const handleOnClickPageNumber = (page: number) => {
        setPage(page)
    }

    const searchData = (value) => {
        setSearchValue(value)
        setPage(1)
        setIsSearchComplete(true)
        setLoading(true)
        props.getUserRoleWithPagination(1, pageSize, encodeSpecialCharacter(value), filterUser).finally(() => {
            setIsSearchComplete(false)
        }).finally(() => {
            setLoading(false)
        })
    }

    // function checkAndDeleteUndefinedKeys(obj) {
    //     for (const key in obj) {
    //         // eslint-disable-next-line no-prototype-builtins
    //         if (obj.hasOwnProperty(key) && obj[key] === undefined) {
    //             delete obj[key]
    //         }
    //     }
    // }

    function Warning(Text: string) {
        Modal.warning({
            title: 'Warning',
            content: Text,
            onOk: () => {
                setLoading(false)
                Modal.destroyAll()
            },
            className: 'modal-not-show-close'
        })
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setMyFile(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                // console.log(info.file, info.fileList)
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`)
            } else if (info.file.status === 'error') {
                // message.error(`${info.file.name} file upload failed.`)
                setMyFile([])
            }
        },
        beforeUpload: (file: any) => {
            const isXlsx = file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            if (isXlsx) {
                message.error('You can only upload xlsx file!')
                setMyFile([])
            } else {
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onloadend = function (e: any) {
                    const bstr = e.target.result
                    const wb = XLSX.read(bstr, { type: 'binary' })
                    /* Get first worksheet */
                    let dataUser: any[] = []
                    let dataCompany: any[] = []
                    let dataDepartment: any[] = []
                    const AllData: any = {
                        users: [],
                        companies: [],
                        departments: []
                    }
                    // const promiseAll: any[] = []
                    // const secret = 'MSPTeamWuhoo'
                    
                    if (wb.SheetNames.length !== 0) {
                        wb.SheetNames.forEach((name, index) => {
                            if (name === 'User') {
                                const wsName = wb.SheetNames[index]
                                const ws = wb.Sheets[wsName]
                                dataUser = XLSX.utils.sheet_to_json(ws)
                                const data = dataUser.map(obj => {
                                    return {
                                        firstName: obj['First Name'],
                                        lastName: obj['Last Name'],
                                        email: obj.Email,
                                        phoneNumber: obj['Phone Number'],
                                        companyName: obj['Company Name'],
                                        employeeId: obj['Employee Id'],
                                        departmentName: obj['Department Name'],
                                        defaultPassword: obj['Default Password'],
                                        isConfirmed: true
                                    }
                                })
                                console.log(data)
                                if (data) {
                                    data.forEach((it, index) => {
                                        AllData.users.push(it)
                                        // checkAndDeleteUndefinedKeys(it)
                                        // if (data[index]?.hasOwnProperty('firstName') && data[index]?.hasOwnProperty('lastName') && data[index]?.hasOwnProperty('email') &&
                                        //     data[index]?.hasOwnProperty('employeeId') && data[index]?.hasOwnProperty('defaultPassword')) {
                                        //     if (it.defaultPassword) {
                                        //         if (!testPassword.test(it.defaultPassword)) {
                                        //         // message.error('password error')
                                        //             it.defaultPassword = null
                                        //         } else {
                                        //             const password = createHmac('sha256', secret)
                                        //                 .update(it.defaultPassword)
                                        //                 .digest('hex')
                                        //             it.defaultPassword = password
                                        //         }
                                        //     }
                                        //     AllData.users.push(it)
                                        //     // promiseAll.push(importUserAuth(dataUser))
                                        // } else {
                                        //     message.error('Failed to save. You upload file incorrect format!!')
                                        //     AllData.users.push(it)
                                        // }
                                    })
                                }
                            } else if (name === 'Company') {
                                const wsName1 = wb.SheetNames[index]
                                const ws1 = wb.Sheets[wsName1]
                                dataCompany = XLSX.utils.sheet_to_json(ws1)
                                const convertDataCompany = dataCompany.map((obj) => {
                                    return {
                                        companyName: obj['Company Name'],
                                        companyAddress: obj['Company Address']
                                    }
                                })

                                if (convertDataCompany) {
                                    convertDataCompany.forEach((it, i) => {
                                        AllData.companies.push(it)
                                        // checkAndDeleteUndefinedKeys(it)
                                        // if (convertDataCompany[i]?.hasOwnProperty('companyName') && convertDataCompany[i]?.hasOwnProperty('companyAddress')) {
                                        //     AllData.companies.push(it)
                                        //     // promiseAll.push(importCompanyAuth(dataCompany))
                                        // } else {
                                        //     message.error('Failed to save. You upload file incorrect format!!')
                                        //     AllData.companies.push(it)
                                        // }
                                    })
                                }
                            } else if (name === 'Department') {
                                const wsName2 = wb.SheetNames[index]
                                const ws2 = wb.Sheets[wsName2]
                                dataDepartment = XLSX.utils.sheet_to_json(ws2)
                                const convertDataDepartment = dataDepartment.map((obj) => {
                                    return {
                                        departmentName: obj['Department Name']
                                    }
                                })

                                if (convertDataDepartment) {
                                    convertDataDepartment.forEach((it, i) => {
                                        AllData.departments.push(it)
                                        // checkAndDeleteUndefinedKeys(it)
                                        // if (convertDataDepartment[i]?.hasOwnProperty('departmentName')) {
                                        //     AllData.departments.push(it)
                                        //     // promiseAll.push(importDepartmentAuth(dataDepartment))
                                        // } else {
                                        //     message.error('Failed to save. You upload file incorrect format!!')
                                        //     AllData.departments.push(it)
                                        // }
                                    })
                                }
                            } else {
                                message.error('Failed to save. You upload file incorrect format!!')
                            }
                        })
                    } else {
                        message.error('Failed to save. You upload file incorrect format!!')
                        return
                    }
                    console.log(AllData)
                    // if (AllData.users.length !== 0) {
                    if (AllData.users.length >= 5001 || AllData.companies.length >= 5001 || AllData.departments.length >= 5001) {
                        Warning('The data import has exceeded the maximum limit of 5000 users.')
                        return
                    } else {
                        Warning('This file is currently being imported and verified. Please wait.')
                    }
                    setLoading(true)
                    importUserAuthFromBE(AllData).then((res) => {
                        // message.success('You have Successfully import the data.')
                        props.getUserRoleWithPagination(1, pageSize, encodeSpecialCharacter(searchValue), filterUser)
                    }).catch((err) => {
                        console.log(err.response.data.message)
                        if (err.status === 400) {
                            const data: importPeopleError = decryptBody(err.data)
                            console.log(data)
                            let textNumberRowDataIsNull = ''
                            let textNumberRowEmployeeIdDup = ''
                            let textNumberRowCitizenIdWrong = ''
                            let textNumberRowEmailDup = ''
                            let TextNumberRowExceedLimit = ''
                            let TextNumberRowInvalidDefaultPassword = ''
                            let TextNumberRowInvalidCompanyOrDepartment = ''
                            let TextNumberRowInvalidDataFormat = ''
                            let TextBlankRows = ''
                            let TextDuplicateRows = ''

                            let numberRowDataIsNull: string = ''
                            let numberRowEmployeeIdDup: string = ''
                            let numberCitizenIdWrong: string = ''
                            let numberRowEmailDup: string = ''
                            let numberRowExceedLimit = ''
                            let numberRowInvalidDefaultPassword = ''
                            let numberRowInvalidCompanyOrDepartment = ''
                            let numberRowInvalidDataFormat = ''
                            let numberBlankRows = ''
                            let numberDuplicateRows = ''

                            if (data.rowDataIsNull?.length > 0) {
                                data.rowDataIsNull.forEach((res) => {
                                    numberRowDataIsNull = numberRowDataIsNull === '' ? res : numberRowDataIsNull + ', ' + res
                                })
                                textNumberRowDataIsNull = 'Please Input FirstName, LastName, Phone Number, Email, Employee ID and DefaultPassword.'
                                numberRowDataIsNull = 'Excel Row No. ' + numberRowDataIsNull
                            }
                            if (data.rowEmployeeIdDup?.length > 0) {
                                data.rowEmployeeIdDup.forEach((res) => {
                                    numberRowEmployeeIdDup = numberRowEmployeeIdDup === '' ? res : numberRowEmployeeIdDup + ', ' + res
                                })
                                textNumberRowEmployeeIdDup = 'Please do not use duplicate Employee ID.'
                                numberRowEmployeeIdDup = 'Excel Row No. ' + numberRowEmployeeIdDup
                            }
                            if (data.rowValidateCitizenId?.length > 0) {
                                data.rowValidateCitizenId.forEach((res) => {
                                    numberCitizenIdWrong = numberCitizenIdWrong === '' ? res : numberCitizenIdWrong + ', ' + res
                                })
                                textNumberRowCitizenIdWrong = 'For Citizen ID, please enter a 13 digit using numbers or blank data only'
                                numberCitizenIdWrong = 'Excel Row No. ' + numberCitizenIdWrong
                            }
                            if (data.rowEmailDup?.length > 0) {
                                data.rowEmailDup.forEach((res) => {
                                    numberRowEmailDup = numberRowEmailDup === '' ? res : numberRowEmailDup + ', ' + res
                                })
                                textNumberRowEmailDup = 'This email already exists in the system. It may be used by some person in another tenant.'
                                numberRowEmailDup = 'Excel Row No. ' + numberRowEmailDup
                            }
                            if (data.rowExceedLimit?.length > 0) {
                                data.rowExceedLimit.forEach((res) => {
                                    numberRowExceedLimit = numberRowExceedLimit === '' ? res : numberRowExceedLimit + ', ' + res
                                })
                                TextNumberRowExceedLimit = 'Some values in these field may cause a problem, Please check FirstName, LastName, Phone Number, or Email in accordance with the policy.'
                                numberRowExceedLimit = 'Excel Row No. ' + numberRowExceedLimit
                            }
                            if (data.rowInvalidDefaultPassword?.length > 0) {
                                data.rowInvalidDefaultPassword.forEach((res) => {
                                    numberRowInvalidDefaultPassword = numberRowInvalidDefaultPassword === '' ? res : numberRowInvalidDefaultPassword + ', ' + res
                                })
                                TextNumberRowInvalidDefaultPassword = 'Please input your password in accordance with the password policy requirements.'
                                numberRowInvalidDefaultPassword = 'Excel Row No. ' + numberRowInvalidDefaultPassword
                            }
                            if (data.rowInvalidCompanyOrDepartment?.length > 0) {
                                data.rowInvalidCompanyOrDepartment.forEach((res) => {
                                    numberRowInvalidCompanyOrDepartment = numberRowInvalidCompanyOrDepartment === '' ? res : numberRowInvalidCompanyOrDepartment + ', ' + res
                                })
                                TextNumberRowInvalidCompanyOrDepartment = 'Department or Company Name was wrong, There is no match of this value in your tenant.'
                                numberRowInvalidCompanyOrDepartment = 'Excel Row No. ' + numberRowInvalidCompanyOrDepartment
                            }
                            if (data.rowInvalidDataFormat?.length > 0) {
                                data.rowInvalidDataFormat.forEach((res) => {
                                    numberRowInvalidDataFormat = numberRowInvalidDataFormat === '' ? res : numberRowInvalidDataFormat + ', ' + res
                                })
                                TextNumberRowInvalidDataFormat = 'Please Input FirstName, LastName, Phone Number and Email in accordance with the policy requirements.'
                                numberRowInvalidDataFormat = 'Excel Row No. ' + numberRowInvalidDataFormat
                            }
                            if (data.blankRows?.length > 0) {
                                data.blankRows.forEach((res) => {
                                    numberBlankRows = numberBlankRows === '' ? res : numberBlankRows + ', ' + res
                                })
                                TextBlankRows = 'The Company name is blank, Please give a value in the Company sheet.'
                                numberBlankRows = 'Excel Row No. ' + numberBlankRows
                            }
                            if (data.duplicateRows?.length > 0) {
                                data.duplicateRows.forEach((res) => {
                                    numberDuplicateRows = numberDuplicateRows === '' ? res : numberDuplicateRows + ', ' + res
                                })
                                TextDuplicateRows = 'Import file has company name and company address are already in the system. Please check data in the file again.'
                                numberDuplicateRows = 'Excel Row No. ' + numberDuplicateRows
                            }
                            Modal.warning({
                                title: <b style={{ color: '#323276' }}>Warning</b>,
                                content: renderResultImportError(textNumberRowDataIsNull, numberRowDataIsNull, textNumberRowEmployeeIdDup, numberRowEmployeeIdDup, textNumberRowCitizenIdWrong, numberCitizenIdWrong, textNumberRowEmailDup, numberRowEmailDup,
                                    TextNumberRowExceedLimit, numberRowExceedLimit, TextNumberRowInvalidDefaultPassword, numberRowInvalidDefaultPassword, TextNumberRowInvalidCompanyOrDepartment, numberRowInvalidCompanyOrDepartment, TextNumberRowInvalidDataFormat, numberRowInvalidDataFormat,
                                    TextBlankRows, numberBlankRows, TextDuplicateRows, numberDuplicateRows),
                                okText: 'OK',
                                okType: 'danger',
                                onOk: () => {
                                    window.location.reload()
                                },
                                onCancel: () => {
                                    window.location.reload()
                                }
                            })
                        } else {
                            message.error('Failed to save')
                        }
                    }).finally(() => {
                        setLoading(false)
                    })
                    // Promise.all(promiseAll).then((res) => {
                    //     message.success('You have Successfully import the data.')
                    //     props.getUserRoleWithPagination(1, pageSize, encodeSpecialCharacter(searchValue), filterUser)
                    // })
                    // .catch((err) => {
                    //     console.log(err)
                    //     if (err.status === 400) {
                    //         const data: importPeopleError = decryptBody(err.data)
                    //         console.log(data)
                    //         let textNumberRowDataIsNull = ''
                    //         let textNumberRowEmployeeIdDup = ''
                    //         let textNumberRowCitizenIdWrong = ''
                    //         let textNumberRowEmailDup = ''
                    //         let TextNumberRowExceedLimit = ''
                    //         let TextNumberRowInvalidDefaultPassword = ''
                    //         let TextNumberRowInvalidCompanyOrDepartment = ''
                    //         let TextNumberRowInvalidDataFormat = ''
                    //         let TextBlankRows = ''
                    //         let TextDuplicateRows = ''

                    //         let numberRowDataIsNull: string = ''
                    //         let numberRowEmployeeIdDup: string = ''
                    //         let numberCitizenIdWrong: string = ''
                    //         let numberRowEmailDup: string = ''
                    //         let numberRowExceedLimit = ''
                    //         let numberRowInvalidDefaultPassword = ''
                    //         let numberRowInvalidCompanyOrDepartment = ''
                    //         let numberRowInvalidDataFormat = ''
                    //         let numberBlankRows = ''
                    //         let numberDuplicateRows = ''

                    //         if (data.rowDataIsNull?.length > 0) {
                    //             data.rowDataIsNull.forEach((res) => {
                    //                 numberRowDataIsNull = numberRowDataIsNull === '' ? res : numberRowDataIsNull + ', ' + res
                    //             })
                    //             textNumberRowDataIsNull = 'Please Input FirstName, LastName, Phone Number, Email, Employee ID and DefaultPassword.'
                    //             numberRowDataIsNull = 'Excel Row No. ' + numberRowDataIsNull
                    //         }
                    //         if (data.rowEmployeeIdDup?.length > 0) {
                    //             data.rowEmployeeIdDup.forEach((res) => {
                    //                 numberRowEmployeeIdDup = numberRowEmployeeIdDup === '' ? res : numberRowEmployeeIdDup + ', ' + res
                    //             })
                    //             textNumberRowEmployeeIdDup = 'Please do not use duplicate Employee ID.'
                    //             numberRowEmployeeIdDup = 'Excel Row No. ' + numberRowEmployeeIdDup
                    //         }
                    //         if (data.rowValidateCitizenId?.length > 0) {
                    //             data.rowValidateCitizenId.forEach((res) => {
                    //                 numberCitizenIdWrong = numberCitizenIdWrong === '' ? res : numberCitizenIdWrong + ', ' + res
                    //             })
                    //             textNumberRowCitizenIdWrong = 'For Citizen ID, please enter a 13 digit using numbers or blank data only'
                    //             numberCitizenIdWrong = 'Excel Row No. ' + numberCitizenIdWrong
                    //         }
                    //         if (data.rowEmailDup?.length > 0) {
                    //             data.rowEmailDup.forEach((res) => {
                    //                 numberRowEmailDup = numberRowEmailDup === '' ? res : numberRowEmailDup + ', ' + res
                    //             })
                    //             textNumberRowEmailDup = 'This email already exists in the system. It may be used by some person in another tenant.'
                    //             numberRowEmailDup = 'Excel Row No. ' + numberRowEmailDup
                    //         }
                    //         if (data.rowExceedLimit?.length > 0) {
                    //             data.rowExceedLimit.forEach((res) => {
                    //                 numberRowExceedLimit = numberRowExceedLimit === '' ? res : numberRowExceedLimit + ', ' + res
                    //             })
                    //             TextNumberRowExceedLimit = 'Some values in these field may cause a problem, Please check FirstName, LastName, Phone Number, or Email in accordance with the policy.'
                    //             numberRowExceedLimit = 'Excel Row No. ' + numberRowExceedLimit
                    //         }
                    //         if (data.rowInvalidDefaultPassword?.length > 0) {
                    //             data.rowInvalidDefaultPassword.forEach((res) => {
                    //                 numberRowInvalidDefaultPassword = numberRowInvalidDefaultPassword === '' ? res : numberRowInvalidDefaultPassword + ', ' + res
                    //             })
                    //             TextNumberRowInvalidDefaultPassword = 'Please input your password in accordance with the password policy requirements.'
                    //             numberRowInvalidDefaultPassword = 'Excel Row No. ' + numberRowInvalidDefaultPassword
                    //         }
                    //         if (data.rowInvalidCompanyOrDepartment?.length > 0) {
                    //             data.rowInvalidCompanyOrDepartment.forEach((res) => {
                    //                 numberRowInvalidCompanyOrDepartment = numberRowInvalidCompanyOrDepartment === '' ? res : numberRowInvalidCompanyOrDepartment + ', ' + res
                    //             })
                    //             TextNumberRowInvalidCompanyOrDepartment = 'Department or Company Name was wrong, There is no match of this value in your tenant.'
                    //             numberRowInvalidCompanyOrDepartment = 'Excel Row No. ' + numberRowInvalidCompanyOrDepartment
                    //         }
                    //         if (data.rowInvalidDataFormat?.length > 0) {
                    //             data.rowInvalidDataFormat.forEach((res) => {
                    //                 numberRowInvalidDataFormat = numberRowInvalidDataFormat === '' ? res : numberRowInvalidDataFormat + ', ' + res
                    //             })
                    //             TextNumberRowInvalidDataFormat = 'Please Input FirstName, LastName, Phone Number and Email in accordance with the policy requirements.'
                    //             numberRowInvalidDataFormat = 'Excel Row No. ' + numberRowInvalidDataFormat
                    //         }
                    //         if (data.blankRows?.length > 0) {
                    //             data.blankRows.forEach((res) => {
                    //                 numberBlankRows = numberBlankRows === '' ? res : numberBlankRows + ', ' + res
                    //             })
                    //             TextBlankRows = 'The Company name is blank, Please give a value in the Company sheet.'
                    //             numberBlankRows = 'Excel Row No. ' + numberBlankRows
                    //         }
                    //         if (data.duplicateRows?.length > 0) {
                    //             data.duplicateRows.forEach((res) => {
                    //                 numberDuplicateRows = numberDuplicateRows === '' ? res : numberDuplicateRows + ', ' + res
                    //             })
                    //             TextDuplicateRows = 'Import file has company name and company address are already in the system. Please check data in the file again.'
                    //             numberDuplicateRows = 'Excel Row No. ' + numberDuplicateRows
                    //         }
                    //         Modal.warning({
                    //             title: <b style={{ color: '#323276' }}>Warning</b>,
                    //             content: renderResultImportError(textNumberRowDataIsNull, numberRowDataIsNull, textNumberRowEmployeeIdDup, numberRowEmployeeIdDup, textNumberRowCitizenIdWrong, numberCitizenIdWrong, textNumberRowEmailDup, numberRowEmailDup,
                    //                 TextNumberRowExceedLimit, numberRowExceedLimit, TextNumberRowInvalidDefaultPassword, numberRowInvalidDefaultPassword, TextNumberRowInvalidCompanyOrDepartment, numberRowInvalidCompanyOrDepartment, TextNumberRowInvalidDataFormat, numberRowInvalidDataFormat,
                    //                 TextBlankRows, numberBlankRows, TextDuplicateRows, numberDuplicateRows),
                    //             okText: 'OK',
                    //             okType: 'danger',
                    //             onOk: () => {
                    //                 window.location.reload()
                    //             },
                    //             onCancel: () => {
                    //                 window.location.reload()
                    //             }
                    //         })
                    //     } else {
                    //         message.error('Failed to save')
                    //     }
                    // }).finally(() => {
                    //     setLoading(false)
                    // })
                    // }

                    /* Update state */
                    // props.importPeople(data).then((res) => {
                    //     if (res) {
                    //         setLoading(true)
                    //         props.getPeopleWithPage(page, pageSize).then((res) => {
                    //             message.success('You have Successfully save the data.')
                    //         }).catch((err) => {
                    //             message.error('Failed to save' + err.response?.data?.message)
                    //         }).finally(() => {
                    //             setLoading(false)
                    //         })
                    //     }
                    // }).catch((err) => {
                    //     if (err.status === 400) {
                    //         const data: importPeopleError = decryptBody(err.data)
                    //         let textNumberRowDataIsNull = ''
                    //         let textNumberRowEmployeeIdDup = ''
                    //         let textNumberRowCitizenIdWrong = ''
                    //         let textNumberRowEmailDup = ''
                    //         let numberRowDataIsNull: string = ''
                    //         let numberRowEmployeeIdDup: string = ''
                    //         let numberCitizenIdWrong: string = ''
                    //         let numberRowEmailDup: string = ''
                    //         if (data.rowDataIsNull.length > 0) {
                    //             data.rowDataIsNull.forEach((res) => {
                    //                 numberRowDataIsNull = numberRowDataIsNull === '' ? res : numberRowDataIsNull + ', ' + res
                    //             })
                    //             textNumberRowDataIsNull = 'Please Input FirstName, LastName, Phone Number, Email and Employee ID'
                    //             numberRowDataIsNull = 'Excel Row No. ' + numberRowDataIsNull
                    //         }
                    //         if (data.rowEmployeeIdDup.length > 0) {
                    //             data.rowEmployeeIdDup.forEach((res) => {
                    //                 numberRowEmployeeIdDup = numberRowEmployeeIdDup === '' ? res : numberRowEmployeeIdDup + ', ' + res
                    //             })
                    //             textNumberRowEmployeeIdDup = 'Please do not use duplicate Employee ID'
                    //             numberRowEmployeeIdDup = 'Excel Row No. ' + numberRowEmployeeIdDup
                    //         }
                    //         if (data.rowValidateCitizenId.length > 0) {
                    //             data.rowValidateCitizenId.forEach((res) => {
                    //                 numberCitizenIdWrong = numberCitizenIdWrong === '' ? res : numberCitizenIdWrong + ', ' + res
                    //             })
                    //             textNumberRowCitizenIdWrong = 'For Citizen ID, please enter a 13 digit using numbers or blank data only'
                    //             numberCitizenIdWrong = 'Excel Row No. ' + numberCitizenIdWrong
                    //         }
                    //         if (data.rowEmailDup.length > 0) {
                    //             data.rowEmailDup.forEach((res) => {
                    //                 numberRowEmailDup = numberRowEmailDup === '' ? res : numberRowEmailDup + ', ' + res
                    //             })
                    //             textNumberRowEmailDup = 'Email is already exist on Goservice'
                    //             numberRowEmailDup = 'Excel Row No. ' + numberRowEmailDup
                    //         }
                    //         Modal.warning({
                    //             title: <b style={{ color: '#323276' }}>Warning</b>,
                    //             content: renderResultImportError(textNumberRowDataIsNull, numberRowDataIsNull, textNumberRowEmployeeIdDup, numberRowEmployeeIdDup, textNumberRowCitizenIdWrong, numberCitizenIdWrong, textNumberRowEmailDup, numberRowEmailDup),
                    //             okText: 'OK',
                    //             okType: 'danger',
                    //             onOk: () => {
                    //                 window.location.reload()
                    //             },
                    //             onCancel: () => {
                    //                 window.location.reload()
                    //             }
                    //         })
                    //     } else {
                    //         message.error('Failed to save')
                    //     }
                    // })
                }
            }
            return isXlsx
        },
        showUploadList: { showDownloadIcon: false }
    }

    const renderResultImportError = (textNumberRowDataIsNull: string, numberRowDataIsNull: string, textNumberRowEmployeeIdDup: string, numberRowEmployeeIdDup: string, textNumberRowCitizenIdWrong: string, numberCitizenIdWrong: string, textNumberRowEmailDup: string, numberRowEmailDup: string,
        TextNumberRowExceedLimit: string, numberRowExceedLimit: string, TextNumberRowInvalidDefaultPassword: string, numberRowInvalidDefaultPassword: string, TextNumberRowInvalidCompanyOrDepartment: string, numberRowInvalidCompanyOrDepartment: string, TextNumberRowInvalidDataFormat: string, numberRowInvalidDataFormat: string,
        TextBlankRows: string, numberBlankRows: string, TextDuplicateRows: string, numberDuplicateRows: string
    ) => {
        return <div>
            {textNumberRowDataIsNull ? RenderText(textNumberRowDataIsNull, numberRowDataIsNull) : null}
            {textNumberRowEmployeeIdDup ? RenderText(textNumberRowEmployeeIdDup, numberRowEmployeeIdDup) : null}
            {textNumberRowCitizenIdWrong ? RenderText(textNumberRowCitizenIdWrong, numberCitizenIdWrong) : null}
            {textNumberRowEmailDup ? RenderText(textNumberRowEmailDup, numberRowEmailDup) : null}
            {TextNumberRowExceedLimit ? RenderText(TextNumberRowExceedLimit, numberRowExceedLimit, <span>
                <Row>First Name : Input the first using letter only and enter more than 1 letters. (Limit than 60 characters.)</Row>
                <Row>Last Name : Input the first using letter only and enter more than 1 letters. (Limit than 60 characters.)</Row>
                <Row>Phone Number : Input more than 10 characters.(Limit than 50 characters.)</Row>
                <Row>Email : The input is not valid E-mail!.(Limit than 60 characters.)</Row>
                <Row>Employee ID : Input limit than 60 characters.</Row>
                <Row>Department : Input limit than 255 characters.</Row>
                <Row>Company Name : Input limit than 255 characters.</Row>
            </span>) : null}
            {TextNumberRowInvalidDefaultPassword ? RenderText(TextNumberRowInvalidDefaultPassword, numberRowInvalidDefaultPassword, <span>
                <Row>At least one upper case character (A-Z)</Row>
                <Row>At least one lower case character (a-z)</Row>
                <Row>At least one numeric case character (1-9)</Row>
                <Row>At least one symbol case character({`${text}`})</Row>
                <Row>A minimum of 8 characters</Row>
            </span>) : null}
            {TextNumberRowInvalidCompanyOrDepartment ? RenderText(TextNumberRowInvalidCompanyOrDepartment, numberRowInvalidCompanyOrDepartment) : null}
            {TextNumberRowInvalidDataFormat ? RenderText(TextNumberRowInvalidDataFormat, numberRowInvalidDataFormat, <span>
                <Row>First Name : Input the first using letter only and enter more than 1 letters. (Limit than 60 characters.)</Row>
                <Row>Last Name : Input the first using letter only and enter more than 1 letters. (Limit than 60 characters.)</Row>
                <Row>Phone Number : Input more than 10 characters.(Limit than 50 characters.)</Row>
                <Row>Email : The input is not valid E-mail!.(Limit than 60 characters.)</Row>
                <Row>Employee ID : Input limit than 60 characters.</Row>
                <Row>Department : Input limit than 255 characters.</Row>
                <Row>Company Name : Input limit than 255 characters.</Row>
            </span>) : null}
            {TextBlankRows ? RenderText(TextBlankRows, numberBlankRows) : null}
            {TextDuplicateRows ? RenderText(TextDuplicateRows, numberDuplicateRows) : null}
        </div>
    }

    const RenderText = (Text: string, rowNumber: string, info?: JSX.Element) => {
        return <div>
            <p>
                <b style={{ color: '#323276', fontWeight: '600' }}>{Text}</b>
                {info ? <Tooltip placement="bottom" title={info} overlayStyle={{minWidth: 'fit-content'}}> <Icon type="info-circle" /></Tooltip> : null}
                <br></br>
                <b style={{ color: '#FA541C', fontWeight: '600' }}>{rowNumber}</b>
            </p>
        </div>
    }

    const exportData = () => {
        console.log(filterUser)
        setLoadingExport(true)
        getExportUser(searchValue, filterUser).then(() => {
            message.success('Export Data Success')
        }).catch((err) => {
            message.error(err.response.data.message)
        }).finally(() => {
            setLoadingExport(false)
        })
        // setLoadingExport(true)
        // getAllPeopleNoPage().then((res) => {
        //     if (res) {
        //         const arrayHerder = ['id', 'createdBy', 'createdDate', 'lastModifiedBy', 'lastModifiedDate', 'firstName', 'lastName', 'fullName', 'email', 'tel', 'citizenId', 'company', 'companyAddress', 'employeeId', 'site', 'siteAddress', 'active', 'department', 'supervisorName', 'supervisorId']
        //         const aoa: string[][] = []
        //         aoa.push(arrayHerder)
        //         const CategoryToOBJ = res.map((data) => {
        //             return arrayHerder.map(key => {
        //                 return data[key]
        //             })
        //         })
        //         aoa.push(...CategoryToOBJ)
        //         const ws1 = XLSX.utils.aoa_to_sheet(aoa)
        //         const wb = XLSX.utils.book_new()
        //         XLSX.utils.book_append_sheet(wb, ws1, 'peoples')
        //         XLSX.writeFile(wb, 'people.xlsx')
        //     }
        // }).finally(() => { setLoadingExport(false) })

        // const promiseAll: any[] = []
        // promiseAll.push(getAllUserNoPage())
        // promiseAll.push(getAllCompanyAuth())
        // promiseAll.push(getAllDepartmentAuth())
        // const fileName = moment().format('YYYYMMDD') + '_user_management.xlsx'
        // Promise.all(promiseAll).then((res) => {
        //     const dataPeople = res[0]
        //     const dataCompany = res[1]
        //     const dataDepartment = res[2]
        //     console.log(dataDepartment)
        //     console.log(dataCompany)
        //     const ws1 = XLSX.utils.json_to_sheet(dataPeople)
        //     const style = { fill: { fgColor: { rgb: 'FA541C' } } }
        //     ws1.A1.s = style
        //     ws1.B1.s = style
        //     ws1.C1.s = style
        //     ws1.D1.s = style
        //     ws1.F1.s = style
        //     ws1.H1.s = style
        //     ws1['!cols'] = fixWidthExcel(dataPeople, ws1)
        //     if (ws1.D1.v === 'tel') {
        //         ws1.D1.v = 'phoneNumber'
        //     }
        //     const ws2 = XLSX.utils.json_to_sheet(dataDepartment)
        //     if (dataDepartment.length !== 0) {
        //         ws2['!cols'] = fixWidthExcel(dataDepartment, ws2)
        //     }
        //     const ws3 = XLSX.utils.json_to_sheet(dataCompany)
        //     if (dataCompany.length !== 0) {
        //         ws3['!cols'] = fixWidthExcel(dataCompany, ws3)
        //     }

        //     const wb = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(wb, ws1, 'Users')
        //     XLSX.utils.book_append_sheet(wb, ws3, 'Company')
        //     XLSX.utils.book_append_sheet(wb, ws2, 'Department')
        //     XLSX.writeFile(wb, fileName)
        // })
    }

    const showDrawer = () => {
        setDrawerVisible(true)
    }

    const onClose = () => {
        setDrawerVisible(false)
    }

    const setVisible = (isVisible: boolean) => {
        setDrawerVisible(isVisible)
    }

    function ErrorIsOk(_err) {
        Modal.warning({
            title: <b style={{ color: '#323276' }}>Warning</b>,
            content: <p style={{ color: '#323276' }}>Please Required is role.</p>
        })
    }

    const showConfirm = (type, data: People) => {
        setTargetLoading(data.id!!)
        const tempData = data
        delete tempData.createdBy
        delete tempData.createdDatetime
        delete tempData.lastModifiedBy
        delete tempData.lastModifiedDate
        delete tempData.createdByDisplay
        delete tempData.lastModifiedByDisplay
        delete tempData.createdById
        delete tempData.lastModifiedById
        delete tempData.lastModifiedDatetime
        if (type === 'goservice') {
            if (data.goServiceAccess) {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm Deactivate this data?',
                    onOk() {
                        data.goServiceAccess = false
                        setToggleGoserviceLoading(true)
                        props.editPeople(data?.id!!, tempData).then(() => {
                            message.success('The Update has finished successfully.')
                        }).catch((err) => {
                            ErrorIsOk(err)
                        }).finally(() => {
                            props.getUserRoleWithPagination(page, pageSize, encodeSpecialCharacter(''), filterUser).finally(() => { setLoading(false) })
                            setToggleGoserviceLoading(false)
                        })
                    },
                    onCancel() {
                        console.log('Cancel')
                        setLoading(false)
                    }
                })
            } else {
                data.goServiceAccess = true
                setToggleGoserviceLoading(true)
                props.editPeople(data?.id!!, tempData).then(() => {
                    message.success('The Update has finished successfully.')
                }).catch((err) => {
                    ErrorIsOk(err)
                }).finally(() => {
                    props.getUserRoleWithPagination(page, pageSize, encodeSpecialCharacter(''), filterUser).finally(() => { setLoading(false) })
                    setToggleGoserviceLoading(false)
                })
            }
        } else {
            if (data.selfPortalAccess) {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm Deactivate this data?',
                    onOk() {
                        data.selfPortalAccess = false
                        setToggleSelfLoading(true)
                        props.editPeople(data?.id!!, tempData).then(() => {
                            message.success('The Update has finished successfully.')
                        }).catch((err) => {
                            ErrorIsOk(err)
                        }).finally(() => {
                            props.getUserRoleWithPagination(page, pageSize, encodeSpecialCharacter(''), filterUser).finally(() => { setLoading(false) })
                            setToggleSelfLoading(false)
                        })
                    },
                    onCancel() {
                        console.log('Cancel')
                        setLoading(false)
                    }
                })
            } else {
                data.selfPortalAccess = true
                setToggleSelfLoading(true)
                props.editPeople(data?.id!!, tempData).then(() => {
                    message.success('The Update has finished successfully.')
                }).catch((err) => {
                    ErrorIsOk(err)
                }).finally(() => {
                    props.getUserRoleWithPagination(page, pageSize, encodeSpecialCharacter(''), filterUser).finally(() => { setLoading(false) })
                    setToggleSelfLoading(false)
                })
            }
        }
    }

    const getDataFilter = (value: filterPeopleManagementType) => {
        setFilterUser(value)
        setIsSearchComplete(true)
    }

    const searchFilterTag = (title: string, searchFilter: string[], onClose: Function) => {
        let tags: JSX.Element = (<></>)
        if (searchFilter.length > 0) {
            tags = (
                <Tooltip placement="bottom" title={ReactHtmlParser(searchFilter.map((filter: string) => { return `- ${title !== 'Roles' ? renderText(title, filter) : filter}` }).join('</br>'))}>
                    <Tag style={{ margin: 10 }} closable onClose={onClose}>{title}</Tag>
                </Tooltip>)
        }
        return tags
    }

    const renderText = (title: string, valueFilter: string): string => {
        let text = ''
        if (title === 'Confirmed Email') {
            if (valueFilter === '0') {
                text = 'Not-Confirmed'
            } else {
                text = 'Confirmed'
            }
        } else if (title === 'Goservice' || title === 'Self-Portal') {
            if (valueFilter === '0') {
                text = 'Deactivate'
            } else {
                text = 'Activate'
            }
        }
        return text
    }

    const search = (value?: string, forceSearch?: boolean) => {
        let filter: filterPeopleManagementType | undefined
        if (!nullOrEmptyObject(filterUser)) {
            filter = filterUser
        }
        setPage(1)
        setLoading(true)
        props.getUserRoleWithPagination(1, pageSize, encodeSpecialCharacter(searchValue), filter).finally(() => { setLoading(false) })
    }

    const downloadTempleteUser = () => {
        downloadTempleteUsers()
    }

    const menu = (
        <Menu>
            <Menu.Item>
                <div onClick={() => setIsModalVisible(true)}>
                    <Icon type="plus" /> Create User
                </div>
            </Menu.Item>
            <Menu.Item>
                <div>
                    <Upload
                        {...uploadProps}
                        listType="text"
                        className="upload-list-inline"
                        showUploadList={false}
                        multiple={false}
                    >
                        <div className="addCriteriaButton"><Icon type="download" /> Import User</div>
                    </Upload>
                </div>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => downloadTempleteUser()}>
                    <Icon type="profile" /> Template Import User
                </div>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>User Management</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card
                title={
                    <div>
                        <h3 style={{ paddingLeft: '24px' }} className={'main-title'}>User Management</h3>
                    </div>
                } extra={
                    <>
                        {/* <span style={{ paddingRight: '20px' }}>
                            <Upload
                                {...uploadProps}
                                listType="text"
                                className="upload-list-inline"
                                showUploadList={false}
                                multiple={false}
                            >
                                <Button type={'ghost'} className="addCriteriaButton">
                                    <Icon type="download" />Import </Button>
                            </Upload>
                        </span> */}
                        <span style={{ paddingRight: '20px' }}>
                            <Button type={'ghost'} className="addCriteriaButton" onClick={() => exportData()}
                                loading={loadingExport}>
                                <Icon type="upload" />Export
                            </Button>
                        </span>
                        <span style={{ paddingRight: '20px' }}>
                            <Dropdown overlay={menu}>
                                <Button type={'primary'}>
                                    Create <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </span>
                    </>
                }
            // headStyle={{borderBottom: 'none'}}
            >
                <Search
                    placeholder="Can search Name or Email"
                    onSearch={value => searchData(value)}
                    style={{ width: 250 }}
                    allowClear={true}
                />
                <br /> <br />
                <Button type="dashed" onClick={showDrawer}><Icon type="filter" theme="filled" />
                    Advanced Search
                </Button>
                <span>
                    {filterUser?.roles ? searchFilterTag('Roles', filterUser?.roles, () => { delete filterUser?.roles; search(searchValue !== '' ? searchValue : undefined, true) }) : null}
                    {filterUser?.confirmed ? searchFilterTag('Confirmed Email', filterUser?.confirmed, () => { delete filterUser?.confirmed; search(searchValue !== '' ? searchValue : undefined, true) }) : null}
                    {filterUser?.goserviceAccess ? searchFilterTag('Goservice', filterUser?.goserviceAccess, () => { delete filterUser?.goserviceAccess; search(searchValue !== '' ? searchValue : undefined, true) }) : null}
                    {filterUser?.selfPortalAccess ? searchFilterTag('Self-Portal', filterUser?.selfPortalAccess, () => { delete filterUser?.selfPortalAccess; search(searchValue !== '' ? searchValue : undefined, true) }) : null}
                </span>
                <br /><br />
                <Table
                    dataSource={props.user_roles}
                    columns={columns}
                    pagination={{
                        pageSize: pageSize,
                        current: page,
                        total: props.userRolesPagination.total,
                        onChange: (event) => {
                            handleOnClickPageNumber(event)
                        }
                    }}
                    rowKey="id"
                    loading={loading}
                    // scroll={{ x: 768 }}
                />
                <Modal width={898} title="Add new user" visible={isModalVisible} footer={false} onCancel={() => setIsModalVisible(false)} maskClosable={false}>
                    <WrappedFormPeople getValueCreate={getValueCreate} isEdit={false} clearData={isModalVisible} isLoading={setLoading} />
                </Modal>
                <Modal width={898} title="Edit new user" visible={isModalVisible2} footer={false} onCancel={() => setIsModalVisible2(false)} maskClosable={false}>
                    <WrappedFormPeople data={dataEdit} getValueCreate={getValueCreate} isEdit={true} clearData={isModalVisible2} isLoading={setLoading} />
                </Modal>
                <Modal width={898} title={<span style={{ fontWeight: 600 }}>{dataView?.fullName || ''}</span>} visible={isViewUser} footer={false} onCancel={() => setIsViewUser(false)} maskClosable={false}>
                    <ViewPeople data={dataView!!} setVisible={setIsViewUser} />
                </Modal>
            </Card>

            <Drawer
                title="Filter"
                placement={'left'}
                closable={true}
                onClose={onClose}
                visible={drawerVisible}
                width='314px'
            >
                <FilterPeople roles={props.roles || []} setVisible={setVisible} getDataFilter={getDataFilter} filterUser={filterUser} drawerVisible={drawerVisible} />
            </Drawer>
        </>
    )
}

export default connect(
    // eslint-disable-next-line no-undef
    mapStateToProps, { importPeople, genReportPeople, getUserRoleWithPagination, showActiveRoleList, editPeople })(PeopleManagement)
