import { decryptBody } from './../common-misc/encryptBody'
import axios, { AxiosRequestConfig } from 'axios'
// import UserShareData from '../authorization-module/login/UserShareData'
import TenantShareData from '../authorization-module/login/TenantShareData'
import IPShareData from '../authorization-module/login/IPShareData'
import { getCookie } from '../guard/route'

let isCall = false
function getIPaddress() {
    if (!isCall && process.env.REACT_APP_IS_REQUEST_IP === 'true') {
        isCall = true
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => {
                // console.log('Your Public IP Address:', data.ip)
                // const temp = encryptBody(data.ip)
                sessionStorage.setItem('tim', data.ip)
                IPShareData.getInstance().ip = data.ip
            })
            .catch(error => {
                console.error('Error fetching IP:', error)
            })
    }
}
interface ParamMapping {
    id: number,
    mappedKey: string,
    originalKey: string
}

export const checkCookieExists = (cookieName: string): boolean => {
    return !!getCookie('access_token')
}

export function axiosFactory(baseURL: string) {
    getIPaddress()
    const headers: any = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, PUT, PATCH',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
        // Authorization: `Bearer ${UserShareData.getInstance().token || getCookie('access_token')}`,
        'Return-Updated-Document': true,
        'accept-language': 'en-US,en;q=0.9',
        crypt: 'on'
    }
    if (process.env.REACT_APP_IS_REQUEST_IP === 'true') {
        const ip = IPShareData.getInstance().ip || sessionStorage.getItem('tim') || localStorage.getItem('tim')
        if (ip) {
            headers['x-forwarded-for'] = ip
        }
    }
    const instance = axios.create({
        withCredentials: true,
        baseURL: baseURL,
        headers: headers
    })

    // instance.interceptors.request.use(
    //     (config) => {
    //         const token = UserShareData.getInstance().token || getCookie('access_token')
    //         if (token) {
    //             config.headers.Authorization = 'Bearer ' + token
    //         //    config.headers['x-access-token'] = token // for Node.js Express back-end
    //         }
    //         return config
    //     },
    //     (error) => {
    //         return Promise.reject(error)
    //     }
    // )
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        const cookieName = 'access_token'
        const newParams = {}
        const paramMapping: ParamMapping[] = decryptBody(localStorage.getItem('param'))
        if (config.params) {
            for (const key in config.params) {
                const matchingParam = (paramMapping || []).find((obj) => {
                    return obj.originalKey === key
                })
                if (matchingParam) {
                    const random = matchingParam.mappedKey
                    if (!Array.isArray(config.params[key])) {
                        newParams[random] = config.params[key]
                    } else {
                        newParams[random] = config.params[key].join(',')
                    }
                } else {
                    newParams[key] = config.params[key]
                }
            }
            config.params = newParams
        }
        if (!checkCookieExists(cookieName)) {
            // Handle case where cookie does not exist
            // For example, you can throw an error or return a promise reject
            return Promise.reject(new Error('Cookie does not exist'))
        }
        return config
    })

    instance.interceptors.response.use(
        (res) => {
            return res
        },
        async (err) => {
            // const originalConfig = err.config

            // if (err.response) {
            // Access Token was expired
            // if (!originalConfig._retry) {
            //     originalConfig._retry = true

            // try {
            //     const token = UserShareData.getInstance().token || getCookie('access_token') || ''
            //     const decoded: any = jwt_decode(token)
            //     const dayShouldRefreshToken = 3 * 60 * 60 * 1000
            //     if (typeof decoded === 'object') {
            //         const exp = decoded?.exp || 0
            //         if (Date.now() >= (exp * 1000) - dayShouldRefreshToken) {
            //             console.info('refresh')
            //             renewToken()
            //         }
            //     }
            //     return instance(originalConfig)
            // } catch (_error) {
            //     return Promise.reject(_error)
            // }
            // }
            // }

            return Promise.reject(err)
        }
    )
    return () => instance
}

export function axiosNonAuthFactory(baseURL: string) {
    const headers = {
        'Return-Updated-Document': true,
        'accept-language': 'en-US,en;q=0.9',
        tenantID: `${TenantShareData.getInstance().tenant || sessionStorage.getItem('tenant')}`
    }
    if (process.env.REACT_APP_IS_REQUEST_IP === 'true') {
        const ip = IPShareData.getInstance().ip || sessionStorage.getItem('tim') || localStorage.getItem('tim')
        if (ip) {
            headers['x-forwarded-for'] = ip
        }
    }
    return () => axios.create({
        withCredentials: true,
        baseURL: baseURL,
        headers: headers
    })
}
