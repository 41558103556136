import { PbStatus } from './../../problem-management/problems/Model'
import { BaseEntity } from '../../common-model'

export interface ApproveWorkOrder extends BaseEntity {
    typeName: string,
    itemId:string,
    itemNumber: string,
    itemSummary: string,
    status: string,
    reason?: string,
    possibleApprovers: string[],
    actionedBy?: string,
    actionedDateTime?: string,
    woId: string,
    woNo: string,
    awaitingApproval: string,
    originals: {
        status: string
    }
}

export interface ApproveServiceRequest extends BaseEntity {
    typeName: string,
    itemId: string,
    itemNumber: string,
    itemSummary: string,
    status: string,
    reason?: string,
    possibleApprovers: string[],
    actionedBy?: string,
    actionedDateTime?: string,
    originals: {
        status: string
    }
}

export interface ApproveWorkOrderList {
    content?: ApproveWorkOrder[],
    pageable?: Pageable,
    totalElements?:number,
    totalpages?:number,
    number:number,
    data?: ApproveWorkOrder[],
    total?: number
}

export interface Pageable{
    offset: number,
    pageNumber: number,
    pageSize: number,
    paged: boolean
}

export enum ApproveWorkOrderStatus {
    NotActioned = 'NotActioned',
    Approved = 'Approved',
    Rejected = 'Rejected',
    AllStatus = 'NotActioned,Approved,Rejected',
    AwaitingApproval = 'AwaitingApproval',
}

export interface ServiceRequestList {
    content?: ApproveServiceRequest[]
    pageable?: Pagination
    totalElements: number
    totalPages: number
}

export interface Pagination {
    size?: number
    currentPage?: number
    totalPages?: number
    numberOfElements?: number
    totalElements?: number
}

export enum ServiceRequestStatus {
    MyNote = 'MyNote',
    Draft = 'Draft',
    AwaitingApproval = 'AwaitingApproval',
    AwaitingRetire = 'AwaitingRetire',
    Rejected = 'Rejected',
    Published = 'Published',
    Retired = 'Retired',
    Outdated = 'Outdated',
    NotActioned = 'NotActioned',
    Approved = 'Approved',
    AllStatus = 'NotActioned, Approved, Rejected'
}

export interface ApproveProblem {
    id: number
    number: string
    topic: string
    pbStatus?: PbStatus
}
