import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {loginMspWithVspaceToken} from './service'
import {LoginInfo, LoginRequestBody} from './model'
import {encrypt} from '../../common-misc'
import {Modal} from 'antd'
import { loadRolesState } from '../permissions/service'
import { UserRole } from '../permissions/model'

interface Props {}
let result: LoginInfo
// let mspToken: string
let incidentNoVspace: string
let userRoles: UserRole[]

const ConfirmLogin: React.FC<Props> = (props: Props) => {
    const { id, token, incidentNo }: any = useParams()
    const params = new URLSearchParams(window.location.search)
    const path = params.get('redirect')
    const [isShowConfirmLogin, setIsShowConfirmLogin] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [message, setMessage] = useState('')

    useEffect(() => {
        checkUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkUser = () => {
        if (id && token && path) {
            const body: LoginRequestBody = {
                sid: id,
                token: token
            }
            loginMspWithVspaceToken(body).then(res => {
                incidentNoVspace = incidentNo || ''
                result = res
                // mspToken = res.mspToken
                userRoles = res.userRole
                const mspUser = sessionStorage.getItem('username')
                if (!mspUser || mspUser === res.email) {
                    handleOK()
                } else {
                    setMessage(`Do you want to replace ${mspUser} to ${res.email} ?`)
                    setIsShowConfirmLogin(true)
                }
            }).catch((e) => {
                setMessage(e.response.data.data)
                setIsError(true)
                // handleClose()
            })
        }
    }

    const handleOK = () => {
        sessionStorage.setItem('username', result.email)
        sessionStorage.setItem('userId', result.userId.toString()!!)
        sessionStorage.setItem('userRoles', JSON.stringify(userRoles))
        // sessionStorage.setItem('access_token', mspToken)
        loadRolesState()
        const convertResult = {
            email: result.email,
            roleId: result.roleId,
            token: result.token
        } as LoginInfo
        window.location.href = `/${path}?data=${JSON.stringify(encrypt(JSON.stringify(convertResult)))}&incidentNo=${incidentNoVspace}`
    }

    const handleClose = () => {
        window.location.href = '/vSpaceLogin'
    }

    function error() {
        Modal.error({
            title: 'This is an error message',
            content: message,
            onOk: handleClose
        })
    }

    return (
        <div>
            <Modal
                title="Confirm Login"
                visible={isShowConfirmLogin}
                onOk={handleOK}
                onCancel={handleClose}
                okText={'Accept'}
            >
                <p>{message}</p>
            </Modal>
            {isError ? error() : null}
        </div>
    )
}

export default ConfirmLogin
