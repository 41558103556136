import React, { useEffect, useState } from 'react'
import { FormComponentProps } from 'antd/es/form'
import { Button, Checkbox, Form, Input, message, Select, InputNumber, Modal } from 'antd'
import { CustomFiledWithWorkFlow } from './model'
import { StoreState } from '../store'
import { connect } from 'react-redux'
import { addCustomFiledWithWorkFlow, updateCustomFiledWithWorkFlow, getAllWorkFlow, getAllWorkflowNoRedux } from '../serviceCatalog/workflow/Service/service'
import { WorkFlow } from '../serviceCatalog/workflow/Service/Model'

interface Param {
    setIsOpenCreate: Function
    dataEdit?: CustomFiledWithWorkFlow
    isOpenCreate?: boolean
    fetchDataCustomfield: Function
    isDataEdit?: boolean
    setIsDataEdit?: Function
}

const mapStateToProps = (state: StoreState) => {
    return {
        customFiledWithWorkFlow: state.customFiledWithWorkFlow,
        workFlow: state.workFlow
    }
}

type StateProps = ReturnType<typeof mapStateToProps>
interface DispatchProps {
    addCustomFiledWithWorkFlow: (customField: CustomFiledWithWorkFlow) => Promise<number>
    updateCustomFiledWithWorkFlow: (id: number, customField: CustomFiledWithWorkFlow) => Promise<number>
    getAllWorkFlow: () => Promise<number>
}

const { Option } = Select
const { confirm } = Modal
type Props = Param & FormComponentProps & StateProps & DispatchProps
const WoCustomForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue, resetFields } = props.form
    const [isRequiredField, setIsRequiredField] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [dataWorkflow, setDataWorkflow] = useState<WorkFlow[]>([])
    const [inputType, setInputType] = useState<string>()
    const [selectOption, setSelectOption] = useState<string[]>([])
    const [allWorkflowList, setAllWorkflowList] = useState<WorkFlow[]>([])

    useEffect(() => {
        getAllWorkflowNoRedux().then((data) => {
            if (data) {
                const filterData = data.filter(data => { return data.activated === true })
                setDataWorkflow(filterData)
                setAllWorkflowList(data)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.dataEdit && props.isDataEdit) {
            setFieldsValue({
                label: props.dataEdit.label,
                description: props.dataEdit.description,
                inputType: props.dataEdit.inputType,
                dataType: props.dataEdit.dataType,
                selectOption: props.dataEdit.selectOption ? props.dataEdit.selectOption.map((it) => { return it.option }) : null,
                defaultValue: props.dataEdit.defaultValue,
                placeholder: props.dataEdit.placeholder,
                workflow: props.dataEdit.workflows ? props.dataEdit.workflows.map((it) => { return it.name }) : null
            })
            setIsRequiredField(props.dataEdit.isRequireFields ? props.dataEdit.isRequireFields : false)
            setInputType(props.dataEdit.inputType)
        }
        props.getAllWorkFlow()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataEdit, props.isDataEdit])

    useEffect(() => {
        if (props.isOpenCreate === false) {
            resetFields()
            setIsRequiredField(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpenCreate])

    const validateLabel = (_: any, value: any, callback) => {
        if (value) {
            // eslint-disable-next-line
            var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /[A-Za-z0-9ก-๙]+/
            if (pattern.test(value)) {
                if (props.customFiledWithWorkFlow) {
                    const filtet = props.customFiledWithWorkFlow.filter((it) => it.label!! !== props.dataEdit?.label!!)
                    const customFiledWithWorkFlowList = filtet.map((it) => {
                        return it.label?.replace(/ +/g, '').trim().toLowerCase()
                    })
                    if (customFiledWithWorkFlowList.includes(value.replace(/ +/g, '').trim().toLowerCase())) {
                        callback('The Label is already exists. It must be unique. Please try another one.')
                    } else {
                        callback()
                    }
                }
            } else {
                if (format.test(value)) {
                    // eslint-disable-next-line
                    callback('Field Label can' + 't contain any of the following characters ' + format)
                } else {
                    if (pattern.test(value.trimEnd())) {
                        callback()
                    } else {
                        callback('Default value is not a valid')
                    }
                }
            }
        } else {
            callback()
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (err, values) => {
            if (!err) {
                values.label = values.label ? values.label.replace(/ +/g, ' ').trim() : undefined
                const trimplaceholder = values.placeholder ? values.placeholder.replace(/ +/g, ' ').trim() : undefined
                setIsLoading(true)
                const workflowfilter: any[] = []
                for (const wfName of values.workflow) {
                    if (wfName) {
                        workflowfilter.push(allWorkflowList.find((it) => it.name === wfName))
                    }
                }
                // values.workflow.map((wfName) => {
                //     if (wfName) {
                //         workflowfilter.push(allWorkflowList.find((it) => it.name === wfName))
                //     }
                // })
                const convertData: CustomFiledWithWorkFlow = {
                    name: values.label!!.replaceAll(' ', '_').toLowerCase(),
                    label: values.label,
                    description: values.description,
                    inputType: values.inputType,
                    dataType: values.dataType,
                    selectOption: values.selectOption ? values.selectOption.map((it) => { return { option: it } }) : undefined,
                    defaultValue: values.defaultValue,
                    placeholder: trimplaceholder,
                    isRequireFields: isRequiredField,
                    active: true,
                    workflows: values.workflow ? workflowfilter.map((it) => { return { id: it.id, name: it.name } }) : undefined,
                    customFieldOrder: props.isDataEdit ? props.dataEdit?.customFieldOrder : props.customFiledWithWorkFlow.length
                }
                console.log('convertData', convertData)
                if (props.dataEdit) {
                    // update
                    props.updateCustomFiledWithWorkFlow(props.dataEdit.id!!, convertData).then((res) => {
                        if (res) {
                            message.success('The Update has finished successfully')
                            props.fetchDataCustomfield()
                            if (props.setIsDataEdit) {
                                props.setIsDataEdit(false)
                            }
                        }
                    }).catch((_err) => {
                        message.error(_err?.response?.data?.message)
                    }).finally(() => {
                        props.setIsOpenCreate(false)
                        setIsLoading(false)
                        resetFields()
                    })
                } else {
                    // create
                    props.addCustomFiledWithWorkFlow(convertData).then((res) => {
                        if (res) {
                            message.success('You have Successfully save the data')
                            props.fetchDataCustomfield()
                            if (props.setIsDataEdit) {
                                props.setIsDataEdit(false)
                            }
                        }
                    }).catch((_err) => {
                        message.error(_err?.response?.data?.message)
                    }).finally(() => {
                        props.setIsOpenCreate(false)
                        setIsLoading(false)
                        resetFields()
                    })
                }
                props.setIsOpenCreate(false)
            }
        })
    }

    const changeInputType = (value: any) => {
        setInputType(value)
        if (value === 'InputNumber') {
            setFieldsValue({ dataType: 'Number' })
        } else if (value === 'DatePicker') {
            setFieldsValue({ dataType: 'Date' })
        } else {
            setFieldsValue({ dataType: 'String' })
        }
        if (value === 'TextBox' || value === 'DatePicker' || value === 'InputNumber') {
            setFieldsValue({ selectOption: [] })
        }
        setFieldsValue({ defaultValue: value === 'InputNumber' ? 0 : undefined, placeholder: undefined })
    }

    const onChangeselect = (value) => {
        setSelectOption(value)
    }

    const handleCancleDrawer = () => {
        confirm({
            title: 'Are you sure?',
            content: 'Are you sure? You want to leave this page? ',
            okText: 'OK',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'Cancel',
            async onOk() {
                props.setIsOpenCreate(false)
                setInputType(undefined)
                if (props.setIsDataEdit) {
                    props.setIsDataEdit(false)
                }
            },
            onCancel() {
            }
        })
    }

    const validateEmail = (_: any, value: any, callback) => {
        // eslint-disable-next-line
        const format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isValidateEmail = getFieldValue('dataType') === 'Email'
        if (isValidateEmail && !format.test(value)) {
            if (!value) {
                callback()
            } else {
                callback('Email is not a valid email')
            }
        } else {
            callback()
        }
    }

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Form.Item label='Field label'>
                    {getFieldDecorator('label', {
                        rules: [{ required: true, message: 'Field label is required' },
                            { validator: validateLabel }]
                    })(
                        <Input placeholder='Label' />
                    )
                    }
                </Form.Item>
                <Form.Item label='Field description'>
                    {getFieldDecorator('description', { rules: [{ required: false }] })(
                        <Input placeholder='Description' />)
                    }
                </Form.Item>
                <Form.Item label='Input type'>
                    {getFieldDecorator('inputType', { rules: [{ required: true, message: 'Input type is required' }] })(
                        <Select placeholder='Select input type' allowClear onChange={(value) => changeInputType(value)} >
                            <Option value="Checkbox">Checkbox</Option>
                            <Option value="DatePicker">Date picker</Option>
                            <Option value="DropdownSelect">Dropdown Select</Option>
                            <Option value="InputNumber">Input number</Option>
                            <Option value="RadioButton">Radio button</Option>
                            <Option value="TextBox">Text box</Option>
                        </Select>)
                    }
                </Form.Item>
                <Form.Item label='Data type'>
                    {getFieldDecorator('dataType', { rules: [{ required: true, message: 'Data type is required' }] })(
                        <Select placeholder='Select data type' allowClear onChange={() => { setFieldsValue({ defaultValue: undefined, placeholder: undefined }) }} >
                            {getFieldValue('inputType') === 'DropdownSelect' || getFieldValue('inputType') === 'RadioButton' || getFieldValue('inputType') === 'TextBox' || getFieldValue('inputType') === 'Checkbox'
                                ? <Option value="String" key='1'>String</Option>
                                : null}
                            {getFieldValue('inputType') === 'InputNumber'
                                ? <Option value="Number" key='2'>Number</Option>
                                : null}
                            {getFieldValue('inputType') === 'TextBox'
                                ? <Option value="Email" key='3'>Email</Option> : null}
                            {/* {getFieldValue('inputType' + row.id) === 'Checkbox' ? <Option value="Boolean" key='4'>Boolean</Option> : null} */}
                            {getFieldValue('inputType') === 'DatePicker'
                                ? <Option value="Date" key='5'>Date</Option> : null}
                        </Select>)
                    }
                </Form.Item>
                <Form.Item label='Select option'>
                    {getFieldDecorator('selectOption', {
                        rules: [{
                            required: getFieldValue('inputType') === 'Checkbox' || getFieldValue('inputType') === 'DropdownSelect' || getFieldValue('inputType') === 'RadioButton'
                        }]
                    })(
                        <Select disabled={getFieldValue('inputType') !== 'Checkbox' && getFieldValue('inputType') !== 'DropdownSelect' && getFieldValue('inputType') !== 'RadioButton'} placeholder='Select Option' onChange={(e) => { onChangeselect(e) }} mode="tags" />
                    )
                    }
                </Form.Item>
                <Form.Item label='Default value'>
                    {getFieldDecorator('defaultValue', { rules: [{ required: false }, { validator: validateEmail }] })(
                        inputType === 'TextBox' || inputType === 'InputNumber' || inputType === 'DropdownSelect'
                            ? inputType === 'TextBox' || inputType === 'InputNumber'
                                ? inputType === 'TextBox'
                                    ? <Input placeholder='Default value' allowClear />
                                    : <InputNumber style={{ width: '100%' }} defaultValue={0} />
                                : <Select allowClear placeholder='Default value'>
                                    {selectOption?.map((it, idx) => {
                                        return <Option value={it} key={idx}>{it}</Option>
                                    })}
                                </Select>
                            : <Input disabled placeholder='Default value' />

                    )}
                </Form.Item>
                <Form.Item label='Placeholder'>
                    {getFieldDecorator('placeholder', { rules: [{ required: false }] })(
                        <Input disabled={inputType === 'Checkbox' || inputType === 'DatePicker' || inputType === 'RadioButton'} placeholder='Placeholder' />)
                    }
                </Form.Item>
                <Form.Item label='Workflow'>
                    {getFieldDecorator('workflow', { rules: [{ required: true, message: 'Workflow is required' }] })(
                        <Select placeholder='Select Workflow' allowClear mode='multiple'>
                            {(dataWorkflow || []).map((workFlow) => {
                                return (<Option key={workFlow.name} value={workFlow.name}>{workFlow.name}</Option>)
                            })}
                        </Select>)
                    }
                </Form.Item>
                <Form.Item >
                    <Checkbox checked={isRequiredField} onChange={(e) => setIsRequiredField(e.target.checked)}>Make this a required field</Checkbox>
                </Form.Item>
                <div style={{ float: 'right' }}>
                    <Form.Item>
                        <Button type="default" htmlType="button" style={{ marginRight: 10 }} onClick={() => handleCancleDrawer()}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

const WoCustomForms = Form.create<Props>()(WoCustomForm)
export default connect(mapStateToProps, {
    addCustomFiledWithWorkFlow,
    updateCustomFiledWithWorkFlow,
    getAllWorkFlow
})(WoCustomForms)
