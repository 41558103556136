import React, {useEffect, useState} from 'react'
import {Row, Col, Breadcrumb, Icon, Avatar, Upload, Button, Modal, message, Form, Input, Slider, Select, Switch } from 'antd'
import './CustomStyle.css'
import {UploadFile} from 'antd/lib/upload/interface'
import { FormComponentProps } from 'antd/lib/form'
import exportIcon from '../common-file/icon-file'
import {Space} from '../common-components/Space'
import { getTenantProfileByTenantId, updateTenantProfileByTenantId} from './Services/Service'
import { tenantProfile} from './Services/model'
import { DownloadAnyFile, tokenDecode } from '../common-misc'
import FSS from '../file-server-storage'
import { UploadLink } from '../file-server-storage/model'
import { decryptBodyToken, encryptBody } from '../common-misc/encryptBody'
import { Redirect, Link } from 'react-router-dom'
import { createOrUpdateTenantConfig, getTenantConfigByApiKeyType } from '../authorization-module/user-role/duck/service'
import { tenantConfig } from '../authorization-module/people/model'
import moment from 'moment'
import { reset2faAll } from '../authorization-module/login/service'
const axios = require('axios')

type Props = FormComponentProps
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)
const { TextArea } = Input
const { Option } = Select
const { confirm } = Modal
const TenantProfile: React.FC<Props> = (props: Props) => {
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [data, setData] = useState<tenantProfile>()
    const [isLoading, setIsLoading] = useState(false)
    const [isRedirect, setIsRedirect] = useState(false)
    const [is2factor, setIs2factor] = useState(false)
    const [isResetSuccess, setIsResetSuccess] = useState(false)
    const [listModelChatGPT, setListModelChatFPT] = useState<string[]>(['text-davinci-003', 'ada'])
    const ChatGPTKey = tokenDecode()?.chatGptKey || ''
    const now = moment()

    // Picture Avatar
    const [pictureAvatar, setPictureAvatar] = useState<string>()
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form

    useEffect(() => {
        InitData()
        setIsResetSuccess(false)
        if (ChatGPTKey !== '') {
            const OPENAI_API_KEY = decryptBodyToken(ChatGPTKey)
            getModel(OPENAI_API_KEY)
            getUsage(OPENAI_API_KEY)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getModel = (OPENAI_API_KEY) => {
        axios({
            method: 'get',
            url: 'https://api.openai.com/v1/models',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`
            }
        })
            .then(response => {
                const models = response.data.data
                const listChat = models.map(model => {
                    return model.id as string
                })
                setListModelChatFPT(listChat)
            })
            .catch(error => {
                console.error(error)
            })
    }

    const getUsage = (OPENAI_API_KEY) => {
        axios({
            method: 'get',
            url: 'https://api.openai.com/dashboard/billing/credit_grants',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${OPENAI_API_KEY}`
            }
        })
            .then(response => {
                if (response) {
                    const getExpireList = response.grants.data?.map((it) => { return it.expires_at })
                    const getMaxDate = Math.max(...getExpireList)
                    if (now.diff(moment(getMaxDate * 1000), 'days') > 0) {
                        message.error('Token Chat GPT are expired!')
                    }
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const InitData = () => {
        getTenantProfileByTenantId().then((res) => {
            if (res) {
                setData(res)
                if (res.activateAuthenticator) {
                    setIs2factor(res.activateAuthenticator)
                }
                setFieldsValue({
                    firstName: res.firstname,
                    lastName: res.lastname,
                    phone: res.phone,
                    company: res.companyName,
                    address: res.billingAddress,
                    is2fa: res.activateAuthenticator
                })
                if (res.uploadLogo) {
                    setPictureAvatar(res.uploadLogo)
                    const convertData = JSON.parse(res.uploadLogo)
                    const uploadFile = convertData?.map((file) => {
                        const uploadFile: UploadFile = {
                            uid: file.name,
                            name: file.name,
                            status: 'done',
                            url: file.url,
                            type: file.type,
                            size: 0
                        }
                        return uploadFile
                    })
                    setFileList(uploadFile || [])
                }
            }
        }).catch((err) => {
            message.error(err?.response?.message || 'error get profile')
        })

        getTenantConfigByApiKeyType('chatGPT').then((res) => {
            if (res.length !== 0) {
                res.forEach((data) => {
                    if (data.keyName === 'apiKey') {
                        setFieldsValue({
                            apiKeyChatGPT: decryptBodyToken(data.value)
                        })
                    } else if (data.keyName === 'model') {
                        setFieldsValue({
                            model: data.value
                        })
                    } else if (data.keyName === 'temperature') {
                        setFieldsValue({
                            temperature: data.value
                        })
                    } else if (data.keyName === 'maxTokens') {
                        setFieldsValue({
                            maxTokens: data.value
                        })
                    }
                })
            }
        })
    }

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {
            setPictureAvatar(undefined)
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                if (fillOffFileList.length > 1) {
                    fillOffFileList.shift()
                    setFileList(fillOffFileList)
                } else {
                    setFileList(fillOffFileList)
                }
            }
            return false
        },
        showUploadList: { showDownloadIcon: false, showRemoveIcon: isEdit }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                setIsLoading(true)
                const convertData: tenantProfile = {
                    email: data?.email || '',
                    firstname: values.firstName,
                    lastname: values.lastName,
                    phone: values.phone,
                    companyName: values.company,
                    billingAddress: values.address,
                    activateAuthenticator: values.is2fa
                }
                const tenantConfigList: tenantConfig[] = []
                if (values.apiKeyChatGPT) {
                    const convertDataConfig: tenantConfig = {
                        apikeytype: 'chatGPT',
                        keyName: 'apiKey',
                        value: encryptBody(values.apiKeyChatGPT)
                    }
                    tenantConfigList.push(convertDataConfig)
                }
                if (values.model) {
                    const convertDataConfigModel: tenantConfig = {
                        apikeytype: 'chatGPT',
                        keyName: 'model',
                        value: values.model
                    }
                    tenantConfigList.push(convertDataConfigModel)
                }
                if (values.temperature) {
                    const convertDataConfigTemperature: tenantConfig = {
                        apikeytype: 'chatGPT',
                        keyName: 'temperature',
                        value: values.temperature
                    }
                    tenantConfigList.push(convertDataConfigTemperature)
                }
                if (values.maxTokens) {
                    const convertDataConfigMaxTokens: tenantConfig = {
                        apikeytype: 'chatGPT',
                        keyName: 'maxTokens',
                        value: values.maxTokens
                    }
                    tenantConfigList.push(convertDataConfigMaxTokens)
                }
                if (tenantConfigList.length !== 0) {
                    createOrUpdateTenantConfig(tenantConfigList)
                }
                if (data) {
                    if (fileList.length > 0) {
                        FSS.putFile(fileList, 'Tenant', (uploadLink: UploadLink[]) => {
                            convertData.uploadLogo = JSON.stringify(uploadLink)
                            updateTenantProfileByTenantId(data.id!!, convertData).then((res) => {
                                message.success('The Update has finished successfully.')
                                setIsRedirect(true)
                            }).catch(() => {
                                message.error('You have Unsuccessfully save the data.', 2)
                            }).finally(() => {
                                setIsLoading(false)
                            })
                        })
                    } else {
                        convertData.uploadLogo = null
                        updateTenantProfileByTenantId(data.id!!, convertData).then((res) => {
                            message.success('The Update has finished successfully.')
                            setIsRedirect(true)
                        }).catch(() => {
                            message.error('You have Unsuccessfully save the data.', 2)
                        }).finally(() => {
                            setIsLoading(false)
                        })
                    }
                }
            }
        })
    }

    const handleCancel = () => {
        // resetFields()
        InitData()
        setIsEdit(false)
        const getHtml = document.getElementById('TenantProfile_apiKeyChatGPT')
        if (getHtml) {
            getHtml.setAttribute('type', 'password')
        }
    }

    const handleFocus = () => {
        getModel(getFieldValue('apiKeyChatGPT'))
    }

    const onChange2fa = () => {
        setIs2factor(!is2factor)
    }

    function showConfirm() {
        confirm({
            title: 'Are you sure?',
            content: <div>
                <p>Please enter the word &quot;Reset&quot; to confirm reset all 2FA.</p>
                <Input id='resetInput' placeholder="Reset" />
            </div>,
            onOk() {
                const docValue = document.getElementById('resetInput')
                if (docValue) {
                    if (docValue.getAttribute('value')?.toLowerCase().trim() === 'reset') {
                        reset2faAll().then(() => {
                            setIsResetSuccess(true)
                            message.success('The Reset has finished successfully.')
                        }).catch(() => {
                            message.error('You have Unsuccessfully Reset the data.', 2)
                        })
                    } else {
                        message.warning('Please enter the word "Reset" to confirm reset all 2FA.', 2)
                    }
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <>
            {isRedirect ? <Redirect to={'/Setting'}/> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/setting'}>Setting</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Tenant Profile</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <div className={'My-card'}>
                <p className={'HerderText'}>Tenant Profile</p>
                <br/>
                <Row gutter={[16, 16]}>
                    <Col span={6} xl={6} md={12} sm={24}>
                        <Col span={24} style={{textAlign: 'center', paddingTop: 0, maxWidth: 'min-content', paddingLeft: 50}}>
                            {/* Picture Avatar */}
                            <p className={'HerderText'}>Logo</p>
                            <Avatar shape="square" size={170} icon="user" src={fileList?.length > 0 ? fileList[0]?.url : pictureAvatar ? JSON.parse(pictureAvatar)[0].url : undefined}/>
                            <Upload {...uploadProps} fileList={fileList} onPreview={(e: any) => handlePreview(e)} >
                                <Button disabled={!isEdit} style={{marginTop: 10}}><Icon type="upload"/>Upload New Logo</Button>
                            </Upload>
                            <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} width={'max-content'} >
                                <img alt={previewImage} style={{ width: '170' }} src={previewImage} />
                            </Modal>
                        </Col>
                    </Col>
                    <Col span={14} xl={14} md={12} sm={24}>
                        <p className={'HerderText'}>Information {!isEdit ? <Icon type="edit" style={{ paddingLeft: 10 }} onClick={() => setIsEdit(!isEdit)}/> : null}</p>
                        <Form onSubmit={handleSubmit} >
                            <Row gutter={[8, 8]}>
                                <Col span={4}>
                                    <div><span className={'TextRed'}>* </span> Email :</div>
                                </Col>
                                <Col span={20}>
                                    <div>{data?.email}</div>
                                </Col>
                            </Row>
                            <br/>
                            <Row gutter={[8, 8]}>
                                <Col span={4} style={{ paddingTop: 10 }}>
                                    <div><span className={'TextRed'}>* </span> Name :</div>
                                </Col>
                                <Col span={10}>
                                    <Form.Item >
                                        {getFieldDecorator('firstName', {
                                            rules: [{ required: true, message: 'Please input your First Name!' }]
                                        })(
                                            <Input placeholder="First Name" disabled={!isEdit}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item >
                                        {getFieldDecorator('lastName', {
                                            rules: [{ required: true, message: 'Please input your Last Name!' }]
                                        })(
                                            <Input placeholder="Last Name" disabled={!isEdit}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={4} style={{ paddingTop: 10 }}>
                                    <div><span className={'TextRed'}>* </span> Phone Number :</div>
                                </Col>
                                <Col span={20}>
                                    <Form.Item >
                                        {getFieldDecorator('phone', {
                                            rules: [{ required: true, message: 'Please input your Phone!' }]
                                        })(
                                            <Input placeholder="Phone" disabled={!isEdit}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={4} style={{ paddingTop: 10 }}>
                                    <div><span className={'TextRed'}>* </span> Company Name : </div>
                                </Col>
                                <Col span={20}>
                                    <Form.Item >
                                        {getFieldDecorator('company', {
                                            rules: [{ required: true, message: 'Please input your Company!' }]
                                        })(
                                            <Input placeholder="Company" disabled={!isEdit}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={4} style={{ paddingTop: 10 }}>
                                    <div><span className={'TextRed'}>* </span> Billing Address :</div>
                                </Col>
                                <Col span={20}>
                                    <Form.Item >
                                        {getFieldDecorator('address', {
                                            rules: [{ required: true, message: 'Please input your Billing Address!' }]
                                        })(
                                            <TextArea rows={2} autoSize={{minRows: 2, maxRows: 2 }} placeholder="Billing Address" disabled={!isEdit}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={[8, 8]}>
                                <Col span={4} style={{ paddingTop: 10 }}>
                                    <div style={{ paddingLeft: 1 }}><span className={'TextRed'}>* </span> Google Authenticator :</div>
                                </Col>
                                <Col span={14}>
                                    <Form.Item >
                                        {getFieldDecorator('is2fa', {
                                            rules: [{ required: true, message: 'Please input your Google Authenticator!' }]
                                        })(
                                            <Switch checked={is2factor} onChange={onChange2fa} disabled={!isEdit}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ textAlign: 'end', paddingTop: 5}}>
                                    {is2factor && !isResetSuccess ? <span className='feck-link' onClick={showConfirm}>Reset All 2FA <Icon type="reload" /></span> : <span style={{ color: 'gray' }}>Reset All 2FA <Icon type="reload" /></span>}
                                </Col>
                            </Row>
                            <br/>
                            <div style={{ backgroundColor: '#e8e8e8', padding: 10 }}>
                                <div className={'HerderText'} style={{ width: 'auto', borderBottom: 5, marginBottom: 5}}> Optional </div>
                                <Row gutter={[8, 8]}>
                                    <Col span={4} style={{ paddingTop: 10 }}>
                                        <div> OpenAI Key :</div>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item >
                                            {getFieldDecorator('apiKeyChatGPT', {
                                                rules: [{ required: false, message: 'Please input your OpenAI Key!' }]
                                            })(
                                                <Input.Password id='KeyGPT' placeholder="OpenAI Key" disabled={!isEdit} allowClear/>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={4} style={{ paddingTop: 10 }}>
                                        <div> GPT Model :</div>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item >
                                            {getFieldDecorator('model', {
                                                rules: [{ required: false, message: 'Please input your GPT Model!' }]
                                            })(
                                                <Select disabled={!isEdit} onFocus={() => handleFocus()}>
                                                    {listModelChatGPT.map((it, i) => {
                                                        return <Option value={it} key={i}>{it}</Option>
                                                    })}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={4} style={{ paddingTop: 10 }}>
                                        <div> Temperature :</div>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item >
                                            {getFieldDecorator('temperature', {
                                                initialValue: 0.7,
                                                rules: [{ required: false, message: 'Please input your ChatGPT API Key!' }]
                                            })(
                                                <Slider
                                                    step={0.1}
                                                    min={0}
                                                    max={1}
                                                    disabled={!isEdit}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]}>
                                    <Col span={4} style={{ paddingTop: 10 }}>
                                        <div> Max Tokens :</div>
                                    </Col>
                                    <Col span={20}>
                                        <Form.Item >
                                            {getFieldDecorator('maxTokens', {
                                                initialValue: 400,
                                                rules: [{ required: false, message: 'Please input your ChatGPT API Key!' }]
                                            })(
                                                <Slider
                                                    step={10}
                                                    min={300}
                                                    max={1000}
                                                    disabled={!isEdit}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            {isEdit ? <Col span={24} style={{ marginTop: 10 }}>
                                <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} loading={isLoading}>Submit</Button>
                                <Button style={{ float: 'right' }} onClick={() => handleCancel()}>Cancel</Button>
                            </Col> : null}
                        </Form>
                    </Col>
                    <Col span={4} xl={4}></Col>
                </Row>
            </div>

        </>
    )
}

const MyTenantProfile = Form.create<Props>({ name: 'TenantProfile' })(TenantProfile)
export default MyTenantProfile
