/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux'
import {AutoComplete, Button, Col, Dropdown, Form, Icon, Menu, message, Modal, Row, Spin, Tag, Tooltip} from 'antd'
import {StoreState} from '../../store'
import IncidentTimeIndex from './IncidentTimeIndex'

import {Incident, IncidentWithSlaCalculations, Satisfaction, TicketStatus} from './index'
// import ModalPending from './PendingDialog'
import {addSatisfaction, GetAllTicketRelatedTicketIncView, markIncidentViewed, updateIncident} from './service'

import {disableByStatus, notEmpty} from '../../common-misc'
import {FetchingState} from '../../common-components'
import {SlaCalculation} from '../../sla-management/sla-calculation/model'
import {SlaGoal} from '../../sla-management/sla-policy/model'
// import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {PriorityLevel} from '../priority'
import {FormComponentProps} from 'antd/es/form'
import {SelectValue} from 'antd/lib/select'
import {getAllWorkLogByIncidentId} from '../worklog/service'
import {SupportTeam} from './model'
import {UserSupportTeam} from '../../authorization-module/support-team-management/model'
import CustomerSat from './CustomerSatModal'
// import {People} from '../../authorization-module/people/model'
import {getTicketStatus} from '../status'
import { getWoRelatedByIncidentId, WoRalateIncidentCard } from '../../msp-wo/wo-management'
import { ticketPacksByincidentId } from '../../serviceCatalog/Catalog/service'
import moment from 'moment'
import RelateList from './RelateList'
import SlaTimeUsedUpPercentage2 from './SlaTimeUsedUpPercentage2'
import { waitingGetSessions } from '../../guard/route'

const mapStateToProps = (state: StoreState) => {
    return {
        statuses: state.statuses,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        incidentState: state.incidentState,
        workLog: state.workLog
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    updateIncident: (incident: Incident) => Promise<number>
    getAllWorkLogByIncidentId: (incidentId: string) => Promise<number>
    addSatisfaction: (satisfaction: Satisfaction) => Promise<number>
}

interface Params {
    incident: IncidentWithSlaCalculations
    xxx: any
    slaCalculationsFetchingState: FetchingState
    setIncidentsFetchingState: Function
    priority: PriorityLevel[]
    isMedium: boolean
}
// const {SubMenu} = Menu
type Props = StateProps & DispatchProps & Params & FormComponentProps
let tempAllStatus: TicketStatus[] = []
const IncidentRowEnhance = (props: Props) => {
    const format = 'YYYY-MM-DD HH:mm:ss'
    const incident = props.incident
    // const [myModal, setMyModal] = useState<JSX.Element | null>()
    const [visibleModal, setVisibleModal] = useState(false)
    const [isRequireAssignGroup, setIsRequireAssignGroup] = useState<boolean>(false)
    const [isRequireAssignee, setIsRequireAssignee] = useState<boolean>(false)
    const [assignee, setAssignee] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [currentStatus, setCurrentStatus] = useState<string>()
    const [, setOldStatused] = useState<string>()
    const [isSpinning] = useState<boolean>(false)
    const [mySupportTeamName, setMySupportTeamName] = useState<string[]>()
    const {getFieldDecorator} = props.form
    const [rowId] = useState<number>()
    const [disableAssignTo, setDisabledAssignTo] = useState<boolean>(false)
    const [, setSlaCalculationsFetchingState] = useState<FetchingState>(props.slaCalculationsFetchingState)
    const [isSatOpen, setIsSatOpen] = useState<boolean>(false)
    const [isSLAOpen, setIsSLAOpen] = useState<boolean>(false)
    const [ticketType, setTicketType] = useState<string>('Incident')
    const dispatch = useDispatch()
    const [, setChildTicket] = useState<number[]>([])
    const [, setParentTicket] = useState<number[]>([])
    const [, GetTicketRelated] = useState<any>()
    const [dataWoRalateIncident, setDataWoRalateIncident] = useState<WoRalateIncidentCard[]>([])
    const [visibleRelated, setVisibleRelated] = useState(false)

    useEffect(() => {
        if (props.incident?.status?.value !== null) {
            setCurrentStatus(props.incident?.status?.value)
        }
        if (props.supportTeamsWithPermission.length !== 0) {
            const supportTeamName = props.supportTeamsWithPermission.map((supportTeam: SupportTeam) => {
                return supportTeam?.name
            })
            setMySupportTeamName(supportTeamName || [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.supportTeamsWithPermission])

    useEffect(() => {
        if (rowId) {
            fetchAllWorklog(rowId)
            fetchTicketRelated(rowId)
            ticketPacksByincidentId(rowId?.toString()!!).then((data) => {
                if (data.tickets) {
                    const convertDataToWoRalateIncident: WoRalateIncidentCard[] = data.tickets.map((it) => {
                        return {
                            id: it.ticketId,
                            description: JSON.parse(it.ticketCreationRequestBody).description,
                            item_id: it.itemId,
                            order_id: it.ticketNumber,
                            wo_status: it.ticketStatus,
                            workflow_name: JSON.parse(it.ticketCreationRequestBody).workflowName,
                            workflow_number: JSON.parse(it.ticketCreationRequestBody).workflowNumber
                        } as WoRalateIncidentCard
                    })
                    const mergeData = [...dataWoRalateIncident, ...convertDataToWoRalateIncident]
                    const arrayUniqueByKey = [...new Map(mergeData.map(item =>
                        [item.id, item])).values()]
                    setDataWoRalateIncident(arrayUniqueByKey)
                }
            })
            fetchDataWoWithIncident()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowId])

    useEffect(() => {
        disableByStatus(currentStatus, setDisabledAssignTo, props.incident?.user?.email, props.incident.supportTeam)
        if (props.incident.ticketType) {
            // const ticketType = props.incident?.incidentSetting?.find(it => it.incGeneralSetting.settingType === 'ticketType')?.incGeneralSetting.value!!
            setTicketType(props.incident.ticketType)
        }
        if (props.incident?.supportTeam?.name) {
            const findData = props.supportTeamsWithPermission.find((it) => { return it.name === props.incident?.supportTeam?.name!! })
            if (!findData) {
                props.setIncidentsFetchingState(FetchingState.NotStarted)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStatus, props.incident])

    useEffect(() => {
        if (tempAllStatus.length === 0 && props.statuses.length !== 0) {
            tempAllStatus = props.statuses
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.statuses])

    useEffect(() => {
        setSlaCalculationsFetchingState(props.slaCalculationsFetchingState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.slaCalculationsFetchingState])

    const fetchAllWorklog = (rowId) => props.getAllWorkLogByIncidentId(rowId)

    const fetchTicketRelated = (rowId) => GetAllTicketRelatedTicketIncView(rowId).then((res) => {
        GetTicketRelated(res)
        setChildTicket(res.child.map((it) => { return it.id }))
        setParentTicket(res.parent.map((it) => { return it.id }))
    })

    const fetchDataWoWithIncident = () => {
        getWoRelatedByIncidentId(props.incident?.id?.toString()!!).then((data) => {
            if (data.length > 0) {
                const mergeData = [...dataWoRalateIncident, ...data]
                const arrayUniqueByKey = [...new Map(mergeData.map(item =>
                    [item.id, item])).values()]
                setDataWoRalateIncident(arrayUniqueByKey)
            }
        })
    }

    // function DueMenuSupport(row) {
    //     return (
    //         <Menu>
    //             {
    //                 // eslint-disable-next-line react/prop-types
    //                 props.supportTeamsWithPermission.map((data: SupportTeam, keys: number) => {
    //                     return (
    //                         <SubMenu title={<span>{data.name || ''}</span>} key={keys}>
    //                             {data.assignees.map((user, index: number) => {
    //                                 return (
    //                                     <Menu.Item key={index}
    //                                         onClick={() => changeMySupportTeam(data, user.users, row)}>
    //                                         <span>{user.users.fullName}</span>
    //                                     </Menu.Item>
    //                                 )
    //                             })}
    //                         </SubMenu>
    //                     )
    //                 })
    //             }
    //         </Menu>
    //     )
    // }

    // function changeMySupportTeam(supportTeam: SupportTeam, assignee: People, incident: Incident) {
    //     setIsSpinning(true)
    //     setSlaCalculationsFetchingState(FetchingState.Started)
    //     let newStatus: TicketStatus | undefined
    //     if (supportTeam !== null && assignee !== null) {
    //         newStatus = getTicketStatus(props.statuses || tempAllStatus, ticketType, 'InProgress')
    //     }
    //     props.updateIncident({
    //         ...incident,
    //         supportTeam: supportTeam,
    //         user: assignee,
    //         ticketStatus: newStatus as TicketStatus
    //     }).then((res) => {
    //         if (res === 200) {
    //             setSlaCalculationsFetchingState(FetchingState.Succeed)
    //             setIsSpinning(false)
    //         }
    //     }).catch((err) => {
    //         setIsSpinning(false)
    //         message.error(`Failed updating incident. ${err}`)
    //     })
    // }

    // function DueMenuStatus(row: Incident) {
    //     let currentKey: number
    //     let oldStatusRender: string | undefined
    //     return (
    //         <Menu>
    //             {myModal ? (displayModal()) : null}
    //             {
    //                 // eslint-disable-next-line react/prop-types
    //                 props.statuses.filter((item) => item.ticketType === ticketType).map((status, keys: number) => {
    //                     const currentStatus = status?.status
    //                     if (currentStatus === (props.incident?.ticketStatus?.status || props.incident.status?.value)) {
    //                         currentKey = keys
    //                         setRowId(row.id)
    //                         if (oldStatus === '' || oldStatus === undefined) {
    //                             const worklogs = (props.workLog || []).filter(value => value.type === 'Pending')
    //                             if (worklogs) {
    //                                 worklogs.forEach((worklog) => {
    //                                     const value = worklog.changes.find(obj => obj.fieldName === 'ticketStatus' && obj.oldValue !== null)
    //                                     if (value) {
    //                                         setOldStatused(value.oldValue)
    //                                         oldStatusRender = value.oldValue
    //                                     }
    //                                 })
    //                             }
    //                         }
    //                     }
    //                     if (currentKey || keys || currentKey === 0) {
    //                         return checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)
    //                             // can close but can't update
    //                             ? (
    //                                 // status = closed and current status = resolved
    //                                 keys === 5 && currentKey === 4 ? (
    //                                     <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}>
    //                                         <span>{currentStatus}</span>
    //                                     </Menu.Item>
    //                                 ) : (
    //                                     <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
    //                                         disabled={true}>
    //                                         <span>{currentStatus}</span>
    //                                     </Menu.Item>
    //                                 )
    //                             ) : (
    //                                 currentKey <= keys || keys === 3
    //                                     ? (
    //                                         (props.incident?.ticketStatus?.status || props.incident.status?.value) === 'Resolved' && keys === 5 ? (
    //                                             <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
    //                                                 disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident?.createdBy)}>
    //                                                 <span>{currentStatus}</span>
    //                                             </Menu.Item>
    //                                         ) : (
    //                                             keys === 5 ? (
    //                                                 <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
    //                                                     disabled={true}>
    //                                                     <span>{currentStatus}</span>
    //                                                 </Menu.Item>
    //                                             ) : (
    //                                                 <Menu.Item key={keys}
    //                                                     onClick={() => changeMyStatus(currentStatus, row)}>
    //                                                     <span>{currentStatus}</span>
    //                                                 </Menu.Item>
    //                                             )
    //                                         )
    //                                     )
    //                                     : (
    //                                         <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
    //                                             disabled={true}>
    //                                             <span>{currentStatus}</span>
    //                                         </Menu.Item>
    //                                     )
    //                             )
    //                     } else {
    //                         if ((props.incident?.ticketStatus?.status || props.incident.status?.value) === 'Pending') {
    //                             return keys === 3 ? (
    //                                 <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}>
    //                                     <span>{currentStatus}</span>
    //                                 </Menu.Item>
    //                             ) : (
    //                                 <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
    //                                     disabled={(oldStatusRender || oldStatus) !== currentStatus}>
    //                                     <span>{currentStatus}</span>
    //                                 </Menu.Item>
    //                             )
    //                         } else if ((props.incident?.ticketStatus?.status || props.incident.status?.value) === 'Resolved') {
    //                             return keys === 3 ? (
    //                                 <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}>
    //                                     <span>{currentStatus}</span>
    //                                 </Menu.Item>
    //                             ) : (
    //                                 <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
    //                                     disabled={true}>
    //                                     <span>{currentStatus}</span>
    //                                 </Menu.Item>
    //                             )
    //                         } else {
    //                             return (
    //                                 <Menu.Item key={keys} onClick={() => changeMyStatus(currentStatus, row)}
    //                                     disabled={true}>
    //                                     <span>{currentStatus}</span>
    //                                 </Menu.Item>
    //                             )
    //                         }
    //                     }
    //                 })
    //             }
    //         </Menu>
    //     )
    // }

    // function displayModal() {
    //     return (
    //         <>
    //             {myModal}
    //             {setMyModal(null)}
    //         </>
    //     )
    // }

    const handleOkRequireField = (e: any) => {
        e.preventDefault()
        props.form.validateFields((err: any, fieldsValue: any) => {
            let newStatus = ''
            if (!err) {
                setIsLoading(true)
                if (fieldsValue.Assignment_group !== null && fieldsValue.Assigned_to !== null) {
                    newStatus = 'InProgress'
                } else {
                    newStatus = 'Assigned'
                }
                setCurrentStatus(newStatus)
                const supportTeam: SupportTeam = props.supportTeamsWithPermission.find(it => it.name === fieldsValue.Assignment_group)!!
                const assignee: UserSupportTeam | undefined = supportTeam.assignees.find(it => it.users.fullName === fieldsValue.Assigned_to)
                const ticketStatus: TicketStatus = getTicketStatus(props.statuses, ticketType, newStatus)
                const request: any = {
                    id: incident.id,
                    supportTeam: { id: supportTeam.id } || undefined,
                    user: assignee?.users.id ? { id: assignee?.users.id } : undefined,
                    ticketStatus: { id: ticketStatus.id } || undefined
                }
                props.updateIncident(request).then(res => {
                    if (res === 200) {
                        setIsLoading(false)
                        setVisibleModal(false)
                        setOldStatused('')
                    }
                }).catch((err) => {
                    setIsLoading(false)
                    setVisibleModal(false)
                    message.error(`Failed updating incident. ${err}`)
                })
            }
        })
    }

    const handleCancelRequireField = () => {
        setVisibleModal(false)
        setIsRequireAssignee(false)
        setIsRequireAssignGroup(false)
        props.form.setFieldsValue({
            Assignment_group: incident.supportTeam?.name,
            Assigned_to: incident.user?.email
        })
    }

    // const onSaveModalPending = (newStatus: string) => {
    //     setCurrentStatus(newStatus)
    // }

    // function changeMyStatus(newStatus: string, data: Incident) {
    //     const oldStatusTemp = oldStatus
    //     const ticketStatus = getTicketStatus(props.statuses, ticketType, newStatus)
    //     if (ticketStatus?.status === 'Pending') {
    //         if (currentStatus === 'Pending') {
    //             setOldStatused(oldStatusTemp)
    //         } else {
    //             setOldStatused(currentStatus)
    //         }
    //         setMyModal(<ModalPending targetId={data.id}
    //             currentStatus={onSaveModalPending}
    //             statuses={props.statuses}
    //             disablePending={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy) || (disableAssignTo && currentStatus !== 'Pending')}
    //             ticketNumber = {props.incident.number!!}
    //             incidentData = {props.incident}
    //             ticketType = {ticketType}
    //             setCurrentStatus = {setCurrentStatus}
    //             setIsSpinning = {setIsSpinning}
    //         />)
    //     } else if (ticketStatus?.status === 'InProgress' || ticketStatus?.status === 'Resolved' || ticketStatus?.status === 'Closed') {
    //         if (ticketStatus?.status === 'Resolved' && data.resolution === null) {
    //             message.error('Unsuccessfully, Please input Resolution in Add note field.')
    //         } else if (ticketStatus?.status === 'Resolved' && (!((props.incident?.user || props.incident.assignedTo?.value) && (props.incident.supportTeam || props.incident.assignedGroups?.value)))) {
    //             setVisibleModal(true)
    //             setIsRequireAssignee(true)
    //             setIsRequireAssignGroup(true)
    //             setOldStatused(oldStatusTemp)
    //         } else {
    //             if (ticketStatus?.status === 'Resolved') {
    //                 if (props.incident.ticketStatus?.status !== 'Resolved') {
    //                     if (!((props.incident?.user || props.incident.assignedTo?.value) && (props.incident.supportTeam || props.incident.assignedGroups?.value))) {
    //                         setVisibleModal(true)
    //                         setIsRequireAssignee(true)
    //                         setIsRequireAssignGroup(true)
    //                         setOldStatused(oldStatusTemp)
    //                     } else {
    //                         const checkTicketStatus = TicketRelatedState.child.find((it) => {
    //                             return it.ticketStatus.status !== 'Resolved'
    //                         })
    //                         const checkWorkFlowStatus = dataWoRalateIncident.find((it) => {
    //                             return it.wo_status !== 'Closed'
    //                         })
    //                         if (TicketRelatedState.child.length > 0 && dataWoRalateIncident.length === 0) {
    //                             if (!checkTicketStatus) {
    //                                 props.updateIncident(
    //                                     {
    //                                         id: data.id,
    //                                         ticketStatus: newStatus,
    //                                         // resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
    //                                         satisfactionDone: 0
    //                                     } as unknown as Incident
    //                                 ).then((res) => {
    //                                     if (res) {
    //                                         setIsSatOpen(true)
    //                                     }
    //                                 }).catch((err) => {
    //                                     console.log(err)
    //                                 })
    //                                 setCurrentStatus(newStatus)
    //                                 // setTempStatus(newStatus)
    //                                 const tempOldStatus = oldStatus
    //                                 setOldStatused(tempOldStatus)
    //                             } else {
    //                                 Modal.warning({
    //                                     title: <b style={{ color: '#323276' }}>Warning</b>,
    //                                     content: <p style={{ color: '#323276' }}>It was related to some ticket, You have to complete thoses.</p>
    //                                 })
    //                             }
    //                         } else if (dataWoRalateIncident.length > 0 && TicketRelatedState.child.length === 0) {
    //                             if (!checkWorkFlowStatus) {
    //                                 props.updateIncident(
    //                                     {
    //                                         id: data.id,
    //                                         ticketStatus: newStatus,
    //                                         // resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
    //                                         satisfactionDone: 0
    //                                     } as unknown as Incident
    //                                 ).then((res) => {
    //                                     if (res) {
    //                                         setIsSatOpen(true)
    //                                     }
    //                                 }).catch((err) => {
    //                                     console.log(err)
    //                                 })
    //                                 setCurrentStatus(newStatus)
    //                                 // setTempStatus(newStatus)
    //                                 const tempOldStatus = oldStatus
    //                                 setOldStatused(tempOldStatus)
    //                             } else {
    //                                 Modal.warning({
    //                                     title: <b style={{ color: '#323276' }}>Warning</b>,
    //                                     content: <p style={{ color: '#323276' }}>It was related to some ticket, You have to complete thoses.</p>
    //                                 })
    //                             }
    //                         } else if (TicketRelatedState.child.length > 0 && dataWoRalateIncident.length > 0) {
    //                             if (!checkTicketStatus && !checkWorkFlowStatus) {
    //                                 props.updateIncident(
    //                                     {
    //                                         id: data.id,
    //                                         ticketStatus: newStatus,
    //                                         // resolution: commentText ? commentText.replace(/<[^>]*>/g, '') : null,
    //                                         satisfactionDone: 0
    //                                     } as unknown as Incident
    //                                 ).then((res) => {
    //                                     if (res) {
    //                                         setIsSatOpen(true)
    //                                     }
    //                                 }).catch((err) => {
    //                                     console.log(err)
    //                                 })
    //                                 setCurrentStatus(newStatus)
    //                                 // setTempStatus(newStatus)
    //                                 const tempOldStatus = oldStatus
    //                                 setOldStatused(tempOldStatus)
    //                             } else {
    //                                 Modal.warning({
    //                                     title: <b style={{ color: '#323276' }}>Warning</b>,
    //                                     content: <p style={{ color: '#323276' }}>It was related to some ticket, You have to complete thoses.</p>
    //                                 })
    //                             }
    //                         } else {
    //                             props.updateIncident(
    //                             {
    //                                 id: data.id,
    //                                 ticketStatus: ticketStatus,
    //                                 satisfactionDone: 0
    //                             } as Incident
    //                             ).then(() => {
    //                                 setCurrentStatus(ticketStatus?.status)
    //                                 setOldStatused(oldStatusTemp)
    //                                 setIsSatOpen(true)
    //                             }).catch((err) => {
    //                                 message.error(`You have unsuccessfully save the data. ${err}`)
    //                             })
    //                         }
    //                     }
    //                 }
    //             } else {
    //                 if (!(props.incident?.user && props.incident.supportTeam)) {
    //                     setVisibleModal(true)
    //                     setIsRequireAssignee(true)
    //                     setIsRequireAssignGroup(true)
    //                     setOldStatused(oldStatusTemp)
    //                 } else if (ticketStatus?.status === 'Closed' && (props.incident.satisfactionDone === null || props.incident.satisfactionDone === 0)) {
    //                     message.error('Unsuccessfully, Please do the Satisfaction Surveys.')
    //                     setOldStatused(oldStatusTemp)
    //                 } else {
    //                     props.updateIncident(
    //                             {
    //                                 id: data.id,
    //                                 ticketStatus: ticketStatus
    //                             } as unknown as Incident
    //                     )
    //                     setCurrentStatus(ticketStatus?.status)
    //                     setOldStatused(oldStatusTemp)
    //                 }
    //             }
    //         }
    //     } else if (ticketStatus?.status === 'Assigned') {
    //         if (!props.incident?.supportTeam) {
    //             setVisibleModal(true)
    //             setIsRequireAssignGroup(true)
    //             setOldStatused(oldStatusTemp)
    //         } else {
    //             props.updateIncident(
    //                 {
    //                     id: data.id,
    //                     ticketStatus: ticketStatus
    //                 } as unknown as Incident
    //             )
    //             setCurrentStatus(ticketStatus?.status)
    //             setOldStatused(oldStatusTemp)
    //         }
    //     } else if (ticketStatus?.status === 'Cancelled') {
    //         Modal.warning({
    //             title: <b style={{ color: '#323276' }}>Are you sure?</b>,
    //             content: <p style={{ color: '#323276' }}>You want to Cancelled this ticket?</p>,
    //             onOk: () => {
    //                 setCurrentStatus(ticketStatus?.status)
    //                 setOldStatused(oldStatusTemp)
    //                 props.updateIncident(
    //                     {
    //                         id: data.id,
    //                         ticketStatus: ticketStatus
    //                     } as unknown as Incident
    //                 )
    //             }
    //         })
    //     } else {
    //         setCurrentStatus(ticketStatus?.status)
    //         setOldStatused(oldStatusTemp)
    //         props.updateIncident(
    //             {
    //                 id: data.id,
    //                 ticketStatus: ticketStatus
    //             } as unknown as Incident
    //         )
    //     }
    // }

    const buildTooltipTextForSlaTag = (incident: IncidentWithSlaCalculations) => {
        const findSlaGoal = (slaCalculation: SlaCalculation): SlaGoal =>
            props.incidentState.slaPolicyHistories.get(slaCalculation.slaPolicyHistoryId)?.slaGoals
                .find((it) => it.name === slaCalculation.slaGoalName)!!

        const findSlaName = (slaCalculation: SlaCalculation) =>
                props.incidentState.slaPolicyHistories.get(slaCalculation.slaPolicyHistoryId)?.name || ''

        return (<div>
            {notEmpty(incident.slaCalculations)
                ? incident.slaCalculations.map((it) => (
                    <p key={it.id!! + it.lastModifiedDatetime}>
                        <SlaTimeUsedUpPercentage2 incident={incident} slaCalculation={it} slaGoal={findSlaGoal(it)} slaName={findSlaName(it)}/>
                    </p>
                ))
                : null
            }
        </div>)
    }

    // function slaTag(incident: IncidentWithSlaCalculations): (JSX.Element | null) {
    //     const items = incident.slaCalculations
    //     const allStatusUnique = toDistinctArray(items.map((it) => it.slaStatus))
    //     const anyNotCancelled = allStatusUnique.find((it) => it !== 'Cancelled') != null

    //     return anyNotCancelled ? (<Tag className="tagSLA">SLA</Tag>) : null
    // }

    const FilterSupportTeam = (e: SelectValue) => {
        props.form.setFieldsValue({
            Assigned_to: null
        })
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            targetSupportTeams.forEach((res: any) => {
                const user = res.assignees?.map(it => {
                    return it.users.fullName || ''
                })
                setAssignee(user)
            })
        } else {
            setAssignee([])
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            const user: string[] = []
            res.assignees?.map(it => {
                user.push(it.users.firstName + ' ' + it.users.lastName)
            })
            setAssignee(user)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e)
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: null
                })
            }
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        if (assignee) {
            const isAssignee = assignee.includes((value as string))
            if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: null
                })
            }
        }
    }

    const isSATOpen = () => {
        setIsSatOpen(true)
    }

    function setTitle(): string {
        return `Please Require filed (${props.incident.number})`
    }

    const menuRelate = (
        <Menu>
            <RelateList visibleRelated={visibleRelated} setVisibleRelated={setVisibleRelated} incidentAndSLA={props.incident}/>
        </Menu>
    )

    const handleVisibleChange = () => {
        setVisibleRelated(!visibleRelated)
    }

    const handleVisibleChangeSLA = () => {
        setIsSLAOpen(!isSLAOpen)
    }

    const menuSLA = (
        <Menu>
            {/* <SlaTimeUsedUpPercentageEnhance incidentAndSLA={props.incident}/> */}
            <div style={{padding: 15, minWidth: 350}}>
                <Row gutter={[26, 26]} style={{ borderBottom: '1px solid rgb(224, 224, 224' }}>
                    <Col span={16}>
                        <div style={{ fontSize: 15, fontWeight: 500 }}>SLAs ( {incident.number} )</div>
                    </Col>
                    <Col span={8}>
                        <div style={{ textAlign: 'end'}}> <Icon type="close" onClick={() => setIsSLAOpen(false)}/></div>
                    </Col>
                </Row>
                <br/>
                {buildTooltipTextForSlaTag(incident)}
            </div>
        </Menu>
    )

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col sm={24} md={16} lg={16} style={{ marginTop: 8, paddingBottom: 0, lineHeight: '25px' }}>
                    {props.isMedium ? <div style={{ textAlign: 'end', marginBottom: 5 }}>
                        <span >
                            {incident.viewed === false ? (<span>
                                <Tag color="#87d068">NEW</Tag>
                            </span>) : null}
                        </span>
                        <span>
                            {/* {incident.isRelated ? <Dropdown overlay={menuRelate} trigger={['click']} visible={visibleRelated} onVisibleChange={handleVisibleChange} >
                                <Tag color="#FAAD14">Related</Tag>
                            </Dropdown> : null} */}
                        </span>
                        <span>
                            {incident.isSla ? <Dropdown overlay={menuSLA} trigger={['click']} visible={isSLAOpen} onVisibleChange={handleVisibleChangeSLA} >
                                <Tag color="#F6712E">SLA</Tag>
                            </Dropdown> : null}
                            <div style={{float: 'right'}}>
                                {!incident.satisfactionDone && (props.incident?.status?.value === 'Resolved' || props.incident.ticketStatus?.status === 'Resolved')
                                    ? <Icon type="info-circle" theme="filled" style={{color: '#0050B3', cursor: 'pointer'}} onClick={() => isSATOpen()}/> : null}
                            </div>
                        </span>
                    </div> : null}
                    <Row gutter={[16, 16]}>
                        {props.isMedium ? <>
                            <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0, paddingTop: 0, wordBreak: 'break-word'}}>
                                <Row>
                                    <Col span={10}>
                                        <span onClick={() => waitingGetSessions()}>
                                            <Link to={`/IncidentDetailsView/${props.incident.id}`} target="_blank" onClick={() => !props.incident.viewed ? markIncidentViewed(props.incident.id!!, dispatch) : undefined}>
                                                {props.incident.number}
                                            </Link>
                                        </span>
                                    </Col>
                                    <Col span={14}>{incident.subject.length > 50 ? (<>{incident.subject.substring(0, 50) + ' ...'}</>) : (<>{incident.subject}</>)}</Col>
                                </Row>
                            </Col>
                        </> : <>
                            <Col sm={6} md={4} lg={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                <span onClick={() => waitingGetSessions()}>
                                    <Link to={`/IncidentDetailsView/${props.incident.id}`} target="_blank" onClick={() => !props.incident.viewed ? markIncidentViewed(props.incident.id!!, dispatch) : undefined}>
                                        {props.incident.number}
                                    </Link>
                                </span>
                            </Col>
                            <Col sm={18} md={11} lg={11} style={{ paddingBottom: 0, paddingTop: 0 }}>
                                {incident.subject.length > 50 ? (<>{incident.subject.substring(0, 50) + ' ...'}</>) : (<>{incident.subject}</>)}
                            </Col>
                        </>}
                        <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <div style={{textAlign: 'end', display: props.isMedium ? 'none' : 'block' }}>
                                <span >
                                    {incident.viewed === false ? (<span>
                                        <Tag color="#87d068">NEW</Tag>
                                    </span>) : null}
                                </span>
                                <span>
                                    {incident.isRelated ? <Dropdown overlay={menuRelate} trigger={['click']} visible={visibleRelated} onVisibleChange={handleVisibleChange} >
                                        <Tag color="#FAAD14">Related</Tag>
                                    </Dropdown> : null}
                                </span>
                                <span>
                                    {incident.isSla ? <Dropdown overlay={menuSLA} trigger={['click']} visible={isSLAOpen} onVisibleChange={handleVisibleChangeSLA}>
                                        <Tag color="#F6712E">SLA</Tag>
                                    </Dropdown> : null}
                                    <div style={{float: 'right'}}>
                                        {!incident.satisfactionDone && (props.incident?.status?.value === 'Resolved' || props.incident.ticketStatus?.status === 'Resolved')
                                            ? <Icon type="info-circle" theme="filled" style={{color: '#0050B3', cursor: 'pointer'}} onClick={() => isSATOpen()}/> : null}
                                    </div>
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        {props.isMedium ? <>
                            <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0, paddingTop: 0}}>
                                <Row>
                                    <Col span={10}>Requester : </Col>
                                    <Col span={14}>{incident.requesterName || incident.people.fullName}</Col>
                                </Row>
                            </Col>
                        </> : <>
                            <Col sm={6} md={4} lg={4} style={{ paddingBottom: 0, paddingTop: 0 }}><div>Requester :</div></Col>
                            <Col sm={18} md={11} lg={11} style={{ paddingBottom: 0, paddingTop: 0 }}><div>{incident.requesterName || incident.people.fullName}</div></Col>
                        </>}
                        {props.isMedium ? <Row style={{ padding: '0px 8px'}}>
                            <Col span={10}>Last modify date : </Col>
                            <Col span={14}>{moment(props.incident.lastModifiedDatetime).format(format)}</Col>
                        </Row> : <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
                        Last modify date : {moment(props.incident.lastModifiedDatetime).format(format)}
                        </Col>}
                    </Row>
                    <Row>
                        {props.isMedium ? <>
                            <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0 }}>
                                <Row>
                                    <Col span={10}>Created : </Col>
                                    <Col span={14}>{moment(props.incident.createdDatetime).format(format)}</Col>
                                </Row>
                            </Col>
                        </> : <>
                            <Col sm={6} md={4} lg={4} style={{ paddingBottom: 0, paddingTop: 0 }}><div>Created : </div></Col>
                            <Col sm={18} md={11} lg={11} style={{ paddingBottom: 0, paddingTop: 0 }}><div>&nbsp;{moment(props.incident.createdDatetime).format(format)}</div></Col>
                        </>}
                        <Col sm={24} md={9} lg={9} style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: props.isMedium ? 0 : 10 }}>
                            <div><IncidentTimeIndex incident={incident} isMedium={props.isMedium} incidentState={props.incidentState}/></div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={8} lg={8} style={{ paddingBottom: 0, borderLeft: '1px solid rgb(224, 224, 224' }}>
                    <Spin spinning={isSpinning}>
                        <Row gutter={[16, 16]} style={{ paddingBottom: 0, paddingLeft: 24, lineHeight: '24px' }}>
                            <Col sm={24} md={24} lg={24}>
                                <Row>
                                    <Col span={props.isMedium ? 11 : 8}>Assigned to :</Col>
                                    <Col span={props.isMedium ? 13 : 16}>
                                        <Tooltip
                                            placement="top" title={
                                                (incident.assignedGroups === null || incident.supportTeam === null) && (incident.assignedTo === null || incident.user === null) ? 'None' : (incident.assignedGroups?.value !== null || incident.supportTeam !== null) && (incident.assignedTo === null || incident.user === null) ? (incident.assignedGroups?.value || incident.supportTeam?.name) + ' / None' : (incident.assignedGroups?.value || incident.supportTeam?.name) + ' / ' + (incident.assignedTo?.value || incident.user?.fullName)}>
                                            <span>
                                                {(incident.assignedGroups?.value || incident.supportTeam) ? ((incident.assignedGroups?.value?.length || incident.supportTeam?.name.length || ''.length) > 18 ? (
                                                    <span>
                                                        {/* <Dropdown
                                                            disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy) || disableAssignTo}
                                                            overlay={() => DueMenuSupport(incident)} trigger={['click']}>
                                                            <span>{(incident.assignedGroups?.value || incident.supportTeam!!.name || '')?.substring(0, 5) + '... / '}{(incident?.assignedTo || incident?.user?.fullName) ? (incident.assignedTo?.value || incident.user?.fullName!! || '')?.substring(0, 5) + '...' : ''}<Icon type="down"/></span>
                                                        </Dropdown> */}
                                                        {(incident.assignedGroups?.value || incident.supportTeam!!.name || '')?.substring(0, 18) + '... / '}{(incident?.assignedTo?.value || incident?.user?.fullName || '')?.toString().length > 18 ? (incident.assignedTo?.value || incident.user?.fullName!! || 'None')?.substring(0, 18) + '...' : (incident.assignedTo?.value || incident.user?.fullName!! || 'None')}
                                                    </span>) : (<span>
                                                    {/* <Dropdown
                                                        disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy) || disableAssignTo}
                                                        overlay={() => DueMenuSupport(incident)} trigger={['click']}>
                                                        <span>{(incident.assignedGroups?.value || incident.supportTeam?.name) + ' / '}{(incident.assignedTo?.value || incident.user?.fullName) ? ((incident.assignedTo?.value || incident.user?.fullName) || '')?.substring(0, 7) + '...' : ''}<Icon type="down"/></span>
                                                    </Dropdown> */}
                                                    {(incident.assignedGroups?.value || incident.supportTeam?.name) + ' / '}{(incident.assignedTo?.value || incident.user?.fullName || '')?.length > 18 ? ((incident.assignedTo?.value || incident.user?.fullName) || 'None')?.substring(0, 18) + '...' : (incident.assignedTo?.value || incident.user?.fullName!! || 'None')}
                                                </span>)) : (
                                                    <span>
                                                        {/* <Dropdown
                                                            disabled={!checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy) || disableAssignTo}
                                                            overlay={() => DueMenuSupport(incident)} trigger={['click']}>
                                                            <span >None<Icon type="down"/></span>
                                                        </Dropdown> */}
                                                        None
                                                    </span>
                                                )
                                                }
                                            </span></Tooltip>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={props.isMedium ? 11 : 8}>Status :</Col>
                                    <Col span={props.isMedium ? 13 : 16}>
                                        {/* <Dropdown
                                            disabled={!checkRolesState(RoleType.Incident, 'CloseIncident', props.incident.createdBy) && !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident.createdBy)}
                                            overlay={() => DueMenuStatus(props.incident)} trigger={['click']} key={props.incident.id}>
                                            <div >{props.incident?.status?.value || props.incident.ticketStatus?.status}<Icon type="down"/></div>
                                        </Dropdown> */}
                                        {props.incident?.status?.value || props.incident.ticketStatus?.status}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={props.isMedium ? 11 : 8}>Priority :</Col>
                                    <Col span={props.isMedium ? 13 : 16}>
                                        {props.priority.map((priority: PriorityLevel, index) => {
                                            return (
                                                (incident.priority?.priority?.name || incident.priority?.name) === priority.name ? (
                                                    <Tag style={{backgroundColor: priority.tagColor, color: '#fff'}}
                                                        key={index}>{(incident.priority?.priority?.name || incident.priority?.name)}</Tag>
                                                ) : null
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Spin>
                </Col>
            </Row>
            <div>
                <Modal
                    visible={visibleModal}
                    title={setTitle()}
                    onOk={handleOkRequireField}
                    onCancel={handleCancelRequireField}
                    footer={[
                        <Button key="back" onClick={handleCancelRequireField}>
                                    Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleOkRequireField}
                            disabled={disableAssignTo} loading={isLoading}>
                                    Save
                        </Button>
                    ]}
                >
                    <Form onSubmit={handleOkRequireField}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label={'Assignment group'}>
                                    {getFieldDecorator('Assignment_group'
                                        , {
                                            initialValue: incident.supportTeam?.name || incident.assignedGroups?.value,
                                            rules: [{
                                                required: isRequireAssignGroup,
                                                whitespace: true,
                                                message: 'Assignment group is required'
                                            }]
                                        })(
                                        <AutoComplete
                                            style={{width: '100%'}}
                                            dataSource={(mySupportTeamName || []).map((it) => {
                                                return it
                                            })}
                                            onChange={(e) => checkAssignee(e)}
                                            onSelect={(e) => FilterSupportTeam(e)}
                                            onBlur={(e) => onBlurTeam(e)}
                                            placeholder={'Select an assignment group'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={24}>
                                <Form.Item label={'Assigned to'}>
                                    {getFieldDecorator('Assigned_to'
                                        , {
                                            initialValue: incident.user?.email || incident?.assignedTo?.value,
                                            rules: [{
                                                required: isRequireAssignee,
                                                whitespace: true,
                                                message: 'Assigned is required'
                                            }]
                                        }
                                    )(
                                        <AutoComplete style={{width: '100%'}}
                                            dataSource={assignee}
                                            onFocus={() => FilterAssignee()}
                                            onBlur={(e) => onBlurAssignee(e)}
                                            placeholder={'Select an assignee'}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                <Modal title="How is support quality?" visible={isSatOpen} width={500} onCancel={() => setIsSatOpen(false)}
                    footer={false}>
                    <CustomerSat incident={incident} setModalClose={() => setIsSatOpen(false)} isOpenfromList={true}/>
                </Modal>
            </div>
        </div>
    )
}

const WrappedIncidentRowEnhance = Form.create<Props>()(IncidentRowEnhance)
export default connect(
    mapStateToProps, {
        updateIncident,
        getAllWorkLogByIncidentId: getAllWorkLogByIncidentId,
        addSatisfaction
    })(WrappedIncidentRowEnhance)
