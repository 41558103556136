/* eslint-disable prefer-const */
import React, { useContext, useEffect, useState } from 'react'
import { Asset, Preventive, pmUploadLink } from '../../Model'
import { UploadFile } from 'antd/lib/upload/interface'
import { UploadLink } from '../../../file-server-storage/model'
import exportIcon from '../../../common-file/icon-file'
import { Checkbox, Col, Empty, Row, message } from 'antd'
import CheckboxImage from './CheckboxImage'
import { SelectedImageAssetContext } from './ContextAPI/SelectedImageAsset'
import { SelectedImagePMContext } from './ContextAPI/SelectedImagePM'
import { PhotoProvider } from 'react-photo-view'

interface Param {
    dataPM?: Preventive
    currentStep: number
    setCurrentStep: Function
    dataOriginalAsset?: Asset
}

type Props = Param
const SyncPMStep1Form = (props: Props) => {
    const [fileList, setFileList] = useState<any[]>([])
    const [CustomPictureList, setCustomPictureList] = useState<any[]>([])
    const { SelectedImageAssetState, GetSelectedImageAsset } = useContext<any>(SelectedImageAssetContext)
    const { SelectedImagePMState, GetSelectedImagePM } = useContext<any>(SelectedImagePMContext)

    useEffect(() => {
        if (props.dataPM) {
            if (props.dataPM?.pmUploadLink) {
                const filterPMUploadLink = props.dataPM?.pmUploadLink?.filter((it) => { return it.url !== null })
                const fileList: any[] = filterPMUploadLink?.map((file: pmUploadLink) => {
                    const uploadFile: pmUploadLink = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        thumbUrl: exportIcon(file.type || ''),
                        size: 0,
                        customPictureId: file.customPictureId,
                        pictureName: file.customField?.pictureName
                    }
                    return uploadFile
                })
                setCustomPictureList(fileList)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataPM])

    useEffect(() => {
        if (props.dataOriginalAsset) {
            if (props.dataOriginalAsset?.attachFile) {
                const attachFile = JSON.parse(props.dataOriginalAsset.attachFile)
                const fileList: UploadFile[] = attachFile?.map((file: UploadLink) => {
                    const uploadFile: UploadFile = {
                        uid: file.name,
                        name: file.name,
                        status: 'done',
                        url: file.url,
                        type: file.type,
                        thumbUrl: exportIcon(file.type),
                        size: 0
                    }
                    return uploadFile
                })
                setFileList(fileList)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataOriginalAsset])

    const selectAllImageAssetDetail = (value) => {
        if (value.target.checked) {
            if (SelectedImagePMState.length + fileList.length < 21) {
                let tempImgId: string[] = []
                fileList.forEach((it: pmUploadLink) => {
                    tempImgId.push(it.url!!)
                })
                GetSelectedImageAsset(tempImgId)
            } else {
                message.warning('Select a maximum of 20 pictures.')
            }
        } else {
            GetSelectedImageAsset([])
        }
    }

    const selectAllImagePM = (value) => {
        if (value.target.checked) {
            if (SelectedImageAssetState.length + CustomPictureList.length < 21) {
                let tempImgId: string[] = []
                CustomPictureList.forEach((it: pmUploadLink) => {
                    tempImgId.push(it.url!!)
                })
                GetSelectedImagePM(tempImgId)
            } else {
                message.warning('Select a maximum of 20 pictures.')
            }
        } else {
            GetSelectedImagePM([])
        }
    }

    const onChangeCheckboxAssetDetail = (value) => {
        if (SelectedImageAssetState.length + SelectedImagePMState.length < 20) {
            GetSelectedImageAsset(value)
        } else {
            if (SelectedImageAssetState.length > value.length) {
                GetSelectedImageAsset(value)
            } else {
                message.warning('Select a maximum of 20 pictures.')
            }
        }
    }

    const onChangeCheckboxPM = (value) => {
        if (SelectedImageAssetState.length + SelectedImagePMState.length < 20) {
            GetSelectedImagePM(value)
        } else {
            if (SelectedImagePMState.length > value.length) {
                GetSelectedImagePM(value)
            } else {
                message.warning('Select a maximum of 20 pictures.')
            }
        }
    }

    return (
        <div>
            {/* <Divider /> */}
            {fileList.length + CustomPictureList.length !== 0 ? <Row style={{ margin: '10px 15px' }}>

            </Row> : null}
            <Row>
                <Col lg={2} />
                <Col md={22} lg={20} xs={24} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                    <p className="main-title" style={{ margin: 12 }}>Original Asset Images</p>
                </Col>
                <Col lg={2} />
            </Row>
            <Row style={{ margin: '20px 0px 20px 20px' }}>
                <Col lg={2} />
                <Col span={20} md={22} lg={20} xs={24}>
                    {fileList.length > 0 ? <Col md={22} lg={24} style={{ margin: '10px 0px' }}>
                        <Checkbox checked={SelectedImageAssetState?.length === fileList.length}
                            indeterminate={SelectedImageAssetState?.length > 0 && SelectedImageAssetState?.length !== fileList.length}
                            onChange={selectAllImageAssetDetail}
                        > Select all images Asset detail </Checkbox>
                    </Col> : null}
                    {fileList && fileList?.length !== 0 ? <div style={{ height: fileList.length < 7 ? '150px' : '280px', overflowY: 'scroll', width: '100%', overflowX: 'hidden' }} className='display-scrollbar-height500'>
                        <PhotoProvider >
                            <Checkbox.Group onChange={onChangeCheckboxAssetDetail} value={SelectedImageAssetState} style={{ width: '100%' }} key={'1'} >
                                <Row gutter={[12, 12]}>
                                    {fileList.map((it: pmUploadLink) => {
                                        return <Col xs={12} sm={12} lg={4} span={4} key={it.name}><CheckboxImage imageDetail={it} /></Col>
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </PhotoProvider>
                    </div> : <Row><Col md={1} lg={4} /><Col md={22} lg={16}><div style={{ height: 180 }}><br /><Empty /><br /></div></Col><Col span={4} /></Row>}
                </Col>
                <Col lg={2} />
            </Row>
            <br />
            <Row>
                <Col span={2} />
                <Col md={22} lg={20} xs={24} style={{ backgroundColor: '#F0F0F0', padding: 5, borderRadius: 5 }}>
                    <p className="main-title" style={{ margin: 12 }}>Desired Images for PM</p>
                </Col>
                <Col span={2} />
            </Row>
            <Row style={{ margin: '20px 0px 20px 20px' }}>
                <Col span={2} />
                <Col span={20} md={22} lg={20} xs={24}>
                    {CustomPictureList.length > 0 ? <Col md={22} lg={24} style={{ margin: '10px 0px' }}>
                        <Checkbox checked={SelectedImagePMState?.length === CustomPictureList.length}
                            indeterminate={SelectedImagePMState?.length > 0 && SelectedImagePMState?.length !== CustomPictureList.length}
                            onChange={selectAllImagePM}
                        > Select all desired images for PM</Checkbox>
                    </Col> : null}
                    {CustomPictureList.length !== 0 ? <div style={{ height: CustomPictureList.length < 7 ? '150px' : '280px', overflowY: 'scroll', width: '100%', overflowX: 'hidden' }} key={'2'} className='display-scrollbar-height500'>
                        <PhotoProvider >
                            <Checkbox.Group onChange={onChangeCheckboxPM} value={SelectedImagePMState} style={{ width: '100%' }} >
                                <Row gutter={[12, 12]}>
                                    {CustomPictureList.map((it: pmUploadLink) => {
                                        return <Col xs={12} sm={12} lg={4} span={4} key={it.name}> <CheckboxImage imageDetail={it} /></Col>
                                    })}
                                </Row>
                            </Checkbox.Group >
                        </PhotoProvider>
                    </div> : <Row><Col md={1} lg={4} /><Col md={22} lg={16}><div style={{ height: 180 }}><br /><Empty /><br /></div></Col><Col span={4} /></Row>}
                </Col>
                <Col span={2} />
            </Row>
            {/* <Divider /> */}
        </div>
    )
}

export default SyncPMStep1Form
