/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { StoreState } from '../../store'
import {
    Breadcrumb,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Icon,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Tag,
    TimePicker, Tooltip
} from 'antd'
import { Link, Redirect, useLocation, useParams } from 'react-router-dom'
import moment from 'moment'
import { BusinessHour, Holiday, WorkingDay } from './model'
import { FormComponentProps } from 'antd/es/form'
import { addBusinessHour, getAllBusinessHour, getBusinessHourById, loadHoliday, updateBusinessHour } from './service'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

const mapStateToProps = (state: StoreState) => {
    return { businessHours: state.businessHours }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    businessHours: BusinessHour[];
    getAllBusinessHour: () => Promise<number>;
    addBusinessHour: (businessHour: BusinessHour) => Promise<number>;
    updateBusinessHour: (businessHour: BusinessHour) => Promise<number>;
}

interface Params {
    id?: string
}

type Props = StateProps & DispatchProps & Params & FormComponentProps

let originId
const BusinessHoursForm: React.FC<Props> = (props: Props) => {
    const { id } = useParams<Params>()
    const { form } = props
    const location = useLocation()
    const spaceBetween: any = '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0'
    const [inputDateString, setInputDateString] = useState<string>('')
    const [tags, setMyTags] = useState<any>([])
    const [isRedirect, setIsRedirect] = useState(false)
    const [defaultBusinessHourName, setDefaultBusinessHourName] = useState<string>('')
    // mon
    const [time_picker_start_mon, set_time_picker_start_mon] = useState<string>('08:00')
    const [time_picker_end_mon, set_time_picker_end_mon] = useState<string>('17:00')
    // tues
    const [time_picker_start_tues, set_time_picker_start_tues] = useState<string>('08:00')
    const [time_picker_end_tues, set_time_picker_end_tues] = useState<string>('17:00')
    // wed
    const [time_picker_start_wed, set_time_picker_start_wed] = useState<string>('08:00')
    const [time_picker_end_wed, set_time_picker_end_wed] = useState<string>('17:00')
    // thu
    const [time_picker_start_thu, set_time_picker_start_thu] = useState<string>('08:00')
    const [time_picker_end_thu, set_time_picker_end_thu] = useState<string>('17:00')
    // fri
    const [time_picker_start_fri, set_time_picker_start_fri] = useState<string>('08:00')
    const [time_picker_end_fri, set_time_picker_end_fri] = useState<string>('17:00')
    // sat
    const [time_picker_start_sat, set_time_picker_start_sat] = useState<string>('08:00')
    const [time_picker_end_sat, set_time_picker_end_sat] = useState<string>('17:00')
    // sun
    const [time_picker_start_sun, set_time_picker_start_sun] = useState<string>('08:00')
    const [time_picker_end_sun, set_time_picker_end_sun] = useState<string>('17:00')
    // break
    const [time_picker_start_break, set_time_picker_start_break] = useState<string>('12:00')
    const [time_picker_end_break, set_time_picker_end_break] = useState<string>('13:00')

    const [check_name, setCheck_name] = useState<string[] | CheckboxValueType[]>([])
    const [check_break, setCheck_break] = useState<string[] | CheckboxValueType[]>([])

    const [businessHourTemp, setBusinessHour] = useState<BusinessHour>()

    const isView: boolean = location.pathname.includes('BusinessHourSettingFormView')
    const isClone: boolean = location.pathname.includes('BusinessHourSettingFormClone')
    const isEdit: boolean = location.pathname.includes('BusinessHourSettingFormEdit')
    const isCreate = !(isView || isClone || isEdit)
    const format = 'HH:mm'

    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (props.businessHours.length === 0) {
            focusCloneBusiness()
        }
        if (id !== undefined) {
            getBusinessHourById(id).then((result) => {
                loadGeneralInfoIntoFormField(result)
                loadWorkDayInfoIntoFormFields(result)
                setBusinessHour(result)
            }).catch((err) => message.error(`Failed loading business hour ID ${id}. ${err}`))
        } else {
            loadHolidayWhenCreate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadGeneralInfoIntoFormField = (businessHour: BusinessHour) => {
        setDefaultBusinessHourName(businessHour.name)
        originId = businessHour.originId
        if (isClone) {
            props.form.setFieldsValue({
                Description: businessHour.description,
                Radio7x24: businessHour.fullTime
            })
        } else {
            props.form.setFieldsValue({
                name: businessHour.name,
                Description: businessHour.description,
                Radio7x24: businessHour.fullTime
            })
        }
    }

    const loadHolidayWhenCreate = () => {
        // @ts-ignore
        loadHoliday().then((res: Holiday[]) => {
            if (res.length > 0) {
                let holiday_name = ''
                let date = ''
                const tempTags: any[] = []
                res.forEach((item: any) => {
                    holiday_name = item.holidayName
                    date = item.date
                    tempTags.push(date + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + holiday_name)
                })
                setMyTags(tempTags)
            }
        }).catch((err) => {
            message.error(`Failed getting all holidays. ${err}`)
        })
    }

    const loadWorkDayInfoIntoFormFields = (businessHour: BusinessHour) => {
        if (businessHour.workingDays) {
            businessHour.workingDays.forEach((workingDay: WorkingDay) => {
                check_name.push(workingDay.dayOfWeek!!)
                if (workingDay.dayOfWeek === 'Monday') {
                    set_time_picker_end_mon(workingDay.stop!!)
                    set_time_picker_start_mon(workingDay.start!!)
                    props.form.setFieldsValue({
                        time_picker_start_mon: moment(workingDay.start!!, format),
                        time_picker_end_mon: moment(workingDay.stop!!, format)
                    })
                } else if (workingDay.dayOfWeek === 'Tuesday') {
                    set_time_picker_end_tues(workingDay.stop!!)
                    set_time_picker_start_tues(workingDay.start!!)
                    props.form.setFieldsValue({
                        time_picker_start_tues: moment(workingDay.start!!, format),
                        time_picker_end_tues: moment(workingDay.stop!!, format)
                    })
                } else if (workingDay.dayOfWeek === 'Wednesday') {
                    set_time_picker_end_wed(workingDay.stop!!)
                    set_time_picker_start_wed(workingDay.start!!)
                    props.form.setFieldsValue({
                        time_picker_start_wed: moment(workingDay.start!!, format),
                        time_picker_end_wed: moment(workingDay.stop!!, format)
                    })
                } else if (workingDay.dayOfWeek === 'Thursday') {
                    set_time_picker_end_thu(workingDay.stop!!)
                    set_time_picker_start_thu(workingDay.start!!)
                    props.form.setFieldsValue({
                        time_picker_start_thu: moment(workingDay.start!!, format),
                        time_picker_end_thu: moment(workingDay.stop!!, format)
                    })
                } else if (workingDay.dayOfWeek === 'Friday') {
                    set_time_picker_end_fri(workingDay.stop!!)
                    set_time_picker_start_fri(workingDay.start!!)
                    props.form.setFieldsValue({
                        time_picker_start_fri: moment(workingDay.start!!, format),
                        time_picker_end_fri: moment(workingDay.stop!!, format)
                    })
                } else if (workingDay.dayOfWeek === 'Saturday') {
                    set_time_picker_end_sat(workingDay.stop!!)
                    set_time_picker_start_sat(workingDay.start!!)
                    props.form.setFieldsValue({
                        time_picker_start_sat: moment(workingDay.start!!, format),
                        time_picker_end_sat: moment(workingDay.stop!!, format)
                    })
                } else if (workingDay.dayOfWeek === 'Sunday') {
                    set_time_picker_end_sun(workingDay.stop!!)
                    set_time_picker_start_sun(workingDay.start!!)
                    props.form.setFieldsValue({
                        time_picker_start_sun: moment(workingDay.start!!, format),
                        time_picker_end_sun: moment(workingDay.stop!!, format)
                    })
                }
            })
        }
        if (businessHour.breakTimeStart && businessHour.breakTimeEnd) {
            if (businessHour.breakTimeStart || businessHour.breakTimeEnd) {
                setCheck_break(['Break'])
                set_time_picker_end_break(businessHour.breakTimeEnd)
                set_time_picker_start_break(businessHour.breakTimeStart)
                props.form.setFieldsValue({
                    time_picker_start_break: moment(businessHour.breakTimeStart, format),
                    time_picker_end_break: moment(businessHour.breakTimeEnd, format)
                })
            }
        }

        if (businessHour.holidays !== null) {
            let holiday_name = ''
            let date = ''
            const tempTags: any[] = []
            businessHour.holidays?.forEach((item: any) => {
                holiday_name = item.holidayName
                date = item.date
                tempTags.push(date + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + holiday_name)
            })
            setMyTags(tempTags)
        }
    }

    const setFormatTime = (s: string) => {
        return s.length === 5 ? `${s}:00` : s
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        props.form.validateFields(async (err, values) => {
            if (!err) {
                setIsLoading(true)
                const working_day: any[] = []
                const holiday_list: any[] = []

                tags.forEach((data) => {
                    const temp = data.split('\xa0')
                    holiday_list.push({
                        holidayName: temp[temp.length - 1],
                        date: temp[0]
                    })
                })

                if (values.checkbox_group !== undefined) {
                    values.checkbox_group.forEach((item) => {
                        if (item === 'Monday' && time_picker_start_mon !== '' && time_picker_end_mon !== '') {
                            working_day.push({
                                dayOfWeek: 'Monday',
                                start: setFormatTime(time_picker_start_mon),
                                stop: setFormatTime(time_picker_end_mon)
                            })
                        }

                        if (item === 'Tuesday' && time_picker_start_tues !== '' && time_picker_end_tues !== '') {
                            working_day.push({
                                dayOfWeek: 'Tuesday',
                                start: setFormatTime(time_picker_start_tues),
                                stop: setFormatTime(time_picker_end_tues)
                            })
                        }

                        if (item === 'Wednesday' && time_picker_start_wed !== '' && time_picker_end_wed !== '') {
                            working_day.push({
                                dayOfWeek: 'Wednesday',
                                start: setFormatTime(time_picker_start_wed),
                                stop: setFormatTime(time_picker_end_wed)
                            })
                        }

                        if (item === 'Thursday' && time_picker_start_thu !== '' && time_picker_end_thu !== '') {
                            working_day.push({
                                dayOfWeek: 'Thursday',
                                start: setFormatTime(time_picker_start_thu),
                                stop: setFormatTime(time_picker_end_thu)
                            })
                        }

                        if (item === 'Friday' && time_picker_start_fri !== '' && time_picker_end_fri !== '') {
                            working_day.push({
                                dayOfWeek: 'Friday',
                                start: setFormatTime(time_picker_start_fri),
                                stop: setFormatTime(time_picker_end_fri)
                            })
                        }

                        if (item === 'Saturday' && time_picker_start_sat !== '' && time_picker_end_sat !== '') {
                            working_day.push({
                                dayOfWeek: 'Saturday',
                                start: setFormatTime(time_picker_start_sat),
                                stop: setFormatTime(time_picker_end_sat)
                            })
                        }

                        if (item === 'Sunday' && time_picker_start_sun !== '' && time_picker_end_sun !== '') {
                            working_day.push({
                                dayOfWeek: 'Sunday',
                                start: setFormatTime(time_picker_start_sun),
                                stop: setFormatTime(time_picker_end_sun)
                            })
                        }
                    })
                }

                let break_day: any
                if (values.checkbox_break !== undefined && values.checkbox_break.length > 0) {
                    values.checkbox_break.forEach((item) => {
                        if (item === 'Break' && time_picker_start_break !== '' && time_picker_end_break !== '') {
                            break_day = {
                                start: setFormatTime(time_picker_start_break),
                                end: setFormatTime(time_picker_end_break)
                            }
                        }
                    })
                } else {
                    break_day = {
                        start: null,
                        end: null
                    }
                }
                if (values.Radio7x24 === false && working_day.length === 0) {
                    message.warning('Please select working days/hours')
                    setIsLoading(false)
                } else {
                    let promise: Promise<number>
                    if (isClone || isCreate) {
                        promise = props.addBusinessHour({
                            name: values.name?.replace(/ +/g, ' ').trim(),
                            description: values.Description?.replace(/ +/g, ' ').trim(),
                            fullTime: values.Radio7x24,
                            breakTimeStart: break_day.start,
                            breakTimeEnd: break_day.end,
                            workingDays: working_day.length === 0 ? [] : working_day,
                            holidays: holiday_list
                        })
                    } else {
                        setDefaultBusinessHourName(values.name)
                        promise = props.addBusinessHour({
                            originId: originId,
                            name: values.name?.replace(/ +/g, ' ').trim(),
                            description: values.Description?.replace(/ +/g, ' ').trim(),
                            fullTime: values.Radio7x24,
                            breakTimeStart: break_day.start,
                            breakTimeEnd: break_day.end,
                            workingDays: working_day.length === 0 ? [] : working_day,
                            holidays: holiday_list
                        })
                    }
                    promise.then((status) => {
                        setIsLoading(false)
                        if (status === 200) {
                            isEdit ? message.success('The Update has finished successfully.') : message.success('You have Successfully save the data.')
                        } else {
                            isEdit ? message.success('The Update has finished successfully.') : message.success('You have Successfully save the data.')
                        }
                        setIsRedirect(true)
                    })
                        .catch((err) => {
                            setIsLoading(false)
                            message.error(`You have unSuccessfully save the data. ${err}`)
                        })
                }
            }
        })
    }

    // set mon
    const set_timeStart_mon = (time: any, timeString: string) => {
        set_time_picker_start_mon(timeString)
    }
    const set_timeEnd_mon = (time: any, timeString: string) => {
        set_time_picker_end_mon(timeString)
    }

    // set tuesday
    const set_timeStart_tues = (time: any, timeString: string) => {
        set_time_picker_start_tues(timeString)
    }
    const set_timeEnd_tues = (time: any, timeString: string) => {
        set_time_picker_end_tues(timeString)
    }

    // set wed
    const set_timeStart_wed = (time: any, timeString: string) => {
        set_time_picker_start_wed(timeString)
    }
    const set_timeEnd_wed = (time: any, timeString: string) => {
        set_time_picker_end_wed(timeString)
    }

    // set thu
    const set_timeStart_thu = (time: any, timeString: string) => {
        set_time_picker_start_thu(timeString)
    }
    const set_timeEnd_thu = (time: any, timeString: string) => {
        set_time_picker_end_thu(timeString)
    }

    // set fri
    const set_timeStart_fri = (time: any, timeString: string) => {
        set_time_picker_start_fri(timeString)
    }
    const set_timeEnd_fri = (time: any, timeString: string) => {
        set_time_picker_end_fri(timeString)
    }

    // set sat
    const set_timeStart_sat = (time: any, timeString: string) => {
        set_time_picker_start_sat(timeString)
    }
    const set_timeEnd_sat = (time: any, timeString: string) => {
        set_time_picker_end_sat(timeString)
    }

    // set sun
    const set_timeStart_sun = (time: any, timeString: string) => {
        set_time_picker_start_sun(timeString)
    }
    const set_timeEnd_sun = (time: any, timeString: string) => {
        set_time_picker_end_sun(timeString)
    }

    // set break
    const set_timeStart_break = (time: any, timeString: string) => {
        console.log(time)
        set_time_picker_start_break(timeString)
    }
    const set_timeEnd_break = (time: any, timeString: string) => {
        console.log(time)
        set_time_picker_end_break(timeString)
    }

    const warningMassage = (time: any, key: any) => {
        if (Number(time) <= 0) {
            try {
                Modal.warning({
                    title: 'Warning!!',
                    content: 'Time Stop must be greater than Time Start.'
                })
                if (key === 'mon') {
                    form.setFieldsValue({
                        time_picker_start_mon: moment('08:00', format),
                        time_picker_end_mon: moment('17:00', format)
                    })
                    set_time_picker_start_mon('08:00')
                    set_time_picker_end_mon('17:00')
                } else if (key === 'tues') {
                    form.setFieldsValue({
                        time_picker_start_tues: moment('08:00', format),
                        time_picker_end_tues: moment('17:00', format)
                    })
                    set_time_picker_start_tues('08:00')
                    set_time_picker_end_tues('17:00')
                } else if (key === 'wed') {
                    form.setFieldsValue({
                        time_picker_start_wed: moment('08:00', format),
                        time_picker_end_wed: moment('17:00', format)
                    })
                    set_time_picker_start_wed('08:00')
                    set_time_picker_end_wed('17:00')
                } else if (key === 'thu') {
                    form.setFieldsValue({
                        time_picker_start_thu: moment('08:00', format),
                        time_picker_end_thu: moment('17:00', format)
                    })
                    set_time_picker_start_thu('08:00')
                    set_time_picker_end_thu('17:00')
                } else if (key === 'fri') {
                    form.setFieldsValue({
                        time_picker_start_fri: moment('08:00', format),
                        time_picker_end_fri: moment('17:00', format)
                    })
                    set_time_picker_start_fri('08:00')
                    set_time_picker_end_fri('17:00')
                } else if (key === 'sat') {
                    form.setFieldsValue({
                        time_picker_start_sat: moment('08:00', format),
                        time_picker_end_sat: moment('17:00', format)
                    })
                    set_time_picker_start_sat('08:00')
                    set_time_picker_end_sat('17:00')
                } else if (key === 'sun') {
                    form.setFieldsValue({
                        time_picker_start_sun: moment('08:00', format),
                        time_picker_end_sun: moment('17:00', format)
                    })
                    set_time_picker_start_sun('08:00')
                    set_time_picker_end_sun('17:00')
                } else if (key === 'break') {
                    form.setFieldsValue({
                        time_picker_start_break: moment('12:00', format),
                        time_picker_end_break: moment('13:00', format)
                    })
                    set_time_picker_start_break('12:00')
                    set_time_picker_end_break('13:00')
                }
            } catch (error: any) {
                console.log(error)
            }
        }
    }

    const getHolidayDate = (date: any, dateString: string) => {
        setInputDateString(dateString)
    }

    const handleInputConfirm = () => {
        let tempTags: any
        props.form.validateFields(['datePickerHoliday'], (err) => {
            if (!err) {
                const { getFieldValue, setFieldsValue } = props.form
                const dataInput: string = getFieldValue('HolidayNameInput')
                const dateTimeInput = getFieldValue('datePickerHoliday')
                const checkIs7x24 = getFieldValue('Radio7x24')

                if (!checkIs7x24) {
                    if (dateTimeInput && dataInput) {
                        if (dataInput && tags.indexOf(dataInput) === -1 && dataInput.length >= 3) {
                            tempTags = [...tags, inputDateString + spaceBetween + dataInput]
                        }
                        setMyTags(tempTags)
                    } else {
                        message.warning('Please enter holiday name or calendar')
                    }
                }

                setFieldsValue({
                    HolidayNameInput: undefined,
                    datePickerHoliday: undefined
                })
            }
        })
    }

    const validateRepeatHolidayDay = (_: any, value: any, callback) => {
        const { getFieldValue } = props.form
        let dateHoliday = getFieldValue('datePickerHoliday')
        const isFullTime = getFieldValue('Radio7x24')
        let isRepeatDate = false
        dateHoliday = moment(dateHoliday).format('YYYY-MM-DD')
        if (!isFullTime) {
            if (getFieldValue('datePickerHoliday')) {
                tags.forEach((res: any) => {
                    if (dateHoliday === res.substring(0, 10)) {
                        isRepeatDate = true
                    }
                })
            }
        }

        if (isRepeatDate) {
            callback('Holiday date is already exists. It must be unique. Please try another one.')
        } else {
            callback()
        }
    }

    const forMap = (tag: any) => {
        const tagElem = (
            <div key={tag.length}>
                <Tag className={isView ? 'disable-showHoliday' : 'showHoliday'}>
                    <Icon type="close" style={{ color: '#323276', marginRight: '5px' }} onClick={e => {
                        e.preventDefault()
                        handleClose(tag)
                    }} />
                    <span className="content">{tag.length > 190 ? (<>{tag.substring(0, 190) + '...'}</>) : tag}</span>
                </Tag>
                <br />
            </div>
        )
        return (
            <span key={tag} style={{ display: 'block' }}>
                {tagElem}
                <br />
            </span>
        )
    }

    const handlesOnChangeServiceHour = () => {
        if (check_name.length < 1) {
            check_name.push('Monday')
            check_break.push('Break')
        }
    }

    const handleClose = (removedTag: any) => {
        let tempTags: any
        if (!isView) {
            tempTags = tags.filter((tag: any) => tag !== removedTag)
            setMyTags(tempTags)
        }
    }

    const focusCloneBusiness = () => {
        props.getAllBusinessHour().catch((err) => message.error(`Failed getting all business hours. ${err}`))
    }

    const { getFieldDecorator, getFieldValue } = props.form
    const tagChild = tags?.map(forMap)

    const changeWorkingDay = (value: CheckboxValueType[]) => {
        setCheck_name(value)
    }

    const calTimeWorkingDay = (start: string, stop: string, key: string) => {
        return (<>
            {start === '' && stop === ''
                ? (<div><p style={{ paddingTop: 7 }}>09 hours 00 minutes</p></div>) : null}
            {start === '' || stop === ''
                ? null : (<div><p style={{ paddingTop: 7 }}>{Math.floor((moment(stop, `${format} A`).diff(moment(start, `${format} A`))) / 3600000)} hours {((moment(stop, `${format} A`).diff(moment(start, `${format} A`))) % 3600000) / 60000} minutes</p>
                    {warningMassage(Math.floor((moment(stop, format).diff(moment(start, format)))), key)}
                </div>)}

            {start !== '' && stop === ''
                ? (<div><p style={{ paddingTop: 7 }}>{Math.floor((moment('17:00', `${format} A`).valueOf() - moment(start, `${format} A`).valueOf()) / 3600000)} hours {((moment('17:00', `${format} A`).diff(moment(start, `${format} A`))) % 3600000) / 60000} minutes</p>
                    {warningMassage(Math.floor((moment('17:00', format).diff(moment(start, format)))), key)}
                </div>)
                : null}

            {start === '' && stop !== ''
                ? (<div><p style={{ paddingTop: 7 }}>{Math.floor((moment(stop, `${format} A`).valueOf() - moment('08:00', `${format} A`).valueOf()) / 3600000)} hours {((moment(stop, `${format} A`).diff(moment('08:00', `${format} A`))) % 3600000) / 60000} minutes</p>
                    {warningMassage(Math.floor((moment(stop, format).diff(moment('08:00', format)))), key)}
                </div>)
                : null}
        </>)
    }

    const modalConfirmCancel = (): void => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'Yes',
            okType: 'danger',
            autoFocusButton: 'cancel',
            cancelText: 'No',
            onOk() {
                setIsRedirect(true)
            }
        })
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    // eslint-disable-next-line array-callback-return
                    const businessHourList = props.businessHours.map((value: any) => {
                        if (!isEdit || value.id !== Number(id)) {
                            return value.name?.replace(/ +/g, ' ').trim().toLowerCase()
                        }
                    })
                    if (isEdit && value.trim() === defaultBusinessHourName) {
                        callback()
                    } else if (businessHourList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                        callback('The Business hour name is already exists. It must be unique. Please try another one.')
                    } else {
                        callback()
                    }
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                // eslint-disable-next-line
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('Enter more than 3 characters')
                }
            }
        } else {
            callback()
        }
    }

    const validateDescription = (_: any, value: any, callback) => {
        if (value) {
            const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
            const pattern = /^[A-Za-z0-9ก-๙]{1}/
            if (pattern.test(value.replace(/ +/g, '').trim())) {
                if (value.replace(/ +/g, '').trim().length >= 3) {
                    callback()
                } else {
                    callback('Enter more than 3 characters')
                }
            } else {
                if (format.test(value.replace(/ +/g, '').trim())) {
                    callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
                } else {
                    callback('description cannot be empty Enter more than 3 letters')
                }
            }
        } else {
            callback()
        }
    }

    const renderTopicViewOrEdit = (Type?: string) => {
        if (isEdit) {
            if (Type === 'Topic') {
                return <h1 className={'main-title'}>Edit Business Hours</h1>
            } else {
                return <Breadcrumb.Item>Edit Business Hours</Breadcrumb.Item>
            }
        } else {
            if (Type === 'Topic') {
                return <h1 className={'main-title'}>View Business Hours</h1>
            } else {
                return <Breadcrumb.Item>View Business Hours</Breadcrumb.Item>
            }
        }
    }

    return (
        <div>
            {isRedirect ? (<Redirect to="/BusinessHourSettingList" />) : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={'/BusinessHourSettingList'} >Business Hours</Link>
                </Breadcrumb.Item>
                {isCreate || isClone
                    ? (
                        <Breadcrumb.Item>New Business Hours</Breadcrumb.Item>
                    ) : (
                        renderTopicViewOrEdit()
                    )}
            </Breadcrumb>
            <br />
            <Row>
                <Col span={24}>
                    <Card>
                        <Row>
                            <Col span={23}>
                                {isCreate || isClone
                                    ? (
                                        <h1 className={'main-title'}>New Business Hours</h1>
                                    ) : (
                                        renderTopicViewOrEdit('Topic')
                                    )}
                            </Col>
                            {isView ? (<Col span={1} >
                                <span style={{ float: 'right', marginRight: '2%' }}>
                                    <Tooltip placement="bottom" title={'Edit'} ><Link to={`/BusinessHourSettingFormEdit/${id}`} ><Icon className="edit_icon" type="edit" /></Link></Tooltip>
                                </span>
                            </Col>
                            ) : null}
                        </Row>
                        <Form onSubmit={handleSubmit} style={{ padding: '1%' }} layout="vertical">
                            <Row gutter={16}>
                                <Col span={10} lg={10} md={10} sm={24} xs={24}>
                                    <Form.Item label={'Name'}>
                                        {getFieldDecorator('name', {
                                            initialValue: '',
                                            rules: [{ required: true, message: 'Business Hours Name is required' },
                                                { max: 80, message: 'message max 250 characters' },
                                                { validator: validateNameUniqueness }
                                            ]
                                        })(<Input maxLength={80} disabled={isView} placeholder={'Name'} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={14} lg={14} md={14} sm={24} xs={24}>
                                    <Form.Item label={'Description'}>
                                        {getFieldDecorator('Description', {
                                            rules: [{ validator: validateDescription }]
                                        })(<Input disabled={isView} placeholder={'Description'} maxLength={120} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <div style={{ padding: 10 }}>
                                    <h1 className={'sub-title'}><span className='requiredIcon'>*</span> Service Hours</h1>
                                    <Form.Item>
                                        {getFieldDecorator('Radio7x24', { initialValue: true })(<Radio.Group disabled={isView} onChange={() => handlesOnChangeServiceHour()}>
                                            <Radio value={true}>
                                                7 days X 24 hours
                                            </Radio><br />
                                            <Radio value={false}>
                                                Select working days/hours
                                            </Radio>
                                        </Radio.Group>)}
                                    </Form.Item>

                                    {getFieldValue('Radio7x24') ? null : (<>
                                        <Row style={{ paddingLeft: 40 }}>
                                            <Col span={2} sm={4} xs={4} md={4} lg={2}>
                                                <Form.Item >
                                                    {getFieldDecorator('checkbox_group', { initialValue: isCreate ? ['Monday'] : check_name })(
                                                        <Checkbox.Group style={{ width: '100%' }} disabled={isView} onChange={(e) => changeWorkingDay(e)}>
                                                            <Row>
                                                                <Col span={24} style={{ marginBottom: 22, marginTop: 8 }}>
                                                                    <Checkbox value="Monday" style={{ color: '#76A998' }} >Monday</Checkbox>
                                                                </Col>
                                                                <Col span={24} style={{ marginBottom: 28 }}>
                                                                    <Checkbox value="Tuesday" style={{ color: '#76A998' }} >Tuesday</Checkbox>
                                                                </Col>
                                                                <Col span={24} style={{ marginBottom: 25 }}>
                                                                    <Checkbox value="Wednesday" style={{ color: '#76A998' }} >Wednesday</Checkbox>
                                                                </Col>
                                                                <Col span={24} style={{ marginBottom: 28 }}>
                                                                    <Checkbox value="Thursday" style={{ color: '#76A998' }} >Thursday</Checkbox>
                                                                </Col>
                                                                <Col span={24} style={{ marginBottom: 23 }}>
                                                                    <Checkbox value="Friday" style={{ color: '#76A998' }} >Friday</Checkbox>
                                                                </Col>
                                                                <Col span={24} style={{ marginBottom: 23 }}>
                                                                    <Checkbox value="Saturday" style={{ color: '#76A998' }} >Saturday</Checkbox>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Checkbox value="Sunday" style={{ color: '#76A998' }} >Sunday</Checkbox>
                                                                </Col>
                                                            </Row>
                                                        </Checkbox.Group>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={20} lg={20} md={20} sm={24} xs={24}>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {
                                                                getFieldDecorator('time_picker_start_mon', { initialValue: moment(time_picker_start_mon, format), rules: [{ required: true, message: ' ' }] })(
                                                                    <TimePicker
                                                                        onChange={set_timeStart_mon}
                                                                        disabled={!(getFieldValue('checkbox_group') || []).includes('Monday') || isView}
                                                                        className={!(getFieldValue('checkbox_group') || []).includes('Monday') || isView ? '' : 'enable-icon'}
                                                                        format={format}
                                                                    />
                                                                )
                                                            }
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={2} md={2} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {
                                                                getFieldDecorator('time_picker_end_mon', { initialValue: moment(time_picker_end_mon, format), rules: [{ required: true, message: ' ' }] })(
                                                                    <TimePicker onChange={set_timeEnd_mon} className={!(getFieldValue('checkbox_group') || []).includes('Monday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Monday') || isView} format={format} />
                                                                )
                                                            }
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_mon, time_picker_end_mon, 'mon')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {
                                                                getFieldDecorator('time_picker_start_tues', { initialValue: moment(time_picker_start_tues, format), rules: [{ required: true, message: ' ' }] })(
                                                                    <TimePicker onChange={set_timeStart_tues} className={!(getFieldValue('checkbox_group') || []).includes('Tuesday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Tuesday') || isView} format={format} />
                                                                )
                                                            }
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={1} md={1} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_end_tues', { initialValue: moment(time_picker_end_tues, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeEnd_tues} className={!(getFieldValue('checkbox_group') || []).includes('Tuesday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Tuesday') || isView} format={format} />
                                                            )
                                                            }
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_tues, time_picker_end_tues, 'tues')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_start_wed', { initialValue: moment(time_picker_start_wed, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeStart_wed} className={!(getFieldValue('checkbox_group') || []).includes('Wednesday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Wednesday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={1} md={1} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_end_wed', { initialValue: moment(time_picker_end_wed, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeEnd_wed} className={!(getFieldValue('checkbox_group') || []).includes('Wednesday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Wednesday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_wed, time_picker_end_wed, 'wed')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_start_thu', { initialValue: moment(time_picker_start_thu, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeStart_thu} className={!(getFieldValue('checkbox_group') || []).includes('Thursday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Thursday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={1} md={1} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_end_thu', { initialValue: moment(time_picker_end_thu, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeEnd_thu} className={!(getFieldValue('checkbox_group') || []).includes('Thursday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Thursday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_thu, time_picker_end_thu, 'thu')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_start_fri', { initialValue: moment(time_picker_start_fri, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeStart_fri} className={!(getFieldValue('checkbox_group') || []).includes('Thursday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Friday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={1} md={1} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_end_fri', { initialValue: moment(time_picker_end_fri, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeEnd_fri} className={!(getFieldValue('checkbox_group') || []).includes('Friday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Friday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_fri, time_picker_end_fri, 'fri')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_start_sat', { initialValue: moment(time_picker_start_sat, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeStart_sat} className={!(getFieldValue('checkbox_group') || []).includes('Saturday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Saturday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={1} md={1} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_end_sat', { initialValue: moment(time_picker_end_sat, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeEnd_sat} className={!(getFieldValue('checkbox_group') || []).includes('Saturday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Saturday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_sat, time_picker_end_sat, 'sat')}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_start_sun', { initialValue: moment(time_picker_start_sun, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeStart_sun} className={!(getFieldValue('checkbox_group') || []).includes('Sunday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Sunday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={1} md={1} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_end_sun', { initialValue: moment(time_picker_end_sun, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeEnd_sun} className={!(getFieldValue('checkbox_group') || []).includes('Sunday') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_group') || []).includes('Sunday') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_sun, time_picker_end_sun, 'sun')}
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col span={23}><hr style={{ border: '0.5px solid #323276', width: '510px', marginLeft: '0px' }} /></Col>
                                            <Col span={2} style={{ marginTop: '13px' }} sm={4} xs={4} md={4} lg={2}>
                                                <Form.Item >
                                                    {getFieldDecorator('checkbox_break', { initialValue: isCreate ? ['Break'] : check_break })(
                                                        <Checkbox.Group style={{ width: '100%' }}>
                                                            <Checkbox value="Break" disabled={isView}>Break</Checkbox>
                                                        </Checkbox.Group>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={20} style={{ marginTop: '13px' }} lg={20} md={20} sm={24} xs={24}>
                                                <Row>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_start_break', { initialValue: moment(time_picker_start_break, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeStart_break} className={!(getFieldValue('checkbox_break') || []).includes('Break') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_break') || []).includes('Break') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1} style={{ marginLeft: 10, top: 5, width: 20 }} lg={1} md={1} sm={24} xs={24}>to</Col>
                                                    <Col span={3} lg={3} md={6} sm={6} xs={6}>
                                                        <Form.Item>
                                                            {getFieldDecorator('time_picker_end_break', { initialValue: moment(time_picker_end_break, format), rules: [{ required: true, message: ' ' }] })(
                                                                <TimePicker onChange={set_timeEnd_break} className={!(getFieldValue('checkbox_break') || []).includes('Break') || isView ? '' : 'enable-icon'} disabled={!(getFieldValue('checkbox_break') || []).includes('Break') || isView} format={format} />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={4} style={{ marginLeft: 10 }} lg={8} md={8} sm={24} xs={24}>
                                                        {calTimeWorkingDay(time_picker_start_break, businessHourTemp?.breakTimeEnd || time_picker_end_break, 'break')}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </>)}
                                </div>
                            </Row>

                            <Row>
                                <Col span={3} style={{ paddingTop: 5 }} lg={3} md={3} sm={24} xs={24}>
                                    <h1 className={'sub-title'}>Yearly Holiday List</h1>
                                </Col>
                                <Col span={9} style={{ paddingTop: 5 }} lg={9} md={9} sm={24} xs={24}>
                                    <span style={{ color: '#323276', marginTop: 5 }}>Holidays will be ignored when calculating SLA for a ticket (Exclude 7 days x 24hrs) </span>
                                </Col>
                            </Row>

                            <Row style={{ paddingTop: 10 }} gutter={16}>
                                <Col span={3} lg={3} md={3} sm={24} xs={24}>
                                    <Form.Item label='Calendar'>
                                        {getFieldDecorator('datePickerHoliday', { rules: [{ validator: validateRepeatHolidayDay }] })(
                                            <DatePicker onChange={getHolidayDate} disabled={isView || getFieldValue('Radio7x24')} className={isView ? '' : 'enable-icon'} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={18} lg={18} md={18} sm={24} xs={24}>
                                    <Form.Item label='Holiday Name '>
                                        {getFieldDecorator('HolidayNameInput', {
                                            rules: [{ required: false, message: 'Required!' }, { min: 3, message: 'Enter more than 3 characters' },
                                                { max: 250, message: 'message max 250 characters' }]
                                        })(<Input
                                            type="text"
                                            maxLength={250}
                                            disabled={isView || getFieldValue('Radio7x24')}
                                            placeholder={'Holiday Name'}
                                        />)}
                                    </Form.Item>
                                </Col>
                                <Col span={3} style={{ marginTop: 20 }} lg={3} md={3} sm={24} xs={24}>
                                    <Form.Item label=' '>
                                        <Button className="addCriteriaButton" onClick={() => handleInputConfirm()} htmlType="button" disabled={isView || getFieldValue('Radio7x24')} style={{ width: '100%' }}>Add Holiday</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div>{getFieldValue('Radio7x24') ? null : tagChild}</div>
                            <div style={{ float: 'right' }}>
                                <Form.Item>
                                    <Button type="default" htmlType="button" style={{ marginRight: 10 }} onClick={() => modalConfirmCancel()}>
                                        Cancel
                                    </Button>
                                    <Button type="primary" htmlType="submit" disabled={isView} loading={isLoading}>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const wrappedByForm = Form.create({ name: 'businessHour_Page' })(BusinessHoursForm)

export default connect(
    mapStateToProps,
    { getAllBusinessHour, addBusinessHour, updateBusinessHour }
)(wrappedByForm)
