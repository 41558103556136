/* eslint-disable react/jsx-key */
import { Button, Form, Input, Row } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import React, { useEffect, useState } from 'react'

interface Param {
    isOpen: boolean
    IsChangeNumberOfSign: boolean
    numberOfSign?: number
    closeModal: Function
    getDataPosition: Function
    positionName?: string
    isPreviewWorkflow?: boolean
}

type Props = Param & FormComponentProps

const PositionSignatureForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue } = props.form
    const [numberRender, setNumberRender] = useState<number[]>([])
    const arrNumberRender: number[] = []
    const [valueInput, setValueInput] = useState<string[]>([])

    useEffect(() => {
        if (props.isOpen && props.IsChangeNumberOfSign) {
            if (props.numberOfSign === 3) {
                // resetFields(['position1', 'position2', 'position3'])
                setFieldsValue({
                    position1: undefined,
                    position2: undefined,
                    position3: undefined
                })
            } else if (props.numberOfSign === 2) {
                // resetFields(['position1', 'position2'])
                setFieldsValue({
                    position1: undefined,
                    position2: undefined
                })
            } else if (props.numberOfSign === 1) {
                // resetFields(['position1'])
                setFieldsValue({
                    position1: undefined
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen, props.IsChangeNumberOfSign])

    useEffect(() => {
        if (props.numberOfSign !== 0) {
            for (let index = 0; index < props.numberOfSign!!; index++) {
                arrNumberRender.push(index + 1)
            }
            setNumberRender(arrNumberRender)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.numberOfSign])

    useEffect(() => {
        if (props.positionName) {
            const spiltValue = props.positionName.split(',')
            setValueInput(spiltValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.positionName])

    // const handleCloseModal = () => {
    //     props.closeModal(false)
    // }

    const handleSubmit = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                const inputData = numberRender.map((data, index) => values[`position${index + 1}`])
                const joinedData = inputData.join(',')

                setValueInput(inputData)
                props.getDataPosition(joinedData)
                props.closeModal(false)
            }
        })
    }

    return (
        <>
            {numberRender.map((data, index) => (
                <Form.Item label={`Job Position ${index + 1} : `}>
                    {getFieldDecorator('position' + (index + 1), {
                        initialValue: valueInput ? valueInput[index] : undefined
                    })(
                        <Input placeholder="Job Position" />
                    )}
                </Form.Item>
            ))}
            <Row style={{ textAlign: 'end' }}>
                {/* <Button onClick={handleCloseModal}>Cancel</Button> */}
                <Button type="primary" htmlType="submit" style={{ marginLeft: 15, marginTop: 5 }} onClick={handleSubmit} disabled={props.isPreviewWorkflow}>Submit</Button>
            </Row>
        </>
    )
}
const PositionSignatureFormModal = Form.create<Props>()(PositionSignatureForm)
export default PositionSignatureFormModal
