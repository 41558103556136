import { Breadcrumb, Button, Checkbox, Empty, Modal, Spin, message } from 'antd'

import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import { IncidentViewState, allVisibleViews } from '../incident-view/state-model'
import { RoleType, checkRolesState } from '../../authorization-module/permissions'
import { DeleteIncidentView } from '../incident-view'

const mapStateToProps = (state: StoreState) => {
    return {
        incidentViewState: state.incidentViewState
    }
}

interface Param {
    DeleteIncidentView: (incidentViewIds: string) => Promise<number>
    isVisibleDeleteFilter: boolean
    setIsVisibleDeleteFilter: Function
}

interface StateProps {
    incidentViewState: IncidentViewState
}

type Props = Param & StateProps

const DeleteFilterIncident = (props: Props) => {
    const [visibleMyGroupViewList, setVisibleMyGroupViewList] = useState<allVisibleViews[]>([])
    const [visibleMySelfViewList, setVisibleMySelfViewList] = useState<allVisibleViews[]>([])
    const [selectedViewDeleteMygroup, setSelectedViewDeleteMygroup] = useState<string[]>([])
    const [selectedViewDeleteMyself, setSelectedViewDeleteMyself] = useState<string[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!props.isVisibleDeleteFilter) {
            setSelectedViewDeleteMygroup([])
            setSelectedViewDeleteMyself([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isVisibleDeleteFilter])

    useEffect(() => {
        setVisibleMyGroupViewList(props.incidentViewState.allVisibleViews?.filter((it) => it.visibility === 'My Group') || [])
        setVisibleMySelfViewList(props.incidentViewState.allVisibleViews?.filter((it) => it.visibility === 'Myself') || [])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incidentViewState])

    const handleSelectMygroup = (value) => {
        setSelectedViewDeleteMygroup(value)
    }

    const handleSelectMyself = (value) => {
        setSelectedViewDeleteMyself(value)
    }

    const selectAllMygroup = (value) => {
        console.log(value.target.checked)
        if (value.target.checked) {
            const tempId = visibleMyGroupViewList.filter((it) => it.id !== props.incidentViewState.currentView?.id).map((it) => { return it.id.toString() })
            setSelectedViewDeleteMygroup(tempId)
        } else {
            setSelectedViewDeleteMygroup([])
        }
    }

    const selectAllMyself = (value) => {
        if (value.target.checked) {
            const tempId = visibleMySelfViewList.filter((it) => it.id !== props.incidentViewState.currentView?.id).map((it) => { return it.id.toString() })
            setSelectedViewDeleteMyself(tempId)
        } else {
            setSelectedViewDeleteMyself([])
        }
    }

    const handleDeleteData = () => {
        Modal.confirm({
            title: 'Are you sure?',
            content: 'Do you confirm to delete data.',
            onOk: () => {
                const deleteDataId = selectedViewDeleteMygroup.concat(selectedViewDeleteMyself).toString()
                setIsLoading(true)
                props.DeleteIncidentView(deleteDataId).then((res) => {
                    message.success('Delete data successfully.')
                }).finally(() => {
                    setIsLoading(false)
                    setSelectedViewDeleteMygroup([])
                    setSelectedViewDeleteMyself([])
                })
            },
            onCancel: () => { }
        })
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                <Breadcrumb className={'breadcrumb-delete-filter'}>
                    <Breadcrumb.Item onClick={() => props.setIsVisibleDeleteFilter(false)}>
                        Advance search
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        Delete view
                    </Breadcrumb.Item>
                </Breadcrumb>
                <br />
                {checkRolesState(RoleType.Admin, 'ManageTicketSetting') ? <><div className='delete-filter-box'>
                    <p className='delete-filter-header'> My groups</p>
                    <div style={{ height: 220, overflowY: 'auto' }}>
                        {visibleMyGroupViewList.length > 0 ? <>
                            <div className='checkbox-choice-box' style={{ backgroundColor: '#D0D5DD' }}>
                                <Checkbox
                                    indeterminate={selectedViewDeleteMygroup.length > 0 && selectedViewDeleteMygroup.length !== visibleMyGroupViewList.filter((it) => it.id !== props.incidentViewState.currentView?.id).length}
                                    checked={selectedViewDeleteMygroup.length > 0 && selectedViewDeleteMygroup.length === visibleMyGroupViewList.filter((it) => it.id !== props.incidentViewState.currentView?.id).length}
                                    onChange={selectAllMygroup}
                                >
                                    <span style={{ color: '#2F80ED' }}> Select all</span>
                                </Checkbox>
                            </div>
                            <Checkbox.Group style={{ width: '100%' }}
                                onChange={handleSelectMygroup}
                                value={selectedViewDeleteMygroup}
                            >
                                {visibleMyGroupViewList.map((it, index) => {
                                    return <div className='checkbox-choice-box' key={index}><Checkbox disabled={it.id === props.incidentViewState.currentView?.id} value={it.id.toString()} key={it.id}>{it.visibleName}</Checkbox>
                                    </div>
                                })}
                            </Checkbox.Group>

                        </> : <Empty style={{ marginTop: 30 }} />}
                    </div>
                </div>
                <br /></> : null}

                <div className='delete-filter-box'>
                    <p className='delete-filter-header'> My self</p>
                    <div style={{ height: 220, overflowY: 'auto' }}>
                        {visibleMySelfViewList.length > 0 ? <>
                            <div className='checkbox-choice-box' style={{ backgroundColor: '#D0D5DD' }}>
                                <Checkbox
                                    indeterminate={selectedViewDeleteMyself.length > 0 && selectedViewDeleteMyself.length !== visibleMySelfViewList.filter((it) => it.id !== props.incidentViewState.currentView?.id).length}
                                    checked={selectedViewDeleteMyself.length > 0 && selectedViewDeleteMyself.length === visibleMySelfViewList.filter((it) => it.id !== props.incidentViewState.currentView?.id).length}
                                    onChange={selectAllMyself}
                                >
                                    <span style={{ color: '#2F80ED' }}> Select all</span>
                                </Checkbox>
                            </div>
                            <Checkbox.Group style={{ width: '100%' }}
                                onChange={handleSelectMyself}
                                value={selectedViewDeleteMyself}
                            >
                                {visibleMySelfViewList.map((it, index) => {
                                    return <div className='checkbox-choice-box' key={index}><Checkbox disabled={it.id === props.incidentViewState.currentView?.id} value={it.id.toString()} key={it.id}>{it.visibleName}</Checkbox>
                                    </div>
                                })}
                            </Checkbox.Group>

                        </> : <Empty style={{ marginTop: 30 }} />}
                    </div>
                </div>
                <br />
                <div style={{ float: 'right' }}>
                    <Button disabled={selectedViewDeleteMygroup.length + selectedViewDeleteMyself.length < 1} type='primary' onClick={handleDeleteData}>Delete</Button>
                </div>
            </Spin>
        </div>
    )
}

export default connect(mapStateToProps, { DeleteIncidentView })(DeleteFilterIncident)
