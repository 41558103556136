import { Row, Col, Modal, Upload, Avatar, Button, Icon, message, Select, Tooltip } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import React, { useEffect, useState } from 'react'
import exportIcon from '../../common-file/icon-file'
import { DownloadAnyFile } from '../../common-misc'
import { resend } from '../user-role/duck/service'
import { People } from './model'
import { reset2faByIDUser } from '../login/service'

interface Params {
    data: People
    setVisible: Function
}

type Props = Params
// const { Option } = Select
const ViewPeople: React.FC<Props> = (props: Props) => {
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    // const [pictureAvatar, setPictureAvatar] = useState<string>()

    useEffect(() => {
        if (props.data.pictureProfile) {
            const convertData = JSON.parse(props.data.pictureProfile)
            const uploadFile = convertData?.map((file) => {
                const uploadFile: UploadFile = {
                    uid: file.name,
                    name: file.name,
                    status: 'done',
                    url: file.url,
                    type: file.type,
                    size: 0
                }
                return uploadFile
            })
            setFileList(uploadFile || [])
        } else {
            setFileList([])
        }
    }, [props.data.pictureProfile])

    const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

    const uploadProps = {
        multiple: false,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const fileTypes = ['jpg', 'jpeg', 'png']
            const extension = file.name.split('.').pop().toLowerCase()
            const isSuccess = fileTypes.indexOf(extension) < 0
            if (isSuccess) {
                message.error('Upload only image')
                return false
            }

            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                if (fillOffFileList.length > 1) {
                    fillOffFileList.shift()
                    setFileList(fillOffFileList)
                } else {
                    setFileList(fillOffFileList)
                }
            }
            return false
        },
        showUploadList: { showDownloadIcon: false, showRemoveIcon: false }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const handleResent = () => {
        resend(props.data?.email || '').then((res) => {
            message.success('Account Verification Link has been send.')
        }).catch(() => {
            message.error('Fail to resend mail, Please try again.')
        })
    }

    const handleResent2fa = () => {
        reset2faByIDUser(props.data?.id).then((res) => {
            message.success('Reset 2FA Successfully.')
        }).catch(() => {
            message.error('Fail to reset, Please try again.')
        })
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={5} style={{ textAlign: 'center', paddingTop: 20, maxWidth: 'min-content', marginLeft: 0 }}>
                <Avatar shape="square" size={170} icon="user" src={fileList?.length > 0 ? fileList[0]?.url : undefined} />
                <Upload {...uploadProps} onPreview={(e: any) => handlePreview(e)} >
                    <Button style={{ marginTop: 10 }} disabled={true}><Icon type="upload" />Upload New User</Button>
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={(e: any) => setPreviewVisible(false)} >
                    <img alt={previewImage} style={{ width: '170' }} src={previewImage} />
                </Modal>
            </Col>
            <Col span={19} style={{ paddingTop: 20 }}>
                <Row style={{ marginLeft: '2%', marginBottom: '3%' }}>
                    <Row style={{ marginBottom: '2%' }}> <h3 style={{ color: '#323276', fontWeight: 600 }}>Personal information</h3></Row>
                    <Row style={{ marginBottom: '3%' }}>
                        <Col span={5} lg={5} >
                            <div style={{ fontWeight: 600 }}>First Name : </div>
                        </Col>
                        <Col span={5} lg={5} >
                            <div>{props.data.firstName}</div>
                        </Col>
                        <Col span={5} lg={5} >
                            <div style={{ fontWeight: 600 }}>Last Name : </div>
                        </Col>
                        <Col span={9} lg={9} >
                            <div>{props.data.lastName}</div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '3%' }}>
                        <Col span={5} lg={5} >
                            <div style={{ fontWeight: 600 }}>Phone Number :  </div>
                        </Col>
                        <Col span={5} lg={5} >
                            <div>{props.data.tel}</div>
                        </Col>
                        <Col span={5} lg={5} >
                            <div style={{ fontWeight: 600 }}>Email : </div>
                        </Col>
                        <Col span={9} lg={9} >
                            <div>{props.data.email}</div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '3%' }}>
                        <Col span={5} lg={5} >
                            <div style={{ fontWeight: 600 }}>Employee ID : </div>
                        </Col>
                        <Col span={5} lg={5} >
                            <div>{props.data.employeeId}</div>
                        </Col>
                        <Col span={5} lg={5} >
                            <div style={{ fontWeight: 600 }}>Department : </div>
                        </Col>
                        <Col span={9} lg={9} >
                            <div>{props.data?.department?.departmentName}</div>
                        </Col>
                    </Row>
                </Row>

                <Row style={{ marginLeft: '2%', marginBottom: '3%' }}>
                    <Row> <h3 style={{ color: '#323276', fontWeight: 600 }}>Company</h3></Row>
                    <Row>
                        <Col span={5} lg={5} style={{ marginBottom: '3%' }}>
                            <div style={{ fontWeight: 600 }}>Name : </div>
                        </Col>
                        <Col span={19} lg={19} style={{ marginBottom: '3%' }}>
                            <div>{props.data?.company?.companyName}</div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '3%' }}>
                        <Col span={5} lg={5} >
                            <div style={{ fontWeight: 600 }}>Address : </div>
                        </Col>
                        <Col span={19} lg={19} >
                            <div>{props.data?.company?.companyAddress}</div>
                        </Col>
                    </Row>
                </Row>

                <Row style={{ marginLeft: '2%', marginBottom: '3%' }}>
                    <Row> <h3 style={{ color: '#323276', fontWeight: 600 }}>Setting Roles</h3></Row>
                    <Row> <h4 style={{ color: '#323276', fontWeight: 600 }}>Roles</h4></Row>
                    <Row style={{ marginBottom: '1%' }}>
                        <Col span={24} lg={24} >
                            <Select
                                mode="multiple"
                                placeholder={'Role'}
                                disabled={true}
                                style={{ width: '100%' }}
                                defaultValue={(props.data?.roles || []).map((res) => { return res.name })}
                            >
                            </Select>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <Col span={12}><Row style={{ marginTop: 30, marginLeft: '2%' }}>
                        <Tooltip placement="top" title={props.data?.email || ''}>
                            <Button disabled={props.data?.confirmed} onClick={() => handleResent()}>Resend</Button>
                        </Tooltip>
                    </Row>
                    <Row style={{ marginTop: '2%', marginLeft: '2%' }}>
                        <span>Please click &quot;Resend&quot; to resend account verification link</span>
                    </Row></Col>
                    <Col span={12}><Row style={{ marginTop: 30, marginLeft: '2%' }}>
                        <Tooltip placement="top" title={props.data?.email || ''}>
                            <Button disabled={props.data?.confirmed} onClick={() => handleResent2fa()}>Resend QR Code</Button>
                        </Tooltip>
                    </Row>
                    <Row style={{ marginTop: '2%', marginLeft: '2%' }}>
                        <span>Please click &quot;Resend QR Code&quot; to resend Account Verification 2FA</span>
                    </Row></Col>
                </Row>

            </Col>

        </Row>
    )
}

export default ViewPeople
