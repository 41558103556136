import React, {useContext, useEffect, useState} from 'react'
import {Row, Col, Form, Breadcrumb, Icon, Modal, Drawer, Tabs, Input, Select, message, Button} from 'antd'
import { Link, Redirect, useParams } from 'react-router-dom'
import { FormComponentProps } from 'antd/lib/form'
import {Space} from '../../../common-components/Space'
import CatalogForm from './CatalogForm'
import CatagoryList from './catagory/CatagoryList'
import ItemList from './Items/ItemList'
import { getCatalogById, updateStatusCatalog, updateCatalog, deleteCatalog, getallCatalogNoRedux, searchCatalogItems, getallCatalog } from '../service'
import { SelfServiceCatalog} from '../Model'
import {useLastLocation} from 'react-router-last-location'
import { PageServiceModelContext } from '../ContextPage/PagesContext'
import { connect, useDispatch } from 'react-redux'
import { StoreState } from '../../../store'
import { DeleteSelfServiceCatalogSuccessEvent } from '../state-event'

interface DispatchProps {
    getallCatalog: () => Promise<number>
    updateCatalog: (catelog: SelfServiceCatalog, id: number) => Promise<any>
}

const mapStateToProps = (state: StoreState) => {
    return {
        selfServiceCatalogs: state.selfServiceCatalogs
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

type Props = FormComponentProps & DispatchProps & StateProps
const { warning } = Modal
const { confirm } = Modal
const { Option } = Select
const { TabPane } = Tabs
const CatalogView: React.FC<Props> = (props: Props) => {
    const { id }: any = useParams()
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form
    const [visible, setVisible] = useState(false)
    const [dataEdit, setDataEdit] = useState<any>()
    const [countCategory, setCountCategory] = useState(0)
    const [countItem, setCountItem] = useState(0)
    const [isCatagoryLoading, setIsCatagoryLoading] = useState<boolean>(true)
    const [dataSource, setDataSource] = useState<SelfServiceCatalog>()
    const [CategoryNameList, setCategoryNameList] = useState<string[]>([])
    const [activeKey, setActiveKey] = useState('1')
    const [isRedirectWithPage, setIsRedirectWithPage] = useState(false)
    const { PageServiceModelState } = useContext<any>(PageServiceModelContext)
    const lastLocation = useLastLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        getCatalogById(id).then(catalog => {
            setDataSource(catalog)
        }).finally(() => {
            setIsCatagoryLoading(false)
        })
        getallCatalogNoRedux().then((data) => {
            const categoryName = data.content.map((value) => {
                return value.name?.toLowerCase()
            })
            setCategoryNameList(categoryName)
        })
        searchCatalogItems(1, 5, id, undefined, 'name', 'asc').then((data) => {
            if (data) {
                setCountItem(data?.totalElements || data?.total)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setFieldsValue({
            catalogName: dataSource?.name,
            status: dataSource?.status,
            description: dataSource?.description
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource])

    useEffect(() => {
        if (lastLocation?.pathname === '/ItemCatalog' || lastLocation?.pathname.includes('/ItemCatalogView')) {
            setActiveKey('2')
        } else {
            setActiveKey('1')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastLocation])

    const oppositeStatus = (status?: string) => {
        if (status === 'Published') {
            return 'Draft'
        } else {
            return 'Published'
        }
    }

    const selectData = () => {
        if (dataSource?.status === 'Draft') {
            setDataEdit(dataSource)
            setVisible(true)
        } else {
            warning({
                title: 'Warning',
                content: 'Please change your status to draft to make corrections.',
                onOk() {
                    console.log('ok')
                }
            })
        }
    }

    const closeDrawer = () => {
        setVisible(false)
    }

    const isDeleteData = () => {
        if (dataSource?.status === 'Draft') {
            if ((countCategory === 0 || !countCategory) && (countItem === 0 || !countCategory)) {
                confirm({
                    title: 'Are you sure?',
                    content: 'Do you confirm Delete of this data ?',
                    onOk() {
                        deleteCatalog(id).catch((err) => {
                            message.error(err)
                        }).then(() => {
                            message.success('Delete data successfully.')
                            setIsRedirectWithPage(true)
                            dispatch(DeleteSelfServiceCatalogSuccessEvent.build(id))
                        })
                    },
                    onCancel() {
                        console.log('Cancel')
                    }
                })
            } else {
                Modal.warning({
                    title: 'Warnning',
                    content: 'This action can\'t be completed. Please delete all catagory related to the catalog.'
                })
            }
        } else {
            Modal.warning({
                title: 'Warnning',
                content: 'Please change your status to Draft to make connections.'
            })
        }
    }

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values)
            }
        })
    }

    const handleEditCatalog = (catalog: SelfServiceCatalog) => {
        props.updateCatalog(catalog, id).then(() => {
            catalog.id = id
            setDataSource(catalog)
        }).catch((err) => {
            message.error(err)
        }).finally(() => {
            setDataEdit(undefined)
        })
    }

    const handleCountCatagory = (count: number) => {
        setCountCategory(count)
    }

    const handleCountItem = (count: number) => {
        setCountItem(count)
    }

    const onChangeTab = (key) => {
        setActiveKey(key)
    }

    const handleChangeStatus = (status: string) => {
        confirm({
            title: 'Are you sure?',
            content: `Do you confirm change status to ${oppositeStatus(status)}?`,
            onOk() {
                const tempDataSource = dataSource
                if (tempDataSource) {
                    tempDataSource.status = oppositeStatus(status)
                    delete tempDataSource.createdDatetime
                    updateStatusCatalog(tempDataSource, id).then(() => {
                        setDataSource(tempDataSource)
                        message.success('The Update has finished successfully.')
                    }).catch((err) => {
                        message.error(err)
                    }).finally(() => {
                        setFieldsValue({
                            status: tempDataSource.status
                        })
                    })
                }
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (props.form.getFieldValue('name')) {
            if (CategoryNameList) {
                if (CategoryNameList.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                    callback('The Category Name already exists. It must be unique. Please try another one.')
                } else {
                    callback()
                }
            }
        } else {
            callback()
        }
    }

    return (
        <>
            {isRedirectWithPage ? <Redirect to={`/CatalogList?path=Catalog&page=${PageServiceModelState.pageCatalog || 1}`} /> : null}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/CatalogList'}>Maintain Catalog</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={'/CatalogList?path=Catalog'}>Catalog</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{dataSource?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <div className={'My-card'}>
                <p className={'HerderText'}>{dataSource?.name} <Icon type="edit" style={{margin: '0px 5px'}} onClick={() => selectData()}/> <Icon type="delete" onClick={() => isDeleteData()}/> </p>
                <Form onSubmit={handleSubmit} >
                    <Row gutter={[8, 8]}>
                        <Col span={12} >
                            <Form.Item label={'Catalog Name'}>
                                {getFieldDecorator('catalogName', {
                                    rules: [
                                        { required: true, message: 'Please enter catalog name!' },
                                        { min: 3, message: 'Enter more than 3 characters' },
                                        { max: 60, message: 'max Input 60 characters' },
                                        { validator: validateNameUniqueness }
                                    ]
                                })(
                                    <Input placeholder="Please enter catalog name" maxLength={60} max={60} disabled={true}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label={'Status'}>
                                {getFieldDecorator('status', {
                                    rules: [{ required: true, message: 'Please enter status!' }]
                                })(
                                    <Select
                                        placeholder="Select Status"
                                        disabled={true}
                                    >
                                        <Option value="Published">Published</Option>
                                        <Option value="Draft">Draft</Option>
                                    </Select>
                                )}
                            </Form.Item>

                        </Col>
                        <Col span={3}>
                            <Button className='btn-status' icon='sync' onClick={() => handleChangeStatus(getFieldValue('status'))} >Change to {oppositeStatus(getFieldValue('status'))}</Button>
                        </Col>

                        <Col span={24}>
                            <Form.Item label={'Description'}>
                                {getFieldDecorator('description', {
                                    rules: [
                                        { required: false, message: 'Please input your username!' },
                                        { max: 255, message: 'max Input 255 characters' }
                                    ]
                                })(
                                    <Input maxLength={255} disabled={true}/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Space size={10} />
                <Tabs activeKey={activeKey} onChange={onChangeTab} >
                    <TabPane
                        tab={
                            <span>
                                Category ({countCategory || 0})
                            </span>
                        }
                        key="1"
                    >
                        {!isCatagoryLoading ? (<CatagoryList catalog={dataSource} setCountCategory={handleCountCatagory}/>) : (<></>)}
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                Catalog Item ({countItem || 0})
                            </span>
                        }
                        key="2"
                    >
                        <ItemList catalog={dataSource} setCountItem={handleCountItem} isViewCatalog={true} />
                    </TabPane>
                </Tabs>
            </div>
            {/* <div style={{display: 'none'}}><ItemList catalog={dataSource} setCountItem={handleCountItem} isViewCatalog={true}/></div> */}
            <Drawer
                title={'Edit Catalog'}
                placement="right"
                onClose={() => closeDrawer()}
                visible={visible}
                width={500}
            >
                <CatalogForm data={dataEdit} setVisible={setVisible} isVisible={visible} catalogSubmit={handleEditCatalog} id={id}/>
            </Drawer>
        </>
    )
}

const MyCatalogView = Form.create<Props>({ name: 'CatalogView' })(CatalogView)

export default connect(mapStateToProps,{ getallCatalog, updateCatalog })(MyCatalogView) 
