import React, { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { buildingHierarchyOption, childGeneral, itemAssetLocation, locationHierarchyOption, parentItemLocation, siteHierarchyOption } from '../model'
import { createItemAssetLocation } from '../service'
import RenderSiteTab from './RenderSiteTab'
import RenderLocationTab from './RenderLocationTab'
import RenderBuildingTab from './RenderBuildingTab'
import RenderFloorTab from './RenderFloorTab'

interface Params {
    locationTab: string
    child: childGeneral[]
    onLoading: Function
    onFetchData: Function
    onImportData: boolean
    onDeleteData: boolean
    currentTab: string
    dataSite: siteHierarchyOption[]
    dataLocation: locationHierarchyOption[]
    dataBuilding: buildingHierarchyOption[]
}

type Props = Params & FormComponentProps
const RenderTabAssetLocationTabs: React.FC<Props> = (props: Props) => {
    const { resetFields, validateFields, setFields } = props.form
    const [optionLocation, setOptionLocation] = useState<locationHierarchyOption[]>([])
    const [optionBuilding, setOptionBuilding] = useState<buildingHierarchyOption[]>([])
    const [isDisableLocation, setIsDisableLocation] = useState<boolean>(true)
    const [isDisableBuilding, setIsDisableBuilding] = useState<boolean>(true)
    const [isDisableFloor, setIsDisableFloor] = useState<boolean>(true)

    useEffect(() => {
        setIsDisableLocation(true)
        setIsDisableBuilding(true)
        setIsDisableFloor(true)
        setFields({ site: undefined, location: undefined, building: undefined, floor: undefined })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentTab])

    const handleCancel = () => {
        if (props.locationTab === 'Location') {
            setIsDisableLocation(true)
            setFields({ location: undefined })
        } else if (props.locationTab === 'Building') {
            setIsDisableLocation(true)
            setIsDisableBuilding(true)
            setFields({ location: undefined, building: undefined })
        } else if (props.locationTab === 'Floor') {
            setIsDisableLocation(true)
            setIsDisableBuilding(true)
            setIsDisableFloor(true)
            setFields({ location: undefined, building: undefined, floor: undefined })
        }
        resetFields()
    }

    const handleSubmitLocations = (e) => {
        e.preventDefault()
        let fieldsToValidate: string[] = []
        if (props.locationTab === 'Site') {
            fieldsToValidate = ['site']
        } else if (props.locationTab === 'Location') {
            fieldsToValidate = ['siteLocation', 'location']
        } else if (props.locationTab === 'Building') {
            fieldsToValidate = ['siteBuilding', 'locationBuilding', 'building']
        } else if (props.locationTab === 'Floor') {
            fieldsToValidate = ['siteFloor', 'locationFloor', 'buildingFloor', 'floor']
        }

        validateFields(fieldsToValidate, (err, values) => {
            if (!err) {
                const items: parentItemLocation[] = []
                const findKey = props.child?.find((it) => it.label.toLowerCase() === props.locationTab.toLowerCase())
                if (props.locationTab === 'Location') {
                    const findParentSite = props.dataSite.find((it) => it.siteName.toLowerCase() === values.siteLocation.toLowerCase())
                    items.push({
                        parentId: findParentSite?.siteId,
                        name: values.location
                    })
                } else if (props.locationTab === 'Building') {
                    const findParentLocation = props.dataLocation.find((it) => it.locationName.toLowerCase() === values.locationBuilding.toLowerCase())
                    items.push({
                        parentId: findParentLocation?.locationId,
                        name: values.building
                    })
                } else if (props.locationTab === 'Floor') {
                    const filterLocation = props.dataLocation.filter((it) => it.locationName.toLowerCase() === values.locationFloor.toLowerCase())
                    const findIdParent = filterLocation.flatMap((it) => it.buildings).find((it) => {
                        return it.buildingName.toLowerCase() === values.buildingFloor.toLowerCase()
                    })
                    items.push({
                        parentId: findIdParent?.buildingId,
                        name: values.floor
                    })
                } else {
                    items.push({
                        name: values.site
                    })
                }

                const body: itemAssetLocation = {
                    typeId: findKey?.id!!,
                    items: items
                }

                props.onLoading(true)
                createItemAssetLocation(body).then((res) => {
                    if (res) {
                        message.success('You have successfully saved the data.')
                        props.onFetchData()
                    }
                }).catch((err) => {
                    console.log(err)
                    props.onLoading(false)
                })
                resetFields()
                setIsDisableLocation(true)
                setIsDisableBuilding(true)
                setIsDisableFloor(true)
            }
        })
    }

    const handleSelectSite = (site: string) => {
        setIsDisableLocation(false)
        const filterLocation = props.dataSite.filter((it) => it.siteName === site).flatMap((data) => { return data.locations })
        const uniqueLocation = [...new Map(filterLocation.map((item) =>
            [item.locationId, item])).values()]
        setOptionLocation(uniqueLocation)
    }

    const handleSelectLocation = (location: string) => {
        setIsDisableBuilding(false)
        // const filterBuilding = props.dataLocation.filter((it) => it.locationName === location).flatMap((data) => { return data.buildings })
        const newFilterBuilding = props.dataLocation.filter((it) => it.locationName === location).flatMap((data) => { return data.buildings }).reduce((acc: any[], curr) => {
            const { buildingId, buildingName, floors } = curr

            const findBuildingId = acc.find((it) => it.buildingId === buildingId)

            if (findBuildingId) {
                findBuildingId.floors = findBuildingId.floors.concat(floors)
            } else {
                acc.push({ buildingId, buildingName, floors: [...floors] })
            }

            return acc
        }, [])
        setOptionBuilding(newFilterBuilding)
    }

    const handleChangeSite = (value) => {
        if (value === undefined || value === '') {
            if (props.locationTab === 'Location') {
                setIsDisableLocation(true)
                setFields({ location: undefined })
            } else if (props.locationTab === 'Building') {
                setIsDisableLocation(true)
                setIsDisableBuilding(true)
                setFields({ location: undefined, building: undefined })
            } else if (props.locationTab === 'Floor') {
                setIsDisableLocation(true)
                setIsDisableBuilding(true)
                setIsDisableFloor(true)
                setFields({ location: undefined, building: undefined, floor: undefined })
            }
        } else {
            if (props.locationTab === 'Building') {
                setIsDisableBuilding(true)
                setFields({ location: undefined, building: undefined })
            } else if (props.locationTab === 'Floor') {
                setIsDisableBuilding(true)
                setIsDisableFloor(true)
                setFields({ location: undefined, building: undefined, floor: undefined })
            }
        }
    }

    return (
        <Form form={props.form} onSubmit={handleSubmitLocations}>
            {props.locationTab === 'Site'
                ? <RenderSiteTab
                    dataSite={props.dataSite}
                    onCancel={handleCancel}
                    form={props.form}
                />
                : null}
            {props.locationTab === 'Location'
                ? <RenderLocationTab
                    dataSite={props.dataSite}
                    dataLocations={props.dataLocation}
                    isDisable={isDisableLocation}
                    setIsDisable={setIsDisableLocation}
                    changeSite={handleChangeSite}
                    onCancel={handleCancel}
                    form={props.form}
                />
                : null}
            {props.locationTab === 'Building'
                ? <RenderBuildingTab
                    dataSite={props.dataSite}
                    dataLocation={props.dataLocation}
                    optionLocation={optionLocation}
                    isDisableBuilding={isDisableBuilding}
                    isDisableLocation={isDisableLocation}
                    selectSite={handleSelectSite}
                    selectLocation={handleSelectLocation}
                    onCancel={handleCancel}
                    form={props.form}
                />
                : null}
            {props.locationTab === 'Floor'
                ? <RenderFloorTab
                    dataSite={props.dataSite}
                    dataLocation={props.dataLocation}
                    dataBuilding={props.dataBuilding}
                    optionLocation={optionLocation}
                    optionBuilding={optionBuilding}
                    selectSite={handleSelectSite}
                    selectLocation={handleSelectLocation}
                    isDisableLocation={isDisableLocation}
                    isDisableBuilding={isDisableBuilding}
                    isDisableFloor={isDisableFloor}
                    setIsDisableFloor={setIsDisableFloor}
                    onCancel={handleCancel}
                    form={props.form}
                />
                : null}
        </Form>
    )
}

export default RenderTabAssetLocationTabs
