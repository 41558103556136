import { Form, Row, Col, Button, Icon, Spin, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import {FormComponentProps} from 'antd/es/form'
import Logo from '../assets/images/LogoGoService.png'
import Icom2FA from '../assets/images/resetpassword 1.png'
// import mockupQRCode from '../assets/images/mockupQR.png'
// import { tokenDecode } from '../common-misc'
import { generate2fa } from '../authorization-module/login/service'

type params = {
    email?: string
    getCode?: Function
    isgenQRCode?: boolean
    validateCode?: boolean
    isSubmitted?: boolean
    handleCloseModal: Function
}
type Props = FormComponentProps & params
const Modal2FA = (props: Props) => {
    const {getFieldDecorator, getFieldValue, resetFields} = props.form
    const listNumber = [1, 2, 3, 4, 5, 6]
    const [validete, serValidate] = useState(false)
    const [isGenQRCode, setIsGenQRCode] = useState(props?.isgenQRCode)
    const [imgaeQRCode, setImageQRCode] = useState<any>()
    const [isloadQRCode, setLoadQRCode] = useState(false)

    useEffect(() => {
        if (!isGenQRCode) {
            setLoadQRCode(true)
            generate2fa(props.email).then((res: any) => {
                const blob = new Blob(
                    [res.data],
                    { type: res.headers['content-type'] }
                )
                const image = URL.createObjectURL(blob)
                setImageQRCode(image)
            }).finally(() => { setLoadQRCode(false) })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGenQRCode])

    useEffect(() => {
        if (props.validateCode) {
            const ele = document.getElementById('normal_login_number1')
            if (ele) {
                resetFields()
                ele.focus()
            }
            serValidate(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.validateCode])

    const validateCode = () => {
        if (getFieldValue('number1') && getFieldValue('number2') && getFieldValue('number3') && getFieldValue('number4') && getFieldValue('number5') && getFieldValue('number6')) {
            const verify = {
                username: props.email,
                code: getFieldValue('number1').toString() + getFieldValue('number2').toString() + getFieldValue('number3').toString() + getFieldValue('number4').toString() + getFieldValue('number5').toString() + getFieldValue('number6').toString()
            }
            if (props.getCode) {
                props.getCode(verify)
            }
        } else {
            resetFields()
            serValidate(true)
        }
    }

    const handleKeyUp = (ev) => {
        const listValue = [getFieldValue('number1'), getFieldValue('number2'), getFieldValue('number3'), getFieldValue('number4'), getFieldValue('number5'), getFieldValue('number6')]
        if (ev.keyCode === 8) {
            // 8 === backspace
            // const findFieldValueEmpty = listValue.findIndex((it) => { return it !== undefined })
            // if (findFieldValueEmpty) {
            //     const ele = document.getElementById('normal_login_number' + (findFieldValueEmpty - 1).toString())
            //     if (ele) {
            //         ele.focus()
            //     }
            // }
        } else if (ev.keyCode === 13) {
            validateCode()
        } else {
            serValidate(false)
            const findFieldValueEmpty = listValue.findIndex((it) => { return it === undefined || it === '' })
            if (findFieldValueEmpty) {
                const ele = document.getElementById('normal_login_number' + (findFieldValueEmpty + 1).toString())
                if (ele) {
                    ele.focus()
                }
            }
        }
    }

    return (
        <div>
            {!isGenQRCode ? <>
                <Row>
                    <div style={{ backgroundColor: '#323377', height: 90}}>
                        <img src={Logo} alt='logo' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: 10, width: '50% '}} />
                        <div style={{ position: 'absolute', color: '#fff', top: 0, right: 0, paddingRight: 10 }}><Icon type="close" onClick={() => props.handleCloseModal(false)}/></div>
                    </div>
                </Row>
                <br/><br/>
                <Row>
                    <Col span={2}></Col>
                    <Col span={22}>
                        <div>
                            <h3 style={{ color: '#323276'}}>Setup Google Authenticator App</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={2}></Col>
                    <Col span={22}>
                        <div>
                            <h5 style={{ color: '#323276'}}>Scan the QR below <br/> Once in the Google Authenticator app scan the QR with your phone camera.</h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={3}></Col>
                    <Col span={18} style={{ textAlign: 'center' }}>
                        <Spin spinning={isloadQRCode}>
                            <img src={imgaeQRCode} alt='qrcode' />
                        </Spin>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                <Row style={{ paddingBottom: 24, paddingTop: 24 }}>
                    <Col span={16}></Col>
                    <Col span={5} style={{ textAlign: 'end' }}>
                        <Button type='primary' htmlType='button' style={{ width: '100%' }} onClick={() => setIsGenQRCode(true)}>Next <Icon type="arrow-right" /></Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </> : <>
                <Row>
                    <div style={{ backgroundColor: '#323377', height: 90}}>
                        <img src={Logo} alt='logo' style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: 10, width: '50% '}} />
                        <div style={{ position: 'absolute', color: '#fff', top: 0, right: 0, paddingRight: 10 }}><Icon type="close" onClick={() => props.handleCloseModal(false)}/></div>
                    </div>
                </Row>
                <br/><br/>
                <Row>
                    <div style={{ textAlign: 'center'}}>
                        <img src={Icom2FA} alt='logo' />
                    </div>
                </Row>
                <Row>
                    <div style={{ textAlign: 'center'}}>
                        <h3>Verify your Authentication app</h3>
                    </div>
                </Row>
                <Row>
                    <div style={{ textAlign: 'center'}}>
                        <h5>Enter 6-digit code from your two-factor authentication app.</h5>
                    </div>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={3}></Col>
                    {listNumber.map((value, index) => {
                        return <Col span={3} key={index} style={{ textAlign: 'center' }}>
                            <Form.Item validateStatus={validete ? 'error' : ''}>
                                {getFieldDecorator('number' + value, {
                                    rules: [{required: false, message: 'Please input your Number' + value}]
                                })(
                                    <Input id={'number' + value.toString()} maxLength={1} style={{ width: 50 }} autoFocus={index === 0} onKeyUp={(key) => handleKeyUp(key)} onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault()
                                        }
                                    }}/>
                                )}
                            </Form.Item>
                        </Col>
                    })}
                    <Col span={3}></Col>
                </Row>
                <Row>
                    <Col span={3}></Col>
                    <Col span={18}>{validete ? <div style={{ color: 'red' }}>The code is entered incorrectly.</div> : null}</Col>
                    <Col span={3}></Col>
                </Row>
                <Row style={{ paddingBottom: 24, paddingTop: 24 }}>
                    <Col span={3}></Col>
                    <Col span={18}>
                        <Button type='primary' htmlType='button' style={{ width: '100%' }} onClick={() => validateCode()} loading={props.isSubmitted}>Enable</Button>
                    </Col>
                    <Col span={3}></Col>
                </Row>
            </>}
        </div>
    )
}

const WrappedNormalModal2FAForm = Form.create<Props>(
    {
        name: 'normal_login'
    }
)(Modal2FA)
export default WrappedNormalModal2FAForm
