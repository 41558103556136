import {
    GetAllGlobalSettingSuccessEvent,
    GlobalSettingStateEvent,
    UpdateGlobalSettingSuccessEvent
} from './state-event'
import { SlaGlobalSetting } from './model'

export const slaGlobalSettingStateReducer = (state: SlaGlobalSetting = {}, event: GlobalSettingStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllGlobalSettingSuccessEvent.type || event.type === UpdateGlobalSettingSuccessEvent.type) {
        return event.payload || {}
    } else {
        return state
    }
}
