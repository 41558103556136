import React, {useEffect, useState} from 'react'
import {
    AutoComplete,
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Radio,
    Row,
    Select
    , Spin
} from 'antd'
import {connect} from 'react-redux'
import {StoreState} from '../../store'
import {useParams} from 'react-router-dom'
import {getAllImpact} from '../impact'
import {getAllUrgency} from '../urgency'
import {Categories, getAllCategory, getAllItems, getAllSubCategory, Item, SubCategory} from '../category'
import {getAllSupportTeamByPermission} from '../support-team'
import {FieldDetail, SupportTeam, TicketStatus} from '../incident/model'
import {searchIncidents, updateIncident} from './service'
import {IncidentFilter} from '../incident-view'
import {Incident} from './index'
import {getAllPriority, PriorityLevel, PriorityMatrix, resolvePriority} from '../priority'
import {FormComponentProps} from 'antd/lib/form'
import {SelectValue} from 'antd/lib/select'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {getAllCustomFields} from '../../configurable-fields'
import moment from 'moment'
import {UserSupportTeam} from '../../authorization-module/support-team-management/model'
import {getTicketStatus} from '../status'
import {disableByStatus} from '../../common-misc/index'
import {People} from '../../authorization-module/people/model'

const mapStateToProps = (state: StoreState) => {
    return {
        impacts: state.impacts,
        urgencies: state.urgencies,
        categories: state.categories,
        subCategories: state.subCategories,
        items: state.items,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        incidents: state.incidentState?.incidents || [],
        incident: state.incidentState.incident,
        customFields: state.customFields,
        priorities: state.priorities,
        statuses: state.statuses,
        generalIncidentSetting: state.generalIncidentSetting
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAllImpact: () => Promise<number>
    getAllUrgency: () => Promise<number>
    getAllCategory: () => Promise<number>
    getAllSubCategory: () => Promise<number>
    getAllItems: () => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    searchIncidents: (criteria: IncidentFilter, sortBy: string, orderBy: string) => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
    getAllCustomFields: (value: string) => Promise<number>
    getAllPriority: () => Promise<number>
}

interface Params {
    currentStatus?: TicketStatus
    changeOldStatus: (boolean) => void
    oldStatus?: string
    myCustomFields?: FieldDetail[] | undefined
    getFetchSuccess: Function
}

interface Params2 {
    id: string
}

type Props = StateProps & DispatchProps & FormComponentProps & Params
const { Option } = Select
const IncidentStatusUpdater: React.FC<Props> = (props: Props) => {
    const { id } = useParams<Params2>()
    const [isFetch, setFetch] = useState(false)
    const [myPriority, setMyPriority] = useState<PriorityMatrix>()
    const [subcategoryName, setSubcategoryName] = useState<string[]>([])
    const [mySubCategory, setMySubCategory] = useState<string[]>([])
    const [items, setItems] = useState<string[]>([])
    let mySupportTeamName: string[] = []
    const [, setSupportTeam] = useState<SupportTeam>()
    const [user, setUser] = useState<People>()
    const [assignee, setAssignee] = useState<UserSupportTeam[]>([])
    const [isRequireAssignGroup, setIsRequireAssignGroup] = useState<boolean>(false)
    const [isRequireAssignee, setIsRequireAssignee] = useState<boolean>(false)
    const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [inc, setInc] = useState<Incident>()
    const [tempCustomFields, setTempCustomFields] = useState<FieldDetail[] | undefined>(props.myCustomFields)

    useEffect(() => {
        if (!isFetch) {
            const promiseAll: any[] = []
            // fetchData().catch((err) => message.error(`Failed fetching all data. ${err}`))
            // props.getAllImpact().catch((err) => message.error(`Failed fetching all impact. ${err}`))
            // props.getAllUrgency().catch((err) => message.error(`Failed fetching all urgency. ${err}`))
            // props.getAllCategory().catch((err) => message.error(`Failed fetching all category. ${err}`))
            // props.getAllSubCategory().catch((err) => message.error(`Failed loading all subcategory data. ${err}`))
            // props.getAllItems().catch((err) => message.error(`Failed loading all items data. ${err}`))
            // props.getAllSupportTeamByPermission().catch((err) => message.error(`Failed fetching all support team. ${err}`))
            promiseAll.push(props.getAllSupportTeamByPermission())
            promiseAll.push(fetchData())
            promiseAll.push(props.getAllImpact())
            promiseAll.push(props.getAllUrgency())
            promiseAll.push(props.getAllCategory())
            promiseAll.push(props.getAllSubCategory())
            promiseAll.push(props.getAllItems())
            Promise.all(promiseAll).catch((err) => {
                message.error(`Failed fetching all Data. ${err}`)
            }).finally(() => {
                props.getFetchSuccess(true)
                setFetch(true)
            })
        }
        if (props.currentStatus !== undefined) {
            checkCurrentStatusToSetRequireFieldAndDisable()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentStatus])

    useEffect(() => {
        if (props.incident) {
            setIncident([props.incident])
            // const settingType = props.incident.incidentSetting.find(it => it.incGeneralSetting.settingType === 'ticketType')!!
            // props.getAllCustomFields(settingType.incGeneralSetting.value!!)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.incident])

    useEffect(() => {
        const temMyCategory: string[] = []
        if (props.categories) {
            props.categories.forEach((category: Categories) => {
                if (category.active) {
                    temMyCategory.push(category.name)
                }
            })
            setMySubCategory(temMyCategory)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.categories])

    const fetchData = async () => {
        if (id) {
            console.log('in id', props.incidents)
            if (props.incidents?.length !== 0) {
                console.log('in if')
                const incidentsTarget = props.incidents?.filter((target: Incident) => {
                    return target.id === Number(id)
                })
                setIncident(incidentsTarget)
                const settingType = incidentsTarget[0].incidentSetting?.find(it => it.incGeneralSetting.settingType === 'ticketType')!!
                console.log(settingType)
                props.getAllCustomFields(settingType.incGeneralSetting.value!!)
            } else {
                console.log('in else')
                // const target = getIncidentById(Number(id)).then((res: Incident) => {
                //     const temp: Incident[] = []
                //     if (res) {
                //         temp.push(res)
                //     }
                //     return temp
                // })
                // await target.then((res) => {
                //     setIncident(res)
                //     const settingType = res[0].incidentSetting.find(it => it.incGeneralSetting.settingType === 'ticketType')!!
                //     props.getAllCustomFields(settingType.incGeneralSetting.value!!)
                //     const isSelfService = res[0].incidentSetting.find(it => it.incGeneralSetting.value === 'Self-Service')!!
                //     if (isSelfService) {
                //         console.log('isSelfService', isSelfService)
                //     }
                // })
            }
        }
    }

    const setIncident = (incidentsTarget: Incident[]) => {
        incidentsTarget.forEach((res) => {
            setInc(res)
            if (res.customField) {
                setTempCustomFields(res.customField)
            }
            props.form.setFieldsValue({
                Assignment_group: res.supportTeam?.name,
                Assigned_to: res.user === null ? undefined : res.user?.employeeId + ' : ' + res.user?.fullName,
                Impact: res.priority?.impact?.name,
                Urgency: res.priority?.urgency?.name,
                Categories: res.category === null ? undefined : res.category,
                subcategory: res.subcategory === null ? undefined : res.subcategory,
                Item: res.item === null ? undefined : res.item
            })
            // FilterMyCategory(res.category || '')
        })
        callApiGetPriorities().catch((err) => message.error(`Failed fetching priority. ${err}`))
    }

    const callApiGetPriorities = async () => {
        const myImpact = props.form.getFieldValue('Impact')
        const myUrgency = props.form.getFieldValue('Urgency')
        const impact = props.impacts?.find(it => (it.id?.toString() === myImpact || it.name === myImpact))?.id!!
        const urgency = props.urgencies?.find(it => (it.id?.toString() === myUrgency || it.name === myUrgency))?.id!!
        if (impact !== undefined && urgency !== undefined) {
            resolvePriority(impact, urgency, Number(id)).then((res) => {
                setMyPriority(res)
            }).catch((err) => {
                message.error(`${err}`)
            })
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()

        props.form.validateFieldsAndScroll((err: any, values: any) => {
            if (!err) {
                setIsLoading(true)
                const fieldDetails: FieldDetail[] = (inc?.customField || []).map((obj: FieldDetail) => {
                    let value = ''
                    if (obj.name) {
                        if (obj.inputType === 'Checkbox') {
                            value = (props.form.getFieldValue(obj.name) || []).join('|')
                        } else if (obj.inputType === 'DatePicker') {
                            value = moment(props.form.getFieldValue(obj.name)).format('YYYY-MM-DD')
                        } else {
                            value = props.form.getFieldValue(obj.name)
                        }
                    }
                    const fieldDetail: FieldDetail = {
                        id: obj.id,
                        value: value
                    }
                    return fieldDetail
                })
                const targetSupportTeams = props.supportTeamsWithPermission.find((supportTeam: SupportTeam) => {
                    return supportTeam.name === values.Assignment_group
                })
                const ticketType = props.incident?.incidentSetting?.find(it => it.incGeneralSetting.settingType === 'ticketType')?.incGeneralSetting.value!!
                const patch = {
                    id: id,
                    supportTeam: targetSupportTeams || null, // supportTeam
                    user: user || inc?.user || null,
                    priority: myPriority,
                    category: values.Categories === undefined ? null : values.Categories,
                    subcategory: values.subcategory === undefined ? null : values.subcategory,
                    item: values.Item === undefined ? null : values.Item,
                    status: '',
                    customField: fieldDetails
                } as unknown as any
                if (values.Assignment_group && values.Assigned_to) {
                    patch.ticketStatus = getTicketStatus(props.statuses, ticketType, 'InProgress')
                } else if (values.Assignment_group) {
                    patch.ticketStatus = getTicketStatus(props.statuses, ticketType, 'Assigned')
                } else {
                    delete patch.ticketStatus
                }
                console.log(patch)
                props.updateIncident((patch as unknown as Incident))
                    .then(() => {
                        message.success('The update has finished successfully.')
                        props.changeOldStatus(true)
                        setIsLoading(false)
                    })
                    .catch((err) => {
                        message.error(`${err}`)
                        setIsLoading(false)
                    })
            }
        })
    }

    const FilterMyCategory = (e: SelectValue | string) => {
        const mySubcategories = props.subCategories.filter((subcategory: SubCategory) => {
            return subcategory.category === e && subcategory.active === true
        })
        setSubcategoryName(mySubcategories.map((subcategory: SubCategory) => subcategory.subCategory))
        props.form.resetFields(['subcategory', 'Item'])
    }

    const checkCategoryField = (valueInCategory: SelectValue) => {
        if (valueInCategory) {
            FilterMyCategory(valueInCategory)
        } else {
            props.form.setFieldsValue({
                subcategory: undefined,
                Item: undefined
            })
            setItems([])
        }
    }

    const checkSubcategoryField = (valueInSubcategory: SelectValue) => {
        if (valueInSubcategory) {
            FilterMyItems(valueInSubcategory)
        } else {
            props.form.setFieldsValue({
                Item: undefined
            })
            setItems([])
        }
    }

    const fetchSubcategory = () => {
        const currentCategory = props.form.getFieldValue('Categories')
        const filterSubCategory = props.subCategories.filter((subCategory: SubCategory) => {
            return subCategory.category === currentCategory && subCategory.active === true
        })
        setSubcategoryName(filterSubCategory.map((subCategory: SubCategory) => subCategory.subCategory))
    }

    const fetchItems = () => {
        const currentSubcategory = props.form.getFieldValue('subcategory')
        const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === currentSubcategory && item.category === props.form.getFieldValue('Categories') && item.active === true)

        setItems(itemsInSubcat.filter((it) => it.active === true).map((item:Item) => item.item))
    }

    const FilterMyItems = (e: SelectValue) => {
        const itemsInSubcat = props.items.filter((item: Item) => item.subCategory === e && item.category === props.form.getFieldValue('Categories') && item.active === true)
        setItems(itemsInSubcat.filter((it) => it.active === true).map((item:Item) => item.item))

        // props.form.setFieldsValue({
        //     Item: undefined
        // })
        props.form.resetFields(['Item'])
    }

    const FilterSupportTeam = (e: SelectValue) => {
        props.form.setFieldsValue({
            Assigned_to: null
        })
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === e
        })
        if (targetSupportTeams.length !== 0) {
            setSupportTeam(targetSupportTeams[0])
            targetSupportTeams.forEach((res: SupportTeam) => {
                setAssignee(res.assignees)
            })
        } else {
            setAssignee([])
        }
    }

    const FilterAssignee = () => {
        const valAssignee = props.form.getFieldValue('Assignment_group')
        const targetSupportTeams = props.supportTeamsWithPermission.filter((supportTeam: SupportTeam) => {
            return supportTeam.name === valAssignee
        })
        targetSupportTeams.forEach((res) => {
            setAssignee(res.assignees)
        })
    }

    const checkAssignee = (e: SelectValue) => {
        if (e) {
            FilterSupportTeam(e)
        } else {
            props.form.setFieldsValue({
                Assigned_to: null
            })
        }
    }

    const { getFieldDecorator } = props.form

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 }
        }
    }

    const onBlurTeam = (value: SelectValue) => {
        if (mySupportTeamName.length !== 0) {
            const isTeam = mySupportTeamName.includes((value as string))
            if (!isTeam) {
                props.form.setFieldsValue({
                    Assignment_group: null
                })
            }
        } else {
            props.form.setFieldsValue({
                Assignment_group: inc?.supportTeam?.name
            })
        }
    }

    const onBlurAssignee = (value: SelectValue) => {
        if (assignee.length !== 0) {
            const isAssignee = assignee.find(it => it.id?.toString() === value || it.users.fullName === value)
            if (!isAssignee) {
                props.form.setFieldsValue({
                    Assigned_to: null
                })
            } else {
                setUser(isAssignee.users)
            }
        } else {
            props.form.setFieldsValue({
                Assigned_to: inc?.user?.fullName
            })
        }
    }

    const checkCurrentStatusToSetRequireFieldAndDisable = () => {
        disableByStatus(props.currentStatus?.status, setDisabledSaveButton, props.incident?.user?.email, props?.incident?.supportTeam)

        if (props.currentStatus?.status === 'InProgress' || (props.currentStatus?.status === 'Pending' && props.oldStatus === 'InProgress')) {
            setIsRequireAssignGroup(true)
            setIsRequireAssignee(true)
        } else if (props.currentStatus?.status === 'Assigned' || (props.currentStatus?.status === 'Pending' && props.oldStatus === 'Assigned')) {
            setIsRequireAssignGroup(true)
            setIsRequireAssignee(false)
        } else if ((props.currentStatus?.status === 'Resolved' || props.currentStatus?.status === 'Closed') &&
            !(props.incident?.user?.email && props.incident.supportTeam)) {
            setIsRequireAssignGroup(true)
            setIsRequireAssignee(true)
        } else {
            setIsRequireAssignGroup(false)
            setIsRequireAssignee(false)
        }
    }
    // eslint-disable-next-line no-return-assign
    return (
        <Spin spinning={isLoading}>
            <Form {...formItemLayout} onSubmit={handleSubmit} layout="vertical">
                {
                    <>
                        {props.supportTeamsWithPermission.length !== 0 ? (<span style={{ display: 'none' }}>{
                            mySupportTeamName = props.supportTeamsWithPermission.map((supportTeam: SupportTeam) => {
                                return supportTeam.name
                            })
                        }</span>
                        ) : null}

                        <Row gutter={8}>
                            <Col span={12}>
                                <p className="MyText-form"><span className={'requiredIcon'}>* </span>Impact</p>
                                <Form.Item>
                                    {getFieldDecorator('Impact',
                                        {
                                            rules: [{ required: true, whitespace: true, message: 'Impact is required' }]
                                        })(
                                        <Select style={{ minWidth: '120px' }} placeholder="-Select-"
                                            onSelect={() => callApiGetPriorities()} disabled={disabledSaveButton}>
                                            {props.impacts.length === 0 ? null : (props.impacts.map((item: PriorityLevel, index: number) => {
                                                return <Option value={item.id?.toString()} key={index}>{item.name}</Option>
                                            }))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <p className="MyText-form"><span className={'requiredIcon'}>* </span>Urgency</p>
                                <Form.Item>
                                    {getFieldDecorator('Urgency',
                                        {
                                            rules: [{ required: true, whitespace: true, message: 'Required' }]
                                        })(
                                        <Select style={{ minWidth: '120px' }} placeholder="-Select-"
                                            onSelect={() => callApiGetPriorities()} disabled={disabledSaveButton}>
                                            {props.urgencies.length === 0 ? null : (props.urgencies.map((item: PriorityLevel, index: number) => {
                                                return <Option value={item.id?.toString()} key={index}>{item.name}</Option>
                                            }))}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <p>{isRequireAssignGroup ? <span className='requiredIcon'>* </span> : null}<b
                                className="MyText-form">Assignment group</b></p>
                            <Form.Item>
                                {getFieldDecorator('Assignment_group',
                                    {
                                        rules: [{
                                            required: isRequireAssignGroup,
                                            whitespace: true,
                                            message: 'Assignment group is required'
                                        }]
                                    })(
                                    <AutoComplete
                                        style={{ width: '250px' }}
                                        dataSource={(mySupportTeamName || []).map((it) => {
                                            return it
                                        })}
                                        onChange={(e) => checkAssignee(e)}
                                        onSelect={(e) => FilterSupportTeam(e)}
                                        onBlur={(e) => onBlurTeam(e)}
                                        placeholder={'Select an assignment group'}
                                        disabled={disabledSaveButton}
                                    />
                                )}
                            </Form.Item>
                        </Row>
                        <Row>
                            <p>{isRequireAssignee ? <span className='requiredIcon'>* </span> : null}<b
                                className="MyText-form">Assigned to</b></p>
                            <Form.Item>
                                {getFieldDecorator('Assigned_to',
                                    {
                                        rules: [{
                                            required: isRequireAssignee,
                                            whitespace: true,
                                            message: 'Assignee is required'
                                        }]
                                    })(
                                    <AutoComplete style={{ width: '250px' }}
                                        dataSource={assignee.map((item, index: number) => {
                                            return <Option value={item.id?.toString()}
                                                key={index}>{item.users.employeeId + ' : ' + item.users.fullName}</Option>
                                        })}
                                        onFocus={() => FilterAssignee()}
                                        onBlur={(e) => onBlurAssignee(e)}
                                        placeholder={'Select an assignee'}
                                        disabled={disabledSaveButton} />
                                )}
                            </Form.Item>
                        </Row>
                        <Row>
                            <p><b className="MyText-form">Category</b></p>
                            <Form.Item>
                                {getFieldDecorator('Categories')(
                                    <Select
                                        style={{ width: '250px' }}
                                        placeholder="Select a category"
                                        onSelect={(e) => FilterMyCategory(e)} key={0}
                                        onChange={(e) => checkCategoryField(e)}
                                        allowClear={true}
                                        disabled={disabledSaveButton}
                                    >
                                        {[...new Set(mySubCategory)].map((item: string, index: number) => {
                                            return <Option value={item} key={index}>{item}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Row>
                        <Row>
                            <p><b className="MyText-form">Subcategory</b></p>
                            <Form.Item>
                                {getFieldDecorator('subcategory')(
                                    <Select
                                        style={{ width: '250px' }}
                                        placeholder='Select a subcategory'
                                        onSelect={(e) => FilterMyItems(e)} key={1}
                                        onChange={(e) => checkSubcategoryField(e)}
                                        onFocus={() => fetchSubcategory()}
                                        allowClear={true}
                                        disabled={disabledSaveButton}
                                    >
                                        {[...new Set(subcategoryName)].map((item: string, index: number) => {
                                            return <Option value={item} key={index}>{item}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        </Row>
                        <Row>
                            <p><b className="MyText-form">Item</b></p>
                            <Form.Item>
                                {getFieldDecorator('Item')(
                                    <Select
                                        style={{ width: '250px' }}
                                        onFocus={() => fetchItems()}
                                        placeholder='Select an item'
                                        key={2}
                                        allowClear={true}
                                        disabled={disabledSaveButton}
                                    >
                                        {items !== null ? ([...new Set(items)].map((item: string, index: number) => {
                                            return <Option value={item} key={index}>{item}</Option>
                                        })) : 'null'}
                                    </Select>
                                )}
                            </Form.Item>
                        </Row>
                        {(inc?.customField || tempCustomFields || []).map((obj: FieldDetail, index) => {
                        // const tempData = Object.values(props.incident?.customField!![index]!!)[0]
                        // const tempData = inc?.customField!![index]
                            if (obj) {
                                return (
                                    <Row key={index}>
                                        <Col span={24} lg={24} md={24} sm={24} xs={24} key={index}>
                                            {obj.inputType === 'Checkbox' ? (
                                                <>
                                                    <p><b className="MyText-form">{obj.isRequireFields ? (
                                                        <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                    </p>
                                                    <Form.Item>
                                                        {getFieldDecorator(obj.name as string, {
                                                            initialValue: obj.value?.split('|').map((it) => { return it }) || obj.defaultValue,
                                                            rules: [{
                                                                required: obj.isRequireFields as boolean,
                                                                message: obj.label + ' is required'
                                                            }]
                                                        })(
                                                            <Checkbox.Group style={{ width: '100%' }}
                                                                disabled={disabledSaveButton}>
                                                                <Row>
                                                                    {
                                                                    obj.selectOption?.map((it, i) => {
                                                                        return <Col span={12} key={i}>
                                                                            <Checkbox value={it.option}>{it.option}</Checkbox>
                                                                        </Col>
                                                                    })
                                                                    }
                                                                </Row>
                                                            </Checkbox.Group>
                                                        )}
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                            {obj.inputType === 'DatePicker' ? (
                                                <>
                                                    <p><b className="MyText-form">{obj.isRequireFields ? (
                                                        <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                    </p>
                                                    <Form.Item>
                                                        {getFieldDecorator(obj.name as string, {
                                                            initialValue: obj.value || obj.defaultValue ? moment(obj.value) || moment(obj.defaultValue as string) : undefined,
                                                            rules: [{
                                                                required: obj.isRequireFields as boolean,
                                                                message: obj.label + ' is required'
                                                            }]
                                                        })(
                                                            <DatePicker style={{ width: '250px' }}
                                                                disabled={disabledSaveButton} />
                                                        )}
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                            {obj.inputType === 'DropdownSelect' ? (
                                                <>
                                                    <p><b className="MyText-form">{obj.isRequireFields ? (
                                                        <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                    </p>
                                                    <Form.Item>
                                                        {getFieldDecorator(obj.name as string, {
                                                            initialValue: obj.value || obj.defaultValue,
                                                            rules: [{
                                                                required: obj.isRequireFields as boolean,
                                                                message: obj.label + ' is required'
                                                            }]
                                                        })(
                                                            <Select style={{ width: '250px' }}
                                                                placeholder={obj.placeholder as string || undefined}
                                                                allowClear
                                                                disabled={disabledSaveButton}
                                                            >
                                                                {/* {obj.selectOption?.split('|').map((it, index) => {
                                                                return (
                                                                    <Option value={it as string}
                                                                        key={index}>{it}</Option>
                                                                )
                                                            })} */}
                                                                {
                                                                obj.selectOption?.map((it, i) => {
                                                                    return <Option value={it.option} key={i}>{it.option}</Option>
                                                                })
                                                                }
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                            {obj.inputType === 'InputNumber' ? (
                                                <>
                                                    <p><b className="MyText-form">{obj.isRequireFields ? (
                                                        <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                    </p>
                                                    <Form.Item>
                                                        {getFieldDecorator(obj.name as string, {
                                                            initialValue: Number(obj.value) || Number(obj.defaultValue),
                                                            rules: [
                                                                { type: (obj.dataType as string).toLowerCase() || undefined },
                                                                {
                                                                    required: obj.isRequireFields as boolean,
                                                                    message: obj.label + ' is required'
                                                                }
                                                            ]
                                                        })(
                                                            <InputNumber min={0} style={{ width: '250px' }}
                                                                disabled={disabledSaveButton}
                                                                placeholder={obj.placeholder as string || undefined} />
                                                        )}
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                            {obj.inputType === 'RadioButton' ? (
                                                <>
                                                    <p><b className="MyText-form">{obj.isRequireFields ? (
                                                        <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                    </p>
                                                    <Form.Item>
                                                        {getFieldDecorator(obj.name as string, {
                                                            initialValue: obj.value || obj.defaultValue,
                                                            rules: [{
                                                                required: obj.isRequireFields as boolean,
                                                                message: obj.label + ' is required'
                                                            }]
                                                        })(
                                                            <Radio.Group disabled={disabledSaveButton}>
                                                                {
                                                                obj.selectOption?.map((it, i) => {
                                                                    return <Radio value={it.option} key={i}>{it.option}</Radio>
                                                                })
                                                                }
                                                            </Radio.Group>
                                                        )}
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                            {obj.inputType === 'TextBox' ? (
                                                <>
                                                    <p><b className="MyText-form">{obj.isRequireFields ? (
                                                        <span className={'requiredIcon'}>* </span>) : null}{obj.label}</b>
                                                    </p>
                                                    <Form.Item>
                                                        {getFieldDecorator(obj.name as string, {
                                                            initialValue: obj.value || obj.defaultValue,
                                                            rules: [
                                                                { type: (obj.dataType as string).toLowerCase() || undefined },
                                                                {
                                                                    required: obj.isRequireFields as boolean,
                                                                    message: obj.label + ' is required'
                                                                }
                                                            ]
                                                        })(
                                                            <Input style={{ width: '250px' }}
                                                                placeholder={obj.placeholder as string || undefined}
                                                                disabled={disabledSaveButton}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </>
                                            ) : null}
                                        </Col>
                                    </Row>)
                            } else {
                                return <></>
                            }
                        })}
                    </>
                }

                <div style={{ float: 'right' }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit"
                            loading={isLoading}
                            disabled={disabledSaveButton || !checkRolesState(RoleType.Incident, 'UpdateIncident', props.incident?.createdBy)}>
                        Save
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Spin>
    )
}

const WrappedRegistrationForm = Form.create<Props>({ name: 'register' })(IncidentStatusUpdater)

export default connect(
    mapStateToProps,
    {
        searchIncidents,
        getAllCategory,
        getAllSubCategory,
        getAllItems,
        getAllSupportTeamByPermission,
        getAllImpact,
        getAllUrgency,
        updateIncident,
        getAllCustomFields: getAllCustomFields,
        getAllPriority
    }
)(WrappedRegistrationForm)
