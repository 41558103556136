import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux'
import {StoreState} from '../../store'
import {Breadcrumb, Button, Card, Col, Icon, message, Modal, Row, Switch, Table, Tooltip} from 'antd'
import {deleteSlaPolicy, getAllSlaPolicyWithPagination, updateSlaPolicy} from './service'
import {SlaPolicy} from './model'
import {FetchingState, fetchWithState, SpinningFetcher} from '../../common-components'
import {useLastLocation} from 'react-router-last-location'
import {UpdateSlaPolicyTableCurrentPageEvent, UpdateSlaPolicyTableTotalDataEvent} from '../../store/state-event'
import moment from 'moment'

const mapStateToProps = (state: StoreState) => {
    return {
        slaPolicies: state.slaPolicies,
        tablePagination: state.tablePagination,
        slaPoliciesPagination: state.slaPoliciesPagination
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    // getAllSlaPolicy: () => Promise<number>
    deleteSlaPolicy: (id: string) => Promise<number>
    updateSlaPolicy: (slaPolicy: SlaPolicy) => Promise<number>
    getAllSlaPolicyWithPagination: (page: number, pageSize: number) => Promise<number>
}

type Props = StateProps & DispatchProps

const SlaPolicyList: React.FC<Props> = (props: Props) => {
    const [fetchingState, setFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const { confirm } = Modal
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [loading, setLoading] = useState<boolean>(false)
    const [targetLoading, setTargetLoading] = useState<string>()
    const lastLocation = useLastLocation()
    const pageSize = 10

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Delete'} ><Icon className="del_icon" type="delete" onClick={() => deleteSla(row.id, row.activate)} /></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Edit'}><Link to={`/SlaPolicySettingFormEdit/${row.id}`} ><Icon className="edit_icon" type="edit" /></Link></Tooltip>
                </>
            )
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '5%',
            // eslint-disable-next-line react/display-name
            render: (row: any) => (
                <>
                    <Tooltip placement="bottom" title={'Clone'}><Link to={`/SlaPolicySettingFormClone/${row.id}`} ><Icon className="clone_icon" type="copy" /></Link></Tooltip>
                </>
            )
        },
        {
            title: 'Name',
            dataIndex: '',
            key: '',
            width: '30%',
            // eslint-disable-next-line react/display-name
            render: (slaPolicy: SlaPolicy) => (
                <>
                    <Tooltip placement="bottom" title={slaPolicy.name.length > 25 ? slaPolicy.name : null }>
                        <Link to={`/SlaPolicySettingFormView/${slaPolicy.id}`}>{slaPolicy.name.length > 25 ? (<>{slaPolicy.name.substring(0, 25) + ' ...'}</>) : (<>{slaPolicy.name}</>)}</Link>
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Description',
            dataIndex: '',
            key: '',
            width: '60%',
            ellipsis: true,
            // eslint-disable-next-line react/display-name
            render: (slaPolicy: SlaPolicy) => (
                <>
                    <span >{slaPolicy.description}</span>
                    <br />
                </>
            ),
            responsive: ['lg']
        },
        {
            title: 'Last Modified',
            dataIndex: '',
            key: '',
            width: '60%',
            ellipsis: true,
            // eslint-disable-next-line react/display-name
            render: (slaPolicy: SlaPolicy) => (
                <>
                    <span >{moment(slaPolicy.lastModifiedDatetime).format('YYYY-MM-DD HH:mm:ss')}</span>
                    <span style={{ display: 'flex', float: 'right' }}>
                        <Tooltip placement="bottom" title={slaPolicy.activate ? 'Deactivate' : 'Activate'}>
                            <Switch checked={slaPolicy.activate} onChange={() => toggleActivate(slaPolicy)} loading={targetLoading === slaPolicy.id ? loading : false}/>
                        </Tooltip>
                    </span>
                    <br />
                </>
            ),
            responsive: ['lg']
        }
    ]

    useEffect(() => {
        // fetchWithState(fetchingState, setFetchingState, props.getAllSlaPolicy, undefined, (err) => {
        //     message.error(`Failed fetching business hours. ${err}`)
        // })
        fetchWithState(fetchingState, setFetchingState, (): Promise<number> => {
            return props.getAllSlaPolicyWithPagination(1, pageSize)
        }, undefined, (err) => {
            message.error(`Failed fetching sla policies. ${err}`)
        })
        initialCurrentPage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.slaPolicies) {
            // go to last page when created new sla
            if (props.tablePagination.slaPolicyTableTotalData) {
                if (props.slaPolicies.length > props.tablePagination.slaPolicyTableTotalData) {
                    const c = 1/* Math.ceil(props.slaPolicies.length / pageSize) */
                    setCurrentPage(c)
                }
                dispatch(UpdateSlaPolicyTableTotalDataEvent.build(props.slaPolicies.length))
            } else {
                dispatch(UpdateSlaPolicyTableTotalDataEvent.build(props.slaPolicies.length))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.slaPolicies])

    useEffect(() => {
        fetch(currentPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    const fetch = (page: number) => {
        fetchWithState(FetchingState.NotStarted, setFetchingState,
            () => props.getAllSlaPolicyWithPagination(page, pageSize)
                .catch(() => message.error('Not found')))
    }

    const handleOnClickPageNumber = (page: number) => {
        dispatch(UpdateSlaPolicyTableCurrentPageEvent.build(page))
        setCurrentPage(page)
    }

    // go to current page when cancel or submit sla
    const initialCurrentPage = () => {
        const isPrevFromAllPageOfSlaPolicy = lastLocation?.pathname.search('/SlaPolicy')!! >= 0
        if (props.tablePagination.slaPolicyTableCurrentPage && (isPrevFromAllPageOfSlaPolicy || isPrevFromAllPageOfSlaPolicy === undefined)) {
            setCurrentPage(props.tablePagination.slaPolicyTableCurrentPage)
        }
    }

    const deleteSla = (id, activate): void => {
        try {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Delete this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    if (activate) {
                        message.warning('Please Deactivate.')
                    } else {
                        props.deleteSlaPolicy(id).then(() => {
                            message.success('This SLA Policy was deleted.')
                        }).catch(() => {
                            message.warning('This SLA Policy is being used by SLA Policies.')
                        })
                    }
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        } catch (error: any) {
            console.log(error)
        }
    }

    const toggleActivate = (slaPolicy: SlaPolicy): void => {
        const proceed = () => {
            setLoading(true)
            setTargetLoading(slaPolicy.id)
            props.updateSlaPolicy({ id: slaPolicy.id!!, activate: !slaPolicy.activate } as SlaPolicy)
                .catch((err) => {
                    if (err.status === 400) {
                        message.warning(err.data.message)
                    } else {
                        message.error(`Failed toggling SLA policy activate. ${err.data.message}`)
                    }
                }).finally(() => {
                    setLoading(false)
                    setTargetLoading(undefined)
                })
        }

        if (!slaPolicy.activate) {
            proceed()
        } else {
            confirm({
                title: 'Are you sure?',
                content: 'Do you confirm Deactivate this data?',
                okText: 'OK',
                okType: 'danger',
                autoFocusButton: 'cancel',
                cancelText: 'Cancel',
                async onOk() {
                    proceed()
                }
            })
        }
    }

    return (
        <div >
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>SLA Policy</Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Row>
                <Col span={24} >
                    <Card style={{ overflow: 'auto' }}>
                        <Row>
                            <Col span={12} lg={12} md={12} sm={12} xs={12}>
                                <h3 className={'main-title'}>SLA Policy</h3>
                            </Col>
                            <Col span={12} lg={12} md={12} sm={12} xs={12} style={{ textAlign: 'end' }}>
                                <Link to="/SlaPolicySettingForm" ><Button type="primary">Create</Button></Link>
                            </Col>
                        </Row>
                        <Row>
                            <p className={'content'}>Note: SLA Policy apply to ticket under this rule; Only SLA Policy created, activate, and modify before ticket created date will apply to the ticket.</p>
                        </Row>
                        <div >
                            <SpinningFetcher fetchingState={fetchingState}>
                                <Table
                                    dataSource={props.slaPolicies || []}
                                    columns={columns}
                                    rowKey="id"
                                    pagination={{
                                        pageSize: pageSize,
                                        current: currentPage,
                                        total: props.slaPoliciesPagination.total,
                                        onChange: (event) => { handleOnClickPageNumber(event) }
                                    }}
                                    scroll={{ x: 768 }}
                                />
                            </SpinningFetcher>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default connect(
    mapStateToProps,
    { getAllSlaPolicyWithPagination, deleteSlaPolicy, updateSlaPolicy }
)(SlaPolicyList)
