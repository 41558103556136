import {Button, Col, Form, Icon, message, Row} from 'antd'
import {FormComponentProps} from 'antd/es/form'
import React, {useEffect, useState} from 'react'
import {getPackageFreeTrial} from '../../TenantSetting/Services/Service'
import {packages} from '../../TenantSetting/Services/model'
import { Link } from 'react-router-dom'
interface Param {
    current: number
    setCurrent: Function
    freeUser: any
    setFreeUser: Function
}

type Props = Param & FormComponentProps
const Step1Free: React.FC<Props> = (props: Props) => {
    const [packageFreeTrial, setPackageFreeTrial] = useState<packages>()
    useEffect(() => {
        getPackageFreeTrial().then((res) => {
            if (res) {
                console.log(res)
                setPackageFreeTrial(res)
            }
        }).catch((err) => {
            message.error(err?.response?.message || 'Error get Packages Master')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSubmit = () => {
        props.freeUser.userRegistration = {}
        props.freeUser.packageRegister = packageFreeTrial?.id
        props.freeUser.durationDay = packageFreeTrial?.durationDay
        props.setFreeUser(props.freeUser)
        props.setCurrent(props.current + 1)
    }
    return (
        <div className={'step-content'}>
            <Row><b>Service that you can use {packageFreeTrial?.durationDay || 60} Days free trial.</b></Row>
            <br/>
            <Row><b>Service</b></Row>
            <Row style={{ paddingLeft: 24, lineHeight: '30px' }}>
                <Col span={12}>
                    <div><Icon type='appstore'/> Dashboard</div>
                </Col>
                <Col span={12}>
                    <div><Icon type='warning'/> Ticket Management</div>
                </Col>
                <Col span={12}>
                    <div><Icon type='laptop'/> Asset Management</div>
                </Col>
                <Col span={12}>
                    <div><Icon type='security-scan'/> Problem Management</div>
                </Col>
                <Col span={12}>
                    <div><Icon type='bulb'/> Knowledge Management</div>
                </Col>
                <Col span={12}>
                    <div><Icon type='profile'/> Work Order Management</div>
                </Col>
                <Col span={12}>
                    <div><Icon type='tool'/> Preventive Management</div>
                </Col>
            </Row>
            <br/>
            <Row><b>Features</b></Row>
            <Row style={{ paddingLeft: 24, lineHeight: '30px' }}>
                <Col span={12}>
                    <div><Icon type="user" /> 10 User in Goservice Access</div>
                </Col>
                <Col span={12}>
                    <div><Icon type="team" /> 5 User in Self-Portal Access</div>
                </Col>
                <Col span={12}>
                    <div><Icon type="cloud-server" /> File storage size 20 GB</div>
                </Col>
                <Col span={12}>
                    <div><Icon type="database" /> Database size 30 GB</div>
                </Col>
            </Row>
            {/* <Card title="Free Trial Package" bordered={true} style={{width: '80%', borderRadius: 5}}
                        className={'card-title'}>
                        <div>
                            <Row gutter={[8, 8]}>
                                {packageFreeTrial?.packageService.map((item, index) => {
                                    return <div key={index}>
                                        {index % 2 !== 0
                                            ? (<Col span={12}><span className={'ql-indent-1'}><Icon
                                                style={{color: '#1890ff'}}
                                                type="check-circle"/> {item.service.servicesName}</span></Col>)
                                            : (<Col span={12}><span className={'ql-indent-1'}><Icon type="check-circle"
                                                style={{color: '#1890ff'}}/> {item.service.servicesName}</span></Col>)
                                        }
                                    </div>
                                })}
                            </Row>
                        </div>
                        <Row style={{paddingTop: 10}}>
                            <Row><b>Limit User</b></Row>
                            <br/>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="user"
                                style={{color: '#1890ff'}}/> {packageFreeTrial?.quotaMaster.limitGoServiceUser} User</span></Col>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="team"
                                style={{color: '#1890ff'}}/> {packageFreeTrial?.quotaMaster.limitServicePortal} People</span></Col>
                        </Row>
                        <Row style={{paddingTop: 10}}>
                            <Row><b>Limit Storage</b></Row>
                            <br/>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="upload"
                                style={{color: '#1890ff'}}/> File storage size {packageFreeTrial?.quotaMaster.limitStorageSize} GB.</span></Col>
                            <Col span={12}><span className={'ql-indent-1'}><Icon type="database"
                                style={{color: '#1890ff'}}/> Database size {packageFreeTrial?.quotaMaster.limitDbSize} GB.</span></Col>
                        </Row>
                    </Card> */}

            <Row style={{paddingTop: '5%'}}>
                <Col span={24} style={{textAlign: 'end' }}>
                    <Link to='/'><Button type='default'>Back</Button></Link>
                    <Button type='primary' onClick={handleSubmit} style={{ marginLeft: 10 }}>Next</Button>
                </Col>
            </Row>
        </div>
    )
}

const MyStep1Free = Form.create<Props>({name: 'Step1Free'})(Step1Free)
export default MyStep1Free
