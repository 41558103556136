import React, { useState, useEffect } from 'react'
import TableSLAIncident from './TableSLAIncident'
import { Col, DatePicker, Form, Icon, Row, Select, Layout, Modal} from 'antd'
import {FormComponentProps} from 'antd/es/form'
// import * as XLSX from 'xlsx'
import {useLocation} from 'react-router-dom'
import { connect } from 'react-redux'
import { StoreState } from '../store'
import { getIncidentBacklog, getIncidentBacklogNOSLA } from './service'
import moment from 'moment'
// import { fixWidthExcel } from '../common-misc'
const mapStateToProps = (state: StoreState) => {
    return {
        DashboardIncidentBacklogMyTicket: state.DashboardIncidentBacklogMyTicket
    }
}

interface DispatchProps {
    getIncidentBacklog: (showBy, created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<any>
    getIncidentBacklogNOSLA: (showBy, created?, startDate?, endDate?, sortBy?, orderBy?, skip?, limit?) => Promise<any>
}

type StateProps = ReturnType<typeof mapStateToProps>
const { Header, Content } = Layout
const { confirm } = Modal
type Props = FormComponentProps & StateProps & DispatchProps
const { Option } = Select
const { RangePicker } = DatePicker
const ReportDashBoard: React.FC<Props> = (props: Props) => {
    const [show, setShow] = useState<string>('TotalGroup')
    const [created, setCreated] = useState<string>('last30Days')
    const [time, setTime] = useState<string[]>([])
    const [orderBy, setOrderBy] = useState<string>('asc')
    const [sortBy, setSortBy] = useState<string>('number')
    const [limit, setLimit] = useState<string>('100')
    // const [, setLoading] = useState<boolean>(false)
    const { getFieldDecorator, setFieldsValue } = props.form
    const location = useLocation()
    const params = new URLSearchParams(window.location.search)
    useEffect(() => {
        // setTimeout(() => {
        //     localStorage.clear()
        // }, 1000)
        if (params.get('group') != null) {
            const tempshow = params.get('group')?.replace(/\s/g, '')
            setShow(tempshow!!)
        }
        if (params.get('created') != null) {
            if (params?.get('created')?.includes(',')) {
                const splitTime = params?.get('created')?.split(',')
                if (splitTime) {
                    if (splitTime[0] !== '' && splitTime[1] !== '') {
                        setFieldsValue({
                            Created: 'anytime',
                            TimePeriod: splitTime
                        })
                        setCreated('anytime')
                        setTime(splitTime)
                    } else {
                        setCreated('anytime')
                        setTime([])
                    }
                }
            } else {
                setCreated(params?.get('created')!!)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(orderBy, sortBy, limit)
        setLimit(String(props.DashboardIncidentBacklogMyTicket.total))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.DashboardIncidentBacklogMyTicket.total])

    // const ExportCSV = () => {
    //     setLoading(true)
    //     if (location.pathname === '/ReportDashBoardNoneSLA') {
    //         genReportNOSLA(show, created, time[0], time[1], sortBy, orderBy, '0', limit).then((data) => {
    //             if (data.data.length !== 0) {
    //                 data.data.map((res) => {
    //                     return delete res.id
    //                 })
    //                 message.info('Please wait. The report is being created.')
    //                 const Heading = [['Ticket Number', 'Topic', 'Priority', 'Category', 'SubCategory', 'Item', 'Channel', 'Incident Status', 'Assignment group', 'Assigned to', 'Created Date']]
    //                 const wb = XLSX.utils.book_new()
    //                 const ws1 = XLSX.utils.json_to_sheet(data.data)
    //                 ws1['!cols'] = fixWidthExcel(data.data, ws1)
    //                 XLSX.utils.sheet_add_json(ws1, data.data, { origin: 'A2', skipHeader: true })
    //                 XLSX.utils.sheet_add_aoa(ws1, Heading)
    //                 XLSX.utils.book_append_sheet(wb, ws1, `Ticket Incident (${show})`)
    //                 XLSX.writeFile(wb, 'Ticket Incident.xlsx')
    //                 setLoading(false)
    //             } else {
    //                 message.warn('No tickets in this filter. Please change the filter.')
    //                 setLoading(false)
    //             }
    //         }).catch(() => {
    //             message.error('Failed to export data. Please try again later')
    //         })
    //     } else {
    //         genReport(show, created, time[0], time[1], sortBy, orderBy, '0', limit).then((data) => {
    //             if (data.data.length !== 0) {
    //                 message.info('Please wait. The report is being created.')
    //                 const Heading = [['Ticket Number', 'Topic', 'Priority', 'Category', 'SubCategory', 'Item', 'Channel', 'Incident Status', 'Assignment group', 'Assigned to', 'SLA Name', 'Sla Goal', 'Start Time', 'Due Date', 'SLA', 'Completed Time', 'Resolved Time']]
    //                 const wb = XLSX.utils.book_new()
    //                 const ws1 = XLSX.utils.json_to_sheet(data.data)
    //                 ws1['!cols'] = fixWidthExcel(data.data, ws1)
    //                 XLSX.utils.sheet_add_json(ws1, data.data, { origin: 'A2', skipHeader: true })
    //                 XLSX.utils.sheet_add_aoa(ws1, Heading)
    //                 XLSX.utils.book_append_sheet(wb, ws1, `SLA (${show})`)
    //                 XLSX.writeFile(wb, 'SLA.xlsx')
    //                 setLoading(false)
    //             } else {
    //                 message.warn('No tickets in this filter. Please change the filter. ')
    //                 setLoading(false)
    //             }
    //         }).catch(() => {
    //             message.error('Failed to export data. Please try again later')
    //         })
    //     }
    // }

    const changeShow = (e) => {
        setShow(e)
    }

    const changeCreated = (e) => {
        if (e !== 'anytime') {
            setTime([])
        }
        setCreated(e)
    }

    const changeTime = (value, dateString) => {
        setTime(dateString)
    }

    const getChangeTable = (sortBy, orderBy, skip, limit) => {
        if (sortBy) {
            setSortBy(sortBy)
        }
        if (orderBy) {
            setOrderBy(orderBy)
        }
        if (limit) {
            setLimit(limit)
        }
    }

    const leavePageModal = () => {
        confirm({
            title: 'Are you sure?',
            content: `You want to close this ${location.pathname.length < 17 ? 'SLA' : 'Incident'} ticket?`,
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                window.close()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 2, width: '100%', backgroundColor: '#fff', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
                <Row>
                    <Col span={12}>
                        <div>
                            <h3 style={{color: '#323276'}}>Ticket {location.pathname.length < 17 ? 'SLA' : 'Incident'} ({show})</h3>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ textAlign: 'end'}}>
                            <Icon type="close-square" theme="filled" style={{color: '#FA541C', fontSize: 24}} onClick={() => leavePageModal()} />
                        </div>
                    </Col>
                </Row>
            </Header>
            <Content style={{ padding: '0 50px', marginTop: 64, backgroundColor: '#fff' }}>
                <br/>
                <Row gutter={16} >
                    <Col xs={24} sm={24} md={20} lg={20} style={{ textAlign: 'left' }}>
                        <Form>
                            <Row gutter={16}>
                                {/* <Col span={18} xs={24} sm={24} md={18} lg={18} /> */}
                                <Col span={3} xs={24} sm={24} md={3} lg={3}>
                                    <span>Show :</span>
                                    <Form.Item>
                                        {getFieldDecorator('Show', {
                                            initialValue: show
                                        })(
                                            <Select onChange={(e) => changeShow(e)}>
                                                {/* <Option value="anytime">All</Option> */}
                                                <Option value="MyGroup">My Group</Option>
                                                <Option value="MyTicket">My Ticket</Option>
                                                <Option value="TotalGroup">Total Group</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={3} xs={24} sm={24} md={3} lg={3}>
                                    <span>Created :</span>
                                    <Form.Item >
                                        {getFieldDecorator('Created', {
                                            initialValue: created
                                        })(
                                            <Select onChange={(e) => changeCreated(e)}>
                                                <Option value="last30Days">Last 30 days</Option>
                                                <Option value="today">Today</Option>
                                                <Option value="yesterday">Yesterday</Option>
                                                <Option value="thisWeek">This Week</Option>
                                                <Option value="thisMonth">This Month</Option>
                                                <Option value="anytime">Select Time Period</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={6}>
                                    {created === 'anytime' ? (<>
                                        <Form.Item >
                                            {getFieldDecorator('TimePeriod', {
                                                initialValue: time.length !== 0 ? [moment(time[0], 'YYYY-MM-DD'), moment(time[1], 'YYYY-MM-DD')] : undefined
                                            })(
                                                <RangePicker
                                                    // showTime={{ format: 'HH:mm' }}
                                                    onChange={changeTime}
                                                    format="YYYY-MM-DD"
                                                    placeholder={['Start Time', 'End Time']}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                />
                                            )}
                                        </Form.Item>
                                    </>) : null}
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} style={{ textAlign: 'right' }}>
                        {/* <Button loading={loading} className="addCriteriaButton" style={{ marginTop: 25 }} onClick={() => ExportCSV()} htmlType="button"
                            // disabled={!checkRolesState(RoleType.Incident, 'ViewIncident')}
                            disabled={true}
                        > {!loading ? <Icon type="upload" /> : null}Export</Button> */}
                    </Col>
                </Row>
                <br />
                <Row className="TopicDashboard">
                    <Col span={24}>
                        <span className="main-title">{location.pathname.length < 17 ? 'SLA' : 'Ticket Incident'} ({show})</span>
                    </Col>
                </Row>
                <br />
                <Row className='fix_scroll_md'>
                    <TableSLAIncident show={show} create={created} start={time[0]} end={time[1]} getChangeTable={getChangeTable} />
                </Row>
            </Content>

        </Layout>
    )
}

const WrappedNormalReportDashBoard = Form.create({ name: 'report' })(ReportDashBoard)

export default connect(mapStateToProps, {
    getIncidentBacklog, getIncidentBacklogNOSLA
})(WrappedNormalReportDashBoard)
