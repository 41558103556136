import {Dispatch} from 'redux'
import {
    AddIncidentViewSuccessEvent,
    CurrentViewCriteriaChangedEvent,
    DeleteIncidentViewSuccessEvent,
    GetCurrentIncidentViewSuccessEvent,
    SetCurrentIncidentViewSuccessEvent,
    UpdateIncidentViewSuccessEvent
} from './state-event'
import {IncidentFilter, IncidentView} from './model'
import {axiosGet, axiosPatch, axiosPost, axiosDelete, axiosPatchWithOutBearer} from '../rest'
import {IncidentViewState} from './state-model'
import {checkRedirect, herderPostPatch} from '../../common-misc'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'

export const getCurrentIncidentView = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<IncidentViewState>('/api/views/current-views')
            const tempData: IncidentViewState = decryptBody(response.data.data!!)
            tempData.isNotFetchingData = false
            dispatch(GetCurrentIncidentViewSuccessEvent.build(tempData))
            return response.status
        } catch (err: any) {
            console.error(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const setCurrentIncidentView = (viewName: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPatch<IncidentViewState>(`/api/views/current/${viewName}`, null,
                herderPostPatch)
            const tempData: IncidentViewState = decryptBody(response.data.data!!)
            tempData.isNotFetchingData = true
            dispatch(SetCurrentIncidentViewSuccessEvent.build(tempData))
            // dispatch(SetCurrentIncidentViewSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.error(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export interface AddIncidentViewParams {
    name: string
    incFilter: IncidentFilter
    visibility: string
}

export const addIncidentView = (params: AddIncidentViewParams) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify({...params}))
            const response = await axiosPost<IncidentView>('/api/views', encryptData, herderPostPatch)
            const tempData: IncidentView = decryptBody(response.data.data!!)
            tempData.isNotFetchingData = true
            dispatch(AddIncidentViewSuccessEvent.build(tempData || decryptBody(encryptData)))
            // dispatch(AddIncidentViewSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}
export const updateIncidentView = (incidentView: IncidentView) => {
    return async (dispatch: Dispatch) => {
        try {
            const {id, name, criteria, visibility, incFilter} = incidentView
            const data = {name, criteria, visibility, incFilter}
            // eslint-disable-next-line no-self-assign
            data.incFilter ? data.criteria = data.criteria : data.criteria = incFilter
            const encryptData = encryptBody(JSON.stringify(data))
            const response = await axiosPatch<IncidentView>(`/api/views/${id}`, encryptData, herderPostPatch)
            const tempData: IncidentView = decryptBody(response.data.data!!)
            tempData.isNotFetchingData = true
            dispatch(UpdateIncidentViewSuccessEvent.build(tempData || decryptBody(encryptData)))
            // dispatch(UpdateIncidentViewSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const notifyCurrentViewCriteriaChanged = (criteria: IncidentFilter) => {
    return async (dispatch: Dispatch) => {
        dispatch(CurrentViewCriteriaChangedEvent.build(criteria))
    }
}

// export const setIncidentSorting = async (sortBy: string, orderBy: string) => {
//     try {
//         const encryptData = encryptBody(JSON.stringify({sortBy, orderBy}))
//         const res = await axiosPost<IncidentViewState>('/api/views/setCurrentSorting', encryptData, herderPostPatch)
//         return decryptBody(res.data.data!!)
//     } catch (err: any) {
//         console.log(err)
//         checkRedirect(err.response.status)
//         throw err
//     }
// }

export const newUserView = async (secretCode: string, tenantID: string) => {
    try {
        const res = await axiosPatchWithOutBearer<number>(`/api/views/user/${secretCode}`, null, {
            headers: {
                // userId: userInfo.id,
                // username: userInfo.username,
                tenantID: tenantID
            }
        })
        return res.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const DeleteIncidentView = (incidentViewIds: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                ids: incidentViewIds
            }
            const response = await axiosDelete<IncidentView>('/api/views/list', {params})
            // const tempData: IncidentView = decryptBody(response.data.data!!)
            const convertModel: any[] = []
            incidentViewIds.split(',').map((it) => {
                return convertModel.push({id: Number(it)})
            })

            // tempData.isNotFetchingData = false
            dispatch(DeleteIncidentViewSuccessEvent.build(convertModel))
            // dispatch(UpdateIncidentViewSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err.response.status)
            throw err
        }
    }
}
