import Compress from 'compress.js'
import { postUploadFile, getFileUrl } from './service'
// import { UploadLink } from './model'
import { tokenDecode } from '../common-misc'
import { pmUploadLink } from '../Preventive/Model'
import { UploadLink } from './modelPmUpload'
import moment from 'moment'
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

const compress = new Compress()
export default class PM_FSS {
  public static putFile = (
      fileList: pmUploadLink[],
      path: string,
      callBack: Function
  ) => {
      const uploadedList: UploadLink[] = []
      const tenantPath = tokenDecode()?.tenantID || '0' + path

      function isBase64(str) {
          if (str === '' || str.trim() === '') {
              return false
          }
          try {
              return btoa(atob(str)) === str
          } catch (err: any) {
              return false
          }
      }

      function dataURItoBlob(dataURI) {
          let byteString
          if (dataURI?.split(',')[0].indexOf('base64') >= 0) {
              // Split into two parts
              let uri = dataURI.split(',')[1]
              // console.log('uri', uri)
              // const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
              // Decode Base64 string
              // console.log(base64regex.test(uri))
              if (typeof uri !== 'string') {
                  uri = uri.toString()
              }

              if (isBase64(uri)) {
                  byteString = atob(uri)
              } else {
                  byteString = atob(uri.split('"')[0])
              }
          } else {
              byteString =
          dataURI !== null
              ? window.decodeURI(dataURI.split(',')[1])
              : undefined
          }

          const mimeString =
        dataURI !== null
            ? dataURI.split(',')[0].split(':')[1].split(';')[0]
            : undefined

          const ia = new Uint8Array(byteString?.length)
          for (let i = 0; i < byteString?.length; i++) {
              ia[i] = byteString.charCodeAt(i)
          }
          return new Blob([ia], { type: mimeString })
      }

      function dataURLtoFile(dataUrl, filename) {
          const arr = dataUrl?.split(',')
          if (arr) {
              const mime = arr[0]?.match(/:(.*?);/)[1]
              const bstr = atob(arr[1])
              let n = bstr.length
              const u8arr = new Uint8Array(n)

              while (n--) {
                  u8arr[n] = bstr.charCodeAt(n)
              }
              return new File([u8arr], filename, { type: mime })
          }
      }

      function uploadFile(formData: FormData, file: pmUploadLink) {
          const fileName = file.name?.split('.').shift()
          const fileExt = file.name?.split('.').pop()
          const now = moment().format('YYYYMMDD')
          postUploadFile(formData)
              .then(() => {
                  i += 1
                  const newPath = `${getFileUrl(`/${tenantPath}/${now}/${path}/${fileName}-${tenantPath}`.replace(/ /g, '_'))}${
                      file.uid
                  }.${fileExt}`
                  uploadedList.push(new UploadLink(file.name!!, newPath, file.type!!, file.customPictureId!!.toString()))
                  if (i === fileList.length) {
                      callBack(uploadedList)
                  }
              })
              .catch((err) => {
                  console.log(err.code)
                  console.log(err.message)
                  callBack()
              })
      }

      let i: number = 0
      fileList.forEach((file: pmUploadLink) => {
          const fileName = file.name?.split('.').shift()
          const fileExt = file.name?.split('.').pop()
          if (file.url && !file.url.includes('data:')) {
              i += 1
              uploadedList.push(new UploadLink(file.name!!, file.url!!, file.type!!, file.customPictureId!!.toString()))
              if (i === fileList.length) {
                  callBack(uploadedList)
              }
          } else {
              if (file.size <= fileSize || tenantPath.includes('signature')) {
                  const blob = dataURItoBlob(file.url)
                  const fd = new FormData(document.forms[0])
                  const now = moment().format('YYYYMMDD')
                  fd.append('path', `/${tenantPath}/${now}/${path}/${fileName}-${tenantPath}${file.uid}.${fileExt}`.replace(/ /g, '_'))
                  fd.append('file', blob)
                  uploadFile(fd, file)
              } else {
                  if (file.url) {
                      const originalFile = dataURLtoFile(file.url, fileName)
                      if (originalFile) {
                          compress.compress([originalFile], {
                              size: 1, // the max size in MB, defaults to 2MB
                              quality: 0.75, // the quality of the image, max is 1,
                              maxWidth: 1280, // the max width of the output image, defaults to 1920px
                              maxHeight: 1280, // the max height of the output image, defaults to 1920px
                              resize: true // defaults to true, set false if you do not want to resize the image width and height
                          })
                              .then((data) => {
                                  const img = data[0]
                                  const base64str = img.data
                                  const imgExt = img.ext
                                  const resizedFile = Compress.convertBase64ToFile(
                                      base64str,
                                      imgExt
                                  )
                                  const fd = new FormData(document.forms[0])
                                  const now = moment().format('YYYYMMDD')
                                  fd.append(
                                      'path',
                                      `/${tenantPath}/${now}/${path}/${fileName}-${tenantPath}${file.uid}.${fileExt}`.replace(/ /g, '_')
                                  )
                                  fd.append('file', resizedFile)
                                  uploadFile(fd, file)
                              })
                      }
                  } else {
                      callBack()
                  }
              }
          }
      })
  };
}
