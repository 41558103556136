import React from 'react'
// eslint-disable-next-line
import './App.css'
import 'react-quill/dist/quill.snow.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {LastLocationProvider} from 'react-router-last-location'
// Layout
import Sidebar from './msp-portal/Sidebar'
// Page
import Login from './msp-portal/Login'
import Home from './msp-portal/Home'
import Setting from './sla-management/setting/settingDash'
import { PrivateRoute, PrivateRouteNoSidebar} from './guard/route'
import IncidentForm from './incident-management/incident/IncidentForm'
import IncidentList from './incident-management/incident/IncidentList'
import SlaGlobalSettingPage from './sla-management/sla-global-setting/SlaGlobalSettingPage'
import SlaPolicyList from './sla-management/sla-policy/SlaPolicyList'
import SlaPolicyForm from './sla-management/sla-policy/SlaPolicyForm'
import BusinessHourList from './sla-management/business-hour/BusinessHourList'
import BusinessHourForm from './sla-management/business-hour/BusinessHourForm'
import IncidentDetailsView from './incident-management/incident/IncidentDetailsView'
import KnowledgeHome from './knowledge-management/KnowledgeHome'
import KnowledgeForm from './knowledge-management/knowledge/KnowledgeForm'
import KnowledgePreview from './knowledge-management/knowledge/KnowledgePreview'
import ApprovalHome from './knowledge-management/approval/ApprovalHome'
import UserRole from './authorization-module/user-role/UserRole'
import UserRoleForm from './authorization-module/user-role/UserRoleForm'
import SupportTeamList from './authorization-module/support-team-management/SupportTeamList'
import SupportTeamForm from './authorization-module/support-team-management/SupportTeamForm'
import SlaCategorySettingPage from './sla-management/sla-category-setting/SlaCategorySettingPage'
import PriorityConfigurationPage from './incident-management/priority/PriorityConfigurationPage'
import RoleAndPermissionsForm from './authorization-module/role-and-permissions/RoleAndPermissionsForm'
import RoleAndPermissionsList from './authorization-module/role-and-permissions/RoleAndPermissionsList'
import GeneralIncidentSettingForm from './incident-management/general-incident-setting/GeneralIncidentSettingForm'
import ConfigurationFiledList from './configurable-fields/ConfigurableFiledList'
import ForgotPassword from './authorization-module/user-role/ForgotPassword'
import UserConfirmation from './authorization-module/user-role/UserConfirmation'
import ReportDashBoard from './msp-portal/ReportDashBoard'
import ReportSatDashBoard from './msp-portal/ReportSatDashBoard'
import WoList from './msp-wo/wo-management/WoList'
import WoForm from './msp-wo/wo-management/WoForm'
import AssetList from './msp-wo/asset-management/AssetList'
import AssetForm from './msp-wo/asset-management/AssetForm'
import ReportAsset from './msp-wo/asset-management/Reports/ReportAsset'
import SelectLocationForPrint from './msp-wo/preventive/SelectLocationForPrint'
import AssetsForSign from './msp-wo/preventive/signature/AssetsForSign'
import ConfirmLogin from './authorization-module/vSpace-login/ConfirmLogin'
import VspaceLogin from './authorization-module/vSpace-login/VspaceLogin'
import SatisfactionDone from './incident-management/incident/SatisfactionDone'
import peopleManagement from './authorization-module/people/peopleManagement'
import PreventiveList from './Preventive/PreventiveList'
import CompletePM from './Preventive/CompletePM'
import CompletePMForm from './Preventive/CompletePMForm'
import Report from './ReportComponent/Report'
import PMSteps from './Preventive/PMSteps'
import AssetPreviewTab from './msp-wo/asset-management/AssetPreviewTab'
import FreeRegister from './msp-portal/FreeRegister'
import LoadingPage from './msp-portal/components/LoadingPage'
import CatalogHome from './serviceCatalog/Catalog/CatalogHome'
import CatalogView from './serviceCatalog/Catalog/components/CatalogView'
import ItemForm from './serviceCatalog/Catalog/components/Items/ItemForm'
import MyCategoryView from './serviceCatalog/CategoryTab/CategoryView'
import MyTenantProfile from './TenantSetting/TenantProfile'
import SubscriptionList from './TenantSetting/SubscriptManagement'
// import PackageDetail from './TenantSetting/Components/PackageDetail'
import OrderDetail from './TenantSetting/Components/OrderDetail'
import MyPayInvoice from './TenantSetting/Components/PayInvoice'
import MyPaymentConfirmation from './TenantSetting/Components/PaymentConfirmation'
import PageExpiredMail from './msp-portal/PageExpiredMail'
import WorkFlowHome from './serviceCatalog/workflow/WorkFlowHome'
import WorkFlowForm from './serviceCatalog/workflow/Components/WorkFlowForm'
import MyDrawflow from './serviceCatalog/workflow/Components/FlowComponent/MyDrawflow'
import NotFound from './msp-portal/Page404'
import CompanySetting from './sla-management/setting/Company&Department/CompanyDepartmentSetting'
import NewHome from './msp-portal/NewHome'
import IncidentListEnhance from './incident-management/incident/IncidentListEnhance'
import ReportHomeForm from './incident-management/Reports/ReportHome'
import PriortyConfigPage from './problem-management/priority/PriortyConfigPage'
// import GeneralProblem from './problem-management/general/GeneralProblem'
import ProblemsManagemant from './problem-management/problems/ProblemsManagemant'
import ProblemsForm from './problem-management/problems/ProblemsForm'
import HomeProblemSetting from './problem-management/HomeProblemSetting'
import ProblemsDetails from './problem-management/problems/ProblemsDetails'
import ViewPTask from './problem-management/problems/ViewPTask'
import GeneralAsset from './asset-management/GeneralAsset'
import WoDashboard from './msp-wo/wo-management/WoDashboard'
import WoReport from './msp-wo/wo-management/WoReport'
import PackageDetailNew from './TenantSetting/NewComponents/PackageDetailNew'
// import Page403 from ''

// PrivateRoute

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/account/forgotPassword" component={ForgotPassword} />
                <Route path="/account/confirmation/:secretCode/:tenantID" component={UserConfirmation} />
                <Route path="/satisfaction/:incidentId/:result/:data" component={SatisfactionDone} />
                <Route exact path="/" component={Login} />
                <Route path='/expired' component={PageExpiredMail} />
                <Route path="/vSpaceLogin/:id/:token/:incidentNo" component={ConfirmLogin} />
                <Route path="/vSpaceLogin/:id/:token" component={ConfirmLogin} />
                <Route path="/vSpaceLogin" component={VspaceLogin} />
                <Route path="/freeRegister" component={FreeRegister} />
                <Route path="/register/confirmation/:secretCode" component={LoadingPage} />
                <PrivateRouteNoSidebar path="/IncidentDetailsView/:id" component={IncidentDetailsView} />
                <PrivateRouteNoSidebar path="/ReportDashBoard" component={ReportDashBoard} />
                <PrivateRouteNoSidebar path="/ReportDashBoardNoneSLA" component={ReportDashBoard} />
                <PrivateRouteNoSidebar path="/ReportSatDashBoard" component={ReportSatDashBoard} />
                <PrivateRouteNoSidebar path="/WorkflowFormView/:id" component={MyDrawflow} />
                <PrivateRouteNoSidebar path="/KnowledgeRelatedPreview/:id/:related" component={KnowledgePreview} />
                <PrivateRouteNoSidebar path="/ProblemTaskDetailView/:id" component={ViewPTask} />
                <PrivateRoute path="/Home" component={NewHome} />
                <Route >
                    <Switch>
                        <LastLocationProvider>
                            <Sidebar>
                                <PrivateRoute exact path="/WoList" component={WoList} />
                                <PrivateRoute path="/WoForm" component={WoForm} />
                                <PrivateRoute path="/WoDashboard" component={WoDashboard} />
                                <PrivateRoute path="/WoReport" component={WoReport} />
                                <PrivateRouteNoSidebar path="/WoFormEdit/:id" component={WoForm} />
                                <PrivateRoute path="/assetList" component={AssetList} />
                                <PrivateRoute path="/assetForm" component={AssetForm} />
                                <PrivateRoute path="/reportAsset" component={ReportAsset} />
                                <PrivateRoute path="/assetPreview/:id/:serialNo" component={AssetPreviewTab} />
                                <PrivateRoute path="/Preventive" component={PreventiveList} />
                                <PrivateRoute path="/CompletePM" component={CompletePM} />
                                <PrivateRoute path="/CompletePMFormView/:id/:serialNo" component={CompletePMForm} />
                                <PrivateRoute path="/CompletePMForm/:id/:serialNo" component={CompletePMForm} />
                                <PrivateRoute path="/Report" component={Report} />
                                <PrivateRouteNoSidebar path="/pmStep/:id" component={PMSteps} />
                                <PrivateRoute path="/locationForSign" component={AssetsForSign} />
                                <PrivateRoute path="/SelectLocationForPrint" component={SelectLocationForPrint} />
                                <PrivateRoute path="/dashboard-all" component={Home} />
                                <PrivateRoute path="/IncidentList" component={IncidentListEnhance} />
                                <PrivateRoute path="/IncidentList1" component={IncidentList} />
                                <PrivateRoute path="/IncidentReport" component={ReportHomeForm} />
                                <PrivateRoute path="/IncidentForm" component={IncidentForm} />
                                <PrivateRoute path="/Setting" component={Setting} />
                                <PrivateRoute path="/GlobalSetting" component={SlaGlobalSettingPage} />
                                <PrivateRoute path="/CategorySetting" component={SlaCategorySettingPage} />
                                <PrivateRoute path="/asset-general" component={GeneralAsset} />
                                <PrivateRoute path="/PrioritySetting" component={PriorityConfigurationPage} />
                                <PrivateRoute path="/problem-priority" component={PriortyConfigPage} />
                                <PrivateRoute path="/problem-general" component={HomeProblemSetting} />
                                <PrivateRoute path="/ProblemList" component={ProblemsManagemant} />
                                <PrivateRoute path="/ProblemForm" component={ProblemsForm} />
                                <PrivateRouteNoSidebar path="/ProblemDetailView/:id" component={ProblemsDetails} />
                                <PrivateRoute path="/SlaPolicySettingList" component={SlaPolicyList} />
                                <PrivateRoute path="/SlaPolicySettingForm" component={SlaPolicyForm} />
                                <PrivateRoute path="/SlaPolicySettingFormView/:id" component={SlaPolicyForm} />
                                <PrivateRoute path="/SlaPolicySettingFormEdit/:id" component={SlaPolicyForm} />
                                <PrivateRoute path="/SlaPolicySettingFormClone/:id" component={SlaPolicyForm} />
                                <PrivateRoute path="/BusinessHourSettingList" component={BusinessHourList} />
                                <PrivateRoute path="/BusinessHourSettingForm" component={BusinessHourForm} />
                                <PrivateRoute path="/BusinessHourSettingFormView/:id" component={BusinessHourForm} />
                                <PrivateRoute path="/BusinessHourSettingFormEdit/:id" component={BusinessHourForm} />
                                <PrivateRoute path="/BusinessHourSettingFormClone/:id" component={BusinessHourForm} />
                                <PrivateRoute exact path="/Knowledge" component={KnowledgeHome} />
                                <Switch>
                                    <PrivateRoute path="/KnowledgeForm/:categoryId/:folderId" component={KnowledgeForm} />
                                    <PrivateRoute path="/KnowledgeForm/:categoryId" component={KnowledgeForm} />
                                    <PrivateRoute path="/KnowledgeForm" component={KnowledgeForm} />
                                </Switch>
                                <PrivateRoute path="/KnowledgeFormEdit/:id" component={KnowledgeForm} />
                                <PrivateRoute path="/KnowledgePreview/:id" component={KnowledgePreview} />
                                <PrivateRoute path="/ApprovalCenter" component={ApprovalHome} />
                                <PrivateRoute path="/ApprovalPreview/:id" component={KnowledgePreview} />
                                <PrivateRoute path="/SupportTeamSettingList" component={SupportTeamList} />
                                <PrivateRoute path="/SupportTeamSettingForm/:id/:action" component={SupportTeamForm} />
                                <PrivateRoute path="/UserSetting" component={UserRole} />
                                <PrivateRoute path="/UserSettingForm" component={UserRoleForm} />
                                <PrivateRoute path="/UserSettingPreview/:id/:peopleIdParam/:usernameParam/:role" component={UserRoleForm} />
                                <PrivateRoute path="/UserSettingEditForm/:id/:peopleIdParam/:usernameParam/:role" component={UserRoleForm} />
                                <PrivateRoute path="/PeopleSetting" component={peopleManagement} />
                                <PrivateRoute path="/RoleAndPermissionsSettingList" component={RoleAndPermissionsList} />
                                <PrivateRoute path="/RoleAndPermissionsSettingForm" component={RoleAndPermissionsForm} />
                                <PrivateRoute path="/RoleAndPermissionsSettingEditForm/:id" component={RoleAndPermissionsForm} />
                                <PrivateRoute path="/RoleAndPermissionsSettingPreview/:id" component={RoleAndPermissionsForm} />
                                <PrivateRoute path="/GeneralIncidentSettingForm" component={GeneralIncidentSettingForm} />
                                <PrivateRoute path="/ConfigurationFiledSettingList" component={ConfigurationFiledList} />
                                <PrivateRoute path="/CompanySetting" component={CompanySetting}/>
                                <PrivateRoute path="/CatalogList" component={CatalogHome} />
                                <PrivateRoute path="/CatalogView/:id" component={CatalogView} />
                                <PrivateRoute path="/ItemCatalogView/:id" component={ItemForm} />
                                <PrivateRoute path="/ItemCatalog" component={ItemForm} />
                                <PrivateRoute path="/CategoryView/:id" component={MyCategoryView} />
                                <PrivateRoute path="/WorkflowHome" component={WorkFlowHome} />
                                <PrivateRoute path="/WorkflowForm" component={WorkFlowForm} />
                                <PrivateRoute path="/WorkflowFormUpdate/:id" component={WorkFlowForm} />
                                <PrivateRoute path="/TenantProfile" component={MyTenantProfile} />
                                <PrivateRoute path="/SubscriptionList" component={SubscriptionList} />
                                {/* <PrivateRoute path="/PackageDetail" component={PackageDetail} /> old */}
                                <PrivateRoute path="/PackageDetail" component={PackageDetailNew} />
                                <PrivateRoute path="/OrderDetail/:id" component={OrderDetail} />
                                <PrivateRoute path="/PayInvoice/:id" component={MyPayInvoice} />
                                <PrivateRoute path="/PaymentConfirmation/:id" component={MyPaymentConfirmation} />
                                <Route path="*" component={NotFound} />
                            </Sidebar>
                        </LastLocationProvider>
                    </Switch>
                </Route>
            </Switch>
        </Router>
    )
}

export default App
