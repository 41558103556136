import { checkRedirect, herderPostPatch } from '../../../common-misc'
import { decryptBody, encryptBody } from '../../../common-misc/encryptBody'
import { axiosGet, axiosPost } from '../../rest'
import { generalProblem, ProblemType } from './model'

export async function getAllGeneralProblems(problemType: string) {
    try {
        const response = await axiosGet<generalProblem>(`/api/general-settings/problem-types?id=${Number(problemType)}&showAll=false`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err.response.status)
        throw err
    }
}

export async function addOrUpdateGeneralProblems(convertData: generalProblem) {
    try {
        const encryptData = encryptBody(JSON.stringify(convertData))
        const response = await axiosPost<ProblemType[]>('/api/general-settings/close-statuses', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err.response.status)
        throw err
    }
}

export async function addAllTypeProblems() {
    try {
        const response = await axiosGet<ProblemType[]>('/api/problem-types')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err.response.status)
        throw err
    }
}

export async function getProblemCounting() {
    try {
        const response = await axiosGet<ProblemType[]>('/api/priority-levels/problem-counting')
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        checkRedirect(err.response.status)
        throw err
    }
}
