/* eslint-disable react/jsx-no-undef */
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {StoreState} from '../../store'
import {Button, Card, Col, Drawer, Dropdown, Form, Icon, Input, Menu, message, Row, Spin, Table, Modal} from 'antd'
import {Link} from 'react-router-dom'
import IncidentFilterForm from '../incident-view/IncidentFilterForm'
import IncidentRow from './IncidentRow'
import {FetchingState, fetchWithState, SpinningFetcher} from '../../common-components'
import {Incident, IncidentFieldLabel, IncidentWithSlaCalculations} from './index'
import {
    getCurrentIncidentView,
    IncidentFilter,
    notifyCurrentViewCriteriaChanged,
    UserChangeOrderByEvent,
    UserChangeSortByEvent
} from '../incident-view'
import { searchIncidents, updateIncident} from './service'
import {getAllSupportTeamByPermission} from '../support-team'
import {getAllPriority} from '../priority'
import {getAllStatus} from '../status'
import {getAllCategory} from '../category'
import {FormComponentProps} from 'antd/es/form'
import {ForIncident} from '../../sla-management/sla-calculation/service'
import {getVersion, notEmpty, resolveOrderDirectionLabel} from '../../common-misc'
import moment from 'moment'
import {IncidentWebSocketChannel, SLAWebSocketChannel} from './web-socket-channel'
import {checkRolesState, RoleType} from '../../authorization-module/permissions'
import {getAllGeneralIncidentSetting} from '../general-incident-setting/service'
// import * as XLSX from 'xlsx'
import CloneTicketList from './CloneTicketList'

const mapStateToProps = (state: StoreState) => {
    return {
        categories: state.categories,
        statuses: state.statuses,
        supportTeamsWithPermission: state.supportTeamsWithPermission,
        incidentViewState: state.incidentViewState,
        criteria: state.incidentViewState.currentView?.criteria,
        priority: state.priorities,
        incidents: state.incidentState?.incidents || [],
        tablePagination: state.tablePagination.incidentsTotal,
        userRolesAndPermissions: state.userRolesAndPermissions,
        permission: state.currentUserRole,
        channels: state.generalIncidentSetting.channels,
        ticketTypes: state.generalIncidentSetting.ticketTypes
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    searchIncidents: (criteria: IncidentFilter, sortBy: string, orderBy: string, skip: number, limit: number) => Promise<number>
    updateIncident: (incident: Incident) => Promise<number>
    getAllStatus: (status: string | undefined) => Promise<number>
    getAllSupportTeamByPermission: () => Promise<number>
    getAllCategory: () => Promise<number>
    notifyCurrentViewCriteriaChanged: (criteria: IncidentFilter) => Promise<void>
    getCurrentIncidentView: () => Promise<number>
    getAllPriority: () => Promise<number>
    getAllSlaCalculationByTicketIds: (ticketIds: number[]) => Promise<number>
    getAllGeneralIncidentSetting: () => Promise<number>
}

type Props = StateProps & DispatchProps & FormComponentProps
const { confirm } = Modal
const IncidentList: React.FC<Props> = (props: Props) => {
    const [incidentViewStateFetchingState, setIncidentViewStateFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [incidentsFetchingState, setIncidentsFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [slaCalculationsFetchingState, setSlaCalculationsFetchingState] = useState<FetchingState>(FetchingState.NotStarted)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isMedium, setIsMedium] = useState<boolean>(false)
    const [isNonDesktop, setIsNonDesktop] = useState<boolean>(false)
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
    // const [, setExportLoading] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingIncident, setIsLoadingIncident] = useState<boolean>(false)
    const [isVisibleTemplete, setIsVisibleTemplete] = useState<boolean>(false)
    const {Search} = Input

    const dispatch = useDispatch()
    const limit: number = 10

    useEffect(() => {
        IncidentWebSocketChannel.subscribe()
        SLAWebSocketChannel.subscribe()
        // Fetch dropdown fields options
        props.getAllStatus('all').catch((err) => message.error(`Failed fetching all status. ${err}`))
        props.getAllSupportTeamByPermission().catch((err) => message.error(`Failed fetching all support team. ${err}`))
        props.getAllCategory().catch((err) => message.error(`Failed fetching all category. ${err}`))
        props.getAllPriority().catch((err) => message.error(`Failed fetching all priority. ${err}`))
        props.getAllGeneralIncidentSetting().catch((err) => message.error(`Failed fetching all general ticket setting. ${err}`))
        // get current view and search incident by view
        fetchWithState(incidentViewStateFetchingState, setIncidentViewStateFetchingState, props.getCurrentIncidentView)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     setIncidentsFetchingState(FetchingState.NotStarted)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [getVersion(props.incidentViewState), props.incidentViewState.sortBy, props.incidentViewState.orderBy, currentPage])

    useEffect(() => {
        if (incidentsFetchingState === FetchingState.NotStarted) {
            if (props.criteria || props.incidentViewState?.currentView?.incFilter) {
                // const f = function () {
                //     return props.searchIncidents(props.criteria || props.incidentViewState?.currentView?.incFilter!!, props.incidentViewState.sortBy!!, props.incidentViewState.orderBy!!, (currentPage - 1) * limit, limit)
                //         .then((res) => {
                //             setSlaCalculationsFetchingState(FetchingState.NotStarted)
                //             setIsLoading(false)
                //         })
                //         .catch((err) => message.error(`Failed searching for incidents. ${err}`))
                // }
                // fetchWithState(incidentsFetchingState, setIncidentsFetchingState, f)
                setSlaCalculationsFetchingState(FetchingState.Started)
                setIsLoadingIncident(true)
                props.searchIncidents(props.criteria || props.incidentViewState?.currentView?.incFilter!!, props.incidentViewState.currentView?.incFilter.sortBy!!, props.incidentViewState.currentView?.incFilter.orderBy!!, (currentPage - 1) * limit, limit)
                    .catch((err) => message.error(`Failed searching for incidents. ${err}`)).finally(() => {
                        setSlaCalculationsFetchingState(FetchingState.NotStarted)
                        setIsLoading(false)
                        setIsLoadingIncident(false)
                    })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [incidentsFetchingState, props.criteria, getVersion(props.incidentViewState), currentPage])

    useEffect(() => {
        if (props.criteria || props.incidentViewState?.currentView?.incFilter) {
            setCurrentPage(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.criteria, props.incidentViewState?.currentView?.incFilter])

    useEffect(() => {
        if (slaCalculationsFetchingState === FetchingState.NotStarted) {
            const f = async () => {
                IncidentWebSocketChannel.subscribeToIncidentIds(props.incidents.map((it) => it.id!!))
                SLAWebSocketChannel.subscribeToIncidentIds(props.incidents.map((it) => it.id!!))
                await slaExtension.fetchData()
            }
            fetchWithState(slaCalculationsFetchingState, setSlaCalculationsFetchingState, f)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slaCalculationsFetchingState, currentPage])

    const slaExtension = {
        fetchData: async () => {
            if (notEmpty(props.incidents)) {
                const incidentIds = props.incidents.map((it) => it.id!!)
                await props.getAllSlaCalculationByTicketIds(incidentIds)
            }
        }
    }
    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsMedium(true)
        } else {
            setIsMedium(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMedium])

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsNonDesktop(true)
        } else {
            setIsNonDesktop(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNonDesktop])

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            // eslint-disable-next-line react/display-name
            render: (row: IncidentWithSlaCalculations) => (
                // eslint-disable-next-line react/prop-types
                <IncidentRow key={row.id} incident={row} xxx={moment()}
                    slaCalculationsFetchingState={slaCalculationsFetchingState} priority={props.priority}
                    isMedium={isMedium} setIncidentsFetchingState={setIncidentsFetchingState}/>
            )
            // xxx is a workaround. Find perfect solution later.
        }
    ]

    const setSortBy = (fieldName: string) => {
        dispatch(UserChangeSortByEvent.build(fieldName))
    }

    const setOrderBy = (direction: string) => {
        dispatch(UserChangeOrderByEvent.build(direction))
    }

    const notifyCurrentViewCriteriaChanged =
        props.notifyCurrentViewCriteriaChanged as (IncidentFilter) => Promise<undefined>

    function modifyCriteria(criteria: IncidentFilter) {
        notifyCurrentViewCriteriaChanged(criteria).catch((error) => message.error(error.toString()))
    }

    const onWordingSearch = (value: string) => {
        props.form.setFieldsValue({
            SearchTopicAndTicket: value?.trim()
        })
        modifyCriteria({...props.criteria, wording: value?.trim()})
    }

    const sortBySelector = (
        <Menu>
            <Menu.Item onClick={() => setSortBy('createdDatetime')}>
                <span>Date Created</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('lastModifiedDatetime')}>
                <span>Last Modified</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('priority')}>
                <span>Priority</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('ticketStatus')}>
                <span>Status</span>
            </Menu.Item>
            <Menu.Item onClick={() => setSortBy('number')}>
                <span>Ticket Number</span>
            </Menu.Item>
        </Menu>
    )

    const orderBySelector = (
        <Menu>
            <Menu.Item onClick={() => setOrderBy('asc')}>
                <span>Ascending</span>
            </Menu.Item>
            <Menu.Item onClick={() => setOrderBy('desc')}>
                <span>Descending</span>
            </Menu.Item>
        </Menu>
    )

    const handleClickNumberPage = (page: number) => {
        setCurrentPage(page)
    }

    const resolveFieldLabel = (fieldName: string) => IncidentFieldLabel.mapping[fieldName] || ''

    const onFilterClose = () => {
        setIsFilterOpen(false)
    }
    const onFilterOpen = () => {
        setIsFilterOpen(true)
    }
    // const convertCamelToText = (text: string) => {
    //     const result = text.replace(/([A-Z])/g, ' $1')
    //     return result.charAt(0).toUpperCase() + result.slice(1)
    // }
    // const ExportCSV = () => {
    //     setExportLoading(true)
    //     // @ts-ignore
    //     exportIncident(props.criteria || props.incidentViewState?.currentView?.incFilter, props.incidentViewState.sortBy!!, props.incidentViewState.orderBy!!).then((data : IncidentFilterReport[]) => {
    //         if (data.length > 0) {
    //             message.info('Please wait. The report is being created.')
    //             const Heading = [Object.keys(data[0]).map((ele) => convertCamelToText(ele))]
    //             const wb = XLSX.utils.book_new()
    //             // @ts-ignore
    //             const ws1 = XLSX.utils.json_to_sheet(data)
    //             ws1['!cols'] = fixWidthExcel(data, ws1)
    //             // @ts-ignore
    //             XLSX.utils.sheet_add_json(ws1, data, {origin: 'A2', skipHeader: true})
    //             XLSX.utils.sheet_add_aoa(ws1, Heading)
    //             XLSX.utils.book_append_sheet(wb, ws1, 'Ticket Incident')
    //             XLSX.writeFile(wb, 'Ticket Incident.xlsx')
    //             setExportLoading(false)
    //         } else {
    //             message.warn('No tickets in this filter. Please change the filter.')
    //             setExportLoading(false)
    //         }
    //     }).catch(() => {
    //         message.error('Failed to export data. Please try again later')
    //     })
    // }

    const menu = (
        <Menu>
            <Menu.Item key="1" disabled={!checkRolesState(RoleType.Incident, 'CreateIncident')}>
                <Link to="/IncidentForm"><div><Icon type="plus" style={{ marginRight: 7 }}/>   New Ticket</div></Link>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => setIsVisibleTemplete(true)}>
                <Icon type="plus" /> New Ticket from Template
            </Menu.Item>
        </Menu>
    )

    const settingIcon = (
        <Icon type="close" onClick={() => HandlecloseModal()}/>
    )

    const HandlecloseModal = () => {
        confirm({
            title: 'Are you sure?',
            content: 'You want to leave this page ?',
            okText: 'OK',
            okType: 'danger',
            maskClosable: false,
            cancelText: 'Cancle',
            onOk() {
                setIsVisibleTemplete(false)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    // eslint-disable-next-line no-return-assign
    return (
        // <div style={{ overflow: window.innerWidth < 768 ? 'auto' : 'none'}} >
        <Col span={24}>
            <SpinningFetcher fetchingState={incidentViewStateFetchingState}>
                {isNonDesktop
                    ? <Drawer
                        placement="right"
                        closable={false}
                        onClose={onFilterClose}
                        visible={isFilterOpen}
                        width={window.innerWidth > 480 ? '40%' : '80%'}
                    >
                        <Col lg={6} md={24} sm={24} xs={24}>
                            <Card className={'border-height-default'}>
                                <Spin spinning={isLoading}>
                                    <SpinningFetcher fetchingState={incidentViewStateFetchingState}>
                                        <IncidentFilterForm isLoading={setIsLoading}/>
                                    </SpinningFetcher>
                                </Spin>
                            </Card>
                        </Col>
                    </Drawer>
                    : null}
                <Row gutter={24}>
                    <Col span={18} lg={18} md={24} sm={24} xs={24}>
                        <Card className={'border-height-default'}>
                            <Col span={24}>
                                <Row>
                                    <Col span={12}>
                                        {!isMedium ? <h2 className="sub-title">Ticket</h2> : null}
                                    </Col>
                                    <Col span={12} style={{textAlign: 'end', zIndex: 999}}>
                                        {/* <Button loading={exportLoading} style={{marginRight: 10}}
                                            onClick={() => ExportCSV()}
                                            className="addCriteriaButton" htmlType="button"
                                            disabled={!checkRolesState(RoleType.Incident, 'ViewIncident')}>
                                            {!exportLoading ? <Icon type="upload"/> : null}Export</Button> */}
                                        <Dropdown overlay={menu}>
                                            <Button type='primary'>
                                                <Icon type="plus" /> Create Ticket <Icon type="down" />
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </Col>
                            <Row>
                                <Form>
                                    {props.form.getFieldDecorator('SearchTopicAndTicket')(
                                        <Search
                                            placeholder="Can search Topic,Description and Ticket Number"
                                            allowClear={true}
                                            onSearch={value => onWordingSearch(value)}
                                            style={{
                                                width: !isNonDesktop ? '30%' : '100%',
                                                marginBottom: 20,
                                                marginTop: window.innerWidth > 768 ? '0' : '30px'
                                            }}
                                        />
                                    )}
                                </Form>
                            </Row>
                            {isNonDesktop
                                ? <Row>
                                    <Col>
                                        <Button onClick={onFilterOpen} style={{
                                            color: 'blue',
                                            border: 'none',
                                            marginLeft: '-15px',
                                            marginBottom: '10px'
                                        }}><Icon type="filter"/>Filter</Button>
                                    </Col>
                                </Row>
                                : null}
                            <Row>
                                <Col xs={9} md={5} style={{marginRight: 10}}>
                                    <span>Sort by : </span>
                                    {!isMedium ? null : <br></br>}
                                    <span style={{fontWeight: 'bold'}}>
                                        <Dropdown overlay={sortBySelector}>
                                            <span className="ant-dropdown-link">
                                                {resolveFieldLabel(props.incidentViewState.currentView?.incFilter.sortBy!!) || 'Date Created'}
                                                <Icon type="down"
                                                    style={{marginBottom: '5px'}}/></span>
                                        </Dropdown></span>
                                </Col>
                                <Col xs={8} md={5}>
                                    {!isMedium ? null : <br></br>}
                                    <Dropdown overlay={orderBySelector}>
                                        <span className="ant-dropdown-link"
                                            style={{fontWeight: 'bold'}}>{resolveOrderDirectionLabel(props.incidentViewState.currentView?.incFilter.orderBy!!)}<Icon
                                                type="down"
                                                style={{marginBottom: '5px'}}/></span>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <Spin spinning={isLoadingIncident}>
                                <Table className="table-config-header" dataSource={props.incidents} columns={columns}
                                    rowKey="id"
                                    loading={incidentsFetchingState === FetchingState.Started}
                                    pagination={{
                                        pageSize: limit,
                                        current: currentPage,
                                        total: props.tablePagination!!,
                                        // showSizeChanger: true,
                                        // showQuickJumper: true,
                                        onChange: (event) => {
                                            handleClickNumberPage(event)
                                        }
                                    }}/>
                            </Spin>
                        </Card>
                    </Col>
                    {!isNonDesktop
                        ? <Col span={6} lg={6} md={6} sm={24} xs={24}>
                            <Card className={'border-height-default'}>
                                <Spin spinning={isLoading}>
                                    <SpinningFetcher fetchingState={incidentViewStateFetchingState}>
                                        <IncidentFilterForm isLoading={setIsLoading}/>
                                    </SpinningFetcher>
                                </Spin>
                            </Card>
                        </Col>
                        : null}
                </Row>
                <Modal
                    title="Ticket Template"
                    visible={isVisibleTemplete}
                    // onCancel={() => setIsVisibleTemplete(false)}
                    style={{ minWidth: 840 }}
                    footer={false}
                    closeIcon={settingIcon}
                >
                    <CloneTicketList setVisible={setIsVisibleTemplete} isVisible={isVisibleTemplete}/>
                </Modal>
            </SpinningFetcher>
        </Col>
    )
}

const IncidentListWrapped = Form.create({name: 'GlobalFormSetting'})(IncidentList)

export default connect(
    mapStateToProps,
    {
        searchIncidents,
        updateIncident,
        getAllStatus,
        getAllSupportTeamByPermission,
        getAllCategory,
        notifyCurrentViewCriteriaChanged,
        getCurrentIncidentView,
        getAllPriority,
        getAllSlaCalculationByTicketIds: ForIncident.getAllSlaCalculationByTicketIds,
        getAllGeneralIncidentSetting
    }
)(IncidentListWrapped)
