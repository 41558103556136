import { Row, Col, Button, Icon, Table, Tag, Select, Form } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Asset, PreventiveWithRelated, related } from '../Model'
import { ColumnProps } from 'antd/lib/table'
import { PmTemplateStateContext } from '../ContextAPI/PmTemplateCustomfieldContext'

interface Param {
    dataSource: Asset[]
    dataEdit?: PreventiveWithRelated
    dataRelated?: related[]
    getDataTransfer: Function
    isLoading: boolean
    isOpen: boolean
    targetKeys?: number[]
    page: number
    pageSize: number
    setPageSize: Function
    total: number
    setPage: Function
    handelSearchCreatePM: Function
    handleSortByPM: Function
    isEdit: boolean
    isClear: boolean
    setIsClear: Function
    form?: any
}
type Props = Param

const { Option } = Select
const CustomTableTranfer: React.FC<Props> = (props: Props) => {
    const [dataSource, setDataSource] = useState<Asset[]>([])
    const [selectedDataSource, setSelectedDataSource] = useState<Asset[]>([])
    const [tempKeySelectedDataSource, setTempKeySelectedDataSource] = useState<Asset[]>([])
    const [tempKeyDeSelectedDataSource, setTempKeyDeSelectedDataSource] = useState<Asset[]>([])
    const [, setSortBy] = useState<string>('createDatetime')
    const [orderBy, setOrderBy] = useState<string>('desc')
    const [customColumns, setCustomColumns] = useState<ColumnProps<Asset>[]>([])
    const [customColumnsTarget, setCustomColumnsTarget] = useState<ColumnProps<Asset>[]>([])
    const { PmTemplateState } = useContext<any>(PmTemplateStateContext)
    useEffect(() => {
        if (props.isEdit && props.dataRelated) {
            const findData = props.dataRelated.map((data) => {
                return data as unknown as Asset
            })
            setSelectedDataSource(findData)
            setDataSource([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isEdit, props.dataRelated])

    useEffect(() => {
        const arrayUniqueByKey = [...new Map(props.dataSource.map(item =>
            [item.serialNumber, item])).values()]
        const filterKeys = arrayUniqueByKey.filter((item) => { return item.completedDatetime !== null })
        setDataSource(filterKeys)
        // update
        if (selectedDataSource.length !== 0) {
            const mapUpdateSelectedDataSource: Asset[] = []
            filterKeys.forEach((data) => {
                selectedDataSource.forEach((obj) => {
                    if (data.serialNumber === obj.serialNumber) {
                        mapUpdateSelectedDataSource.push(data)
                    }
                })
            })
            if (mapUpdateSelectedDataSource.length !== 0) {
                console.log(mapUpdateSelectedDataSource)
                setSelectedDataSource(mapUpdateSelectedDataSource)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dataSource])

    useEffect(() => {
        if (!props.isOpen) {
            setDataSource([])
            setSelectedDataSource([])
            setTempKeySelectedDataSource([])
            setTempKeyDeSelectedDataSource([])
            props.setPageSize(10)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen])

    useEffect(() => {
        if (props.isClear) {
            setTempKeySelectedDataSource([])
            props.setIsClear(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isClear])

    useEffect(() => {
        const data = sessionStorage.getItem('CreatePM') || 'ownerName,assetStatus,type,group,ownerId,location,building,floor,department'
        if (data) {
            const hardCodeColSerialNumber = {
                title: 'Serial Number',
                dataIndex: 'serialNumber',
                key: 'serialNumber',
                sorter: true,
                render: (row) => {
                    return <div style={{ color: !selectedDataSource.find(data => { return data.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText', margin: 5 }}>
                        {row}
                    </div>
                }
            }
            const parseData = data?.split(',')
            const convertData = parseData.map((it) => {
                return {
                    dataIndex: '',
                    key: it,
                    sorter: true,
                    render: (row) => {
                        return <div style={{ color: !selectedDataSource.find(data => { return data.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
                            {renderDataColumn(row, it)}
                        </div>
                    },
                    title: convertCamelCaseToSentenceCase(it)
                }
            })

            convertData.splice(0, 0, hardCodeColSerialNumber)
            setCustomColumns(convertData)

            const hardCodeColSerialNumberTarget = {
                title: 'Serial Number',
                dataIndex: 'serialNumber',
                key: 'serialNumber',
                sorter: true,
                render: (row) => {
                    return <div>
                        {row}
                    </div>
                }
            }
            const hardCodeColSelectTemplate = {
                title: 'PM Template',
                dataIndex: '',
                key: '',
                width: '150px',
                render: (row, data, index) => {
                    return <div >
                        <Form.Item>
                            {props.form?.getFieldDecorator('PMTemplate' + data.id, {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select PM template'
                                    }
                                ],
                                initialValue: PmTemplateState?.find((it) => it?.id === data.pmActivityTemplateId) ? PmTemplateState?.find((it) => it?.assetGroupId === data?.groupId && it?.id === data.pmActivityTemplateId)?.id : PmTemplateState?.find((it) => it?.assetGroupId === data?.groupId && it?.isDefault)?.id
                            })(
                                <Select
                                    placeholder={'Select PM template'}
                                    style={{marginTop: 5}}
                                >
                                    {PmTemplateState.filter((it) => it.assetGroupId === data.groupId).map((it) => {
                                        return <Option key={it.id} value={it.id}>{it.name}</Option>
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                    </div>
                }
            }
            const convertData2: any[] = parseData.map((it) => {
                return {
                    dataIndex: '',
                    key: it,
                    sorter: (a, b) => a?.[it]?.localeCompare(b?.[it]),
                    render: (row) => {
                        return <div>
                            {renderDataColumn(row, it)}
                        </div>
                    },
                    title: convertCamelCaseToSentenceCase(it)
                }
            })
            convertData2.splice(0, 0, hardCodeColSerialNumberTarget)
            convertData2.splice(5, 0, hardCodeColSelectTemplate)
            setCustomColumnsTarget(convertData2)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionStorage.getItem('CreatePM'), selectedDataSource])

    const convertCamelCaseToSentenceCase = (fieldValue: string) => {
        // adding space between strings
        if (fieldValue === 'ownerId') {
            return 'Owner ID'
        } else {
            const result = fieldValue.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')

            // converting first character to uppercase and join it to the final string
            const final = result.charAt(0).toUpperCase() + result.slice(1)
            return final
        }
    }

    const renderDataColumn = (row, it) => {
        if (it === 'ownerName') {
            return <span>{row.firstName || row?.people?.firstName} {row.lastName || row?.people?.lastName}  {row[it]}</span>
        } else if (it === 'assetStatus') {
            return <span>{row[it] || row?.status}</span>
        } else if (it === 'group') {
            return <span>{row[it] || row?.assetGroup?.name}</span>
        } else if (it === 'ownerId') {
            return <span>{row[it] || row?.people?.employeeId}</span>
        } else if (it === 'department') {
            return <span>{row[it] || row?.people?.department?.name}</span>
        } else {
            return <span>{row[it]}</span>
        }
    }

    // const TableColumnsPMPlan = [
    //     {
    //         title: 'Serial Number',
    //         dataIndex: 'serialNumber',
    //         key: 'serialNumber',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row }) ? '#323276' : 'GrayText' }}>
    //                 {row}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Owner Name',
    //         dataIndex: '',
    //         key: 'ownerName',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.firstName || row?.people?.firstName} {row.lastName || row?.people?.lastName}  {row.ownerName}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Asset Status',
    //         dataIndex: '',
    //         key: 'assetStatus',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.assetStatus || row?.status}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Type',
    //         dataIndex: '',
    //         key: 'type',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row?.type}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Group',
    //         dataIndex: '',
    //         key: 'group',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.group || row?.assetGroup?.name}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Owner ID',
    //         dataIndex: '',
    //         key: 'ownerId',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.ownerId || row?.people?.employeeId}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Location',
    //         dataIndex: '',
    //         key: 'location',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.location}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Building',
    //         dataIndex: '',
    //         key: 'building',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.building}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Floor',
    //         dataIndex: '',
    //         key: 'floor',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.floor}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Department',
    //         dataIndex: '',
    //         key: 'department',
    //         sorter: true,
    //         render: (row) => {
    //             return <div style={{ color: !selectedDataSource.find(it => { return it.serialNumber === row.serialNumber }) ? '#323276' : 'GrayText' }}>
    //                 {row.department || row?.people?.department?.name}
    //             </div>
    //         }
    //     }
    // ]

    // const TableColumnsPMPlan2 = [
    //     {
    //         title: 'Serial Number',
    //         dataIndex: 'serialNumber',
    //         key: 'serialNumber',
    //         sorter: (a, b) => a?.serialNumber?.localeCompare(b?.serialNumber),
    //         render: (row) => {
    //             return <div>
    //                 {row}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Owner Name',
    //         dataIndex: '',
    //         key: 'ownerName',
    //         sorter: (a, b) => a?.ownerName?.localeCompare(b?.ownerName) || a?.firstName?.localeCompare(b?.firstName),
    //         render: (row) => {
    //             return <div>
    //                 {row.firstName || row?.people?.firstName} {row.lastName || row?.people?.lastName} {row.ownerName}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Asset Status',
    //         dataIndex: '',
    //         key: 'assetStatus',
    //         sorter: (a, b) => a?.assetStatus?.localeCompare(b?.assetStatus),
    //         render: (row) => {
    //             return <div>
    //                 {row.assetStatus || row?.status}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Type',
    //         dataIndex: '',
    //         key: 'type',
    //         sorter: (a, b) => a?.type?.localeCompare(b?.type),
    //         render: (row) => {
    //             return <div>
    //                 {row?.type}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Group',
    //         dataIndex: '',
    //         key: 'group',
    //         sorter: (a, b) => a?.group?.localeCompare(b?.group),
    //         render: (row) => {
    //             return <div>
    //                 {row.group || row?.assetGroup?.name}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Owner ID',
    //         dataIndex: '',
    //         key: 'ownerId',
    //         sorter: (a, b) => a?.ownerId?.localeCompare(b?.ownerId),
    //         render: (row) => {
    //             return <div>
    //                 {row.ownerId || row?.people?.employeeId}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Location',
    //         dataIndex: '',
    //         key: 'location',
    //         sorter: (a, b) => a?.location?.localeCompare(b?.location),
    //         render: (row) => {
    //             return <div>
    //                 {row.location}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Building',
    //         dataIndex: '',
    //         key: 'building',
    //         sorter: (a, b) => a?.building?.localeCompare(b?.building),
    //         render: (row) => {
    //             return <div>
    //                 {row.building}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Floor',
    //         dataIndex: '',
    //         key: 'floor',
    //         sorter: (a, b) => a?.floor?.localeCompare(b?.floor),
    //         render: (row) => {
    //             return <div>
    //                 {row.floor}
    //             </div>
    //         }
    //     },
    //     {
    //         title: 'Department',
    //         dataIndex: '',
    //         key: 'department',
    //         sorter: (a, b) => a?.department?.localeCompare(b?.department),
    //         render: (row) => {
    //             return <div>
    //                 {row.department || row?.people?.department?.name}
    //             </div>
    //         }
    //     }
    // ]

    const rowSelection = {
        selectedRowKeys: tempKeySelectedDataSource.map((row) => { return row.serialNumber!! }),
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            setTempKeySelectedDataSource(selectedRows)
        },
        getCheckboxProps: record => ({
            disabled: selectedDataSource.some((it: Asset) => { return it.serialNumber === record.serialNumber })
        })
    }

    const rowSelection2 = {
        selectedRowKeys: tempKeyDeSelectedDataSource.map((row) => { return row.serialNumber!! }),
        onChange: (selectedRowKeys, selectedRows) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
            setTempKeyDeSelectedDataSource(selectedRows)
        }
    }

    const handleSelect = () => {
        setTempKeySelectedDataSource([])
        setSelectedDataSource([...selectedDataSource, ...tempKeySelectedDataSource])
        props.getDataTransfer([...selectedDataSource, ...tempKeySelectedDataSource])
    }

    const handleDeSelect = () => {
        setTempKeyDeSelectedDataSource([])
        // eslint-disable-next-line array-callback-return
        const filteredDataSource = selectedDataSource.filter((it) => {
            if (!(tempKeyDeSelectedDataSource.find((data) => { return data.serialNumber === it.serialNumber }))) {
                return it
            }
        })
        setSelectedDataSource(filteredDataSource)
        props.getDataTransfer(filteredDataSource)
    }

    const onchangeTable = (event) => {
        props.setPage(event)
        props.handelSearchCreatePM(event)
    }

    const handleTableChange = (pagination, filters, sorter) => {
        if (dataSource.length !== 0 && pagination.current === props.page) {
            if (sorter.order === 'descend') {
                setOrderBy('DESC')
            } else if (sorter.order === 'ascend') {
                setOrderBy('ASC')
            } else {
                setSortBy('createdDatetime')
            }
            props.handleSortByPM(sorter.order ? sorter.columnKey : 'createdDatetime', orderBy, pagination.current, pagination.pageSize)
        }
    }
    // console.log(dataSource)
    // console.log(selectedDataSource)
    return (
        <Row type='flex' justify='center' align='top'>
            <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11} className='custom-table-tranfer-card'>
                <Table
                    dataSource={dataSource}
                    columns={customColumns}
                    rowSelection={rowSelection}
                    loading={props.isLoading}
                    rowKey={'serialNumber'}
                    size='small'
                    title={() => <Row>
                        <Col span={12}><h4 style={{ color: '#323276' }}>Catalog Assets</h4></Col>
                        <Col span={12} style={{ textAlign: 'end' }}>{<Tag color="#323276">{props.total} Assets</Tag>}</Col>
                    </Row>}
                    pagination={{
                        pageSize: props.pageSize,
                        current: props.page,
                        total: props.total,
                        showSizeChanger: true,
                        onShowSizeChange(current, size) {
                            props.setPageSize(size)
                            props.setPage(1)
                        },
                        pageSizeOptions: ['10', '30', '50', '80', '100'],
                        size: window.innerWidth > 765 ? 'medium' : 'small',
                        onChange: (event) => { onchangeTable(event) }
                    }}
                    onChange={handleTableChange}
                    bodyStyle={{ overflow: 'auto' }}
                    className='overflow-table-herder'
                />
            </Col>
            <Col span={2} xs={24} sm={24} md={2} lg={2} xl={2} style={{ textAlign: 'center', position: 'sticky', top: '50%' }}>
                <Button size='small' onClick={() => handleSelect()} type={tempKeySelectedDataSource.length !== 0 ? 'primary' : 'default'} style={{ marginBottom: 5 }}><Icon type="right" style={{marginLeft: 0}}/></Button>
                <br />
                <Button size='small' onClick={() => handleDeSelect()} type={tempKeyDeSelectedDataSource.length !== 0 ? 'primary' : 'default'}><Icon type="left" style={{marginLeft: 0}}/></Button>
            </Col>
            <Col span={11} xs={24} sm={24} md={11} lg={11} xl={11} className='custom-table-tranfer-card'>
                <Table
                    dataSource={selectedDataSource}
                    columns={customColumnsTarget}
                    rowSelection={rowSelection2}
                    rowKey={'serialNumber'}
                    loading={props.isLoading}
                    size='small'
                    title={() => <Row>
                        <Col span={12}><h4 style={{ color: '#323276' }}>Selected Assets</h4></Col>
                        <Col span={12} style={{ textAlign: 'end' }}>{<Tag color="#FA541C">{selectedDataSource.length} Assets</Tag>}</Col>
                    </Row>}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '30', '50', '80', '100'],
                        size: window.innerWidth > 765 ? 'medium' : 'small'
                    }}
                    bodyStyle={{ overflow: 'auto' }}
                    className='overflow-table-herder'
                />
            </Col>
        </Row>
    )
}

export default CustomTableTranfer
