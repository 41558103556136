import React, { useEffect, useState } from 'react'
import GoServiceLogo from '../../../assets/images/LogoGoService.png'
import { Layout, Row, Col, Spin } from 'antd'
import { FormComponentProps } from 'antd/es/form'
import { Job, jobSignature } from '../../Relate/Job'
import { WorkOrder, getAllWorkOrderSignatureForm, WoSignatureForm } from '..'
import { Asset } from '../../Relate/Asset'
import RenderSignatureFormModal from './renderSignatureForm'
import moment from 'moment'

interface Param {
    woId?: string
    isCloseModal: Function
    dataJobSignature?: jobSignature
    dataAsset?: Asset[]
    dataJob?: Job
    isOpen: boolean
    getSignature: Function
    wo?: WorkOrder
}

type Props = Param & FormComponentProps

const { Header, Content } = Layout
// const sigPad: any = {}
const WoSignForm: React.FC<Props> = (props: Props) => {
    const [dataAsset, setDataAsset] = useState<Asset[]>([])
    const [data, setData] = useState<WoSignatureForm>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (props.isOpen) {
            setIsLoading(true)
            getAllWorkOrderSignatureForm(props.woId!!, props.dataJob?.jobNo).then((res) => {
                if (res) {
                    setData(res)
                    console.log(res)
                    if (res.jobRelateAsset.length > 0) {
                        setDataAsset(res.jobRelateAsset)
                    }
                }
            }).finally(() => {
                setIsLoading(false)
            })
            // setDataAsset([])
            // getWorkOrderById(props.woId!!).then((data) => {
            //     setDataWo(data)
            // })
            // if (props.dataJobSignature?.jobSignatureDetail) {
            //     const findSignatureNull = props.dataJobSignature.jobSignatureDetail.find((it) => { return it.signaturePath === null })
            //     if (!findSignatureNull) {
            //         getAssetForWosignform(props.woId!!, props.dataJob?.jobNo).then((data) => {
            //             setDataAsset(data)
            //         })
            //     } else {
            //         setDataAsset(props.dataAsset!!)
            //     }
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isOpen])

    // const renderForRelateAsset = () => {
    //     if (dataAsset.length === 1) {
    //         return (<>
    //             <br />
    //             <br />
    //             <br />
    //             <br />
    //         </>)
    //     } else if (dataAsset.length === 2) {
    //         return (<>
    //             <br />
    //             <br />
    //             <br />
    //         </>)
    //     } else if (dataAsset.length === 3) {
    //         return (<>
    //             <br />
    //             <br />
    //         </>)
    //     } else if (dataAsset.length === 4) {
    //         return (<>
    //             <br />
    //         </>)
    //     } else {

    //     }
    // }

    return (
        <Layout id="main-content">
            <Header style={{ background: '#323276' }}>
                <img src={GoServiceLogo} alt="logo" style={{ width: '20%', margin: '12px 0px 16px -24px'}}/>
            </Header>
            <Content className='content-woSign'>
                <Spin spinning={isLoading}>
                    <Row>
                        <Col span={12}>
                            <h1 style={{ paddingLeft: '10px', color: '#323276' }}>{data?.documentName || '-'}</h1>
                        </Col>
                    </Row>
                    <br />
                    <Row style={{ maxWidth: '100%', marginLeft: '12px', marginRight: '12px' }}>
                        <Col span={12}>
                            <Col span={12}>
                                <p className='content-info'><b>ชื่อบริษัท / Company Name</b> : </p>
                            </Col>
                            <Col span={12}>
                                <p className='content-info'>{data?.companyName || '-'}</p>
                            </Col>
                            <Col span={12}>
                                <p className='content-info'><b>ที่อยู่ / Address</b> : </p>
                            </Col>
                            <Col span={12}>
                                <p className='content-info'>{data?.companyAddress || '-'}</p>
                            </Col>
                            <Col span={12}>
                                <p className='content-info'><b>โทรศัพท์ / Phone Number</b> : </p>
                            </Col>
                            <Col span={12}>
                                <p className='content-info'>{data?.companyPhone || '-'}</p>
                            </Col>
                        </Col>
                        <Col span={12} style={{ padding: 0, backgroundColor: '#F2F2F2', border: '1px solid #DCDCDC', maxWidth: '452px' }}>
                            <div>
                                <table className="table-dataJobSign">
                                    <tbody>
                                        <tr>
                                            <td className="content-dataJobSign">
                                                <p className='content-info'><b>เลขที่เอกสาร / Document Number</b> : </p>
                                            </td>
                                            <td className="content-dataJobSign">
                                                <p className='content-info'>{data?.documentNumber || '-'}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="content-dataJobSign">
                                                <p className='content-info'><b>วันที่เอกสาร / Document Date</b> : </p>
                                            </td>
                                            <td className="content-dataJobSign">
                                                <p className='content-info'>{data?.documentDate}</p>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                        <td className="content-dataJobSign">
                                            <p className='content-info'>เลขที่ผู้เสียภาษี / Tax ID : </p>
                                        </td>
                                        <td className="content-dataJobSign">
                                            <p className='content-info'>{props.dataJobSignature?.taxId || '-'}</p>
                                        </td>
                                    </tr> */}
                                        <tr>
                                            <td className="content-dataJobSign">
                                                <p className='content-info'><b>Work Order Number</b> : </p>
                                            </td>
                                            <td className="content-dataJobSign">
                                                <p className='content-info'>{data?.woNumber}</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="content-dataJobSign" style={{ borderBottom: 'none' }}>
                                                <p className='content-info'><b>Job Number</b> : </p>
                                            </td>
                                            <td className="content-dataJobSign" style={{ borderBottom: 'none' }}>
                                                <p className='content-info'>{data?.jobNumber}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: 5, border: '1px solid #DCDCDC', backgroundColor: '#F2F2F2', marginLeft: '12px', marginRight: '12px' }}>
                        <Col span={12} style={{ padding: '8px', borderRight: '1px solid #ddd', textAlign: 'center', backgroundColor: 'rgb(210 210 210)' }}>
                            <b>Requester Information</b>
                        </Col>
                        <Col span={12} style={{ padding: '8px', textAlign: 'center', backgroundColor: 'rgb(210 210 210)' }}>
                            <b>Customer Information</b>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ border: '1px solid #DCDCDC', borderTop: 'none', marginLeft: '12px', marginRight: '12px' }}>
                        <Col span={12} className='content-info' style={{ borderRight: '1px solid #DCDCDC', paddingTop: '12px' }}>
                            <Row>
                                <Col span={13}><p className='content-info'><b>ผู้ร้องขอ / Requester</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.requesterName || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>หน่วยงาน / Department</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.requesterDepartment || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>เรื่องที่ร้องขอ / Topic</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.woTopic || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>ชื่อบริษัท / Company Name</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.requesterCompany || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>ที่อยู่ / Address</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.requesterAddress || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>โทรศัพท์ / Phone Number</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.requesterPhone || '-'}</p></Col>
                            </Row>
                        </Col>
                        <Col span={12} className='content-info' style={{ paddingTop: '12px' }}>
                            <Row>
                                <Col span={13}><p className='content-info'><b>ช่องทางการติดต่อ / Contact</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.customerName || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>ชื่อบริษัท / Company Name</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.customerCompany || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>ที่อยู่ / Address</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.customerAddress || '-'}</p></Col>
                            </Row>
                            <Row>
                                <Col span={13}><p className='content-info'><b>โทรศัพท์ / Phone Number</b> :</p></Col>
                                <Col span={11}><p className='content-info'>{data?.customerPhone || '-'}</p></Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* <br/> */}
                    <Row gutter={24} style={{ textAlign: 'center', backgroundColor: 'rgb(210 210 210)', borderBottom: '1px solid #ddd', marginTop: 5, marginLeft: '12px', marginRight: '12px' }}>
                        <Col span={3} style={{ padding: '8px', borderRight: '1px solid #ddd' }}><b>ลำดับที่</b></Col>
                        <Col span={17} style={{ padding: '8px', borderRight: '1px solid #ddd' }}><b>รายการสินค้า (Product Description)</b></Col>
                        <Col span={4} style={{ padding: '8px' }}><b>จำนวน (Qty)</b></Col>
                    </Row>
                    {dataAsset?.map((item: Asset, index) => (
                        <Row key={index} gutter={24} style={{borderTop: '1px solid #ddd', borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', textAlign: 'center', borderBottom: index === dataAsset?.length!! - 1 ? '1px solid #ddd' : '1px solid #ddd', marginLeft: '12px', marginRight: '12px' }} className='page-break'>
                            <Col span={3} style={{ paddingBottom: index === dataAsset?.length!! - 1 ? '10px' : '0' }}>{index + 1}</Col>
                            <Col span={17} style={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd', marginBottom: 5, textAlign: 'left', paddingLeft: 20, paddingBottom: index === dataAsset?.length!! - 1 ? '10px' : '0' }}>
                                <div style={{ fontSize: '12px' }}> <b>Serial Number </b>: {item.serialNumber + ` (Status : ${item.assetStatus})`}</div>
                                <Row>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>Asset Name </b>: {item.assetName}</div></Col>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>Manufacturer </b>: {item.manufacturer}</div></Col>
                                </Row>
                                <Row>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>Asset Tag </b>: {item.assetTag}</div></Col>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>Site </b>: {item.site}</div></Col>
                                </Row>
                                <Row>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>Location </b>: {item.location || item.assetLocation}</div></Col>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>Building </b>: {item.building}</div></Col>
                                </Row>
                                <Row>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>Floor </b>: {item.floor}</div></Col>
                                    <Col span={12}><div style={{ fontSize: '12px' }}> <b>PO Number</b>: {item.poNumber}</div></Col>
                                </Row>
                            </Col>
                            <Col span={4} >1</Col>
                        </Row>
                    ))}
                    {dataAsset.length === 0
                        ? <>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </>
                        : null}
                    <Row gutter={24} style={{ marginLeft: '12px', marginRight: '12px' }}>
                        <Col span={16}></Col>
                        <Col span={8} style={{ textAlign: 'center', backgroundColor: 'rgb(210 210 210)'}}>
                            <Col span={4} style={{ paddingTop: '4px', paddingRight: '24px', width: '50%', borderRight: '1px solid #ddd'}}><b>TOTAL</b></Col>
                            <Col span={4} style={{ paddingTop: '4px', paddingLeft: '24px', width: '50%' }}>{dataAsset?.length}</Col>
                        </Col>
                    </Row>
                    {/* {renderForRelateAsset()} */}
                    <Row gutter={[8, 8]} style={{ marginLeft: '12px', marginRight: '12px' }} className='page-break'>
                        <p><b>หมายเหตุ</b></p>
                        <ul>
                            <li><b>Appointment date</b> : {moment(data?.appointmentDatetime).format('YYYY-MM-DD HH:mm:00') || '-'}</li>
                            <li><b>Estimate date</b> : {moment(data?.estimateDatetime).format('YYYY-MM-DD HH:mm:00') || '-'}</li>
                        </ul>
                        {props.dataJobSignature?.jobSignatureDetail?.map((item) => (
                            <Col span={8} key={item.id}>
                                <RenderSignatureFormModal
                                    position={item.signaturePosition}
                                    dataJobDetail={item}
                                    getSignature={props.getSignature}
                                    allData={props.dataJobSignature?.jobSignatureDetail}
                                    wo={props.wo}
                                />
                            </Col>
                        ))}
                    </Row>
                </Spin>
            </Content>
        </Layout>
    )
}

export default WoSignForm
