import React, { useContext, useEffect, useState } from 'react'
import RenderFiled from '../../../configurable-fields/RenderFiled'
import { CustomFiled, CustomFiledWithWorkFlow } from '../../../configurable-fields'
import { Empty } from 'antd'
import { WorkflowRelatedContext } from '../../../Preventive/ContextAPI/WorkflowRelatedContext'
import { FieldDetail } from '../model'
import { replaceName } from '../../../common-file'
import moment from 'moment'
import { WorkflowRelatedSelectContext } from '../../../Preventive/ContextAPI/WorkflowRelatedSelectContext'

interface Param {
    workFlowId: number
    form: any
    customFields: CustomFiledWithWorkFlow[]
    isModalVisible: boolean
    selectWorkflow: string[]
}

type Props = Param
const RenderFieldWorkFlow: React.FC<Props> = (props: Props) => {
    // const [props.customFields, setprops.customFields] = useState<CustomFiled[]>([])
    const { WorkflowRelatedState } = useContext<any>(WorkflowRelatedContext)
    const { WorkflowRelatedSelectState } = useContext<any>(WorkflowRelatedSelectContext)
    const [tempCustomFields, setTempCustomFields] = useState<FieldDetail[]>([])

    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (!props.isModalVisible && WorkflowRelatedSelectState.length === 0 || props.selectWorkflow.length === 0 && WorkflowRelatedSelectState.length === 0) {
            const obj = {}
            tempCustomFields.forEach((data) => {
                obj[replaceName(props.workFlowId ? data.name!! + props.workFlowId : data.name!!)] = undefined
            })
            props.form.setFieldsValue(obj)
        } else if (props.isModalVisible && WorkflowRelatedState.length > 0) {
            const obj = {}
            tempCustomFields.forEach((data) => {
                if (data.inputType !== 'DatePicker') {
                    obj[replaceName(props.workFlowId ? data.name!! + props.workFlowId : data.name!!)] = data.value
                } else {
                    obj[replaceName(props.workFlowId ? data.name!! + props.workFlowId : data.name!!)] = data.value ? moment(data.value as string) : undefined
                }
            })
            props.form.setFieldsValue(obj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isModalVisible, props.selectWorkflow])

    useEffect(() => {
        if (props.customFields) {
            const fieldDetails: FieldDetail[] = (props.customFields || []).map((obj: CustomFiled) => {
                let value = ''
                if (obj.name) {
                    if (obj.inputType === 'Checkbox' && Array.isArray(props.form.getFieldValue(replaceName(props.workFlowId ? obj.name + props.workFlowId : obj.name)))) {
                        value = (props.form.getFieldValue(replaceName(props.workFlowId ? obj.name + props.workFlowId : obj.name)) || []).join('|')
                    } else if (obj.inputType === 'DatePicker' && props.form.getFieldValue(props.workFlowId ? obj.name + props.workFlowId : obj.name)) {
                        value = moment(props.form.getFieldValue(props.workFlowId ? obj.name + props.workFlowId : obj.name)).format('YYYY-MM-DD')
                    } else {
                        if (props.form.getFieldValue(replaceName(props.workFlowId ? obj.name + props.workFlowId : obj.name)) && typeof props.form.getFieldValue(props.form.getFieldValue(replaceName(props.workFlowId ? obj.name + props.workFlowId : obj.name))) === 'string') {
                            value = props.form.getFieldValue(replaceName(props.workFlowId ? obj.name + props.workFlowId : obj.name))?.replace(/ +/g, ' ')?.trim()
                        } else {
                            value = props.form.getFieldValue(replaceName(props.workFlowId ? obj.name + props.workFlowId : obj.name))
                        }
                    }
                }
                const fieldDetail: FieldDetail = {
                    id: obj.id,
                    name: obj.name,
                    value: obj.name ? value : undefined,
                    label: obj.label,
                    description: obj.description,
                    inputType: obj.inputType,
                    dataType: obj.dataType,
                    selectOption: obj.selectOption,
                    defaultValue: obj.defaultValue,
                    placeholder: obj.placeholder,
                    isRequireFields: Boolean(obj.isRequireFields)
                }
                return fieldDetail
            })
            setTempCustomFields(fieldDetails)
            if (WorkflowRelatedState) {
                const findData = WorkflowRelatedState.find((it) => { return it.id.toString() === props.workFlowId.toString() })
                if (findData) {
                    findData.customFields = fieldDetails
                    // UpdateWorkflowRelated(findData)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form])

    return (
        <div>
            {props.customFields.length !== 0 ? <RenderFiled fields={props.customFields} form={props.form} colSpan={12}
                colSM={12} labelColSm={24} wrapperColSm={24} maxWidth={'100%'} uniqueName={props.workFlowId}
                genCol={2} disableFields={false} isShowToggle={false} isLabelLeft={true}/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
    )
}

export default RenderFieldWorkFlow
