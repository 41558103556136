import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row } from 'antd'
import { Link } from 'react-router-dom'
import '../knowledge.css'
import ApprovalList from './ApprovalList'
import { KnowledgeList, KnowledgeStatus } from '../knowledge'
import { FormComponentProps } from 'antd/es/form'
import { Space } from '../../common-components/Space'
import { ApprovalCategories } from './ApprovalCategories'
import { getAllKnowledgeWithApprovalStatus, getApproveServiceRequest } from './service'
import { getAllWorkOrderForApproveOrRejectJob } from '../../msp-wo/wo-management'
import ApprovalSelfListForm from './ApprovalSelf'
import ApprovalWOListForm from './ApprovalWO'
import { ApproveProblem, ApproveWorkOrderList, ServiceRequestList, ServiceRequestStatus } from './model'
import { RoleType, checkRolesState } from '../../authorization-module/permissions'
import ApproveProblems from './ApproveProblems'
import ApproveChange from './ApproveChange'
import { getAllApproveProblem } from '../../problem-management/problems/service'

interface StateProps {
    knowledgeList: KnowledgeList
    workorderList: ApproveWorkOrderList
    serviceRequestList: ServiceRequestList
}

type Props = StateProps & FormComponentProps

const ApprovalHome: React.FC<Props> = (props: Props) => {
    // const [isFetchingKnowledges, setFetchingKnowledges] = useState<FetchingState>(FetchingState.NotStarted)
    const [key, setKey] = useState('')
    const [knowledgeList, setKnowledgeList] = useState<KnowledgeList>(props.knowledgeList)
    const listOfStatus = [KnowledgeStatus.AwaitingApproval, KnowledgeStatus.AwaitingRetire]

    // const [isFetchingWorkOrder, setFetchingWorkOrder] = useState<FetchingState>(FetchingState.NotStarted)
    const [workOrderList, setWorkOrderList] = useState<ApproveWorkOrderList>(props.workorderList)
    // const [isFetchingServiceRequest, setFetchingServiceRequest] = useState<FetchingState>(FetchingState.NotStarted)
    const [serviceRequestList, setServiceRequestList] = useState<ServiceRequestList>(props.serviceRequestList)
    const [dataProblem, setDataProblem] = useState<ApproveProblem[]>([])
    const [totalProblems, setTotalProblems] = useState(0)
    const [page] = useState<number>(1)
    const pageSize = 10
    const param = {
        currentPage: 1, listStatus: listOfStatus
    }

    const fetchDataKM = () => {
        // setFetchingKnowledges(FetchingState.Started)
        getAllKnowledgeWithApprovalStatus(param).then(knowledge => {
            setKnowledgeList(knowledge)
            // setFetchingKnowledges(FetchingState.Succeed)
        }).catch(error => {
            console.log(error)
            // setFetchingKnowledges(FetchingState.Failed)
        })
    }

    const fetchDataSelf = () => {
        // setFetchingServiceRequest(FetchingState.Started)
        getApproveServiceRequest(sessionStorage.getItem('username') || '', ServiceRequestStatus.AwaitingApproval).then(serviceRequest => {
            console.log(serviceRequest)
            setServiceRequestList(serviceRequest)
            // setFetchingServiceRequest(FetchingState.Succeed)
        }).catch(error => {
            console.log(error)
            // setFetchingServiceRequest(FetchingState.Failed)
        })
    }

    const fetchDataWO = () => {
        // setFetchingWorkOrder(FetchingState.Started)
        getAllWorkOrderForApproveOrRejectJob((page), pageSize).then((res) => {
            setWorkOrderList(res)
            // setFetchingWorkOrder(FetchingState.Succeed)
            // console.log('res : ', res)
        }).catch((err) => {
            console.log(err)
            // setFetchingWorkOrder(FetchingState.Failed)
        })
    }

    const fetchDataProblems = () => {
        getAllApproveProblem(page, pageSize, 'lastModifiedDatetime', 'desc').then((data) => {
            console.log(data)
            setDataProblem(data.content)
            setTotalProblems(data.totalElements)
        })
    }

    useEffect(() => {
        if (checkRolesState(RoleType.Knowledge, 'ApproveOrRejectKnowledge')) {
            setKey('1')
        } else if (!checkRolesState(RoleType.Knowledge, 'ApproveOrRejectKnowledge') && checkRolesState(RoleType.WO, 'ApproveOrRejectWorkOrder')) {
            setKey('2')
        } else if (!checkRolesState(RoleType.Knowledge, 'ApproveOrRejectKnowledge') && !checkRolesState(RoleType.WO, 'ApproveOrRejectWorkOrder') && checkRolesState(RoleType.WO, 'ApproveOrRejectJob')) {
            setKey('3')
        }
        const getValueApprove = sessionStorage.getItem('vap')
        if (getValueApprove) {
            setKey(getValueApprove)
        }
        fetchDataKM()
        fetchDataWO()
        fetchDataSelf()
        fetchDataProblems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getKeyView = (value) => {
        setKey(value)
        sessionStorage.setItem('vap', value)
        if (value === '1') {
            fetchDataKM()
        } else if (value === '2') {
            fetchDataSelf()
        } else if (value === '3') {
            fetchDataWO()
        } else if (value === '4') {
            fetchDataProblems()
        }
    }

    const checkKeyRender = () => {
        if (key === '2') {
            // return <SpinningFetcher fetchingState={isFetchingServiceRequest}>
            //     <ApprovalSelfListForm serviceRequestList={serviceRequestList} />
            // </SpinningFetcher>
            return <ApprovalSelfListForm serviceRequestList={serviceRequestList} />
        } else if (key === '3') {
            // return (<SpinningFetcher fetchingState={isFetchingWorkOrder}>
            //     <ApprovalWOListForm workorderList={workOrderList} dataSourceWorkOrder={workOrderList?.data}/>
            // </SpinningFetcher>)
            return <ApprovalWOListForm workorderList={workOrderList} dataSourceWorkOrder={workOrderList?.data} total={workOrderList?.total || 0}/>
        } else if (key === '4') {
            return <ApproveProblems problemsList={dataProblem} totalElements={totalProblems}/>
        } else if (key === '5') {
            return <ApproveChange problemsList={[]} totalElements={0}/>
        }
    }

    return (
        <>
            <Breadcrumb className={'content'}>
                <Breadcrumb.Item>
                    <Link to="#">Approval Center</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Space size={10} />
            <Row gutter={20} className='fix_scroll_md'>
                <ApprovalCategories selectFolder={() => { }} getKeyView={getKeyView}
                    countKnowledge={knowledgeList ? knowledgeList.pagination?.totalElements : 0}
                    countServiceRequest={serviceRequestList ? serviceRequestList.totalElements : 0}
                    countWorkOrder={workOrderList ? workOrderList.total : 0}
                    countProblems={totalProblems}
                />
                {
                    // key === '1' ? <SpinningFetcher fetchingState={isFetchingKnowledges}>
                    //     <ApprovalList knowledgeList={knowledgeList} />
                    // </SpinningFetcher> : checkKeyRender()
                    key === '1' ? <ApprovalList knowledgeList={knowledgeList} /> : checkKeyRender()
                }
            </Row >
        </>
    )
}

export default ApprovalHome
