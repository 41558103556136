import { Dispatch } from 'redux'
import { GetAllGeneralIncidentSettingSuccessEvent } from './state-event'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '../../incident-management/rest'
import {GeneralIncidentSetting, RequestBodyGeneralIncidentSetting, ticketNumberList} from './model'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { Incident } from '../incident'
import { message } from 'antd'

export const getAllGeneralIncidentSetting = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<GeneralIncidentSetting>('/api/general-settings/goservice/setting')
            // console.log(decryptBody(response.data.data))
            const setting: GeneralIncidentSetting = decryptBody(response.data.data) || {}
            // console.log(setting)
            dispatch(GetAllGeneralIncidentSettingSuccessEvent.build(setting))
            // dispatch(GetAllGeneralIncidentSettingSuccessEvent.build(response.data.data[0] || []))
            return response.status
        } catch (err: any) {
            checkRedirect(err?.response?.status)
            throw err.response
        }
    }
}

export const createGeneralIncidentSetting = async (data: RequestBodyGeneralIncidentSetting[]) => {
    try {
        return await axiosPost<GeneralIncidentSetting>('/api/general-settings/list', encryptBody(JSON.stringify(data)), herderPostPatch)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const searchTicketTemplate = async (page: number, pageSize: number, sortBy?: string, orderBy?: string, search?: string, activate?: string) => {
    try {
        const config = {
            params: {
                page: page,
                size: pageSize,
                sort: `${sortBy},${orderBy}`,
                value: search || null,
                activations: activate
            }
        }
        const response = await axiosGet<ticketNumberList[]>('/api/templates/pagination', config)
        return decryptBody(response.data.data)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const searchTicketForCreateTemplate = async (search: string) => {
    try {
        const params = {
            value: search
        }
        const response = await axiosGet<Incident>('/api/templates/search', {params})
        return decryptBody(response.data.data)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const getTemplateByid = async (id: string) => {
    try {
        const response = await axiosGet<Incident>(`/api/templates/${id}`)
        return decryptBody(response.data.data)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const SearchIncidentForCreateTemplates = async (text: string) => {
    try {
        const params = {
            value: text
        }
        const response = await axiosGet<Incident>('/api/templates/search', {params})
        return decryptBody(response.data.data)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const createTemplates = async (data) => {
    try {
        const response = await axiosPost<ticketNumberList>('/api/templates', encryptBody(JSON.stringify(data)), herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        message.error(`You have Unsuccessfully save the data. ${err.response?.data?.message}`, 3)
        throw err.response
    }
}

export const updateTemplates = async (id, data) => {
    try {
        const response = await axiosPatch<ticketNumberList>('/api/templates/' + id, encryptBody(JSON.stringify(data)), herderPostPatch)
        return decryptBody(response.data.data)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        message.error(`You have Unsuccessfully save the data. ${err.response?.data?.message}`, 3)
        throw err.response
    }
}

export const deleteTemplates = async (id) => {
    try {
        const response = await axiosDelete<ticketNumberList>('/api/templates/' + id)
        return decryptBody(response.data.data)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}
