import {BusinessHour, Holiday} from './model'
import {
    AddBusinessHourSuccessEvent,
    DeleteBusinessHourSuccessEvent,
    GetAllBusinessHourSuccessEvent,
    UpdateBusinessHourPageEvent,
    UpdateBusinessHourSuccessEvent,
    UpdateTotalContentBusinessHourEvent
} from './state-event'
import {axiosDelete, axiosGet, axiosGetWithPagination, axiosPatch, axiosPost} from '../rest'
import {Dispatch} from 'redux'
import {decryptBody, encryptBody} from '../../common-misc/encryptBody'
import {herderPostPatch} from '../../common-misc'

export async function getBusinessHourById(id: string): Promise<BusinessHour> {
    try {
        const res = await axiosGet<BusinessHour>(`/api/business-hours/${id}`)
        return decryptBody(res.data.data!!)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export async function getAllBusinessHourWithOrigin(): Promise<BusinessHour[]> {
    try {
        const res = await axiosGet<BusinessHour[]>('/api/business-hours')
        if (res.data.data) {
            return decryptBody(res.data.data)
        } else {
            return []
        }
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const getAllBusinessHour = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<BusinessHour[]>('/api/business-hours')
            if (response.data.data) {
                dispatch(GetAllBusinessHourSuccessEvent.build(decryptBody(response.data.data!!)))
            }
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const getAllBusinessHourWithPagination = (page: number, pageSize: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                page: page - 1,
                size: pageSize
            }
            const response = await axiosGetWithPagination<BusinessHour[]>('/api/business-hours/pagination', {params})
            if (response.data.data) {
                const searchResult = decryptBody(response.data.data!!)
                if (searchResult) {
                    dispatch(GetAllBusinessHourSuccessEvent.build(searchResult.content || []))
                    dispatch(UpdateBusinessHourPageEvent.build(searchResult.number + 1 || searchResult?.total))
                    dispatch(UpdateTotalContentBusinessHourEvent.build(searchResult.totalElements || searchResult?.pageable?.page))
                }
            }
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const addBusinessHour = (businessHour: BusinessHour) => {
    return async (dispatch: Dispatch) => {
        try {
            console.log(businessHour)
            const encryptData = encryptBody(JSON.stringify(businessHour))
            const response = await axiosPost<BusinessHour>('/api/business-hours', encryptData, herderPostPatch)
            console.log(decryptBody(response.data.data))
            dispatch(AddBusinessHourSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteBusinessHour = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/business-hours/${id}`)
            dispatch(DeleteBusinessHourSuccessEvent.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

// edit function
export const updateBusinessHour = (patch: BusinessHour) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const encryptData = encryptBody(JSON.stringify(patch))
            const response = await axiosPatch<BusinessHour>(`/api/business-hours/${id}`, encryptData, herderPostPatch)
            dispatch(UpdateBusinessHourSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export async function loadHoliday() {
    try {
        const res = await axiosGet<Holiday>('/api/business-hours/holidays')
        if (res.data.data) {
            return decryptBody(res.data.data)
        } else {
            return []
        }
    } catch (error: any) {
        console.log(error)
        throw error
    }
}
