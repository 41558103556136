import { Folder } from './model'
import { axiosGet, axiosPost, axiosPatch, axiosDelete } from '../rest'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'

export const getFolderById = async (folderId: string) => {
    try {
        const response = await axiosGet<Folder>(`/api/folders/${folderId}`)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const createFolder = async (folder: Folder) => {
    try {
        const encryptData = encryptBody(JSON.stringify(folder))
        const response = await axiosPost<Folder>('/api/folders', encryptData, herderPostPatch)
        return response.data.data!!
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const updateFolder = async (folder: Folder) => {
    try {
        const encryptData = encryptBody(JSON.stringify(folder))
        return await axiosPatch<Folder>(`/api/folders/${folder.id}`, encryptData, herderPostPatch)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}

export const deleteFolder = async (folderId: string) => {
    try {
        return await axiosDelete<Folder>(`/api/folders/${folderId}`)
    } catch (err: any) {
        checkRedirect(err?.response?.status)
        throw err.response
    }
}
