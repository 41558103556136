import React from 'react'
import { Layout, Row, Col, Button } from 'antd'

const { Footer } = Layout

const styles = {
    text_main: {
        fontWeight: 600,
        fontSize: 24,
        color: '#ffffff'
    },
    sub_text: {
        fontWeight: 400,
        fontSize: 18,
        color: '#ffffff'
    }
}
interface Param {
    broken: boolean
    roleLevel?: number
}
type Props = Param
const NewFooter: React.FC<Props> = (props: Props) => {
    return (
        <Footer className='MyFooter' style={{ backgroundColor: '#F6712E', padding: '12px 25px' }}>
            <Row>
                <Col span={14} xs={24} md={14} lg={14}>
                    <div style={styles.sub_text}>Go service system created for you.</div>
                    <div style={styles.sub_text}>Don&apos;t miss any features of go service. Click now &gt;&gt;</div>
                    <div style={styles.sub_text}>Let&apos;s get started with yours.</div>
                </Col>
                <Col span={10} xs={24} md={10} lg={10} style={{ textAlign: props.broken ? 'start' : 'end', paddingRight: 50 }}>
                    <Button ghost style={{ color: '#fff', border: '1px solid #fff', height: 50, marginTop: 20}}
                    // disabled={props.roleLevel !== 0}
                    >
                        {/* <Link to={'/SubscriptionList'}>Upgrade Package</Link> */}
                        Upgrade Package
                    </Button>
                </Col>
            </Row>
        </Footer>
    )
}

export default NewFooter
