/* eslint-disable no-return-assign */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { permissionMapping } from '../authorization-module/permissions'
import { decryptDataVspace, tokenDecode } from '../common-misc'
// import VerifyUser from '../msp-portal/components/VerifyUser'

const params = new URLSearchParams(window.location.search)
const data = params.get('data')
const incidentNo = params.get('incidentNo')

export function setCookie(name, value, days) {
    var expires = ''
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function getCookie(name) {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

export function deleteCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;'
}

const isHasPermission = (permission: string): boolean => {
    const objRole = JSON.stringify(tokenDecode()?.permission) || ''
    // const Roles = mergeRoles(permissionMapping(objRole))
    const Roles = permissionMapping(objRole)
    if (Roles) {
        const arrKey = Object.keys(Roles)
        // let isAuthorize: boolean = false
        arrKey.forEach(o => {
            if (Roles[o].permissions?.length !== 0) {
                if (Roles[o].permissions?.includes(permission)) {
                    // isAuthorize = true
                }
            }
        })
    }
    return true
}

const isAuthorizePath = (path: string): boolean => {
    if (path.includes('UserSetting')) {
        return isHasPermission('ManagePeopleUserGroup')
    }
    if (path.includes('UserSettingForm')) {
        return isHasPermission('ManagePeopleUserGroup')
    }
    if (path.includes('GlobalSetting')) {
        return isHasPermission('ManageSLASetting')
    }
    if (path.includes('SlaPolicySettingList')) {
        return isHasPermission('ManageSLASetting')
    }
    if (path.includes('SlaPolicySettingForm')) {
        return isHasPermission('ManageSLASetting')
    }
    if (path.includes('BusinessHourSettingList')) {
        return isHasPermission('ManageSLASetting')
    }
    if (path.includes('BusinessHourSettingForm')) {
        return isHasPermission('ManageSLASetting')
    }
    if (path.includes('RoleAndPermissionsSettingList')) {
        return isHasPermission('ManageRoleAndPermission') || isHasPermission('ManageTicketAccess') || isHasPermission('ManageKnowledgeAccess')
    }
    if (path.includes('RoleAndPermissionsSettingForm')) {
        return isHasPermission('ManageRoleAndPermission') || isHasPermission('ManageTicketAccess') || isHasPermission('ManageKnowledgeAccess')
    }
    if (path.includes('IncidentList')) {
        return isHasPermission('ViewIncident')
    }

    if (path.includes('IncidentForm')) {
        return isHasPermission('CreateIncident')
    }
    if (path.includes('IncidentDetailsView')) {
        return isHasPermission('ViewIncident')
    }
    if (path.includes('ApprovalCenter')) {
        return isHasPermission('ApproveOrRejectKnowledge')
    }
    if (path.includes('/TenantProfile')) {
        return tokenDecode()?.roleLevel!! === 0
    }

    return true
}

// function checkPath(path: string, word: string): boolean {
//     return path.includes(word)
// }

export function waitingSetSessions() {
    // if (localStorage.getItem('access_token')) {
    //     sessionStorage.setItem('access_token', localStorage.getItem('access_token') || '')
    // }
    if (localStorage.getItem('username')) {
        sessionStorage.setItem('username', localStorage.getItem('username') || '')
    }
    if (localStorage.getItem('active_key_path')) {
        sessionStorage.setItem('active_key_path', localStorage.getItem('active_key_path') || '')
    }
    if (localStorage.getItem('tenant')) {
        sessionStorage.setItem('tenant', localStorage.getItem('tenant') || '')
    }
    if (localStorage.getItem('tim')) {
        sessionStorage.setItem('tim', localStorage.getItem('tim') || '')
    }
}

export function waitingGetSessions() {
    // if (getCookie('access_token')) {
    //     localStorage.setItem('access_token', getCookie('access_token') || '')
    // }
    if (sessionStorage.getItem('username')) {
        localStorage.setItem('username', sessionStorage.getItem('username') || '')
    }
    if (sessionStorage.getItem('active_key_path')) {
        localStorage.setItem('active_key_path', sessionStorage.getItem('active_key_path') || '')
    }
    if (sessionStorage.getItem('tenant')) {
        localStorage.setItem('tenant', sessionStorage.getItem('tenant') || '')
    }
    if (sessionStorage.getItem('tim')) {
        localStorage.setItem('tim', sessionStorage.getItem('tim') || '')
    }
}

export const PrivateRouteNoSidebar = ({ component: Component, auth, ...rest }: any) => (
    // eslint-disable-next-line no-return-assign
    <Route {...rest} render={(props: any) => (
        <React.Suspense fallback={<div />}>
            {waitingSetSessions()}
            {isAuthorizePath(props.location.pathname) ? null : window.location.href = '/403'}
            {
                getCookie('access_token') ? <Component {...props} /> : <Redirect to="/" />
            }
        </React.Suspense>
    )} />
)

export const PrivateRoute = ({ component: Component, auth, ...rest }: any) => (
    // eslint-disable-next-line no-return-assign

    <Route {...rest} render={(props: any) => (
        <React.Suspense fallback={<div />}>
            {isAuthorizePath(props.location.pathname) ? null : window.location.href = '/403'}
            {
                // (window.history.length > 1 || sessionStorage.getItem('tim')) && getCookie('access_token') ? <Component {...props} /> : <VerifyUser Component={<Component {...props} />}/>
                getCookie('access_token') ? <Component {...props} /> : <Redirect to="/" />
            }
        </React.Suspense>
    )} />
)

const validateUserVspace = (param): boolean => {
    let check = false
    if (sessionStorage.getItem('scrollHeightTo1000')) {
        sessionStorage.removeItem('scrollHeightTo1000')
    }
    if (param.location.search) {
        if (param.location.search.substring(0, 12) === '?datamoblie=') {
            const getDataMobile = decodeURI(param.location.search.substring(12, param.length))
            if (getDataMobile) {
                sessionStorage.setItem('dataVspace', getDataMobile)
                check = true
            }
        } else {
            if (data) {
                sessionStorage.setItem('dataVspace', decodeURI(data))
            }
            check = true
        }
        let pathName = param.location.pathname
        if (incidentNo) {
            pathName += `?incidentNo=${incidentNo}`
        }
        window.history.replaceState({}, 'title', pathName)
    }
    return check
}

export const PrivateRouteForVspace = ({ component: Component, auth, ...rest }: any) => (
    <Route {...rest} render={(props: any) => (
        <React.Suspense fallback={<div />}>
            {
                validateUserVspace(props) || decryptDataVspace() || window.location.pathname.includes('vSpaceLogin') ? <Component {...props} /> : <Redirect to={`/vSpaceLogin?redirect=${window.location.pathname.slice(1)}`} />
            }
        </React.Suspense>
    )} />
)

export const PrivateRouteKM = ({ component: Component, auth, ...rest }: any) => (
    // eslint-disable-next-line no-return-assign
    <Route {...rest} render={(props: any) => (
        <React.Suspense fallback={<div />}>
            {isAuthorizePath(props.location.pathname) ? null : window.location.href = '/403'}
            {
                validateUserVspace(props) || decryptDataVspace() || getCookie('access_token') ? <Component {...props} /> : <Redirect to={`/vSpaceLogin?redirect=${window.location.pathname.slice(1)}`} />
            }
        </React.Suspense>
    )} />
)
