import {Dispatch} from 'redux'
import {axiosGet, axiosPost, axiosDelete} from '../../rest'
import {ChargingGroup, Charging} from './Model'
import {GetChargingSuccessEvent, DeleteChargingSuccessEvent} from './state-event'

export const getChargingByWoId = (woId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const params = {
                id: woId
            }
            const response = await axiosGet<ChargingGroup[]>('/api/charging', {params})
            dispatch(GetChargingSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const addCharging = (data: Charging[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<ChargingGroup[]>('/api/charging/list', data)
            dispatch(GetChargingSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const deleteCharging = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/charging/${id}`)
            dispatch(DeleteChargingSuccessEvent.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

// export async function getChargingByWoIdNoRedux(id: string): Promise<ChargingGroup[]> {
//     try {
//         const res = await axiosGet<ChargingGroup[]>(`/api/charging/getCharging?woId=${id}`)
//         return res.data.data!! || []
//     } catch (error: any) {
//         console.log(error)
//         throw error
//     }
// }
