import React, { useEffect, useState } from 'react'
import { Breadcrumb, Col, Menu, Row } from 'antd'
import { Link } from 'react-router-dom'
import CustomAssetSettingFields from './components/CustomAssetSetting'
import { getAllLabelActive } from './service'
import { generalAssetSetting } from './model'
import { tokenDecode } from '../common-misc'
import { ImportWebSocketAssetSetting } from '../common-notify/web-socket-asset-setting'

const GeneralAsset = () => {
    const [general, setGeneral] = useState<generalAssetSetting[]>([])
    const [keys, setKeys] = useState<string>('1')

    useEffect(() => {
        getAllLabelActive().then((res) => {
            if (res) {
                const sortResponse = res.sort((a, b) => {
                    return a.label.localeCompare(b.label)
                })
                setGeneral(sortResponse)
                setKeys(sortResponse[0].id)
            }
        })
        ImportWebSocketAssetSetting.subscribe(tokenDecode()?.sub!!)
    }, [])

    const handleOnClick = (e) => {
        setKeys(e.key)
    }

    const checkKeyForRender = (id: number) => {
        const findCheckKeys = general.find((generals) => generals.id === id)
        if (findCheckKeys) {
            if (findCheckKeys.child) {
                return <CustomAssetSettingFields general={findCheckKeys} name={findCheckKeys.label} keyId={findCheckKeys.id} child={findCheckKeys.child}/>
            } else {
                return <CustomAssetSettingFields general={findCheckKeys} name={findCheckKeys.label} keyId={findCheckKeys.id}/>
            }
        }
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/Setting">Setting</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Asset Setting
                </Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Row gutter={[16, 16]}>
                <Col span={4} style={{ border: '1px solid #DCDCDC', padding: '20px 5px 20px 20px', marginTop: 7, borderRadius: '5px', height: '78vh', minHeight: 715 }}>
                    <Menu
                        onClick={handleOnClick}
                        defaultSelectedKeys={['20']}
                        defaultOpenKeys={['sub1']}
                        mode='inline'
                        className='problemas-menu'
                    >
                        <Menu.ItemGroup key="g1" title="General" className='fix-color'>
                            {general.map((data) => {
                                return <Menu.Item key={data.id}>{data.label}</Menu.Item>
                            })}
                        </Menu.ItemGroup>
                    </Menu>
                </Col>
                <Col span={20} style={{ paddingLeft: 20 }}>
                    <div style={{ border: '1px solid #DCDCDC', padding: 20, borderRadius: '5px', minHeight: 715 }}>
                        {checkKeyForRender(Number(keys))}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default GeneralAsset
