import React, { useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { StoreState } from '../../store'
import { DataForChart } from '../model'
import { nullOrEmptyObject } from '../../common-misc'
import { Empty } from 'antd'

const mapStateToProps = (state: StoreState) => {
    return {
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface Param {
    myData?: DataForChart
    Height?: number
    minHeight?: number

}

type Props = Param & StateProps

const VerticleBarChart: React.FC<Props> = (props: Props) => {
    const [maxdata, setMaxdata] = useState<number>(0)

    const data = {
        labels: ['2nd', '1st', '3rd'],
        datasets: [{
            label: 'Like/Knowledge',
            data: [10, 15, 5],
            backgroundColor: [
                '#FAAD14',
                '#69BB57',
                '#F6712E'
            ]
        }]
    }

    const plugins = [{
        afterDraw: function (chart: any) {
            const chartInstance = chart
            const ctx = chart.chart.ctx
            // const height = chart.height
            ctx.textAlign = 'center'
            ctx.textBaseline = 'bottom'
            ctx.font = '12px sans-serif'
            // eslint-disable-next-line no-unused-expressions
            props.myData?.datasets.forEach(function (dataset, i) {
                const meta = chartInstance.controller.getDatasetMeta(i)
                setMaxdata(dataset.data[1] ? dataset.data[1] + 5 : dataset.data[0] + 5)
                meta.data.forEach(function (bar, index) {
                    const data = dataset.data[index]
                    const label = 'Like/Knowledge'
                    ctx.fillStyle = ('#323276')
                    ctx.fillText(data, bar._model.x, bar._model.y - 20)
                    ctx.fillText(label, bar._model.x, bar._model.y - 5)
                })
            })
        }
    }]

    return (
        <div>
            {!nullOrEmptyObject(props.myData?.labels)
                ? <Bar
                    data={props.myData?.labels ? props.myData : data}
                    options={{
                        legend: {
                            display: false
                        },
                        tooltips: {
                            enabled: false,
                            titleFontColor: '#fa5414'
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                    precision: 0,
                                    max: maxdata > 0 ? maxdata : 50
                                }
                            }],
                            xAxes: [{
                                gridLines: false,
                                barThickness: 70
                            }],
                            Axes: [{
                                display: false
                            }]
                        }

                    }}
                    height={420}
                    plugins={plugins}
                />
                : <div style={{height: 420}}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            }
        </div>

    )
}

export default VerticleBarChart
