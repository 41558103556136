import React, {useContext, useEffect, useState} from 'react'
import {Button, Card, Col, Collapse, DatePicker, Icon, message, Row, Select, Spin, Form} from 'antd'
import {
    downloadpmActivityReport,
    downloadPmAdjustDataReport,
    downloadPmStatusReport,
    getAdjust,
    getStatus
} from './components/Services/Service'
import ColumnPMStatus from './components/ColumnPMStatus'
import ColumnAdjustment from './components/ColumnAdjustment'
// import {NewAssetContext} from './components/ContextAPI/NewAssetContext'
import {PMStatusContext} from './components/ContextAPI/PMStatusContext'
import {AdjustContext} from './components/ContextAPI/AdjustContext'
import './report.css'
import {Adjust, ColumnNormal, NewAsset, PMStatus} from './components/Model'
import {checkRolesState, RoleType} from '../authorization-module/permissions'
import {convertCamelCaseToSentenceCase} from '../knowledge-management/util'
import PageService from './components/ContextAPI/PageService'
import moment from 'moment'
import { FormComponentProps } from 'antd/es/form'

interface Param {
}

type Props = Param & FormComponentProps
const {Panel} = Collapse
const {RangePicker} = DatePicker
const {Option} = Select
const dateFormat = ('YYYY-MM-DD')
const Report: React.FC<Props> = (props: Props) => {
    const {getFieldDecorator, setFieldsValue} = props.form
    // const { GetNewAsset } = useContext<any>(NewAssetContext)
    const { GetAdjust } = useContext<any>(AdjustContext)
    const { GetPMStatus } = useContext<any>(PMStatusContext)
    // const [, setLoadingAsset] = useState<boolean>(false)
    const [loadingAdjust, setLoadingAdjust] = useState<boolean>(false)
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false)
    const [, setTempNewAssetState] = useState<NewAsset[]>([])
    const [tempAdjustState, setTempAdjustState] = useState<Adjust[]>([])
    const [tempPMStatusState, setTempPMStatusState] = useState<PMStatus[]>([])
    // const [columnsNewAssetList, setColumnsNewAssetList] = useState<string[]>([])
    const [columnsAssetAdjustmentList, setColumnsAssetAdjustmentList] = useState<string[]>([])
    const [isFetchingFirst, setisFetchingFirst] = useState<boolean>(false)
    // const [isFetchingFirstAllAsset, setisFetchingFirstAllAsset] = useState<boolean>(false)
    const [isFetchingFirstPMStatus, setisFetchingFirstPMStatus] = useState<boolean>(false)
    const [columnsPMStatusList, setColumnsPMStatusList] = useState<string[]>([])
    // const [, setColumnsNewAssetObjList] = useState<ColumnNormal[]>([])
    const [columnsAssetAdjustmentObjList, setColumnsAssetAdjustmentObjList] = useState<ColumnNormal[]>([])
    const [columnsPMStatusObjList, setColumnsPMStatusObjList] = useState<ColumnNormal[]>([])
    // const [, setTotalAsset] = useState(0)
    // const [rangeAsset] = useState<any[]>()
    const [totalAdjust, setTotalAdjust] = useState(0)
    const [rangeAdjust, setRangeAdjust] = useState<any[]>()
    const [totalPMStatus, setTotalPMStatus] = useState(0)
    const [rangePMStatus, setRangePMStatus] = useState<any[]>()
    const [loadingExportAsset] = useState<boolean>(false)
    const [loadingExportAdjust, setLoadingExportAdjust] = useState<boolean>(false)
    const [loadingExportStatus, setLoadingExportStatus] = useState<boolean>(false)
    const [loadingPMActivity, setLoadingPMActivity] = useState<boolean>(false)
    // const [rangePMActivity, setRangePMActivity] = useState<any[] | null>()
    // const [value, setValue] = useState<any>(null)
    const [startValue, setStartValue] = useState<any | null>()
    const [endValue, setEndValue] = useState<any | null>()
    const [endOpen, setEndOpen] = useState(false)
    const [startDateAssetDataAdj, setStartDateAssetDataAdj] = useState(null)
    const [startDatePMStatus, setStartDatePMStatus] = useState(null)
    // const [startDatePMActivity, setStartDatePMActivity] = useState(null)

    const onCalendarChange = (dates) => {
        // Store the start date when the user selects a range
        if (dates) {
            setStartDateAssetDataAdj(dates && dates[0] ? dates[0] : null)
        } else {
            setStartDateAssetDataAdj(null)
        }
    }

    const onCalendarChangePMStatus = (dates) => {
        // Store the start date when the user selects a range
        if (dates) {
            setStartDatePMStatus(dates && dates[0] ? dates[0] : null)
        } else {
            setStartDatePMStatus(null)
        }
    }

    // const onCalendarChangePMActivity = (dates) => {
    //     // Store the start date when the user selects a range
    //     if (dates) {
    //         setStartDatePMActivity(dates && dates[0] ? dates[0] : null)
    //     } else {
    //         setStartDatePMActivity(null)
    //     }
    // }

    useEffect(() => {
        setTempNewAssetState([])
        setTempAdjustState([])
        setTempPMStatusState([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const handleOnClickPreviewAsset = () => {
    //     if (rangeAsset) {
    //         const startData = rangeAsset[0].format(dateFormat)
    //         const endDate = rangeAsset[1].format(dateFormat)
    //         fetchNewAsset(startData, endDate, PageService.getInstance().data.pageAsset, PageService.getInstance().data.pageSizeAsset, columnsNewAssetList)
    //     } else {
    //         GetNewAsset('')
    //         setColumnsNewAssetList([])
    //         setColumnsNewAssetObjList([])
    //         setisFetchingFirstAllAsset(false)
    //         setRangeAsset(undefined)
    //     }
    // }

    // const handleAssetChange = (range: any) => {
    //     if (range && range.length !== 0) {
    //         setRangeAsset(range)
    //     } else {
    //         GetNewAsset('')
    //         setColumnsNewAssetList([])
    //         setColumnsNewAssetObjList([])
    //         setisFetchingFirstAllAsset(false)
    //         setRangeAsset(undefined)
    //         setTotalAsset(0)
    //         setTempNewAssetState([])
    //     }
    // }

    const handleOnClickPreviewPMAdjustData = () => {
        if (rangeAdjust) {
            const startData = rangeAdjust[0].format(dateFormat)
            const endDate = rangeAdjust[1].format(dateFormat)
            fetchAdjust(startData, endDate, PageService.getInstance().data.pageAssetAdjust, PageService.getInstance().data.pageSizeAssetAdjust, columnsAssetAdjustmentList)
        } else {
            GetAdjust('')
            setColumnsAssetAdjustmentList([])
            setColumnsAssetAdjustmentObjList([])
            setisFetchingFirst(false)
            setRangeAdjust(undefined)
        }
    }

    const handleAdjustChange = (range: any) => {
        if (!range || range.length === 0) {
            setStartDateAssetDataAdj(null)
        }
        if (range && range.length !== 0) {
            setRangeAdjust(range)
        } else {
            GetAdjust('')
            setColumnsAssetAdjustmentList([])
            setColumnsAssetAdjustmentObjList([])
            setisFetchingFirst(false)
            setRangeAdjust(undefined)
            setTotalAdjust(0)
            setTempAdjustState([])
        }
    }

    const handleOnClickPreviewPMStatus = () => {
        if (rangePMStatus) {
            const startData = rangePMStatus[0].format(dateFormat)
            const endDate = rangePMStatus[1].format(dateFormat)
            fetchPMStatus(startData, endDate, PageService.getInstance().data.pagePm, PageService.getInstance().data.pageSizePm, columnsPMStatusList)
        } else {
            GetPMStatus('')
            setColumnsPMStatusList([])
            setColumnsPMStatusObjList([])
            setisFetchingFirstPMStatus(false)
            setRangePMStatus(undefined)
        }
    }
    const handleStatusChange = (range: any) => {
        if (!range || range.length === 0) {
            setStartDatePMStatus(null)
        }
        if (range && range.length !== 0) {
            setRangePMStatus(range)
        } else {
            GetPMStatus('')
            setColumnsPMStatusList([])
            setColumnsPMStatusObjList([])
            setisFetchingFirstPMStatus(false)
            setRangePMStatus(undefined)
            setTotalPMStatus(0)
            setTempPMStatusState([])
        }
    }
    const getTableHeader = (data) => {
        const columnsExportNewAsset: ColumnNormal[] = []
        if (data.length > 0) {
            for (const keyName of data) {
                columnsExportNewAsset.push(
                    {
                        title: convertCamelCaseToSentenceCase(keyName),
                        dataIndex: keyName
                    }
                )
            }
            // data.map((keyName) => {
            //     columnsExportNewAsset.push(
            //         {
            //             title: convertCamelCaseToSentenceCase(keyName),
            //             dataIndex: keyName
            //         }
            //     )
            // })
        }
        return columnsExportNewAsset
    }
    // const fetchNewAsset = (startDate: string, endDate: string, page: number, pageSize: number, selectedColumn: string[]) => {
    //     setLoadingAsset(true)
    //     getNewAsset(startDate, endDate, page, pageSize, selectedColumn).then((res) => {
    //         const assetHeader = getTableHeader(res.header)
    //         if (res.data.length !== 0) {
    //             if (GetNewAsset) {
    //                 GetNewAsset(res.data)
    //                 setTotalAsset(res.total)
    //                 setLoadingAsset(false)
    //                 setTempNewAssetState(res.data)
    //                 if (!isFetchingFirstAllAsset || columnsNewAssetList.length === 0) {
    //                     setisFetchingFirstAllAsset(true)
    //                     if (sessionStorage.getItem('NewAsset')) {
    //                         const newAsset = sessionStorage.getItem('NewAsset')?.split(',')
    //                         setColumnsNewAssetList(newAsset || [])
    //                     } else {
    //                         setColumnsNewAssetList(assetHeader.map((keyName) => keyName.dataIndex))
    //                     }
    //                 }
    //                 setColumnsNewAssetObjList(assetHeader)
    //                 const newAssetInSesion = sessionStorage.getItem('NewAsset')?.split(',')
    //                 if (newAssetInSesion) {
    //                     if (newAssetInSesion[0] !== '') {
    //                         setFieldsValue({
    //                             ColumnAssets: sessionStorage.getItem('NewAsset')?.split(',')
    //                         })
    //                     } else {
    //                         setFieldsValue({
    //                             ColumnAssets: assetHeader.map((keyName) => keyName.dataIndex)
    //                         })
    //                     }
    //                 } else {
    //                     setFieldsValue({
    //                         ColumnAssets: assetHeader.map((keyName) => keyName.dataIndex)
    //                     })
    //                 }
    //             }
    //         } else {
    //             if (GetNewAsset) {
    //                 GetNewAsset(res.data)
    //                 setTotalAsset(res.total)
    //                 message.warning('Data Not Found')
    //                 setLoadingAsset(false)
    //                 if (!isFetchingFirstAllAsset || columnsNewAssetList.length === 0) {
    //                     setisFetchingFirstAllAsset(true)
    //                     if (sessionStorage.getItem('NewAsset')) {
    //                         const newAsset = sessionStorage.getItem('NewAsset')?.split(',')
    //                         setColumnsNewAssetList(newAsset || [])
    //                     } else {
    //                         setColumnsNewAssetList(assetHeader.map((keyName) => keyName.dataIndex))
    //                     }
    //                 }
    //                 setColumnsNewAssetObjList(assetHeader)
    //             }
    //         }
    //     }).catch(() => {
    //         setTempNewAssetState([])
    //     }).finally(() => setLoadingAsset(false))
    // }

    const fetchAdjust = (startDate: string, endDate: string, page: number, pageSize: number, selectedColumn: string[]) => {
        setLoadingAdjust(true)
        getAdjust(startDate, endDate, page, pageSize, selectedColumn).then((res) => {
            const adjustHeader = getTableHeader(res.header)
            if (res.data.length !== 0) {
                if (GetAdjust) {
                    GetAdjust(res.data)
                    setTotalAdjust(res.total)
                    setTempAdjustState(res.data)
                    setLoadingAdjust(false)
                    if (!isFetchingFirst || columnsAssetAdjustmentList.length === 0) {
                        setisFetchingFirst(true)
                        if (sessionStorage.getItem('AssetAdjust')) {
                            const AssetAdjust = sessionStorage.getItem('AssetAdjust')?.split(',')
                            setColumnsAssetAdjustmentList(AssetAdjust || [])
                        } else {
                            setColumnsAssetAdjustmentList(adjustHeader.map((keyName) => keyName.dataIndex))
                        }
                    }
                    setColumnsAssetAdjustmentObjList(adjustHeader)
                    const AssetAdjustInSession = sessionStorage.getItem('AssetAdjust')?.split(',')
                    if (AssetAdjustInSession) {
                        if (AssetAdjustInSession[0] !== '') {
                            setFieldsValue({
                                ColumnAdjust: sessionStorage.getItem('AssetAdjust')?.split(',')
                            })
                        } else {
                            setFieldsValue({
                                ColumnAdjust: adjustHeader.map((keyName) => keyName.dataIndex)
                            })
                        }
                    } else {
                        setFieldsValue({
                            ColumnAdjust: adjustHeader.map((keyName) => keyName.dataIndex)
                        })
                    }
                }
            } else {
                if (GetAdjust) {
                    GetAdjust(res.data)
                    setTotalAdjust(res.total)
                    message.warning('Data Not Found')
                    setLoadingAdjust(false)
                    if (!isFetchingFirst || columnsAssetAdjustmentList.length === 0) {
                        setisFetchingFirst(true)
                        if (sessionStorage.getItem('AssetAdjust')) {
                            const AssetAdjust = sessionStorage.getItem('AssetAdjust')?.split(',')
                            setColumnsAssetAdjustmentList(AssetAdjust || [])
                        } else {
                            setColumnsAssetAdjustmentList(adjustHeader.map((keyName) => keyName.dataIndex))
                        }
                    }
                    setColumnsAssetAdjustmentObjList(adjustHeader)
                }
            }
        }).catch(() => {
            setTempAdjustState([])
        }).finally(() => setLoadingAdjust(false))
    }

    const fetchPMStatus = (startDate: string, endDate: string, page: number, pageSize: number, selectedColumn: string[]) => {
        setLoadingStatus(true)
        getStatus(startDate, endDate, page, pageSize, selectedColumn).then((res) => {
            const pmStatusHeader = getTableHeader(res.header)
            if (res.data.length !== 0) {
                if (GetPMStatus) {
                    GetPMStatus(res.data)
                    setTotalPMStatus(res.total)
                    setTempPMStatusState(res.data)
                    setLoadingStatus(false)
                    if (!isFetchingFirstPMStatus || columnsPMStatusList.length === 0) {
                        setisFetchingFirstPMStatus(true)
                        if (sessionStorage.getItem('PMStatus')) {
                            const PMStatus = sessionStorage.getItem('PMStatus')?.split(',')
                            setColumnsPMStatusList(PMStatus || [])
                        } else {
                            setColumnsPMStatusList(pmStatusHeader.map((keyName) => keyName.dataIndex))
                        }
                    }
                    setColumnsPMStatusObjList(pmStatusHeader)
                    const PMStatusInSession = sessionStorage.getItem('PMStatus')?.split(',')
                    if (PMStatusInSession) {
                        if (PMStatusInSession[0] !== '') {
                            setFieldsValue({
                                ColumnPM: sessionStorage.getItem('PMStatus')?.split(',')
                            })
                        } else {
                            setFieldsValue({
                                ColumnPM: pmStatusHeader.map((keyName) => keyName.dataIndex)
                            })
                        }
                    } else {
                        setFieldsValue({
                            ColumnPM: pmStatusHeader.map((keyName) => keyName.dataIndex)
                        })
                    }
                }
            } else {
                if (GetPMStatus) {
                    GetPMStatus(res.data)
                    setTotalPMStatus(res.total)
                    message.warning('Data Not Found')
                    setLoadingStatus(false)
                    if (!isFetchingFirstPMStatus || columnsPMStatusList.length === 0) {
                        setisFetchingFirstPMStatus(true)
                        if (sessionStorage.getItem('PMStatus')) {
                            const PMStatus = sessionStorage.getItem('PMStatus')?.split(',')
                            setColumnsPMStatusList(PMStatus || [])
                        } else {
                            setColumnsPMStatusList(pmStatusHeader.map((keyName) => keyName.dataIndex))
                        }
                    }
                    setColumnsPMStatusObjList(pmStatusHeader)
                }
            }
        }).catch(() => {
            setTempPMStatusState([])
        }).finally(() => setLoadingStatus(false))
    }

    // const exportNewAssetData = () => {
    //     if (rangeAsset) {
    //         setLoadingExportAsset(true)
    //         const startData = moment(rangeAsset[0]).format(dateFormat)
    //         const endDate = moment(rangeAsset[1]).format(dateFormat)
    //         downloadAssetReport(startData, endDate, columnsNewAssetList).then((data) => {
    //             console.log('success')
    //         }).finally(() => setLoadingExportAsset(false))
    //     }
    // }

    const exportAdjustData = () => {
        if (rangeAdjust) {
            setLoadingExportAdjust(true)
            const startData = moment(rangeAdjust[0]).format(dateFormat)
            const endDate = moment(rangeAdjust[1]).format(dateFormat)
            downloadPmAdjustDataReport(startData, endDate, columnsAssetAdjustmentList).then((data) => {
                console.log('success')
            }).finally(() => setLoadingExportAdjust(false))
        }
    }
    // const exportAdjustData = () => {
    //     if (rangeAdjust) {
    //         const startData = moment(rangeAdjust[0]).format(dateFormat)
    //         const endDate = moment(rangeAdjust[1]).format(dateFormat)
    //         getAdjust(startData, endDate, 1, 99999).then((data) => {
    //             const wb = XLSX.utils.book_new()
    //             const aoa: string[][] = []
    //             const columnsTitle = columnsAssetAdjustmentList.map((keyName) => convertCamelCaseToSentenceCase(keyName))
    //             aoa.push(columnsTitle)
    //             const CategoryToOBJ = data.data.map((data) => {
    //                 return columnsAssetAdjustmentList.map(key => {
    //                     return data[key]
    //                 })
    //             })
    //             aoa.push(...CategoryToOBJ)
    //             const ws1 = XLSX.utils.aoa_to_sheet(aoa)
    //             ws1['!cols'] = fixWidthExcel(data.data, ws1)
    //             XLSX.utils.book_append_sheet(wb, ws1, 'AdjustData')
    //             XLSX.writeFile(wb, 'Adjustment_Data.xlsx')
    //         })
    //     }
    // }

    const exportPMStatusData = () => {
        if (rangePMStatus) {
            setLoadingExportStatus(true)
            const startData = moment(rangePMStatus[0]).format(dateFormat)
            const endDate = moment(rangePMStatus[1]).format(dateFormat)
            downloadPmStatusReport(startData, endDate, columnsPMStatusList).then((data) => {
                console.log('success')
            }).finally(() => setLoadingExportStatus(false))
        }
    }
    // const exportPMStatusData = () => {
    //     if (rangePMStatus) {
    //         const startData = moment(rangePMStatus[0]).format(dateFormat)
    //         const endDate = moment(rangePMStatus[1]).format(dateFormat)
    //         getStatus(startData, endDate, 1, 99999).then((data) => {
    //             const wb = XLSX.utils.book_new()
    //             const aoa: string[][] = []
    //             const columnsTitle = columnsPMStatusList.map((keyName) => convertCamelCaseToSentenceCase(keyName))
    //             aoa.push(columnsTitle)
    //             const CategoryToOBJ = data.data.map((data) => {
    //                 return columnsPMStatusList.map(key => {
    //                     return data[key]
    //                 })
    //             })
    //             aoa.push(...CategoryToOBJ)
    //             const ws1 = XLSX.utils.aoa_to_sheet(aoa)
    //             ws1['!cols'] = fixWidthExcel(data.data, ws1)
    //             XLSX.utils.book_append_sheet(wb, ws1, 'PMStatus')
    //             XLSX.writeFile(wb, 'PM_Status.xlsx')
    //         })
    //     }
    // }

    // const HandelChangeColumnsNewAssetList = (e) => {
    //     setColumnsNewAssetList(e)
    //     sessionStorage.setItem('NewAsset', e)
    // }

    const HandelChangeAssetAdjustmentList = (e) => {
        setColumnsAssetAdjustmentList(e)
        sessionStorage.setItem('AssetAdjust', e)
    }

    const HandelChangePMStatusList = (e) => {
        setColumnsPMStatusList(e)
        sessionStorage.setItem('PMStatus', e)
    }

    // function disabledDate() {
    //     // Can not select days before today and today
    //     if (rangePMActivity) {
    //         console.log(rangePMActivity)
    //         return rangePMActivity[0] && rangePMActivity[0] < moment().endOf('day')
    //     } else {
    //         return false
    //     }
    // }

    // const onOpenChange = (open) => {
    //     if (open) {
    //         setRangePMActivity([null, null])
    //     } else {
    //         setRangePMActivity(null)
    //     }
    // }

    // const disabledDate = (current, rangePMActivity) => {
    //     if (!rangePMActivity) {
    //         return false
    //     }
    //     const tooLate = rangePMActivity[0] && current.diff(rangePMActivity[0], 'days') >= 30
    //     const tooEarly = rangePMActivity[1] && rangePMActivity[1].diff(current, 'days') >= 30
    //     // console.log('rangePMActivity : ', rangePMActivity)
    //     // console.log('tooLate : ', tooLate)
    //     // console.log('tooEarly : ', tooEarly)
    //     return !!tooEarly || !!tooLate
    // }

    const disabledStartDate = (startValue) => {
        if (!startValue || !endValue) {
            return false
        }
        return startValue.valueOf() > endValue.valueOf()
    }

    const onStartChange = value => {
        setStartValue(value)
        setEndValue(undefined)
    }

    const handleStartOpenChange = open => {
        if (!open) {
            setEndOpen(true)
        }
    }

    const disabledEndDate = endValue => {
        if (!endValue || !startValue) {
            return false
        }
        return endValue.valueOf() <= startValue.valueOf() || endValue.diff(startValue, 'days') >= 30
    }

    const onEndChange = value => {
        setEndValue(value)
    }

    const handleEndOpenChange = open => {
        setEndOpen(open)
    }

    const exportPMActivityData = () => {
        if (startValue && endValue) {
            setLoadingPMActivity(true)
            downloadpmActivityReport(moment(startValue).format('YYYY-MM-DD'), moment(endValue).format('YYYY-MM-DD')).then((data) => {
                console.log('success')
            }).catch(() => {
                message.error('No data found for export report.')
            }).finally(() => setLoadingPMActivity(false))
        }
    }

    // const disabledTimeAssetDataAdj = (current) => {
    //     // return current && current > moment().endOf('day')
    //     // Disable dates beyond 30 days from the selected start date
    //     if (current && startDateAssetDataAdj && startDateAssetDataAdj !== null) {
    //         return (
    //             current && (current < moment(startDateAssetDataAdj || null).startOf('day') || current > moment(startDateAssetDataAdj || null).add(30, 'days').endOf('day'))
    //         )
    //     }
    //     return false // No dates disabled until start date is selected
    // }

    const disabledTimeAssetDataAdj = (current) => {
        // Disable dates beyond 30 days from the selected start date
        if (current && startDateAssetDataAdj) {
            return (
                current < moment(startDateAssetDataAdj || '').startOf('day') ||
                current > moment(startDateAssetDataAdj || '').add(30, 'days').endOf('day')
            )
        }
        return false // No dates disabled until start date is selected
    }

    // const disabledTimePMStatus = (current) => {
    //     // return current && current > moment().endOf('day')
    //     // Disable dates beyond 30 days from the selected start date
    //     if (current && startDatePMStatus && startDatePMStatus !== null) {
    //         return (
    //             current && (current < moment(startDatePMStatus || null).startOf('day') || current > moment(startDatePMStatus || null).add(30, 'days').endOf('day'))
    //         )
    //     }
    //     return false // No dates disabled until start date is selected
    // }

    const disabledTimePMStatus = (current) => {
        if (current && startDatePMStatus) {
            return (
                current < moment(startDatePMStatus || '').startOf('day') ||
                current > moment(startDatePMStatus || '').add(30, 'days').endOf('day')
            )
        }
        return false // No dates disabled until start date is selected
    }

    // const disabledTimePMACtivity = (current) => {
    //     // return current && current > moment().endOf('day')
    //     // Disable dates beyond 30 days from the selected start date
    //     if (startDatePMActivity) {
    //         return (
    //             current &&
    //       (current < moment(startDatePMActivity).startOf('day') ||
    //        current > moment(startDatePMActivity).add(30, 'days').endOf('day'))
    //         )
    //     }
    //     return false // No dates disabled until start date is selected
    // }

    return (
        <div className="main">
            <Spin spinning={loadingExportAsset || loadingExportAdjust || loadingExportStatus}
                tip="Wating to Create File!!">
                <p className={'content'}>Report</p>
                <Card headStyle={{borderBottom: 'none', paddingTop: '23px'}}>
                    <Collapse defaultActiveKey={['2']} >
                        {/* <Panel header={'All Asset'} key="1">
                            <Row gutter={[16, 16]}>
                                <Col md={6} sm={24}>
                                    <p>Created Date</p>
                                    <RangePicker onChange={handleAssetChange} placeholder={['Start Date', 'End Date']}
                                        disabled={!checkRolesState(RoleType.PM, 'ReportPM')}/>
                                </Col>
                                {
                                    columnsNewAssetObjList.length !== 0
                                        ? <>
                                            <Col md={8} sm={24}>
                                                <p>Selected Column</p>
                                                {getFieldDecorator('ColumnAssets', {
                                                    initialValue: columnsNewAssetList,
                                                    rules: [{required: false, message: 'Please input your Activity!'}]
                                                })(<Select mode="tags"
                                                    style={{width: '100%'}}
                                                    placeholder="Show"
                                                    maxTagCount={3}
                                                    // defaultValue={columnsNewAssetList}
                                                    onChange={(e) => HandelChangeColumnsNewAssetList(e)}
                                                    allowClear={true}
                                                    loading={loadingAsset}
                                                >
                                                    {(columnsNewAssetObjList || []).map((it, index) => {
                                                        return <Option key={index}
                                                            value={it.dataIndex}>{it.title}</Option>
                                                    })}
                                                </Select>)}
                                            </Col>
                                            <Col md={2} sm={24}>
                                                <Button htmlType="button" style={{marginTop: 32}}
                                                    onClick={() => handleOnClickPreviewAsset()}
                                                    loading={loadingAsset}
                                                    type='primary'
                                                ><Icon type="eye"/>Preview</Button>
                                            </Col>
                                        </>
                                        : (<>
                                            <Col md={2} sm={24}>
                                                <Button htmlType="button" style={{marginTop: 32}}
                                                    onClick={() => handleOnClickPreviewAsset()}
                                                    loading={loadingAsset}
                                                    type='primary'
                                                ><Icon type="eye"/> Preview</Button>
                                            </Col></>
                                        )
                                }
                                <Col md={8} sm={24} style={{textAlign: 'end'}}>
                                    {columnsNewAssetObjList.length > 0
                                        ? <Button htmlType="button" style={{marginTop: 32}}
                                            onClick={() => exportNewAssetData()}
                                            disabled={!checkRolesState(RoleType.PM, 'ReportPM') || columnsNewAssetList.length === 0}
                                            loading={loadingExportAsset}
                                        ><Icon type="upload"/> Export</Button> : null}
                                </Col>
                                <Col span={24}>
                                    <Spin tip="Loading..." spinning={loadingAsset}>
                                        <ColumnNewAsset dataTransfer={tempNewAssetState}
                                            columnsNewAssetList={columnsNewAssetList}
                                            columnsNewAssetObjList={columnsNewAssetObjList}
                                            total={totalAsset} handleAssetChange={handleOnClickPreviewAsset}
                                            range={rangeAsset}
                                        />
                                    </Spin>
                                </Col>
                            </Row>
                        </Panel> */}
                        <Panel header={'Asset Data Adjustment'} key="2">
                            <div>
                                <Row gutter={[16, 16]} type='flex' align='bottom'>
                                    <Col md={6} sm={24}>
                                        <p>PM Plan</p>
                                        <RangePicker onChange={handleAdjustChange} placeholder={['Start Date', 'End Date']}
                                            onCalendarChange={onCalendarChange}
                                            disabled={!checkRolesState(RoleType.PM, 'ReportPM')}
                                            disabledDate={disabledTimeAssetDataAdj}
                                            allowClear
                                        />
                                    </Col>
                                    {
                                        columnsAssetAdjustmentObjList.length !== 0 ? <>
                                            <Col md={8} sm={24}>
                                                <p>Selected Column</p>
                                                {getFieldDecorator('ColumnAdjust', {
                                                    initialValue: columnsAssetAdjustmentList,
                                                    rules: [{required: false, message: 'Please input your Activity!'}]
                                                })(<Select mode="tags"
                                                    style={{width: '100%'}}
                                                    placeholder="Show"
                                                    maxTagCount={3}
                                                    // defaultValue={columnsAssetAdjustmentList}
                                                    onChange={(e) => HandelChangeAssetAdjustmentList(e)}
                                                    allowClear={true}
                                                >
                                                    {(columnsAssetAdjustmentObjList || []).map((it, index) => {
                                                        return <Option key={index}
                                                            value={it.dataIndex}>{it.title}</Option>
                                                    })}
                                                </Select>)}
                                            </Col>
                                            <Col md={2} sm={12} xs={12}>
                                                <Button htmlType="button"
                                                    onClick={() => handleOnClickPreviewPMAdjustData()}
                                                    loading={loadingAdjust}
                                                    type='primary'
                                                ><Icon type="eye"/>Preview</Button>
                                            </Col>
                                        </>
                                            : (<>
                                                <Col md={2} sm={12} xs={12}>
                                                    <Button htmlType="button"
                                                        onClick={() => handleOnClickPreviewPMAdjustData()}
                                                        loading={loadingAdjust}
                                                        type='primary'
                                                    ><Icon type="eye"/> Preview</Button>
                                                </Col></>
                                            )
                                    }
                                    <Col md={8} sm={12} xs={12} style={{textAlign: 'end'}}>
                                        {columnsAssetAdjustmentObjList.length > 0
                                            ? <Button htmlType="button"
                                                // onClick={() => commonExportData(columnsAssetAdjustmentList, 'AdjustData', 'Adjustment_Data.xlsx', AdjustState)}
                                                onClick={() => exportAdjustData()}
                                                disabled={!checkRolesState(RoleType.PM, 'ReportPM') || rangeAdjust === undefined || columnsAssetAdjustmentList.length === 0}
                                                loading={loadingExportAdjust}>
                                                <Icon type="upload"/> Export</Button> : null}
                                    </Col>
                                    <Col span={24}>
                                        <Spin tip="Loading..." spinning={loadingAdjust}>
                                            <ColumnAdjustment dataTransfer={tempAdjustState}
                                                columnsAdjustDataList={columnsAssetAdjustmentList}
                                                columnsAssetAdjustmentObjList={columnsAssetAdjustmentObjList}
                                                total={totalAdjust}
                                                handleAdjustChange={handleOnClickPreviewPMAdjustData}
                                                range={rangeAdjust}
                                            />
                                        </Spin>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>

                        <Panel header={'PM Status'} key="3">
                            <div>
                                <Row gutter={[16, 16]} type='flex' align='bottom'>
                                    <Col md={6} sm={24}>
                                        <p>PM Plan</p>
                                        <RangePicker onChange={handleStatusChange} placeholder={['Start Date', 'End Date']}
                                            disabled={!checkRolesState(RoleType.PM, 'ReportPM')}
                                            disabledDate={disabledTimePMStatus}
                                            onCalendarChange={onCalendarChangePMStatus}
                                            allowClear
                                        />
                                    </Col>
                                    {
                                        columnsPMStatusObjList.length !== 0 ? <>
                                            <Col md={8} sm={24}>
                                                <p>Selected Column</p>
                                                {getFieldDecorator('ColumnPM', {
                                                    initialValue: columnsPMStatusList,
                                                    rules: [{required: false, message: 'Please input your Activity!'}]
                                                })(<Select mode="tags"
                                                    style={{width: '100%'}}
                                                    placeholder="Show"
                                                    maxTagCount={3}
                                                    // defaultValue={columnsPMStatusList}
                                                    onChange={(e) => HandelChangePMStatusList(e)}
                                                    allowClear={true}
                                                >
                                                    {(columnsPMStatusObjList || []).map((it, index) => {
                                                        return <Option key={index}
                                                            value={it.dataIndex}>{it.title}</Option>
                                                    })}
                                                </Select>)}
                                            </Col>
                                            <Col md={2} sm={12} xs={12}>
                                                <Button htmlType="button"
                                                    onClick={() => handleOnClickPreviewPMStatus()}
                                                    loading={loadingAdjust}
                                                    type='primary'
                                                ><Icon type="eye"/>Preview</Button>
                                            </Col>
                                        </>
                                            : (<>
                                                <Col md={2} sm={12} xs={12}>
                                                    <Button htmlType="button"
                                                        onClick={() => handleOnClickPreviewPMStatus()}
                                                        loading={loadingAdjust}
                                                        type='primary'
                                                    ><Icon type="eye"/> Preview</Button>
                                                </Col></>
                                            )
                                    }
                                    <Col md={8} sm={12} xs={12} style={{textAlign: 'end'}}>
                                        {columnsPMStatusObjList.length > 0
                                            ? <Button htmlType="button"
                                                // onClick={() => commonExportData(columnsPMStatusList, 'PMStatus', 'PM_Status.xlsx', PMStatusState)}
                                                onClick={() => exportPMStatusData()}
                                                disabled={!checkRolesState(RoleType.PM, 'ReportPM') || rangePMStatus === undefined || columnsPMStatusList.length === 0}
                                                loading={loadingExportStatus}>
                                                <Icon type="upload"/> Export</Button> : null}
                                    </Col>
                                    <Col span={24}>
                                        <Spin tip="Loading..." spinning={loadingStatus}>
                                            <ColumnPMStatus dataTransfer={tempPMStatusState}
                                                columnsPMStatusList={columnsPMStatusList}
                                                columnsPMStatusObjList={columnsPMStatusObjList}
                                                total={totalPMStatus}
                                                handleStatusChange={handleOnClickPreviewPMStatus}
                                                range={rangePMStatus}
                                            />
                                        </Spin>
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                        <Panel header={'PM Activity'} key="4">
                            <div>
                                <Row gutter={[16, 16]} type='flex' align='bottom'>
                                    <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                                        <p>PM Plan</p>
                                        {/* <RangePicker
                                            value={rangePMActivity || value}
                                            onCalendarChange={(val) => {
                                                setRangePMActivity(val)
                                            }}
                                            disabledDate={(current) => disabledDate(current, rangePMActivity)}
                                            disabled={!checkRolesState(RoleType.PM, 'ReportPM')}
                                            onOpenChange={onOpenChange}
                                            onChange={(val) => {
                                                setValue(val)
                                            }}
                                        /> */}
                                        <DatePicker
                                            disabledDate={disabledStartDate}
                                            format="YYYY-MM-DD"
                                            value={startValue}
                                            placeholder="Start Date"
                                            onChange={onStartChange}
                                            onOpenChange={handleStartOpenChange}
                                            style={{marginRight: 10, marginBottom: 10, width: '100%'}}
                                        />
                                        <DatePicker
                                            disabledDate={disabledEndDate}
                                            format="YYYY-MM-DD"
                                            value={endValue}
                                            placeholder="End Date"
                                            onChange={onEndChange}
                                            open={endOpen}
                                            onOpenChange={handleEndOpenChange}
                                            style={{width: '100%'}}
                                        />
                                    </Col>
                                    <Col md={9} sm={24} xs={24} style={{textAlign: window.innerWidth > 1024 ? 'left' : 'end'}}>
                                        {startValue && endValue ? <Button htmlType="button"
                                            onClick={() => exportPMActivityData()}
                                            disabled={!checkRolesState(RoleType.PM, 'ReportPM')}
                                            loading={loadingPMActivity}
                                        ><Icon type="upload"/> Export</Button> : null}
                                    </Col>
                                </Row>
                            </div>
                        </Panel>
                    </Collapse>
                </Card>
            </Spin>
        </div>
    )
}

const ReportForm = Form.create<Props>({name: 'Report'})(Report)
export default ReportForm
