import { Empty, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { GetTicketRelateToShowInKm } from '../incident-management/incident/service'
import { Knowledge } from './knowledge'

interface Params {
    knowledgeId: number
}

type Props = Params

const TicketRelated = (props: Props) => {
    const [ticketRelatedData, setTicketRelatedData] = useState<Knowledge[]>([])

    useEffect(() => {
        GetTicketRelateToShowInKm(props.knowledgeId).then((res) => {
            setTicketRelatedData(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: 'Ticket Number',
            dataIndex: 'ticketNumber',
            key: 'ticketNumber'

        },
        {
            title: 'Ticket Type',
            dataIndex: 'ticketType',
            key: 'ticketType'
        },
        {
            title: 'Ticket Status',
            dataIndex: 'ticketStatus',
            key: 'ticketStatus'
        },
        {
            title: 'Ticket Related Timestamp',
            dataIndex: 'ticketRelatedTimestamp',
            key: 'ticketRelatedTimestamp'
        }
    ]

    return (
        <div>
            {ticketRelatedData ? <Table dataSource={ticketRelatedData} columns={columns} pagination={false} rowKey={'ticketNumber'} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
    )
}

export default TicketRelated
