/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'

import {Button, Col, Form, Input, Select, message} from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { getallCatalogNoRedux } from '../service'
import { SelfServiceCatagory } from '../Model'

interface StateProps {
    setVisible: Function
    catalogSubmit: Function
    isVisible?: boolean
    data?: SelfServiceCatagory
    id?: number
}

type Props = StateProps & FormComponentProps
const { Option } = Select
const { TextArea } = Input
const CatalogForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, resetFields } = props.form
    const [CategoryNameList, setCategoryNameList] = useState<string[]>([])

    useEffect(() => {
        getallCatalogNoRedux().then((data) => {
            const categoryName = data.content.map((value) => {
                return value.name?.toLowerCase()
            })
            setCategoryNameList(categoryName)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id, props.data])

    useEffect(() => {
        if (props.data || props.isVisible) {
            setFieldsValue({
                name: props.data?.name || undefined,
                status: props.data?.status || undefined,
                description: props.data?.description || undefined
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, props.isVisible])

    const handleSubmit = e => {
        e.preventDefault()
        props.form.validateFields((err, values) => {
            if (!err) {
                if (props.setVisible) {
                    props.setVisible(false)
                }

                const tempValues = ({
                    name: values.name.replace(/ +/g, ' ').trim(),
                    status: values.status,
                    description: values.description ? values.description.replace(/ +/g, ' ').trim() : ''
                })
                if (props.catalogSubmit) {
                    message.success('The Update has finished successfully.')
                    props.catalogSubmit(tempValues)
                    setCategoryNameList([...CategoryNameList, values.name.toLowerCase().replace(/ +/g, ' ').trim()])
                }
                resetFields()
            }
        })
    }
    const handleCancel = () => {
        if (props.setVisible) {
            props.setVisible(false)
        }
        if (!props.id) {
            resetFields()
        }
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        const pattern = /^[A-Za-z0-9ก-๙]{1}/
        const filterOldName = props.id ? CategoryNameList.filter((it) => it !== props.data?.name) : CategoryNameList
        if (pattern.test(value.replace(/ +/g, ' ').trim())) {
            if (value.replace(/ +/g, ' ').trim().length >= 3) {
                if (props.form.getFieldValue('name')) {
                    if (filterOldName) {
                        if (filterOldName.includes(value.replace(/ +/g, ' ').trim().toLowerCase())) {
                            callback('The Catalog Name already exists. It must be unique. Please try another one.')
                        } else {
                            callback()
                        }
                    }
                } else {
                    callback()
                }
            } else {
                callback('Enter more than 3 characters')
            }
        } else {
            // eslint-disable-next-line
            if (format.test(value.replace(/ +/g, ' ').trim())) {
                callback('Enter more than 1 letter and can not contain any of the following characters ' + format)
            } else {
                callback('Enter more than 3 characters')
            }
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit} >
                <Form.Item label={'Catalog Name'}>
                    {getFieldDecorator('name', {
                        rules: [
                            { required: true, message: 'Please enter catalog name!' },
                            { max: 60, message: 'max Input 60 characters' },
                            { validator: validateNameUniqueness }
                        ]
                    })(
                        <Input placeholder="Please enter catalog name" maxLength={60} max={60}/>
                    )}
                </Form.Item>
                <Form.Item label={'Status'}>
                    {getFieldDecorator('status', {
                        rules: [{ required: true, message: 'Please enter status!' }]
                    })(
                        <Select
                            placeholder="Select status"
                        >
                            <Option value="Published">Published</Option>
                            <Option value="Draft">Draft</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item label={'Description'}>
                    {getFieldDecorator('description', {
                        rules: [{ max: 255, message: 'max Input 255 characters' }]
                    })(
                        <TextArea rows={4} maxLength={255} placeholder='Please enter description'/>
                    )}
                </Form.Item>
                <Col span={24} style={{ marginTop: 10 }}>
                    <Button type="primary" htmlType="submit" style={{ float: 'right', marginLeft: 15 }} >Submit</Button>
                    <Button style={{ float: 'right' }} onClick={() => handleCancel()}>Cancel</Button>
                </Col>
            </Form>
        </>
    )
}

const MyCatalogForm = Form.create<Props>({ name: 'CatalogForm' })(CatalogForm)
export default MyCatalogForm
