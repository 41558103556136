import React, { useState, useEffect } from 'react'
import {Row, Col, Empty, Modal, Spin, Button} from 'antd'
import SelectAsset from './SelectAsset'
import AssetContent from './AssetContent'
import { Asset } from './model'
import { getAssetRelatedWo } from './service'
import { useLocation } from 'react-router-dom'
import { StoreState } from '../../../store'
import { connect } from 'react-redux'
import { getAssetFilter } from '../../asset-management/service'
import { RoleType, checkRolesState } from '../../../authorization-module/permissions'
import { getDataLocationBuilding } from '../../../Preventive/services'
import { LocationBuilding } from '../../../Preventive/Model'
import { WorkOrder } from '../../wo-management'
interface Param {
    getItemAsset: Function
    woId: string
    isEdit?: boolean
    statusJob?: string
    dataAsset: Asset[]
    wo?: WorkOrder
}

const mapStateToProps = (state: StoreState) => {
    return {
        asset: state.asset,
        filterAsset: state.filterAsset,
        job: state.job
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    getAssetFilter: () => Promise<number>
}

type Props = Param & StateProps & DispatchProps
const { confirm } = Modal
const RelateAsset: React.FC<Props> = (props: Props) => {
    const [visible, setVisible] = useState<boolean>(false)
    const [visibleSpare] = useState<boolean>(false)
    const [dataAsset, setDataAsset] = useState<Asset[]>([])
    const [dataSpare] = useState<Asset[]>([])
    const [loadData, setLoadData] = useState<boolean>(false)
    const [isDisableAsset, setIsDisableAsset] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const location = useLocation()
    const params = new URLSearchParams(window.location.search)
    const incidentNo = params.get('incidentNo') || ''
    const [LocationBuildings, setLocationBuildings] = useState<LocationBuilding[]>([])

    useEffect(() => {
        loadFilter()
        if (incidentNo && !location.pathname.includes('/WoFormEdit')) {
            fetchDataAsset(incidentNo)
        }
        if (location.pathname.includes('/WoFormEdit')) {
            if (props.woId) {
                fetchDataAsset(props.woId)
            }
        }
        getDataLocationBuilding().then((res) => {
            setLocationBuildings(res)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const mergeAsset = dataAsset.concat(dataSpare)
        if (checkForDuplicates(mergeAsset, 'id')) {
            // message.error('This Asset is already exists.')
        }
        props.getItemAsset(mergeAsset)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataAsset, dataSpare])

    useEffect(() => {
        if (props.job[0] && location.pathname.includes('/WoFormEdit')) {
            if (props.job[0].attachedSignatureReplace) {
                setIsDisableAsset(true)
            } else {
                setIsDisableAsset(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.job])

    useEffect(() => {
        if (props.isEdit && !checkRolesState(RoleType.WO, 'UpdateWorkOrder')) {
            setIsDisableAsset(true)
        } else if (props.statusJob === 'Complete' || props.statusJob === 'Closed' || props.statusJob === 'Cancelled') {
            setIsDisableAsset(true)
        } else {
            setIsDisableAsset(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.statusJob])

    function checkForDuplicates(array, keyName) {
        return new Set(array.map(item => item[keyName])).size !== array.length
    }

    const loadFilter = async () => {
        if (!props.filterAsset.employeeID) {
            if (Object.keys(props.filterAsset).length === 0) {
                props.getAssetFilter().then(() => {
                    console.log('load filter success')
                })
            }
        }
    }

    const fetchDataAsset = async (id) => {
        setLoadData(true)
        const res = props.dataAsset?.length > 0 ? props.dataAsset : await getAssetRelatedWo(id)
        if (res) {
            const tempAsset: any = []
            // const tempSpare: any = []
            res.forEach((it) => {
                tempAsset.push(it)
            })
            setDataAsset(tempAsset)
            // setDataSpare(tempSpare)
            setLoadData(false)
        } else {
            setLoadData(false)
        }
    }

    const showModal = () => {
        setVisible(true)
    }

    const handleState = e => {
        setVisible(false)
    }

    // const showModalSpare = () => {
    //     setVisibleSpare(true)
    // }

    // const handleStateSpare = e => {
    //     setVisibleSpare(false)
    // }

    const getData = (data, check) => {
        if (data !== null) {
            const tempData = dataAsset.concat(data)
            const arrayUniqueByKey = [...new Map(tempData.map(item =>
                [item.serialNumber, item])).values()]
            if (check) {
                setDataAsset(arrayUniqueByKey)
                setVisible(false)
            } else {
                // setDataSpare(data)
                // setVisibleSpare(false)
            }
        }
    }

    const deleteDataAsset = (id) => {
        const temp = (dataAsset || []).filter(res => res.id !== id)
        setDataAsset(temp)
    }

    // const deleteDataSpare = (id) => {
    //     const temp = (dataSpare || []).filter(res => res.id !== id)
    //     setDataSpare(temp)
    // }

    const closeModal = () => {
        confirm({
            title: <b style={{ color: '#323276', fontSize: '25px', fontWeight: 'bold' }}>Are you sure?</b>,
            content: <span style={{ color: '#5A5A89', fontSize: '16px' }}>You want to leave this page ?</span>,
            className: 'custom-modal-wo',
            okText: 'Yes',
            cancelText: 'No',
            onOk() {
                setVisible(false)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    // const GeneratePagination = () => {
    //     if (dataAsset.length > 0) {
    //         return <Pagination total={dataAsset.length} pageSize={10} current={page} onChange={(page) => setPage(page)}/>
    //     }
    //     return <></>
    // }

    const tempAsset = dataAsset.slice((page - 1) * 50, page * 50)
    if (page > 1 && tempAsset.length === 0) {
        setPage(page - 1)
    }

    return (
        <>
            <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    {/* {!isDisableAsset ? <span className='feck-link' onClick={showModal}>Select Asset </span> : null} */}
                    <Button className='feck-link-Button'
                        onClick={showModal}
                        disabled={isDisableAsset || props.wo?.status === 'Closed' || !checkRolesState(RoleType.WO, 'UpdateWorkOrder')}
                        type='dashed'
                        style={{ border: 'none', boxShadow: 'none', color: '#2F80ED', fontWeight: 'bold', fontSize: '16px' }}
                    >
                        Select Asset
                    </Button>
                </Col>
            </Row>
            <br />
            <Modal
                title= {<span style={{ fontWeight: 'bold' }}>Asset List</span>}
                visible={visible}
                onOk={handleState}
                onCancel={() => closeModal()}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
                footer={null}
                width={'90%'}
            >
                <SelectAsset getData={getData} setData={dataAsset} isAsset={true} assetvisible = {visible} assetSpare = {visibleSpare} woId={props.woId} isEdit={props.isEdit}/>
            </Modal>
            <Spin spinning={loadData}>
                {tempAsset.length > 0 ? <>
                    <div style={{ maxHeight: 915, overflowY: tempAsset.length > 2 ? 'scroll' : undefined, overflowX: 'hidden' }}>
                        {tempAsset.map((data, index) => {
                            return <div style={{ marginBottom: '15px' }} key={index}>
                                <AssetContent data={data} deleteData={deleteDataAsset} isDisableAsset={isDisableAsset} woId={props.woId} setLoadData={setLoadData} locationBuildings={LocationBuildings} wo={props.wo}/>
                            </div>
                        })}
                        {/* <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10 }}>
                            <GeneratePagination />
                        </div> */}
                    </div>
                </> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>

            <Row style={{ lineHeight: '500%' }}><Col>&nbsp;</Col></Row>
            {/* <Row>
                <Col span={24} style={{ textAlign: 'end' }}>
                    {!isDisableAsset ? <span className='feck-link' onClick={showModalSpare}>Select Spare</span> : null}
                </Col>
            </Row>
            <br />
            <Modal
                title="Asset List"
                visible={visibleSpare}
                onOk={handleStateSpare}
                onCancel={handleStateSpare}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
                footer={null}
                width={1500}
            >
                <SelectAsset getData={getData} setData={dataSpare} isAsset={false} assetvisible = {visible} assetSpare = {visibleSpare} woId={props.woId} isEdit={props.isEdit}/>
            </Modal> */}
            {/* <Spin spinning={loadData}>
                {dataSpare.length !== 0 ? (dataSpare || []).map((it, index) => {
                    return (<div key={index}>
                        <AssetContent data={it} deleteData={deleteDataSpare} isDisableAsset={isDisableAsset}/>
                    </div>)
                })
                    : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Spin> */}
        </>
    )
}

export default connect(mapStateToProps, {
    getAssetFilter
})(RelateAsset)
