import {
    AddWorkOrderSuccessEvent,
    DeleteWorkOrderSuccessEvent,
    GetAllWorkOrderSuccessEvent,
    UpdateTotalContentWorkOrderEvent,
    UpdateTotalWorkOrderSuccessEvent,
    UpdateWorkOrderPageEvent,
    UpdateWorkOrderSuccessEvent,
    WorkOrderStateEvent
} from './state-event'
import {TotalWorkOrderContent, WorkOrder, WorkOrderList, WorkOrderWithPagination} from './model'

// const closedJob = (workOrders: any[]) => {
//     workOrders.forEach(a => {
//         const countTotalJobs = (a.related || []).filter(it => it.id === null).length
//         const totalJobs = countTotalJobs > 0 ? 0 : (a.related || []).length
//         const closedJobs = (a.related || []).filter(it => it.jobStatus === 'Closed' || it.jobStatus === 'Rejected' || it.jobStatus === 'Cancelled').length
//         a.workOrder.closedJobs = `${closedJobs}/${totalJobs}`

//         let jobTopic = ''
//         let jobStatus = ''
//         let currentJobIndex = 0
//         const relateJob = (a.related || []).filter(it => it.id !== null)
//         for (const [index, item] of relateJob.entries()) {
//             if (item.jobStatus !== 'Closed') {
//                 if (index === currentJobIndex) {
//                     currentJobIndex = index
//                     jobTopic = item.jobTopic
//                     jobStatus = item.jobStatus
//                     break
//                 }
//             } else {
//                 const lastIndex = relateJob.length - 1
//                 const nextIndex = index + 1
//                 if (index === lastIndex) {
//                     jobTopic = item.jobTopic
//                     jobStatus = item.jobStatus
//                     break
//                 } else {
//                     if (relateJob[nextIndex].jobStatus !== 'Closed') {
//                         currentJobIndex = nextIndex
//                         jobTopic = relateJob[nextIndex].jobTopic
//                         jobStatus = relateJob[nextIndex].jobStatus
//                     }
//                 }
//             }
//         }
//         a.workOrder.jobTopic = jobTopic
//         a.workOrder.jobStatus = jobStatus
//     })

//     return workOrders
// }

export const workOrderStateReducer = (state: WorkOrder[] = [], event: WorkOrderStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === AddWorkOrderSuccessEvent.type) {
        return [...state, event.payload]
    } else if (event.type === DeleteWorkOrderSuccessEvent.type) {
        return state.filter((it) => it.id !== event.payload)
    } else if (event.type === UpdateWorkOrderSuccessEvent.type) {
        return state.map((it) => it.id === event.payload.id ? event.payload : it)
    } else {
        return state
    }
}

export const workOrderListStateReducer = (state: WorkOrderList[] = [], event: WorkOrderStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === GetAllWorkOrderSuccessEvent.type) {
        // return closedJob(event.payload)
        return event.payload
    } else {
        return state
    }
}

export const totalWorkOrderContentStateReducer = (state: TotalWorkOrderContent = {}, event: WorkOrderStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateTotalWorkOrderSuccessEvent.type) {
        return event.payload
    } else {
        return state
    }
}

export const workOrderPaginationStateReducer = (state: WorkOrderWithPagination = {}, event: WorkOrderStateEvent<any> = { type: undefined, payload: undefined}) => {
    if (event.type === UpdateTotalContentWorkOrderEvent.type) {
        state.total = event.payload
    } else if (event.type === UpdateWorkOrderPageEvent.type) {
        state.page = event.payload
    } else {
        return state
    }
    return state
}
