import React, { useState } from 'react'
import { Form, message, Select } from 'antd'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import IncidentViewSaveAsModalForm from './IncidentViewSaveAsModalForm'
import { FormComponentProps } from 'antd/lib/form'
import { getCurrentIncidentView, setCurrentIncidentView, updateIncidentView } from './service'
import { allVisibleViews, IncidentViewState } from './state-model'
import { IncidentFilter } from './model'

interface Props extends FormComponentProps {
    incidentViewState: IncidentViewState
    setCurrentIncidentView: Function
    getCurrentIncidentView: Function
    updateIncidentView: Function
    reload: Function
    criteria?: IncidentFilter
    onChangedView?: () => void
    sortBy?: string
    orderBy?: string
    setIsLoadingFilterTemplate?: Function
}

const { Option, OptGroup } = Select
const ViewSelection: React.FC<Props> = (props: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [isShowingSaveAsModalForm, setIsShowingSaveAsModalForm] = useState<boolean>(false)

    const onViewSelected = (v: number) => {
        props.setIsLoadingFilterTemplate!!(true)
        props.setCurrentIncidentView(v).then((res: IncidentViewState) => {
            if (props.onChangedView) {
                props.onChangedView()
            }
        }).catch((reason: any) =>
            message.error('Failed to set current view. ' + reason?.toString())
        ).finally(() => {
            props.setIsLoadingFilterTemplate!!(false)
        })
        setIsEditing(false)
        console.log(isEditing)
    }

    // const onEditIconClicked = () => {
    //     setIsEditing(true)
    // }

    // const getEditUiElementsColor = () => props.incidentViewState.currentView?.isCriteriaDirty === true ? 'red' : '#323276'

    // const isOverwritingAllowedForCurrentView = () => props.incidentViewState.currentView?.isPredefined === false

    // const onSaveIconClicked = () => {
    //     let tempsenddata = props.incidentViewState.currentView!!
    //     tempsenddata.incFilter = props.criteria!!
    //     // props.updateIncidentView(props.incidentViewState.currentView!!).then((res: number) => {
    //     //     message.success('The update has finished successfully.')
    //     // })
    //     tempsenddata.incFilter.sortBy = props.sortBy
    //     tempsenddata.incFilter.orderBy = props.orderBy
    //     props.updateIncidentView(tempsenddata).then((res: number) => {
    //         message.success('The update has finished successfully.')
    //     })
    //     setIsEditing(false)
    // }

    // const onDiscardIconClicked = () => {
    //     setIsEditing(false)
    //     props.reload()
    //     // window.location.reload()
    // }

    // const onSaveAsIconClicked = () => {
    //     setIsShowingSaveAsModalForm(true)
    // }

    const onSaveAsModalFormClosed = (isSuccess: boolean) => {
        if (isSuccess) {
            setIsEditing(false)
        }
        setIsShowingSaveAsModalForm(false)
    }

    // function viewSelector() {
    //     return (
    //         <Menu>
    //             {
    //                 // eslint-disable-next-line react/prop-types
    //                 ((props.incidentViewState.allVisibleViews || []).map((data: allVisibleViews, index: number) => {
    //                     return (
    //                         <Menu.Item key={index} onClick={() => onViewSelected(data.visibleName)}>
    //                             <span>{data.visibleName}</span>
    //                         </Menu.Item>
    //                     )
    //                 }))
    //             }
    //         </Menu >
    //     )
    // }

    const renderSelectViews = () => {
        const tempDefault = props.incidentViewState?.allVisibleViews?.filter((data: allVisibleViews) => data.visibility === 'Default')
        const tempMyGroup = props.incidentViewState?.allVisibleViews?.filter((data: allVisibleViews) => data.visibility === 'My Group')
        const tempMySelf = props.incidentViewState?.allVisibleViews?.filter((data: allVisibleViews) => data.visibility === 'Myself')
        return (
            <Select className='select-no-border' dropdownClassName='select-dropdown-no-border' value={props.incidentViewState.currentView?.id} onChange={(value) => onViewSelected(value)}>
                {tempDefault && tempDefault?.length > 0
                    ? <OptGroup label="Default">
                        {tempDefault?.map((data: allVisibleViews, index: number) => {
                            return (
                                <Option key={index} value={data.id}>
                                    <span>{data.visibleName}</span>
                                </Option>
                            )
                        })}
                    </OptGroup>
                    : null}
                {tempMyGroup && tempMyGroup?.length > 0
                    ? <OptGroup label="My group">
                        {tempMyGroup?.map((data: allVisibleViews, index: number) => {
                            return (
                                <Option key={index} value={data.id}>
                                    <span>{data.visibleName}</span>
                                </Option>
                            )
                        })}
                    </OptGroup>
                    : null}
                {tempMySelf && tempMySelf?.length > 0
                    ? <OptGroup label="My self">
                        {tempMySelf?.map((data: allVisibleViews, index: number) => {
                            return (
                                <Option key={index} value={data.id}>
                                    <span>{data.visibleName}</span>
                                </Option>
                            )
                        })}
                    </OptGroup>
                    : null}
            </Select>
        )
    }

    return (
        <>
            <div className={'border-current-view'}>
                <span style={{ color: '#6A6C8F' }}>View :</span>&nbsp;&nbsp;
                {/* <Dropdown overlay={() => viewSelector()} trigger={['click']} className={isEditing ? 'selectStyleRed' : 'selectStyleBlack'}>
                    <span className="ant-dropdown-link" >{props.incidentViewState.currentView?.name.length!! > 18 ? (<>{props.incidentViewState.currentView?.name.substring(0, 18) + '..'}</>) : (<>{props.incidentViewState.currentView?.name}</>)} <Icon type="down" className={'pen-icon'} /></span>
                </Dropdown> */}
                {renderSelectViews()}
                {/* <IncidentViewSaveAsModalForm /> */}
                {/* {!isEditing ? (
                    <Tooltip placement="bottom" title={'Edit'}>
                        <Icon className="edit_icon" type="edit" style={{ color: getEditUiElementsColor() }}
                            onClick={onEditIconClicked}/>
                    </Tooltip>
                ) : (
                    <span>
                        {isOverwritingAllowedForCurrentView()
                            ? <Tooltip placement="bottom" title={'Save'}><span onClick={onSaveIconClicked}
                                style={{ margin: '1px' }}><Icon type="check"/></span></Tooltip>
                            : null
                        }
                        <Tooltip placement="bottom" title={'Discard changes'}><span onClick={onDiscardIconClicked}
                            style={{ margin: '1px' }}><Icon
                                type="close"/></span></Tooltip>
                        <Tooltip placement="bottom" title={'Save as'}><span onClick={onSaveAsIconClicked}
                            style={{ margin: '1px' }}><Icon type="save"
                                theme="filled"/></span></Tooltip>
                    </span>
                )} */}
            </div>
            {isShowingSaveAsModalForm
                ? <IncidentViewSaveAsModalForm onClose={onSaveAsModalFormClosed} onOpen={isShowingSaveAsModalForm} criteria={props.criteria} sortBy={props.sortBy!!} orderBy={props.orderBy!!} />
                : null
            }
        </>
    )
}

const mapStateToProps = (state: StoreState) => {
    return {
        incidentViewState: state.incidentViewState
    }
}

const wrappedByForm = Form.create<Props>()(ViewSelection)

export default connect(
    mapStateToProps,
    { setCurrentIncidentView, getCurrentIncidentView, updateIncidentView }
)(wrappedByForm)
