import React from 'react'

function BubbleChat() {
    const isShow = process.env.REACT_APP_IS_BUBBLE_CHAT!!
    const showChat = () => {
        const chatPopup = document.getElementById('chat-popup')
        if (chatPopup) {
            if (chatPopup.style.display === 'none') {
                chatPopup.style.display = 'block'
                console.log(chatPopup)
            } else {
                chatPopup.style.display = 'none'
                console.log(chatPopup)
            }
        }
    }
    return (
        <div>
            {isShow === 'true' ? <>
                <button id="open-chat-btn" onClick={() => showChat()}><span role="img" aria-label="chat">💬</span></button>

                <div id="chat-popup" className="chat-popup">
                    <div className="chat-container">
                        <iframe title='Chat With AI' src="https://webchat.botframework.com/embed/demobotopt?s=wmY30J8kkII.Ng3RSnsP5WLWAVnEHQlOaYpHDS8PeVG1sunBW0EvLAU" style={{ width: '100%', height: 500, border: 'none' }} className='iframe-display'></iframe>
                    </div>
                </div>
            </> : null}
        </div>
    )
}

export default BubbleChat
