/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {
    Col,
    Empty, Row, Spin
} from 'antd'
import { getWorkOrderById } from '../../wo-management/service'
import { decryptDataVspace } from '../../../common-misc'
import { getAllTicketRelatedWo } from '../../../incident-management/incident/service'
interface Param {

}

type Props = Param
const RelateTicket: React.FC<Props> = (props: Props) => {
    const [dataVspace, setDataVspace] = useState<any>(undefined)
    const [loadData, setLoadData] = useState<boolean>(false)
    const location = useLocation()
    const {id}: any = useParams()

    let email: string | undefined
    const params = new URLSearchParams(window.location.search)
    const incidentNo = params.get('incidentNo') || null
    let token: string | undefined
    const dataVspaceInfo = decryptDataVspace()
    if (typeof dataVspaceInfo === 'object') {
        email = dataVspaceInfo.email
        token = dataVspaceInfo.token
    }

    useEffect(() => {
        if (!location.pathname.includes('/WoFormEdit')) {
            // create
            fetchDataVspace()
            const convertData = {
                token: token,
                email: email,
                ticket_sid: incidentNo
            }
            const formData = new URLSearchParams()

            for (const key in convertData) {
                formData.append(key, convertData[key])
            }
        } else {
            // edit
            fetchDataVspace()
            const idWO = id || ''
            getWorkOrderById(idWO).then((res) => {
                const convertData = {
                    token: token,
                    email: email,
                    ticket_sid: res.incidentNo!!
                }
                const formData = new URLSearchParams()

                for (const key in convertData) {
                    formData.append(key, convertData[key])
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchDataVspace = async () => {
        setLoadData(true)
        getAllTicketRelatedWo(id).then((res) => {
            setDataVspace(res)
            setLoadData(false)
        }).catch(() => {
            setLoadData(false)
        })
    }

    return (
        <>
            <div>
                <Spin spinning={loadData}>
                    {/* {dataVspace === undefined
                        ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        : nullOrEmptyMap(dataVspace.data?.refer_remedy_hd)
                            ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                            : <span style={{ padding: 10 }}>{dataVspace.data?.refer_remedy_hd!! || ''} : {dataVspace.data?.subject!! || ''}</span>
                    } */}
                    {dataVspace?.length > 0
                        ? <>
                            {dataVspace.map((it, index) => {
                                return (
                                    <div style={{ maxHeight: 915, overflowY: dataVspace.length > 3 ? 'scroll' : undefined }} key={index}>
                                        <div style={{ backgroundColor: '#F2F2F2', padding: '20px 25px 20px 25px', marginBottom: '10px', marginRight: '10px'}}>
                                            <Row>
                                                <Col span={24} style={{ color: '#FA541C', fontSize: '16px', fontWeight: 'bold' }}> Ticket Number : <span> {it.ticketNumber} </span></Col>
                                            </Row>
                                            <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <Col span={24} className="TextFrontDesc"> Topic </Col>
                                                <Col span={24}>
                                                    <span style={{ color: '#757F8D', fontSize: '16px' }}> {it.topic}</span>
                                                </Col>
                                            </Row>
                                            <hr style={{ border: '1px solid #ddd' }}/>
                                            <Row style={{ marginTop: '10px'}}>
                                                <Col span={24} className="TextFrontDesc"> Description </Col>
                                                <Col span={24}>
                                                    <span style={{ color: '#757F8D', fontSize: '16px', wordWrap: 'break-word' }}> {it.description}</span>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    }
                </Spin>
            </div>
        </>
    )
}

export default RelateTicket
