import React from 'react'
import { Bar, HorizontalBar } from 'react-chartjs-2'
import { colorWoDashboardPiority } from '../../msp-wo/wo-management'
import { Empty } from 'antd'

interface Param {
    label?: string[]
    data?: any[]
    height?: number
    isVerticle?: boolean
    isShowLabel?: boolean
}
type Props = Param

const StackBarChart = (props: Props) => {
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
    const data = {
        labels: props.label ? props.label : labels,
        datasets: props.data ? props.data : [
            {
                label: 'Low',
                data: Array.from({ length: 8 }, () => Math.floor(Math.random() * 20)),
                backgroundColor: 'red'
            },
            {
                label: 'Medium',
                data: Array.from({ length: 8 }, () => Math.floor(Math.random() * 20)),
                backgroundColor: colorWoDashboardPiority.Low
            },
            {
                label: 'High',
                data: Array.from({ length: 8 }, () => Math.floor(Math.random() * 30)),
                backgroundColor: colorWoDashboardPiority.Meduim
            },
            {
                label: 'Critical',
                data: Array.from({ length: 8 }, () => Math.floor(Math.random() * 30)),
                backgroundColor: colorWoDashboardPiority.High
            }
        ]
    }

    return (
        <div>
            {props.data ? props.isVerticle ? <HorizontalBar
                data={data}
                options={{
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                precision: 0
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                // eslint-disable-next-line camelcase
                                callback: function(value, index, ticks_array) {
                                    const characterLimit = 15
                                    const label = value
                                    if (label.length >= characterLimit) {
                                        return label.slice(0, label.length).substring(0, characterLimit - 1).trim() + '...'
                                    }
                                    return label
                                }
                            }

                        }],
                        Axes: [{
                            display: false
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: props.isShowLabel
                    },
                    tooltips: {
                        backgroundColor: '#505050',
                        bodyFontColor: '#ffffff'
                    }
                }}
                height={props.height ? props.height : 100}
            ></HorizontalBar> : <Bar
                data={data}
                options={{
                    scales: {
                        xAxes: [{
                            stacked: true,
                            ticks: {
                                // eslint-disable-next-line camelcase
                                callback: function(value, index, ticks_array) {
                                    const characterLimit = 20
                                    const label = value
                                    if (label.length >= characterLimit) {
                                        return label.slice(0, label.length).substring(0, characterLimit - 1).trim() + '...'
                                    }
                                    return label
                                }
                            }
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                                min: 0,
                                precision: 0
                            }
                        }]
                    },
                    legend: {
                        position: 'bottom',
                        display: props.isShowLabel
                    },
                    tooltips: {
                        backgroundColor: '#505050',
                        bodyFontColor: '#ffffff'
                    }
                }}
            // height={props.height ? props.height : 100}
            /> : <div>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>}
        </div>
    )
}

export default StackBarChart
