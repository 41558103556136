/* eslint-disable no-unused-expressions */
import { Col, Icon, Modal, Row, Upload} from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import AutoAvatar from '../../../../common-components/autoAvatar'
import { WorkNoteProblems } from '../../Model'
import TimeRender from '../../../../incident-management/comment/TimeRender'
import moment from 'moment'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../../../../common-file/icon-file'
import { UploadLink } from '../../../../file-server-storage/model'
import { DownloadAnyFile } from '../../../../common-misc'
import ReactHtmlParser from 'react-html-parser'
import DisplayReplyProblem from './DisplayReplyProblem'

interface Params {
    reloadWorknote: Function
    isRenderPTask: boolean
    isScroll?: boolean
    pbNumber?: string
    data?: WorkNoteProblems[]
}

type Props = Params
const DisplayListComment = (props: Props) => {
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const messagesEndRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (messagesEndRef.current && props.isScroll) {
            setTimeout(() => {
                messagesEndRef?.current?.scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    })
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isScroll])

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png') || ((file.url) as string).includes('jpeg') || ((file.url) as string).includes('PNG')) {
            setPreviewVisible(true)
            setPreviewImage(file.url || file.preview)
        } else {
            DownloadAnyFile(file)
        }
    }

    const handleDownload = (file) => {
        const link = document.createElement('a')
        link.href = file.url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const fileListView = (data) => {
        const fileList = data?.pbWorknoteUploadLink!!?.map((file: UploadLink) => {
            const uploadFile: UploadFile = {
                uid: file.name,
                name: file.name,
                status: 'done',
                url: file.url,
                type: file.type,
                size: 0,
                thumbUrl: exportIcon(file.type)
            }
            return uploadFile
        })
        return (
            <Upload
                listType="picture"
                className={'upload-list-inline'}
                fileList={fileList}
                onPreview={(e: any) => handlePreview(e)}
                onDownload={(e: any) => handleDownload(e)}
                showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
            />
        )
    }

    const renderTypeMailing = (data) => (
        <div style={{ backgroundColor: '#f2f2f2', padding: 15, borderRadius: '10px', marginBottom: '10px' }} key={data.id}>
            <Row style={{ alignItems: 'center', display: 'flex' }}>
                <Col lg={2} md={4} xs={5} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <AutoAvatar title={data?.createdBy?.pictureProfile || data?.createdBy?.email} broken={false} username={data?.createdBy?.fullName}/>
                </Col>
                <Col lg={20} md={16} xs={14}>
                    <span className='feck-link'><b>{data.createdBy?.fullName}</b></span> &nbsp;
                    <span style={{ color: 'gray', fontSize: '12px', fontStyle: 'italic', marginLeft: 3 }}>
                        <TimeRender Date={data.createdDatetime!!}/>
                        {' '} ago ({moment(data.createdDatetime).format('YYYY-MM-DD HH:mm:ss')})
                    </span>
                </Col>
                <Col lg={2} md={4} xs={5} style={{textAlign: 'center'}}>
                    <Icon type="mail" style={{ color: '#FA541C'}}/>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24}>
                    <div style={{ fontSize: '16px', paddingLeft: '20px' }}><b>{data.subject}</b></div>
                </Col>
            </Row>
        </div>
    )

    const renderTypeNotes = (data) => (
        <div style={{ backgroundColor: '#f2f2f2', padding: 15, borderRadius: '10px', marginBottom: '10px' }} key={data.id}>
            <Row style={{ alignItems: 'center', display: 'flex' }}>
                <Col lg={2} md={4} xs={5} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <AutoAvatar title={data?.createdBy?.pictureProfile || data?.createdBy?.email} broken={false} username={data?.createdBy?.fullName}/>
                </Col>
                <Col lg={20} md={16} xs={14}>
                    <span className='feck-link'><b>{data?.createdBy?.fullName}</b></span> &nbsp;
                    <span style={{ color: 'gray', fontSize: '12px', fontStyle: 'italic', marginLeft: 3 }}>
                        <TimeRender Date={data.createdDatetime!!}/>
                        {' '} ago ({moment(data.createdDatetime).format('YYYY-MM-DD HH:mm:ss')})
                    </span>
                </Col>
                <Col lg={2} md={4} xs={5} style={{textAlign: 'center'}}>
                    <Icon type="lock" style={{ color: '#FA541C'}}/>
                </Col>
            </Row>
            <br />
            <Row>
                <Col span={24} style={{ paddingLeft: 40 }}>
                    <div style={{ color: '#667085', padding: '10px 20px'}}>
                        {ReactHtmlParser(data.content)}
                    </div>
                    <div style={{ paddingLeft: 15 }}>
                        {data?.pbWorknoteUploadLink != null ? (<Row>
                            {fileListView(data)}
                            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)} >
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Row>) : null}
                    </div>
                </Col>
                <Col span={24}>
                    <hr style={{ color: '#D0D5DD'}}/>
                </Col>
                <Col span={24}>
                    <DisplayReplyProblem data={data} reloadWorknote={props.reloadWorknote}/>
                </Col>
            </Row>
        </div>
    )

    return (
        <div>
            <div style={{ fontSize: '18px', marginTop: 12, display: props.data?.length === 0 ? 'none' : 'block' }}><b>Noted</b></div>
            <br />
            {props.data?.map((it) => {
                if (it.type === 'Noted') {
                    return renderTypeNotes(it)
                } else {
                    return renderTypeMailing(it)
                }
            })}
            <div ref={messagesEndRef} />
        </div>
    )
}

export default DisplayListComment
