import SockJS from 'sockjs-client'
import * as Stomp from 'stompjs'
import { Client } from 'stompjs'
import { BASE_URL_AUTH } from '../service'
import { notEmpty } from '../../common-misc'

let stompClient: (Client | null) = null
let promises: Promise<Client>[] = []

export const UserWebSocket = {
    getClient: () => {
        let promise
        if (notEmpty(promises)) {
            const delegate = promises[0]
            promise = new Promise<Client>((resolve, reject) => {
                delegate.then((res) => resolve(res)).catch((err) => reject(err))
            })
        } else {
            promise = new Promise<Client>((resolve, reject) => {
                if (stompClient !== null) {
                    resolve(stompClient)
                    promises = []
                } else {
                    const sockJsProtocols = ['xhr-streaming', 'xhr-polling']
                    const socket = new SockJS(BASE_URL_AUTH + '/ws', null, {transports: sockJsProtocols})
                    const candidate = Stomp.over(socket)

                    candidate.connect(
                        {
                            // Authorization: `Bearer ${getCookie('access_token')}`,
                            id_token: sessionStorage.getItem('id_token'),
                            'X-Frame-Options': 'deny'
                        },
                        (frame: any) => {
                            stompClient = candidate
                            resolve(stompClient)
                            promises = []
                        },
                        (frame: any) => {
                            reject(frame)
                            promises = []
                        }
                    )
                }
            })
        }
        promises.push(promise)
        return promise
    },
    getSessionId: (client: Client) => {
        // @ts-ignore
        const a = client.ws._transport.url.split('/')
        if (a[a.length - 1] === 'websocket') {
            return a[a.length - 2]
        } else {
            return a[a.length - 1]
        }
    },
    // buildDestination: (client: Client, prefix: string) => '/user/' + IncidentWebSocket.getSessionId(client) + prefix,
    buildDestination3: (prefix: string) => '/user/' + prefix
}
