import { Dispatch } from 'redux'
import { CommentAddedEvent, GetCommentListByIncidentIdSuccessEvent } from './state-event'
import { Comment } from './model'
import { axiosGet, axiosPost } from '../rest'
import { checkRedirect, herderPostPatch } from '../../common-misc'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'

export const getCommentListByIncidentId = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Comment[]>(`/api/incidents/${id}/comments`)
            dispatch(GetCommentListByIncidentIdSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}

export const addComment = (comment: Comment) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(comment))
            const response = await axiosPost<Comment>('/api/comments', encryptData, herderPostPatch)
            dispatch(CommentAddedEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            checkRedirect(err?.response?.status)
            throw err
        }
    }
}
