import {Dispatch} from 'redux'
import {axiosDelete, axiosGet, axiosNonAuthGet, axiosNonAuthPost, axiosPatch, axiosPost} from '../../rest'
import {axiosGetPeople} from './rest'
import {ResponseUserRole, Roles, User, UserConfirm, UserProfile} from './model'
import {People} from '../../people/model'
import {
    ROLES_DATA_SHOW,
    USER_ROLES_DATA_ADD,
    USER_ROLES_DATA_DELETE,
    USER_ROLES_DATA_EDIT
    // USER_ROLES_DATA_SHOW
} from './type'
import {decryptBody, encryptBody} from '../../../common-misc/encryptBody'
import {checkRedirect, herderPostPatch} from '../../../common-misc'
import {convertResponseToFile} from '../../../ReportComponent/components/Services/Service'

// export const showUserList = (roles: string[]) => {
//     return async (dispatch: Dispatch) => {
//         try {
//             const response = await axiosGet<ResponseUserRole[]>(`/api/users/filterUser?roles=${roles}`)
//             dispatch(USER_ROLES_DATA_SHOW.build(decryptBody(response.data.data!!)))
//             return response.status
//         } catch (err: any) {
//             console.log(err)
//             throw err
//         }
//     }
// }
//
// export const searchUser = async (roles: string[], searchValue: string) => {
//     return async (dispatch: Dispatch) => {
//         try {
//             const encryptData = encryptBody(JSON.stringify(roles))
//             const response = await axiosPost<ResponseUserRole[]>(`/api/user/searchUser?value=${searchValue}`, encryptData, herderPostPatch)
//             dispatch(USER_ROLES_DATA_SHOW.build(decryptBody(response.data.data!!)))
//             return response.status
//         } catch (err: any) {
//             console.log(err)
//             throw err
//         }
//     }
// }

export const addUserRole = (userRole: ResponseUserRole) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(userRole))
            console.log(JSON.stringify(userRole))
            const response = await axiosPost<ResponseUserRole[]>('/api/users', encryptData, herderPostPatch)
            dispatch(USER_ROLES_DATA_ADD.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const confirmUser = async (user: UserConfirm, tenantID: string, userInfo: any) => {
    try {
        const encryptData = encryptBody(JSON.stringify(user))
        const response = await axiosNonAuthPost<any>('/api/users/confirm-account', encryptData, {
            headers: {
                // userId: userInfo.id,
                // username: userInfo.username,
                tenantID: tenantID,
                'Content-Type': 'text/plain'
            }
        })
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const forgotPassword = async (username: string, event: string) => {
    try {
        const response = await axiosNonAuthGet<any>(`/api/users/forgot-password/${username}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const resend = async (username: string, event: string) => {
    try {
        const response = await axiosGet<any>(`/api/users/resend/${username}`)
        return response.status
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getUsername = async (code: string, tenantID: string) => {
    try {
        const response = await axiosNonAuthGet<any>(`/api/users/username/${code}`, {
            headers: {
                tenantID: tenantID
            }
        })
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const editUser = (userRole: ResponseUserRole, id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(userRole))
            const response = await axiosPatch<ResponseUserRole>(`/api/users/${id}`, encryptData, herderPostPatch)
            dispatch(USER_ROLES_DATA_EDIT.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const deleteUser = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<ResponseUserRole[]>(`/api/users/${id}`)
            dispatch(USER_ROLES_DATA_DELETE.build(id))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const showActiveRoleList = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<Roles[]>('/api/roles')
            dispatch(ROLES_DATA_SHOW.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const searchAllPeople = async (people: string) => {
    try {
        const params = {
            search: people
        }
        const response = await axiosGetPeople<People[]>('/api/users/requester', {params})
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getAllPeople = async () => {
    try {
        const response = await axiosGetPeople<People[]>('/api/users')
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const searchPeopleNotExistInUser = async (people: string) => {
    try {
        const params = {
            people: people
        }
        const response = await axiosGetPeople<People[]>('/api/users/search', {params})
        return decryptBody(response.data.data)
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export const getAllUser = async () => {
    try {
        const response = await axiosGet<User[]>('/api/users/allUsers')
        return decryptBody(response.data.data)
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const getUserImage = async (username: string) => {
    try {
        const response = await axiosGet<string>('/api/users/image')
        return decryptBody(response.data.data)
    } catch (error: any) {
        console.log(error)
        checkRedirect(error?.response?.status)
        throw error
    }
}

export const getUserProfile = async () => {
    try {
        const response = await axiosGet<UserProfile>('/api/users/profile')
        return decryptBody(response.data.data || '')
    } catch (error: any) {
        console.log(error)
        checkRedirect(error?.response?.status)
        throw error
    }
}

export const getAllUsersByUserId = async (id: string) => {
    try {
        const response = await axiosGet<User[]>('/api/users/employee/' + id)
        return decryptBody(response.data.data || '')
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const getAllUserAsigneeSLA = async (search?: string) => {
    try {
        const params = {
            value: search || ''
        }

        const response = await axiosGet<User[]>('/api/users/search/users', {params})
        return decryptBody(response.data.data || '')
    } catch (error: any) {
        console.log(error)
        throw error
    }
}

export const downloadTempleteUsers = async () => {
    try {
        const response = await axiosGet('/api/imports/users/template', {
            headers: {
                'Content-Type': 'text/plain'
            },
            responseType: 'blob' // Set the response type to 'blob' to handle binary data
        })
        convertResponseToFile(response, 'Template_Import_User.xlsx')
    } catch (err: any) {
        console.log(err)
        throw err
    }
}

export async function importUserAuthFromBE(data) {
    try {
        const encryptData = encryptBody(JSON.stringify(data))
        const response = await axiosPost('/api/imports/users', encryptData, herderPostPatch)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err?.response?.data || undefined
    }
}

export async function getNotificationAuthByUserID(UserID) {
    try {
        const response = await axiosGet('/api/notifications/user/' + UserID)
        return decryptBody(response.data.data!!)
    } catch (err: any) {
        console.log(err)
        throw err?.response?.data || undefined
    }
}
