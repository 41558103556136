import {Col, Collapse, Row, Table} from 'antd'
import React, {useState} from 'react'
import {Asset, PMHistory} from '../../../Preventive/Model'
import moment from 'moment'
import {TicketChangeHistory, TicketRelatedHistory, syncDataFromPMHistory} from '../model'
import {getChangeHistoryBySerialNo, getSyncPMToAssetHistory, getTicketRelatedHistory} from '../service'
import {getPMHistory} from '../../../Preventive/services'
import { getHistoryWoRelatedAsset } from '../../wo-management'

interface Params {
    assetData?: Asset
    serialNo: string
}

type Props = Params
const { Panel } = Collapse
const TabHistory: React.FC<Props> = (props: Props) => {
    const [ticketChangeHistory, setTicketChangeHistory] = useState<TicketChangeHistory[]>([])
    const [PMHistorys, setPMHistorys] = useState<PMHistory[]>([])
    const [ticketRelatedAsset, setTicketRelatedAsset] = useState<TicketRelatedHistory[]>([])
    const [woRelatedAsset, setWoRelatedAsset] = useState()
    const [syncDataPMToAsset, setSyncDataPMToAsset] = useState<syncDataFromPMHistory[]>([])

    const columnsChangeHistory = [
        {
            title: 'Date/Time',
            dataIndex: 'createdDatetime',
            render: (text, row, index) => {
                return (moment(row.createdDatetime).format('YYYY-MM-DD HH:mm:ss'))
            }
        },
        {
            title: 'Field Name',
            dataIndex: 'field'
        },
        {
            title: 'From',
            dataIndex: 'oldValue'
        },
        {
            title: 'To',
            dataIndex: 'newValue'
        },
        {
            title: 'By',
            dataIndex: 'createdBy'
        }
    ]

    const columnsPreventiveHistory = [
        {
            title: 'ID',
            dataIndex: 'pmID',
            width: '20%'

        },
        {
            title: 'Plan Date',
            dataIndex: 'pmPlanDate',
            render: (text, row, index) => {
                return (moment(row.pmPlanDate).format('YYYY-MM-DD'))
            },
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'Completed Date',
            dataIndex: '',
            render: (data: PMHistory) => {
                return <>{data.pmStatus === 'Completed' ? data.lastModifiedDatetime : null}</>
            },
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'By',
            dataIndex: 'createdBy',
            width: '20%'
            // align: 'center' as const
        }
        // {
        //     title: 'Deskside Name',
        //     dataIndex: 'pmTeam'
        // }
    ]

    const columnsTicketRelated = [
        {
            title: 'Ticket Number',
            dataIndex: 'ticketNumber',
            width: '20%'
            // align: 'center' as const
        },
        {
            title: 'Ticket Type',
            dataIndex: 'ticketType',
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'Ticket Status',
            dataIndex: 'ticketStatus',
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'Ticket Related Timestamp',
            dataIndex: 'ticketRelatedTimestamp',
            width: '20%'
            // align: 'center' as const
        }
    ]

    const columnWorkorderRelated = [
        {
            title: 'WorkOrder Number',
            dataIndex: 'woNumber',
            width: '20%'
            // align: 'center' as const
        },
        {
            title: 'Ticket Type',
            dataIndex: 'woType',
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'WO Status',
            dataIndex: 'woStatus',
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'WorkOrder Related Timestamp',
            dataIndex: 'woRelatedTimestamp',
            width: '20%'
            // align: 'center' as const
        }
    ]

    const columnSyncDataFromPM = [
        {
            title: 'Date/Time',
            dataIndex: 'createdDatetime',
            render: (text, row, index) => {
                return (moment(row.createdDatetime).format('YYYY-MM-DD HH:mm:ss'))
            },
            width: '20%'
        },
        {
            title: 'PM ID',
            dataIndex: 'pmId',
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'PM Staus',
            dataIndex: 'pmStatus',
            width: '30%'
            // align: 'center' as const
        },
        {
            title: 'By',
            dataIndex: 'createdBy',
            width: '20%'
            // align: 'center' as const
        }
    ]

    const openChange = () => {
        getChangeHistoryBySerialNo(props.serialNo).then((result) => {
            if (result) {
                setTicketChangeHistory(result)
            }
        })
    }

    const openHistory = () => {
        getPMHistory(props.serialNo).then((result) => {
            if (result) {
                setPMHistorys(result)
            }
        })
    }

    const openTicketRelate = () => {
        getTicketRelatedHistory(props.serialNo).then((result) => {
            setTicketRelatedAsset(result)
        })
    }

    const openWorkorderRelated = () => {
        getHistoryWoRelatedAsset(props.serialNo).then((res) => {
            setWoRelatedAsset(res)
        })
    }

    const openSyncPMFromAsset = () => {
        getSyncPMToAssetHistory(props.assetData?.id).then((res) => {
            setSyncDataPMToAsset(res)
        })
    }

    return (
        <Row>
            <Col span={24} style={{ padding: 30, backgroundColor: '#F2F2F2', marginTop: 10 }}>
                <Col sm={24} md={12} xl={8}>
                    <Col span={24}>
                        <span style={{ color: '#828282' }}>Data origin : </span>
                        <span style={{ color: '#4f4f4f' }}>{props.assetData?.dataOrigin}</span>
                    </Col>
                </Col>
                <Col sm={24} md={12} xl={8}>
                    <Col span={24}>
                        <span style={{ color: '#828282' }}>Created by : </span>
                        <span style={{ color: '#4f4f4f' }}>{props.assetData?.createdBy}</span>
                    </Col>
                    <Col span={24}>
                        <span style={{ color: '#828282' }}>Created datetime : </span>
                        <span style={{ color: '#4f4f4f' }}>{props.assetData?.createdDatetime}</span>
                    </Col>
                </Col>
                <Col sm={24} md={12} xl={8}>
                    <Col span={24}>
                        <span style={{ color: '#828282' }}>Last modified by : </span>
                        <span style={{ color: '#4f4f4f' }}>{props.assetData?.lastModifiedBy}</span>
                    </Col>
                    <Col span={24}>
                        <span style={{ color: '#828282' }}>Last modified datetime : </span>
                        <span style={{ color: '#4f4f4f' }}>{props.assetData?.lastModifiedDatetime}</span>
                    </Col>
                </Col>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
                <Collapse defaultActiveKey={['0']} onChange={openChange}>
                    <Panel header="Change History" key="1">
                        <div style={{ overflow: 'auto' }}>
                            <Table
                                columns={columnsChangeHistory}
                                dataSource={ticketChangeHistory}
                                size="middle"
                            />
                        </div>
                    </Panel>
                </Collapse>
                <Col span={24} style={{ marginTop: 10 }}>
                </Col>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
                <Collapse defaultActiveKey={['0']} onChange={openHistory}>
                    <Panel header="Preventive Maintenance History" key="2">
                        <div style={{ overflow: 'auto' }}>
                            <Table
                                columns={columnsPreventiveHistory}
                                dataSource={PMHistorys}
                                size="middle"
                            />
                        </div>
                    </Panel>
                </Collapse>
                <Col span={24} style={{ marginTop: 10 }}>
                </Col>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
                <Collapse defaultActiveKey={['0']} onChange={openTicketRelate}>
                    <Panel header="Ticket Related" key="3">
                        <div style={{ overflow: 'auto' }}>
                            <Table
                                columns={columnsTicketRelated}
                                dataSource={ticketRelatedAsset}
                                size="middle"
                            />
                        </div>
                    </Panel>
                </Collapse>
                <Col span={24} style={{ marginTop: 10 }}>
                </Col>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
                <Collapse defaultActiveKey={['0']} onChange={openWorkorderRelated}>
                    <Panel header="WorkOrder Related" key="3">
                        <div style={{ overflow: 'auto' }}>
                            <Table
                                columns={columnWorkorderRelated}
                                dataSource={woRelatedAsset}
                                size="middle"
                            />
                        </div>
                    </Panel>
                </Collapse>
                <Col span={24} style={{ marginTop: 10 }}>
                </Col>
            </Col>

            <Col span={24} style={{ marginTop: 10 }}>
                <Collapse defaultActiveKey={['0']} onChange={openSyncPMFromAsset}>
                    <Panel header="Sync Data From PM" key="4">
                        <div style={{ overflow: 'auto' }}>
                            <Table
                                columns={columnSyncDataFromPM}
                                dataSource={syncDataPMToAsset}
                                size="middle"
                            />
                        </div>
                    </Panel>
                </Collapse>
                <Col span={24} style={{ marginTop: 10 }}>
                </Col>
            </Col>
        </Row>
    )
}

export default TabHistory
