/* eslint-disable no-useless-escape */
import { Breadcrumb, Button, Card, Col, Drawer, Form, Input, message, Modal, Row, Tag, Tooltip, Icon } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import TableCompletePM from './components/TableCompletePM'
import { FilterData } from './Model'
import moment from 'moment'
import { CompletedPMContext } from './ContextAPI/CompletedPMContext'
import { Link } from 'react-router-dom'
import FilterDataForm from './components/FilterDataForm'
import ReactHtmlParser from 'react-html-parser'
import './components/detailPrint.css'
import { FormComponentProps } from 'antd/es/form'
import QRCode from '../common-components/QRCode'
import { getAllPreventiveForComplete } from './services'

type Props = FormComponentProps
const { Search } = Input
const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
const CompletePM: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form
    const [visible, setVisible] = useState<boolean>(false)
    const [isQRCodeVisible, setIsQRCodeVisible] = useState<boolean>(false)
    const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
    const [isBarcodeVisible, setIsBarcodeVisible] = useState<boolean>(false)
    const { CompletedPMState, GetCompletedPreventive } = useContext<any>(CompletedPMContext)
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(10)
    const [total, setTotal] = useState<number>(0)
    const [valueFilter, setValueFilter] = useState<FilterData>({
        pmStatus: [],
        pmIdStatus: [],
        // department: [],
        // groups: [],
        start: '',
        end: '',
        completeStart: moment().add(-1, 'days').format('YYYY-MM-DD'),
        completeEnd: moment().format('YYYY-MM-DD')
        // assetStatus: []
    })
    const [isDeletePmStatus, setIsDeletePmStatus] = useState(true)
    const [, setOnline] = useState(window.navigator.onLine)
    const [SortByAndOrderBy, setSortByAndOrderBy] = useState<{sortBy: string | undefined, orderBy: string | undefined}>()

    const changePage = (page: number) => {
        if (page) {
            setPage(page)
        }
    }

    const changePageSize = (pageSize: number) => {
        if (pageSize) {
            setPageSize(pageSize)
        }
    }

    const FetchData = (filter: FilterData, search?: string, thisPage?: number, sortBy?: string, orderBy?: string) => {
        setIsLoadingTable(true)
        let getDatasessionStorage: any = sessionStorage.getItem('valueFilterPMComplete')
        if (getDatasessionStorage) {
            getDatasessionStorage = JSON.parse(getDatasessionStorage)
            setValueFilter(getDatasessionStorage as any)
        }
        getAllPreventiveForComplete(search, getDatasessionStorage || filter, page, pageSize, sortBy, orderBy).then((res) => {
            setIsLoadingTable(false)
            if (GetCompletedPreventive) {
                GetCompletedPreventive(res.data)
                setTotal(res.total)
            }
            // addAllDataPMWithAssetInDBOffline(res)
        }).catch((err) => {
            setIsLoadingTable(false)
            message.error('Failed to fetch data' + err)
        })
    }

    useEffect(() => {
        if (SortByAndOrderBy) {
            let convertSortBy
            switch (SortByAndOrderBy.sortBy) {
            case 'pmPlanDate':
                convertSortBy = 'planDate'
                break
            case 'pmId':
                convertSortBy = 'id'
                break
            case 'description':
                convertSortBy = 'description'
                break
            case 'pmIdStatus':
                convertSortBy = 'overallStatus'
                break
            case 'pmLocation':
                convertSortBy = 'pmLocation'
                break
            case 'pmStatus':
                convertSortBy = 'pmStatus'
                break
            default:
                convertSortBy = null
            }
            FetchData(valueFilter, undefined, page, convertSortBy, SortByAndOrderBy.orderBy)
        } else {
            FetchData(valueFilter)
        }
        window.addEventListener('online', () => setOnline(true))
        window.addEventListener('offline', () => setOnline(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize, SortByAndOrderBy])

    const onSearch = (e: string) => {
        setIsLoadingTable(true)
        if (e) {
            const cleanedStr = e.replace(regex, ' ').trim()
            setFieldsValue({
                search: cleanedStr
            })
            e = cleanedStr
        }
        FetchData(valueFilter, e)
        // searchPreventive(e, valueFilter).then((res) => {
        //     setIsLoadingTable(false)
        //     if (GetCompletedPreventive) {
        //         GetCompletedPreventive(res)
        //     }
        // }).catch(() => {
        //     setIsLoadingTable(false)
        //     message.error('Failed to fetch data')
        // })
    }

    const CancelQRCode = () => {
        setIsQRCodeVisible(false)
    }

    const getDataScan = (value: string) => {
        if (value !== undefined) {
            setIsQRCodeVisible(false)
            const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g
            const cleanedStr = value.replace(regex, '')
            setFieldsValue({
                search: cleanedStr
            })
        }
        setIsBarcodeVisible(false)
    }

    const log = (e: string) => {
        const valueFilterTemp = valueFilter
        // if (e === 'department') {
        //     valueFilterTemp.department = []
        // } else if (e === 'groups') {
        //     valueFilterTemp.groups = []
        // } else
        if (e === 'start') {
            valueFilterTemp.start = ''
            valueFilterTemp.end = ''
        } else if (e === 'completeStart') {
            valueFilterTemp.completeStart = ''
            valueFilterTemp.completeEnd = ''
        } else if (e === 'pmLocation') {
            valueFilterTemp.pmLocation = []
        } else if (e === 'pmStatus') {
            valueFilterTemp.pmStatus = []
            setIsDeletePmStatus(true)
        } else if (e === 'pmIdStatus') {
            valueFilterTemp.pmIdStatus = []
        }
        // else if (e === 'ownerName') {
        //     valueFilterTemp.userName = []
        // } else if (e === 'assetStatus') {
        //     valueFilterTemp.assetStatus = []
        // }
        sessionStorage.setItem('valueFilterPMComplete', JSON.stringify(valueFilterTemp))
        setValueFilter(valueFilterTemp)
        FetchData(valueFilterTemp)
    }

    const getValueFilter = (value: FilterData) => {
        sessionStorage.setItem('valueFilterPMComplete', JSON.stringify(value))
        if (value.pmStatus.length !== 0) {
            setIsDeletePmStatus(false)
        }
        setValueFilter(value)
        setVisible(false)
        setPage(1)
        FetchData(value, undefined, 1)
    }

    const handleOnclose = (visible: boolean) => {
        setVisible(visible)
    }

    const searchFilterTag = (title: string, searchFilter: string[], onClose: Function) => {
        let tags: JSX.Element = (<></>)
        if (searchFilter.length > 0) {
            tags = (
                <Tooltip placement="bottom" title={ReactHtmlParser(searchFilter.map((filter: string) => { return `- ${filter}` }).join('</br>'))}>
                    <Tag style={{ margin: 10 }} closable onClose={() => onClose()}>{title}</Tag>
                </Tooltip>)
        }
        return tags
    }

    const OpenBarcode = () => {
        setIsBarcodeVisible(true)
    }

    const CancelBarcode = () => {
        setIsBarcodeVisible(false)
    }

    const changeSortByAndOrderBy = (data) => {
        if (data) {
            setSortByAndOrderBy(data)
        }
    }

    return (
        <div>
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item>
                    <Link to="/CompletePM">Completed Preventive Maintenance</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <br />
            <Card headStyle={{ borderBottom: 'none', paddingTop: '23px' }}>

                <Row gutter={[8, 8]}>
                    <Col xs={20} sm={20} md={12} xl={8}>
                        <Tooltip placement="bottom" title={'Search Serial Number or ID or Description'}>
                            <Form
                                name="search"
                                layout={'vertical'}
                            >
                                <Form.Item
                                    label=""
                                >
                                    {getFieldDecorator('search')(
                                        <Search placeholder="Search Serial Number or ID or Description" onSearch={(e) => onSearch(e)} allowClear={true}/>
                                    )}
                                </Form.Item>
                            </Form>
                        </Tooltip>
                    </Col>
                    <Col xs={4} sm={4} md={12} xl={1}>
                        <Button onClick={OpenBarcode}><Icon type="barcode" style={{margin: 0}}/></Button>
                    </Col>
                    {/* <Col xs={12} sm={12} md={12} xl={1}> */}
                    {/* <Button className="mr5" onClick={OpenQRCode} style={{width:'100%'}}><QrcodeOutlined /></Button> */}
                    {/* </Col> */}
                    {isQRCodeVisible
                        ? <Modal title="Scan QR-Code" visible={isQRCodeVisible} onOk={CancelQRCode} onCancel={CancelQRCode} footer={false} width={550}>
                            <QRCode getDataScan={getDataScan} />
                        </Modal>
                        : null }
                    {isBarcodeVisible
                        ? <Modal title="Scan Barcode" visible={isBarcodeVisible} onCancel={CancelBarcode} footer={false} width={550}>
                            <QRCode getDataScan={getDataScan} />
                        </Modal>
                        : null }
                </Row>
                <br />
                <div>
                    <Button onClick={() => setVisible(true)} className="mr5" style={{borderStyle: 'none'}}><Icon type="filter" />Advanced Search</Button>
                    {/* {valueFilter.department ? searchFilterTag('Department', valueFilter.department, () => log('department')) : null} */}
                    {valueFilter.start ? searchFilterTag('Plan Date', [valueFilter.start, valueFilter.end], () => log('start')) : null}
                    {/* {valueFilter.groups ? searchFilterTag('Group', valueFilter.groups, () => log('groups')) : null} */}
                    {valueFilter.completeStart ? searchFilterTag('CompletedDate', [valueFilter.completeStart, valueFilter.completeEnd], () => log('completeStart')) : null}
                    {/* {valueFilter.userName ? searchFilterTag('Owner name', valueFilter.userName, () => log('ownerName')) : null} */}
                    {/* {valueFilter.assetStatus ? searchFilterTag('Asset status', valueFilter.assetStatus, () => log('assetStatus')) : null} */}
                    {valueFilter.pmLocation ? searchFilterTag('PM Location', valueFilter.pmLocation, () => log('pmLocation')) : null}
                    {valueFilter.pmIdStatus ? searchFilterTag('Overall Status', valueFilter.pmIdStatus, () => log('pmIdStatus')) : null}
                    {valueFilter.pmStatus ? searchFilterTag('PM Status', valueFilter.pmStatus, () => log('pmStatus')) : null}
                </div>
                <Drawer
                    width={window.innerWidth <= 768 ? '70%' : '20%'}
                    title="Advanced Search"
                    placement="left"
                    onClose={() => handleOnclose(false)}
                    visible={visible}
                    closable={true}
                >
                    <FilterDataForm valueFilter={valueFilter} isRender={visible} getValueFilter={getValueFilter} isCompletePm={true} handleOnclose={handleOnclose} isDeletePMStatus={isDeletePmStatus}/>
                </Drawer>
                <br />
                <TableCompletePM dataTransfer={CompletedPMState} isLoading={isLoadingTable} changePage={changePage} changePageSize={changePageSize} total={total} pageSize={pageSize} changeSortByAndOrderBy={changeSortByAndOrderBy} valueFilter={valueFilter} valueSearch={getFieldValue('search')}/>
            </Card>
        </div>
    )
}

const MyCompletePM = Form.create<Props>({ name: 'CompletePM' })(CompletePM)
export default MyCompletePM
