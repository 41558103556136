import { SlaGlobalSetting } from './model'
import { GetAllGlobalSettingSuccessEvent, UpdateGlobalSettingSuccessEvent } from './state-event'
import { axiosPost, axiosGet } from '../rest'
import { Dispatch } from 'redux'
import { decryptBody, encryptBody } from '../../common-misc/encryptBody'
import { herderPostPatch } from '../../common-misc'

// fix ticket type = incident waiting UI for support other type
export const getAllGlobalSetting = (ticketType = 'Incident') => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<SlaGlobalSetting>(`/api/global-settings/type/${ticketType}`)
            dispatch(GetAllGlobalSettingSuccessEvent.build(decryptBody(response.data.data!!)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}

export const updateGlobalSetting = (globalSetting: SlaGlobalSetting) => {
    return async (dispatch: Dispatch) => {
        try {
            const encryptData = encryptBody(JSON.stringify(globalSetting))
            const response = await axiosPost<SlaGlobalSetting>('/api/global-settings', encryptData, herderPostPatch)
            dispatch(UpdateGlobalSettingSuccessEvent.build(decryptBody(response.data.data || encryptData)))
            return response.status
        } catch (err: any) {
            console.log(err)
            throw err
        }
    }
}
